import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {
  @Input() image;
  @Input() photos = [];
  @Output() closed = new EventEmitter();
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

  }
  @HostListener('document:keydown', ['$event'])
  handleImage(event) {
    if (this.image) {
      if (event && event.code === "Escape") {
        this.image = null;
      }else if (event && event.code === "ArrowLeft") {
        this.prevImage();
      }else if (event && event.code === "ArrowRight") {
        this.nextImage();
      }else {
        // DO NOTHING
      }
    }
  }
  prevImage() {
    if(this.photos && this.photos.length >= 0){
      let currentImageIndex = this.photos.findIndex((i) => i === this.image);
      currentImageIndex--;
      if (currentImageIndex < 0) {currentImageIndex = this.photos.length - 1; }
      this.image = this.photos[currentImageIndex];
    }
  }
  nextImage() {
    if(this.photos && this.photos.length >= 0) {
      let currentImageIndex = this.photos.findIndex((i) => i === this.image);
      currentImageIndex++;
      if (currentImageIndex >= this.photos.length) {
        currentImageIndex = 0;
      }
      this.image = this.photos[currentImageIndex];
    }
  }
  closeImage() {
    this.image = null;
    this.closed.emit();
  }
  rotateImage(){
    if(!this.image.rotation){
      this.image.rotation = 0;
    }
    this.image.rotation -= 90;
  }
  getRotation(){
    return this.sanitizer.bypassSecurityTrustStyle(`rotate(${this.image.rotation || 0}deg)`);
  }

  getImage(url) {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
  downloadImage(){
    const a = document.createElement('a');
    a.href = this.image.url
    a.target = "_blank";
    a.download = this.image.name || this.image.url;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

}
