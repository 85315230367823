import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {UserService} from "../services/user.service";
import {BaseService} from "../services/_base.service";

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService{

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  all(start_at: string, end_at: string, search?: string) {
    return this.http.get(this.apiURL + '/events', this.getParams({start_at: start_at,end_at: end_at, search: search}));
  }
  create(value: any) {
    return this.http.post(this.apiURL + '/events', value, this.getOptions());
  }
  get(id: number) {
    return this.http.get(this.apiURL + '/events/' + id, this.getParams());
  }
  update(id: number, value: any) {
    return this.http.put(this.apiURL + '/events/' + id, value, this.getOptions());
  }
  delete(id: number) {
    return this.http.delete(this.apiURL + '/events/' + id, this.getOptions());
  }
}
