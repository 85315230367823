import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class StatusRequirementsService extends BaseService implements ICRUDService{
  params = ['status_id','requirement_status_id']
  model = 'StatusRequirement';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null, page = 1){
    return this.http.get(this.adminURL+'/status_requirements',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/status_requirements/',{
      status_requirement: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/status_requirements/'+id,{
      status_requirement: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/status_requirements/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/status_requirements/'+id+'/restore',this.getParams());
  }

}
