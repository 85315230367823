<div class="sign" [hidden]="termMode">
  <div class="sign-here" [hidden]="!signaturePad ||!signaturePad.isEmpty()">Firme aquí</div>
  <canvas id="sign"></canvas>
  <div class="buttons">
    <button mat-raised-button type="button" (click)="clearSign()" [disabled]="uploading" color="warn"><i class="fa fa-trash"></i></button>
    <div class="expander"></div>
    <button mat-raised-button type="button" (click)="saveSign()" [disabled]="uploading" color="accent"><i class="fa fa-save"></i></button>
  </div>
</div>
<div class="terms" [hidden]="!termMode">
  <pre class="text">
    {{data?.sign_text || company.preferences.order_legend || 'No existen términos ni condiciones'}}
  </pre>
  <div class="buttons">
    <button mat-raised-button (click)="close()" color="warn"><i class="fa fa-times"></i></button>
    <div class="expander"></div>
    <button mat-raised-button (click)="termMode = false" color="accent"><i class="fa fa-check"></i></button>
  </div>
</div>
