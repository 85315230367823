import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {RequirementsService} from "../../services/requirements.service";
import { HttpResponse } from "@angular/common/http";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {WorkUserSpecialitiesService} from "../../admin/services/work-user-specialities.service";

@Component({
  selector: 'app-work-user-speciality',
  templateUrl: './work-user-speciality.component.html',
  styleUrls: ['./work-user-speciality.component.scss']
})
export class WorkUserSpecialityComponent implements OnInit, OnChanges {
  requirements = []
  specialities:any = null
  @Input() user: any;
  constructor(
    private requirementService: RequirementsService,
    private service: WorkUserSpecialitiesService
  ) { }

  ngOnInit() {
    this.requirementService.all().toPromise().then((value:HttpResponse<any[]>)=>{
      this.requirements = value.body;
    });
  }
  ngOnChanges(){
    if(this.user){
      this.specialities = this.user.work_user_specialities;
    }
  }
  getValue(type,id){
    const found = this.specialities.find((s)=>s.speciality_type===type && s.speciality_id===id);
    if(found){
      return found.enable;
    }else{
      return true;
    }
  }
  setValue(type,id,event:MatCheckboxChange){
    this.service.create({
      speciality_type: type,
      speciality_id: id,
      enable: event.checked,
      user_id: this.user.id
    }).toPromise().catch(error=>{
      console.log(error);
    });
  }

}
