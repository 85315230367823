import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";
import {AdminService} from "../../services/admin.service";
import {API_URL, MPAGO_APPID} from "../../../app.config";
import {ActivatedRoute} from "@angular/router";
import {first} from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: 'app-admin-payment',
  templateUrl: './admin-payment.component.html',
  styleUrls: ['./admin-payment.component.scss','../admin-main.common.scss']
})
export class AdminPaymentComponent implements OnInit {
  companyForm = this.fb.group({
    name: [{value: '', disabled: true}],
    preferences: this.fb.group({
      logo: '',
      address: '',
      phone: '',
      email_banner: '',
      work_days: new UntypedFormArray([
        new UntypedFormControl(true),
        new UntypedFormControl(true),
        new UntypedFormControl(true),
        new UntypedFormControl(true),
        new UntypedFormControl(true),
        new UntypedFormControl(true),
        new UntypedFormControl(true),
      ]),
      work_start_time: '08:00',
      work_end_time: '19:00',
      report_send_frecuency: 'weekly',
      report_role_ids: []
    }),
    email: [{value: '', disabled: true}],
    domain: [{value: '', disabled: true},
      Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)]
  });
  loading = false;
  mercadopago;
  constructor(
    private fb: UntypedFormBuilder,
    private companyService: AdminService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getCompany()
    this.route.queryParams.pipe(first()).subscribe(value => {
      if(value.code){
        this.loading = true;
        this.companyService.setupMercagoPago(value.code).toPromise().then(value1 => {
          this.loading = false;
          this.getCompany();
        }).catch(reason => {
          console.log(reason);
        })
      }
    })
  }
  getCompany(){
    this.companyService.setupApp().toPromise().then((response:HttpResponse<any>)=>{
      if(response.body && response.body.preferences){
        this.mercadopago = response.body.preferences.mercadopago;
      }

    }).catch((error)=>{
      console.log(error);
    });
  }
  openMpagoLink() {
    const appId = MPAGO_APPID;
    const redirectUri = encodeURI(`${API_URL}/company/mauth/`);
    const url = `https://auth.mercadopago.cl/authorization?client_id=${appId}&state=${this.companyService.local$.getValue().id}&response_type=code&platform_id=mp&redirect_uri=${redirectUri}`
    console.log(url);
    window.open(url,"_blank");
    window.close()
  }
}
