<ng-container *ngIf="fields.length + hiddenFields.length > 0 && (!filterFields || filterFields.length > 0)">
  <div class="subtitle" *ngIf="wrap">{{appService.getText('others')}}</div>
  <ng-container *ngIf="!readonly && reactiveFormMode && parentFormGroup" [formGroup]="parentFormGroup">
    <fieldset formGroupName="custom_fields" [class.wrapper]="wrap">
    <div class="row" *ngFor="let field of fields">
      <ng-container *ngIf="((field.show_booking && bookingMode) || !bookingMode) && !hidden(field)">
        <div class="col radio" *ngIf="field.field_type==='boolean'">
          <div class="radio-container">
            <span class="radio-title">{{field.title}}: </span>
            <mat-radio-group
                class="radio-group"
                [formGroupName]="field.key"
                [required]="field.required && !bookingMode || field.require_booking && bookingMode"
                [name]="field.key"
                style="margin-left: 10px"
                [formControlName]="field.key"
            >
                <mat-radio-button value="si">Si</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
            </mat-radio-group>
        </div>
        </div>
        <div class="col" *ngIf="field.field_type==='datetime'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}" class="hidden-datepicker">
            <mat-label>{{field.title}}</mat-label>
            <input matInput
              [matDatetimepicker]="datetimePicker"
              [min]="currentDate"
              [formGroupName]="field.key"
              [name]="field.key"
              [required]="field.required && !bookingMode || field.require_booking && bookingMode"
              [formControlName]="field.key"
            >
            <mat-datepicker #picker></mat-datepicker>
            <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
          </mat-form-field>
        </div>
        <div class="col" *ngIf="field.field_type==='date'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}" (click)="pickerN.open()">
            <mat-label>{{field.title}}</mat-label>
            <input
              matInput
              [matDatepicker]="pickerN"
              [required]="field.required && !bookingMode || field.require_booking && bookingMode"
              [formGroupName]="field.key"
              [name]="field.key"
              autocomplete="off"
              [formControlName]="field.key"
            >
          </mat-form-field>
          <mat-datepicker #pickerN [startAt]="currentDate"></mat-datepicker>
        </div>
        <div class="col" *ngIf="field.field_type==='text'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title}}</mat-label>
            <input
              matInput
              [formGroupName]="field.key"
              [required]="field.required && !bookingMode || field.require_booking && bookingMode"
              [name]="field.key"
              autocomplete="off"
              [formControlName]="field.key"
            >
          </mat-form-field>
        </div>
        <div class="col" *ngIf="field.field_type==='number'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title}}</mat-label>
            <input matInput
              type="number"
              [formGroupName]="field.key"
              [required]="field.required && !bookingMode || field.require_booking && bookingMode"
              [name]="field.key"
              autocomplete="off"
              [formControlName]="field.key"
            >
          </mat-form-field>
        </div>
        <div class="col file" *ngIf="field.field_type==='image'">
          <div class="title">
            <span>{{field.title}}</span>
          </div>
          
          <app-image-uploader style="margin-bottom: 10px;" small="true" (uploaded)="addImages(field,$event)" [photos]="parentFormGroup.controls['custom_fields'].controls[field.key].value || []"></app-image-uploader>
        </div>
        <div class="col" *ngIf="field.field_type==='users'" style="margin-bottom: 15px;">
          <div class="user-selector" (click)="setUser(field,parentFormGroup.controls['custom_fields'].controls[field.key].disabled)" [class.disabled]="parentFormGroup.controls['custom_fields'].controls[field.key].disabled">
            <div class="label" *ngIf="parentFormGroup.controls['custom_fields'].value[field.key]">{{field.title}}</div>
            <div class="title" [class.with-label]="parentFormGroup.controls['custom_fields'].value[field.key]">{{parentFormGroup.controls['custom_fields'].value[field.key]?.name || field.title}}<span *ngIf="field.required && !bookingMode || field.require_booking && bookingMode">&nbsp;*</span></div>
            <div class="selector">{{parentFormGroup.controls['custom_fields'].value[field.key]?.name || 'Seleccionar'}}</div>
            <input
              type="hidden"
              [placeholder]="field.title"
              [formGroupName]="field.key"
              [required]="field.required && !bookingMode || field.require_booking && bookingMode"
              [name]="field.key"
              autocomplete="off"
              [formControlName]="field.key"
            >
          </div>
        </div>
        <div class="col" *ngIf="field.field_type==='list'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title}}</mat-label>
            <mat-select
              [formGroupName]="field.key"
              [required]="field.required && !bookingMode || field.require_booking && bookingMode"
              [name]="field.key"
              autocomplete="off"
              [multiple]="field.list.multiple"
              (selectionChange)="saveDrop(field,$event)"
              [value]="parentFormGroup.controls['custom_fields'].controls[field.key].value"
            >
              <mat-option *ngFor="let item of field.list.list_items" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        </ng-container>
    </div>
    <div class="extra" *ngIf="hiddenFields.length > 0" (click)="showHiddenFields()">
      <i class="fa fa-plus"></i>
    </div>
    </fieldset>
  </ng-container>
  <fieldset *ngIf="!readonly && !reactiveFormMode && model" [class.wrapper]="wrap">
    <div class="row" *ngFor="let field of fields">
      <ng-container *ngIf="((field.show_booking && bookingMode) || !bookingMode) && !hidden(field)">
        <div class="col radio" *ngIf="field.field_type==='boolean'">
          <span class="radio-title">{{field.title}}<span *ngIf="field.required && !bookingMode || field.require_booking && bookingMode">*</span>: </span>
          <mat-radio-group [(ngModel)]="model.custom_fields[field.key]" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key" style="margin-left: 10px">
            <mat-radio-button value="si">Si</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col" *ngIf="field.field_type==='datetime'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}" class="hidden-datepicker">
            <mat-label>{{field.title}}</mat-label>
            <input matInput [matDatetimepicker]="datetimePicker" [min]="currentDate" [(ngModel)]="model.custom_fields[field.key]" [name]="field.key" [required]="field.required && !bookingMode || field.require_booking && bookingMode">
            <mat-datepicker #picker></mat-datepicker>
            <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
          </mat-form-field>
        </div>
        <div class="col" *ngIf="field.field_type==='date'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}" (click)="pickerN.open()">
            <mat-label>{{field.title}}</mat-label>
            <input matInput [matDatepicker]="pickerN" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [(ngModel)]="model.custom_fields[field.key]" [name]="field.key" autocomplete="off">
          </mat-form-field>
          <mat-datepicker #pickerN [startAt]="currentDate"></mat-datepicker>
        </div>
        <div class="col" *ngIf="field.field_type==='text'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title}}</mat-label>
            <input matInput [(ngModel)]="model.custom_fields[field.key]" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col" *ngIf="field.field_type==='number'">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title}}</mat-label>
            <input matInput type="number" [(ngModel)]="model.custom_fields[field.key]" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col file" *ngIf="field.field_type==='image'">
          <div class="title">{{field.title}}<span *ngIf="field.required && !bookingMode || field.require_booking && bookingMode">&nbsp;*</span></div>
          <app-image-uploader style="margin-bottom: 10px;" small="true" [photos]="model.custom_fields[field.key]" (uploaded)="addImages(field,$event)"></app-image-uploader>
          <input type="hidden" [(ngModel)]="model.custom_fields[field.key]" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key">
        </div>
        <div class="col" *ngIf="field.field_type==='users'">
          <div class="user-selector" (click)="setUser(field)">
            <div class="label" *ngIf="model.custom_fields[field.key]">{{field.title}}</div>
            <div class="title" [class.with-label]="model.custom_fields[field.key]">{{model.custom_fields[field.key]?.name || field.title}}<span *ngIf="field.required && !bookingMode || field.require_booking && bookingMode">&nbsp;*</span></div>
            <div class="selector">{{model.custom_fields[field.key]?.name || 'Seleccionar'}}</div>
            <input type="hidden" [placeholder]="field.title" [(ngModel)]="model.custom_fields[field.key]" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key" autocomplete="off">
          </div>
        </div>
        <div class="col" *ngIf="field.field_type==='list' && field.list">
          <ng-container *ngIf="field.list.list_items.length <= 10">
            <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
              <mat-label>{{field.title.split(',')[0]}}</mat-label>
              <mat-select [(ngModel)]="model.custom_fields[field.key]" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key" autocomplete="off" [multiple]="field.list.multiple" (ngModelChange)="setList(field,$event)">
                <mat-option *ngFor="let item of field.list.list_items" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="field.list.list_items.length > 10">
            <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
              <mat-label>{{field.title.split(',')[0]}}</mat-label>
              <input matInput [(ngModel)]="model.custom_fields[field.key]" [name]="field.key" [required]="field.required && !bookingMode || field.require_booking && bookingMode" autocomplete="off" [matAutocomplete]="autoModel" (ngModelChange)="searchList(field,$event);setList(field,$event)">
            </mat-form-field>
            <mat-autocomplete #autoModel="matAutocomplete" [displayWith]="displayItem(field.list.list_items)">
              <mat-option *ngFor="let item of filteredList[field.key]" [value]="item.id">{{item.name}}</mat-option>
            </mat-autocomplete>
          </ng-container>
        </div>
        <div class="col" *ngIf="sublist[field.key] && sublist[field.key].length <= 10 && field.title.split(',').length > 1">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title.split(',')[1] || field.title.split(',')[0]}}</mat-label>
            <mat-select [(ngModel)]="model.custom_fields[field.key+'_2']" [required]="field.required && !bookingMode || field.require_booking && bookingMode" [name]="field.key+'2'" autocomplete="off" [multiple]="field.list.multiple" (ngModelChange)="updated.emit()">
              <mat-option *ngFor="let item of sublist[field.key]" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col" *ngIf="sublist[field.key] && sublist[field.key].length > 10 && field.title.split(',').length > 1">
          <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
            <mat-label>{{field.title.split(',')[1] || field.title.split(',')[0]}}</mat-label>
            <input matInput [(ngModel)]="model.custom_fields[field.key+'_2']" [name]="field.key+'2'" [required]="field.required && !bookingMode || field.require_booking && bookingMode" autocomplete="off" [matAutocomplete]="autoModelB" (ngModelChange)="searchItem(field,$event)">
          </mat-form-field>
          <mat-autocomplete #autoModelB="matAutocomplete" [displayWith]="displayItem(sublist[field.key])">
            <mat-option *ngFor="let item of filteredSublist[field.key]" [value]="item.id">{{item.name}}</mat-option>
          </mat-autocomplete>
        </div>
        </ng-container>

    </div>
    <div class="extra" *ngIf="hiddenFields.length > 0" (click)="showHiddenFields()">
      <i class="fa fa-plus"></i> Agregar campos
    </div>
  </fieldset>
  <ng-container *ngIf="readonly">
    <div class="info" *ngFor="let field of fields; let first = first; let last = last" [class.last]="last" [class.first]="first" [hidden]="clientMode && !field.show_client">
      <div class="name">{{field.title}}</div>
      <div class="value" *ngIf="field.field_type === 'text'">{{model.custom_fields[field.key]}}</div>
      <div class="value" *ngIf="field.field_type === 'users'">{{model.custom_fields[field.key]?.name || 'Sin '+field.title}}</div>
      <div class="value" *ngIf="field.field_type === 'number'">{{model.custom_fields[field.key]}}</div>
      <div class="value" *ngIf="field.field_type === 'datetime'">{{model.custom_fields[field.key] | date:'short'}}</div>
      <div class="value" *ngIf="field.field_type === 'date'">{{model.custom_fields[field.key] | date:'shortDate'}}</div>
      <div class="value" *ngIf="field.field_type === 'boolean'" style="text-transform: capitalize">{{model.custom_fields[field.key]}}</div>
      <div class="value" *ngIf="field.field_type === 'list'">{{getListItem(field)}}</div>
      <div class="value" *ngIf="field.field_type === 'image'" style="text-transform: capitalize">
        <app-image-uploader style="margin-bottom: 10px;" [photos]="model.custom_fields[field.key]" [readonly]="true" small="true"></app-image-uploader>
      </div>
    </div>
  </ng-container>
</ng-container>
