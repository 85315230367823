import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/_base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DatabaseConnectionsService extends BaseService{
  constructor(
    public http:HttpClient,
  ) {
    super(http,null);
  }
  get(){
    return this.http.get(this.managerURL+'/database_connections',this.getParams());
  }
  create(value){
    return this.http.post(this.managerURL+'/database_connections',{
      manager_database_connection: this.permitParams(value,'manager_database_id','company_id')
    },this.getParams());
  }
  delete(id){
    return this.http.delete(this.managerURL+'/database_connections/'+id,this.getParams());
  }
}
