<ng-container [formGroup]="parentFormGroup">
  <div class="row">
    <div class="col" *ngIf="!hideOptionals || !identifierOptional">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <input
          matInput
          [placeholder]="companyService.getText('object_identifier')"
          [required]="!identifierOptional"
          [minlength]="country.preferences.vehicle_id_min"
          formControlName="identifier"
          name="identifier"
          autocomplete="off"
          [matAutocomplete]="autoPlate">
      </mat-form-field>
      <mat-autocomplete #autoPlate="matAutocomplete">
        <mat-option *ngFor="let option of plates" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
  <app-order-fields mode='Custom' [model]="model" [wrap]="false" [parentFormGroup]="parentFormGroup" [reactiveFormMode]="true" [outlinemode]="outlinemode"></app-order-fields>
</ng-container>
