<mat-card *ngIf="!selected">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Marcas</mat-card-title>
    <mat-card-subtitle>Administrar listado de Marcas y Modelos Permitidos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="brandsService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        <div class="click" (click)="select(item)"><i class="fa fa-plus"></i> {{item.name}}</div>
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
<app-admin-models *ngIf="selected" (close)="selected = null" [parentItem]="selected"></app-admin-models>
