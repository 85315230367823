<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-users fa-2x"></i></div>
    <mat-card-title>{{ this.companyService.getText('warehouse_plural') }}</mat-card-title>
    <mat-card-subtitle>Administre la información de los almacenes</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="warehousesService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formTemplate let-fform="form" let-value="value">
        <form [formGroup]="fform">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
          </mat-form-field>
          </form>
        </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
