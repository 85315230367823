import { NgModule } from '@angular/core';
import { MccColorPickerModule } from 'material-community-components/color-picker';

import { BaseModule } from '../base.module';
import { ImageUploaderModule } from '../image-uploader/image-uploader.module';
import { AdditionalsFormComponent } from './additionals-form/additionals-form.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { CrmsDialogComponent } from './crms-dialog/crms-dialog.component';
import { OrderFieldsComponent } from './custom-form/order-fields.component';
import { CustomObjectFormComponent } from './custom-object-form/custom-object-form.component';
import { HiddenFieldsComponent } from './hidden-fields/hidden-fields.component';
import { InventoryActionFormComponent } from './inventory-action-form/inventory-action-form.component';
import { KitsDialogComponent } from './kits-dialog/kits-dialog.component';
import { ObjectsDialogComponent } from './objects-dialog/objects-dialog.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { ProductsDialogComponent } from './products-dialog/products-dialog.component';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';
import { VehiclesDialogComponent } from './vehicles-dialog/vehicles-dialog.component';

@NgModule({
  declarations: [
    AdditionalsFormComponent,
    ClientFormComponent,
    CrmsDialogComponent,
    CustomObjectFormComponent,
    CustomObjectFormComponent,
    HiddenFieldsComponent,
    HiddenFieldsComponent,
    InventoryActionFormComponent,
    KitsDialogComponent,
    ObjectsDialogComponent,
    ObjectsDialogComponent,
    OrderFieldsComponent,
    ProductsDialogComponent,
    ProductFormComponent,
    VehicleFormComponent,
    VehiclesDialogComponent,
  ],
  imports: [
    BaseModule,
    ImageUploaderModule,
    MccColorPickerModule.forRoot({}),
  ],
  exports: [
    AdditionalsFormComponent,
    ClientFormComponent,
    CrmsDialogComponent,
    CustomObjectFormComponent,
    CustomObjectFormComponent,
    InventoryActionFormComponent,
    KitsDialogComponent,
    ObjectsDialogComponent,
    ObjectsDialogComponent,
    OrderFieldsComponent,
    ProductsDialogComponent,
    ProductFormComponent,
    VehicleFormComponent,
    VehiclesDialogComponent,
  ],
})
export class CommonFormsModule { }
