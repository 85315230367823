import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component, Inject, OnDestroy, OnInit, Optional,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusesService } from 'app/admin/services/statuses.service';
import { Client } from 'app/models/client.model';
import { Status } from 'app/models/status.model';
import { cloneDeep } from 'lodash-es';

import { AdminService } from '../../admin/services/admin.service';
import { BookingComponent } from '../../main/booking/booking.component';
import { ConfirmDialogComponent } from '../../main/confirm-dialog/confirm-dialog.component';
import { OrderComponent } from '../../main/order/order.component';
import { OrderNotificationsComponent } from '../../main/order-notifications/order-notifications.component';
import { RoleService } from '../../role/role.service';
import { CRMService } from '../crm.service';

@Component({
  selector: 'app-crm-client',
  templateUrl: './crm-client.component.html',
  styleUrls: ['./crm-client.component.scss'],
})
export class CrmClientComponent implements OnInit, OnDestroy {
  client: Client;

  company:any;

  company_logo:string;

  statuses: Status[] = [];

  forbidden = false;

  saving = false;

  clientForm = this.fb.group({
    rut: this.fb.control(''),
    name: this.fb.control(''),
    email: this.fb.control(''),
    phone: this.fb.control(''),
    address: this.fb.control(''),
    city: this.fb.control(''),
    custom_fields: this.fb.group({}),
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private clientService: CRMService,
    private adminService: AdminService,
    private router: Router,
    private statusesService: StatusesService,
    @Optional() private matDialogRef: MatDialogRef<CrmClientComponent>,
    public roleService: RoleService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.forEach((params) => {
      if (params.id) {
        this.load(params.id);
      } else {
        this.load(this.data.id);
      }
    });
    this.company_logo = this.adminService.local$.getValue().preferences.logo || 'assets/images/logo-anfruns.png';
    this.company = this.adminService.local$.getValue();
    this.statusesService.statuses('StatusForCrmForBooking').subscribe((statuses) => {
      this.statuses = statuses;
    });
  }

  ngOnDestroy(): void {
  }

  load(id) {
    this.clientService.get(id).toPromise().then((value: any) => {
      this.client = value.body;
      this.clientForm.patchValue(this.client);
    }).catch((error:HttpErrorResponse) => {
      if (error.status === 403) {
        this.forbidden = true;
      }
    });
  }

  goBack() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    } else {
      this.router.navigate(['main', 'crm', 'clients']);
    }
  }

  openOrder(order) {
    if (order.correlative_id) {
      this.dialog.open(OrderComponent, { data: order.id, minWidth: '80%' });
    } else {
      this.dialog.open(BookingComponent, { data: { id: order.id }, minWidth: '80%' });
    }
  }

  createBooking(vehicle) {
    this.dialog.open(BookingComponent, {
      minWidth: '80%',
      data: {
        object: vehicle,
        client: this.client,
      },
    });
  }

  saveClient() {
    this.clientService.update(this.client.id, { ...this.client }).toPromise().then((response) => {
      Object.assign(this.client, response.body);
    }).catch((error:HttpErrorResponse) => {
      console.log(error.error);
    });
  }

  startManagement() {
    this.dialog.open(
      ConfirmDialogComponent,
      { data: { title: 'Iniciar gestión' } },
    ).afterClosed().toPromise().then((result) => {
      if (result) {
        const status = this.statuses.filter((stat) => stat.initial)[0].id;
        // this.client.status_id = status;
        this.clientService.update(this.client.id, { ...this.client }).toPromise().then((response) => {
          Object.assign(this.client, response.body);
        }).catch((error:HttpErrorResponse) => {
          console.log(error.error);
        });
      }
    });
  }

  saveComment(value) {
    this.saving = true;
    this.clientService.update(this.client.id, { comments: [value] }).toPromise().then((response: HttpResponse<any>) => {
      // this.client.comments = response.body.comments;
      this.client = cloneDeep(this.client);
      this.saving = false;
    }).catch((error) => {
      console.log(error);
    });
  }

  setStatus(status: number) {
    this.dialog.open(
      ConfirmDialogComponent,
      { data: { title: 'Actualizar Estado' } },
    ).afterClosed().toPromise().then((result) => {
      if (result) {
        // this.client.status_id = status;
        this.clientService.update(this.client.id, { status_id: status }).toPromise().then((response) => {
          Object.assign(this.client, response.body);
        }).catch((error:HttpErrorResponse) => {
          console.log(error);
        });
      }
    });
  }

  openNotifications() {
    this.dialog.open(OrderNotificationsComponent, { data: { client_id: this.client.id } });
  }
}
