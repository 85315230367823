<h2 mat-dialog-title>{{'Buscador de Kit de Productos'}}</h2>
<ng-container [formGroup]="form">
  <mat-form-field>
    <mat-label>Kit</mat-label>
    <input
      matInput
      type="text"
      autocomplete="off"
      formControlName="search"
      [matAutocomplete]="autoName"
      placeholder="Busque por SKU"
      required
    >
    <mat-autocomplete #autoName="matAutocomplete" [displayWith]="displayProduct">
      <mat-option *ngFor="let kit of filteredKits$ | async" [value]="kit">
        {{ kit.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="buttons">
    <button
        mat-button
        mat-dialog-close
        >
      Cancelar
    </button>
    <button
        mat-raised-button
        color="accent"
        (click)="selectKit()"
        [disabled]="disabledButton()">
      Seleccionar
    </button>
  </div>
</ng-container>
