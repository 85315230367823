<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Kit - {{ 'Productos' }}</mat-card-title>
    <mat-card-subtitle>Administra los productos que formaran parte del Kit.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager
        [service]="kitProductsService"
        [itemTemplate]="itemTemplate"
        [formTemplate]="formTemplate"
        [formGroup]="form"
        [args]="[id]"
        (back)="goUp()"
        *ngIf="id"
        [showNavigation]="true"
    >
      <ng-template #itemTemplate let-item>
        @if (!item.error) {
          <b>[{{ item.code }}]</b> &nbsp; <span>{{ item.name }} ({{ item.quantity }} x {{ item.price | money }})</span>
        }
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Producto</mat-label>
            <input
              matInput
              type="text"
              formControlName="product_category_id"
              name="product_category_id"
              autocomplete="off"
              [matAutocomplete]="autoName"
              required
            >
            <mat-autocomplete #autoName="matAutocomplete" [displayWith]="displayProduct">
              <mat-option *ngFor="let product of filteredProducts$ | async" [value]="product.id">
                <div class="option-wrapper">
                  <div class="option-left">
                    <span><b>{{ product.name | titlecase }}</b></span>
                    <span class="option-sku">SKU: {{product.code}} &nbsp; Unidad: {{product.category.unit}}</span>
                  </div>
                  <div class="option-right">
                    <span>
                      {{product.price | money}}
                    </span>
                    <span class="option-brands" [matTooltip]="product.brands">
                      {{ product.brands | truncate: [20]}}
                    </span>
                    <span class="option-category">
                      {{product.category.name | titlecase }}
                    </span>
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input
              matInput
              type="number"
              formControlName="quantity"
              id="quantity"
              required
            >
          </mat-form-field>
          <br />
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
