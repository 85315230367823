import {NgModule} from '@angular/core';
import {SettingsComponent} from './settings.component';
import {WorkModule} from "../work/work.module";
import {BaseModule} from "../shared/base.module";
import { ImageCropperComponent } from 'ngx-image-cropper';
import { UploadPfpDialogComponent } from './upload-pfp-dialog/upload-pfp-dialog.component';

@NgModule({
  imports: [
    BaseModule,
    WorkModule,
    ImageCropperComponent,
  ],
  declarations: [
    SettingsComponent,
    UploadPfpDialogComponent
  ]
})
export class SettingsModule { }
