import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class CheckListsService extends BaseService implements ICRUDService{
  params = ['name','check_list_type','warn_requires_photo','danger_requires_photo']
  model = 'CheckList';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null,page = 1){
    return this.http.get(this.adminURL+'/check_lists',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/check_lists/',{
      check_list: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/check_lists/'+id,{
      check_list: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/check_lists/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/check_lists/'+id+'/restore',this.getParams());
  }
  all(){
    return this.http.get(this.adminURL+'/check_lists/all',this.getParams(
    ));
  }
  clone(id){
    return this.http.post(this.adminURL+'/check_lists/'+id+'/clone',{},this.getParams());
  }
}
