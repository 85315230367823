<div class="work-buttons-container">
  <div class="work-buttons">
    @for (action of customActions; track action.action) {
      <button mat-button (click)="close(action.action)">
        <div class="button-content">
          <mat-icon class="button-icon">add</mat-icon>
          <span class="button-text">{{ action.name }}</span>
        </div>
      </button>
    }
    <button mat-button *ngFor="let work of works; ;let even = even" [ngClass]="{ 'even': even }" (click)="createWork(work.id)">
      <div class="button-content">
        <mat-icon class="button-icon">add</mat-icon>
        <span class="button-text">{{ work.name }}</span>
      </div>
    </button>
  </div>
</div>
