import {debounceTime, first} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RoleService} from "../role/role.service";
import {CalendarEvent} from "../models/types";
import {interval, Subject, Subscription} from "rxjs";
import moment from "moment";
import {CalendarEventTimesChangedEvent, CalendarMonthViewDay} from "angular-calendar";
import {AdminService} from "../admin/services/admin.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {EventService} from "./event-service.service";
import {EventDialogComponent} from "./event-dialog/event-dialog.component";
import {UntypedFormControl} from "@angular/forms";
import {OrderComponent} from "../main/order/order.component";
import {range} from "lodash-es";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  month = moment().toDate();
  events: CalendarEvent[] = [];
  monthDays: number[];
  data: any = {

  };
  type = localStorage.getItem('calendar_type');
  display = 'month';
  now = moment();
  dayTotal = 0;
  dayCurrent = 0;
  dayEvents = [];
  working = false;
  currentDate = moment().format('YYYY-MM-DD');
  dates = [];
  dateNames = [];
  excludeDays = [];
  searchControl: UntypedFormControl = new UntypedFormControl();
  findings = [];
  startHour = null
  startMinute = null
  endHour = null
  endMinute = null
  selectedCalendar: string = 'Producción';
  subs = [];
  refresh: Subject<any> = new Subject();
  private dataInterval: Subscription;
  eventTimesChanged({
                      event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    // this.handleEvent('Dropped or resized', event);
    this.refresh.next(0);
  }
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if(this.data.holydays){
        if(this.isHoliday(day.date)){
          day.cssClass = 'holiday';
        }else{
          day.cssClass = '';
        }
      }
    });
  }
  constructor(
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AdminService,
    private eventService: EventService,
    private adminService: AdminService,
    public roleService: RoleService,
  ) {
    if(!this.type){
      localStorage.setItem('calendar_type','deliveries')
    }
  }

  ngOnInit() {
    this.appService.pageTitle.set(this.appService.getText('calendar'));
    this.route.queryParams.pipe(first()).forEach((params) => {
      this.subs.push(this.appService.local$.subscribe(()=>{
        this.events = [];
        this.setWeekends();
        this.getData();
        this.setDates();
      }))
      
    });
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((identifier) => {
      this.highlightBookings(identifier);
    }));
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  setCalendar(event?: string): void {
    if (event) {
      this.selectedCalendar = event === 'deliveries' ? 'Producción' : 'Eventos';
      this.type = event;
      localStorage.setItem('calendar_type', event);
    }
    
    this.toEvents();
    if (this.display === 'day') {
      this.setDayEvents();
    }
    this.refresh.next(0);
  }


  setDates() {
    this.dateNames = [this.now.format('YYYY-MM-DD')];
    this.dates = [this.now.format('YYYY-MM-DD')];
    this.monthDays = range(1, this.now.daysInMonth() + 1);
    this.month = this.now.toDate();
  }
  getData(search?) {
    this.working = true;
    if(this.dataInterval){
      this.dataInterval.unsubscribe();
    }
    this.dataInterval = interval(200).subscribe(()=>{
      this.dataInterval.unsubscribe();
      let start_at, end_at;
      start_at = moment(this.now).startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
      end_at = moment(this.now).endOf('month').add(7, 'days').format('YYYY-MM-DD');
      this.eventService.all(start_at, end_at, search).toPromise().then((values) => {
        this.data = values.body;
        this.setCalendar();
        this.working = false;
      }).catch((error:HttpErrorResponse) => {
        console.log(error);
      });
    });

  }
  setDayEvents(){
    this.dayEvents = this.events.filter(((value, index) => {
      return this.now.format('YYYY-MM-DD') === moment(value.start).format('YYYY-MM-DD');
    })).sort((a, b) => {
      if(a.allDay && b.allDay){ return 0;}
      if(a.allDay && !b.allDay){ return 1;}
      if(!a.allDay && b.allDay){ return -1;}
    });
    this.dayTotal = this.dayEvents.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.meta.order_total || 0);
    },0);
    this.dayCurrent = this.dayEvents.filter((e)=>e.meta.ended_at!=null).reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.meta.order_total || 0);
    },0);
  }
  highlightBookings(event){
    let start_at, end_at;
    start_at = moment(this.now).startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
    end_at = moment(this.now).endOf('month').add(7, 'days').format('YYYY-MM-DD');
    if(event && event.length >=2){
      this.eventService.all(start_at, end_at, event).toPromise().then((values:HttpResponse<any>) => {
        this.findings = values.body.events.concat(values.body.deliveries);
      }).catch((error:HttpErrorResponse) => {
        console.log(error);
      });
    }else{
      this.findings = [];
    }
  }
  isHoliday(date){
    if(this.data.holydays){
      const fdate = moment(date).format('YYYY-MM-DD');
      const index = this.data.holydays.findIndex((v)=>v.date === fdate);
      return index >= 0;
    }else{
      return false;
    }
  }
  openMonth() {
    if(this.display !== 'month'){
      this.display = 'month';
      this.getData();
    }
  }
  openDay(date) {
    if(!this.isHoliday(date.day.date)){
      this.now = moment(date.day.date.toISOString());
      this.setDates();
      this.display = 'day';
      this.setDayEvents();
    }
  }
  previous() {
    if(this.display==='week'){
      this.now = moment(this.now).subtract(1, 'week');
    }else if(this.display==='month'){
      this.now = moment(this.now).subtract(1, 'month');
    }else{
      this.now = moment(this.now).subtract(1, 'day');
    }
    this.setDates();
    this.getData();
  }
  next() {
    if(this.display==='week'){
      this.now = moment(this.now).add(1, 'week');
    }else if(this.display==='month'){
      this.now = moment(this.now).add(1, 'month');
    }else{
      this.now = moment(this.now).add(1, 'day');
    }
    this.setDates();
    this.getData();
  }
  createNewEvent(){
    this.matDialog.open(EventDialogComponent).afterClosed().pipe(first()).forEach((value)=>{
      this.getData();
    });
  }
  eventClick(event){
    if(event.event.meta.finish_date || event.event.meta.predictive_data){
      this.matDialog.open(OrderComponent,{data: event.event.meta.id});
    }else{
      this.matDialog.open(EventDialogComponent,{data: event.event.meta}).afterClosed().pipe(first()).forEach((value)=>{
        this.getData();
      });
    }
  }
  transformDay(index){
    // index = 0 Monday index 6 = Sunday
    // to = 0 Sunday 1 Monday
    if(index + 1>6){
      return 0;
    }else{
      return index+1;
    }
  }
  setWeekends(){
    const days:any[] = this.appService.local$.getValue().days_preferences.days;
    const notDays = [];
    days.forEach((value,index) =>{
      if(value === false){
        notDays.push(index);
      }else if(value !== true && !value.enabled){
        notDays.push(index);
      }
    });
    this.excludeDays = notDays.map((value)=>this.transformDay(value));
  }
  toEvents() {
    const events: CalendarEvent[] = [];
    if(this.type === 'deliveries'){
      for (const delivery of this.data.deliveries){
        let startDate = null;
        let endDate = null;
        let color = 'gray';
        const count = delivery.finish_reschedule_count;
        let countText = "";
        if(count > 0){
          countText = `(${count}) `;
          color = '#D4D300';
        }
        let name = "Finalización agendada "+countText+delivery.identifier;
        if(delivery.finish_date && !delivery.completed_at){
          startDate = moment(delivery.finish_date).toDate();
        }else if(delivery.completed_at && delivery.finish_date){
          const format_completed_at = moment(delivery.completed_at).format('YYYY-MM-DD');
          const format_finish_date = moment(delivery.finish_date).format('YYYY-MM-DD')
          if(format_completed_at===format_finish_date){
            color = 'green';
            name = 'Finalizado a tiempo. '+countText+delivery.identifier;
            startDate = moment(delivery.completed_at).toDate();
            endDate = moment(delivery.completed_at).toDate();
            if(count > 0){
              color = 'yellowgreen';
            }
          }else if(format_completed_at > format_finish_date){
            color = 'orange';
            name = 'Finalizado atrasado. '+countText+delivery.identifier;
            startDate = moment(delivery.completed_at).toDate();
            endDate = moment(delivery.completed_at).toDate();
            if(count > 0){
              color = '#FF9E00';
            }
          }else if(format_finish_date > format_completed_at){
            color = 'blue';
            name = 'Finalizado antes. '+countText+delivery.identifier;
            startDate = moment(delivery.completed_at).toDate();
            endDate = moment(delivery.completed_at).toDate();
            if(count > 0){
              color = '#809A56';
            }
          }
        }else if(delivery.completed_at){
          color = 'green';
          name = 'Finalizado. '+delivery.identifier;
          startDate = moment(delivery.completed_at).toDate();
          endDate = moment(delivery.completed_at).toDate();
        }else if(delivery.ended_at){
          color = 'green';
          name = 'Entregado. '+delivery.identifier;
          startDate = moment(delivery.ended_at).toDate();
          endDate = moment(delivery.ended_at).toDate();
        }
        events.push({
          start: startDate,
          title: name,
          color: {
            primary: color
          },
          allDay: true,
          resizable: {
            beforeStart: false,
            afterEnd: false
          },
          draggable: false,
          meta: delivery
        });
      }
    }
    else{
      for (const event of this.data.events) {
        const toDate = moment(event.start_date).toDate();
        let color = '#67189d';
        if(event.eventable_type === 'Order'){
          color = '24989D';
        }
        
        const finish = moment(event.start_date).add(event.duration,'hours').toDate();
        let name = event.name;
        if(event.eventable && event.eventable.vehicle){
          name = event.eventable.vehicle.identifier + ": " + name;
        }
        events.push({
          start: toDate,
          end: finish,
          title: name,
          color: {
            primary: color
          },
          allDay: false,
          resizable: {
            beforeStart: false,
            afterEnd: false
          },
          draggable: false,
          meta: event
        });
      }
    }
    this.events = events;
  }
}
