import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  FormArray, FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { InventoryService } from 'app/inventory/inventory.service';
import { displayProduct, Product } from 'app/models/product.model';
import { Warehouse } from 'app/models/warehouse.model';
import { InventoryActionService } from 'app/services/inventory-action.service';
import { ProductsService } from 'app/services/products.service';
import { WarehousesService } from 'app/services/warehouses.service';
import { toUnderscore } from 'app/utils/string-utils';
import {
  debounceTime, filter, map, Observable, Subject, switchMap, takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-inventory-action-form',
  templateUrl: './inventory-action-form.component.html',
  styleUrls: ['./inventory-action-form.component.scss'],
})
export class InventoryActionFormComponent implements OnInit, OnDestroy {
  filteredProducts$: Observable<Product[]>[];

  form: FormGroup = this.fb.group({
    estimated_completion_date: [null, Validators.required],
    inventory_action_products_attributes: this.fb.array(this.generateProductsControls()),
    warehouse_id: [null, Validators.required],
  });

  inventory;

  inventoryActionType: string;

  title: string;

  unsubscribe$: Subject<boolean> = new Subject();

  displayProduct = displayProduct;

  warehouses$: Observable<Warehouse[]>;

  constructor(
    private fb: FormBuilder,
    private inventoryActionService: InventoryActionService,
    private inventoryService: InventoryService,
    private matDialogRef: MatDialogRef<InventoryActionFormComponent>,
    private warehousesService: WarehousesService,
    public adminService: AdminService,
    public productsService: ProductsService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {
    if (this.data.edit) {
      this.inventoryActionType = this.data.inventoryAction.type;
      this.form.patchValue({
        estimated_completion_date: this.data.inventoryAction.estimated_completion_date || new Date(),
        warehouse_id: this.data.inventoryAction.warehouse_id,
        inventory_action_products_attributes: this.data
          .inventoryAction
          .inventory_action_products
          .map((pr) => ({
            id: pr.id,
            product_id: { id: pr.product_id, name: pr.product.name, code: pr.product.code },
            quantity: pr.quantity,
          })),
      });
    } else {
      this.inventoryActionType = this.data.inventoryActionType;
    }

    this.inventoryService.all().subscribe((data) => {
      this.inventory = data.reduce((mapping, obj) => ({ ...mapping, [obj.product.id]: obj }), {});
    });

    this.title = this.adminService.getText(toUnderscore(this.inventoryActionType));
    this.setFilteredProducts();
    this.warehouses$ = this.warehousesService.index();
  }

  setFilteredProducts() {
    this.filteredProducts$ = this.inventoryActionProducts.controls.map(
      (control) => control.get('product_id').valueChanges.pipe(
        filter((value) => value.length > 2),
        takeUntil(this.unsubscribe$),
        debounceTime(300),
        switchMap((searchValue) => this.productsService.select(searchValue).pipe(map((data) => data.items))),
      ),
    );
  }

  addProductControl(quantity = 1) {
    for (let i = 0; i < quantity; i += 1) {
      (<FormArray> this.form.controls.inventory_action_products_attributes).push(this.buildProductControl());
    }
    this.setFilteredProducts();
  }

  removeProductControl(index: number) {
    (<FormArray> this.form.controls.inventory_action_products_attributes).removeAt(index);
    this.setFilteredProducts();
  }

  private setFormDataForSending() {
    this.form.get('inventory_action_products_attributes').setValue(
      this.form.get('inventory_action_products_attributes').value.map(
        (product) => ({
          ...product,
          id: null,
          product_id: product.product_id.id,
          warehouse_id: this.form.get('warehouse_id').value,
        }),
      ),
    );
  }

  saveForm() {
    this.setFormDataForSending();

    this.inventoryActionService
      .create({
        inventory_action: {
          ...this.form.getRawValue(),
          type: this.inventoryActionType,
        },
      })
      .subscribe(() => {
        this.matDialogRef.close(true);
      });
  }

  editInventoryActionInstantly() {
    this.setFormDataForSending();

    this.inventoryActionService
      .update(this.data.inventoryAction.id, {
        ...this.form.getRawValue(),
        type: this.inventoryActionType,
      })
      .subscribe(() => {
        this.matDialogRef.close(true);
      });
  }

  editInventoryAction() {
    this.setFormDataForSending();

    this.inventoryActionService
      .edit(this.data.inventoryAction.id, {
        ...this.form.getRawValue(),
        type: this.inventoryActionType,
      })
      .subscribe(() => {
        this.matDialogRef.close(true);
      });
  }

  isValidProduct(index: number) {
    const productId = this.form.get('inventory_action_products_attributes').value[index].product_id?.id;
    const { quantity } = this.form.get('inventory_action_products_attributes').value[index];
    if (this.type === 'Consume' && this.inventory && productId) {
      const availableStock = this.inventory[productId];

      return availableStock && availableStock.available_for_sale >= quantity;
    }

    return true;
  }

  get type() {
    return this.inventoryActionType.split('InventoryActionFor')[1];
  }

  get inventoryActionProducts(): FormArray {
    return this.form.get('inventory_action_products_attributes') as FormArray;
  }

  private buildProductControl(
    formValue = {
      id: null,
      product_id: null,
      quantity: 1,
      warehouse_id: null,
    },
  ) {
    return this.fb.group({
      id: new FormControl(formValue.id),
      product_id: new FormControl(formValue.product_id, Validators.required),
      quantity: new FormControl(formValue.quantity, [Validators.required, Validators.min(1)]),
      warehouse_id: null,
    });
  }

  private generateProductsControls() {
    if (this.data.edit) {
      return this.data
        .inventoryAction
        .inventory_action_products
        .map(() => this.buildProductControl());
    }
    return [this.buildProductControl()];
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
