import { NgModule } from '@angular/core';
import { CommonFormsModule } from './common-forms/common-forms.module';
import { BaseModule } from "app/shared/base.module";

@NgModule({
  imports: [
    BaseModule,
    CommonFormsModule,
  ],
  declarations: [

  ],
  exports: [
    CommonFormsModule,
  ]
})
export class SharedModule { }
