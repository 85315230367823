<div class="header">
  <i class="fa fa-plus-circle"></i> <div class="text">Productos (Total: {{getTotal() | money}})</div>
</div>
<div class="wrapper">
  <div class="content">
    <form [formGroup]="form">
      <fieldset formArrayName="order_items">
        <div class="item" *ngFor="let item of formItems.controls; let index = index" [formGroup]="item">
          <mat-form-field class="number">
            <input matInput placeholder="Cantidad" type="number" required formControlName="item_quantity" autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Nombre" required formControlName="item_name">
          </mat-form-field>
          <mat-form-field >
            <input matInput placeholder="Codigo Producto" formControlName="item_description">
          </mat-form-field>
          <mat-form-field class="number" [hidden]="disabled">
            <input matInput placeholder="Precio UNITARIO" type="number" formControlName="item_price">
          </mat-form-field>
          <span *ngIf="disabled" class="value" matTooltip="Precio Unitario: {{item.controls['item_price'].value | money}}">{{item.controls['item_price'].value*item.controls['item_quantity'].value | money}}</span>
          <mat-checkbox formControlName="item_available" [hidden]="disabled"></mat-checkbox>
          <button mat-button color="primary" *ngIf="disabled && added[index] && !clientMode"><i class="fa fa-check"></i></button>
          <button mat-button color="accent" *ngIf="disabled && !added[index] && !clientMode" (click)="addToOrder(item,index)" matTooltip="Agregar Producto a orden"><i class="fa fa-cart-plus"></i></button>
          <button mat-button color="warn" *ngIf="!disabled && !clientMode" (click)="removeItem(index)"><i class="fa fa-trash"></i></button>
        </div>
      </fieldset>
      <span (click)="addItem()" class="link subtitle" *ngIf="!disabled"><i class="fa fa-plus"></i> Agregar otro item</span>
    </form>
  </div>
</div>
