import { Injectable } from '@angular/core';
import { InventoryActionProduct } from 'app/models/inventory-action-product';
import { PaginatedItems } from 'app/models/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from './_base.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryActionProductsService extends BaseService {
  index(
    productId: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PaginatedItems<InventoryActionProduct>> {
    return this.http
      .get<PaginatedItems<InventoryActionProduct>>(
      `${this.apiURL}/inventory_action_products`,
      this.getOptions({ page: pageNumber, page_size: pageSize, product_id: productId }),
    )
      .pipe(
        map((response) => response.body),
      );
  }
}
