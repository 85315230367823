import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { AdminService } from 'app/admin/services/admin.service';

@Component({
  selector: 'app-objects-dialog',
  templateUrl: './objects-dialog.component.html',
  styleUrls: ['./objects-dialog.component.scss']
})
export class ObjectsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public objects,
    private matDialogRef: MatDialogRef<ObjectsDialogComponent>,
    public adminService: AdminService
  ) {

  }
  ngOnInit() {

  }
  selectObject(object: any) {
    this.matDialogRef.close(object);
  }

}
