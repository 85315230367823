import { Route } from '@angular/router';

import { InventoryActionsComponent } from './actions/inventory-actions.component';
import { InventoryProductDetailsComponent } from './products/details/inventory-product-details.component';
import { InventoryProductsComponent } from './products/inventory-products.component';

export const inventoryRoutes: Route[] = [
  { path: 'products/:id', component: InventoryProductDetailsComponent },
  { path: 'products', component: InventoryProductsComponent },
  { path: 'actions', component: InventoryActionsComponent },

  { path: '**', redirectTo: 'products' },
];
