import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';

import { CameraService } from '../camera/camera.service';
import { ImageUploaderComponent } from '../image-uploader.component';

@Component({
  selector: 'app-advanced-image-uploader',
  templateUrl: './advanced-image-uploader.component.html',
  styleUrls: ['./advanced-image-uploader.component.scss'],
})
export class AdvancedImageUploaderComponent extends ImageUploaderComponent implements OnInit, AfterViewInit {
  // INPUTS CONFIG
  @Input() photos:any[any] = [];

  @Input() selectedImage:number = 0;

  @Input() tags = [{
    id: 0,
    name: 'base',
    color: 'red',
  }];

  @Input() selectedTag = 0;

  @Input() actions:any = {
    edit_markers: true,
    edit_photos: true,
  };

  @Output() close = new EventEmitter();

  // ViewChildren
  @ViewChild('imageZoom', { static: true }) imageZoom;

  @ViewChild('rightPanel', { static: true }) rightPanel;

  // Statuses
  drag = false;

  rectangleDrag = false;

  mode = 0;

  DRAG_MODE = 0;

  MARKER_MODE = 1;

  ERASER_MODE = 2;

  VECTOR_MODE = 3;

  swipe:TouchEvent;

  loading = false;

  // Values
  size = 100;

  offset = { x: 0, y: 0 };

  BASE_CANVAS = 1000;

  private backgroundImage: HTMLImageElement;

  constructor(
    @Optional() private ref: MatDialogRef<AdvancedImageUploaderComponent>,
    private route: ActivatedRoute,
    private router: Router,
    matDialog:MatDialog,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    cameraService: CameraService,
    sanitizer: DomSanitizer,
  ) {
    super(matDialog, snackBar, ngZone, cameraService, sanitizer);
  }

  ngOnInit(): void {
    this.uploaded.subscribe((photos) => {
      this.selectedImage = photos.length - 1;
    });
    this.preloadImages();
  }

  preloadImages() {
    this.photos.forEach((p) => {
      const img = new Image();
      img.src = (p.new_url || p.url);
    });
  }

  saveImages() {
    this.ngZone.run(() => {
      this.photos = cloneDeep(this.photos);
      this.close.emit(this.blobs);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateMarkers();
    }, 0);
    window.addEventListener('resize', () => {
      this.calculateMarkers();
    });
    this.imageZoom.nativeElement.addEventListener('wheel', (event) => {
      if (event.target === this.imageZoom.nativeElement) {
        switch (this.mode) {
          case this.DRAG_MODE:
            // this.scroll(event);
        }
      }
    });
    this.imageZoom.nativeElement.addEventListener('mousedown', (event:MouseEvent) => {
      if (event.target === this.imageZoom.nativeElement) {
        switch (this.mode) {
          case this.DRAG_MODE:
            this.startDrag(event);
            break;
          case this.MARKER_MODE:
            this.setMarker(event);
            break;
          case this.VECTOR_MODE:
            this.rectangleDrag = true;
            this.setMarker(event);
            break;
        }
      }
    });
    this.imageZoom.nativeElement.addEventListener('touchstart', (event) => {
      if (event.target === this.imageZoom.nativeElement) {
        switch (this.mode) {
          case this.DRAG_MODE:
            this.startSwipe(event);
            break;
        }
      }
    });
    this.imageZoom.nativeElement.addEventListener('touchend', (event) => {
      if (event.target === this.imageZoom.nativeElement) {
        switch (this.mode) {
          case this.DRAG_MODE:
            this.endSwipe(event);
            break;
        }
      }
    });
    this.imageZoom.nativeElement.addEventListener('touchmove', (event) => {
      if (event.target === this.imageZoom.nativeElement) {
        switch (this.mode) {
          case this.DRAG_MODE:
            this.setSwipe(event);
            break;
        }
      }
    });

    this.imageZoom.nativeElement.addEventListener('mouseup', (event) => {
      if (event.target === this.imageZoom.nativeElement) {
        switch (this.mode) {
          case this.DRAG_MODE:
            this.stopDrag(event);
            break;
          case this.VECTOR_MODE:
            this.stopRectangleDrag(event);
            break;
        }
      }
    });
    this.imageZoom.nativeElement.addEventListener('mousemove', (event) => {
      switch (this.mode) {
        case this.DRAG_MODE:
          this.dragImage(event);
          break;
        case this.VECTOR_MODE:
          this.dragMarker(event);
          break;
      }
    });
  }

  getClass() {
    if (this.drag) {
      return 'image-placeholder grabbing';
    }
    if (this.DRAG_MODE === this.mode) {
      return 'image-placeholder grab';
    }
    if (this.MARKER_MODE === this.mode) {
      return 'image-placeholder crosshair';
    } if (this.VECTOR_MODE === this.mode) {
      return 'image-placeholder crosshair';
    }
    return 'image-placeholder';
  }

  downloadImage() {
    if (this.photos.length > 0) {
      const a = document.createElement('a');
      a.href = this.photos[this.selectedImage].url;
      a.target = '_blank';
      a.download = this.photos[this.selectedImage].url;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  backgroundSize() {
    const pos = `scale(${this.size / 100},${this.size / 100}) translateY(${this.offset.y}px) translateX(${this.offset.x}px)`;
    return this.sanitizer.bypassSecurityTrustStyle(pos);
  }

  setMode(mode) {
    this.mode = mode;
    this.drag = false;
  }

  handleMarkerClick(marker) {
    if (this.photos[this.selectedImage] && this.photos[this.selectedImage].markers) {
      const index = this.photos[this.selectedImage].markers.findIndex((m) => m === marker);
      if (index >= 0) {
        if (this.mode === this.ERASER_MODE) {
          const markers = cloneDeep(this.photos[this.selectedImage].markers);
          this.photos[this.selectedImage].markers.splice(index, 1);
          markers[index]._destroy = true;
        }
      }
    }
  }

  selectImage(index) {
    this.selectedImage = index;
    this.calculateMarkers();
  }

  calculateMarkers() {
    this.loading = true;
    if (this.photos[this.selectedImage] && this.photos[this.selectedImage].markers) {
      this.backgroundImage = new Image();
      this.backgroundImage.src = this.photos[this.selectedImage].url;
      this.backgroundImage.removeEventListener('load', null);
      this.backgroundImage.addEventListener('load', (ev) => {
        this.photos[this.selectedImage].markers.forEach((marker) => {
          marker.calculated_x = this.sanitizer.bypassSecurityTrustStyle(`calc(50% + ${(marker.x) * this.getDeltaSize()}px)`);
          marker.calculated_y = this.sanitizer.bypassSecurityTrustStyle(`calc(50% + ${(marker.y) * this.getDeltaSize()}px)`);
          if (marker.width > 0 && marker.height > 0) {
            marker.calculated_width = this.sanitizer.bypassSecurityTrustStyle(`${(marker.width - 4) * this.getDeltaSize()}px`);
            marker.calculated_height = this.sanitizer.bypassSecurityTrustStyle(`${(marker.height - 4) * this.getDeltaSize()}px`);
          }
          const index = this.tags.findIndex((value) => value.id === marker.tag_id);
          if (index >= 0) {
            marker.color = this.tags[index].color;
            if (marker.marker_type === 'rectangle') {
              marker.background = this.tags[index].color;
            }
          }
        });
        this.setMarkerNumbers();
        this.loading = false;
        this.backgroundImage.removeEventListener('load', null);
      });
    }
  }

  setMarkerNumbers() {
    let marker_number = 0;
    this.photos[this.selectedImage].markers.forEach((marker) => {
      marker_number += 1;
      marker.number = marker_number;
    });
  }

  setOffset(x, y) {
    this.offset.x = x;
    this.offset.y = y;
  }

  getDeltaSize() {
    if (!this.backgroundImage) {
      this.backgroundImage = new Image();
      this.backgroundImage.src = this.photos[this.selectedImage].url;
    }
    const propBackground = this.backgroundImage.width / this.backgroundImage.height;
    const propClient = this.imageZoom.nativeElement.clientWidth / this.imageZoom.nativeElement.clientHeight;
    if (propClient > propBackground) {
      return this.imageZoom.nativeElement.clientHeight * propBackground / this.BASE_CANVAS;
    }
    return this.imageZoom.nativeElement.clientWidth / this.BASE_CANVAS;
  }

  getCanvasCenter() {
    const canvas = this.imageZoom.nativeElement;
    return { x: canvas.clientWidth / 2, y: canvas.clientHeight / 2 };
  }

  scroll(event:WheelEvent) {
    event.preventDefault();
    this.size = Math.round(this.size - event.deltaY / 2);
    if (this.size < 30) {
      this.size = 30;
      this.setOffset(0, 0);
    } else if (this.size > 300) {
      this.size = 300;
      this.setOffset(0, 0);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleImage(event) {
    if (event && event.code === 'Escape') {
      this.close.emit(this.blobs);
    } else if (event && event.code === 'ArrowLeft') {
      this.prevImage();
    } else if (event && event.code === 'ArrowRight') {
      this.nextImage();
    } else {
      // DO NOTHING
    }
  }

  prevImage() {
    if (this.photos && this.photos.length >= 0) {
      if (this.selectedImage === 0) { this.selectedImage = this.photos.length - 1; } else {
        this.selectedImage--;
      }
      this.calculateMarkers();
    }
  }

  nextImage() {
    if (this.photos && this.photos.length >= 0) {
      if (this.selectedImage === this.photos.length - 1) {
        this.selectedImage = 0;
      } else {
        this.selectedImage++;
      }
      this.calculateMarkers();
    }
  }

  zoom(event:MouseEvent) {
    /* const canvas_center = this.getCanvasCenter();
    const x = -(event.clientX - canvas_center.x - this.offset.x*this.getDeltaSize())/this.getDeltaSize();
    const y = -(event.clientY - canvas_center.y - this.offset.y*this.getDeltaSize())/this.getDeltaSize();
    this.setOffset(x,y);
    if(this.size === 300){

    }else{
      this.size = 100;
    }

    */
    this.size = 100;
    this.setOffset(0, 0);
    this.calculateMarkers();
  }

  startDrag(event:MouseEvent) {
    if (event.button === 0) {
      this.drag = true;
    }
  }

  stopDrag(event:MouseEvent) {
    if (event.button === 0) {
      this.drag = false;
      this.calculateMarkers();
    }
  }

  startSwipe(event) {
    if (event.touches.length === 1) {
      this.swipe = event;
    }
  }

  endSwipe(event) {
    if (this.swipe && this.swipe.touches.length === 1) {
      const diff = this.swipe.touches[0].clientX - event.changedTouches[0].clientX;
      const treshold = this.imageZoom.nativeElement.clientWidth * 0.4;
      if (diff > treshold) {
        this.nextImage();
      } else if (diff < -treshold) {
        this.prevImage();
      } else {
        this.imageZoom.nativeElement.style.transition = 'ease-out 0.3s';
      }
      this.setOffset(0, 0);
      this.swipe = null;
      setTimeout(() => {
        this.imageZoom.nativeElement.style.transition = null;
      }, 300);
    }
  }

  setSwipe(event) {
    if (this.swipe && this.swipe.touches.length === 1) {
      const diff = this.swipe.touches[0].clientX - event.touches[0].clientX;
      this.setOffset(-diff, 0);
    }
  }

  stopRectangleDrag(event:MouseEvent) {
    if (event.button === 0) {
      this.rectangleDrag = false;
    }
  }

  dragImage(event:MouseEvent) {
    if (this.drag) {
      this.setOffset(this.offset.x + event.movementX, this.offset.y + event.movementY);
    }
  }

  dragMarker(event:MouseEvent) {
    if (this.rectangleDrag) {
      const { length } = this.photos[this.selectedImage].markers;
      const marker = this.photos[this.selectedImage].markers[length - 1];
      this.photos[this.selectedImage].markers[length - 1].width += event.movementX / this.getDeltaSize();
      this.photos[this.selectedImage].markers[length - 1].height += event.movementY / this.getDeltaSize();
      const cw = this.sanitizer.bypassSecurityTrustStyle(`${marker.width * this.getDeltaSize() - 4}px`);
      const ch = this.sanitizer.bypassSecurityTrustStyle(`${marker.height * this.getDeltaSize() - 4}px`);
      this.photos[this.selectedImage].markers[length - 1].calculated_width = cw;
      this.photos[this.selectedImage].markers[length - 1].calculated_height = ch;
    }
  }

  setMarker(event:MouseEvent) {
    if (event.button === 0 && this.actions.edit_markers) {
      if (this.photos[this.selectedImage]) {
        const canvas_center = this.getCanvasCenter();
        const x = (event.clientX - canvas_center.x - this.offset.x) / this.getDeltaSize();
        const y = (event.clientY - canvas_center.y - this.offset.y) / this.getDeltaSize();
        if (!this.photos[this.selectedImage].markers) {
          this.photos[this.selectedImage].markers = [];
        }
        const marker:any = {
          x,
          y,
          width: 0,
          height: 0,
          marker_type: this.getMarkerClass(),
          tag_id: this.selectedTag,
          canvas_width: this.imageZoom.nativeElement.clientWidth,
          canvas_height: this.imageZoom.nativeElement.clientHeight,
        };
        this.photos[this.selectedImage].markers.push(marker);
      }
      this.calculateMarkers();
    }
  }

  getMarkerClass() {
    if (this.MARKER_MODE === this.mode) {
      return 'marker';
    } if (this.VECTOR_MODE === this.mode) {
      return 'rectangle';
    }
  }
}
