import {Component, OnInit} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatDialog} from "@angular/material/dialog";
import {DercoMailsDialogComponent} from "./derco-mails-dialog/derco-mails-dialog.component";
import {UserService} from "../../services/user.service";
import { AdminService } from 'app/admin/services/admin.service';
import { BookingService } from 'app/services/booking.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-derco-mails',
  templateUrl: './derco-mails.component.html',
  styleUrls: ['./derco-mails.component.scss']
})
export class DercoMailsComponent implements OnInit {

  not_readed_count = 0;
  mails = [];
  constructor(
    private matDialog: MatDialog,
    private bookingService: BookingService,
  ) {

  }

  ngOnInit() {
    this.load();
  }
  load(){
    this.bookingService.externalMails().pipe(first()).subscribe((mails:any) => {
      this.mails = mails.body;
      this.not_readed_count = this.mails.filter((m) => !m.readed).length;
    });

  }
  openDialog() {
    this.matDialog.open(DercoMailsDialogComponent, {
      width: '80%',
      data: this.mails
    }).afterClosed().subscribe(() => {
      this.load();
    });
  }

}
