import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WorkDialogComponent} from "../../work/work-dialog/work-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {RoleService} from "../../role/role.service";
import {first} from "rxjs/operators";
import {AdminService} from '../../admin/services/admin.service';


@Component({
  selector: 'app-order-works',
  templateUrl: './order-works.component.html',
  styleUrls: ['./order-works.component.scss']
})
export class OrderWorksComponent implements OnInit {
  @Input() order:any = {works: []};
  @Input() works = [];
  @Output() load = new EventEmitter();
  requirementFirstLetter: string;
  constructor(
    private matDialog: MatDialog,
    private roleService: RoleService,
    public adminService: AdminService
  ) {
    }

  ngOnInit() {
    const text = this.adminService.getText('requirement');
    this.requirementFirstLetter = text && text.length > 0 ? text.charAt(0).toUpperCase() : '';
  }
  openWork(work){
    this.roleService.can('read','work').pipe(first()).forEach((value)=>{
      if(value){
        this.matDialog.open(WorkDialogComponent,{data:
            {id: work.id, type: work.type}, minWidth: '80%'
        }).afterClosed().toPromise().then(()=>this.load.emit(true));
      }
    });
  }


}
