<mat-card  *ngIf="!hideLists && !showId">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>CheckLists</mat-card-title>
    <mat-card-subtitle>Administra listados de pasos para completar un trabajo.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager clone="true" [service]="checkListsService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        <div class="click" (click)="select(item)"><i class="fa fa-plus"></i> {{item.name}}</div>
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select placeholder="Tipo" formControlName="check_list_type">
              <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}</mat-option>
            </mat-select>
            <mat-hint>Seleccione el tipo de lista</mat-hint>
          </mat-form-field>
          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="warn_requires_photo" *ngIf="form.controls['check_list_type'].value === 'radio'">
                Requerir Foto en Semaforo amarillo
                <i class="fa fa-question-circle" matTooltip="Si selecciona la opcion amarilla del radio, el trabajo requerirá una foto"></i>
              </mat-checkbox>
            </div>
          </div>
          <div class="row" style="margin-bottom: 10px;">
            <div class="col">
              <mat-checkbox formControlName="danger_requires_photo" *ngIf="form.controls['check_list_type'].value === 'radio'">
                Requerir Foto en Semaforo rojo
                <i class="fa fa-question-circle" matTooltip="Si selecciona la opcion roja del radio, el trabajo requerirá una foto"></i>
              </mat-checkbox>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
<mat-card  *ngIf="!hideLinks && roleService.can('read','admin') | async">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Enlazar Listas de Trabajo</mat-card-title>
    <mat-card-subtitle>Enlaza Listas a Trabajos, Requerimientos, Marcas y Modelos especificos.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="workCheckListsService" [itemTemplate]="workCheckListTemplate" [formTemplate]="workCheckListFormTemplate" [formGroup]="workCheckListForm">
      <ng-template #workCheckListTemplate let-item>
        {{item.work?.name || 'Guardando..'}}
        <ng-container *ngIf="item.requirements && item.requirements.length > 0">
          <i class="fa fa-plus"></i> (&nbsp;<span *ngFor="let value of item.requirements">{{value.name}}&nbsp;</span>)
        </ng-container>
        <ng-container *ngIf="item.brands && item.brands.length > 0">
          <i class="fa fa-plus"></i> (&nbsp;<span *ngFor="let value of item.brands">{{value.name}}&nbsp;</span>)
        </ng-container>
        <ng-container *ngIf="item.brand_models && item.brand_models.length > 0">
          <i class="fa fa-plus"></i> (&nbsp;<span>+ {{item.brand_models.length}} Modelos&nbsp;</span>)
        </ng-container>
        <ng-container *ngIf="item.check_list">
          <i class="fa fa-arrow-right"></i> {{item.check_list.name}}
        </ng-container>
      </ng-template>
      <ng-template #workCheckListFormTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Lista de Trabajo</mat-label>
            <mat-select placeholder="Lista de Trabajo" formControlName="check_list_id" required>
              <mat-option *ngFor="let list of lists" [value]="list.id">{{list.name}}</mat-option>
            </mat-select>
            <mat-hint>Seleccione Lista a enlazar</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Trabajo</mat-label>
            <mat-select placeholder="Trabajo" formControlName="work_id" required>
              <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
            </mat-select>
            <mat-hint>Seleccione Trabajo donde se ejecutará la lista</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Requerimiento (opcional)</mat-label>
            <mat-select placeholder="Requerimiento (opcional)" formControlName="requirements_id" [multiple]="true">
              <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
            </mat-select>
            <mat-hint>Seleccione Requerimiento donde se ejecutará la lista</mat-hint>
          </mat-form-field>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Marca (opcional)</mat-label>
                <mat-select placeholder="Marca (opcional)" formControlName="brands_id" autocomplete="off" [multiple]="true">
                  <mat-option *ngFor="let brand of brands" [value]="brand.id">
                    {{brand.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Modelo (opcional)</mat-label>
                <mat-select placeholder="Modelo (opcional)" formControlName="brand_models_id" autocomplete="off" [multiple]="true">
                  <mat-option *ngFor="let brand_model of models | brand:form.controls['brands_id'].value" [value]="brand_model.id">
                    {{brand_model.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
<app-admin-check-list-items *ngIf="selected" (close)="selected = null" [parentItem]="selected"></app-admin-check-list-items>
