/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role',
  pure: false,
})
export class RolePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'aditional':
        return 'Adicionales';
      case 'analytics':
        return 'Analíticas';
      case 'booking':
        return 'Agendamientos';
      case 'booking_block':
        return 'Bloqueos';
      case 'brand':
        return 'Marcas';
      case 'brand_model':
        return 'Modelos';
      case 'budget':
        return 'Presupuestos';
      case 'check_in':
        return 'Ordenes';
      case 'client':
        return 'Datos Clientes';
      case 'crm':
        return 'CRM';
      case 'derco_mail':
        return 'DercoMail';
      case 'event':
        return 'Eventos';
      case 'inventory_action':
        return 'Inventario';
      case 'inventory_action_for_consume':
        return 'Consumo de Inventario';
      case 'manager':
        return 'Administrar';
      case 'order':
        return 'Ordenes';
      case 'order_comment':
        return 'Comentarios';
      case 'order_payment':
        return 'Cobros';
      case 'planner':
        return 'Planificador';
      case 'product':
        return 'Productos';
      case 'role':
        return 'Roles';
      case 'shares':
        return 'Comisiones';
      case 'vehicle':
        return 'Datos Vehículos';
      case 'work':
        return 'Trabajos';
      default:
        return value;
    }
  }
}
