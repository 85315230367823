<div class="header">
  <i class="fa fa-plus-circle"></i> <div class="text">Productos (Total: {{getTotal() | money}})</div>
</div>
<div class="wrapper">
  <div class="content">
    <div class="info">
      <div class="quantity"></div>
      <div class="value">Valor Base</div>
      <div class="name right">{{order.total | money }}</div>
    </div>
    <div class="info" *ngFor="let item of order.order_items">
      <div class="quantity right">{{item.item_quantity || 1}}</div>
      <div class="value">{{item.item_name}} <span *ngIf="item.item_description">|</span> {{item.item_description}}</div>
      <div class="name right">{{item.item_price*item.item_quantity | money }}</div>
    </div>
    <div class="info last">
      <div class="quantity"></div>
      <div class="value">Total</div>
      <div class="name right total" style="text-align: right">{{getTotal() | money }}</div>
    </div>
    <button mat-raised-button (click)="generatePayment()" *ngIf="!parentForm.valid" [disabled]="working" color="accent">Enviar Cobro a Cliente</button>
    <span class="success" *ngIf="parentForm.valid"><i class="fa fa-check-circle"></i>Cobro enviado exitosamente <span class="see" (click)="openPayment()">Ver</span></span>
  </div>
</div>
