import { HttpResponse } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ChartFiltersService } from "app/analytics/chart-filters/chart-filters.service";
import { RoleService } from "app/role/role.service";
import {AdminService} from 'app/admin/services/admin.service';
import { AnalyticsService } from "app/services/analytics.service";
import moment from "moment";
import { first } from "rxjs";


@Component({
  selector: 'app-shares',
  templateUrl: './shares.component.html',
  styleUrls: ['./shares.component.scss'],
})
export class SharesComponent implements OnInit, OnDestroy {
  @Input() snapshot = '';
  working = true;
  onlyOwn = true;
  totalShares: number;
  totalOrders: number;
  avgShares: number;
  totalSharesPerformance: number;
  totalOrdersPerformance: number;
  avgSharesPerformance: number;
  filters;
  start_at: moment.Moment;
  end_at: moment.Moment;
  periodGroup: string;
  includedFilters = {
    start_at: true,
    end_at: true,
    period_group: true,
  }

  constructor(
    private analyticsService: AnalyticsService,
    private chartFiltersService: ChartFiltersService,
    private roleService: RoleService,
    public appService: AdminService,
  ) {
    this.roleService.onlyOwn('read', 'shares').pipe(first()).forEach(value => {
      if (!value) {
        this.includedFilters['user'] = true;
        this.onlyOwn = false;
      }
      this.working = false;
    });
  }

  ngOnInit(): void {
    this.appService.pageTitle.set(this.appService.getText('Comisiones'));
    this.chartFiltersService.filters$
      .subscribe(filters => {
        if (filters){
          this.filters = filters;
          this.start_at = filters.start_at;
          this.end_at = filters.end_at;
          this.periodGroup = filters.period_group;
          this.updateKpis();
        }
      });

    if(this.snapshot){
      this.analyticsService.getSnapshot(this.snapshot).then((response: HttpResponse<any>) => {
        this.chartFiltersService.updateFilters(response.body.filters);
      });
    }
  }

  ngOnDestroy(): void {
    this.chartFiltersService.resetFilters();
  }

  private updateKpis() {
    this.analyticsService.generate({kpi: 'shares_kpis'}, this.filters).then((data: any) => {
      this.totalShares = data?.[0]?.total_shares || 0;
      this.totalOrders = data?.[0]?.total_orders || 0;
      const previousTotalShares = data?.[0]?.total_shares || 0;
      const previousTotalOrders = data?.[0]?.total_orders || 0;
      this.avgShares = this.totalOrders ? this.totalShares / this.totalOrders : 0;
      const previousAvgShares = previousTotalOrders ? previousTotalShares / previousTotalOrders : 0;

      this.totalSharesPerformance =
        previousTotalShares ? ((this.totalShares - previousTotalShares) / previousTotalShares) * 100 : 0;

      this. totalOrdersPerformance =
        previousTotalOrders ? ((this.totalOrders - previousTotalOrders) / previousTotalOrders) * 100 : 0;

      this.avgSharesPerformance =
        previousAvgShares ? ((this.avgShares - previousAvgShares) / previousAvgShares) * 100 : 0;
    });
  }

}
