<div class="priorities">
  <div class="priority" *ngFor="let priority of priorities" [class.readed]="priority.readed" (click)="openPriority(priority)">
    <div class="icon">
      <i class="fa {{priority.model_class | priority: 'icon'}}"></i>
    </div>
    <div class="content">
      {{priority.action | priority:'action'}}&nbsp;<div class="model">{{priority | priority:'model'}}</div>
    </div>
  </div>
  <div class="priority" *ngIf="priorities.length == 0">No hay notificaciones</div>
</div>
<div class="works">
  <div class="title"><i class="fa fa-chevron-right"></i> Mis trabajos Pendientes</div>
  <div class="work" *ngFor="let work of works" (click)="openWork(work.id,work.type)">
    
    <div class="vehicle">
      <i class="fa fa-user" *ngIf="!work.vehicle_name"></i>
      <i class="fa fa-car" *ngIf="work.vehicle_name"></i>
      <span>{{work.vehicle_name || work.client_name}}</span>
    </div>
    <div class="name" [title]="work.work_name || work.comment || 'Otros'">{{work.work_name || work.comment || 'Otros' | truncate:[20]}}</div>
    <div class="client"><i class="fa fa-tag"></i> <span>{{work.status_name}}</span></div>
  </div>
  <div class="work" *ngIf="works.length == 0">
    <div class="vehicle">
      <i class="fa fa-check"></i> <span>No hay trabajos</span>
    </div>
  </div>
</div>

