import {NgModule} from '@angular/core';
import {CalendarComponent} from './calendar.component';
import {BaseModule} from "../shared/base.module";
import {EventDialogComponent} from "./event-dialog/event-dialog.component";
import {CalendarModule} from "angular-calendar";
import {ImageUploaderModule} from "../shared/image-uploader/image-uploader.module";
import {CommentsAddonsModule} from "../shared/comments/comments.addons.module";

@NgModule({
  imports: [
    BaseModule,
    ImageUploaderModule,
    CalendarModule,
    CommentsAddonsModule
  ],
  declarations: [
    CalendarComponent,
    EventDialogComponent
  ],
  exports: [
    EventDialogComponent,
    CalendarComponent
  ]
})
export class ProductionCalendarModule { }
