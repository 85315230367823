import {NgModule} from '@angular/core';
import {WorkComponent} from './work.component';
import {WorkService} from './work.service';
import {WorkDialogComponent} from './work-dialog/work-dialog.component';
import {WorkRequestComponent} from './work-request/work-request.component';
import {AssignUserComponent} from './assign-user/assign-user.component';
import {WorkPluginsModule} from "./work-plugins/work-plugins.module";
import {WorkStatusPipe} from './work-status.pipe';
import {WorkColumnsComponent} from './work-columns/work-columns.component';
import {WorkColumnsService} from "./work-columns/work-columns.service";
import {WorkCountdownComponent} from './work-countdown/work-countdown.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {FormatColumnPipe} from "./format-column.pipe";
import {SetStatusComponent} from "./set-status/set-status.component";
import {WorkRejectionComponent} from './work-rejection/work-rejection.component';
import {WorkNextComponent} from './work-next/work-next.component';
import {BaseModule} from "../shared/base.module";
import {WorksAddonsModule} from "../shared/works/works.addons.module";
import {CommentsAddonsModule} from "../shared/comments/comments.addons.module";
import {SortableModule} from "../shared/sort/sortable.module";
import {SignModule} from "../shared/sign/sign.module";
import {ImageUploaderModule} from "../shared/image-uploader/image-uploader.module";
import { WorkPlanComponent } from './work-plan/work-plan.component';
import { WorkTimeComponent } from './work-plan/work-time/work-time.component';
import { SharedModule } from 'app/shared/shared.module';
import { CommonFormsModule } from 'app/shared/common-forms/common-forms.module';
import { WorkRequestBottomSheetComponent } from './work-request-bottom-sheet/work-request-bottom-sheet.component';

@NgModule({
  imports: [
    BaseModule,
    WorkPluginsModule,
    ScrollingModule,
    WorksAddonsModule,
    CommentsAddonsModule,
    SortableModule,
    SignModule,
    ImageUploaderModule,
    CommonFormsModule,
  ],
  declarations: [
    WorkComponent,
    WorkDialogComponent,
    WorkRequestComponent,
    AssignUserComponent,
    SetStatusComponent,
    WorkStatusPipe,
    FormatColumnPipe,
    WorkColumnsComponent,
    WorkCountdownComponent,
    WorkRejectionComponent,
    WorkNextComponent,
    WorkPlanComponent,
    WorkTimeComponent,
    WorkRequestBottomSheetComponent
  ],
  providers: [WorkService,WorkColumnsService],
  exports: [
    WorkComponent,
    WorkDialogComponent,
    SetStatusComponent,
    WorkRequestComponent,
    FormatColumnPipe,
  ]
})
export class WorkModule { }
