import {
  ChangeDetectorRef, Component, HostListener, Inject, OnInit, TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'ngx-crud-manager-form',
  templateUrl: './ngx-crud-form.component.html',
  styleUrls: ['./ngx-crud-form.component.scss'],
})
export class NgxCrudFormComponent implements OnInit {
  formTemplate: TemplateRef<any>;

  formGroup: FormGroup;

  formValue: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private matDialogRef: MatDialogRef<NgxCrudFormComponent>,
    private change: ChangeDetectorRef,
  ) {
    this.formGroup = data.form;
    this.formTemplate = data.template;
    this.formValue = data.value;
    if (this.formValue && this.formGroup) {
      this.formGroup.patchValue(this.formValue);
    } else {
      this.formGroup.reset();
    }
  }

  ngOnInit() {
    this.change.detectChanges();
  }

  sendForm() {
    if (this.formGroup && this.formGroup.valid) {
      this.matDialogRef.close(this.formGroup.value);
    }
  }

  @HostListener('keydown', ['$event'])
  enterButton(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.sendForm();
    }
  }
}
