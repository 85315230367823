<div class="wrapper">
  <div class="search-container">
    <div class="search-bar" >
      @if (searchControl.value) {
        <button mat-icon-button (click)="clearSearch()"> <mat-icon>arrow_back</mat-icon> </button>
      }
      @else {
        <mat-icon class="icon-container">search</mat-icon> 
      }
      <input [formControl]="searchControl" name="search_query" autocomplete="off" autocorrect="off" spellcheck="false">
      <button mat-icon-button (click)="openFilters()"> <mat-icon>filter_list</mat-icon> </button>
    </div>
  </div>
<!--
  <mat-chip-listbox class="status-list-box display-desktop">
    <mat-chip class="status-chip" *ngFor="let status of statuses" (click)="toggleFilter(status.id)">
      <mat-icon *ngIf="statusOn(status.id)" [ngStyle]="{'color': status.color}" >check</mat-icon><span>{{status.name}} </span>
    </mat-chip>
  </mat-chip-listbox>
  -->

  <div class="statuses-container display-desktop">

    <div class="status-box" *ngFor="let status of statuses" (click)="toggleFilter(status.id)" onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);">
      <div class="status-content">
        <div class="checkbox-container" >
            @if (statusOn(status.id)) {
              <mat-icon [ngStyle]="{'color': status.color}" >check_box</mat-icon>
            }@else{
              <mat-icon class="default">check_box_outline_blank</mat-icon>
              <mat-icon class="on-hover" [ngStyle]="{'color': status.color}">check_box_outline_blank</mat-icon>
            }
        </div>
        
        <div class="counter">
          <span class="default" [ngStyle]="statusOn(status.id) ? {'color': status.color} : {}">{{totalByStatus[status.id]?.count ?? 0}}</span>
          <span class="on-hover" [ngStyle]="{'color': status.color}">{{totalByStatus[status.id]?.count ?? 0}}</span>
        </div>
        
        <div class="status-name">
          {{status.name}}
        </div>
      </div>
    </div>
  </div>



  <div class="table-container display-desktop"  id="Vehicles" >
    <mat-card class="box-shadowless" >
      <mat-card-content style="padding: 0;" >
        <table mat-table [dataSource]="dataSource">
    
          <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef > Marca </th>
            <td mat-cell *matCellDef="let vehicle"> {{vehicle.brand_name || '-'}} </td>
          </ng-container>
    
          <ng-container matColumnDef="object_identifier">
            <th mat-header-cell *matHeaderCellDef > {{appService.getText('object_identifier')}} </th>
            <td mat-cell *matCellDef="let vehicle"> {{vehicle.identifier || '-'}} </td>
          </ng-container>
    
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef > {{appService.getText('client')}} </th>
            <td mat-cell *matCellDef="let vehicle"> {{vehicle.client_name || 'Sin Cliente'}} </td>
          </ng-container>
    
          <ng-container matColumnDef="requirement">
            <th mat-header-cell *matHeaderCellDef > {{appService.getText('work_assignation')}} </th>
            <td mat-cell *matCellDef="let vehicle"> {{vehicle.requirement_name || 'Sin Orden de Trabajo'}} </td>
          </ng-container>
    
          <ng-container matColumnDef="workInfo">
            <th mat-header-cell *matHeaderCellDef > Datos de {{appService.getText('check_in')}} </th>
            <td mat-cell *matCellDef="let vehicle">
              @if (vehicle.correlative_id) {
                <span class="order status-label">Orden #{{vehicle.correlative_id}}</span>
              }
              @else if (!vehicle.correlative_id && vehicle.booking_date) {
                <span class="booking_date status-label">Agendado para el {{vehicle.booking_date | date:'shortDate'}}</span>
              }
              @else {
                <span>-</span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef > Nota </th>
            <td mat-cell *matCellDef="let vehicle">
              @if (vehicle.finish_date) {
                <span>Entrega agendada: {{vehicle.finish_date | date:'shortDate'}}</span>
              }
              @else if (!vehicle.finish_date && vehicle.check_in_time) {
                <span>Fecha ingreso: {{vehicle.check_in_time | date:'shortDate'}}</span>
              }
              @else if (!vehicle.finish_date && !vehicle.check_in_time && vehicle.phone) {
                <span>{{vehicle.phone}}<i class="fa-phone fa" style="margin: 0 0 0 5px"></i></span>
              }
              @else {
                <span>-</span>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openVehicle(row)" [hidden]="working"></tr>
        </table>
        
        <div class="empty" [hidden]="!working">
          <mat-spinner></mat-spinner>
        </div>

        @if (vehicles && vehicles.length == 0 && !working) {
          <div class="empty white-rounded">
            <span>No se han encontrado vehículos</span>
          </div>
        }
    
        <div class="load-more white-rounded" [hidden]="page >= total_pages" id="load-more">
          <mat-spinner [diameter]="20"></mat-spinner> Cargando vehículos
        </div>

      </mat-card-content>
    </mat-card>
    
  </div>


  <div [hidden]="working" id="Vehicles" class="table-container display-mobile">
  
    <div class="card" *ngFor="let vehicle of vehicles;" (click)="openVehicle(vehicle)" [hidden]="working">
      <div class="card-details">
        @if (vehicle.correlative_id || vehicle.booking_date) {
          <div class="row">
              @if (vehicle.correlative_id) {
                <span class="order status-label">Orden #{{vehicle.correlative_id}}</span>
              }
              @else if (!vehicle.correlative_id && vehicle.booking_date) {
                <span class="booking_date status-label">Agendado para el {{vehicle.booking_date | date:'shortDate'}}</span>
              }
          </div> 
        }
        <div class="row title">
          <span class="bold">{{vehicle.identifier}}</span>
          <span class="light">{{vehicle.brand_name}}</span>
        </div>
        <div class="row">
          <span>{{vehicle.client_name || 'Sin Cliente'}}, {{vehicle.requirement_name || 'Sin Orden de Trabajo'}}</span>
        </div>
        @if (vehicle.finish_date || vehicle.check_in_time || vehicle.phone) {
            <div class="row">
                @if (vehicle.finish_date) {
                  <span>Entrega agendada: {{vehicle.finish_date | date:'shortDate'}}</span>
                }
                @else if (!vehicle.finish_date && vehicle.check_in_time) {
                  <span>Fecha ingreso: {{vehicle.check_in_time | date:'shortDate'}}</span>
                }
                @else if (!vehicle.finish_date && !vehicle.check_in_time && vehicle.phone) {
                  <span>{{vehicle.phone}}<i class="fa-phone fa" style="margin: 0 0 0 5px"></i></span>
                }
            </div>
          }
      </div>
    </div>
    
    @if (vehicles && vehicles.length == 0 && !working) {
      <div class="empty">
        <span>No se han encontrado vehículos</span>
      </div>
    }

    <div class="load-more" [hidden]="page >= total_pages" id="load-more">
      <mat-spinner [diameter]="20"></mat-spinner> Cargando vehículos
    </div> 
    
    <div class="loading" [hidden]="!working">
      <mat-spinner></mat-spinner>
    </div>

  </div>
  
</div>
