import { Category } from './category.model';

export interface Product {
  id: number;
  brand_ids: number[];
  category_ids: number[];
  category: Category;
  code: string;
  company_id: number;
  custom_fields: Record<string, any>;
  created_at: Date;
  deleted_at: null;
  description: string;
  discount_percent: number;
  keep: boolean;
  name: string;
  price: number;
  unit: string;
  updated_at: Date;
}

export interface InventoryProduct extends Product {
  quantity: number;
}

export function displayProduct(product: Product) {
  if (!product) {
    return '';
  }

  return `[${product.code}] ${product.name}`;
}
