import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {RoleService} from "../../../role/role.service";
import {Router} from "@angular/router";
import { AdminService } from 'app/admin/services/admin.service';

@Component({
  selector: 'app-admin-sidemenu',
  templateUrl: './admin-sidemenu.component.html',
  styleUrls: ['./admin-sidemenu.component.scss']
})
export class AdminSidemenuComponent implements OnInit {
  heading;

  company;

  constructor(
    private userService: UserService,
    public roleService: RoleService,
    private router: Router,
    public adminService: AdminService
  ) { }

  ngOnInit() {
    this.heading = this.adminService.getText('location_plural');
    this.company = this.adminService.local$.getValue();
  }
  logout() {
    this.userService.logout();
    this.router.navigate(['admin','login']);
  }

}
