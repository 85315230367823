
<div mat-dialog-title class="name">
  Seleccione colecciones a importar
</div>

<div mat-dialog-content class="content">
  <mat-checkbox class="grid-full"  *ngFor="let item of items" [checked]="itemSelected(item)" (change)="setItem(item)">
    {{item.name}}
  </mat-checkbox>

  <span class="name" *ngIf="items.length === 0">No hay colecciones para importar</span>
</div>  
<div mat-dialog-actions class="footer">
  <button mat-button (click)="closeDialog()">Cancelar</button>
  <button mat-button cdkFocusInitial (click)="import()">Importar</button>
</div>