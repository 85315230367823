import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {SortService} from "./sort.service";

@Component({
  selector: '[sortable-column]',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit, OnDestroy {

  constructor(private sortService: SortService) { }
  @Input('sortable-column')
  columnName: string;

  @Input('sort-direction')
  sortDirection = '';

  @Input('sortable-column-key')
  key = 'key';

  private columnSortedSubscription: Subscription;
  @HostListener('click')
  sort() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortService.columnSorted({ sortColumn: this.columnName, sortDirection: this.sortDirection, key: this.key });
  }

  ngOnInit() {

    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
      if (this.columnName !== event.sortColumn && event.key === this.key) {
        this.sortDirection = '';
      }else if(event.key === this.key){
        this.sortDirection = event.sortDirection;
      }
    });
  }
  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }

}
