<div class="wrapper no-padding-mobile">
  <div class="control-card">
    <div class="logo">
      <img [src]="company.preferences.logo || 'assets/images/logo.svg'">
    </div>
    <div class="user" *ngIf="user">
      No tienes acceso a esta característica
      <button mat-raised-button (click)="logout()" color="primary">Cerrar sesión</button>
    </div>
    <div class="login-controls">
      <form #loginForm="ngForm">
        <mat-form-field appearance="outline">
          <mat-label>Codígo Ingreso</mat-label>
          <input matInput type="password" name="password" required ngModel autocomplete="off" autofocus="true">
        </mat-form-field>
        <button mat-raised-button color="accent" (click)="login(loginForm)" [disabled]="loading || !loginForm.valid">
          <span [hidden]="loading">Entrar</span>
          <i class="fa fa-spin fa-circle-notch" [hidden]="!loading"></i>
        </button>
      </form>
    </div>
  </div>
</div>

