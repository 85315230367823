<div class="main">
  <div class="canvas">
    <div [style.background-image]="getBackground(photos[selectedImage])" #imageZoom [className]="getClass()" [style.transform]="backgroundSize()">
      <video autoplay loop *ngIf="photos && photos[selectedImage] && photos[selectedImage].video">

        <source [src]="photos[selectedImage].url">

        Sorry, your browser doesn't support embedded videos.
      </video>
      <ng-container *ngIf="photos[selectedImage] && photos[selectedImage].markers">
        <div *ngFor="let marker of photos[selectedImage].markers" [className]="marker.marker_type" [style.top]="marker.calculated_y" [style.left]="marker.calculated_x" [style.width]="marker.calculated_width" [style.height]="marker.calculated_height" [hidden]="loading">
          <i class="fa fa-map-marker" [style.color]="marker.color"></i>
          <span class="number" (click)="handleMarkerClick(marker)" [class.pointer-events]="mode === ERASER_MODE" [style.background-color]="marker.background">{{marker.number}}</span>
        </div>
      </ng-container>
      <div class="uploader-icon" (click)="uploadPhotos(file)" *ngIf="uploadingFiles.length == 0 && photos.length == 0 && actions.edit_photos">
      <span class="upload-text"><i class="fa fa-upload hide-mobile"></i>
      <span>Adjunte una imagen para comenzar</span></span>
        <input type="file" style="display: none" name="image" #file (change)="uploadFile($event)" multiple="true">
      </div>
      <div class="uploading-progress" *ngIf="uploadingFiles.length != 0" [hidden]="uploadingFiles[0].uploaded">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="controls">
      <div class="control" (click)="setMode(DRAG_MODE)" [class.selected]="mode === DRAG_MODE"><i class="fa fa-expand-arrows-alt"></i></div>
      <ng-container *ngIf="photos.length > 0 && selectedImage >= 0 && (photos[selectedImage] && !photos[selectedImage].video)">
      <!--
      <div class="control" (click)="setMode(MARKER_MODE)" [class.selected]="mode === MARKER_MODE" *ngIf="actions.edit_markers">
        <div class="tags" *ngIf="tags.length > 1">
          <div class="tag" *ngFor="let tag of tags" [class.selected]="tag.id === selectedTag" (click)="selectedTag = tag.id">
            <i class="fa fa-map-marker" [style.color]="tag.color"></i>
            <span>{{tag.name}}</span>
          </div>
        </div>
        <i class="fa fa-map-marker" [style.color]="tags[0].color" *ngIf="tags.length === 1"></i>
      </div>
      -->
      <!--
      <div class="control hide-mobile" (click)="setMode(VECTOR_MODE)" [class.selected]="mode === VECTOR_MODE" *ngIf="actions.edit_markers">
        <div class="tags" *ngIf="tags.length > 1">
          <div class="tag" *ngFor="let tag of tags" [class.selected]="tag.id === selectedTag" (click)="selectedTag = tag.id">
            <i class="fa fa-vector-square" [style.color]="tag.color"></i>
            <span>{{tag.name}}</span>
          </div>
        </div>
        <i class="fa fa-vector-square" [style.color]="tags[0].color" *ngIf="tags.length === 1"></i>
      </div>
      -->
      <!--
      <div class="control" (click)="setMode(ERASER_MODE)" [class.selected]="mode === ERASER_MODE" matTooltip="Borrar Marcadores" *ngIf="actions.edit_markers"><i class="fa fa-eraser"></i></div>
      -->
      </ng-container>
      <div class="control" (click)="downloadImage()" matTooltip="Descargar"><i class="fa fa-external-link-alt"></i></div>
      <div class="control toggle selected" (click)="saveImages()" matTooltip="Cerrar"><i class="fa fa-times"></i></div>
    </div>
    <div class="images">
      <div class="image" *ngFor="let image of photos | photos:'url'; let index = index" [class.selected]="selectedImage === index" [style.background-image]="getThumb(image)" (click)="selectImage(index)" [hidden]="image._destroy">
        <div class="exit" (click)="removeImage(image)" [hidden]="readonly" *ngIf="actions.edit_photos"><i class="fa fa-times"></i></div>
      </div>
      <div class="image" *ngFor="let image of uploadingFiles" [hidden]="image.uploaded">
        <div class="add-image"><span class="progress">{{image.progress}}%</span></div>
      </div>
      <div class="image" (click)="addPhotos()" [hidden]="readonly" matTooltip="Desde Cámara" *ngIf="photos.length + uploadingFiles.length < MAX_IMAGES && actions.edit_photos">
        <div class="add-image"><i class="fa fa-camera"></i></div>
      </div>
      <div class="image" (click)="uploadPhotos(file)" [hidden]="readonly" matTooltip="Desde Archivo" *ngIf="(photos.length + uploadingFiles.length < MAX_IMAGES) && actions.edit_photos">
        <input type="file" style="display: none" name="image" #file (change)="uploadFile($event)" multiple="true">
        <div class="add-image"><i class="fa fa-plus" ></i></div>
      </div>
      <div class="image" (click)="fromGallery()" [hidden]="readonly || !gallery || !gallery.length || gallery.length == 0" matTooltip="Desde Galería" *ngIf="(photos.length + uploadingFiles.length < MAX_IMAGES) && actions.edit_photos">
        <div class="add-image"><i class="fa fa-image" ></i></div>
      </div>
    </div>
  </div>
  <div class="right-panel hide-mobile" #rightPanel *ngIf="!readonly || (readonly && photos[selectedImage] && photos[selectedImage].markers && photos[selectedImage].length > 0)">
    <!--
    <div class="markers" *ngIf="photos[selectedImage]">
      <ng-container *ngIf="photos[selectedImage].markers">
        <div class="marker" *ngFor="let marker of photos[selectedImage].markers">
          <div class="icon">
            <i class="fa fa-map-marker" *ngIf="marker.marker_type==='marker'" [style.color]="marker.color"></i>
            <i class="fa fa-vector-square" *ngIf="marker.marker_type==='rectangle'" [style.color]="marker.color"></i>
            <span class="number" [style.color]="marker.background">{{marker.number}}</span>
          </div>
          <div class="info hide-mobile">
            <textarea name="comment" rows="3" placeholder="Ingrese comentario" #input [(ngModel)]="marker.comment" [disabled]="!actions.edit_markers"></textarea>
          </div>
        </div>
      </ng-container>
      <div class="marker" *ngIf="!photos[selectedImage].markers || photos[selectedImage].markers.length === 0">
        <div class="icon">
          <i class="fa fa-map-marker" [style.color]="'gray'"></i>
          <span class="number">?</span>
        </div>
        <div class="info hide-mobile">
          Agregue un marcador para comenzar
        </div>
      </div>
    </div>
    -->
  </div>
</div>
