import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventoryAction } from 'app/models/inventory_action.model';
import { PaginatedItems } from 'app/models/types';
import {
  BehaviorSubject, catchError, map, Observable, of, tap,
} from 'rxjs';

import { BaseService } from './_base.service';

@Injectable()
export class InventoryActionService extends BaseService {
  private allInventoryActions$ = new BehaviorSubject<InventoryAction[]>([]);

  index(
    pageNumber: number = 1,
    pageSize: number = 10,
    sort: string = 'updated_at desc',
    warehouseIds: number[] = [],
    filters = {},
  ): Observable<PaginatedItems<InventoryAction>> {
    return this.http
      .get<PaginatedItems<InventoryAction>>(
      `${this.apiURL}/inventory_actions`,
      this.getOptions({
        page: pageNumber,
        page_size: pageSize,
        'warehouse_ids[]': warehouseIds,
        sort,
        ...filters,
      }),
    )
      .pipe(
        map((response) => response.body),
        tap((inventoryActions) => {
          this.allInventoryActions$.next(inventoryActions.items);
        }),
      );
  }

  create(value: { inventory_action: Partial<InventoryAction> }): Observable<InventoryAction> {
    return this.http.post<InventoryAction>(`${this.apiURL}/inventory_actions`, value, this.getOptions())
      .pipe(
        map((response: HttpResponse<InventoryAction>) => response.body),
        tap((newInventoryAction: InventoryAction) => {
          const inventoryActions = this.allInventoryActions$.getValue();
          this.allInventoryActions$.next([...inventoryActions, newInventoryAction]);
        }),
        catchError(() => of(null)),
      );
  }

  clone(id: number): Observable<InventoryAction> {
    return this.http.post<InventoryAction>(`${this.apiURL}/inventory_actions/${id}/clone`, {}, this.getOptions())
      .pipe(
        map((response: HttpResponse<InventoryAction>) => response.body),
        tap((duplicatedInventoryAction: InventoryAction) => {
          console.log('Duplicated Inventory Action received:', duplicatedInventoryAction);
        
          const inventoryActions = this.allInventoryActions$.getValue();
          this.allInventoryActions$.next([...inventoryActions, duplicatedInventoryAction]);
        }),
        catchError(() => of(null)),
      );
  }

  import(type: string, products: string[][]): Observable<InventoryAction> {
    return this.http.post<InventoryAction>(
      `${this.apiURL}/inventory_actions/import`,
      { type, inventory_action_products: products },
      this.getOptions(),
    ).pipe(
      map((response: HttpResponse<InventoryAction>) => response.body),
      tap((newInventoryAction: InventoryAction) => {
        const inventoryActions = this.allInventoryActions$.getValue();
        this.allInventoryActions$.next([...inventoryActions, newInventoryAction]);
      }),
      catchError(() => of(null)),
    );
  }

  update(id: number, inventoryAction: Partial<InventoryAction>): Observable<InventoryAction> {
    return this.http.put<InventoryAction>(`${this.apiURL}/inventory_actions/${id}`, inventoryAction, this.getOptions())
      .pipe(
        map((response: HttpResponse<InventoryAction>) => response.body),
        tap((updatedInventoryAction: InventoryAction) => {
          const inventoryActions = this.allInventoryActions$.getValue();
          const index = inventoryActions.findIndex((action) => action.id === id);
          inventoryActions[index] = updatedInventoryAction;
          this.allInventoryActions$.next(inventoryActions);
        }),
        catchError(() => of(null)),
      );
  }

  edit(id: number, inventoryAction: Partial<InventoryAction>): Observable<InventoryAction> {
    return this.http.patch<InventoryAction>(
      `${this.apiURL}/inventory_actions/${id}/edit`,
      inventoryAction,
      this.getOptions(),
    )
      .pipe(
        map((response: HttpResponse<InventoryAction>) => response.body),
        tap((updatedInventoryAction: InventoryAction) => {
          const inventoryActions = this.allInventoryActions$.getValue();
          const index = inventoryActions.findIndex((action) => action.id === id);
          inventoryActions[index] = updatedInventoryAction;
          this.allInventoryActions$.next(inventoryActions);
        }),
        catchError(() => of(null)),
      );
  }

  get(id: number): Observable<InventoryAction> {
    return this.http.get<InventoryAction>(`${this.apiURL}/inventory_actions/${id}`, this.getOptions())
      .pipe(
        map((response: HttpResponse<InventoryAction>) => response.body),
        catchError(() => of(null)),
      );
  }

  getWorks(id: number) {
    return this.http.get(`${this.apiURL}/inventory_actions/${id}/works`, this.getOptions())
      .pipe(
        map((response: HttpResponse<InventoryAction>) => response.body),
        catchError(() => of(null)),
      );
  }

  all(): Observable<HttpResponse<InventoryAction[]>> {
    return this.http.get<InventoryAction[]>(`${this.apiURL}/inventory_actions/all`, this.getOptions());
  }

  delete(id: number) {
    return this.http.delete(`${this.apiURL}/inventory_actions/${id}`, this.getOptions())
      .pipe(
        tap(() => {
          const inventoryActions = this.allInventoryActions$.getValue();
          const index = inventoryActions.findIndex((action) => action.id === id);
          inventoryActions.splice(index, 1);
          this.allInventoryActions$.next(inventoryActions);
        }),
      );
  }

  approve(id: number) {
    return this.http.post(`${this.apiURL}/inventory_actions/${id}/approve`, {}, this.getOptions());
  }

  decline(id: number) {
    return this.http.post(`${this.apiURL}/inventory_actions/${id}/decline`, {}, this.getOptions());
  }

  inventoryActions(type?: string): Observable<InventoryAction[]> {
    if (type) {
      return this.allInventoryActions$.pipe(
        map(
          (inventoryActions) => inventoryActions.filter((inventoryAction) => inventoryAction.type === type),
        ),
      );
    }

    return this.allInventoryActions$.asObservable();
  }
}
