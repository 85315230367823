<ng-container [formGroup]="parentFormGroup">
  @for(field of objectFields$ | async; track field.id) {
    @if (field.field_type === 'number') {
      <div class="form-row" [formGroupName]="field.key">
        <div class="row number">
          <mat-radio-group
              style="margin-left: 10px; display: flex; flex-direction: column;"
              formControlName="compareFn"
            >
            <mat-radio-button value="equal">Igual a</mat-radio-button>
            <mat-radio-button value="range">Rango entre</mat-radio-button>
          </mat-radio-group>

          @if (parentFormGroup.get(field.key).get('compareFn').value === 'range') {
            <mat-form-field>
              <mat-label>{{field.title}} Desde</mat-label>
              <input matInput formControlName="from" name="from" type="number" autocomplete="off">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{field.title}} Hasta</mat-label>
              <input formControlName="to" matInput name="to" type="number" autocomplete="off">
            </mat-form-field>
          } @else {
            <mat-form-field>
              <mat-label>{{field.title}}</mat-label>
              <input matInput formControlName="value" name="value" type="number" autocomplete="off">
            </mat-form-field>
          }
        </div>
      </div>
    } @else if (field.field_type === 'text') {
      <div class="form-row" [formGroupName]="field.key">
        <mat-form-field>
          <mat-label>{{field.title}}</mat-label>
          <input matInput formControlName="value" name="value" autocomplete="off">
        </mat-form-field>
      </div>
    } @else if (field.field_type === 'boolean') {
      <div class="col radio" [formGroupName]="field.key">
        <span class="radio-title">{{field.title}}: </span>
        <mat-checkbox
          formControlName="value"
        >
        </mat-checkbox>
      </div>
    }
  }
</ng-container>
