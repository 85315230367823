<div class="wrapper client-style">
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="notification" *ngIf="!loading && notification">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="logo">
          <img [src]="company.preferences.logo || 'assets/images/logo.svg'">
        </div>
        <form #f="ngForm">
          <div class="subtitle"><i class="fa fa-bullhorn"></i> Notificación</div>
          <div class="title" [innerHTML]="notification.safeBody"></div>
          <ng-container *ngIf="notification.work_instance_id && notification.client_notification.show_work_data">
            <div class="subtitle"><i class="fa fa-wrench"></i> Información</div>
            <app-work-dialog [work]="notification.work_instance" [clientMode]="true"></app-work-dialog>
          </ng-container>
          <ng-container *ngIf="notification.client_notification.answer_type !== 'empty'">
            <div class="subtitle"><i class="fa fa-calendar"></i> Completa los siguientes datos</div>
            <div class="row" *ngIf="notification.client_notification.answer_type === 'date'">
              <div class="col">
                <mat-form-field (click)="pickerN.open()">
                  <input matInput [matDatepicker]="pickerN" [min]="currentDate" [matDatepickerFilter]="onlyWeek" placeholder="Fecha" required [(ngModel)]="notificationForm.date" name="date" autocomplete="off">
                </mat-form-field>
                <mat-datepicker #pickerN [startAt]="currentDate"></mat-datepicker>
              </div>
            </div>
            <div class="row" *ngIf="notification.client_notification.answer_type==='booking'">
              <div class="col">
                <div class="vehicles">
                  <div class="vehicle" *ngFor="let recall_id of notification.client.recall_data" [class.booked]="isBooked(recall_id)">
                    <div class="title">
                      <div class="title-info">
                        <div class="icon">
                          <i class="fa fa-car"></i>
                        </div>
                        <div class="title-data">
                          <div class="first-line">{{getVehicle(recall_id).identifier}} - {{getVehicle(recall_id).brand_name}} {{getVehicle(recall_id).brand_model_name}}</div>
                          <div class="second-line">{{getVehicle(recall_id).chasis}}</div>
                        </div>
                      </div>
                      <div class="expander"></div>
                      <div class="title-info book" (click)="createBooking(recall_id)">

                        <div class="title-data" *ngIf="getRequirement(recall_id)">
                          <div class="first-line">Agendar</div>
                          <div class="second-line">{{getRequirement(recall_id).name}}</div>
                        </div>
                      </div>
                      <div class="title-info hour">
                        <span style="margin-right: 15px">Agendada el</span>
                        <div class="icon">
                          <i class="fa fa-clock"></i>
                        </div>
                        <div class="title-data" *ngFor="let order of getVehicle(recall_id).orders | slice:0:1; let last = last">
                          <div class="first-line">{{order.booking_date | date:'shortDate'}}</div>
                          <div class="second-line">{{order.booking_time}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="notification.client_notification.answer_type === 'datetime'">
              <div class="col">
                <mat-form-field class="hidden-datepicker">
                  <input matInput [matDatetimepicker]="datetimePicker" [matDatepickerFilter]="onlyWeek" placeholder="Elige fecha y hora" [min]="currentDate" [(ngModel)]="notificationForm.date_time" name="date_time" required>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="notification.client_notification.answer_type === 'text'">
              <div class="col">
                <mat-form-field>
                  <input matInput placeholder="Respuesta" required [(ngModel)]="notificationForm.text" name="text" autocomplete="off">
                </mat-form-field>
              </div>
            </div>
            <ng-container *ngIf="notification.client_notification.answer_type === 'boolean'">
              <div class="row">
                <div class="col">
                  <mat-radio-group [(ngModel)]="notificationForm.boolean" required name="boolean" style="margin-bottom: 10px">
                    <mat-radio-button value="Acepta">Acepto</mat-radio-button>
                    <mat-radio-button value="Rechaza">Rechazo</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field>
                    <input matInput placeholder="Comentario (opcional)" [(ngModel)]="notificationForm.text" name="text" autocomplete="off">
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

            <div class="buttons" *ngIf="notification.client_notification.answer_type !== 'booking'">
              <div class="expander"></div>
              <button mat-raised-button color="accent" [disabled]="!f.valid || working" (click)="saveNotificationForm(f)">Enviar</button>
            </div>
          </ng-container>
          <div class="footer">
            {{company.name}}. <ng-container *ngIf="company.preferences.address"><i class="fa fa-address-book"></i> {{company.preferences.address}}</ng-container>
            <ng-container *ngIf="company.preferences.phone"><i class="fa fa-phone"></i> {{company.preferences.phone}}</ng-container>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
