<ng-container *ngIf="changes">
    <div mat-dialog-title class="name">
      Historial de Cambios 
    </div>
    
    <div mat-dialog-content class="content">

      <mat-stepper orientation="vertical">
        <mat-step *ngFor="let change of changes.slice().reverse(); let i = index" completed="false">
          <ng-template matStepLabel>
            <span>{{change.name || 'Sistema'}}</span>
            <br>
            <span class="subtitle">{{change.created_at | date:'short'}}</span>
          </ng-template>
          <ng-template matStepContent>
            <div *ngIf="change.object_changes">
              <div  *ngFor="let attr of filterChangeSet(change);">
                <span style="margin-right: 10px; text-transform: capitalize">• {{attr.name}}:</span>
                <span style="margin-right: 10px" *ngIf="change.changeset[attr.id][0]">{{getValue(attr.id,change.changeset[attr.id][0]) || 'null'}}</span>
                <span *ngIf="change.changeset[attr.id][0]" style="margin-right: 10px">-></span>
                <span>{{getValue(attr.id,change.changeset[attr.id][1]) || 'null'}}</span>
              </div>
            </div>
            <div *ngIf="!change.object_changes">
              <span>Sin cambios registrados</span> 
            </div>
          </ng-template>
        </mat-step>
      </mat-stepper>

      <div *ngIf="changes && changes.length == 0">
       <span>No hay cambios</span>
      </div>

    </div>

  
  <div mat-dialog-actions class="footer">
    <button mat-button mat-dialog-close cdkFocusInitial>Cerrar</button>
  </div>


</ng-container>
<mat-spinner *ngIf="!changes"></mat-spinner>
