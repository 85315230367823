import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Field, FieldsService } from 'app/admin/services/fields.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-fields-form',
  templateUrl: './fields-form.component.html',
  styleUrls: ['./fields-form.component.scss', '../common-forms.common.scss'],
})
export class FieldsFormComponent implements OnInit {
  @Input() modelName: string;

  @Input() parentFormGroup: FormGroup;

  objectFields$: Observable<Field[]>;

  constructor(
    private fb: FormBuilder,
    private fieldsService: FieldsService,
  ) { }

  ngOnInit(): void {
    this.fieldsService.fields(`FieldFor${this.modelName}`).subscribe((response) => {
      response.forEach((field) => {
        const control = field.field_type === 'number'
          ? this.fb.group(
            {
              compareFn: this.fb.control('equal'),
              value: this.fb.control(null),
              to: this.fb.control(null),
              from: this.fb.control(null),
            },
          )
          : this.fb.group({ value: this.fb.control(null), compareFn: this.fb.control('equal') });

        this.parentFormGroup.addControl(field.key, control);
      });
      this.objectFields$ = this.fieldsService.fields(`FieldFor${this.modelName}`);
    });
  }
}
