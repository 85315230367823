import { Pipe, PipeTransform } from '@angular/core';
import { AdminService } from 'app/admin/services/admin.service';
import { formatIdentifier } from 'app/utils/client-utils';

@Pipe({
  name: 'identifier',
})
export class IdentifierPipe implements PipeTransform {
  constructor(private adminService: AdminService) { }

  transform(identifier: string): string {
    const countryCode = this.adminService.local$.getValue().country.code;
    return formatIdentifier(identifier, countryCode);
  }
}
