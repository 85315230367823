import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class UsersService extends BaseService implements ICRUDService{
  params = ['name','rut','mail','password','password_confirmation','role_id','active','online','workpoint_code','phone','location_ids','block_hours']
  model = 'User';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null, page = 1){
    return this.http.get(this.adminURL+'/users',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/users/',{
      user: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/users/'+id,{
      user: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/users/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/users/'+id+'/restore',this.getParams());
  }


}
