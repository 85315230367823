import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "../services/user.service";

@Injectable()
export class CanActivateWorkPoint  {

  private user= null;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (this.user != null) {
      return true;
    }else if (this.authService.token) {
      return this.authService.authCallback();
    }else {
      this.router.navigate(['/workpoint']);
      return false;
    }
  }

  constructor(private authService: UserService, private router: Router) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }
}
