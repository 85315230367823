import { CrmBookingComponent } from '../crm/crm-booking/crm-booking.component';
import { ClientAnalyticComponent } from './client-analytic/client-analytic.component';
import { ClientBookingComponent } from './client-booking/client-booking.component';
import { ClientBudgetComponent } from './client-budget/client-budget.component';
import { ClientConfirmComponent } from './client-confirm/client-confirm.component';
import { ClientNotificationComponent } from './client-notification/client-notification.component';
import { ClientOrderComponent } from './client-order/client-order.component';
import { ClientPaymentComponent } from './client-payment/client-payment.component';

export const clientRoutes = [
  {
    path: 'booking',
    component: ClientBookingComponent,
  },
  {
    path: 'order',
    component: ClientOrderComponent,
  },
  {
    path: 'notification',
    component: ClientNotificationComponent,
  },
  {
    path: 'confirm',
    component: ClientConfirmComponent,
  },
  {
    path: 'payment',
    component: ClientPaymentComponent,
  },
  {
    path: 'budget',
    component: ClientBudgetComponent,
  },
  {
    path: 'analytics',
    component: ClientAnalyticComponent,
  },
  {
    path: 'crm/booking',
    component: CrmBookingComponent,
  },
  {
    path: '**',
    redirectTo: 'booking',
  },
];
