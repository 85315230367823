<div mat-dialog-title class="name">
  Creación de Gestión de Agendamiento
</div>

<ng-container [formGroup]="form">
  <mat-form-field class="row">
    <mat-label>Gestión</mat-label>
    <input
      matInput
      type="text"
      autocomplete="off"
      formControlName="search"
      placeholder="Buscar por Nº de Identificación, Nombre o Patente"
      required
    >
  </mat-form-field>
  <div mat-dialog-content class="content">
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="rut">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nº Identificación </mat-header-cell>
          <mat-cell *matCellDef="let crm"> {{ crm.client.rut | identifier }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
          <mat-cell *matCellDef="let crm"> {{ crm.client.name | titlecase }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
          <mat-cell *matCellDef="let crm"> {{ crm.client.email | lowercase }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="object-identifier">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Patente </mat-header-cell>
          <mat-cell *matCellDef="let crm"> {{ crm.object.identifier | uppercase }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="from-requirement">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Última {{adminService.getText("Requirement")}}</mat-header-cell>
          <mat-cell *matCellDef="let crm"> {{ crm.from_requirement.name | titlecase }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="requirement">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{adminService.getText("Requirement")}} Objetivo</mat-header-cell>
          <mat-cell *matCellDef="let crm"> {{ crm.requirement.name | titlecase }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let crm; columns: displayedColumns;" (click)="onOptionClick(crm)"></mat-row>
      </mat-table>
      @if((dataSource?.data.length === 0)){
        <div class="no-results">
          {{ loading ? 'Cargando...' : 'No se encontraron resultados' }}
        </div>
      }
    </div>

    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <mat-paginator
      #paginator
      class="pagination"
      [length]="totalData"
      [pageSizeOptions]="pageSizes"
          showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>

<div mat-dialog-actions class="footer">
  <button mat-button mat-dialog-close >Cancelar </button>
  <button mat-button (click)="onAcceptClick()" [disabled]="disabledButton()"> Seleccionar </button>
</div>

