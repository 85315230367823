import {Component} from '@angular/core';

@Component({
  selector: 'app-work-point',
  templateUrl: './work-point.component.html',
  styleUrls: ['./work-point.component.scss']
})
export class WorkPointComponent {

}
