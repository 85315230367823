import { NgModule } from '@angular/core';

import { AnalyticsModule } from '../analytics/analytics.module';
import { BudgetModule } from '../budget/budget.module';
import { BaseModule } from '../shared/base.module';
import { CommentsAddonsModule } from '../shared/comments/comments.addons.module';
import { CommonFormsModule } from '../shared/common-forms/common-forms.module';
import { WorksAddonsModule } from '../shared/works/works.addons.module';
import { SharesModule } from '../shares/shares.module';
import { WorkModule } from '../work/work.module';
import { ClientComponent } from './client.component';
import { ClientService } from './client.service';
import { ClientAnalyticComponent } from './client-analytic/client-analytic.component';
import { ClientBookingComponent } from './client-booking/client-booking.component';
import { ClientBudgetComponent } from './client-budget/client-budget.component';
import { ClientConfirmComponent } from './client-confirm/client-confirm.component';
import { ClientMessageComponent } from './client-message/client-message.component';
import { ClientNotificationComponent } from './client-notification/client-notification.component';
import { ClientOrderComponent } from './client-order/client-order.component';
import { ClientPaymentComponent } from './client-payment/client-payment.component';

@NgModule({
  declarations: [
    ClientComponent,
    ClientOrderComponent,
    ClientBookingComponent,
    ClientMessageComponent,
    ClientNotificationComponent,
    ClientConfirmComponent,
    ClientBudgetComponent,
    ClientAnalyticComponent,
    ClientPaymentComponent,
  ],
  imports: [
    BaseModule,
    WorkModule,
    BudgetModule,
    AnalyticsModule,
    SharesModule,
    CommentsAddonsModule,
    WorksAddonsModule,
    CommonFormsModule,
  ],
  providers: [
    ClientService,
  ],
  exports: [
    ClientBookingComponent,
  ],
})
export class ClientModule { }
