<div class="wrapper">
  <div class="search-container">
    <div class="search-bar" >
      @if (searchControl.value) {
        <button mat-icon-button (click)="clearSearch()"> <mat-icon>arrow_back</mat-icon> </button>
      }
      @else {
        <mat-icon class="icon-container">search</mat-icon> 
      }
      <input [formControl]="searchControl" name="search" autocomplete="off" autocorrect="off" spellcheck="false">
      <button mat-icon-button (click)="openFilters()"> <mat-icon>filter_list</mat-icon> </button>
  
    </div>
  
    @if (!downloading) {
      <button mat-icon-button (click)="exportAsExcelFile()"> <mat-icon>download</mat-icon> </button>
    }
    @if (downloading) {
      <button mat-icon-button disabled><mat-icon>downloading</mat-icon></button>
       
    }
  </div>

    <div class="statuses-container display-desktop">
    
      <div class="status-box" *ngFor="let status of statuses" (click)="toggleFilter(status.id)" onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);">
        <div class="status-content">
          <div class="checkbox-container" >
              @if (statusOn(status.id)) {
                <mat-icon [ngStyle]="{'color': status.color}" >check_box</mat-icon>
              }@else{
                <mat-icon class="default">check_box_outline_blank</mat-icon>
                <mat-icon class="on-hover" [ngStyle]="{'color': status.color}">check_box_outline_blank</mat-icon>
              }
          </div>
          <div class="counter">
            <span class="default" [ngStyle]="statusOn(status.id) ? {'color': status.color} : {}">{{totalByStatus[status.id]?.count ?? 0}}</span>
            <span class="on-hover" [ngStyle]="{'color': status.color}">{{totalByStatus[status.id]?.count ?? 0}}</span>
          </div>
          <div class="status-name">
            {{status.name}}
          </div>
          <div class="status-subtitle">
            {{(totalByStatus[status.id]?.total ?? 0) * (country.preferences.tax_rate) | money}}
          </div>
        </div>
      </div>
    </div>

  <div class="table-container display-desktop" id="budgets" appPagination (loadMore)="getMore()" [isLoading]="loadingMore" [page]="page" [totalPages]="total_pages">
    <mat-card class="box-shadowless">
      <mat-card-content style="padding: 0;">
        <table mat-table [dataSource]="dataSource">
  
          <ng-container matColumnDef="correlative-id">
            <th mat-header-cell *matHeaderCellDef> N° {{adminService.getText('check_in')}} </th>
            <td mat-cell *matCellDef="let budget"> #{{budget.correlative_id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="requirement">
            <th mat-header-cell *matHeaderCellDef> {{adminService.getText('requirement')}} </th>
            <td mat-cell *matCellDef="let budget"> {{budget.order.requirement?.name}} </td>
          </ng-container>
  
          <ng-container matColumnDef="object-identifier">
            <th mat-header-cell *matHeaderCellDef> {{adminService.getText('object_identifier')}} </th> <!--REVISAR COMO CAMBIAR HEADER SI NO HAY OBJECT IDENTIFIER-->
            <td mat-cell *matCellDef="let budget"> {{budget.order.object?.identifier || budget.order.client.name}} </td>
          </ng-container>
  
          <ng-container matColumnDef="status-list">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let budget">
              <div class="status-list">
                <div class="status" [style.color]="status.color" *ngFor="let status of statuses" [matTooltip]="status.name">
                  @if (getStatusCompletion(status.id, budget) && status.id != budget.status_id) {
                    <i class="fa fa-square"></i>
                  }
                  @if (!getStatusCompletion(status.id, budget) && status.id != budget.status_id) {
                    <i class="far fa-square"></i>
                  }
                  @if (status.id == budget.status_id) {
                    <i class="fa fa-check-square"></i>
                  }
                </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="budget-total">
            <th mat-header-cell *matHeaderCellDef> {{adminService.getText('Value')}} </th>
            <td mat-cell *matCellDef="let budget"> 
              {{budget.total * country.preferences.tax_rate | money}} 
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/main', 'budgets', row.id]" [hidden]="working"></tr>
        </table>

        <div class="empty" [hidden]="!working">
          <mat-spinner></mat-spinner>
        </div>

        @if (budgets && budgets.length == 0 && !working) {
          <div class="empty white-rounded">
            <span>No se han encontrado {{adminService.getText('budget')}}s</span>
          </div>
        }
  
        <div class="load-more white-rounded" [hidden]="page >= total_pages" id="load-more">
          <mat-spinner [diameter]="20"></mat-spinner> Cargando {{adminService.getText('budget')}}s...
        </div>
  
      </mat-card-content>
    </mat-card>
  </div>
  
  <div class="table-container display-mobile" id="budgets"  appPagination (loadMore)="getMore()" [isLoading]="loadingMore" [page]="page" [totalPages]="total_pages">
    <div class="card" *ngFor="let budget of budgets;" [routerLink]="['/main', 'budgets', budget.id]" [hidden]="working">
      <div class="card-details">
        <div class="row no-margin">
          <span class="small light">#{{budget.correlative_id}}</span>
        </div>
        <div class="row title">
          <span class="bold">{{budget.order.object?.identifier || budget.order.client.name}}</span>
          <span class="light">{{budget.order.requirement?.name}}</span>
        </div>
        <div class="row">
          <span> {{budget.total * country.preferences.tax_rate | money}} </span>
        </div>
        <div class="row">
          <span><i class="fas fa-check-circle" [style.color]="budget.status?.color"></i>&nbsp;{{budget.status?.name}}</span>
        </div>
      </div>
    </div>
    
    <div class="loading" [hidden]="!working">
      <mat-spinner></mat-spinner>
    </div>

    @if (budgets && budgets.length == 0 && !working) {
      <div class="empty">
        <span>No se han encontrado {{adminService.getText('budget')}}s</span>
      </div>
    }
    <div class="load-more" [hidden]="page >= total_pages" id="load-more">
      <mat-spinner [diameter]="20"></mat-spinner> Cargando {{adminService.getText('budget')}}s...
    </div> 

  </div>
  


</div>
