import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../services/admin.service";
import {StepperSelectionEvent} from "@angular/cdk/stepper";

@Component({
  selector: 'app-admin-automation-wizard',
  templateUrl: './admin-automation-wizard.component.html',
  styleUrls: ['./admin-automation-wizard.component.scss']
})
export class AdminAutomationWizardComponent implements OnInit {
  code = "";
  step = 1;
  constructor(
    public company:AdminService
  ) { }

  ngOnInit() {
    const domain = this.company.local$.getValue().domain;
    this.code = `<iframe width="100%" height="900px" src="${domain}/client/booking"></iframe>`
  }
  setStep(event: StepperSelectionEvent){
    this.step = event.selectedIndex + 1;
  }

}
