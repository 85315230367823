<div mat-dialog-title class="name">
  {{'Creación de ' + categoryName || 'Productos'}}
</div>

<ng-container>
  <form [formGroup]="form" mat-dialog-content>
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>SKU</mat-label>
      <input matInput placeholder="SKU" formControlName="code" autocomplete="off" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Unidad&nbsp;
        <i
          class="fa fa-question-circle"
          matTooltip="De no especificarse la unidad aquí, se tomará la de la categoría.">
        </i>
      </mat-label>
      <input matInput placeholder="ej. Horas, Litros, Metros (plural)" formControlName="unit" autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Precio</mat-label>
      <input type="number" matInput placeholder="Precio" formControlName="price" autocomplete="off" step="0.01" required>
    </mat-form-field>
    <mat-form-field style="margin-bottom: 10px">
      <mat-label>Marcas</mat-label>
      <mat-select placeholder="Marcas" formControlName="brand_ids" multiple="true">
        <mat-option *ngFor="let brand of brands$ | async" [value]="brand.id">{{brand.name}}</mat-option>
      </mat-select>
      <mat-hint>Marcas a las que corresponde el producto</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Descripción</mat-label>
      <textarea matInput placeholder="Descripción" formControlName="description" autocomplete="off" required></textarea>
    </mat-form-field>

    <app-order-fields
        mode="Category"
        [model]="form.value"
        [parentFormGroup]="form">
    </app-order-fields>
  </form>
</ng-container>

<div mat-dialog-actions class="footer">
  <button mat-button mat-dialog-close >Cancelar </button>
  <button mat-button (click)="createProduct()" [disabled]="disabledButton()"> Crear </button>
</div>
