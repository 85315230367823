import {Injectable} from '@angular/core';
import {BaseService} from "./_base.service";
import {UserService} from "./user.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class OrderNotificationsService extends BaseService {

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  index(data) {
    return this.http.get(this.apiURL + '/order_notifications', this.getParams(data));
  }

}
