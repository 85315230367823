import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/_base.service';
import { UserService } from 'app/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class KitProductsService extends BaseService {
  params = [
    'quantity',
    'product_category_id',
  ];

  constructor(public http: HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(search: string | undefined, page = 1, kitId: number) {
    return this.http.get(`${this.adminURL}/kits/${kitId}/kit_products/`, this.getParams({ search, page }));
  }

  create(value, kitId: number) {
    return this.http.post(
      `${this.adminURL}/kits/${kitId}/kit_products/`,
      { kit_product: this.permitParams(value, ...this.params) },
      this.getParams(),
    );
  }

  update(id, value, kitId: number) {
    return this.http.put(
      `${this.adminURL}/kits/${kitId}/kit_products/${id}`,
      { kit_product: this.permitParams(value, ...this.params) },
      this.getParams(),
    );
  }

  destroy(id: number, kitId: number) {
    return this.http.delete(`${this.adminURL}/kits/${kitId}/kit_products/${id}`, this.getParams());
  }

  restore(id: number, kitId: number) {
    return this.http.delete(`${this.adminURL}/kits/${kitId}/kit_products/${id}/restore`, this.getParams());
  }
}
