import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgModule } from '@angular/core';
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

import { AnalyticsModule } from "../analytics/analytics.module";
import { PrioritiesModule } from "../priorities/priorities.module";
import { BaseModule } from "../shared/base.module";
import { CommentsAddonsModule } from "../shared/comments/comments.addons.module";
import { CommonFormsModule } from "../shared/common-forms/common-forms.module";
import { ImageUploaderModule } from "../shared/image-uploader/image-uploader.module";
import { SettingsModule } from "../settings/settings.module";
import { SignModule } from "../shared/sign/sign.module";
import { WorksAddonsModule } from "../shared/works/works.addons.module";
import { WorkModule } from "../work/work.module";

import { AgendaComponent } from "./agenda/agenda.component";
import { BookingComponent } from './booking/booking.component';
import { CheckInComponent } from "./check-in/check-in.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { DercoMailsModule } from "./derco-mails/derco-mails.module";
import { FilterComponent } from "./filter/filter.component";
import { MainComponent } from './main.component';
import { OrderDateRangeComponent } from './order-date-range/order-date-range.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderNotificationsComponent } from './order-notifications/order-notifications.component';
import { OrderComponent } from "./order/order.component";
import { OrdersComponent } from "./orders/orders.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { LocalSelectorComponent } from './local-selector/local-selector.component';
@NgModule({
    imports: [
      AnalyticsModule,
      BaseModule,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory
      }),
      CdkAccordionModule,
      CommentsAddonsModule,
      CommonFormsModule,
      DercoMailsModule,
      ImageUploaderModule,
      PrioritiesModule,
      SettingsModule,
      SignModule,
      WorkModule,
      WorksAddonsModule,
    ],
  declarations: [
    MainComponent,
    SidemenuComponent,
    BookingComponent,
    AgendaComponent,
    CheckInComponent,
    OrdersComponent,
    OrderComponent,
    FilterComponent,
    ConfirmDialogComponent,
    OrderNotificationsComponent,
    OrderHistoryComponent,
    OrderDateRangeComponent,
    LocalSelectorComponent
  ],
  exports: [
    MainComponent,
    SidemenuComponent,
    BookingComponent,
    AgendaComponent,
    DercoMailsModule,
    CheckInComponent,
    OrdersComponent,
    OrderComponent,
    FilterComponent,
    ConfirmDialogComponent,
    OrderNotificationsComponent,
    LocalSelectorComponent,
  ]
})
export class MainModule { }
