import {Component, Input, OnInit} from '@angular/core';
import {RoleService} from './role.service';
import { HttpResponse } from "@angular/common/http";
import {Role} from "../models/types";

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  models: { "name": string; "actions": any; }[] = [];
  actions = ['read', 'create', 'update', 'destroy'];
  role: {name: string, powers:any, id:number};
  roles: any[] = [];
  @Input() parentForm;
  working = false;
  importing = false;
  current_role = 0;
  constructor(public roleService: RoleService) { }

  ngOnInit() {
    this.roleService.index().toPromise().then((roles:HttpResponse<Role[]>) => {
      this.roles = roles.body;
      if (this.roles.length >= 0) {
        this.current_role = 0;
        if(this.parentForm){
          this.role = this.parentForm.value;
        }
        this.selectRole();
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  selectRole() {
    if(!this.parentForm){
      this.role = this.roles[this.current_role];
    }
    
    if(this.role && this.role.powers && Object.keys(this.role.powers).length > 0){
      this.models = Object.keys(this.role.powers).filter((k)=>k!=='admin').map((k) => {
        return {
          "name": k,
          "actions": Object.keys(this.role.powers[k])
        };
      });
    }else{
      this.role['powers'] = this.roles[0].powers;
      delete this.role.powers['admin'];
      this.resetPowers();
      this.parentForm.controls['powers'].setValue(this.role.powers);
      // HOOK AGAIN
      this.role = this.parentForm.value;
      this.models = Object.keys(this.role.powers).map((k) => {
        return {
          "name": k,
          "actions": Object.keys(this.role.powers[k])
        };
      });
    }
    if(this.parentForm){
      this.role = this.parentForm.value;
    }
  }
  resetPowers(){
    for(const key in this.role.powers){
      if(this.role.powers[key]){
        for(const action in this.role.powers[key]){
          if(this.role.powers[key][action] && action!=='own') {
            this.role.powers[key][action] = false;
          }
        }
      }
    }
  }
  importDefaults(){
    this.importing = true;
    this.roleService.import().toPromise().then((roles:HttpResponse<Role[]>) => {
      this.roles = roles.body;
      if (this.roles && this.roles.length >= 0) {
        this.current_role = 0;
        this.selectRole();
      }
      this.importing = false;
    }).catch((error) => {
      console.log(error);
      this.importing = false;
    });
  }
  createNewRole() {
    this.working = true;
    let name = "Nuevo Rol ";
    if (this.roles.length === 0) {
      name += "1";
    }else {
      name += this.roles[this.roles.length - 1].id + 1;
    }
    this.roleService.create({name: name}).toPromise().then((response) => {
      this.roles.push(response.body);
      this.current_role = this.roles.length - 1;
      this.selectRole();
      this.working = false;
    }).catch((error) => {
      console.log(error);
      this.working = false;
    });
  }
  saveRole() {
    this.roleService.update(this.role.id, this.role).toPromise().then((response)=>{

    }).catch((error) => {
      console.log(error);
    });
  }

}
