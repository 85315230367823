import { Injectable } from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService extends BaseService {
  params = ['token']
  model = 'Whatsapp';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(){
    return this.http.get(this.adminURL+'/whatsapp_configs',this.getParams({}));
  }

 create(value){
    return this.http.post(this.adminURL+'/whatsapp_configs/',{
      whatsapp_config: this.permitParams(value,...this.params)
    },this.getParams());
  }


  // update(id,value){
  //   return this.http.put(this.adminURL+'/whatsappconfig/'+id,{
  //     brand: this.permitParams(value,...this.params)
  //   },this.getParams());
  // }
}