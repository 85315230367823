import {
  Component,
} from '@angular/core';

@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.scss', '../admin-main.common.scss'],
})
export class AdminProductsComponent {

}
