import {Component, OnInit} from '@angular/core';
import {VehiclesService} from "../../services/vehicles.service";
import {FormControl, FormGroup, UntypedFormBuilder, Validators} from "@angular/forms";
import {BrandService} from "../../../services/brand.service";
import { FieldsService } from "app/admin/services/fields.service";
import {Brand} from "../../../models/types";
import { HttpResponse } from "@angular/common/http";
import { RoleService } from 'app/role/role.service';
import { AdminService } from 'app/admin/services/admin.service';

@Component({
  selector: 'app-admin-vehicles',
  templateUrl: './admin-vehicles.component.html',
  styleUrls: ['./admin-vehicles.component.scss','../admin-main.common.scss']
})
export class AdminVehiclesComponent implements OnInit {
  form = this.fb.group({
    client_id: '',
    brand_id: '',
    brand_model_id: '',
    color: '',
    identifier: '',
    chasis: '',
    kms: '',
    custom_fields: this.fb.group({})
  });
  vehicleConfigForm = this.fb.group({
    object_display: 'identifier'
  });
  identifiers = [
    {id: 'identifier', name: 'Patente'},
    {id: 'brand_name', name: 'Marca'},
    {id: 'brand_model_name', name: 'Modelo'},
  ];
  roles = [];
  brands = [];
  models = [];
  custom = [];
  constructor(
    public vehiclesService: VehiclesService,
    public brandService: BrandService,
    private fb: UntypedFormBuilder,
    public fieldsService: FieldsService,
    private roleService: RoleService,
    private adminService: AdminService
  ) {
  }
  ngOnInit() {
    this.fieldsService.fields('FieldForVehicle').subscribe(
      (fields) => {
        this.custom = fields;
        this.identifiers = this.identifiers.concat(fields.map((field) => {
          return {id: field.key, name: field.title}
        }));
      }
    );
    this.adminService.local$.subscribe((company)=>{
      if(company.preferences){
        this.vehicleConfigForm.patchValue(company.preferences);
      }
    })
    this.vehicleConfigForm.valueChanges.subscribe((value) => {
      this.adminService.updateCompany({
        preferences: value
      }).toPromise().then((value) => {
        this.adminService.setCompany(value.body);
      });
    });

    this.custom.forEach(field => {
      (<FormGroup>this.form.controls['custom_fields']).addControl(field.key,new FormControl('',{nonNullable: field.required}))
    })
    this.roleService.simple_index().toPromise().then((value: HttpResponse<any[]>)=>{
      this.roles = value.body;
    });
    this.brandService.all().toPromise().then((values:HttpResponse<Brand[]>) => {
      this.brands = values.body;
    }).catch((error) => {
      console.log(error);
    });
    this.brandService.getModels(0).toPromise().then((values:HttpResponse<Brand[]>) => {
      this.models = values.body;
    }).catch((error) => {
      console.log(error);
    });
  }

}
