import {Component, OnInit} from '@angular/core';
import {ApiKeysService} from "../../services/api-keys.service";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import { HttpResponse } from "@angular/common/http";
import {StatusesService} from "../../services/statuses.service";
import {RequirementsService} from "../../../services/requirements.service";
import {RoleService} from "../../../role/role.service";

@Component({
  selector: 'app-admin-apis',
  templateUrl: './admin-apis.component.html',
  styleUrls: ['./admin-apis.component.scss','../admin-main.common.scss']
})
export class AdminApisComponent implements OnInit {
  form = this.fb.group({
    name: ['',Validators.required],
    active: true,
    daily_request_limit: 500,
    scope: this.fb.group({
      roles_ids: [],
      requirements_ids: [],
      statuses_ids: []
    })
  });
  statuses = [];
  requirements = [];
  roles = [];
  constructor(
    public apiService: ApiKeysService,
    private fb: UntypedFormBuilder,
    private statusesService: StatusesService,
    private requirementService: RequirementsService,
    private roleService: RoleService
  ) { }

  ngOnInit() {
    this.statusesService.all().toPromise().then((response: HttpResponse<any[]>)=>{
      this.statuses = response.body;
    });
    this.requirementService.all().toPromise().then((response: HttpResponse<any[]>)=>{
      this.requirements = response.body;
    });
    this.roleService.simple_index().toPromise().then((value: HttpResponse<any[]>)=>{
      this.roles = value.body;
    });
  }
  copyKey(text){
    const textArea = document.createElement("textarea");
    textArea.value = text
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

}
