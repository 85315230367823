import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ZendeskService } from 'app/services/zendesk.service';
import moment from 'moment';
import { first } from 'rxjs/operators';

import { AdminService } from '../../admin/services/admin.service';
import { HolydaysService } from '../../services/holydays.service';
import { RequirementsService } from '../../services/requirements.service';
import { ClientService } from '../client.service';
import { ClientBookingComponent } from '../client-booking/client-booking.component';
import { ClientMessageComponent } from '../client-message/client-message.component';

@Component({
  selector: 'app-client-notification',
  templateUrl: './client-notification.component.html',
  styleUrls: ['./client-notification.component.scss'],
})
export class ClientNotificationComponent implements OnInit {
  client_token:string;

  loading = false;

  working = false;

  notification:any;

  currentDate = moment().toDate();

  notificationForm:any = {};

  requirements = [];

  company:any;

  onlyWeek;

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private domSanitizer: DomSanitizer,
    private companyService: AdminService,
    private requirementService: RequirementsService,
    private matDialog: MatDialog,
    private holydaysService: HolydaysService,
    private zendesk: ZendeskService,
  ) { }

  ngOnInit() {
    this.company = this.companyService.local$.getValue();
    this.loading = true;
    this.holydaysService.filterFunction(true).then((value) => {
      this.onlyWeek = value;
    });
    this.requirementService.all().toPromise().then((value:any) => {
      this.requirements = value.body;
    });
    this.route.queryParams.pipe(first()).forEach((value) => {
      if (value.client_token && value.notification_id) {
        this.client_token = value.client_token;
        this.clientService.getNotification(value.notification_id, this.client_token)
          .toPromise().then((response2:HttpResponse<any>) => {
            this.notification = response2.body;
            this.notification.safeBody = this.domSanitizer.bypassSecurityTrustHtml(this.notification.body);
            this.notification.safeTitle = this.domSanitizer.bypassSecurityTrustHtml(this.notification.title);
            this.loading = false;
          }).catch((error) => {
            console.log(error);
          });
      }
    });
    this.zendesk.hide();
  }

  createBooking(recall_id) {
    this.matDialog.open(ClientBookingComponent, {
      minWidth: '80%',
      data: {
        object: this.getVehicle(recall_id),
        client: this.notification.client,
        requirement_id: recall_id.to_requirement_id,
        booking_date: moment().utc(true).add(1, 'week').format('YYYY-MM-DD'),
        clientMode: true,
      },
    }).afterClosed().toPromise().then((value) => {
      if (value) {
        recall_id.booking = value;
        this.saveNotificationForm();
      }
    });
  }

  getRequirement(recall_id) {
    return this.requirements.find((r) => r.id === recall_id.to_requirement_id);
  }

  isBooked(recall) {
    const booked = this.getVehicle(recall).orders.filter(
      (value) => !value.correlative_id && !value.completed_at,
    ).length !== 0;
    return booked || recall.booked;
  }

  getVehicle(recall) {
    return this.notification.client.vehicles.find((v) => v.id === recall.object_id);
  }

  saveNotificationForm(f?: NgForm) {
    this.working = true;
    const body:any = {};
    if (f) {
      body.answer = f.value;
    } else {
      body.answer = { answer: true };
    }
    body.notification_id = this.notification.id;
    this.clientService.saveNotification(this.notification.id, body, this.client_token).toPromise().then(() => {
      this.working = false;
      const message = 'Hemos recibido la información exitosamente';
      this.matDialog.open(ClientMessageComponent, {
        data: {
          message,
        },
        width: '100%',
        height: '100%',
        disableClose: true,
        minWidth: '100%',
        minHeight: '100%',
      }).afterClosed();
    }).catch(() => {
      this.working = false;
    });
  }
}
