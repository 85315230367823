  <div mat-dialog-title class="name">
    Requerir Trabajo
  </div>

  <div mat-dialog-content class="content">

    <form [formGroup]="form">
      <div>
        <mat-form-field>
          <mat-select placeholder="Seleccione Trabajo" required formControlName="work_id" (valueChange)="setWork($event)">
            <mat-option *ngFor="let work of works" [value]="work.id">
              {{ work.name }}
            </mat-option>
            <mat-option [value]="0">
              Otro
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field [hidden]="form.value.work_id != 0">
          <mat-select formControlName="roles_id" placeholder="Seleccione Roles" required [multiple]="true">
            <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput placeholder="Comentario" formControlName="name" autocomplete="off" [required]="!form.value.work_id">
        </mat-form-field>
      </div>
      <div>
        <!-- CUSTOM RESPONSES -->
        <wpl-products *ngIf="current_work && current_work.custom_response_type==='products'" [parentForm]="form" [value]="form.value.custom_params"></wpl-products>
        <!-- END CUSTOM RESPONSES -->
      </div>
    </form>

  </div>

  <div mat-dialog-actions class="footer">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button cdkFocusInitial [disabled]="!form.valid || working" (click)="sendRequest()">Crear</button>
  </div>