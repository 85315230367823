import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HolydaysService } from 'app/services/holydays.service';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from '../services/_base.service';
import { UserService } from '../services/user.service';

@Injectable()
export class WorkService extends BaseService {
  works$ = new BehaviorSubject([]);

  constructor(http:HttpClient, userService: UserService) {
    super(http, userService);
    this.userService.user$.subscribe((user) => {
      if (user) {
        this.updateWorks();
      }
    });
  }

  updateWorks() {
    this.all().toPromise().then((value:any) => {
      this.works$.next(value.body);
    }).catch((error) => {
      console.log(error);
    });
  }

  index(filters = {}, page, sort) {
    return this.http.post(`${this.apiURL}/works`, { filters, sort }, this.getOptions({ page }));
  }

  own() {
    return this.http.get(`${this.apiURL}/works/own`, this.getParams());
  }

  all() {
    return this.http.get(`${this.apiURL}/works/all`, this.getParams());
  }

  get(id:number) {
    return this.http.get(`${this.apiURL}/works/${id}`, this.getParams());
  }

  toggleItem(id, object) {
    return this.http.post(`${this.apiURL}/works/toggle`, { id, work_status: object }, this.getOptions());
  }

  update(id, object) {
    return this.http.put(`${this.apiURL}/works/${id}`, { work: object }, this.getParams());
  }

  complete(id, body = {}) {
    return this.http.post(`${this.apiURL}/works/complete`, { id, work: body }, this.getOptions());
  }

  customWorks(params) {
    return this.http.get(`${this.apiURL}/works/custom`, this.getParams(params));
  }

  onlyPriorityJobs(filters = {}, page, sort) {
    return this.http.post(`${this.apiURL}/works/only_priority`, { filters, sort }, this.getOptions({ page }));
  }

  requestWork(work_request: any) {
    return this.http.post(`${this.apiURL}/works/work_request`, { work_request }, this.getOptions());
  }

  updateStatus(id, status_id) {
    return this.http.post(`${this.apiURL}/works/${id}/update_status`, { status_id }, this.getParams());
  }

  reject(id, body) {
    return this.http.post(`${this.apiURL}/works/${id}/reject`, { rejection: body }, this.getParams());
  }

  saveTimers(id, body) {
    return this.http.post(`${this.apiURL}/works/${id}/save_timers`, { timers: body }, this.getParams());
  }

  order(ids) {
    return this.http.post(`${this.apiURL}/works/order`, { ids }, this.getParams());
  }
}
