import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CameraService } from 'app/shared/image-uploader/camera/camera.service';
import moment from 'moment';
import { debounceTime, first } from 'rxjs/operators';

import { AdditionalsService } from '../../services/additionals.service';
import { AdminService } from '../../services/admin.service';
import { RequirementsService } from '../../services/requirements.service';

@Component({
  selector: 'app-admin-booking',
  templateUrl: './admin-booking.component.html',
  styleUrls: ['./admin-booking.component.scss', '../admin-main.common.scss'],
})
export class AdminBookingComponent implements OnInit {
  @Input() hideAdditionals = false;

  @Input() hideHours = false;

  @Input() hideHolydays = false;

  @Input() hideRequirements = false;

  additionals;

  locations = [];

  now = moment();

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    price: new UntypedFormControl(),
    hide: new UntypedFormControl(),
    show_client_booking: new UntypedFormControl(),
  });

  formBooking = new UntypedFormGroup({
    preferences: new UntypedFormGroup({
      booking_mail: new UntypedFormGroup({
        banner_url: new UntypedFormControl(),
        banner_style: new UntypedFormControl(),
        text: new UntypedFormControl(),
        confirm_text: new UntypedFormControl(),
      }),
      booking_addon: new UntypedFormGroup({
        allowed_domains: new UntypedFormControl(),
        background_color: new UntypedFormControl(),
      }),
    }),
  });

  requirementForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    max_daily_bookings: new UntypedFormControl(),
    duration: new UntypedFormControl(),
    price: new UntypedFormControl(),
    location_ids: new UntypedFormControl([]),
  });

  constructor(
    public additionalsService: AdditionalsService,
    public requirementsService: RequirementsService,
    private companyService: AdminService,
    private cameraService: CameraService,
  ) {}

  ngOnInit() {
    this.companyService.local$.subscribe((company) => {
      this.formBooking.patchValue(company);
      this.locations = company.locations;
    });
    this.formBooking.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.companyService.updateCompany(this.formBooking.value).pipe(first()).subscribe();
    });
  }

  onFileChange(event, form) {
    const uploadTask = this.cameraService.saveImage(event.target.files[0]);
    uploadTask.then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        console.log(url, form);
        form.patchValue({
          preferences: {
            booking_mail: {
              banner_url: url,
            },
          },
        });
      });
    });
  }
}
