import { HttpResponse } from '@angular/common/http';
import {
  Component, Inject, Input, OnInit, Optional,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { UserService } from 'app/services/user.service';
import { cloneDeep } from 'lodash-es';
import { debounceTime, first } from 'rxjs/operators';

import { CrmClientComponent } from '../../crm/crm-client/crm-client.component';
import { ConfirmDialogComponent } from '../../main/confirm-dialog/confirm-dialog.component';
import { OrderComponent } from '../../main/order/order.component';
import { RoleService } from '../../role/role.service';
import { StatusesService } from '../../services/statuses.service';
import { SignComponent } from '../../shared/sign/sign.component';
import { AssignUserComponent } from '../assign-user/assign-user.component';
import { SetStatusComponent } from '../set-status/set-status.component';
import { WorkService } from '../work.service';
import { WorkNextComponent } from '../work-next/work-next.component';
import { WorkRejectionComponent } from '../work-rejection/work-rejection.component';

@Component({
  selector: 'app-work-dialog',
  templateUrl: './work-dialog.component.html',
  styleUrls: ['./work-dialog.component.scss'],
})
export class WorkDialogComponent implements OnInit {
  @Input() work: any = null;

  @Input() clientMode = false;

  error = false;

  statuses = [];

  placeholders = [];

  filterCustom = [];

  form = new UntypedFormGroup({
    pictures: new UntypedFormControl([]),
    sign_url: new UntypedFormControl(''),
    next_custom_work_id: new UntypedFormControl(null),
    custom_fields: new UntypedFormGroup({}),
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private workService: WorkService,
    public roleService: RoleService,
    @Optional() private dialogRef: MatDialogRef<WorkDialogComponent>,
    private statusesService: StatusesService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public appService: AdminService,
  ) { }

  ngOnInit() {
    if (this.data) {
      this.workService.get(this.data.id).toPromise().then((work) => {
        this.setupWork(work.body);
      }).catch((error:any) => {
        if (error.status === 403) {
          this.error = true;
          this.dialogRef.close();
        }
      });
    } else if (this.work) {
      this.data = {};
      this.data.type = 'WorkRequest';
      this.setupWork(this.work);
    } else {
      this.route.params.pipe(first()).forEach((params) => {
        if (params.id) {
          this.workService.get(params.id).toPromise().then((work) => {
            this.setupWork(work.body);
          }).catch((error:any) => {
            if (error.status === 403) {
              this.router.navigate(['main', 'work']);
            }
          });
        }
      });
    }
    this.statusesService.statuses('StatusForOrder').pipe(first()).subscribe((value) => {
      this.statuses = value;
    });
  }

  setupWork(work:any) {
    this.filterCustom = work.work?.custom_field_ids;
    this.form.patchValue(work);
    this.work = work;
    this.getPlaceholders();
    this.form.get('custom_fields').valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (value && value !== this.work.custom_fields) {
        this.work.custom_fields = value;

        this.workService.update(this.work.id, { custom_fields: value }).toPromise().then(() => {
          this.work = cloneDeep(this.work);
        });
      }
    });
  }

  toggle(item) {
    // CHECK LIST COMPLETED?
    this.workService.toggleItem(item.id, item).toPromise().catch((error) => {
      console.log(error);
    });
    this.getPlaceholders();
  }

  hasPendingPlaceholder() {
    return this.placeholders.filter((i) => !i.url).length > 0;
  }

  getPendingImages() {
    return this.placeholders.filter((i) => !i.url).length + Math.max(0, this.work.work.min_photos - this.work.pictures.filter((p) => !p.placeholder_id).length);
  }

  getPlaceholders() {
    if (this.work.work) {
      const placeholders = [];
      if (this.work.check_list) {
        this.work.work_statuses.forEach((item, index) => {
          const image = this.work.pictures.find((p) => p.placeholder_id === item.id);
          if (item.value == 0 && this.work.check_list.danger_requires_photo) {
            placeholders.push({
              name: this.work.check_list.check_list_items[index].name, danger: true, id: item.id, url: image ? image.url : null,
            });
          } else if (item.value == 1 && this.work.check_list.warn_requires_photo) {
            placeholders.push({
              name: this.work.check_list.check_list_items[index].name, warn: true, id: item.id, url: image ? image.url : null,
            });
          }
        });
      }
      this.placeholders = placeholders;
    } else {
      this.placeholders = [];
    }
  }

  remainingWork() {
    if (this.work && this.work.check_list) {
      const total = this.work.work_statuses.length;
      const completed = this.work.work_statuses.filter((i) => i.completed || i.value != null).length;
      return total - completed;
    }
    return 0;
  }

  waitingRevision() {
    return this.work && this.work.work && !this.work.completed_at && this.work.work.require_revision && this.work.revisor_id;
  }

  requiresRevision() {
    return this.work.work && this.work.work.require_revision && (!this.work.revisor_id || !this.isRevisor());
  }

  isRevisor() {
    return (this.work.revisor_id && this.work.revisor_id === this.userService.user$.getValue().id);
  }

  complete() {
    if (this.form.valid) {
      let body = '¿Desea continuar?';
      if (this.work.has_notifications) {
        body += ' Se enviará una notificación al usuario';
      }
      if (this.work.work && this.work.work.sign_needed) {
        this.dialog.open(SignComponent, { data: { sign_text: this.work.work.sign_text } }).afterClosed().toPromise().then((result) => {
          if (result) {
            this.workService.update(this.work.id, { sign_url: result }).toPromise().then((value) => {
              this.nextWork();
            });
          }
        });
      } else {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Finalizar Trabajo',
            body,
          },
        }).afterClosed().toPromise().then((result) => {
          if (result) {
            this.nextWork();
          }
        });
      }
    }
  }

  setUser() {
    this.dialog.open(AssignUserComponent, {
      data: this.work.work ? this.work.work.roles_id : this.work.roles_id,
    }).afterClosed().toPromise().then((value) => {
      if (value) {
        this.work.user_id = value.id;
        this.workService.update(this.work.id, { user_id: value.id }).toPromise().then((user) => {
          this.dialogRef.close();
        }).catch((error) => {
          console.log(error);
        });
      }
    });
  }

  nextWork() {
    if (this.work.work && this.work.work.next_custom_work_ids && this.work.work.next_custom_work_ids.length !== 0) {
      if (this.work.work.next_custom_work_ids.length === 1) {
        this.form.patchValue({ next_custom_work_id: this.work.work.next_custom_work_ids[0] });
        this.finalize();
      } else {
        this.dialog.open(WorkNextComponent, {
          data: { work_ids: this.work.work.next_custom_work_ids },
        })
          .afterClosed().toPromise().then((value) => {
            if (value) {
              this.form.patchValue({ next_custom_work_id: value });
              this.finalize();
            }
          });
      }
    } else {
      this.finalize();
    }
  }

  finalize() {
    this.workService.complete(this.work.id, this.form.value).toPromise().then(() => {
      if (!this.requiresRevision()) {
        this.work.completed_at = true;
        this.changeStatus();
      } else if (this.dialogRef instanceof MatDialogRef) this.dialogRef.close();
      else this.router.navigate(['main', 'work']);
    }).catch((error) => {
      console.log(error);
    });
  }

  saveImages(images:any[]) {
    const send = cloneDeep(images);
    send.forEach((i) => delete i.blob);
    this.workService.update(this.work.id, { pictures: send }).toPromise().then((response: HttpResponse<any>) => {
      const picsWithId = response.body.pictures;
      // ADD BLOB PREVIEW
      picsWithId.forEach((p) => {
        const imageWithBlob = images.find((i) => i.url === p.url);
        if (imageWithBlob) {
          p.blob = imageWithBlob.blob;
        }
      });
      this.work.pictures = picsWithId;
      this.work = cloneDeep(this.work);
      this.form.patchValue(this.work);
    }).catch((error) => {
      console.log(error);
    });
  }

  changeStatus() {
    if (this.work.work && this.work.work.can_change_status) {
      this.dialog.open(SetStatusComponent, {
        disableClose: true,
        data: {
          document_id: this.work.document_id,
          document_type: this.work.document_type,
          status_id: this.work.work.status_id,
          work_instance_id: this.work.id,
        },
      }).afterClosed().toPromise().then(() => {
        this.work.completed_at = true;
        this.dialogRef.close();
      });
    } else {
      this.work.completed_at = true;
      this.dialogRef.close();
    }
  }

  getCheckListName(id) {
    const index = this.work.check_list.check_list_items.findIndex((i) => i.id === id);
    if (index >= 0) {
      return this.work.check_list.check_list_items[index].name;
    }
    return 'Sin Nombre';
  }

  reject() {
    if (this.waitingRevision() && this.isRevisor()) {
      this.workService.reject(this.work.id, {
        reason: 'revision_rejected',
        comment: 'No cumple con los requisitos mínimos',
      }).toPromise().then((value) => {
        if (this.dialogRef instanceof MatDialogRef) this.dialogRef.close();
        else this.router.navigate(['main', 'work']);
      }).catch((reason1) => {
        console.log(reason1);
      });
    } else {
      this.dialog.open(WorkRejectionComponent).afterClosed().pipe(first()).forEach((form) => {
        if (form) {
          this.workService.reject(this.work.id, form).toPromise().then((value) => {
            this.dialogRef.close();
          }).catch((reason1) => {
            console.log(reason1);
          });
        }
      });
    }
  }

  openOrder(id) {
    this.dialog.open(OrderComponent, { data: id, minWidth: '80%' });
  }

  openClient(id: number) {
    this.dialog.open(CrmClientComponent, { data: { id }, minWidth: '80%' });
  }

  openBudget(id) {
    this.router.navigate(['main', 'budgets', id], { queryParams: { work_id: this.work.id } });
    this.dialogRef.close();
  }

  cloneData() {
    if (this.work && this.work.work_request) {
      this.work.custom_params = cloneDeep(this.work.work_request.custom_params);
      this.form.patchValue({ custom_params: this.work.work_request.custom_params });
    }
  }

  calcPoints(statuses, type) {
    let value = 0;
    if (type == 'check') {
      value = statuses.filter((s) => s.revision_completed).length / statuses.length * 100;
    }
    if (type == 'radio') {
      value = statuses.reduce((a, b) => a + b.revision_value, 0) / (statuses.length * 2) * 100;
    }
    // round
    return Math.round(value);
  }

  getGallery() {
    if (this.clientMode) return null;

    if (this.work.document_type === 'Order') {
      return this.work.order.order_attachments.concat(this.work.order.pictures);
    } if (this.work.document_type === 'Budget') {
      return this.work.budget.order.order_attachments.concat(this.work.budget.pictures);
    }

    return [];
  }
}
