import {NgModule} from '@angular/core';
import {VehiclesComponent} from './vehicles.component';
import {BaseModule} from "../shared/base.module";


@NgModule({
  declarations: [VehiclesComponent],
  imports: [
    BaseModule,
  ]
})
export class VehiclesModule { }
