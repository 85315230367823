import { environment } from '../environments/environment';

// API URL
export const API_URL = environment.apiUrl;
export const MPAGO_APPID = environment.mpagoAppid;
export const WORKPOINT_URL = environment.workpointUrl;

export const FIREBASE = {
  apiKey: 'AIzaSyDaV0xUSPfMdWWtURNDBUoxfLTPJPA02ac',
  authDomain: 'cardcontrol-4793f.firebaseapp.com',
  databaseURL: 'https://cardcontrol-4793f.firebaseio.com',
  projectId: 'cardcontrol-4793f',
  storageBucket: 'cardcontrol-4793f.appspot.com',
  messagingSenderId: '344791511531',
  appId: '1:344791511531:web:cde29b44999f1faba529ae',
};
export const CURRENT_VERSION = '8.1.0';
