import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Brand, BrandModel } from 'app/models/types';
import { BehaviorSubject, first } from 'rxjs';

import { BaseService } from './_base.service';
import { UserService } from './user.service';

@Injectable(
  {
    providedIn: 'root',
  },
)
export class BrandService extends BaseService {
  private brands = new BehaviorSubject<Brand[]>([]);

  public brands$ = this.brands.asObservable();

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
    this.all().pipe(first()).subscribe((response) => {
      this.brands.next(response.body);
    });
  }

  all() {
    return this.http.get<Brand[]>(`${this.apiURL}/brands`, this.getParams());
  }

  getModels(id: number) {
    return this.http.get<BrandModel[]>(`${this.apiURL}/brands/${id}/models`, this.getParams());
  }
}
