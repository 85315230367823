import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';
import {
  BehaviorSubject,
  map,
  Observable,
  tap,
} from 'rxjs';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

export interface Field {
  id: number;
  company_id: number;
  title: string;
  key: string;
  order: number;
  required: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  show_client: boolean;
  field_type: string;
  field_filter: number[];
  show_booking: boolean;
  require_booking: boolean;
  hide_optional: boolean;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class FieldsService extends BaseService implements ICRUDService {
  params = [
    'field_type',
    'field_filter',
    'hide_optional',
    'order',
    'required',
    'require_booking',
    'show_booking',
    'show_client',
    'title',
    'type',
  ];

  private allFields$ = new BehaviorSubject<Field[]>([]);

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
    this.updateFields();
  }

  index(value = null, page = 1, type = 'FieldForOrder') {
    return this.http.get(`${this.adminURL}/fields`, this.getParams({ search: value, page, type }));
  }

  create(value: Partial<Field>, type = 'FieldForOrder') {
    Object.assign(value, { type });
    return this.http.post(
      `${this.adminURL}/fields/`,
      { field: this.permitParams(value, ...this.params) },
      this.getParams(),
    ).pipe(
      tap(() => {
        this.updateFields();
      }),
    );
  }

  update(id: number, value: Partial<Field>) {
    return this.http.put(
      `${this.adminURL}/fields/${id}`,
      { field: this.permitParams(value, ...this.params) },
      this.getParams(),
    ).pipe(
      tap(() => {
        this.updateFields();
      }),
    );
  }

  destroy(id: number) {
    return this.http.delete(`${this.adminURL}/fields/${id}`, this.getParams())
      .pipe(
        tap(() => {
          this.updateFields();
        }),
      );
  }

  restore(id: number) {
    return this.http.delete(`${this.adminURL}/fields/${id}/restore`, this.getParams())
      .pipe(
        tap(() => {
          this.updateFields();
        }),
      );
  }

  all(type?: string): Observable<Field[]> {
    return this.http.get<Field[]>(`${this.adminURL}/fields/all`, this.getParams({ type }))
      .pipe(
        map((httpResponse: HttpResponse<Field[]>) => httpResponse.body ?? []),
      );
  }

  swap(ids: number[]) {
    return this.http.post(`${this.adminURL}/fields/swap`, { ids }, this.getParams());
  }

  fields(type?: string): Observable<Field[]> {
    if (!type) {
      return this.allFields$.asObservable();
    }

    return this.allFields$.asObservable().pipe(
      map((fields) => fields.filter((field: Field) => field.type === type)),
    );
  }

  private updateFields() {
    this.all().pipe(
      tap((allFields: Field[]) => {
        this.allFields$.next(allFields);
      }),
    ).subscribe();
  }
}
