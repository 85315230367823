import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Brand } from 'app/models/types';
import { RoleService } from 'app/role/role.service';
import { BrandService } from 'app/services/brand.service';
import { ProductsService } from 'app/services/products.service';
import { catchError, Observable, of } from 'rxjs';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent implements OnInit {
  public brands$: Observable<Brand[]>;

  categoryId: number;

  categoryName: string;

  public form = this.fb.group(
    {
      brand_ids: this.fb.control([]),
      code: this.fb.control('', Validators.required),
      custom_fields: new UntypedFormGroup({}),
      description: this.fb.control('', Validators.required),
      name: this.fb.control('', Validators.required),
      price: this.fb.control(0.00, Validators.required),
      unit: this.fb.control(''),
    },
  );

  public saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private brandService: BrandService,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<ProductFormComponent>,
    private productService: ProductsService,
    public roleService: RoleService,
  ) {
    this.categoryId = data.categoryId;
    this.categoryName = data.categoryName;
    this.form.get('code').setValue(data.searchValue || '');
  }

  ngOnInit() {
    this.brands$ = this.brandService.brands$;
  }

  createProduct() {
    if (this.form.invalid) {
      return;
    }

    this.saving = true;
    this.productService.create({ product: this.form.value, category_id: this.categoryId }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.saving = false;
        console.error(error);
        return of(null);
      }),
    ).subscribe((product) => {
      this.saving = false;
      this.matDialogRef.close(product);
    });
  }

  disabledButton() {
    return this.form.invalid || this.saving;
  }
}
