import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class ListsItemsService extends BaseService implements ICRUDService{
  params = ['name']
  model = 'ListItem';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null,page = 1,list_id){
    return this.http.get(this.adminURL+'/lists/'+list_id+'/list_items',this.getParams({search: value, page: page}));
  }
  create(value, list_id){
    return this.http.post(this.adminURL+'/lists/'+list_id+'/list_items',{
      list_item: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value, list_id){
    return this.http.put(this.adminURL+'/lists/'+list_id+'/list_items/'+id,{
      list_item: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id, list_id){
    return this.http.delete(this.adminURL+'/lists/'+list_id+'/list_items/'+id,this.getParams());
  }
  restore(id, list_id){
    return this.http.delete(this.adminURL+'/lists/'+list_id+'/list_items/'+id+'/restore',this.getParams());
  }
}
