import { Route } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { adminRoutes } from './admin/admin.routes';
import { AnalyticsComponent } from './analytics/analytics.component';
import { CanActivateMain, CanActivateMenu } from './app.permissions';
import { BudgetViewComponent } from './budget/budget-view/budget-view.component';
import { BudgetsComponent } from './budget/budgets/budgets.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ClientComponent } from './client/client.component';
import { clientRoutes } from './client/client.routes';
import { CRMComponent } from './crm/crm.component';
import { crmRoutes } from './crm/crm.routes';
import { InventoryComponent } from './inventory/inventory.component';
import { inventoryRoutes } from './inventory/inventory.routes';
import { LoginComponent } from './login/login.component';
import { AgendaComponent } from './main/agenda/agenda.component';
import { CheckInComponent } from './main/check-in/check-in.component';
import { MainComponent } from './main/main.component';
import { OrderComponent } from './main/order/order.component';
import { OrdersComponent } from './main/orders/orders.component';
import { ManagerComponent } from './manager/manager.component';
import { managerRoutes } from './manager/manager.routes';
import { PaymentsComponent } from './payments/payments.component';
import { RoleComponent } from './role/role.component';
import { SettingsComponent } from './settings/settings.component';
import { SharesComponent } from './shares/shares.component';
import { IframeRejectedComponent } from './utils/iframe-rejected/iframe-rejected.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { WorkComponent } from './work/work.component';
import { WorkDialogComponent } from './work/work-dialog/work-dialog.component';
import { WorkPointComponent } from './work-point/work-point.component';
import { workPointRoutes } from './work-point/work-point.routes';
import { ZipComponent } from './zip/zip.component';

export const routes: Route[] = [
  { path: 'login', component: LoginComponent },
  { path: 'iframe-rejected', component: IframeRejectedComponent },
  { path: 'booking-addon', redirectTo: 'client/booking' },
  { path: 'client', component: ClientComponent, children: clientRoutes },
  { path: 'zip/:model/:id', component: ZipComponent },
  { path: 'workpoint', component: WorkPointComponent, children: workPointRoutes },
  { path: 'manager', component: ManagerComponent, children: managerRoutes },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [CanActivateMain],
    children: [
      { path: 'booking', component: AgendaComponent, canActivate: [CanActivateMenu] },
      { path: 'calendar', component: CalendarComponent, canActivate: [CanActivateMenu] },
      { path: 'checkin', component: CheckInComponent, canActivate: [CanActivateMenu] },
      { path: 'orders', component: OrdersComponent, canActivate: [CanActivateMenu] },
      { path: 'orders/:id', component: OrderComponent, canActivate: [CanActivateMenu] },
      { path: 'roles', component: RoleComponent, canActivate: [CanActivateMenu] },
      { path: 'analytics', component: AnalyticsComponent, canActivate: [CanActivateMenu] },
      { path: 'payments', component: PaymentsComponent, canActivate: [CanActivateMenu] },
      { path: 'vehicles', component: VehiclesComponent, canActivate: [CanActivateMenu] },
      { path: 'shares', component: SharesComponent, canActivate: [CanActivateMenu] },
      { path: 'work', component: WorkComponent, canActivate: [CanActivateMenu] },
      { path: 'work/:id', component: WorkDialogComponent, canActivate: [CanActivateMenu] },
      { path: 'settings', component: SettingsComponent },
      { path: 'budgets', component: BudgetsComponent },
      { path: 'budgets/:id', component: BudgetViewComponent },
      // { path: 'inventory/products/:id', component: InventoryProductDetailsComponent },
      {
        path: 'crm', component: CRMComponent, canActivate: [CanActivateMenu], children: crmRoutes,
      },
      {
        path: 'inventory', component: InventoryComponent, canActivate: [CanActivateMenu], children: inventoryRoutes,
      },
      { path: '**', redirectTo: 'booking' },
    ],
  },
  { path: 'admin', component: AdminComponent, children: adminRoutes },
  { path: '**', redirectTo: 'login' },
];
