import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from 'app/models/product.model';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseService implements ICRUDService {
  params = [
    'brand_ids',
    'category_ids',
    'code',
    'custom_fields',
    'description',
    'name',
    'price',
    'unit',
  ];

  model = 'Product';

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(search = null, page = 1, category_id = null) {
    return this.http.get(
      `${this.adminURL}/products/`,
      this.getParams({ search, page, category_id }),
    );
  }

  create(value: Product) {
    return this.http.post(`${this.adminURL}/products/`, {
      product: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id: number, value: Product) {
    return this.http.put(`${this.adminURL}/products/${id}`, {
      product: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  destroy(id: number) {
    return this.http.delete(
      `${this.adminURL}/products/${id}`,
      this.getParams(),
    );
  }

  restore(id: number) {
    return this.http.delete(
      `${this.adminURL}/products/${id}/restore`,
      this.getParams(),
    );
  }

  import(items = null) {
    return this.http.post(
      `${this.adminURL}/products/import`,
      { items },
      this.getParams(),
    );
  }
}
