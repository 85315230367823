import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedItems } from 'app/models/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client } from '../models/client.model';
import { BaseService } from './_base.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService extends BaseService {
  private clients$: BehaviorSubject<Client[]> = new BehaviorSubject([]);

  select_with_object(
    searchValue?: string,
    page?: number,
    page_size?: number,
    sort?: string,
  ): Observable<PaginatedItems<Client>> {
    return this.http.get<PaginatedItems<Client>>(
      `${this.apiURL}/clients/select_with_object`,
      this.getParams({
        search: searchValue,
        page,
        page_size,
        sort,
      }),
    )
      .pipe(
        map((response: HttpResponse<PaginatedItems<Client>>) => response.body),
      );
  }

  all(): Observable<HttpResponse<Client[]>> {
    return this.http.get<Client[]>(`${this.apiURL}/clients/all`, this.getParams());
  }

  create(data): Observable<Client> {
    return this.http
      .post<Client>(`${this.apiURL}/clients`, data, this.getParams())
      .pipe(map((response: HttpResponse<Client>) => response.body));
  }

  get(id: number): Observable<Client> {
    return this.http
      .get<Client>(`${this.apiURL}/clients/${id}`, this.getParams())
      .pipe(map((response: HttpResponse<Client>) => response.body));
  }

  get allClients(): Observable<Client[]> {
    return this.clients$.asObservable();
  }
}
