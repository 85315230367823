
<div mat-dialog-title class="name">
  Seleccione imagenes
</div>

<div mat-dialog-content class="content">


  <div class="wrapper images" #scrollTarget>
    <div
        class="image"
        [class.select]="index(image)>=0"
        *ngFor="let image of photos"
        [lazyLoad]="getThumbUrl(image)"
        offset="100"
        [scrollTarget]="scrollTarget"
        defaultImage="https://media.giphy.com/media/N256GFy1u6M6Y/giphy.gif"
        (click)="selectImage(image)"
    >
      <div class="exit" [hidden]="index(image)>=0"><i class="fa fa-plus"></i></div>
      <div class="remove" [hidden]="index(image) === -1"><i class="fa fa-times"></i></div>
      <div class="selected" [hidden]="index(image) === -1"><i class="fa fa-check"></i></div>
      <div class="zoom" (click)="currentImage = image"><i class="fa fa-search"></i></div>
    </div>
    <app-image-zoom [image]="currentImage" [photos]="photos"></app-image-zoom>
  </div>


</div>


<div mat-dialog-actions class="footer">
<button mat-button (click)="toggleSelectAll()">Todas</button>
<div class="expander"></div>
<button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>

<button mat-button (click)="sendImages()">
<span class="display-normal">Continuar</span>
<span class="display-xs-screen"><mat-icon>arrow_forward</mat-icon></span>


</button>
</div>
