import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequirementTransition } from 'app/models/requirement-transition.model';
import { PaginatedItems } from 'app/models/types';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class RequirementTransitionsService extends BaseService implements ICRUDService {
  params = ['from_requirement_id', 'to_requirement_id', 'wait_months'];

  model = 'StatusTransitionForOrder';

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(value = null, page = 1) {
    return this.http.get<PaginatedItems<RequirementTransition>>(
      `${this.adminURL}/requirement_transitions`,
      this.getParams({ search: value, page }),
    );
  }

  create(value) {
    return this.http.post(`${this.adminURL}/requirement_transitions/`, {
      requirement_transition: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id, value) {
    return this.http.put(`${this.adminURL}/requirement_transitions/${id}`, {
      requirement_transition: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  destroy(id) {
    return this.http.delete(`${this.adminURL}/requirement_transitions/${id}`, this.getParams());
  }

  restore(id) {
    return this.http.delete(`${this.adminURL}/requirement_transitions/${id}/restore`, this.getParams());
  }

  import(items = null) {
    return this.http.post(`${this.adminURL}/requirement_transitions/import`, { items }, this.getParams());
  }
}
