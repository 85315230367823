import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'period' })
export class PeriodPipe implements PipeTransform {
  private start_at: moment.Moment;
  private end_at: moment.Moment;

  transform(start_at: moment.Moment, end_at: moment.Moment, periodGroup: string): string {
    this.start_at = start_at;
    this.end_at = end_at;

    switch (periodGroup) {
      case 'year':
        return this.getYearPeriod();
      case 'month':
        return this.getMonthPeriod();
      case 'week':
        return this.getWeekPeriod();
      case 'day':
        return this.getDayPeriod();
      default:
        return this.getDefaultPeriod();
    }
  }

  private getYearPeriod(): string {
    if (this.start_at.isSame(moment(), 'year') && this.end_at.isSame(moment(), 'year')) {
      return 'este año';
    } else if (this.start_at.isSame(this.end_at, 'year')) {
      return `en ${this.start_at.format('YYYY')}`;
    } else {
      return `Entre ${this.start_at.format('YYYY')} y ${this.end_at.format('YYYY')}`;
    }
  }

  private getMonthPeriod(): string {
    if (this.start_at.isSame(moment(), 'month') && this.end_at.isSame(moment(), 'month')) {
      return 'este mes';
    } else if (this.start_at.isSame(this.end_at, 'month')) {
      return `en ${this.start_at.format('MMMM YYYY')}`;
    } else {
      return `Entre ${this.start_at.format('MMM YYYY')} y ${this.end_at.format('MMM YYYY')}`;
    }
  }

  private getWeekPeriod(): string {
    if (this.start_at.isSame(moment(), 'isoWeek') && this.end_at.isSame(moment(), 'isoWeek')) {
      return 'esta semana';
    } else {
      return `Entre el ${this.start_at.startOf('isoWeek').format('D MMM YYYY')}`
        + ` y el ${this.end_at.endOf('isoWeek').format('D MMM YYYY')}`;
    }
  }

  private getDayPeriod(): string {
    if (this.start_at.isSame(moment(), 'day') && this.end_at.isSame(moment(), 'day')) {
      return 'hoy';
    } else if (this.start_at.isSame(this.end_at, 'day')) {
      return `el ${this.start_at.format('D MMM YYYY')}`;
    } else {
      return `Entre el ${this.start_at.format('D MMM YYYY')} y el ${this.end_at.format('D MMM YYYY')}`;
    }
  }

  private getDefaultPeriod(): string {
    return 'en el periodo seleccionado'
  }
}
