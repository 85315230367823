import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import {UserService} from "./user.service";
import {BaseService} from "./_base.service";
import {Moment} from "moment";
import {AdminService} from "../admin/services/admin.service";

@Injectable()
export class HolydaysService extends BaseService{
  blockedDays = {blocks: {},holydays: []};
  days;
  constructor(http: HttpClient, userService: UserService, private adminService: AdminService) {
    super(http, userService);
    this.index().toPromise().then((value: HttpResponse<any>) => {
      this.blockedDays = value.body;
    });
  }
  index(){
    return this.http.get(this.apiURL+'/holydays',this.getParams());
  }
  filterFunction(calendar_mode?:boolean,preferences?): Promise<(d: Moment) => boolean> {
    return new Promise((resolve, reject) => {
      this.index().toPromise().then((value: HttpResponse<any>) => {
        this.blockedDays = value.body;
        if(calendar_mode){
          this.days = (preferences || this.adminService.local$.getValue().days_preferences).work_days;
        }else{
          this.days = (preferences || this.adminService.local$.getValue().days_preferences).days;
        }
        const onlyWeek = (d: Moment): boolean => {
          let holyday = false;
          let blocked = false;
          let day = null;
          let hasDay = false
          if (d) {
            const fdate = d.toISOString().split('T')[0];
            holyday = this.blockedDays.holydays.findIndex((v) => v === fdate) >= 0;
            if (!calendar_mode) {
              let d = this.blockedDays.blocks[fdate]
              if(d)
                blocked = d.all;
            }
            day = d.isoWeekday();
            if(this.days[day] === true || this.days[day] === false){
              hasDay = this.days[day - 1]
            }else{
              hasDay = this.days[day-1].enabled;
            }
          }
          return hasDay && !holyday && !blocked;
        };
        resolve(onlyWeek);
      }).catch(reason => {
        const onlyWeek = (d: Moment): boolean => {
          let day = null;
          const days = (preferences || this.adminService.local$.getValue().days_preferences).days;
          if (d) {
            day = d.isoWeekday();
            return days[day - 1];
          }else{
            return false;
          }
          
        };
        resolve(onlyWeek);
      });
    });
  }
}
