<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Lista de Trabajo - {{parentItem?.name || 'Items'}}</mat-card-title>
    <mat-card-subtitle>Administra listados de pasos para completar un trabajo.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="checkListItemsService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form" [args]="[id]" [showNavigation]="true" (back)="goUp()" *ngIf="id">
      <ng-template #itemTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
