<div class="card small">
  <div class="header">
    <div class="title">
      <div class="main-title">{{mainTitle}}</div>
      <div class="main-subtitle">{{mainSubtitle}}</div>
    </div>
    <div *ngIf="hasDetails" class="main-info" (click)="viewDetails(chartKey, filters)"><i class="fa fa-file"></i></div>
    <div class="expander"></div>
    <div class="values" [class.good]="performance > performanceThreshold" [class.bad]="performance < performanceThreshold">
      <i class="fa fa-caret-up"></i>
      <i class="fa fa-caret-down"></i>
      {{ value | number: numberFormat }}{{suffix}}
    </div>
  </div>
  <div class="chart-container">
    <app-chart
      [custom]="customCharts"
      [filters]="filters"
      [type]="chartType"
      mode="simple"
      (dataReady)="updateChart($event)"
      [stacked]="stacked"
      [dataLabelSuffix]="dataLabelSuffix"
      [snapshot_key]="snapshot" />
  </div>
</div>
