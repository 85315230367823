import { Injectable } from '@angular/core';

import { BaseService } from "../../services/_base.service";
import { ClientNotification } from 'app/models/client-notification.model';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';
import { PaginatedItems } from 'app/models/types';

@Injectable({
  providedIn: "root"
})
export class ClientNotificationsService extends BaseService implements ICRUDService {

  params = [
    'active',
    'answer_type',
    'banner_style',
    'banner_url',
    'complete_work',
    'name',
    'requirement_transitions_ids',
    'roles_ids',
    'send_at',
    'show_client',
    'show_order',
    'show_work_data',
    'statuses_ids',
    'text',
    'type',
    'whatsapp_active',
    'works_ids',
  ];

  model = 'ClientNotification';

  index(search: string, page = 1, type: string){
    return this.http.get<PaginatedItems>(
      this.adminURL + '/client_notifications',
      this.getParams({ search, page, type }),
    );
  }

  create(value: ClientNotification, type: string){
    Object.assign(value, { type });
    return this.http.post<ClientNotification>(
      this.adminURL + '/client_notifications/',
      {
        client_notification: this.permitParams(value,...this.params)
      },
      this.getParams(),
    );
  }

  update(id: number, value: ClientNotification, type: string) {
    Object.assign(value, { type });
    return this.http.put<ClientNotification>(
      this.adminURL + '/client_notifications/' + id,
      {
        client_notification: this.permitParams(value,...this.params)
      },
      this.getParams(),
    );
  }

  destroy(id: number){
    return this.http.delete(
      this.adminURL + '/client_notifications/' + id,
      this.getParams(),
    );
  }

  restore(id: number){
    return this.http.delete(
      this.adminURL + '/client_notifications/' + id + '/restore',
      this.getParams(),
    );
  }
}
