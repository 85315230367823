import {Component, OnInit} from '@angular/core';
import {StatusesService} from "../../services/statuses.service";
import {StatusTransitionsService} from "../../services/status-transitions.service";
import {WorksService} from "../../services/works.service";
import { HttpResponse } from "@angular/common/http";
import { PagedResponse } from 'app/shared/crud-manager/crud.interface';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-admin-workflow',
  templateUrl: './admin-workflow.component.html',
  styleUrls: ['./admin-workflow.component.scss','../admin-main.common.scss']
})
export class AdminWorkflowComponent implements OnInit {
  nodes:any[] = [];
  links:any[] = [];
  curve: any = shape.curveBundle.beta(1);
  constructor(
    private statusService: StatusesService,
    private transitionsSevice: StatusTransitionsService,
    private worksService: WorksService
  ) { }

  ngOnInit() {
    this.statusService.all().toPromise().then((response: HttpResponse<any[]>)=>{
      this.nodes = response.body.map((status)=> {
        return {id: status.id.toString(),label: status.name, height: 30, width: 70, color: status.color};
      });
      this.transitionsSevice.index().toPromise().then((r: HttpResponse<PagedResponse<any>>)=>{
        const links = r.body.items.map((transition)=> {
          return {source: transition.from_status_id.toString(), target: transition.to_status_id.toString(), label: ''};
        });
        this.worksService.index().toPromise().then((wr: HttpResponse<PagedResponse<any>>)=>{
          const works = wr.body.items;
          works.forEach((w)=>{
            links.forEach((l,index)=>{
              if(l.source === w.status_id.toString() && w.can_change_status){
                links[index].label = w.name;
              }
            });
          });
          this.links = links;
        });
      });
    });
  }

}
