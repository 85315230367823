import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';

import { WorkService } from '../work.service';

@Component({
  selector: 'app-work-request-bottom-sheet',
  templateUrl: './work-request-bottom-sheet.component.html',
  styleUrls: ['./work-request-bottom-sheet.component.scss'],
})
export class WorkRequestBottomSheetComponent implements OnInit {
  customActions = [];

  works = [];

  working = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data,
    private workService: WorkService,
    private bottomSheetRef: MatBottomSheetRef<WorkRequestBottomSheetComponent>,
    private snackBar: MatSnackBar,
  ) {
    this.customActions = this.data.customActions || [];
  }

  ngOnInit() {
    this.workService.customWorks(
      {
        status_id: this.data.status_id,
        budget_id: this.data.budget_id,
        type: this.data.work_type,
      },
    ).toPromise().then(
      (response: HttpResponse<any[]>) => {
        this.works = response.body;
      },
    ).catch((error) => {
      console.log(error);
    });
  }

  createWork(workId: number) {
    if (!this.working) {
      this.working = true;
      const requestData = {
        document_id: this.data.document_id,
        document_type: this.data.document_type,
        work_id: workId,
        custom_params: this.data.custom_params || {},
      };
      this.workService.requestWork(requestData).toPromise().then(() => {
        this.bottomSheetRef.dismiss(true);
      }).catch((error) => {
        console.log(error);
        this.snackBar.open('Error al crear el trabajo', 'Cerrar', { duration: 3000 });
        this.working = false;
      });
    }
  }

  close(value: string) {
    this.bottomSheetRef.dismiss(value);
  }
}
