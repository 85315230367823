import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkColumnsService} from "./work-columns.service";
import {cloneDeep} from "lodash-es";

@Component({
  selector: 'app-work-columns',
  templateUrl: './work-columns.component.html',
  styleUrls: ['./work-columns.component.scss']
})
export class WorkColumnsComponent implements OnInit {
  selectAll = false;
  columns = []
  constructor(
    private matDialogRef: MatDialogRef<WorkColumnsComponent>,
    private columnsService: WorkColumnsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.columns = cloneDeep(this.data.columns);
  }

  ngOnInit() {
    if(this.data.columns.filter((o)=>o[1]===true).length === this.data.columns.length){
      this.selectAll = true;
    }else{
      this.selectAll = false;
    }
  }
  applyFilters() {
    this.data.columns = cloneDeep(this.columns);
    this.columnsService.saveColumns(this.data.id,this.data.columns);
    this.matDialogRef.close(this.formatResult());
  }
  formatResult() {
    return this.data.columns;
  }

  closeDialog() {
    this.matDialogRef.close();
  }
  toggleSelectAll() {
    this.columns.forEach(option => option[1] = this.selectAll);
  }

  checkIfAllSelected() {
    this.selectAll = this.columns.every(option => option[1]);
  }
}
