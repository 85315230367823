<div class="header">
  <i class="fa fa-comment"></i> <div class="text" *ngIf="value">Comentario</div> <div class="text" *ngIf="!value">Comentario</div>
</div>
<div class="wrapper">
  <div class="content">
    <form [formGroup]="form">
      <fieldset>
        <mat-form-field>
          <input matInput placeholder="Comentario" required formControlName="comment" autocomplete="off">
        </mat-form-field>
      </fieldset>
    </form>
  </div>
</div>
