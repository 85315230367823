<form [formGroup]="form">
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-select placeholder="Trabajo" formControlName="work_id" required>
          <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <mat-select placeholder="Requerimiento" formControlName="requirement_id" required>
          <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field>
        <input matInput placeholder="Tiempo Base (hrs)" formControlName="base_time" autocomplete="off">
        <mat-hint>Tiempo de duración base</mat-hint>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <input matInput placeholder="Desviación Base (hrs)" formControlName="base_std_dev" autocomplete="off">
        <mat-hint>Desviación base</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field>
        <input matInput placeholder="Tiempo Promedio (hrs)" formControlName="avg_time" autocomplete="off">
        <mat-hint>Tiempo promedio calculado</mat-hint>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <input matInput placeholder="Desviación Estandar (hrs)" formControlName="std_dev" autocomplete="off">
        <mat-hint>Desviación estandar calculada</mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>
<button mat-raised-button color="accent" (click)="save()" [disabled]="!form.valid">
  <span>Guardar</span>
</button>
