import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component, Inject, OnInit, Optional, ViewChild, HostBinding,
} from '@angular/core';
import {
  FormArray, FormBuilder, FormGroup, Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { ClientMessageComponent } from 'app/client/client-message/client-message.component';
import {
  addAdditional,
  removeAdditional, setupInventoryActionForConsumeForm,
  setupOrderAdditionals,
} from 'app/main/booking/booking.component';
import { Booking } from 'app/models/booking.model';
import { CrmForBooking } from 'app/models/crm.model';
import { Requirement } from 'app/models/types';
import { User } from 'app/models/user';
import { RoleService } from 'app/role/role.service';
import { BookingService } from 'app/services/booking.service';
import { RequirementsService } from 'app/services/requirements.service';
import { UserService } from 'app/services/user.service';
import { ZendeskService } from 'app/services/zendesk.service';
import { InventoryActionFormComponent }
  from 'app/shared/common-forms/inventory-action-form/inventory-action-form.component';
import { cloneDeep, uniq } from 'lodash';
import moment from 'moment';
import {
  catchError, debounceTime, first, merge, of,
} from 'rxjs';

import { CRMService } from '../crm.service';

interface FreeBookingHoursData {
  hours: string[];
  errors: Record<string, boolean>;
}

@Component({
  selector: 'app-crm-booking',
  templateUrl: './crm-booking.component.html',
  styleUrls: ['../../main/booking/booking.component.scss', '../../client/client-booking/client-booking.component.scss'],
})
export class CrmBookingComponent implements OnInit {
  addAdditional = addAdditional;

  removeAdditional = removeAdditional;

  booking: Booking;

  bookingErrors;

  crmData: CrmForBooking;

  crmToken: string;

  company = this.adminService.local$.getValue();

  crmNotFound = false;

  form = this.fb.group({
    id: this.fb.control(null),
    booking_date: this.fb.control(moment().format('YYYY-MM-DD'), [Validators.required]),
    booking_time: this.fb.control(null, [Validators.required]),
    comment: '',
    from: 'crm-addon',
    inventory_action_for_consume: this.fb.group({
      inventory_action_products: this.fb.array([]),
    }),
    order_aditionals: this.fb.array([]),
    user_id: this.fb.control(null),
  });

  freeBookingHours: string[] = [];

  @ViewChild('inventoryForm') inventoryForm : InventoryActionFormComponent;

  loading = false;

  locationId: number;

  permittedHours: string[];

  professionals: User[] = [];

  requirements: Requirement[] = [];

  userId: number;

  constructor(
    private bookingService: BookingService,
    private snackBar: MatSnackBar,
    private requirementService: RequirementsService,
    private matDialog: MatDialog,
    public roleService: RoleService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private userService: UserService,
    private zendesk: ZendeskService,
    public fb: FormBuilder,
    private crmService: CRMService,
    private router: Router,
  ) {

  }

  get inventory_action_for_consume() {
    return this.form.controls.inventory_action_for_consume as FormGroup;
  }

  set inventory_action_for_consume(value) {
    this.form.controls.inventory_action_for_consume = value;
  }

  get inventory_action_products() {
    return this.inventory_action_for_consume.controls.inventory_action_products as FormArray;
  }

  set inventory_action_products(value) {
    this.inventory_action_for_consume.controls.inventory_action_products = value;
  }

  get order_aditionals() {
    return this.form.controls.order_aditionals as FormArray;
  }

  set order_aditionals(value) {
    this.form.controls.order_aditionals = value;
  }


  @HostBinding('style.backgroundColor') get backgroundColor() {
    return this.company.preferences && this.company.preferences.booking_addon
      ? this.company.preferences.booking_addon.background_color
      : 'white';
  }


  ngOnInit() {
    this.loading = true;

    this.requirementService.requirements$.subscribe((requirements) => {
      this.requirements = requirements;
    });

    this.route.queryParams.pipe(first()).forEach((value) => {
      if (value.crm_token) {
        this.loading = true;
        this.crmToken = value.crm_token;
        this.crmService.getData({ crm_token: this.crmToken }).pipe(
          catchError((error) => {
            this.loading = false;

            if (error.status === 404) {
              this.crmNotFound = true;
            }

            return of(null);
          }),
        ).subscribe((response) => {
          if (this.crmNotFound) {
            this.router.navigate(['client', 'booking']);
            return;
          }

          if (!response?.body || response.status !== 200) {
            this.loading = false;
            return;
          }
          this.crmData = response.body;
          this.booking = this.crmData.booking;

          if (this.booking) {
            this.form.patchValue(this.booking);
            this.form.controls.id.setValue(this.booking.id);
            this.setLocation();

            if (this.booking.order_aditionals.length > 0) {
              setupOrderAdditionals(this, this.booking);
            }

            if (this.booking.inventory_action_for_consume) {
              setupInventoryActionForConsumeForm(this, this.booking);
            }

            this.getProfessionals().then(
              () => {
                if (this.booking.user?.id) {
                  this.form.controls.user_id.setValue(this.booking.user.id);
                }
              },
            );
          } else {
            this.crmData = response.body;
            this.setLocation();
            this.getProfessionals().then(() => {
              this.form.controls.user_id.setValue(this.crmData.user?.id);
            });
          }
          this.loading = false;
        });
      }
    });

    merge(this.form.controls.user_id.valueChanges, this.form.controls.booking_date.valueChanges).pipe(
      debounceTime(500),
    ).subscribe((change) => {
      if (!change) return;

      // eslint-disable-next-line no-underscore-dangle
      if (change._isAMomentObject) {
        this.searchDate(this.form.controls.booking_date.value, null).then();
      } else {
        this.searchDate(this.form.controls.booking_date.value, this.booking?.booking_time).then();
      }
    });

    this.zendesk.hide();
  }

  searchDate(date: string, hour?: string) {
    return new Promise<void>((resolve) => {
      this.form.controls.booking_time.clearValidators();

      const queryData = {
        booking_date: date,
        requirement_id: this.crmData.requirement_id,
        object_id: this.crmData.object.id,
        object_type: this.company.object,
        client_id: this.crmData.client.id,
        location_id: this.crmData.location_id,
        kms: this.crmData.object.kms,
        user_id: this.form.get('user_id').value,
      };

      this.bookingService.searchFree(queryData).subscribe(
        (value:HttpResponse<FreeBookingHoursData>) => {
          this.freeBookingHours = value.body.hours || [];

          if (value.body.errors) {
            this.bookingErrors = Object.keys(value.body.errors).length > 0 ? value.body.errors : null;
            this.freeBookingHours = [];
          } else {
            this.bookingErrors = null;
          }

          this.permittedHours = cloneDeep(this.freeBookingHours);
          if (moment(date).isSame(moment(), 'day')) {
            this.freeBookingHours = this.freeBookingHours.filter((freeHour) => moment().format('HH:mm') < freeHour);
          }

          this.permittedHours = cloneDeep(this.freeBookingHours);
          if (this.booking && hour) {
            this.freeBookingHours.unshift(this.booking.booking_time);
            this.freeBookingHours = uniq(this.freeBookingHours);
            this.permittedHours.unshift(this.booking.booking_time);
            this.permittedHours = uniq(this.permittedHours);
          }
          if (hour && !this.booking) {
            this.freeBookingHours.unshift(hour);
            this.freeBookingHours = uniq(this.freeBookingHours);
            this.form.controls.booking_time.setValue(hour);
          }

          if (this.permittedHours.indexOf(this.form.get('booking_time').value) === -1) {
            this.form.controls.booking_time.setValue(null);
            this.form.controls.booking_time.setErrors({ invalid: true });
            this.form.controls.booking_time.markAsTouched();
            this.form.controls.booking_time.markAsDirty();
          }

          resolve();
        },
      );
    });
  }

  setLocation() {
    this.locationId = this.booking?.location_id;

    if (!this.locationId) {
      this.locationId = this.crmData.location_id;
    }
  }

  getProfessionals() {
    return new Promise<void>((resolve) => {
      if (this.company.preferences.booking_type !== 'professional') {
        resolve();
        return;
      }

      this.userService.professionals(this.locationId).subscribe((value: HttpResponse<User[]>) => {
        this.professionals = value.body.filter((u) => !u.work_user_specialities.filter((s) => !s.enabled)
          .map((s) => s.speciality_id)
          .includes(this.booking?.requirement_id));
        resolve();
      });
    });
  }

  getSelectedLocationName(): string {
    const locationId = this.crmData.location_id;
    const selectedLocation = this.company?.locations?.find((location) => location.id === locationId);
    return selectedLocation ? selectedLocation.name : '-';
  }

  getSelectedRequirementName(): string {
    const requirementId = this.crmData.requirement_id;
    const selectedRequirement = this.requirements?.find((requirement) => requirement.id === requirementId);
    return selectedRequirement ? selectedRequirement.name : '-';
  }

  getSelectedProfessionalName(): string {
    const userId = this.form.get('user_id')?.value;
    const selectedUser = this.professionals?.find((user) => user.id === userId);
    return selectedUser ? selectedUser.name : '-';
  }

  saveForm() {
    this.loading = true;
    const formData = this.form.value;

    const body = {
      ...formData,
      client_id: this.crmData.client.id,
      document_id: this.crmData.id,
      document_type: 'Crm',
      from: 'crm',
      location_id: this.crmData.location_id,
      object_id: this.crmData.object.id,
      object_type: this.company.object,
      requirement_id: this.crmData.requirement_id,
    };

    if (this.booking) {
      this.bookingService.update(
        this.booking.id,
        { booking: this.form.getRawValue() },
      )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.error(error);
            return of(null);
          }),
        )
        .subscribe((value) => {
          if (!value) return;

          this.success();
        });
    } else {
      this.bookingService.create({ booking: body }).pipe(
        catchError((error: HttpErrorResponse) => {
          this.error(error);
          return of(null);
        }),
      )
        .subscribe((value) => {
          this.loading = false;
          if (!value) return;

          this.success();
        });
    }
  }

  success() {
    let message = 'Cita agendada';
    if (this.booking && this.booking.id) {
      message = '¡Cita actualizada!';
    }

    this.matDialog.open(ClientMessageComponent, {
      data: {
        message,
      },
      width: '100%',
      height: '100%',
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      minHeight: '100vh',
    });
  }

  error(error: HttpErrorResponse) {
    const errors = [];
    Object.keys(error.error).forEach((key) => {
      if (error.error[key]) {
        errors.push(`${key}: ${error.error[key]}`);
        if (this.form[key]) {
          this.form[key].setErrors({ invalid: true });
          this.form[key].updateValueAndValidity();
        }
      }
    });
    if (errors.length === 0) {
      errors.push('Error desconocido');
    }
    this.snackBar.open(`¡OOPS! ${errors.join(',')}`, null, { duration: 2000 });
  }
}
