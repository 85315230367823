import {
  HttpClient, HttpHeaders, HttpParams, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { API_URL } from '../app.config';
import { BaseOptions } from '../models/types';
import { User } from '../models/user';
import { PUB_KEY } from '../utils/public.key';
import { ZendeskService } from './zendesk.service';

declare let JSEncrypt: any;
@Injectable()
export class UserService {
  public apiURL = API_URL;

  public user$: BehaviorSubject<User> = new BehaviorSubject(null);

  public token = localStorage.getItem('auth_token');

  users$ = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private router: Router,
    private authFirebase: AngularFireAuth,
    private zenddesk: ZendeskService,
  ) {
    this.user$.subscribe((user) => {
      if (user) {
        this.updateUsers();
      }
    });
  }

  updateUsers() {
    this.index().toPromise().then((value:HttpResponse<any[]>) => {
      this.users$.next(value.body || []);
    }).catch(() => {});
  }

  index() {
    return this.http.get(`${this.apiURL}/users`, this.getOptions());
  }

  professionals(location_id?): Observable<HttpResponse<User[]>> {
    return this.http.post<User[]>(`${this.apiURL}/users/professionals`, { location_id }, this.getOptions());
  }

  create(user: User) {
    return this.http.post(`${this.apiURL}/users`, { user: this.encryptPassword(user) }, this.getOptions());
  }

  update(user) {
    return this.http.put(`${this.apiURL}/users`, { user, auth_token: this.token }, this.getOptions());
  }

  login(user: User) {
    // SEND USER TO APP
    // Register in Firebase
    localStorage.setItem('encrypt_key', user.encrypt_key);
    this.authFirebase.signInWithCustomToken(user.firebase_token).then((value) => {
      console.log('[FIREBASE] Auth Completed.');
    });
    this.zenddesk.login(user.zendesk_token);
    this.user$.next(user);
    this.updateUsers();
  }

  refreshToken(token?) {
    if (token) {
      localStorage.setItem('auth_token', token);
      this.token = token;
    }
  }

  logout() {
    // STORAGE AUTH_KEY
    localStorage.setItem('auth_token', null);
    this.token = null;
    this.authFirebase.signOut();
    this.zenddesk.logout();
    // CLEAR USER
    this.user$.next(null);
  }

  auth(user: User) {
    return this.http.post(`${this.apiURL}/users/auth`, { user: this.encryptPassword(user) }, this.getOptions());
  }

  authCode(user: User, token?) {
    return this.http.post(`${this.apiURL}/users/auth_code`, { user: this.encryptPassword(user), company_token: token }, this.getOptions());
  }

  reauth(token?) {
    this.refreshToken(token);
    return this.http.post(`${this.apiURL}/users/reauth`, { auth_token: token || this.token }, this.getOptions());
  }

  recover(user: User) {
    return this.http.post(`${this.apiURL}/users/recover_password`, { user: this.encryptPassword(user) }, this.getOptions());
  }

  validateRecoveryToken(recover_token) {
    return this.http.post(`${this.apiURL}/users/validate_recovery_token`, { recovery_token: recover_token }, this.getOptions());
  }

  changePassword(value) {
    return this.http.post(`${this.apiURL}/users/change_password`, {
      user: this.encryptPassword(value), auth_token: this.token,
    }, this.getOptions());
  }

  newPassword(newPassword, recovery_token) {
    return this.http.post(`${this.apiURL}/users/new_password`, {
      user: this.encryptPassword(newPassword), recovery_token,
    }, this.getOptions());
  }

  setOnlineStatus(online) {
    return this.http.post(`${this.apiURL}/users/online`, { user: { online }, auth_token: this.token }, this.getOptions());
  }

  encryptPassword(user: User) {
    const copyUser = cloneDeep(user);
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUB_KEY);
    for (const key in copyUser) {
      if (key.match('password')) {
        copyUser[key] = encrypt.encrypt(copyUser[key]);
      }
    }
    return copyUser;
  }

  enable2FA() {
    return this.http.post(`${this.apiURL}/users/enable2fa`, { auth_token: this.token }, this.getOptions());
  }

  validate2FA(value) {
    return this.http.post(`${this.apiURL}/users/validate2fa`, {
      user: this.encryptPassword(value), auth_token: this.token,
    }, this.getOptions());
  }

  disable2FA(value) {
    return this.http.post(`${this.apiURL}/users/disable2fa`, {
      user: this.encryptPassword(value), auth_token: this.token,
    }, this.getOptions());
  }

  authCallback(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.reauth().toPromise().then((response:any) => {
        this.login(response.body);
        resolve(true);
      }).catch((error) => {
        this.logout();
        resolve(false);
      });
    });
  }

  adminAuthCallback(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.reauth().toPromise().then((response:any) => {
        this.login(response.body);
        resolve(response.body.role.powers.admin);
      }).catch(() => {
        this.logout();
        resolve(false);
      });
    });
  }

  managerAuthCallback(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.reauth().toPromise().then((response:any) => {
        this.login(response.body);
        resolve(response.body.role.powers.admin || response.body.role.powers.manager.read);
      }).catch(() => {
        this.logout();
        resolve(false);
      });
    });
  }

  getOptions(addParams?:any): BaseOptions {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', '0');
    const paramsObject: any = {
      'ngsw-bypass': 'true',
    };
    for (const key in addParams) {
      if (key && addParams[key]) {
        paramsObject[key] = addParams[key];
      }
    }
    if (this.token) {
      paramsObject.auth_token = this.token;
    }
    if (localStorage.getItem('workpoint_token')) {
      paramsObject.company_token = localStorage.getItem('workpoint_token');
    }
    if (localStorage.getItem('locations')) {
      paramsObject.locations = localStorage.getItem('locations');
    }
    const params = new HttpParams({ fromObject: paramsObject });
    const options: BaseOptions = {
      headers,
      observe: 'response',
      responseType: 'json',
      params,
    };
    return options;
  }
}
