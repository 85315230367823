import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-sidenav',
  templateUrl: './manager-sidenav.component.html',
  styleUrl: './manager-sidenav.component.scss'
})
export class ManagerSidenavComponent {

}
