import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagerComponent } from './manager.component';
import { ManagerLoginComponent } from './manager-login/manager-login.component';
import { BaseModule } from '../shared/base.module';
import { CanActivateManager } from './manager.permissions';
import { ManagerMainComponent } from './manager-main/manager-main.component';
import { ManagerDatabasesComponent } from './manager-databases/manager-databases.component';
import { ManagerSidenavComponent } from './manager-sidenav/manager-sidenav.component';



@NgModule({
  declarations: [
    ManagerLoginComponent,
    ManagerComponent,
    ManagerMainComponent,
    ManagerDatabasesComponent,
    ManagerSidenavComponent
  ],
  imports: [
    CommonModule,
    BaseModule
  ],
  providers: [
    CanActivateManager
  ]
})
export class ManagerModule { }
