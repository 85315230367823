import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {RoleService} from "../../role/role.service";
import {OrderComponent} from "../../main/order/order.component";
import {PaymentsService} from "../payments.service";
import {merge} from 'lodash-es';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public payment,
    public roleService: RoleService,
    private dialog: MatDialog,
    private paymentService: PaymentsService
  ) {
    console.log(payment)
  }

  ngOnInit(): void {
  }
  openOrder(id){
    this.dialog.open(OrderComponent,{data: id, minWidth: '80%'});
  }
  reprocess(id){
    this.paymentService.processPayment(id).toPromise().then(value => {
      merge(this.payment,value.body);
    }).catch(reason => {

    })
  }

}
