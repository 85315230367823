import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '../../../../services/_base.service';
import { UserService } from '../../../../services/user.service';
import { ICRUDService } from '../../../../shared/crud-manager/crud.interface';

@Injectable({
  providedIn: 'root',
})
export class KitsService extends BaseService implements ICRUDService {
  params = [
    'name',
    'hidden',
    'chasis',
    'selectors',
  ];

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(search: string | undefined, page = 1) {
    return this.http.get(`${this.adminURL}/kits`, this.getParams({ search, page }));
  }

  create(value) {
    return this.http.post(
      `${this.adminURL}/kits/`,
      { kit: this.permitParams(value, ...this.params) },
      this.getParams(),
    );
  }

  update(id, value) {
    return this.http.put(
      `${this.adminURL}/kits/${id}`,
      { kit: this.permitParams(value, ...this.params) },
      this.getParams(),
    );
  }

  destroy(id: number) {
    return this.http.delete(`${this.adminURL}/kits/${id}`, this.getParams());
  }

  restore(id: number) {
    return this.http.delete(`${this.adminURL}/kits/${id}/restore`, this.getParams());
  }

  all() {
    return this.http.get(`${this.adminURL}/kits/all`, this.getParams());
  }

  clone(id: number) {
    return this.http.post(`${this.adminURL}/kits/${id}/clone`, {}, this.getParams());
  }
}
