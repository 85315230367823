<ng-container [formGroup]="parentFormGroup">
  <div class="row">
    <div class="col">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>{{companyService.getText('client_identifier')}}</mat-label>
        <input matInput appIdentifier required formControlName="rut" [matAutocomplete]="autoRut" autocomplete="off">
        <mat-error>
          El {{companyService.getText('client_identifier')}} es <strong>inválido</strong>
        </mat-error>
      </mat-form-field>
      <mat-autocomplete #autoRut="matAutocomplete">
        <mat-option *ngFor="let option of ruts" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="col" *ngIf="bookingMode && formBooking.name || !bookingMode && formRequired.name">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>Nombre completo</mat-label>
        <input matInput [required]="formRequired.name" formControlName="name" autocomplete="off">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col" *ngIf="bookingMode && formBooking.address || !bookingMode && formRequired.address">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>Dirección</mat-label>
        <input matInput [required]="formRequired.address" formControlName="address" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="col" *ngIf="bookingMode && formBooking.city || !bookingMode && formRequired.city">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>{{country.preferences.place_name || 'Comuna'}}</mat-label>
        <input matInput [required]="formRequired.city" formControlName="city" [matAutocomplete]="auto" autocomplete="off">
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of comunas" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>E-mail</mat-label>
        <input matInput required formControlName="email" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="col" *ngIf="bookingMode && formBooking.phone || !bookingMode && formRequired.phone">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>Fono</mat-label>
        <input matInput [required]="formRequired.phone" formControlName="phone" autocomplete="off" minlength="9">
      </mat-form-field>
    </div>
  </div>
  <app-order-fields mode='Client' [model]="client" [wrap]="false" [bookingMode]="bookingMode" [parentFormGroup]="parentFormGroup" [reactiveFormMode]="true" [outlinemode]="outlinemode"></app-order-fields>
</ng-container>
