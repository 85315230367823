<div class="wrapper client-style">
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <div class="logo">
      <img [src]="company.preferences.logo || 'assets/images/logo.svg'" alt="Company logo">
    </div>
    <div class="label">
      <span>Programa tu cita y selecciona el horario </span>
    </div>
    <div class="content-container">
      <div class="content">
        <mat-card class="transparent">
          <mat-card-content>
            <ng-container [formGroup]="form">
              <mat-stepper linear (selectionChange)="onStepChange($event)" class="transparent client-booking-stepper">
  
                    <mat-step [stepControl]="form.get('firstStepFormGroup')" [completed]="checkValidStep(0)">
                        <div class="subtitle"><i class="fa fa-pen"></i> Rellene los datos</div>
                        <fieldset>
                          <div class="row" *ngIf="hasLocations">
                            <div class="col">
                              <mat-form-field appearance="outline" class="white-backgrounded-field">
                                <mat-label>{{adminService.getText('location')}}</mat-label>
                                <mat-select formControlName="location_id" required autocomplete="off">
                                  <mat-option *ngFor="let location of company.locations" [value]="location.id">
                                    {{location.name}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <mat-form-field appearance="outline" class="white-backgrounded-field">
                                <mat-label>Solicitud</mat-label>
                                <mat-select formControlName="requirement_id" required autocomplete="off">
                                  <mat-option *ngFor="let requirement of types" [value]="requirement.id">
                                    {{requirement.name}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row" *ngIf="company.preferences.booking_type === 'professional'">
                            <div class="col">
                              <mat-form-field appearance="outline" class="white-backgrounded-field">
                                <mat-label>Profesional</mat-label>
                                <mat-select formControlName="user_id" required autocomplete="off" >
                                  <mat-option *ngFor="let user of professionals" [value]="user.id">
                                    {{user.name}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                        </fieldset>

                        <div class="buttons">
                          <div class="expander"></div>
                          <button
                          mat-raised-button
                          color="primary"
                          [disabled]="!checkValidStep(0)"
                          matStepperNext
                        >
                          Siguiente
                        </button>
                        <button
                          mat-raised-button
                          color="warn"
                          (click)="removeBooking()"
                          *ngIf="!booking.checked_in && booking.id && ((roleService.can('destroy','booking') | async) || bookingEditToken)">
                          <i class="fa fa-trash"></i>Cancelar
                        </button>
                        </div>
                    </mat-step>
                  
                    <mat-step >
                 
                        <div class="subtitle"><i class="fa fa-user"></i> Ingresa tus datos</div>
                        <app-client-form 
                        [parentFormGroup]="form.controls.client" 
                        [bookingMode]="true" 
                        (vehicleSelected)="form.controls.object.patchValue($event)" 
                        [outlinemode]="true">
                        </app-client-form>
                        
                        <div class="buttons">
                          <button mat-raised-button
                            color="primary"
                            matStepperPrevious
                            *ngIf="(!data || !data.shortMode)">
                              Atrás
                          </button>
                          <div class="expander"></div>
                          <button
                          mat-raised-button
                          color="primary"
                          [disabled]="!checkValidStep(1)"
                          matStepperNext>
                          Siguiente
                        </button>
                        <button
                          mat-raised-button
                          color="warn"
                          (click)="removeBooking()"
                          *ngIf="!booking.checked_in && booking.id && ((roleService.can('destroy','booking') | async) || bookingEditToken)">
                          <i class="fa fa-trash"></i>Cancelar
                        </button>
                        </div>
                    
                    </mat-step>
                  
                    <mat-step>
                    
                        @if (company.object === 'Custom') {
                          <div class="subtitle" id="custom-form"><i class="fa fa-cubes"></i> {{adminService.getText('object','capitalize')}}</div>
                          <app-custom-object-form [model]="booking.object" [parentFormGroup]="form.controls.object" (plateSelected)="form.controls.client.patchValue($event)" [optionalFields]="true" [hideOptionals]="true" [outlinemode]="true"></app-custom-object-form>
                        } @else {
                          <div class="subtitle" id="vehicle-form"><i class="fa fa-car"></i> {{adminService.getText('object','capitalize')}}</div>
                          <app-vehicle-form 
                          [model]="booking.object" 
                          [parentFormGroup]="form.controls.object" 
                          [parentControl]="form" 
                          (plateSelected)="form.controls.client.patchValue($event)" 
                          [optionalFields]="true" 
                          [hideOptionals]="true" 
                          [outlinemode]="true"></app-vehicle-form>
                        }
                        <div class="buttons">
                          <button mat-raised-button
                            color="primary"
                            matStepperPrevious
                            *ngIf="(!data || !data.shortMode)">
                              Atrás
                          </button>
                          <div class="expander"></div>
                          <button
                          mat-raised-button
                          color="primary"
                          [disabled]="!checkValidStep(2)"
                          matStepperNext>
                          Siguiente
                        </button>
                        <button
                          mat-raised-button
                          color="warn"
                          (click)="removeBooking()"
                          *ngIf="!booking.checked_in && booking.id && ((roleService.can('destroy','booking') | async) || bookingEditToken)">
                          <i class="fa fa-trash"></i>Cancelar
                        </button>
                        </div>
                    
                    </mat-step>
                
                    <mat-step>
                     
                      <div class="subtitle"><i class="fa fa-clock"></i> Horario de la Cita </div>
                      <fieldset>
                        <div class="row">
                          <div class="col">
                            <!--
                            <mat-form-field appearance="outline" class="white-backgrounded-field" (click)="picker.open()">
                              <mat-label>Fecha</mat-label>
                              <input
                                matInput
                                [matDatepicker]="picker"
                                [min]="nextDay"
                                [matDatepickerFilter]="onlyWeek"
                                required
                                formControlName="booking_date"
                                autocomplete="off">
                              <mat-error>Fecha no disponible</mat-error>
                            </mat-form-field>
                            <mat-datepicker #picker [startAt]="form.controls.booking_date.value"></mat-datepicker>
                            -->
                            <mat-calendar
                            class="calendar"
                            [selected]="form.controls.booking_date.value"
                            [minDate]="nextDay"
                            (selectedChange)="onDateSelected($event)"
                            [dateFilter]="onlyWeek"
                            ></mat-calendar>
                            <mat-error *ngIf="form.controls.booking_date.hasError('required')">
                              Fecha no disponible
                            </mat-error>

                          </div>

                         

                          <div class="col">
                            <mat-form-field appearance="outline" class="white-backgrounded-field">
                              <mat-label>Hora</mat-label>
                              <mat-select formControlName="booking_time" required autocomplete="off">
                                <mat-option *ngFor="let hour of freeBookingHours" [value]="hour">
                                  {{hour}}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="freebookingHours && freebookingHours.length === 0 || bookingErrors">No hay horas disponibles</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <mat-form-field appearance="outline" class="white-backgrounded-field">
                              <mat-label>Comentario</mat-label>
                              <input matInput formControlName="comment" autocomplete="off">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="hidden-fields">
                          <input type="hidden" value="addon" formControlName="from">
                        </div>
                      </fieldset>
                      <div class="subtitle"><i class="fa fa-plus-circle"></i> Adicionales</div>
                      <app-additionals-form
                        class="white-additionals"
                        [initialValue]="order_aditionals.getRawValue()"
                        [parentFormArray]="order_aditionals"
                        (added)="addAdditional(this, $event)"
                        (removed)="removeAdditional(this, $event)"
                      >
                      </app-additionals-form>
                      <div class="buttons">
                        <button mat-raised-button
                          color="primary"
                          matStepperPrevious
                          *ngIf="(!data || !data.shortMode)">
                            Atrás
                        </button>
                        <div class="expander"></div>
                        <button
                          mat-raised-button
                          color="accent"
                          [disabled]="!form.valid|| booking.checked_in || working" (click)="saveForm()"
                          *ngIf="!booking.id || (roleService.can('update','booking') | async) || (booking.id && bookingEditToken)">
                          <span *ngIf="booking.id">Actualizar</span>
                          <span *ngIf="!booking.id">Agendar</span>
                        </button>
                        <button
                          mat-raised-button
                          color="warn"
                          (click)="removeBooking()"
                          *ngIf="!booking.checked_in && booking.id && ((roleService.can('destroy','booking') | async) || bookingEditToken)">
                          <i class="fa fa-trash"></i>Cancelar
                        </button>
                      </div>
                    </mat-step>
                
              </mat-stepper>


            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
     
        <div class="content summary">
        
            <mat-card class="details-card">
              <mat-card-content>

                <div class="field" *ngIf="form.get('requirement_id').value">
                  <span class="requirement-title">{{getSelectedRequirementName()}}</span>
                </div>

                <div class="field">
                  <span class="fieldname">Local: </span> 
                  <span class="fieldvalue" *ngIf="form.get('location_id').value"> {{getSelectedLocationName()}}</span>
                </div>

                <!--
                <div class="field">
                  <span class="fieldname">Solicitud: </span>
                  <span class="fieldvalue" *ngIf="form.get('requirement_id').value">{{getSelectedRequirementName()}}</span>
                </div>
                -->

                <div class="field" *ngIf="company.preferences.booking_type === 'professional'">
                  <span class="fieldname">Profesional: </span>
                  <span class="fieldvalue">{{ form.get('user_id')?.value ? getSelectedProfessionalName() : '-' }}</span>
                </div>
                
                <div class="field"  *ngIf="form.get('client.rut')?.value">
                  <span class="fieldname">{{adminService.getText('client_identifier')}}: </span>
                  <span class="fieldvalue">{{ form.get('client.rut')?.value ? form.get('client.rut').value : '-' }}</span>
                </div>

                <div class="field"  *ngIf="form.get('object.identifier')?.value">
                  <span class="fieldname">{{adminService.getText('object_identifier')}}: </span>
                  <span class="fieldvalue">{{ form.get('object.identifier')?.value}}</span>
                </div>
                
                <div class="field">
                  <span class="fieldname">Fecha y Hora: </span> 
                  <span class="fieldvalue">{{ form.get('booking_date').value ? (form.get('booking_date').value | date: 'dd/MM/yyyy') : '-' }}, {{ form.get('booking_time').value ? form.get('booking_time').value : 'Pendiente' }}</span>
                </div>
                
                <div class="field" *ngIf="form.get('comment').value">
                  <span class="fieldname">Comentario: </span> 
                  <span class="fieldvalue">{{ form.get('comment').value ? form.get('comment').value : '-' }}</span>
                </div>

                <div *ngIf=" isLastStep() && (!form.valid|| booking.checked_in || working)" (click)="saveForm()" class="field">
                  <span class="missingfield">Faltan datos obligatorios (*)</span>
                </div>

                <div class="details-subtitle"> <mat-icon>home_repair_service</mat-icon> Detalle del servicio</div>
                <app-inventory-action-form
                  [parentFormGroup]="inventory_action_for_consume"
                  [isLocked]="true"
                  [readOnly]="true"
                  [showCategories]="false"
                  [showTotals]="false"
                  [clientBookingMode]="true"
                  #inventoryForm
                  parentModel="booking"
                >
                </app-inventory-action-form>
              </mat-card-content>
            </mat-card>
        
        </div>
      
    </div>
    <div class="extender"></div>
    <div class="footer">
      <a href="https://www.controlcar.app">Powered by <img src='assets/images/logo.svg' alt="logo"></a>
    </div>
  </ng-container>
</div>
