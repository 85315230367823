<div class="table" *ngIf="data">
  <div class="row header" *ngIf="data.length > 0">
    <div class="column" *ngFor="let column of columns">{{column[2]}}</div>
    <div class="export" (click)="exportAsExcelFile()"><i><mat-icon>download</mat-icon></i></div>
  </div>
  <div class="row content">
    <div appPagination
         (loadMore)="getMoreData()"
         [isLoading]="loadingMore"
         [page]="page"
         [totalPages]="totalPages"
         class="column content"
         *ngIf="data"
         id="works">
      <ng-container *ngIf="data.length > 0">
        <div class="row work-row" *ngFor="let row of data; let odd = odd" [class.odd]="odd" [class.even]="!odd" (click)="openDetails(row)">
          <div class="column" *ngFor="let column of columns" [title]="row[column[0]]" [innerHtml]="row[column[0]] | formatColumn:column[0]"></div>
        </div>
      </ng-container>
      <div class="row" *ngIf="data.length == 0">
        <div class="column center">No hay información</div>
      </div>
    </div>
  </div>
  <div class="row footer" *ngIf="data.length > 0">
    @if (loadingMore) {
      Cargando...
    } @else {
      Mostrando {{data.length}} de {{totalCount}} items
    }
  </div>
</div>
