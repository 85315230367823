<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select #matSelectElement [(formControl)]="formControl" attr.name="{{name}}" [multiple]="true">
    <mat-select-trigger>
			{{formControl.value ? displayItem(formControl.value[0]) : ''}}
      @if(formControl.value?.length > 1) {
        <span class="additional-selection" [matTooltip]="formControl.value.slice(1).map(displayItem.bind(this)).join(', ')">
          (+{{formControl.value.length - 1}} otros)
        </span>
      }
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [formControl]="searchControl"
                             placeholderLabel="Buscar"
                             noEntriesFoundLabel="Sin resultados"></ngx-mat-select-search>
    </mat-option>
    @if ((filteredItems$ | async)?.length > 0) {
      <div class="select-all">
        <mat-checkbox class="mat-pseudo-checkbox"
                      [(ngModel)]="allOptionsSelected"
                      [ngModelOptions]="{standalone: true}"
                      (change)="toggleAllSelection(matSelectElement, allOptionsSelected)">Marcar todos</mat-checkbox>

      </div>
    }
    <mat-option *ngFor="let option of filteredItems$ | async" [value]="option.id">
      {{option.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
