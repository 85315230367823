/* eslint-disable no-underscore-dangle */
import {
  Component, EventEmitter, Input, OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { Kit } from 'app/models/kits.model';
import { cloneDeep } from 'lodash';

import { AdditionalsService } from '../../../services/additionals.service';
import { HiddenFieldsComponent } from '../hidden-fields/hidden-fields.component';

@Component({
  selector: 'app-additionals-form',
  templateUrl: './additionals-form.component.html',
  styleUrls: ['./additionals-form.component.scss'],
})
export class AdditionalsFormComponent implements OnInit {
  additionals: Kit[] = [];

  @Input() readonly = false;

  @Input() clientBookingMode = false;

  @Input() parentFormArray;

  @Input() model;

  @Input() initialValue = [];

  @Output() added = new EventEmitter();

  @Output() removed = new EventEmitter();

  hiddenAdditionals = [];

  mem = {};

  constructor(
    private additionalsService: AdditionalsService,
    public adminService: AdminService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.additionalsService.additionals$.subscribe((res: Kit[]) => {
      const data = cloneDeep(res);
      this.additionals = data;
      this.hiddenAdditionals = data.filter((item) => item.hidden);
    });

    if (!this.initialValue) {
      this.initialValue = [];
    }
  }

  onChecked(event, additional) {
    if (event.checked) {
      this.addAdditional(additional);
      this.added.emit(additional);
    } else {
      this.removeAdditional(additional);
      this.removed.emit(additional);
    }
  }

  addAdditional(additional) {
    const index = this.parentFormArray.controls.findIndex((control) => control.value.aditional_id === additional.id);
    if (index === -1) {
      this.parentFormArray.push(this.createAdditionalFormGroup(additional));
      return;
    }

    if (this.parentFormArray.at(index).value.id) {
      this.parentFormArray.at(index).patchValue({
        _destroy: false,
      });
    }
  }

  isSelected(additional) {
    return !!this.initialValue.find((item) => item.aditional_id === additional.id);
  }

  removeAdditional(additional) {
    const index = this.parentFormArray.controls.findIndex((control) => control.value.aditional_id === additional.id);
    if (index === -1) return;

    if (this.parentFormArray.at(index).value.id) {
      this.parentFormArray.at(index).patchValue({
        _destroy: true,
      });
    } else {
      this.parentFormArray.removeAt(index);
    }
  }

  createAdditionalFormGroup(additional) {
    return this.fb.control({
      id: null,
      aditional_id: additional.id,
      _destroy: false,
    });
  }

  onShowHidden() {
    this.matDialog.open(HiddenFieldsComponent, {
      data: {
        fields: this.hiddenAdditionals,
        onChecked: this.onChecked.bind(this),
        isChecked: this.isChecked.bind(this),
        multiple: true,
      },
    });
  }

  isChecked(additional) {
    if (this.readonly) {
      return this.initialValue.some(
        (selectedOrderAdditional) => selectedOrderAdditional.aditional_id === additional.id,
      );
    }

    return this.parentFormArray
      .getRawValue()
      .some(
        (selectedOrderAdditional) => selectedOrderAdditional.aditional_id === additional.id
          && !selectedOrderAdditional._destroy,
      );
  }

  get visibleAdditionals() {
    if (this.readonly) {
      return this.additionals.filter((item) => this.isChecked(item));
    }

    return this.additionals.filter(
      (item) => !this.hiddenAdditionals.map((f) => f.id).includes(item.id),
    );
  }
}
