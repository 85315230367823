import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, input} from '@angular/core';
import {CommentsService} from "../../services/comments.service";
import {RoleService} from "../../role/role.service";
import {MatDialog} from "@angular/material/dialog";
import {RoleSelectorComponent} from "../../role/role-selector/role-selector.component";
import moment from 'moment';
import {Moment} from 'moment';
import {NgForm} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {interval} from "rxjs";
import {WorkRequestComponent} from "../../work/work-request/work-request.component";
import {AdminService} from "../../admin/services/admin.service";
import {HolydaysService} from "../../services/holydays.service";
import {EventDialogComponent} from "../../calendar/event-dialog/event-dialog.component";
import {first} from "rxjs/operators";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-order-comments',
  templateUrl: './order-comments.component.html',
  styleUrls: ['./order-comments.component.scss']
})
export class OrderCommentsComponent implements OnInit, AfterViewInit {
  @Input() order: any = {order_comments: []};
  @Input() work: any = {comments: []};
  @Input() comments: any[] = [];
  @Input() disableAddWork = false;
  @Output() load = new EventEmitter();
  @Output() saveComment = new EventEmitter();
  @ViewChild('commentsContainer', { static: true }) commentsContainer!: ElementRef;
  order_comment:any = {comment: "", user_ids: [], event: ""};
  working = false;
  selected_users = [];
  now = moment().toDate();
  isExpanded = false;
  userID = this.userService.user$.value.id;
  onlyWeek: (d: Moment) => boolean;
  constructor(
    private commentService: CommentsService,
    public roleService: RoleService,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    public holydayService: HolydaysService,
    public adminService: AdminService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.now = moment().toDate();
    this.holydayService.filterFunction(true,this.order && this.order.location ? this.order.location.preferences : null).then(value => {
      this.onlyWeek = value;
    });
  }
  ngAfterViewInit() {
    this.roleService.can('create','order_comment').pipe(first()).forEach((response)=>{
      if(response){
        this.activatedRoute.queryParams.forEach((params)=>{
          if(params.goto && params.goto==='comments') {
            const intervals = interval(500).subscribe((value) => {
              const element = document.getElementById('comment-input')
              if (element) {
                element.focus();
                element.click();
                intervals.unsubscribe();
              }
            });
          }
        });
      }
    });
    this.scrollToBottom();
  }
  createComment(f: NgForm) {
    if (this.order_comment.comment && (this.work.id || this.order.id)) {
      this.working = true;
      const order_comment = {
        comment: this.order_comment.comment,
        order_id: this.order ? this.order.id : null,
        work_id: this.work.id,
        user_ids: this.order_comment.user_ids,
        event: this.order_comment.event
      };
      this.order_comment = {comment: "", user_ids: [], event: ""};
      this.selected_users = [];
      this.commentService.create({
        order_comment: order_comment
      }).forEach((value) => {
        if(!this.comments){
          this.comments = [];
        }
        this.comments.push(value.body);
        this.working = false;
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);  // wait for the new comment to be added to the DOM
      }).catch((error) => {
        console.log(error);
        this.working = false;
      });
    }else{
      this.selected_users = [];
      this.saveComment.emit(this.order_comment);
      this.order_comment = {comment: "", user_ids: [], event: ""};
    }
  }
  reply(user){
    this.selected_users.push(user);
    //remove duplicates
    this.selected_users = this.selected_users.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
    this.order_comment.user_ids = this.selected_users.map((u)=>u.id);
  }
  removeReply(user){
    this.selected_users = this.selected_users.filter((u)=>u.id !== user.id);
    this.order_comment.user_ids = this.selected_users.map((u)=>u.id);
  }
  addWork(){
    this.matDialog
      .open(WorkRequestComponent, { 
        data: {
          document_id: this.order.id,
          document_type: 'Order',
          status_id: this.order.status_id,
        }
      })
      .afterClosed().toPromise().then((value)=>{
        if(value){
          this.load.emit(true);
        }
      });
  }
  openEvent(event){
    if(event){
      this.roleService.can('update','event').pipe(first()).forEach((value)=>{
        if(value){
          this.matDialog.open(EventDialogComponent, {data: event}).afterClosed().toPromise().then(()=>{
            this.load.emit(true);
          });
        }
      });
    }
  }
  getTooltip(users){
    return users.slice(1).map((s)=>s.name).join(", ");
  }
  selectRoles() {
    this.matDialog.open(RoleSelectorComponent, {data: this.selected_users}).afterClosed().pipe(first()).forEach((values) => {
      if (values) {
        this.selected_users = values;
        this.order_comment.user_ids = values.map((v) => v.id);
      }
    });
  }
  getInitials(name: string): string {
    if (!name) return '';
    const words = name.split(' ');
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words.length > 1 ? words[words.length > 2 ? 2 : 1].charAt(0).toUpperCase() : '';
    
    return firstInitial + secondInitial;
  }
  scrollToBottom(): void {
    const container = this.commentsContainer.nativeElement;
    container.scrollTop = container.scrollHeight;
  }
}
