import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ObjectModel } from 'app/models/object.model';
import { formatIdentifier } from 'app/utils/client-utils';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';

import { AdminService } from '../../../admin/services/admin.service';
import { ConfirmDialogComponent } from '../../../main/confirm-dialog/confirm-dialog.component';
import { Brand, BrandModel } from '../../../models/types';
import { BrandService } from '../../../services/brand.service';
import { CheckInService } from '../../../services/check-in.service';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent implements OnInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;

  @Input() parentControl: FormGroup;

  @Input() optionalFields = false;

  @Input() hideOptionals = false;

  @Input() hideKms = false;

  @Input() model;

  @Input() outlinemode: boolean = false;

  @ViewChild('autoBrand') autoBrand: MatAutocomplete;

  @ViewChild('autoModel') autoModel: MatAutocomplete;

  @ViewChild('identifierInput') identifierInput: ElementRef;

  @Output() plateSelected = new EventEmitter();

  country;

  plates = [];

  brands: Brand[] = [];

  models: BrandModel[] = [];

  subs: Subscription[] = [];

  searchingModels = false;

  identifierOptional = false;

  filteredBrands: Brand[] = [];

  filteredModels: BrandModel[] = [];

  formRequired: Record<string, boolean> = {};

  constructor(
    private checkinService: CheckInService,
    private brandService: BrandService,
    private matDialog: MatDialog,
    public companyService: AdminService,
  ) {

  }

  ngOnInit() {
    this.brandService.brands$.subscribe((values) => {
      this.brands = values;
      this.filteredBrands = cloneDeep(this.brands);
    });

    this.country = this.companyService.local$.getValue().country;
    this.formRequired = this.companyService.local$.getValue().preferences.forms_required.vehicle;
    this.identifierOptional = this.companyService.local$.getValue().preferences.temp_identifier;

    this.parentFormGroup.controls.brand_id.setValidators(this.checkBrand.bind(this));
    this.parentFormGroup.controls.brand_model_id.setValidators(this.checkModel.bind(this));

    this.subs.push(this.parentFormGroup.controls.identifier.valueChanges.subscribe((value) => {
      this.searchPlate(value);
    }));

    this.subs.push(this.parentFormGroup.controls.brand_id.valueChanges.subscribe((value) => {
      this.searchBrand(value);
      this.searchBrandModels(this.brand_id).then((models: BrandModel[]) => {
        if (models.find((model) => model.id === this.brand_model_id)) {
          this.parentFormGroup.controls.brand_model_id.setValue(this.brand_model_id);
        } else {
          this.parentFormGroup.controls.brand_model_id.setValue(null);
        }
      });
    }));

    this.subs.push(this.parentFormGroup.controls.brand_model_id.valueChanges.subscribe((value) => {
      this.searchModel(value);
    }));
  }

  get brand_id() {
    return this.parentFormGroup.controls.brand_id.value;
  }

  get brand_model_id() {
    return this.parentFormGroup.controls.brand_model_id.value;
  }

  searchBrandModels(id: string) {
    return new Promise((resolve, reject) => {
      if (!id || this.searchingModels) {
        reject();
        return;
      }

      this.searchingModels = true;
      this.brandService.getModels(parseInt(id, 10)).toPromise().then((values: HttpResponse<BrandModel[]>) => {
        this.models = values.body;
        this.filteredModels = cloneDeep(this.models);
        this.searchingModels = false;
        resolve(this.models);
      }).catch(() => {
        this.parentFormGroup.controls.brand_model_id.setValue(null);
        this.filteredModels = cloneDeep(this.models);
        this.searchingModels = false;
        reject();
      });
    });
  }

  searchBrand(value: string) {
    if (value && value.toUpperCase) {
      this.filteredBrands = this.brands.filter((value1) => value1.name.indexOf(value.toUpperCase()) >= 0);
    } else {
      this.filteredBrands = cloneDeep(this.brands);
    }
  }

  searchModel(value?: number | string) {
    if (typeof value === 'string') {
      this.filteredModels = this.models.filter((value1) => value1.name.indexOf(value.toUpperCase()) >= 0);
    } else if (typeof value === 'number') {
      this.filteredModels = this.models.filter((value1) => value1.id.toString() === value.toString());
    } else {
      this.filteredModels = cloneDeep(this.models);
    }
  }

  displayBrand = (id: string) => {
    if (this.brands && this.brands.length !== 0 && id) {
      const i = this.brands.findIndex((value) => value.id === id);
      if (i >= 0) {
        return this.brands[i].name;
      }
    }
    return '';
  };

  displayModel = (id) => {
    if (this.models && this.models.length !== 0 && id) {
      const i = this.models.findIndex((value) => value.id === id);
      if (i >= 0) {
        return this.models[i].name;
      }
    }
    return '';
  };

  checkBrand(c: UntypedFormControl) {
    if (c && c.value) {
      const i = this.brands.findIndex((value) => value.id.toString() === c.value.toString());
      if (i >= 0) {
        return null;
      }
      return { invalid: true };
    }
    return null;
  }

  checkModel(c: UntypedFormControl) {
    if (c && c.value) {
      const i = this.models.findIndex((value) => value.id.toString() === c.value.toString());
      if (i >= 0) {
        return null;
      }
      return { invalid: true };
    }
    return null;
  }

 searchPlate(value: string) {
    if (!value) return;

    this.checkinService.searchPlate({ identifier: value }).toPromise().then((response: HttpResponse<ObjectModel>) => {
        const { identifier, brand_model_id: brandModelId, ...rest } = response.body;
        this.parentFormGroup.patchValue(rest);
        this.parentFormGroup.controls.identifier.setValue(identifier, { emitEvent: false });

        setTimeout(() => {
            this.parentFormGroup.controls.brand_model_id.setValue(brandModelId);
        }, 1000);

        const clientRutControl = this.parentFormGroup?.parent?.get('client.rut');
        if (response.body.client && (!clientRutControl || !clientRutControl.value)) {
            this.matDialog.open(ConfirmDialogComponent, {
                data: {
                    title: '¡Cliente encontrado!', 
                    body: '¿Desea importar la información?',
                },
                minWidth: '300px',
            }).afterClosed().subscribe((selected: boolean) => {
                if (!selected) return;

                response.body.client.rut = formatIdentifier(
                    response.body.client.rut,
                    this.country.code,
                );

                this.plateSelected.emit(response.body.client);
                this.identifierInput.nativeElement.blur();
            });
        }
    }).catch((error: HttpErrorResponse) => {
        this.plates = error.error;
        this.parentFormGroup.controls.id?.setValue(null);
    });
}


  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  // eslint-disable-next-line class-methods-use-this
  validateNumberInput(event: KeyboardEvent): void {
    const { charCode } = event;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  handleArrowKeys(event: KeyboardEvent): void {
    const control = this.parentControl.get('kms');
    let currentValue = parseInt(control?.value || '0', 10);

    if (event.key === 'ArrowUp') {
      currentValue += 1;
      control?.setValue(currentValue);
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      currentValue -= 1;
      if (currentValue >= 0) {
        control?.setValue(currentValue);
      }
      event.preventDefault();
    }
  }
}
