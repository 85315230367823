import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class RolesService extends BaseService implements ICRUDService{
  params = ['name','powers']
  model = 'Role';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null, page = 1){
    return this.http.get(this.adminURL+'/roles',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/roles/',{
      role: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/roles/'+id,{
      role: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/roles/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/roles/'+id+'/restore',this.getParams());
  }
  import(items=null){
    return this.http.post(this.adminURL+'/roles/import',{items: items},this.getParams());
  }

}
