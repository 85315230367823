<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-wrench fa-2x"></i></div>
    <mat-card-title>Trabajos</mat-card-title>
    <mat-card-subtitle>Trabajos a ejecutar en cada estado</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager
        [service]="worksService"
        [itemTemplate]="itemTemplate"
        [formTemplate]="formTemplate"
        [formGroup]="form"
        [args]="['WorkFor' + model]">
      <ng-template #itemTemplate let-item>
        {{item.name}} <i class="fa fa-wrench" *ngIf="item.custom" style="margin-left: 10px"></i>
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Nombre Trabajo</mat-label>
                <input matInput placeholder="Nombre Trabajo" formControlName="name" autocomplete="off" autofocus required>
                <mat-hint>Nombre descriptivo de la accion a ejecutar</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Estado</mat-label>
                <mat-select placeholder="Estado" formControlName="status_id" required>
                  <mat-option *ngFor="let status of statuses | async" [value]="status.id">{{status.name}}</mat-option>
                </mat-select>
                <mat-hint>Estado en el que se ejecutara el trabajo</mat-hint>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Roles</mat-label>
                <mat-select placeholder="Roles" formControlName="roles_id" required multiple="true">
                  <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                </mat-select>
                <mat-hint>Roles encargados de ejecutar el trabajo</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="automatic_assignation">
                Auto-asignar usuario
                <i class="fa fa-question-circle" matTooltip="Al iniciarse el trabajo se elegirá automaticamente un usuario del rol adecuado según disponibilidad"></i>
              </mat-checkbox>
            </div>
            @if(model === 'Order') {
              <div class="col" [hidden]="form.controls['automatic_assignation'].value">
                <mat-form-field>
                  <mat-label>Rol encargado de asignar</mat-label>
                  <mat-select placeholder="Rol encargado de asignar" formControlName="assignation_roles_id" multiple="true">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                  </mat-select>
                  <mat-hint>Roles que serán notificados para asignar</mat-hint>
                </mat-form-field>
              </div>
            }
          </div>
          @if(model === 'Order') {
            <div class="row">
              <div class="col">
                <mat-checkbox formControlName="require_revision">
                  Requerir revisión
                  <i class="fa fa-question-circle" matTooltip="Para poder aprobar el trabajo se deber revisar primero"></i>
                </mat-checkbox>
              </div>
              <div class="col" [hidden]="!form.controls['require_revision'].value">
                <mat-form-field>
                  <mat-label>Rol encargado de revisar</mat-label>
                  <mat-select placeholder="Rol encargado de revisar" formControlName="revision_roles_id" multiple="true">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                  </mat-select>
                  <mat-hint>Roles que serán notificados para revisar</mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col" [hidden]="!form.controls['require_revision'].value">
                <mat-form-field>
                  <mat-label>Tipo de revisión</mat-label>
                  <mat-select placeholder="Tipo de revisión" formControlName="revision_type">
                    <mat-option *ngFor="let type of revisionTypes" [value]="type.id">{{type.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col" [hidden]="(!form.controls['require_revision'].value || form.controls['revision_type'].value!='score')">
                <mat-form-field>
                  <mat-label>Puntaje máximo</mat-label>
                  <input matInput type="number" placeholder="Puntaje máximo" formControlName="revision_max_score" autocomplete="off" autofocus pattern="\d*">
                  <mat-hint>Puntaje máximo</mat-hint>
                </mat-form-field>
              </div>
            </div>
          }
          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="can_change_status">
                Cambiar estado al finalizar
                <i class="fa fa-question-circle" matTooltip="Al finalizar el trabajo la orden cambiará de estado automaticamente"></i>
              </mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox formControlName="complete_child_works">
                ¿Autocompletar al finalizar otros trabajos?
                <i class="fa fa-question-circle" matTooltip="Al finalizar el trabajo la orden cambiará de estado automaticamente"></i>
              </mat-checkbox>
            </div>
          </div>
          @if(model === 'Order') {
            <div class="row">
              <div class="col">
                <mat-form-field>
                  <mat-label>Nº de fotos mínimo</mat-label>
                  <input matInput type="number" placeholder="Nº de fotos mínimo" formControlName="min_photos" autocomplete="off" autofocus required pattern="\d*">
                  <mat-hint>Número mínimo de fotos para completar el trabajo</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-checkbox formControlName="non_productive">
                  Trabajo No Productivo
                  <i class="fa fa-question-circle" matTooltip="Este trabajo no genera valor en la orden o es responsabilidad de un tercero"></i>
                </mat-checkbox>
              </div>
            </div>
          }
          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="custom">
                Requerimiento de Trabajo
                <i class="fa fa-question-circle" matTooltip="Este trabajo es generado y asignado entre usuarios, EJ: (Solicitudes, Aprobaciones)"></i>
              </mat-checkbox>
            </div>
          </div>
          <div class="row" [hidden]="!form.controls['custom'].value">
            <div class="col">
              <mat-checkbox formControlName="global">
                Global
                <i class="fa fa-question-circle" matTooltip="Este trabajo puede ser requerido en cualquier etapa"></i>
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="sign_needed">
                Necesita Firma
                <i class="fa fa-question-circle" matTooltip="Este trabajo debe ser firmado para poder ser completado"></i>
              </mat-checkbox>
            </div>
          </div>
          <div class="row" [hidden]="!form.controls['sign_needed'].value">
            <div class="col">
              <mat-form-field>
                <mat-label>Texto a aceptar en diálogo de firma</mat-label>
                <textarea matInput formControlName="sign_text" placeholder="Texto a aceptar en diálogo de firma">
                </textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Formato Respuesta</mat-label>
                <mat-select placeholder="Formato Respuesta" formControlName="custom_response_type" required>
                  <mat-option *ngFor="let response of responses" [value]="response.id">{{response.name}}</mat-option>
                </mat-select>
                <mat-hint>Tipo de Formulario que verá el usuario para responder</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Campos customs</mat-label>
                <mat-select placeholder="Campos customs" formControlName="custom_field_ids" multiple="true">
                  <mat-option *ngFor="let field of customFields" [value]="field.id">{{field.title}}</mat-option>
                </mat-select>
                <mat-hint>Campos customs a mostrar</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row" [hidden]="form.controls['custom_response_type'].value !== 'form_filler'">
            <div class="col">
              <mat-form-field>
                <mat-label>Campos a llenar</mat-label>
                <mat-select placeholder="Campos a llenar" formControlName="form_fields" multiple="true">
                  <mat-option *ngFor="let field of fields" [value]="field.key">{{field.title}}</mat-option>
                </mat-select>
                <mat-hint>Campos que el usuario deberá completar en el trabajo</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row" [hidden]="!form.controls['custom'].value">
            <div class="col">
              <mat-form-field>
                <mat-label>Trabajos siguientes (opcional)</mat-label>
                <mat-select placeholder="Trabajos siguientes (opcional)" formControlName="next_custom_work_ids" multiple="true">
                  <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
                </mat-select>
                <mat-hint>Al terminar requerimiento ejecutar otro</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
