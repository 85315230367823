import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, Inject, OnDestroy, OnInit, Optional,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { AdminService } from '../../admin/services/admin.service';
import { BudgetService } from '../../budget/budget.service';
import { BudgetViewComponent } from '../../budget/budget-view/budget-view.component';
import { RoleService } from '../../role/role.service';
import { CheckInService } from '../../services/check-in.service';
import { PDFService } from '../../services/pdf.service';
import { StatusesService } from '../../services/statuses.service';
import { WorkDialogComponent } from '../../work/work-dialog/work-dialog.component';
import { WorkRequestBottomSheetComponent } from '../../work/work-request-bottom-sheet/work-request-bottom-sheet.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { OrderHistoryComponent } from '../order-history/order-history.component';
import { OrderNotificationsComponent } from '../order-notifications/order-notifications.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnDestroy {
  order: any;

  editMode = false;

  currentImage = null;

  company_logo;

  statuses = [];

  company: any = {};

  forbidden = false;

  creatingBudget = false;

  budgetBack:any;

  heading;

  constructor(
    private checkinService: CheckInService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private statusesService: StatusesService,
    private dialog: MatDialog,
    public roleService: RoleService,
    public adminService: AdminService,
    private pdfService: PDFService,
    private budgetService: BudgetService,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    public location: Location,
    @Optional() @Inject(MAT_DIALOG_DATA) public id,
    @Optional() private matDialogRef: MatDialogRef<OrderComponent>,
  ) {
    if (this.id) {
      this.load().then(() => {});
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.adminService.pageTitle.set(this.adminService.getText('order_plural'));
    this.company_logo = this.adminService.local$.getValue().preferences.logo || 'assets/images/kongu.png';
    this.company = this.adminService.local$.getValue();
    this.activatedRoute.params.forEach((params) => {
      if (params.id) {
        this.id = params.id;
        this.load().then(() => {});
      }
    });
    this.activatedRoute.queryParams.forEach((params) => {
      if (params.budget) {
        this.budgetBack = params.budget;
      }
    });
    this.statusesService.all('StatusForOrder').toPromise().then((value:any) => {
      this.statuses = value.body;
    });
  }

  load() {
    return new Promise((resolve, reject) => {
      this.checkinService.get(this.id).toPromise().then((response) => {
        this.order = response.body;
        resolve(this.order);
      }).catch((error:HttpErrorResponse) => {
        if (error.status === 403) {
          this.forbidden = true;
        }
        reject(error);
      });
    });
  }

  reload() {
    this.editMode = false;
    this.load().then(() => {});
  }

  goBack() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    } else {
      this.location.back();
    }
  }

  print() {
    this.pdfService.getOrder(this.order.id, `Order_de_trabajo_${this.order.correlative_id}`);
  }

  setStatus(status: number) {
    this.dialog.open(ConfirmDialogComponent, { data: { title: 'Actualizar Estado' } }).afterClosed().toPromise().then((result) => {
      if (result) {
        this.order.status_id = status;
        this.checkinService.update(this.id, { check_in: { status_id: status } }).toPromise().then((response) => {
          Object.assign(this.order, response.body);
        }).catch((error:HttpErrorResponse) => {
          console.log(error);
        });
      }
    });
  }

  openNotifications() {
    this.dialog.open(OrderNotificationsComponent, { data: { order_id: this.order.id } });
  }

  openChanges() {
    this.dialog.open(OrderHistoryComponent, { data: { order_id: this.order.id } });
  }

  sanitizeBackground(url) {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }

  getTotal(useTax = true) {
    const total = this.order.total + this.order.budgets.reduce((acc, budget) => acc + budget.total, 0);
    if (useTax) {
      return total;
    }
    const tax = this.company.country.preferences.tax_rate;
    return Math.round(total / tax);
  }

  budgetItems(budget) {
    return budget.inventory_actions.flatMap((action) => action.inventory_action_products);
  }

  openBudget(budgetId: number) {
    console.log('budgetId', budgetId);
    this.dialog.open(BudgetViewComponent, {
      data: { id: budgetId },
      minWidth: '90%',
    });
  }

  addWork() {
    const bottomSheetRef = this.bottomSheet.open(WorkRequestBottomSheetComponent, {
      data: {
        document_id: this.order.id,
        document_type: 'Order',
        status_id: this.order.status_id,
        work_type: 'WorkForOrder',
      },
      panelClass: 'work-request-bottom-sheet',
    });

    bottomSheetRef.afterDismissed().toPromise().then((value) => {
      if (value) {
        this.openLastWork();
      }
    });
  }

  openLastWork() {
    this.load().then(() => {
      const lastWork = this.order.works[this.order.works.length - 1];
      this.dialog.open(WorkDialogComponent, {
        data:
        { id: lastWork.id, type: lastWork.type },
        minWidth: '80%',
      }).afterClosed().toPromise().then(() => this.load());
    });
  }

  getOrderTotal(): number {
    const inventoryTotal = (this.order.inventory_action_for_consume?.total || 0) * 1.19;

    const budgetsTotal = this.order.budgets?.reduce((sum, budget) => sum + (budget.total * 1.19), 0) || 0;

    return inventoryTotal + budgetsTotal;
  }
}
