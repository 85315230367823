import { Component, effect } from '@angular/core';
import { ManagerService } from '../manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manager-login',
  templateUrl: './manager-login.component.html',
  styleUrl: './manager-login.component.scss'
})
export class ManagerLoginComponent {
  user = {
    email: '',
    password: '',
    otp: ''
  };
  constructor(
    private managerService: ManagerService,
    private router: Router
  ) {
    effect(() => {
      if(this.managerService.user()){
        this.router.navigate(['/manager/main']);
      }
      else if(this.managerService.authToken()){
        this.managerService.relogin();
      }
    })
  }
  login(form){
    this.managerService.login(form.value);
  }
}
