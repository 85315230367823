import { NgModule } from '@angular/core';

import { BaseModule } from '../shared/base.module';
import { CommentsAddonsModule } from '../shared/comments/comments.addons.module';
import { CommonFormsModule } from '../shared/common-forms/common-forms.module';
import { WorksAddonsModule } from '../shared/works/works.addons.module';
import { WorkModule } from '../work/work.module';
import { CRMComponent } from './crm.component';
import { CrmBookingComponent } from './crm-booking/crm-booking.component';
import { CrmClientComponent } from './crm-client/crm-client.component';
import { CrmDetailsComponent } from './crm-details/crm-details.component';
import { CRMViewComponent } from './crmview/crmview.component';

@NgModule({
  declarations: [
    CRMComponent,
    CRMViewComponent,
    CrmClientComponent,
    CrmBookingComponent,
    CrmDetailsComponent,
  ],
  exports: [
    CRMComponent,
    CRMViewComponent,
  ],
  imports: [
    BaseModule,
    CommentsAddonsModule,
    CommonFormsModule,
    WorksAddonsModule,
    WorkModule,
  ],
})
export class CRMModule { }
