import 'firebase/storage';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import localeCL from '@angular/common/locales/es-CL';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { FIREBASE } from './app.config';
import { CanActivateMain, CanActivateMenu } from './app.permissions';
import { routes } from './app.routes';
import { ProductionCalendarModule } from './calendar/production-calendar.module';
import { ClientModule } from './client/client.module';
import { CRMModule } from './crm/crm.module';
import { InventoryModule } from './inventory/inventory.module';
import { LoginModule } from './login/login.module';
import { ManagerModule } from './manager/manager.module';
import { PaymentsModule } from './payments/payments.module';
import { AdditionalsService } from './services/additionals.service';
import { AnalyticsService } from './services/analytics.service';
import { BookingService } from './services/booking.service';
import { BrandService } from './services/brand.service';
import { CategoriesService } from './services/categories.service';
import { CheckInService } from './services/check-in.service';
import { ClientsService } from './services/clients.service';
import { CommentsService } from './services/comments.service';
import { HolydaysService } from './services/holydays.service';
import { DecryptInterceptor } from './services/interceptors/decrypt.interceptor';
import { InventoryActionService } from './services/inventory-action.service';
import { KitsService } from './services/kits.service';
import { ProductsService } from './services/products.service';
import { RequirementsService } from './services/requirements.service';
import { StatusesService } from './services/statuses.service';
import { UserService } from './services/user.service';
import { WarehousesService } from './services/warehouses.service';
import { CameraService } from './shared/image-uploader/camera/camera.service';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { SharesService } from './shares/services/shares.service';
import { UpdateAvailableComponent } from './update-available/update-available.component';
import { IframeRejectedComponent } from './utils/iframe-rejected/iframe-rejected.component';
import { VehiclesModule } from './vehicles/vehicles.module';
import { WorkPointModule } from './work-point/work-point.module';
import { ZipModule } from './zip/zip.module';

registerLocaleData(localeCL, 'es-CL');
registerLocaleData(localeES, 'es-ES');
@NgModule({
  declarations: [
    AppComponent,
    UpdateAvailableComponent,
    IframeRejectedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    LoginModule,
    CurrencyMaskModule,
    SharedModule,
    MaterialModule,
    AdminModule,
    WorkPointModule,
    ManagerModule,
    ProductionCalendarModule,
    ClientModule,
    InventoryModule,
    PaymentsModule,
    VehiclesModule,
    ZipModule,
    CRMModule,
    AngularFireModule.initializeApp(FIREBASE),
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AdditionalsService,
    AnalyticsService,
    BookingService,
    BrandService,
    CameraService,
    CanActivateMain,
    CanActivateMenu,
    CategoriesService,
    CheckInService,
    ClientsService,
    CommentsService,
    HolydaysService,
    InventoryActionService,
    KitsService,
    ProductsService,
    RequirementsService,
    SharesService,
    StatusesService,
    UserService,
    WarehousesService,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: HTTP_INTERCEPTORS, useClass: DecryptInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
