import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class WorksService extends BaseService implements ICRUDService{

  params = [
    'assignation_roles_id',
    'automatic_assignation',
    'can_change_status',
    'complete_child_works',
    'custom',
    'custom_field_ids',
    'custom_response_type',
    'form_fields',
    'global',
    'min_photos',
    'name',
    'next_custom_work_ids',
    'non_productive',
    'require_revision',
    'revision_max_score',
    'revision_roles_id',
    'revision_type',
    'roles_id',
    'sign_needed',
    'sign_roles',
    'sign_text',
    'status_id',
    'type',
  ];

  model = 'Work';

  constructor(public http:HttpClient, userService: UserService) {
    super(http,userService);
  }

  index(value = null, page = 1, type = "WorkForOrder") {
    return this.http.get(this.adminURL + '/works', this.getParams({ search: value, page, type }));
  }

  create(value, type = "WorkForOrder") {
    Object.assign(value, { type })
    return this.http.post(this.adminURL+'/works/', {
      work: this.permitParams(value, ...this.params)
    }, this.getParams());
  }

  update(id: number, value) {
    return this.http.put(this.adminURL+'/works/'+id, {
      work: this.permitParams(value, ...this.params)
    }, this.getParams());
  }

  destroy(id: number) {
    return this.http.delete(this.adminURL+'/works/' + id, this.getParams());
  }

  restore(id: number) {
    return this.http.delete(this.adminURL+'/works/' + id + '/restore', this.getParams());
  }

  all(type = "WorkForOrder") {
    return this.http.get(this.adminURL+'/works/all', this.getParams({ type }));
  }
}
