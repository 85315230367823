import {NgModule} from '@angular/core';
import {PrioritiesComponent} from './priorities.component';
import {PrioritiesDialogComponent} from './priorities-dialog/priorities-dialog.component';
import {PrioritiesService} from './priorities.service';
import {PriorityPipe} from './priority.pipe';
import {WorkModule} from "../work/work.module";
import {BaseModule} from "../shared/base.module";

@NgModule({
    imports: [
        BaseModule,
        WorkModule
    ],
    declarations: [
        PrioritiesComponent,
        PrioritiesDialogComponent,
        PriorityPipe
    ],
    exports: [
        PrioritiesComponent
    ],
    providers: [PrioritiesService]
})
export class PrioritiesModule { }
