import {
  Component, Input, OnInit,
} from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { Kit } from 'app/models/kits.model';
import { Requirement } from 'app/models/requirement.model';
import { Brand, BrandModel } from 'app/models/types';
import { BrandService } from 'app/services/brand.service';
import { RequirementsService } from 'app/services/requirements.service';
import {
  combineLatest,
} from 'rxjs';

import { KitsService } from './services/kits.services';

@Component({
  selector: 'app-admin-kits',
  templateUrl: './admin-kits.component.html',
  styleUrls: ['./admin-kits.component.scss', '../admin-main.common.scss'],
})
export class AdminKitsComponent implements OnInit {
  @Input() inView = false;

  service;

  title = 'Kits';

  subtitle = 'Administra los kits de productos.';

  hiddenInputTitle = 'Deshabilitar';

  hiddenInputTooltipMessage = 'Al deshabilitar un kit, éste no podrá ser seleccionado bajo ninguna condición.';

  brands: Brand[] = [];

  public company;

  form: FormGroup<{
    name: FormControl<string | null>,
    hidden: FormControl<boolean | null>,
    chasis: FormControl<string | null>,
    selectors: FormGroup
  }> = this.fb.group({
      name: ['', {
        validators: [Validators.required, Validators.minLength(3)],
      }],
      hidden: this.fb.control(false),
      chasis: this.fb.control(null),
      selectors: this.fb.group({
        requirement_ids: new FormControl([[], Validators.required]),
        object: this.fb.group({
          brand_ids: [],
          brand_model_ids: [],
          custom_fields: this.fb.group({}),
        }),
        custom_fields: this.fb.group({}),
      }),
    });

  loading: boolean = true;

  models: BrandModel[] = [];

  requirements: Requirement[] = [];

  selected: Kit;

  showSelectors = true;

  constructor(
    public adminService: AdminService,
    public brandService: BrandService,
    public fb: FormBuilder,
    public requirementService: RequirementsService,
    public router: Router,
    public kitsService?: KitsService,
  ) {
    this.service = this.kitsService;
  }

  ngOnInit(): void {
    this.company = this.adminService.local$.getValue();
    combineLatest([this.requirementService.all(), this.brandService.brands$, this.brandService.getModels(0)])
      .subscribe(([requirements, brands, models]) => {
        this.requirements = requirements.body;
        this.brands = brands;
        this.models = models.body;
        this.loading = false;
      });
  }

  select(item) {
    this.selected = item;
    if (!this.inView) {
      this.router.navigate(['admin', 'main', 'kits', this.selected.id, 'products']);
    }
  }
}
