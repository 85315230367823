
<div mat-dialog-title class="name">
  Asigne un usuario
</div>

<div mat-dialog-content class="content">

  <div class="loading" *ngIf="loading"><mat-spinner></mat-spinner></div>

    <button mat-button class="grid" *ngFor="let role of roles" (click)="setRole(role)" [hidden]="users.length > 0">
      <span >{{role.name}}</span>
    </button>

    <button mat-button class="grid" *ngFor="let user of users" (click)="setUser(user)" [hidden]="users.length == 0">
      <span>{{user.name}} <div class="user-status" [class.online]="user.online"></div></span>
    </button>
</div>


<div mat-dialog-actions class="footer">
<!-- <button mat-button (click)="closeDialog()">Cancelar</button> -->
<button mat-button [hidden]="users.length == 0" (click)="users = []">Regresar</button>

<!-- <button mat-button cdkFocusInitial (click)="applyFilters()" [hidden]="users.length == 0">Aplicar</button> -->
<button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
</div>