import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tag } from 'app/models/tag.model';
import { Observable } from 'rxjs';

import { BaseService } from '../services/_base.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService extends BaseService {
  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(filters?, search?) {
    const params: Record<any, any> = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter) {
          params[filter] = filters[filter].toString();
        }
      });
    }
    if (search) {
      params.query = search;
    }
    return this.http.get(`${this.apiURL}/budgets`, this.getParams(params));
  }

  allPost(filters?, search?) {
    const params: Record<any, any> = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter) {
          params[filter] = filters[filter];
        }
      });
    }
    if (search) {
      params.query = search;
    }
    return this.http.post(`${this.apiURL}/budgets/all`, params, this.getParams());
  }

  get(id, token = null) {
    return this.http.get(`${this.apiURL}/budgets/${id}${token ? `?token=${token}` : ''}`, this.getParams());
  }

  getFromOriginWork(origin_work_instance_id: number) {
    return this.http.get(`${this.apiURL}/budgets/from_work/${origin_work_instance_id}`, this.getParams());
  }

  draft(order_id: number, origin_work_instance_id: number) {
    return this.http.post(`${this.apiURL}/budgets`, { order_id, origin_work_instance_id }, this.getOptions());
  }

  save(id: number, body, return_null = false) {
    const params = ['items', 'pictures', 'comments', 'markers', 'status_id', 'custom_fields'];
    return this.http.put(
      `${this.apiURL}/budgets/${id}`,
      { budget: this.permitParams(body, ...params), return_null },
      this.getOptions(),
    );
  }

  publish(id: number) {
    return this.http.post(`${this.apiURL}/budgets/${id}/publish`, {}, this.getOptions());
  }

  tags(): Observable<HttpResponse<Tag[]>> {
    return this.http.get<Tag[]>(`${this.apiURL}/budgets/tags`, this.getParams());
  }

  groups(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.apiURL}/budgets/groups`, this.getParams());
  }

  products(group_id) {
    return this.http.get(`${this.apiURL}/products`, this.getParams({ category_id: group_id }));
  }

  addProduct(group_id: number, body) {
    return this.http.post(
      `${this.apiURL}/products`,
      { product: body },
      this.getParams({ category_id: group_id }),
    );
  }

  code(id: number, group_id: number, code: string) {
    return this.http.get(`${this.apiURL}/budgets/${id}/code`, this.getParams({ code, group_id }));
  }

  name(id: number, group_id: number, name: string) {
    return this.http.get(`${this.apiURL}/budgets/${id}/name`, this.getParams({ name, group_id }));
  }

  totalsByStatus() {
    return this.http.get(`${this.apiURL}/budgets/totals_by_status`, this.getParams());
  }

  approve_all(id: number) {
    return this.http.post(
      `${this.apiURL}/budgets/${id}/approve_all`,
      { type: 'InventoryActionForConsume' },
      this.getOptions(),
    );
  }

  decline_all(id: number) {
    return this.http.post(
      `${this.apiURL}/budgets/${id}/decline_all`,
      { type: 'InventoryActionForConsume' },
      this.getOptions(),
    );
  }

  remigrate(id: number) {
    return this.http.post(
      `${this.apiURL}/budgets/${id}/remigrate`,
      {},
      this.getOptions(),
    );
  }
}
