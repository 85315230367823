import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { HttpResponse } from "@angular/common/http";
import {Role} from "../../models/types";
import {RoleService} from "../../role/role.service";
import {RoleSelectorComponent} from "../../role/role-selector/role-selector.component";

@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.component.html',
  styleUrls: ['./assign-user.component.scss']
})
export class AssignUserComponent implements OnInit {
  roles = [];
  users = [];
  loading = false;
  constructor(
    private matDialogRef: MatDialogRef<RoleSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) private data:any[],
    public roleService: RoleService
  ) {

  }

  ngOnInit() {
    if(this.data.length===1){
      this.setRole({id: this.data[0]});
    }else{
      this.loading = true;
      this.roleService.simple_index().toPromise().then((response:HttpResponse<Role[]>) => {
        this.roles = response.body.filter((r)=> this.data.findIndex((i)=>i===r.id)>=0);
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }
  setRole(role) {
    this.loading = true;
    this.roleService.getUsers(role.id).toPromise().then((response: HttpResponse<any[]>)=>{
      this.loading = false;
      this.users = response.body;
    }).catch((error)=>{
      this.loading = false;
      console.log(error);
    });
  }
  setUser(user) {
    this.matDialogRef.close(user);
  }
}
