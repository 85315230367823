import {
  AfterViewInit,
  Component, OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { InventoryService } from 'app/inventory/inventory.service';
import { InventoryItem } from 'app/models/inventory.model';
import { WarehousesService } from 'app/services/warehouses.service';
import {
  catchError,
  map,
  merge, of, startWith, Subject, switchMap, takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-inventory-products',
  templateUrl: './inventory-products.component.html',
  styleUrls: ['./inventory-products.component.scss', '../inventory.component.scss'],
})
export class InventoryProductsComponent implements AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<InventoryItem>();

  displayedColumns: string[] = ['code', 'name', 'stock_on_hand', 'committed_stock', 'available_for_sale'];

  filters = new FormGroup({
    code: new FormControl(''),
    name: new FormControl(''),
    to_date: new FormControl(''),
  });

  isLoading: boolean = true;

  pageSizes = [10, 25, 50, 100];

  sort = 'code asc';

  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(MatSort) sorter: MatSort;

  totalData = 0;

  totalPages = 0;

  unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    public companyService: AdminService,
    public inventoryService: InventoryService,
    private warehousesService: WarehousesService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sorter;
    merge(
      this.paginator.page,
      this.sorter.sortChange.asObservable(),
      this.filters.valueChanges,
      this.warehousesService.selectedWarehouses.asObservable(),
    )
      .pipe(
        startWith({}),
        takeUntil(this.unsubscribe$),
        switchMap(() => {
          this.isLoading = true;
          return this.getTableData$(
            (this.paginator?.pageIndex ?? 0) + 1,
            (this.paginator?.pageSize ?? 10),
            this.sort,
            this.filters.getRawValue(),
          ).pipe(catchError(() => of(null)));
        }),
        map((data) => {
          if (data == null) return [];
          this.totalData = data.total_count;
          this.totalPages = data.total_pages;
          this.isLoading = false;
          return data.items;
        }),
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
      });
  }

  getTableData$(pageNumber: number, pageSize: number, sort: string, filters) {
    return this.inventoryService.index(
      this.warehousesService.selectedWarehouses.value.map((warehouse) => warehouse.id),
      pageNumber,
      pageSize,
      sort,
      filters,
    );
  }

  goToProductDetails(row: InventoryItem) {
    this.router.navigate([row.product.id], { relativeTo: this.route });
  }

  onSortChange({ active, direction }: { active: string; direction: string }) {
    if (!direction) {
      this.sort = 'code asc';
      return;
    }

    this.sort = `${active} ${direction}`;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
