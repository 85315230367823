import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';
import { StatusTransition } from 'app/models/status-transition.model';

@Injectable({
  providedIn: "root"
})
export class StatusTransitionsService extends BaseService implements ICRUDService {

  params = ['from_status_id','to_status_id','requirement_id', 'type', 'requirement_ids'];

  model = 'StatusTransition';

  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }

  index(value = null, page = 1, type = 'StatusTransitionForOrder') {
    return this.http.get(this.adminURL + '/status_transitions', this.getParams({search: value, page, type}));
  }

  create(value: StatusTransition[], type='StatusTransitionForOrder') {
    Object.assign(value, { type });
    return this.http.post(this.adminURL + '/status_transitions/', {
      status_transition: this.permitParams(value, ...this.params)
    }, this.getParams());
  }
  update(id: number, value: StatusTransition) {
    return this.http.put(this.adminURL + '/status_transitions/' + id, {
      status_transition: this.permitParams(value, ...this.params)
    }, this.getParams());
  }

  destroy(id: number) {
    return this.http.delete(this.adminURL + '/status_transitions/'+ id, this.getParams());
  }

  restore(id: number) {
    return this.http.delete(this.adminURL + '/status_transitions/'+ id + '/restore', this.getParams());
  }

  import(items=null) {
    return this.http.post(this.adminURL + '/status_transitions/import', {items: items}, this.getParams());
  }

  swap(ids: number[]) {
    return this.http.post(this.adminURL + '/status_transitions/swap', {ids}, this.getParams());
  }
}
