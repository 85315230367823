import { NgModule } from '@angular/core';

import { BaseModule } from '../../shared/base.module';
import { CommonFormsModule } from '../../shared/common-forms/common-forms.module';
import { BudgetWorkComponent } from './budget-work/budget-work.component';
import { CustomPlaceholderComponent } from './custom-params/custom-params.component';
import { FormFillerComponent } from './form-filler/form-filler.component';
import { InventoryActionsComponent } from './inventory-actions/inventory-actions.component';
import { OrderProductsComponent } from './products/order-products.component';
import { TextFormComponent } from './text/text-form.component';
import { WplPaymentComponent } from './wpl-payment/wpl-payment.component';

@NgModule({
  imports: [
    BaseModule,
    CommonFormsModule,
  ],
  declarations: [
    OrderProductsComponent,
    TextFormComponent,
    CustomPlaceholderComponent,
    BudgetWorkComponent,
    InventoryActionsComponent,
    WplPaymentComponent,
    FormFillerComponent,
  ],
  exports: [
    OrderProductsComponent,
    TextFormComponent,
    CustomPlaceholderComponent,
    InventoryActionsComponent,
    BudgetWorkComponent,
    FormFillerComponent,
  ],
})
export class WorkPluginsModule { }
