import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component, OnInit,
} from '@angular/core';
import {
  FormGroup, UntypedFormBuilder, Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategoriesService } from 'app/admin/services/product-categories.service';
import { Product } from 'app/models/product.model';
import { Observable, of } from 'rxjs';
import {
  debounceTime, map, switchMap,
} from 'rxjs/operators';

import { KitProductsService } from './services/kit-products.service';

@Component({
  selector: 'app-admin-kit-products',
  templateUrl: './admin-kit-products.component.html',
  styleUrls: ['./admin-kit-products.component.scss', '../admin-main.common.scss'],
})
export class AdminKitProductsComponent implements OnInit {
  form: FormGroup = this.fb.group({
    quantity: this.fb.control(1, { nonNullable: true, validators: [Validators.required, Validators.min(1)] }),
    product_category_id: this.fb.control(null, Validators.required),
  });

  id: number;

  filteredProducts$: Observable<Product[]>;

  products: Product[];

  selectedProductPrice: number;

  selectedProductUnit: string;

  constructor(
    public kitProductsService: KitProductsService,
    public fb: UntypedFormBuilder,
    public router: Router,
    private productCategoriesService: ProductCategoriesService,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((value) => {
      this.id = value.kit_id;
    });

    this.filteredProducts$ = this.form.get('product_category_id').valueChanges.pipe(
      debounceTime(500),
      switchMap((value) => {
        if (typeof value === 'string' && value.length > 2) {
          const search = value.toLowerCase();
          return this.productCategoriesService.select(search).pipe(
            map((response: HttpResponse<Product[]>) => {
              this.products = response.body;
              return response.body;
            }),
          );
        }

        return of([]);
      }),
    );
  }

  goUp() {
    this.location.back();
  }

  displayProduct = (productId: number) => {
    if (productId && this.products) {
      const product = this.products.find((p) => p.id === productId);
      this.selectedProductPrice = product.price;
      this.selectedProductUnit = product.category.unit;
      return `[${product.code}] ${product.name}`;
    }
    return '';
  };
}
