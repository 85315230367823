<mat-card>
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración General</mat-card-title>
    <mat-card-subtitle>Administre la configuración de la aplicación</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="hours">
          <form [formGroup]="companyForm">
            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input matInput placeholder="Nombre" formControlName="name">
              <mat-hint>Nombre del Local, Compañía, etc</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Dominio</mat-label>
              <input matInput placeholder="Dominio" formControlName="domain" >
              <mat-hint>Dominio Web desde el cual se accederá a la plataforma. Ej: https://www.controlcar.cl</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Casilla de Correo</mat-label>
              <input matInput placeholder="Casilla de Correo" formControlName="email" >
              <mat-hint>Para agendamientos vía email entre otros</mat-hint>
            </mat-form-field>
            <fieldset formGroupName="preferences" #group>
              <div class="logo">
                <mat-form-field>
                  <mat-label>URL Logo</mat-label>
                  <input matInput placeholder="URL Logo" formControlName="logo">
                  <mat-hint>Enlace directo al logo de la Compañia</mat-hint>
                </mat-form-field>
                <div class="image" (click)="uploadPhotos(logo)" [class.disabled]="uploadingFiles">
                  <input type="file" style="display: none" name="image" #logo (change)="uploadFile($event,'logo')">
                  <div class="add-image"><i class="fa fa-upload" ></i></div>
                </div>
              </div>
              <mat-form-field>
                <mat-label>Agendamiento</mat-label>
                <mat-select placeholder="Agendamiento" formControlName="booking_type" name="booking_type" autocomplete="off" multiple="false" required>
                  <mat-option *ngFor="let booking_type of booking_types" [value]="booking_type.id">
                    {{booking_type.name}}
                  </mat-option>
                </mat-select>
                <mat-hint>Seleccione tipo de agendamiento</mat-hint>
              </mat-form-field>
              <mat-form-field *ngIf="getPref().controls['booking_type'].value == 'professional'">
                <mat-label>Profesional</mat-label>
                <mat-select placeholder="Profesional" formControlName="booking_role_ids" name="booking_role_ids" autocomplete="off" required [multiple]="true">
                  <mat-option *ngFor="let role of roles" [value]="role.id">
                    {{role.name}}
                  </mat-option>
                </mat-select>
                <mat-hint>El rol que se usará para el agendamiento según profesionales</mat-hint>
              </mat-form-field>
              <mat-checkbox formControlName="temp_identifier" style="margin-bottom: 10px;">Identificador opcional en agendamiento</mat-checkbox>
              <mat-form-field>
                <mat-label>Direccion</mat-label>
                <input matInput placeholder="Dirección" formControlName="address">
                <mat-hint>Se mostrará en los correos</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Telefono</mat-label>
                <input matInput placeholder="Teléfono" formControlName="phone">
                <mat-hint>Se mostrará en los correos</mat-hint>
              </mat-form-field>
              <div class="row">
                <div class="col-full">
                  <div class="workdays">
                    <h4>Reportes</h4>
                    <mat-form-field style="margin-bottom: 10px">
                      <mat-label>Frecuencia de envio</mat-label>
                      <mat-select placeholder="Frecuencia de envio" formControlName="report_send_frecuency" required>
                        <mat-option value="daily">Diario</mat-option>
                        <mat-option value="weekly">Semanal</mat-option>
                        <mat-option value="monthly">Mensual</mat-option>
                      </mat-select>
                      <mat-hint>Cada cuanto se enviará el reporte</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Roles</mat-label>
                      <mat-select placeholder="Roles" formControlName="report_role_ids" name="report_role_ids" autocomplete="off" multiple="true">
                        <mat-option *ngFor="let role of roles" [value]="role.id">
                          {{role.name}}
                        </mat-option>
                      </mat-select>
                      <mat-hint>Roles a los que se les enviará el reporte</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>
