import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Warehouse } from 'app/models/warehouse.model';
import { BehaviorSubject, map } from 'rxjs';

import { BaseService } from './_base.service';

@Injectable()
export class WarehousesService extends BaseService {
  public selectedWarehouses: BehaviorSubject<Warehouse[]> = new BehaviorSubject([]);

  index() {
    return this.http.get<Warehouse[]>(`${this.apiURL}/warehouses`, this.getParams())
      .pipe(
        map((response: HttpResponse<Warehouse[]>) => response.body),
      );
  }
}
