import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';

import { CommonDirectivesModule } from './directives/common-directives.module';
import { MaterialModule } from './material.module';
import { CommonPipesModule } from './pipes/common-pipes.module';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  decimal: ',',
  precision: 0,
  prefix: '$',
  suffix: '',
  thousands: '.',
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    LazyLoadImageModule,
    MaterialModule,
    CommonPipesModule,
    CommonDirectivesModule,
    CurrencyMaskModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    LazyLoadImageModule,
    MaterialModule,
    CommonPipesModule,
    CommonDirectivesModule,
    CurrencyMaskModule,
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
})
export class BaseModule { }
