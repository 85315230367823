import {
  Component, Inject, Input, OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Kit } from 'app/models/kits.model';
import { KitsService } from 'app/services/kits.service';
import {
  debounceTime, Observable, switchMap,
} from 'rxjs';

function displayProduct(product) {
  return product.name;
}

@Component({
  selector: 'app-kits-dialog',
  templateUrl: './kits-dialog.component.html',
  styleUrls: ['./kits-dialog.component.scss'],
})

export class KitsDialogComponent implements OnInit {
  public displayProduct = displayProduct;

  public filteredKits$: Observable<Kit[]>;

  public form: FormGroup = this.fb.group({
    search: [''],
  });

  @Input() public parentFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<KitsDialogComponent>,
    private kitsService: KitsService,
  ) {}

  ngOnInit() {
    this.filteredKits$ = this.form.get('search').valueChanges.pipe(
      debounceTime(300),
      switchMap((searchValue: string) => this.kitsService.searchByCode(searchValue)),
    );
  }

  selectKit() {
    const product = this.form.get('search').value;
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close(product);
    } else {
      this.parentFormGroup.addControl('product_id', this.fb.control(product.id));
    }
  }

  disabledButton() {
    return typeof this.form.get('search').value !== 'object';
  }
}
