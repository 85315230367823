import { AsyncPipe, NgFor } from '@angular/common';
import {
  AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption, MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  ReplaySubject, Subject, takeUntil,
} from 'rxjs';

type ItemType = {
  name: string;
  id: number;
};

@Component({
  selector: 'app-multi-select-dropdown',
  standalone: true,
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: './multi-select-dropdown.component.scss',
  imports: [
    MatOption,
    MatSelect,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckbox,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    AsyncPipe,
    NgFor,
  ],
})
export class MultiSelectDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('matSelectElement') matSelectElement: MatSelect;

  allOptionsSelected = false;

  @Input() formControl: FormControl;

  @Input() items: ItemType[];

  @Input() name: string;

  @Input() label: string;

  filteredItems$: ReplaySubject<ItemType[]> = new ReplaySubject<ItemType[]>(1);

  searchControl: FormControl<string> = new FormControl<string>('');

  onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterOptions();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  /**
   * Sets the initial value after the filtered items are loaded initially
   */
  protected setInitialValue() {
    const items = this.appendSelectedItems(this.items.slice(0, 100));

    this.filteredItems$.next(items);
  }

  // eslint-disable-next-line class-methods-use-this
  public toggleAllSelection(selector: MatSelect, isSelectedAll: boolean) {
    if (isSelectedAll) {
      selector.options.forEach((item: MatOption) => { if (item.value != null) item.select(); });
    } else {
      selector.options.forEach((item: MatOption) => item.deselect());
    }
  }

  private appendSelectedItems(items) {
    const itemsId = items.map((item) => item.id);
    const selectedIds = this.formControl.value?.filter((id: number) => !itemsId.includes(id));
    if (selectedIds) {
      const selectedItems = this.items.filter((item) => selectedIds.includes(item.id));
      items.push(...selectedItems);
    }

    return items;
  }

  filterOptions() {
    if (!this.items) return;

    let searchValue = this.searchControl.value;

    if (!searchValue) {
      this.filteredItems$.next(this.appendSelectedItems(this.items.slice(0, 100)));
      return;
    }

    searchValue = searchValue.toLocaleLowerCase();

    this.filteredItems$.next(
      this.appendSelectedItems(
        this.items.filter((m) => m.name.toLowerCase().indexOf(searchValue) > -1).slice(0, 100),
      ),
    );
  }

  displayItem(value) {
    return this.items?.find((item) => item.id === value)?.name ?? '';
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
