import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../services/users.service";
import {RoleService} from "../../../role/role.service";
import { HttpResponse } from "@angular/common/http";
import {AdminService} from '../../services/admin.service';
import { LocationService } from 'app/admin/services/location.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss','../admin-main.common.scss']
})
export class AdminUsersComponent implements OnInit {
  heading;
  locations = [];
  days = [
    {name: 'Lunes', id: 1},
    {name: 'Martes', id: 2},
    {name: 'Miércoles', id: 3},
    {name: 'Jueves', id: 4},
    {name: 'Viernes', id: 5},
    {name: 'Sábado', id: 6},
    {name: 'Domingo', id: 7}
  ];
  form = new UntypedFormGroup({
    rut: new UntypedFormControl(),
    name: new UntypedFormControl(),
    mail: new UntypedFormControl(),
    password: new UntypedFormControl(null,[Validators.minLength(6)]),
    password_confirmation: new UntypedFormControl(null,[
      Validators.minLength(6)
    ]),
    role_id: new UntypedFormControl(),
    online: new UntypedFormControl(),
    active: new UntypedFormControl(),
    phone: new UntypedFormControl(),
    location_ids: new UntypedFormControl([]),
    block_hours: new UntypedFormArray([])
  },this.samePassword.bind(this));
  roles = [];
  country;
  constructor(
    public usersService: UsersService,
    private roleService: RoleService,
    private companyService: AdminService,
    private locationService: LocationService
  ) {

    for(let i = 1; i<=7; i++){
      const blockHourForm = new UntypedFormGroup({
        enabled: new UntypedFormControl(false),
        day: new UntypedFormControl(i),
        start_time: new UntypedFormControl('13:00'),
        end_time: new UntypedFormControl('14:00')
      });
      (<UntypedFormArray>this.form.controls['block_hours']).push(blockHourForm);
    }

  }

  ngOnInit() {
    this.heading = this.companyService.getText('location');
    this.locationService.all().toPromise().then((response: HttpResponse<any>) => {
      this.locations = response.body;
    })
    this.country = this.companyService.local$.getValue().country;
    this.roleService.simple_index().toPromise().then((response: HttpResponse<any[]>)=>{
      this.roles = response.body.filter(value => {
        if(value.name=="Admin" && !this.roleService.can('read','admin')){
          return false;
        }else{
          return true;
        }
      });
    }).catch((error)=>{
      this.roles = [];
    });
  }
  addBlockRow(){
    (<UntypedFormArray>this.form.get('block_hours')).push(new UntypedFormGroup({
      enabled: new UntypedFormControl(false),
      day: new UntypedFormControl(1, Validators.required),
      start_time: new UntypedFormControl('13:00', Validators.required),
      end_time: new UntypedFormControl('14:00', Validators.required)
    }));
  }
  samePassword(g: UntypedFormGroup){
    if(g.value['password']===g.value['password_confirmation']){
      return null;
    }else{
      g.controls['password_confirmation'].setErrors({notEqual: true});
      return {
        notEqual: true
      };
    }
  }

}
