<div mat-dialog-title class="name">
  Seleccione Vehículo
</div>
<div mat-dialog-content class="content">

  <mat-list>
    <mat-list-item *ngFor="let vehicle of vehicles" (click)="selectVehicle(vehicle)" class="option">
      <span matListItemTitle>{{vehicle.brand?.name}} - {{vehicle.brand_model.name}}</span>
      <span matListItemLine> <i class="fa fa-car"></i> {{vehicle.identifier}}</span>
    </mat-list-item>
  </mat-list>

</div>

<div mat-dialog-actions class="footer">
  <button mat-button cdkFocusInitial (click)="selectVehicle(null)">Crear nuevo Vehículo</button>
</div>