<mat-toolbar>
  <i class="fa fa-arrow-left" (click)="goBack()"></i>
  <div class="hide-mobile">Cliente&nbsp;</div> Nº {{client?.id}}
  <div class="expander"></div>
  <button mat-button (click)="openNotifications()" matTooltip="Estado de Notificaciones"><i class="fa fa-bell"></i></button>
  <a mat-button href="tel:{{client?.phone}}" class="phone" matTooltip="Llamar"><i class="fa fa-phone"></i></a>
</mat-toolbar>
<div class="wrapper" #wrapper>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="client" *ngIf="client">
        <div class="header">
          <div class="date">
            <span>{{client.created_at | date:'shortDate'}}</span>
            <div class="expander">
              <div class="only-print" style="font-weight: bold">
                {{adminService.getText('client','uppercase')}}#{{client.correlative_id}}
              </div>

            </div>
            <span class="right">Actualización: {{client.updated_at | date:'shortDate'}}</span>
          </div>
          <div class="title">
            <div class="logo">
              <img [src]="company_logo">
            </div>
          </div>
        </div>
        <div class="content">
          <app-client-form *ngIf="(roleService.can('update','client') | async)" [parentFormGroup]="clientForm" [clientMode]="true"></app-client-form>
          <ng-container *ngIf="!(roleService.can('update','client') | async)">
          <div class="info">
            <div class="name">Nombre</div>
            <div class="value">{{client.name || 'No existe el '+adminService.getText('client')}}</div>
          </div>
          <div class="info">
            <div class="name">{{adminService.getText('client_identifier')}}</div>
            <div class="value">{{client.rut}}</div>
          </div>
          <div class="info">
            <div class="name">Dirección</div>
            <div class="value">{{client.address}}, {{client.city}}</div>
          </div>
          <div class="info">
            <div class="name">E-mail</div>
            <div class="value">{{client.email}}</div>
          </div>
          <div class="info last">
            <div class="name">Teléfono</div>
            <div class="value"><a href="tel:{{client.phone}}" class="phone"><i class="fa fa-phone"></i>{{client.phone}}</a></div>
          </div>
          </ng-container>
          <div class="vehicle" *ngFor="let vehicle of client.vehicles">
            <div class="title">
              <div class="title-info">
                <div class="icon">
                  <i class="fa fa-car"></i>
                </div>
                <div class="title-data">
                  <div class="first-line">{{vehicle.identifier}} - {{vehicle.brand_name}} {{vehicle.brand_model_name}}</div>
                  <div class="second-line">{{vehicle.chasis}}</div>
                </div>
              </div>
              <div class="expander"></div>
              <div class="kms">{{vehicle.kms | currency:'':'':'0.0-0'}} KMS</div>
            </div>
            <div class="orders">
              <div class="order" *ngFor="let order of vehicle.orders; let last = last"
                   [class.ended_at]="order.ended_at"
                   [class.completed_at]="order.completed_at && !order.ended_at"
                   [class.check_in_time]="order.check_in_time && !order.completed_at && !order.ended_at"
                   [class.booking_time]="order.booking_date && !order.check_in_time && !order.ended_at"
                   (click)="openOrder(order)"
              >
                <div class="icon">
                  <i class="fa fa-check-double" *ngIf="order.ended_at"></i>
                  <i class="fa fa-check" *ngIf="order.completed_at && !order.ended_at"></i>
                  <i class="fa fa-wrench" *ngIf="order.check_in_time && !order.completed_at"></i>
                  <i class="fa fa-clock" *ngIf="order.booking_date && !order.check_in_time && !order.ended_at"></i>
                </div>
                <div class="requirement">
                  <div>{{order.requirement_name}}</div>
                </div>
                <div class="order-info">
                  <div>KMS: {{order.kms | currency:'':'':'0.0-0'}}</div>
                  <div *ngIf="order.ended_at">Entregado: {{order.ended_at | date:'shortDate'}}</div>
                  <div *ngIf="order.completed_at && !order.ended_at">Completado: {{order.completed_at | date:'shortDate'}}</div>
                  <div *ngIf="order.check_in_time && !order.completed_at">Ingresado: {{order.check_in_time | date:'shortDate'}}</div>
                  <div *ngIf="order.booking_date && !order.check_in_time && !order.ended_at">Agendado: {{order.booking_date | date:'shortDate'}}</div>
                </div>
              </div>
              <div class="order" (click)="createBooking(vehicle)">
                <div class="icon">
                  <i class="fa fa-calendar-plus"></i>
                </div>
                <div class="requirement">
                  <div>Agendar vehiculo</div>
                </div>
              </div>
            </div>
          </div>
          <div class="subtitle hide-print">Trabajos</div>
          <app-order-works [order]="client" class="hide-print" (load)="ngOnInit()"></app-order-works>
          <div class="subtitle">Bitácora</div>
          <app-order-comments [comments]="client.comments" (saveComment)="saveComment($event)" *ngIf="client && client.comments"></app-order-comments>
        </div>
        <div class="buttons">
          <button mat-raised-button color="primary"  *ngIf="(roleService.can('update','client') | async)" (click)="saveClient()"><i class="fa fa-save" style="margin-right: 0"></i></button>
          <div class="expander"></div>
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu"  *ngIf="(roleService.can('update','client') | async) && client.status_id"><i class="fa fa-pencil-alt"></i><span class="hide-mobile">{{client.status_id | status:'name':statuses}}</span></button>
          <button mat-raised-button color="accent"  *ngIf="(roleService.can('update','client') | async) && !client.status_id" (click)="startManagement()"><i class="fa fa-pencil-alt"></i><span class="hide-mobile">Iniciar gestión</span></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let status of statuses" (click)="setStatus(status.id)" [style.color]="status.color">{{status.name}}</button>
          </mat-menu>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="no-access" *ngIf="forbidden">
    <i class="fa fa-warning"></i>
    No tienes acceso a esta información
  </div>
</div>
