import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class BrandModelsService extends BaseService implements ICRUDService {
  params = ['name'];

  model = 'BrandModel';

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(value = null, page = 1, brand_id) {
    return this.http.get(`${this.adminURL}/brands/${brand_id}/brand_models`, this.getParams({ search: value, page }));
  }

  create(value, brand_id) {
    return this.http.post(`${this.adminURL}/brands/${brand_id}/brand_models`, {
      brand_model: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id, value, brand_id) {
    return this.http.put(`${this.adminURL}/brands/${brand_id}/brand_models/${id}`, {
      brand_model: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  destroy(id, brand_id) {
    return this.http.delete(`${this.adminURL}/brands/${brand_id}/brand_models/${id}`, this.getParams());
  }

  restore(id, brand_id) {
    return this.http.delete(`${this.adminURL}/brands/${brand_id}/brand_models/${id}/restore`, this.getParams());
  }
}
