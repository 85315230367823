import {NgModule} from '@angular/core';
import {ImageUploaderComponent} from "./image-uploader.component";
import {BaseModule} from "../base.module";
import {GalleryComponent} from "./gallery/gallery.component";
import {ImageZoomComponent} from "./image-zoom/image-zoom.component";
import {AdvancedImageUploaderComponent} from "./advanced-image-uploader/advanced-image-uploader.component";
import {CameraComponent} from "./camera/camera.component";


@NgModule({
  declarations: [
    ImageUploaderComponent,
    GalleryComponent,
    ImageZoomComponent,
    AdvancedImageUploaderComponent,
    CameraComponent
  ],
  imports: [
    BaseModule
  ],
  exports: [
    ImageUploaderComponent,
    GalleryComponent,
    ImageZoomComponent,
    AdvancedImageUploaderComponent,
    CameraComponent
  ]
})
export class ImageUploaderModule { }
