<mat-toolbar class="hide-print header" *ngIf="!clientMode">
  <div
    class="back"
    (click)="goBack()"
    (keydown)="$event.keyCode === 13 && goBack()"
    tabindex
    matTooltip="Atrás"
    matTooltipPosition="right"
  >
    <mat-icon >arrow_back_ios_new</mat-icon>
  </div>
  <div style="font-weight: 500;">
    <span class="hide-mobile">Presupuesto de Trabajo&nbsp;</span>Nº {{budget?.correlative_id}}
  </div>

  <div class="expander"></div>

  <i class="fa fa-spin fa-spinner" *ngIf="saving"></i>
  <i class="fa fa-check" *ngIf="!saving && saved" matTooltip="Guardado"></i>
  <i
    class="fa fa-save"
    *ngIf="!saving && !saved && (roleService.can('update','budget') | async) && !budget.status.locked"
    matTooltip="Guardar"
    (click)="saveBudget(true)"
    (keyup)="keyCode === 13 && saveBudget(true)"
    tabindex
  >
  </i>

  <button mat-button [mat-menu-trigger-for]="menu"><mat-icon>more_horiz</mat-icon></button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="print()"><mat-icon>download</mat-icon> Descargar</button>
    @if (budget && budget.items.length > 0) {
      <button mat-menu-item (click)="remigrate()"><mat-icon>replay</mat-icon> Restaurar</button>
    }
  </mat-menu>

</mat-toolbar>

<div class="wrapper" #wrapper>
  @if (!budget) {
    <div class="skeleton-container" >
      <div class="skeleton" style="height: 98px;"></div>
      <div class="skeleton" style="height: 123px;"></div>
      <div class="skeleton" style="height: 170px;"></div>
      <div class="skeleton" style="height:  74px;"></div>
      <div class="skeleton" style="height:  90px;"></div>
    </div>
  }

  <div class="order" *ngIf="budget">
    <div class="content">
      <div class="subtitle">Solicitante</div>
      <div class="info">
        <div class="name">Nombre</div>
        <div class="value">{{budget.user?.name}}</div>
      </div>
      <div class="info last">
        <div class="name">Creación</div>
        <div class="value">{{budget.created_at | date:'shortDate'}}</div>
      </div>
      <div class="subtitle">Orden Nº {{budget.order.correlative_id}}
        <div class="expander"></div>
        <div class="order-button" (click)="goOrder()" (keydown)="$event.keyCode === 13 && goOrder()" tabindex>Ver Orden</div>
      </div>
      <div class="info">
        <div class="name">Requerimiento</div>
        <div class="value">{{budget.order.requirement?.name}}</div>
      </div>
      <div class="info last hide-print">
        <div class="name">Comentario</div>
        <div class="value">{{budget.order.comment}}</div>
      </div>
      <div class="subtitle">Cliente #{{budget.order.client_id}}</div>
      <div class="info">
        <div class="name">Nombre</div>
        <div class="value">{{budget.order.client?.name || 'No existe el cliente'}}</div>
      </div>
      <div class="info last">
        <div class="name">Teléfono</div>
        <div class="value"><a href="tel:{{budget.order.client?.phone}}" class="phone"><i class="fa fa-phone"></i>{{budget.order.client?.phone}}</a></div>
      </div>
      <ng-container *ngIf="budget.order.vehicle">
        <div class="subtitle">Vehículo #{{budget.order.vehicle_id}}</div>
        <div class="info">
          <div class="name">Patente</div>
          <div class="value">{{budget.order.vehicle?.identifier}}</div>
        </div>
        <div class="info hide-print">
          <div class="name">Marca</div>
          <div class="value">{{budget.order.vehicle?.brand?.name}}</div>
        </div>
        <div class="info hide-print">
          <div class="name">Modelo</div>
          <div class="value">{{budget.order.vehicle?.brand_model?.name}}</div>
        </div>
        <div class="info">
          <div class="name">Chasis</div>
          <div class="value">{{budget.order.vehicle?.chasis || '-'}}</div>
        </div>
        <div class="info hide-print" *ngIf="budget.order.kms >= 0">
          <div class="name">Kilometraje</div>
          <div class="value">{{budget.order.kms || budget.order.vehicle?.kms}}</div>
        </div>
        <div class="info last hide-print">
          <div class="name">Color</div>
          <div class="value">{{budget.order.vehicle?.color}}</div>
        </div>
      </ng-container>

      @if(!this.budget?.status?.locked || budget.pictures?.length > 0){
      <div class="subtitle">Imagenes adjuntas al Presupuesto</div>
      <div class="images-container">
        <app-image-uploader
          [gallery]="budget.order.order_attachments.concat(budget.order.pictures)"
          [photos]="budget.pictures"
          (uploaded)="setImages($event)"
          [scrollTarget]="wrapper"
          [small]="true"
          [readonly]="((roleService.can('update','budget') | async) === false) || budget.status.locked"
        >
        </app-image-uploader>
      </div>
     }

      <div class="subtitle">Servicios</div>
      <div class="items" #inputs>

        <div class="items-empty" [hidden]="inventory_actions_form.controls.length !== 0">
          No hay items. Por favor agregue marcadores para empezar
        </div>
        <ng-container>

        @if (!(adminService.local$.getValue().preferences.budget_type === 'simple')){
          <div class="expanded-views-container">
            @for (formGroup of inventory_actions_form.controls; track formGroup.getRawValue().id) {
              <div class="expanded-view">
                <div class="expanded-view__header">
                    <div class="tag_circle" [style.background-color]="getTag(formGroup.get('tag_id')?.value)?.color"></div>
                    <span class="header_text"> {{getTag(formGroup.get('tag_id')?.value)?.name}}</span>

                    @if (!budget.status.locked && !formGroup.getRawValue().status.locked) {
                      <div class="expander"></div>
                      <button mat-icon-button matSuffix (click)="removeItem($index)" matTooltip="Remover">
                        <mat-icon color="warn">delete</mat-icon>
                      </button>

                    }
                </div>
                @if((formGroup.get('pictures')?.value?.length > 0) || !budget.status.locked ){
                  <span class="photo-subtitle">Fotos adjuntas</span>
                  <app-image-uploader
                    [gallery]="budget.order.order_attachments.concat(budget.pictures)"
                    [photos]="formGroup.get('pictures')?.value"
                    (uploaded)="setImagesToInventoryAction(formGroup, $index, $event)"
                    [scrollTarget]="wrapper"
                    [small]="true"
                    [readonly]="((roleService.can('update','budget') | async) === false)"
                    [allowUploadPhoto]="false"
                    [allowUploadFile]="false"
                    [allowAdvanceUpload]="false"
                    [readonly]="((roleService.can('update','budget') | async) === false) || budget.status.locked"
                    >
                  </app-image-uploader>

                }



                <app-inventory-action-form
                  [parentFormGroup]="formGroup"
                  [filterCategories]="getTagCategories(formGroup.getRawValue().tag_id)"
                  (dataChanged)="updateInventoryAction($index)"
                  [showTotals]="false"
                  (approved)="saveBudget(true)"
                  (declined)="saveBudget(true)"
                  [simpleView]="adminService.local$.getValue().preferences.budget_type === 'simple'"
                  [brandId]="budget.order.object?.brand?.id"
                  parentModel="budget"
                >
                </app-inventory-action-form>
              </div>
            }
            @if(!budget.status.locked
              && (roleService.can('update','budget') | async)){
              <div class="expanded-view">
                <div class="add-marker" >
                  <button class="add-button" *ngFor="let tag of tags; let index = index" [class.saving]="saving"
                      mat-button
                      (click)="createInventoryAction(tag)"
                      [disabled]="saving"
                      [style.background-color]="tag?.color"
                      [style.color]="'white'"
                  >
                    <i class="fas fa-plus"></i> {{tag.name}}
                  </button>
                </div>
              </div>

            }
          </div>
        }
        @else{
          @for (formGroup of inventory_actions_form.controls; track formGroup.getRawValue().id) {
            <div class="simple-view-container">
              <div class="tag-container">
                <div class="tag_bar" [style.background-color]="getTag(formGroup.get('tag_id')?.value)?.color"></div>
              </div>
              <div class="inventory-container">
                <app-inventory-action-form
                    [parentFormGroup]="formGroup"
                    [filterCategories]="getTagCategories(formGroup.getRawValue().tag_id)"
                    (dataChanged)="updateInventoryAction($index)"
                    [showTotals]="false"
                    (approved)="saveBudget(true)"
                    (declined)="saveBudget(true)"
                    [simpleView]="adminService.local$.getValue().preferences.budget_type === 'simple'"
                    [brandId]="budget.order.object?.brand?.id"
                    parentModel="budget"
                    [gallery]="budget.order.order_attachments.concat(budget.pictures)"
                    [photos]="formGroup.get('pictures')?.value"
                    [isReadOnly]="((roleService.can('update','budget') | async) === false) || budget.status.locked"        
                  >
                  </app-inventory-action-form>
              </div>
              @if (!budget.status.locked && !formGroup.getRawValue().status.locked) {
                <button mat-mini-fab class="delete-button" (click)="removeItem($index)" matTooltip="Remover"><mat-icon>delete</mat-icon></button>
              }
              
              @if (!budget.status.locked){
              <button mat-mini-fab class="duplicate-button" (click)="duplicateInventoryAction($index)"  matTooltip="Duplicar" [disabled]="working">
                @if (!working){
                  <mat-icon>content_copy</mat-icon>
                }
                @else {
                  <mat-icon>hourglass_empty</mat-icon>
                }
              </button>
              }
            </div>
          }
          @if(!budget.status.locked && (roleService.can('update','budget') | async)){
            <div class="simple-view-container">
              <div class="add-marker" >
                <button class="add-button" *ngFor="let tag of tags; let index = index" [class.saving]="saving"
                    mat-button
                    (click)="createInventoryAction(tag)"
                    [disabled]="saving"
                    [style.background-color]="tag?.color"
                    [style.color]="'white'"
                >
                  <i class="fas fa-plus"></i> {{tag.name}}
                </button>
              </div>
            </div>

          }
        }



        </ng-container>
      </div>
      <div class="subtitle">Totales</div>
      <div class="totals">
        @for(category of categories; track category.id) {
          @if (totals[category.id] !== undefined){
            <div class="total">{{category.name}}: {{totals[category.id] | money}}</div>
          }
        }
        <div class="total" [ngClass]="{'discount': discountTotal > 0}">Neto: {{budget.total + discountTotal| money}}</div>
        <div class="total" *ngIf="discountTotal > 0">Descuentos: -{{ discountTotal | money }}</div>
        <div class="total discount__label" *ngIf="discountTotal > 0">Neto: {{budget.total| money}}</div>

        <div class="total">{{adminService.getText('tax','uppercase','IVA')}}: {{budget.total*(country.preferences.tax_rate - 1) | money}}</div>
        <div class="total">Total: {{budget.total*(country.preferences.tax_rate) | money}}</div>
      </div>

    </div>
    <div class="content hide-print" *ngIf="!clientMode">

      <app-order-fields
        [parentFormGroup]="budgetForm"
        [readonly]="clientMode || budget.status.locked"
        [reactiveFormMode]="true"
        [model]="budget"
        mode="Budget"
      ></app-order-fields>

      <mat-accordion multi>

        <mat-expansion-panel class="budget-expansion-panel-works expansion-panel">
          <mat-expansion-panel-header>
              <div class="panel-title">
                <mat-icon>build_circle</mat-icon>
                Trabajos&nbsp;<span>({{budget.works?.length}})</span>
              </div>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <app-order-works [order]="budget" (load)="load()"></app-order-works>
          </mat-expansion-panel-content>
        </mat-expansion-panel>

        <mat-expansion-panel class="expansion-panel" *ngIf="budget && budget.comments">
          <mat-expansion-panel-header>
              <div class="panel-title">
                <mat-icon>forum</mat-icon>
                Bitácora&nbsp;<span>({{budget.comments?.length || 0}})</span>
              </div>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <app-order-comments [comments]="budget.comments" (saveComment)="saveComment($event)"></app-order-comments>
          </mat-expansion-panel-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

</div>
<mat-toolbar class="hide-print footer" *ngIf="!clientMode && budget">
  <div class="buttons hide-print">
    <button mat-raised-button class="primary" [matMenuTriggerFor]="menu"  *ngIf="(roleService.can('update','budget') | async)"><i class="fa fa-pencil-alt"></i><span class="hide-mobile">{{budget.status.name}}</span></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let status of statuses" (click)="setStatus(status.id)" [style.color]="status.color" [disabled]="working">{{status.name}}</button>
    </mat-menu>
  </div>
  <div class="expander"></div>
  @if(this.budget?.status?.approval){
    <button mat-button class="reject display-desktop" (click)="declineAll()" [disabled]="working">Rechazar Todos</button>
    <button mat-button class="approval display-desktop" (click)="approveAll()" [disabled]="working">Aprobar Todos</button>
  }
  <span class="footer-total">Total: {{budget.total*(country.preferences.tax_rate) | money}}</span>
</mat-toolbar>
