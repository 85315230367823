import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CheckInService} from "../../../services/check-in.service";
import {RoleService} from "../../../role/role.service";

@Component({
  selector: 'wpl-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['./order-products.component.scss']
})
export class OrderProductsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() parentForm:UntypedFormGroup;
  @Input() value:any = {};
  @Input() disabled = false;
  @Input() request;
  @Input() clientMode = false;
  key = 'custom_params';
  added = {};
  working = false;
  form:UntypedFormGroup = new UntypedFormGroup({order_items: new UntypedFormArray([])});
  constructor(
    private orderService: CheckInService,
    public roleService: RoleService
  ) {
  }

  ngOnInit() {
    Promise.resolve(null).then(() => {
      this.formItems.push(this.buildItem());
      if(this.value && this.value['order_items']){
        for(let i =1; i < this.value['order_items'].length; i++){
          this.formItems.push(this.buildItem());
        }
        this.form.patchValue(this.value);
      }
      this.parentForm.setControl(this.key,this.form);
    });
    this.setupAdded();
  }
  ngOnChanges(changes: SimpleChanges){
    this.form.patchValue(this.value);
  }
  buildItem() {
    return new UntypedFormGroup({
      item_quantity: new UntypedFormControl({disabled: this.disabled, value: null},Validators.required),
      item_name: new UntypedFormControl({disabled: this.disabled, value: null},Validators.required),
      item_description: new UntypedFormControl({disabled: this.disabled, value: null}),
      item_price: new UntypedFormControl({disabled: this.disabled, value: null}),
      item_available: new UntypedFormControl({disabled: this.disabled, value: false})
    });
  }
  removeItem(index){
    if(!this.disabled){
      if(this.formItems.length>1){
        this.formItems.removeAt(index);
      }
    }
  }
  addItem(){
    this.formItems.push(this.buildItem());
  }
  addToOrder(formGroup:UntypedFormGroup,index){
    this.added[index] = true;
    this.orderService.update(this.request.order_id,{check_in:{order_items: [formGroup.value]}}).toPromise().then((response)=>{
    }).catch((error)=>{
      console.log(error);
    });
  }
  get formItems(){
    return <UntypedFormArray>this.form.get('order_items');
  }
  setupAdded(){
    if(this.request && this.request.order && this.request.order.order_items){
      this.request.order.order_items.forEach((item)=>{
        const index = this.value.order_items.findIndex((v)=>v.item_name===item.item_name);
        if(index >= 0){
          this.added[index] = true;
        }
      });
    }
  }
  getTotal(){
    return this.formItems.controls.reduce(((previousValue, currentValue:UntypedFormGroup, currentIndex) => {
      previousValue += currentValue.controls['item_price'].value*currentValue.controls['item_quantity'].value;
      return previousValue;
    }),0);
  }
  ngOnDestroy(){
    Promise.resolve(null).then(() => {
      this.parentForm.removeControl(this.key);
    });
  }

}
