import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RoleService} from "../role.service";
import { HttpResponse } from "@angular/common/http";
import {Role} from "../../models/types";

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss']
})
export class RoleSelectorComponent implements OnInit {
  roles = [];
  users = [];
  selectedUsers = []
  constructor(
    private matDialogRef: MatDialogRef<RoleSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public roleService: RoleService
  ) {

  }

  ngOnInit() {
    this.roleService.simple_index().forEach((response:HttpResponse<Role[]>) => {
      this.roles = response.body;
      if (this.data) {
        this.selectedUsers = this.data;
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  applyRoles() {
    this.matDialogRef.close(this.selectedUsers);
  }
  setRole(role) {
    this.roleService.getUsers(role.id).toPromise().then((response: HttpResponse<any[]>)=>{
      this.users = response.body;
    }).catch((error)=>{
      console.log(error);
    });
  }
  userSelected(user){
    const index = this.selectedUsers.findIndex((r) => r.id === user.id);
    return index >= 0;
  }
  setUser(user) {
    const index = this.selectedUsers.findIndex((r) => r.id === user.id);
    if(index >= 0){
      this.selectedUsers.splice(index,1);
    }else {
      this.selectedUsers.push(user);
    }
  }
  closeDialog() {
    this.matDialogRef.close();
  }
}
