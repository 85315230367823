<div class="wrapper" #wrapper>
  <div class="success" *ngIf="error">
    <i class="fa fa-times-circle fa-4x" style="margin-bottom: 10px; color: red"></i>
    <span>¡Esta órden no existe!</span>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="order" *ngIf="order">
        <div class="header">
          <div class="date">
            <span class="hide-print">{{order.created_at | date:'shortDate'}}</span>
            <div class="expander">
              <div class="only-print" style="font-weight: bold; align-self: center">
                ORDEN DE TRABAJO #{{order.correlative_id}}
              </div>

            </div>
            <span class="right">Actualización: {{order.updated_at | date:'shortDate'}}</span>
          </div>
          <div class="title">
            <div class="logo">
              <img [src]="company_logo">
            </div>
            <div class="hide-print">
              ORDEN DE TRABAJO #{{order.correlative_id}}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="recall" *ngIf="order.recall_data && order.recall_data.length > 0">
            <div class="icon">
              <i class="fa fa-bullhorn fa-4x"></i>
            </div>
            <div class="data">
              <span>Este vehiculo tiene las siguientes alarmas de seguridad</span>
              <ul>
                <li *ngFor="let data of order.recall_data">
                  <i class="fa fa-dot-circle"></i> {{data.nom_campana}}: {{data.den_campana}}
                </li>
              </ul>
            </div>
          </div>
          <div class="subtitle">Recepcionista</div>
          <div class="info last">
            <div class="name">Nombre</div>
            <div class="value">{{order.user?.name}}</div>
          </div>
          <div class="subtitle">Cliente #{{order.client_id}}</div>
          <div class="info">
            <div class="name">Nombre</div>
            <div class="value">{{order.client?.name || 'No existe el cliente'}}</div>
          </div>
          <div class="info">
            <div class="name">{{country.preferences.client_id}}</div>
            <div class="value">{{order.client?.rut}}</div>
          </div>
          <div class="info">
            <div class="name">Dirección</div>
            <div class="value">{{order.client?.address}}, {{order.client?.city}}</div>
          </div>
          <div class="info">
            <div class="name">E-mail</div>
            <div class="value">{{order.client?.email}}</div>
          </div>
          <div class="info last">
            <div class="name">Teléfono</div>
            <div class="value"><a href="tel:{{order.client?.phone}}" class="phone"><i class="fa fa-phone"></i>{{order.client?.phone}}</a></div>
          </div>
          <div class="subtitle">Vehículo #{{order.vehicle_id}}</div>
          <div class="info">
            <div class="name">Patente</div>
            <div class="value">{{order.vehicle.identifier}}</div>
          </div>
          <div class="info">
            <div class="name">Marca</div>
            <div class="value">{{order.vehicle?.brand?.name}}</div>
          </div>
          <div class="info">
            <div class="name">Modelo</div>
            <div class="value">{{order.vehicle?.brand_model?.name}}</div>
          </div>
          <div class="info">
            <div class="name">Chasis</div>
            <div class="value">{{order.vehicle?.chasis || '-'}}</div>
          </div>
          <div class="info" *ngIf="order.kms >= 0">
            <div class="name">Kilometraje</div>
            <div class="value">{{order.kms || order.vehicle?.kms}}</div>
          </div>
          <div class="info last">
            <div class="name">Color</div>
            <div class="value">{{order.vehicle.color}}</div>
          </div>
          <div class="subtitle">General</div>
          <div class="info">
            <div class="name">Requerimiento</div>
            <div class="value">{{order.requirement?.name}}</div>
          </div>
          <div class="info last">
            <div class="name">Comentario</div>
            <div class="value">{{order.comment}}</div>
          </div>
          <app-order-fields readonly="true" [model]="order" clientMode="true"></app-order-fields>
          <div class="subtitle">Servicios Adicionales</div>
          <div class="info last">
            <app-additionals-form initialValue="order_aditionals" readonly="true"></app-additionals-form>
          </div>
          <ng-template [ngIf]="order.order_items && order.order_items.length>0">
            <div class="subtitle">Productos Adicionales</div>
            <div class="info">
              <div class="quantity"></div>
              <div class="value">Valor Base</div>
              <div class="name right">{{order.total | money }}</div>
            </div>
            <div class="info" *ngFor="let item of order.order_items">
              <div class="quantity right">{{item.item_quantity || 1}}</div>
              <div class="value">{{item.item_name}} | {{item.item_description}}</div>
              <div class="name right">{{item.item_price*item.item_quantity | money }}</div>
            </div>
            <div class="info last">
              <div class="quantity"></div>
              <div class="value">Total</div>
              <div class="name right total" style="text-align: right">{{getTotal() | money }}</div>
            </div>
          </ng-template>
        </div>
        <div class="legend">
          {{company.preferences.order_legend}}
          <ng-container *ngIf="!company.preferences.order_legend">
            Autorizo todas las reparaciones aquí detalladas, el uso, traslado e instalación de piezas que se requiera para ello.

            {{company.name}} queda autorizado para efectuar las pruebas que sean necesarias fuera del servicio, tanto en calles como carreteras.

            {{company.name}} realizará reparaciones de acuerdo a lo autorizado por la compañía de seguros. Toda reparación no efectuada deberá atenderse de forma externa entre el asegurado y respectivo liquidador.{{company.name}} no se responsabiliza por objetos no declarados al momento de la recepción del vehículo.

            {{company.name}} por todo vehículo que no sea retirado dentro de 48 horas de finalizada la reparación, cobrará el respectivo almacenaje.
          </ng-container>
        </div>
        <div class="signature">
          <div class="placeholder" [style.background-image]="sanitizeBackground(order.sign_url)">
            <div class="sign"></div>
            <span>{{order.client?.name || 'Firma Cliente'}}</span>
          </div>
        </div>
        <div class="buttons">
          <button mat-raised-button color="primary" (click)="print()" class="hide-mobile"><i class="fa fa-print"></i></button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
