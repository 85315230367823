import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {BrandsService} from "../../services/brands.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-admin-brands',
  templateUrl: './admin-brands.component.html',
  styleUrls: ['./admin-brands.component.scss','../admin-main.common.scss']
})
export class AdminBrandsComponent implements OnInit {
  form = this.fb.group({name: ''})
  @Input() inView = false;
  selected:any;
  constructor(
    public brandsService: BrandsService,
    public fb: UntypedFormBuilder,
    public router: Router
  ) { }

  ngOnInit() {
  }
  select(item){
    this.selected = item;
    if(!this.inView){
      this.router.navigate(['admin','main','brands',this.selected.id,'models']);
    }
  }

}
