import { NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseModule } from 'app/shared/base.module';
import { DialogsModule } from 'app/shared/dialogs/dialogs.module';

import { WorksAddonsModule } from '../shared/works/works.addons.module';
import { InventoryActionDetailsComponent } from './actions/details/inventory-action-details.component';
import { InventoryActionFormComponent } from './actions/form/inventory-action-form.component';
import { InventoryActionsComponent } from './actions/inventory-actions.component';
import { InventoryComponent } from './inventory.component';
import { InventoryProductDetailsComponent } from './products/details/inventory-product-details.component';
import { InventoryProductsComponent } from './products/inventory-products.component';

@NgModule({
  declarations: [
    InventoryActionDetailsComponent,
    InventoryActionFormComponent,
    InventoryActionsComponent,
    InventoryComponent,
    InventoryProductDetailsComponent,
    InventoryProductsComponent,
  ],
  imports: [
    BaseModule,
    DialogsModule,
    WorksAddonsModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class InventoryModule {}
