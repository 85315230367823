import { Injectable } from '@angular/core';
import { BaseService } from "./_base.service";
import { UserService } from "./user.service";
import { BehaviorSubject, map } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Status } from "../models/status.model";

@Injectable()
export class StatusesService extends BaseService {

  public allStatuses$: BehaviorSubject<Status[]> = new BehaviorSubject([]);

  constructor(
    http: HttpClient,
    userService: UserService,
  ) {
    super(http, userService);
    this.all().toPromise().then((value: HttpResponse<Status[]>) => {
      this.allStatuses$.next(value.body);
    }).catch((error) => {
      console.log(error);
    });
  }

  all(type?: string) {
    return this.http.get(this.apiURL + '/statuses', this.getParams({ type }));
  }

  statuses(type?: string) {
    if (!type) {
      return this.allStatuses$.asObservable();
    }

    return this.allStatuses$.asObservable().pipe(
      map((statuses) => {
        return statuses.filter((status: Status) => status.type === type);
      })
    );
  }
}
