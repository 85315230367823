import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { FieldsService } from 'app/admin/services/fields.service';
import { Requirement } from "app/models/requirement.model";
import { RequirementsService } from 'app/services/requirements.service';
import { WorkService } from "app/work/work.service";
import { WorkRequirementAvgService } from "app/admin/services/work-requirement-avg.service";

@Component({
  selector: 'app-work-time-form',
  templateUrl: './work-time-form.component.html',
  styleUrls: ['./work-time-form.component.scss', '../common-forms.common.scss']
})
export class WorkTimeFormComponent implements OnInit {

  wraForm =new UntypedFormGroup({
    requirement_id: new UntypedFormControl('', Validators.required),
    work_id: new UntypedFormControl('', Validators.required),
    time: new UntypedFormControl({disabled: true, value: 0}),
    std_dev: new UntypedFormControl({disabled: true, value: 0}),
    base_time : new UntypedFormControl(0),
    base_std_dev : new UntypedFormControl(0),
  });

  requirements: Partial<Requirement>[] = [];

  allWorks: any[];

  constructor(
    private workService: WorkService,
    public fieldsService: FieldsService,
    public workRequirementAvgService: WorkRequirementAvgService,
    private requirementService: RequirementsService,
  ) { }

  ngOnInit() {
    this.workService.all().toPromise().then((value: HttpResponse<any[]>)=>{
      this.allWorks = value.body;
    });
    this.requirementService.all().toPromise().then((value: HttpResponse<any>)=>{
      this.requirements = value.body;
      this.requirements.unshift({id: 0, name: 'Todos'});
    });
  }
}
