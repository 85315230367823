import {NgModule} from "@angular/core";
import {BaseModule} from "../base.module";
import {OrderCommentsComponent} from "./order-comments.component";
import {RoleModule} from "../../role/role.module";
import {ProductionCalendarModule} from "../../calendar/production-calendar.module";

@NgModule({
  declarations: [
    OrderCommentsComponent
  ],
  imports: [
    BaseModule,
    RoleModule,
  ],
  exports: [
    OrderCommentsComponent
  ]
})
export class CommentsAddonsModule {}
