import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedItems } from 'app/models/types';
import { Warehouse } from 'app/models/warehouse.model';
import { BaseService } from 'app/services/_base.service';
import { UserService } from 'app/services/user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WarehousesService extends BaseService {
  params = ['name'];

  model = 'Warehouse';

  warehouses: BehaviorSubject<Warehouse[]> = new BehaviorSubject([]);

  warehouses$ = this.warehouses.asObservable();

  constructor(
    public http: HttpClient,
    userService: UserService,
  ) {
    super(http, userService);
    this.all().subscribe((warehouses) => this.warehouses.next(warehouses.body));
  }

  index(value = null, page = 1) {
    return this.http.get<PaginatedItems<Warehouse>>(
      `${this.adminURL}/warehouses`,
      this.getParams({ search: value, page }),
    );
  }

  all() {
    return this.http.get<Warehouse[]>(`${this.adminURL}/warehouses/all`, this.getParams());
  }

  create(value: Partial<Warehouse>) {
    return this.http.post<Warehouse>(`${this.adminURL}/warehouses/`, {
      warehouse: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id: number, value: Warehouse) {
    return this.http.put<Warehouse>(
      `${this.adminURL}/warehouses/${id}`,
      { warehouse: this.permitParams(value, ...this.params) },
      this.getParams(),
    );
  }

  destroy(id: number) {
    return this.http.delete(`${this.adminURL}/warehouses/${id}`, this.getParams());
  }

  restore(id: number) {
    return this.http.delete(`${this.adminURL}/warehouses/${id}/restore`, this.getParams());
  }

  swap(ids: number[]) {
    return this.http.post(`${this.adminURL}/warehouses/swap`, { ids }, this.getParams());
  }
}
