<div class="images" [class.small]="small" *ngIf="photos">
  <div class="image" (click)="addPhotos()" [hidden]="readonly || !allowUploadPhoto" matTooltip="Desde Cámara" *ngIf="photos.length + uploadingFiles.length < MAX_IMAGES">
    <div class="add-image"><i class="fa fa-camera"></i></div>
  </div>
  <div class="image" (click)="uploadPhotos(file)" [hidden]="readonly || !allowUploadFile" matTooltip="Desde Archivo" *ngIf="photos.length + uploadingFiles.length < MAX_IMAGES">
    <input type="file" style="display: none" name="image" #file (change)="uploadFile($event)" multiple="true">
    <div class="add-image"><i class="fa fa-upload" ></i></div>
  </div>
  <div class="image" (click)="showAdvanced = true" [hidden]="readonly || !allowAdvanceUpload" matTooltip="Avanzado" *ngIf="photos.length + uploadingFiles.length < MAX_IMAGES">
    <div class="add-image"><i class="fa fa-marker" ></i></div>
  </div>
  <div class="image" (click)="fromGallery()" [hidden]="readonly || !gallery || !gallery.length || gallery.length == 0" matTooltip="Desde Galería" *ngIf="photos.length + uploadingFiles.length < MAX_IMAGES">
    <div class="add-image"><i class="fa fa-image" ></i></div>
  </div>
  <div class="image placeholder" *ngFor="let image of placeholders;  let index = index" [matTooltip]="image.name" [class.warn]="image.warn" (click)="handlePlaceholderClick(image,singlefile)" [style.background-image]="getThumb(image)" [class.empty-placeholder]="!image.url" [attr.data-index]="index">
    <input type="file" style="display: none" name="image" #singlefile (change)="uploadFile($event,image)">
    <div class="add-image">
      <span *ngIf="!image.url && !image.progress">+</span>
      <span class="progress" *ngIf="image.progress && !image.uploaded">{{image.progress}}%</span>
    </div>
    <div class="exit" (click)="removePlaceholderImage(image)" [hidden]="readonly" *ngIf="image.url"><i class="fa fa-times"></i></div>
  </div>
  <div class="image" *ngFor="let image of uploadingFiles" [hidden]="image.uploaded || placeholders.length !== 0">
    <div class="add-image"><span class="progress">{{image.progress}}%</span></div>
  </div>
  <div class="image" [class.draggable]="placeholders.length > 0" [draggable]="placeholders.length > 0" *ngFor="let image of photos | photos:'url':false:placeholders; let index = index" [style.background-image]="getThumb(image)" (click)="openAdvanced(image)" [hidden]="image._destroy" [matTooltip]="image.created_at | date:'short'" [attr.data-id]="image.url">
    <div class="video" *ngIf="image.video"><i class="fa fa-video"></i></div>
    <div class="exit" (click)="removeImage(image)" [hidden]="readonly"><i class="fa fa-times"></i></div>
  </div>
  <div class="image" [matTooltip]="image.name" *ngFor="let image of photos | photos:'url':true:placeholders" [hidden]="image._destroy" [style.background-image]="'url(assets/images/open-file.png)'" (click)="openFile(image.url)">
    <div class="exit" (click)="removeImage(image)" [hidden]="readonly"><i class="fa fa-times"></i></div>
  </div>
</div>
<app-image-zoom [image]="currentImage" [photos]="photos" (closed)="currentImage = null"></app-image-zoom>
<app-advanced-image-uploader [photos]="photos" [selectedImage]="selectedImage" *ngIf="showAdvanced" (close)="closeAdvanced($event)" [actions]="{
    edit_markers: !readonly && allowAdvanceUpload,
    edit_photos: !readonly && allowAdvanceUpload
  }" [readonly]="readonly || !allowAdvanceUpload"></app-advanced-image-uploader>

