<ng-container *ngIf="categories">
  <fieldset class="wrapper">

    @if (showCategories) {

      <!--expandedView-->
      @if (!simpleView) {
        <div class="categories">

          @if (parentFormGroup.get('comment')) {
            <div class="category extended-comment">

              <div>
                <mat-form-field class="comment-field" subscriptSizing="dynamic" appearance="outline">
                  <mat-label>{{adminService.getText('comment','capitalize')}}</mat-label>
                  <textarea
                    matInput
                    [formControl]="parentFormGroup.controls.comment"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5"
                    (keydown)="handleInput($event)"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          }

          <div class="category-buttons" *ngIf="!isLocked && !isParentLocked">
            @for (category of categories; track category.id) {
              @if (category.name !== 'Adicionales' && canUpdate) {
                <button mat-button (click)="onAddItem(category.id)" *ngIf="getCategoryFormArray(category.id)?.controls?.length === 0">
                  <i class="fa fa-plus"></i>&nbsp; {{ category.name }}
                </button>
              }
            }
          </div>

          <ng-container *ngFor="let category of categories; let last = last; trackBy: trackByCategoryId">
            <div *ngIf="getCategoryFormArray(category.id)?.controls?.length > 0" class="category" [formGroup]="form" [ngClass]="{'last': last}">

              <div class="category-subtitle">
                <span>{{ category.name }}</span>
              </div>

              <div class="category-content" [formArrayName]="category.id.toString()" *ngFor="let product of getCategoryFormArray(category.id).controls; let i = index" [hidden]="product.get('_destroy')?.value" >
                <div [formGroupName]="i">

                  <div class="row header">
                    <span>
                      @if (product.get('kit_name')?.value) {
                        <i class="fa fa-toolbox iconTooltip iconTooltip__info" matTooltip="Este producto es parte de {{ product.get('kit_name').value }}"></i>
                      }
                      {{ product.get('description').value }}
                      @if (product.get('kit_product_quantity')?.value && product.get('quantity')?.value !== product.get('kit_product_quantity').value) {
                        <i class="fas fa-exclamation-triangle iconTooltip iconTooltip__warning quantityTooltip" matTooltip="Ha introducido una cantidad distinta a la que trae el kit ({{product.get('kit_product_quantity').value}})"></i>
                      }
                    </span>
                  </div>

                  <div class="row subtitle">
                    <span>
                      <input
                          currencyMask
                          class="amount-input"
                          (keydown)="blockNegativeInput($event)"
                          onwheel="event.preventDefault()"
                          formControlName="amount"
                                >
                       x
                       {{ product.get('unit')?.value }}</span>
                  </div>
                  @if (((!isLocked && !isParentLocked) || ((isLocked || isParentLocked) && product.get('discount_percent')?.value !== 0)) && canUpdate) {
                    <div class="row">
                      <div class="discount-tag" [ngClass]="{'discounted': product.get('discount_percent')?.value > 0}">
                        -
                        <input matInput type="number"
                          class="input-discount"
                          [min]="0"
                          [max]="100"
                          formControlName="discount_percent"
                          (keydown)="blockNegativeInput($event)"
                          >
                          <span class="percent-span">%</span>
                      </div>
                    </div>
                  }

                  <div class="row" style="margin-top: 10px;">

                    <i class="fas fa-sync-alt replaceicon"
                    *ngIf="!isLocked && !isParentLocked && canUpdate"
                    (click)="onReplaceItem(category.id, i)"
                    matTooltip="Reemplazar"
                    ></i>
                    <input
                      class="input-style"
                      type="text"
                      formControlName="code"
                      placeholder="Código"
                      autocomplete="off"
                      (click)="!isLocked && !isParentLocked && onReplaceItem(category.id, i)"
                      required
                      style="width: 100px;"
                      readonly
                    >

                  </div>

                  <div class="row">
                    <div class="expander"></div>
                    <span>Total: {{ (product.get('amount')?.value || 0) * (product.get('quantity')?.value || 0) * (1 - (product.get('discount_percent')?.value || 0) / 100) | money }}</span>

                  </div>

                  <div class="quantity">
                    <div class="quantity-display">
                      <button mat-icon-button (click)="product.get('quantity')?.value > 1 ? updateQuantity(category.id, i, -1) : onRemoveItem(category.id, i)" [disabled]="isLocked || isParentLocked  || !canUpdate">
                        <mat-icon *ngIf="product.get('quantity')?.value > 1">remove</mat-icon>
                        <mat-icon *ngIf="product.get('quantity')?.value <= 1">delete</mat-icon>
                      </button>

                      <input type="number"
                      formControlName="quantity"
                      (blur)="onQuantityInputBlur(category.id, i)"
                      step="1"
                      min="0"
                      class="quantity-input"
                      [disabled]="isLocked || isParentLocked  || !canUpdate"
                      />

                      <button mat-icon-button (click)="updateQuantity(category.id, i, 1)" [disabled]="isLocked || isParentLocked || !canUpdate">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                </div>
              </div>

              <div class="category-buttons" *ngIf="!isLocked && !isParentLocked && canUpdate">
                  @if (category.name !== 'Adicionales' && (!simpleView || getCategoryFormArray(category.id)?.controls?.length === 0)) {
                    <button class="bottom-button" mat-button (click)="onAddItem(category.id)">
                      <i class="fa fa-plus"></i>&nbsp; {{ category.name }}
                    </button>
                  }
              </div>

            </div>
          </ng-container>
          @if (canUpdate && parentDocumentOnApproval && !isLocked) {
            <div class="approvalButtons">
                  <button
                    mat-button
                    class="approvalButton"
                    (click)="approveInventoryAction()">
                      <mat-icon>check</mat-icon>
                      <span>Aprobar</span>
                  </button>
                  <button
                    mat-button
                    class="rejectButton"
                    (click)="declineInventoryAction()">
                      <mat-icon>close</mat-icon>
                      <span>Rechazar</span>
                  </button>
            </div>
          }
          @if ( isDeclined || isApproved) {
            <div class="approvalButtons"></div>
            <button
              mat-button
              [ngClass]="{
                'approvalButton': isApproved,
                'rejectButton': isDeclined
                }"
              disabled>
              <mat-icon>
                {{ isApproved ? 'check' : isDeclined ? 'do_not_disturb_on' : 'schedule' }}
              </mat-icon>
              <span>
                {{ isApproved ? 'Aprobado' : 'Rechazado'  }}
              </span>
            </button>

          }

      </div>

      }
      @else {
        <div class="simple-view">

          <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)" #expansionPanel disabled >
            <mat-expansion-panel-header>

              <div class="header-card">

                <div class="main-content">

                    @if (parentFormGroup.get('comment')) {
                      <div class="category inventory-comment-field">
                        <mat-form-field subscriptSizing="dynamic">
                          <mat-label>{{adminService.getText('comment','capitalize')}}</mat-label>
                          <textarea
                            matInput
                            [formControl]="parentFormGroup.controls.comment"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="3"
                            (keydown)="handleInput($event)"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    }
                    @if (!hasMainCategory){
                      <div class="category sub display-desktop"></div>
                    }
                    @for (category of categories; track category.id) {

                      @if (category.name !== 'Adicionales' && canUpdate && (getCategoryFormArray(category.id).controls.length === 0 && !isLocked && !isParentLocked)) {
                        <div class="category sub display-desktop">
                          <div class="category-buttons">
                            <button mat-button (click)="onAddItem(category.id)">
                              <i class="fa fa-plus"></i>&nbsp; {{ category.name }}&nbsp;
                            </button>
                          </div>
                        </div>

                      }

                      <div class="category sub display-desktop" *ngFor="let product of getCategoryFormArray(category.id).controls; let i = index" >

                        @if (getCategoryFormArray(category.id).controls.length > 0) {
                          @if(category.id === mainCategory?.id){
                            <div class="description">
                              @if (product.get('kit_name')?.value) {
                                <i class="fa fa-toolbox iconTooltip iconTooltip__info" matTooltip="Este producto es parte de {{ product.get('kit_name').value }}"></i>
                              }
                              {{ product.get('description').value | titlecase }}
                              @if (product.get('kit_product_quantity')?.value && product.get('quantity')?.value !== product.get('kit_product_quantity').value) {
                                <i class="fas fa-exclamation-triangle iconTooltip iconTooltip__warning quantityTooltip" matTooltip="Ha introducido una cantidad distinta a la que trae el kit ({{product.get('kit_product_quantity').value}})"></i>
                              }
                            </div>
                            <div class="row">
                              <i class="fas fa-sync-alt replaceicon"
                              *ngIf="!isLocked && !isParentLocked && canUpdate"
                              (click)="onReplaceItem(category.id, i)"
                              matTooltip="Reemplazar"
                              ></i>
                              <input
                                class="input-style"
                                type="text"
                                [formControl]="getCategoryFormArray(category.id).controls[0].get('code')"
                                placeholder="Código"
                                autocomplete="off"
                                (click)="!isLocked && !isParentLocked && onReplaceItem(category.id, i)"
                                required
                                readonly
                              >
                            </div>

                            <!--
                            <div class="row status">
                              @if (parentDocumentOnApproval || isDeclined || isApproved) {
                                <span
                                [ngClass]="{
                                'approved': isApproved,
                                'declined': isDeclined,
                                'on-approval': !isApproved && !isDeclined
                                }">
                                  {{ isApproved ? 'Aprobado' : isDeclined ? 'Rechazado' : 'En aprobación' }}
                                </span>

                              }
                            </div>
                            -->
                          }
                          @else {
                            <div class="description sub">
                              <span>{{category.name}}</span>
                            </div>
                          }
                        }
                          <div class="expander"></div>
                            <div class="row" [ngClass]="category.id !== mainCategory.id ? 'sub' : ''">

                              <span>
                                <input
                                  currencyMask
                                  class="amount-input"
                                  (keydown)="blockNegativeInput($event)"
                                  onwheel="event.preventDefault()"
                                  [formControl]="getCategoryFormArray(category.id).controls[0].get('amount')">
                                x
                                <input type="number"
                                  [formControl]="getCategoryFormArray(category.id).controls[0].get('quantity')"
                                  (blur)="onQuantityInputBlur(category.id, i)"
                                  step="1"
                                  min="0"
                                  class="header-quantity-input"
                                  onwheel="event.preventDefault()" />

                              </span>



                            </div>
                      </div>
                    }

                  </div>



                  @if( !(photos?.length == 0 && (isLocked || isParentLocked))){
                    <div class="images-container-inventory display-mobile">

                          <app-image-uploader
                          [gallery]="gallery || []"
                          [photos]="photos || []"
                          (uploaded)="onImagesUploaded($event)"
                          [scrollTarget]="wrapper"
                          [small]="true"
                          [readonly]="isReadOnly || isLocked || isParentLocked"
                          [allowUploadPhoto]="true  "
                          [allowUploadFile]="true"
                          [allowAdvanceUpload]="false"
                        >
                        </app-image-uploader>

                    </div>
                  }

                  <div class="approve-buttons display-desktop">

                    @if (canUpdate && parentDocumentOnApproval && !isLocked) {
                        @if(!isDeclined) {
                          <button
                            mat-button
                            class="approvalButton"
                            [disabled]="!canUpdate"
                            (click)="approveInventoryAction()">
                              <mat-icon>check</mat-icon>
                              <span>{{ isApproved ? 'Aprobado' : 'Aprobar' }}</span>
                          </button>
                        }

                        @if(!isApproved) {
                          <button
                            mat-button
                            class="rejectButton"
                            [disabled]="!canUpdate"
                            (click)="declineInventoryAction()">
                              <mat-icon>close</mat-icon>
                              <span>{{ isDeclined ? 'Rechazado' : 'Rechazar' }}</span>
                          </button>
                        }
                    }
                  </div>
                  <div class="show-container display-desktop" >
                    <div class="show-total">{{ (getTotal()) | money }}</div>
                    <button mat-button (click)="togglePanel()" class="show-button" [disabled]="!hasProducts()">
                      <div class="show-content">
                        <!--
                        <span>{{panelOpenState() ? 'Ocultar' : 'Mostrar'}} </span>
                        -->
                        <span>Detalles</span>
                        <mat-icon>{{ panelOpenState() ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>

                      </div>
                    </button>


                  </div>


              </div>

            </mat-expansion-panel-header>

            <div class="categories">

              @if( !(photos?.length == 0 && (isLocked || isParentLocked))){
                  <div class="images-subtitle">
                    Imagenes
                  </div>
                  <app-image-uploader
                  [gallery]="gallery || []"
                  [photos]="photos || []"
                  (uploaded)="onImagesUploaded($event)"
                  [scrollTarget]="wrapper"
                  [small]="true"
                  [readonly]="isReadOnly || isLocked || isParentLocked"
                  [allowUploadPhoto]="true  "
                  [allowUploadFile]="true"
                  [allowAdvanceUpload]="false"
                >
                </app-image-uploader>
              }


              <ng-container *ngFor="let category of categories; let first = first; let last = last; trackBy: trackByCategoryId">
                <div *ngIf="getCategoryFormArray(category.id).controls.length > 0" class="category" [formGroup]="form" [ngClass]="{'first': first, 'last': last}">


                  <div class="category-subtitle">
                    <span>{{ category.name }}</span>&nbsp;
                    @if (simpleView && category.id === mainCategory?.id) {
                      <mat-chip class="chip">Principal</mat-chip>
                    }
                  </div>

                  <div class="category-content" [formArrayName]="category.id.toString()" *ngFor="let product of getCategoryFormArray(category.id).controls; let i = index" [hidden]="product.get('_destroy')?.value" >
                    <div [formGroupName]="i">

                      <div class="row header">
                        <span>
                          @if (product.get('kit_name')?.value) {
                            <i class="fa fa-toolbox iconTooltip iconTooltip__info" matTooltip="Este producto es parte de {{ product.get('kit_name')?.value }}"></i>
                          }
                          {{ product.get('description')?.value }}
                          @if (product.get('kit_product_quantity')?.value && product.get('quantity')?.value !== product.get('kit_product_quantity')?.value) {
                            <i class="fas fa-exclamation-triangle iconTooltip iconTooltip__warning quantityTooltip" matTooltip="Ha introducido una cantidad distinta a la que trae el kit ({{product.get('kit_product_quantity')?.value}})"></i>
                          }
                        </span>
                      </div>

                      <div class="row subtitle">
                        <span>
                          <input
                            currencyMask
                            formControlName="amount"
                            class="amount-input"
                            (keydown)="blockNegativeInput($event)"
                            onwheel="event.preventDefault()"
                          >
                          x
                          {{ product.get('unit')?.value }}</span>
                      </div>
                      @if (((!isLocked && !isParentLocked) || (product.get('discount_percent')?.value !== 0))) {
                        <div class="row">
                          <div class="discount-tag" [ngClass]="{'discounted': product.get('discount_percent')?.value > 0}">
                            -
                            <input matInput type="number"
                              class="input-discount"
                              [min]="0"
                              [max]="100"
                              formControlName="discount_percent"
                              [readonly]="isLocked || isParentLocked || !canUpdate"
                              (keydown)="blockNegativeInput($event)"
                              onwheel="event.preventDefault()"
                              >
                              <span class="percent-span">%</span>
                          </div>
                        </div>
                      }

                      <div class="row" style="margin-top: 10px;">
                        <i class="fas fa-sync-alt replaceicon"
                        *ngIf="!isLocked && !isParentLocked && canUpdate"
                        (click)="onReplaceItem(category.id, i)"
                        matTooltip="Reemplazar"
                        ></i>
                        <input
                          class="input-style"
                          type="text"
                          formControlName="code"
                          placeholder="Código"
                          autocomplete="off"
                          (click)="!isLocked && !isParentLocked && onReplaceItem(category.id, i)"
                          required
                          readonly
                        >
                      </div>
                      <div class="row">
                        <div class="expander"></div>
                        <span>Total: {{ (product.get('amount')?.value || 0) * (product.get('quantity')?.value || 0) * (1 - (product.get('discount_percent')?.value || 0) / 100) | money }}</span>
                      </div>

                      <div class="quantity">
                        <div class="quantity-display">
                          <button mat-icon-button (click)="product.get('quantity')?.value > 1 ? updateQuantity(category.id, i, -1) : onRemoveItem(category.id, i)" [disabled]="isLocked || isParentLocked || !canUpdate">
                            <mat-icon *ngIf="product.get('quantity').value > 1">remove</mat-icon>
                            <mat-icon *ngIf="product.get('quantity').value <= 1">delete</mat-icon>
                          </button>

                          <input type="number"
                          formControlName="quantity"
                          (blur)="onQuantityInputBlur(category.id, i)"
                          step="1"
                          min="0"
                          class="quantity-input"
                          onwheel="event.preventDefault()" />

                          <button mat-icon-button (click)="updateQuantity(category.id, i, 1)" [disabled]="isLocked || isParentLocked || !canUpdate">
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="category-buttons" *ngIf="!isLocked && !isParentLocked && canUpdate">
                      @if (category.name !== 'Adicionales' && (!simpleView || getCategoryFormArray(category.id).controls.length === 0)) {
                        <button mat-button (click)="onAddItem(category.id)">
                          <i class="fa fa-plus"></i>&nbsp; {{ category.name }}
                        </button>
                      }
                  </div>

                </div>
              </ng-container>

            </div>

          </mat-expansion-panel>

          @if (parentDocumentOnApproval || isDeclined || isApproved) {

            <button
              mat-mini-fab
              class="status"

              [ngClass]="{
                'approved': isApproved,
                'declined': isDeclined
                }"
              [matTooltip]="isApproved ? 'Aprobado' : isDeclined ? 'Rechazado' : 'En aprobación'">
              <mat-icon>
                {{ isApproved ? 'check' : isDeclined ? 'do_not_disturb_on' : 'schedule' }}
              </mat-icon>
            </button>

          }
        </div>
      }

    }
    @if (categories && showTotals) {
      <div class="totals">
        @for (category of categories; track category.id) {
          <div class="total">
            <span class="total__label">Total {{ category.name }}</span>
            <span class="total__value">{{ getCategoryTotal(category.id) | money }}</span>
          </div>
        }

        <div class="total" [ngClass]="{'discount': discountTotal > 0}">
          <span class="total__label">Neto</span>
          <span class="total__value">{{ getTotal() + discountTotal| money }}</span>
        </div>
        <div class="total" *ngIf="discountTotal > 0">
          <span class="total__label">Descuentos</span>
          <span class="total__value">-{{ discountTotal | money }}</span>
        </div>
        <div class="total"  *ngIf="discountTotal > 0">
          <span class="total__label">Neto</span>
          <span class="total__value discount__label">{{ (getTotal()) | money }} </span>
        </div>

        <div class="total">
          <span class="total__label">IVA (19%)</span>
          <span class="total__value">{{ getTotal() * 0.19 | money }}</span>
        </div>

        <br>

        <div class="total">
          <span class="total__label">Total General</span>
          <span class="total__value total__label">{{ getTotal() * 1.19| money }} </span>
        </div>

      </div>

    }

    @if(categories && clientBookingMode){
      <div class="totals">
        <div class="total header">
          <span class="total__label">Incluye:</span>
        </div>
        @for (category of categories; track category.id) {
          @if (getCategoryTotal(category.id) > 0) {
            @for (product of getCategoryFormArray(category.id).controls; track product.get('product_id').value) {
              @if (product.get('_destroy').value === false) {
                <div class="total">
                  <span class="sub">•{{ product.get('description').value }}</span>
                </div>
              }
            }
          }
        }

        <br>

        <div class="total">
          <div><span class="total__label">Total Referencial</span>&nbsp;<span class="sub">(incluye IVA)</span></div>
          <span class="total__value " [ngClass]="{'discount__label': getTotal() > 0}">{{ getTotal() * 1.19| money }} </span>
        </div>

      </div>
    }
  </fieldset>
</ng-container>
