import { HttpResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { StatusRequirementsService } from 'app/admin/services/status-requirements.service';
import { StatusTransitionsService } from 'app/admin/services/status-transitions.service';
import { StatusesService } from 'app/admin/services/statuses.service';
import { Status } from 'app/models/status.model';
import { Requirement } from 'app/models/types';
import { RequirementsService } from 'app/services/requirements.service';
import { Observable, tap } from 'rxjs';

import {
  budgetActions, getStatusesTypesFor, getTransitionModelFor,
} from './status-form.config';

interface StatusType {
  value: string;
  viewValue: string;
  tooltipMessage: string | null;
}

@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss', '../common-forms.common.scss'],
})
export class StatusFormComponent implements OnInit {
  @Input() title: string = '';

  @Input() type: string = '';

  @Input() showActions: boolean = false;

  @Input() showNotifyClient: boolean = false;

  @Input() showStatuses: boolean = false;

  @Input() showStatusTransitionRequirement: boolean = false;

  @Input() showTransitions: boolean = false;

  @Input() showTransitionRequirement: boolean = false;

  statusForm = this.fb.group({
    color: '',
    name: '',
    notify_client: false,
    order: 0,
    status_type: new FormControl([], Validators.required),
    budget_actions: [],
  });

  statusTransitionForm = this.fb.group({
    from_status_id: new FormControl(null, Validators.required),
    to_status_id: new FormControl(null, Validators.required),
    requirement_id: null,
    requirement_ids: [],
  });

  requirementTransitionForm = this.fb.group({
    status_id: new FormControl(null, Validators.required),
    requirement_status_id: new FormControl(null, Validators.required),
  });

  requirements: Requirement[] = [];

  budgetActions = budgetActions;

  statuses: Observable<Status[]>;

  statusTypes: StatusType[];

  statusTransitionModel: string;

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private requirementsService: RequirementsService,
    public statusesService: StatusesService,
    public statusRequirementsService: StatusRequirementsService,
    public statusTransitionsService: StatusTransitionsService,
  ) { }

  ngOnInit(): void {
    if (this.showTransitions) {
      this.setTransitionModel();
      this.loadStatuses();

      if (this.showTransitionRequirement) {
        this.loadRequirements();
      }
    }

    this.setStatusTypes();
  }

  sanitizeColor(color: string) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }
  getRequirementsNames(requirementIds: number[]) {
    let names = this.requirements.filter((requirement) => requirementIds.includes(requirement.id)).map((requirement) => requirement.name);
    if(names.length > 4){
      names = names.slice(0, 4);
      names.push('...');
    }
    else if (names.length === 0) {
      names.push('Principal');
    }
    return names.join(', ');
  }

  private loadStatuses() {
    this.statuses = this.statusesService.statuses(this.type);
  }

  private loadRequirements() {
    this.requirementsService
      .all()
      .pipe(
        tap((response: HttpResponse<Requirement[]>) => {
          this.requirements = response.body;
        }),
      )
      .subscribe();
  }

  private setStatusTypes() {
    this.statusTypes = getStatusesTypesFor(this.type);
  }

  private setTransitionModel() {
    this.statusTransitionModel = getTransitionModelFor(this.type);
  }
}
