import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from "../models/user";
import {UserService} from "../services/user.service";
import {UntypedFormControl, UntypedFormGroup, NgForm, Validators} from "@angular/forms";
import {flyInAnimation} from "../animations/flyIn";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RoleService} from "../role/role.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {AdminService} from "../admin/services/admin.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [flyInAnimation]
})
export class LoginComponent implements OnInit, OnDestroy {
  savedAccount = false;
  public mode= "login";
  @Input() adminMode = false;
  public loading = false;
  public registerForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    user: new UntypedFormControl(''),
    mail: new UntypedFormControl(''),
    rut: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    password_confirmation: new UntypedFormControl('')
  });
  public recoverForm = new UntypedFormGroup({
    password: new UntypedFormControl('',[Validators.minLength(6),Validators.required]),
    password_confirmation: new UntypedFormControl('',[
      Validators.minLength(6),Validators.required
    ])
  }, this.samePassword.bind(this));
  public user: User;
  subs: Subscription[] = [];
  sentRecoverMail = false;
  changedPassword = false;
  errortext = "";
  recovery_token:any;
  company:any = {};
  country;
  otp = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private companyService: AdminService
  ) { }

  ngOnInit() {
    this.company = this.companyService.local$.getValue();
    this.country = this.company.country;
    this.route.queryParams.pipe(first()).forEach((value)=>{
      if(value.recovery_token) {
        this.loading = true;
        this.recovery_token = value.recovery_token;
        this.userService.validateRecoveryToken(value.recovery_token).toPromise().then((response: HttpResponse<any>)=>{
          this.loading = false;
          this.mode = 'change-password';
        }).catch((error)=>{
          this.loading = false;
          this.router.navigate(['login']);
        });
      }
    });
    this.subs.push(this.userService.user$.subscribe((user: User) => {
      this.user = user;
      if (user){
        if(this.adminMode) {
          this.router.navigate(['admin','main']);
        }else{
          this.router.navigate(['main',this.roleService.getRootUrl(user)]);
        }
      }
    }));
    // TRY TO RELOGIN IF AUTHTOKEN ON LOCAL STORAGE
    if (this.user) {
      this.loading = true;
      this.userService.login(this.user);
    }else if(this.userService.token && this.userService.token!=="null"){
      this.loading = true;
      this.userService.reauth().toPromise().then((response:any) => {
        // SUCCESS
        if (response.status === 200) {
          this.userService.login(response.body);
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
      });
    }
  }
  login(user:User) {
    this.loading = true;
    this.errortext = '';
    this.userService.auth(user).toPromise().then((response:HttpResponse<User>) => {
      if (response.status === 200) {
        this.userService.reauth(response.body.auth_token).toPromise().then((response:HttpResponse<User>) => {
          if (response.status === 200) {
            this.userService.login(response.body);
          }
        }).catch((error) => {
          console.log(error);
        });
      }
      if(response.status === 202){
        this.otp = true;
      }
      this.loading = false;
    }).catch((error) => {
      if(error.status === 404){
        this.errortext=('No se ha podido encontrar el usuario.');
      }
      if(error.status === 401){
        this.errortext=('Contraseña incorrecta. Vuelve a intentarlo o selecciona "Olvidó contraseña" para cambiarla.');
      }
      this.loading = false;
    });
  }
  logout(){
    this.userService.logout();
    if(this.adminMode) {
      this.router.navigate(['admin','login']);
    }else{
      this.router.navigate(['login']);
    }
  }
  register(f: NgForm, user: User) {
    this.loading = true;
    this.userService.create(user).toPromise().then((response:HttpResponse<User>) => {
      if (response.status === 200) {
        this.userService.login(response.body);
      }
      this.loading = false;
      this.success(f);
    }).catch((error:HttpErrorResponse) => {
      this.loading = false;
      this.error(f, error);
    });
  }
  recover(user:User) {
    this.loading = true;
    this.userService.recover(user).toPromise().then((response:HttpResponse<User>) => {
      if (response.status === 200) {
        this.sentRecoverMail = true;
      }
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
    });
  }
  savePassword(){
    this.loading = true;
    this.userService.newPassword(this.recoverForm.value,this.recovery_token).toPromise().then((response: HttpResponse<any>)=>{
      this.changedPassword = true;
      this.loading = false;
    }).catch((error: HttpErrorResponse)=>{
      this.changedPassword = false;
      this.loading = false;
    });
  }
  success(f: NgForm) {
    this.snackBar.open("¡Guardado exitosamente!", null, { duration: 1000 });
    f.reset();
    for (const control in f.controls) {
      if (control) {
        f.controls[control].markAsPristine();
        f.controls[control].markAsUntouched();
        f.controls[control].markAsPending();
      }
    }
    this.savedAccount = true;
  }
  error(f: NgForm, error) {
    if (error.error) {
      if (error.error.rut) {
        f.controls['rut'].setErrors({'invalid': true});
        this.snackBar.open("¡OOPS!, el rut ya esta registrado.", null, { duration: 2000 });
      }else if (error.error.mail) {
        f.controls['mail'].setErrors({'invalid': true});
        this.snackBar.open("¡OOPS!, el correo ya esta registrado.", null, { duration: 2000 });
      }else if (error.error.password_confirmation) {
        f.controls['password_confirmation'].setErrors({'invalid': true});
        this.snackBar.open("¡OOPS!, Las contraseñas no coinciden.", null, { duration: 2000 });
      }
    }
  }
  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
  samePassword(g: UntypedFormGroup){
    if(g.value['password']===g.value['password_confirmation']){
      return null;
    }else{
      g.controls['password_confirmation'].setErrors({notEqual: true});
      return {
        notEqual: true
      };
    }
  }

}
