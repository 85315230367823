import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

function getThumbUrl(image: { url: string }) {
  const search = 'images%2F';
  const url = image.url.replace(search, `${search}thumb@256_`);
  return url;
}
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnDestroy {
  currentImage = null;

  getThumbUrl = getThumbUrl;

  selectedImages = [];

  sub;

  selectAll = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public photos,
    private dialogRef: MatDialogRef<GalleryComponent>,
  ) { }

  ngOnInit() {
    if (this.dialogRef instanceof MatDialogRef) {
      this.sub = this.dialogRef.backdropClick().subscribe(() => {
        this.sendImages();
      });
    }
  }

  selectImage(image) {
    const index = this.index(image);
    if (index >= 0) {
      this.selectedImages.splice(index, 1);
    } else {
      this.selectedImages.push(image);
    }
  }

  index(image) {
    return this.selectedImages.findIndex((i) => image === i);
  }

  sendImages() {
    const formattedImages = this.selectedImages.map((i) => ({
      url: i.url,
      cloned: true,
    }));
    this.dialogRef.close(formattedImages);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  toggleSelectAll() {
    if (this.selectAll) {
      this.selectedImages = [];
    } else {
      this.selectedImages = [...this.photos];
    }
    this.selectAll = !this.selectAll;
  }
}
