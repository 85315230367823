import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Booking } from 'app/models/booking.model';
import { Observable } from 'rxjs';

import { BaseService } from '../services/_base.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {
  constructor(http: HttpClient) {
    super(http, null);
  }

  getBooking(booking_token?: string): Observable<HttpResponse<Booking>> {
    return this.http.get<Booking>(`${this.apiURL}/client/booking`, this.getParams({
      booking_token,
    }));
  }

  getOrder(token?: string) {
    return this.http.get(`${this.apiURL}/client/order`, this.getParams({ token }));
  }

  getBudget(token?: string) {
    return this.http.get(`${this.apiURL}/client/budget`, this.getParams({ token }));
  }

  confirmBooking(token: string) {
    return this.http.post(`${this.apiURL}/client/confirm`, {}, this.getParams({ token }));
  }

  getNotification(id, token) {
    return this.http.get(`${this.apiURL}/client/notification`, this.getParams({ token, id }));
  }

  getPreference(id, payment_id = null) {
    return this.http.post(`${this.apiURL}/client/payment`, { payment_id }, this.getParams({ id }));
  }

  saveNotification(id, body, token) {
    return this.http.post(
      `${this.apiURL}/client/save_notification`,
      { order_notification: body },
      this.getParams({ token, id }),
    );
  }
}
