
<div class="wrapper">
  <div class="search-container">
    <div class="search-bar" >
      @if (searchControl.value) {
        <button mat-icon-button (click)="clearSearch()"> <mat-icon>arrow_back</mat-icon> </button>
      }
      @else {
        <mat-icon class="icon-container">search</mat-icon>
      }
      <input [formControl]="searchControl" name="search_query" autocomplete="off" autocorrect="off" spellcheck="false">
      <button mat-icon-button (click)="setupColumns('pending')"><mat-icon>edit</mat-icon></button>


    </div>

    @if (!downloading) {
      <button mat-icon-button (click)="exportAsExcelFile()"><mat-icon>download</mat-icon></button>
    }
    @if (downloading) {
      <button mat-icon-button disabled><mat-icon>downloading</mat-icon></button>
    }

   <button class="display-mobile primary" mat-icon-button (click)="togglePlanMode()" matTooltip="Plan Mode" *ngIf="(roleService.can('read','planner') | async)">
      <mat-icon style="color: var(--sys-on-primary);">
        @if (!planMode) {calendar_today}
        @else {table}
      </mat-icon>
    </button>


  </div>




  <div class="panels" *ngIf="workList">

    <div class="filters-lists hide-mobile">



      <mat-form-field>
        <mat-label>Rol</mat-label>
        <mat-select placeholder="Rol" [multiple]="true" [(ngModel)]="filter.role_id" (ngModelChange)="getWorks()">
          <mat-checkbox class="mat-option"
                        (click)="$event.stopPropagation()"
                        (change)="selectAllRoles($event)"
                        [indeterminate]="filter.role_id.length != roles.length && filter.role_id.length > 0"
                        [checked]="filter.role_id.length == roles.length"
                        >
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Usuario</mat-label>
        <mat-select placeholder="Usuario" [multiple]="true" [(ngModel)]="filter.user_id" (ngModelChange)="getWorks()">
          <mat-checkbox class="mat-option"
                        (click)="$event.stopPropagation()"
                        (change)="selectAllUsers($event)"
                        [indeterminate]="filter.user_id.length != users.length && filter.user_id.length > 0"
                        [checked]="filter.user_id.length == users.length">
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let user of users" [value]="user.id">{{user.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Estado</mat-label>
        <mat-select placeholder="Estado" [multiple]="true" [(ngModel)]="filter.status_id" (ngModelChange)="getWorks()">
          <mat-checkbox class="mat-option"
                        (click)="$event.stopPropagation()"
                        (change)="selectAllStatuses($event)"
                        [indeterminate]="filter.status_id.length != statuses.length && filter.status_id.length > 0"
                        [checked]="filter.status_id.length == statuses.length">
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [hidden]="planMode">
        <mat-label>{{appService.getText('work_assignation')}}</mat-label>
        <mat-select placeholder="Trabajo" [multiple]="true" [(ngModel)]="filter.work_id" (ngModelChange)="getWorks()">
          <mat-checkbox class="mat-option"
                        (click)="$event.stopPropagation()"
                        (change)="selectAllWorks($event)"
                        [indeterminate]="filter.work_id.length != works.length && filter.work_id.length > 0"
                        [checked]="filter.work_id.length == works.length">
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [hidden]="planMode">
        <mat-label>{{appService.getText('requirement')}}</mat-label>
        <mat-select placeholder="{{appService.getText('requirement')}}" [multiple]="true" [(ngModel)]="filter.requirement_id" (ngModelChange)="getWorks()">
          <mat-checkbox class="mat-option"
                        (click)="$event.stopPropagation()"
                        (change)="selectAllRequirements($event)"
                        [indeterminate]="filter.requirement_id.length != requirements.length && filter.requirement_id.length > 0"
                        [checked]="filter.requirement_id.length == requirements.length">
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [hidden]="planMode">
        <mat-label>Tipo</mat-label>
        <mat-select placeholder="Tipo" [multiple]="true" [(ngModel)]="filter.type" (ngModelChange)="getWorks()">
          <mat-checkbox class="mat-option"
                        (click)="$event.stopPropagation()"
                        (change)="selectAllTypes($event)"
                        [indeterminate]="filter.type.length != types.length && filter.type.length > 0"
                        [checked]="filter.type.length == types.length">
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [hidden]="!planMode">
        <mat-label>Zoom</mat-label>
        <mat-select placeholder="Zoom" [(ngModel)]="zoom">
          <mat-option *ngFor="let zoom of zooms" [value]="zoom.id">{{zoom.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="expander"></div>
      <div class="button-container">
        <button mat-raised-button class="primary modebutton" (click)="togglePlanMode()" *ngIf="(roleService.can('read','planner') | async)">
          @if (!planMode) {
            Plan Mode
          }
          @else {
             Lista
          }
        </button>
      </div>

    </div>

    <div class="left-panel panel" *ngIf="!planMode">
      <mat-card class="no-padding">

          <div class="table" [sortable-table]="workList" (sorted)="getWorks(false,$event)" sortable-table-key="pending">
            <div class="row header">
              <div class="column short hide-mobile" sortable-column="correlative_id" sortable-column-key="pending" style="padding-left: 10px;">{{appService.getText('order')}} Nº</div>
              <div class="column" sortable-column="user_name" sortable-column-key="pending">Asignado</div>
              <div class="column" *ngFor="let column of columns.pending_show" [sortable-column]="column[0]" sortable-column-key="pending" [hidden]="!column[1]">{{column[2] | formatColumn}}</div>
              <div class="column short hide-mobile" sortable-column="created_at" sortable-column-key="pending">Fecha</div>
              <!--
              <div class="column short hide-mobile" sortable-column="current_time" sortable-column-key="pending">Δ Tiempo</div>
              -->
            </div>
            <div class="row content">
              <div class="column content slider" *ngIf="workList" id="works">
                <ng-container *ngIf="workList.length > 0">
                  <div class="row work-row" [class.highlight]="pendingWork.id == highlight" *ngFor="let pendingWork of workList; let odd = odd" [class.odd]="odd" [class.even]="!odd" (click)="openWork(pendingWork.id,$event)" [class.rejected]="pendingWork.deleted_at">
                    <div class="column short hide-mobile" style="padding-left: 10px;">#{{pendingWork.correlative_id}}</div>
                    <div class="column assign"  (click)="pendingWork.user_name && setUser({id: pendingWork.id, roles_id: pendingWork.roles_id})" [class.not-assigned]="!pendingWork.user_name">{{pendingWork.user_name || 'Sin Asignar' | truncate:[20]}} <div class="user-status" [class.online]="pendingWork.online" *ngIf="pendingWork.user_name"></div></div>
                    <div class="column" *ngFor="let column of columns.pending_show" [title]="pendingWork[column[0]]">{{pendingWork[column[0]] | formatColumn:column[0]}}</div>
                    <div class="column short hide-mobile">{{pendingWork.created_at | date}}</div>
                    <!--
                    <div class="column short hide-mobile"><app-work-countdown [work]="pendingWork"></app-work-countdown></div>
                  -->
                  </div>
                  <div class="load-more" [hidden]="page >= total_pages" id="load-more">
                    <mat-spinner [diameter]="20"></mat-spinner> Cargando trabajos
                  </div>
                </ng-container>
                <div class="row" *ngIf="workList.length == 0">
                  <div class="column center">No hay trabajos pendientes <i class="fa fa-check"></i></div>
                </div>
              </div>
            </div>
          </div>

      </mat-card>
    </div>

    <div class="left-panel panel" *ngIf="planMode">
      <app-work-plan [works]="workList" (onOpenWork)="openWork($event)" (onSetUser)="setUser($event)" (update)="getWorks()" [zoom]="zoom"></app-work-plan>
    </div>
  </div>


  <mat-card appearance="outlined" *ngIf="!workList">
    <div class="row">
      <div class="column">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </mat-card>
</div>
