<mat-card  *ngIf="!selected">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Listas</mat-card-title>
    <mat-card-subtitle>Administra listados personalizados para formularios.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager clone="true" [service]="listsService" [itemTemplate]="itemLTemplate" [formTemplate]="formLTemplate" [formGroup]="formList">
      <ng-template #itemLTemplate let-item>
        <div class="click" (click)="select(item)" *ngIf="!item.list_id"><i class="fa fa-plus"></i>&nbsp;</div>
        <div><i class="fa fa-globe" *ngIf="item.list_id"></i> {{item.name}}</div>
      </ng-template>
      <ng-template #formLTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Lista Global</mat-label>
            <mat-select placeholder="Lista Global" formControlName="list_id">
              <mat-option *ngFor="let list of global_lists" [value]="list.id">{{list.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [hidden]="!form.controls['list_id'].value">
            <mat-label>Filtro de Lista</mat-label>
            <mat-select placeholder="Filtro de Lista" formControlName="list_item_filter_ids" multiple="true">
              <mat-option *ngFor="let item of list" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-hint>Opciones a mostrar (No filtrar seleccionará todas las opciones)</mat-hint>
          </mat-form-field>
          <div class="row" style="margin-bottom: 10px">
            <div class="col">
              <mat-checkbox formControlName="multiple">
                Opción Multiple
              </mat-checkbox>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
<mat-card  *ngIf="selected">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Lista - {{selected?.name || 'Items'}}</mat-card-title>
    <mat-card-subtitle>Administra listados personalizados para formularios.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="listsItemsService" [itemTemplate]="itemListTemplate" [formTemplate]="formListTemplate" [formGroup]="formList" [args]="[selected.id]" [showNavigation]="true" (back)="goUp()">
      <ng-template #itemListTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formListTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
<!--<mat-card>
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Importar Lista</mat-card-title>
    <mat-card-subtitle>Importar bases de datos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="importForm">
      <mat-form-field>
        <input matInput placeholder="Excel" formControlName="file" (click)="file.click()">
        <mat-icon matSuffix (click)="getRows()">folder</mat-icon>
      </mat-form-field>
      <input type="file" #file (change)="import($event)" hidden>
      <ng-container formArrayName="pairs">
        <ng-container *ngFor="let pair of getPairs(); let i = index" formGroupName="{{i}}">
          <div class="row">
            <div class="column">
              <mat-form-field>
                <mat-select placeholder="Campo Excel" formControlName="row_id">
                  <mat-option *ngFor="let row of rows" [value]="row">{{row}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="column">
              <mat-form-field>
                <mat-select placeholder="Campo Base de Datos" formControlName="attribute_id">
                  <mat-optgroup *ngFor="let field of fields" [label]="field.name">
                    <mat-option *ngFor="let f of field.fields" [value]="f.id">{{f.name}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </form>
  </mat-card-content>
</mat-card>
-->
