/* eslint-disable no-param-reassign */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component, Inject, OnDestroy, OnInit, signal,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Booking } from 'app/models/booking.model';
import { CheckIn } from 'app/models/checkin.model';
import { calculateInventoryActionTotal } from 'app/models/inventory_action.model';
import { InventoryActionProduct } from 'app/models/inventory-action-product';
import { fromKitsToInventoryActionProducts, Kit } from 'app/models/kits.model';
import { Location } from 'app/models/location.model';
import { User } from 'app/models/user';
import { KitsService } from 'app/services/kits.service';
import { UserService } from 'app/services/user.service';
import { InventoryActionFormComponent }
  from 'app/shared/common-forms/inventory-action-form/inventory-action-form.component';
import { cloneDeep, uniq } from 'lodash-es';
import moment from 'moment';
import {
  catchError, debounceTime, merge, of,
} from 'rxjs';

import { AdminService } from '../../admin/services/admin.service';
import { RoleService } from '../../role/role.service';
import { BookingService } from '../../services/booking.service';
import { HolydaysService } from '../../services/holydays.service';
import { RequirementsService } from '../../services/requirements.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

interface FreeBookingHoursData {
  hours: string[];
  errors: Record<string, boolean>;
}

export function setupInventoryActionForConsumeForm(self, model: Partial<Booking> | Partial<CheckIn>) {
  self.inventory_action_for_consume = self.fb.group({
    id: model.inventory_action_for_consume.id,
    estimated_completion_date: model.inventory_action_for_consume.estimated_completion_date,
    document: self.fb.control(model.inventory_action_for_consume.document),
    status: self.fb.control(model.inventory_action_for_consume.status),
    inventory_action_products: self.fb.array(
      model.inventory_action_for_consume.inventory_action_products.map(
        (product) => self.fb.group({
          id: product.id,
          amount: product.amount,
          product_id: product.product_id,
          discount_percent: product.discount_percent,
          quantity: product.quantity,
          unit: product.unit,
          description: product.description,
          code: product.code,
          category_id: product.category_id,
          _destroy: false,
        }),
      ),
    ),
  });
}

export function setupOrderAdditionals(self, model: Partial<Booking> | Partial<CheckIn>) {
  self.order_aditionals = self.fb.array(
    model.order_aditionals.map((aditional) => self.fb.group({ ...aditional, _destroy: false })),
  );
}

export function addAdditional(self, additional) {
  const products = self.inventory_action_products.getRawValue();
  const productsIds = products.map((product: InventoryActionProduct) => product.product_id);

  const additionalProducts = fromKitsToInventoryActionProducts([additional]).filter(
    (product: InventoryActionProduct) => {
      if (productsIds.includes(product.product_id)) {
        products.find((p) => p.product_id === product.product_id)._destroy = false;
        return false;
      }

      return true;
    },
  );
  const id = self.inventory_action_for_consume.get('id')?.value;
  const estimatedCompletionDate = self.inventory_action_for_consume.get('estimated_completion_date')?.value;

  self.inventory_action_for_consume = self.fb.group({
    id,
    estimated_completion_date: estimatedCompletionDate,
    inventory_action_products: self.fb.array(
      products.concat(additionalProducts).map((product) => self.fb.group(product)),
    ),
  });
  if (self.inventoryForm) {
    setTimeout(() => {
      self.inventoryForm.emitTotal();
    }, 0);
  }
}

export function removeAdditional(self, additional) {
  const id = self.inventory_action_for_consume.get('id')?.value;
  const estimatedCompletionDate = self.inventory_action_for_consume.get('estimated_completion_date')?.value;

  const additionalProductsIds = additional.kit_products.map((kp) => kp.product_id);

  const productsToRemain = [];

  self.inventory_action_products.getRawValue().forEach((product) => {
    if (!additionalProductsIds.includes(product.product_id)) {
      productsToRemain.push(product);
      return;
    }

    if (product.id) {
      product._destroy = true;
      productsToRemain.push(product);
    }
  });

  self.inventory_action_for_consume = self.fb.group({
    id,
    estimated_completion_date: estimatedCompletionDate,
    inventory_action_products: self.fb.array(
      productsToRemain.map((product) => self.fb.group(product)),
    ),
  });
  if (self.inventoryForm) {
    setTimeout(() => {
      self.inventoryForm.emitTotal();
    }, 0);
  }
}

export function retrieveKits(self, model: Partial<Booking> | Partial<CheckIn>) {
  self.kitsService.searchByOrder(self.form.getRawValue()).subscribe((kits: Kit[]) => {
    let inventoryProducts = [];

    const editedProducts = self.inventory_action_products.controls
      .filter(
        (control) => control.get('_edited')?.value,
      )
      .map((control) => control.getRawValue());

    const editedProductsIds = editedProducts.map((product: InventoryActionProduct) => product.product_id);
    if ((kits && kits.length) || model.clientMode) {
      inventoryProducts = fromKitsToInventoryActionProducts(kits).filter(
        (product: InventoryActionProduct) => !editedProductsIds.includes(product.product_id),
      );
    }

    self.inventory_action_for_consume = self.fb.group({
      id: model.inventory_action_for_consume?.id,
      estimated_completion_date: model.inventory_action_for_consume?.estimated_completion_date,
      inventory_action_products: self.fb.array(
        inventoryProducts.concat(editedProducts).map((product) => self.fb.group(product)),
      ),
    });
    if (self.inventoryForm) {
      setTimeout(() => {
        self.inventoryForm.emitTotal();
      }, 0);
    }
  });
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit, OnDestroy {
  addAdditional = addAdditional;

  booking: Partial<Booking> = {};

  bookingEditToken:string;

  readonly panelOpenState = signal(false);

  bookingErrors;

  company = this.adminService.local$.getValue();

  correct = false;

  currentDate = moment().toDate();

  deleted = false;

  @ViewChild('inventoryForm') inventoryForm : InventoryActionFormComponent;

  freeBookingHours: string[] = [];

  locations: Location[];

  onlyWeek;

  permittedHours: string[];

  removeAdditional = removeAdditional;

  subs = [];

  types = [];

  users: User[] = [];

  working = false;

  totalValue: number = 0;

  form: FormGroup = this.fb.group({
    id: null,
    booking_date: this.fb.control({ value: null }, [Validators.required]),
    booking_time: [null, [Validators.required]],
    client: this.fb.group({
      id: null,
      rut: this.fb.control(null, [Validators.required]),
      name: '',
      address: '',
      city: '',
      email: '',
      phone: '',
      correlative_id: null,
      custom_fields: this.fb.group({}),
    }),
    comment: '',
    custom_fields: this.fb.group({}),
    document_id: this.fb.control(null),
    document_type: this.fb.control(null),
    from: this.fb.control(''),
    location_id: [null],
    object: this.fb.group({
      id: null,
      client_id: '',
      brand_id: this.fb.control(null, [Validators.required]),
      brand_model_id: this.fb.control(null, [Validators.required]),
      color: '',
      identifier: '',
      chasis: '',
      custom_fields: this.fb.group({}),
    }),
    requirement_id: new FormControl(null, [Validators.required]),
    user_id: this.fb.control({ value: [], disabled: true }),
    inventory_action_for_consume: this.fb.group({
      inventory_action_products: this.fb.array([]),
    }),
    order_aditionals: this.fb.array([]),
    kms: '',
  });

  loading = false;

  constructor(
    public bookingService: BookingService,
    public snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<BookingComponent>,
    public router: Router,
    public matDialog: MatDialog,
    public requirements: RequirementsService,
    public roleService: RoleService,
    @Inject(MAT_DIALOG_DATA) data,
    private holydaysService: HolydaysService,
    public adminService: AdminService,
    public userService: UserService,
    public kitsService: KitsService,
    public fb: FormBuilder,
  ) {
    if (data) {
      this.booking = cloneDeep(data);
      this.form.patchValue(this.booking, { emitEvent: false });
      this.form.controls.booking_date.setValue(this.booking.booking_date);
      if (this.company.object === 'Vehicle') {
        setTimeout(() => {
          this.form.get('object').get('brand_id').setValue(this.booking.object.brand_id);
        }, 1000);
      }
    }
  }

  get inventory_action_for_consume() {
    return this.form.controls.inventory_action_for_consume as FormGroup;
  }

  set inventory_action_for_consume(value) {
    this.form.controls.inventory_action_for_consume = value;
  }

  get inventory_action_products() {
    return this.inventory_action_for_consume.controls.inventory_action_products as FormArray;
  }

  set inventory_action_products(value) {
    this.inventory_action_for_consume.controls.inventory_action_products = value;
  }

  get order_aditionals() {
    return this.form.controls.order_aditionals as FormArray;
  }

  set order_aditionals(value) {
    this.form.controls.order_aditionals = value;
  }

  ngOnInit() {
    if (this.booking && this.booking.id) {
      this.loading = true;
      this.bookingService.get(this.booking.id).toPromise().then((response) => {
        this.booking = response.body;
        this.booking.hasConsumeAction = !!this.booking.inventory_action_for_consume;
        this.form.patchValue(this.booking, { emitEvent: false });
        this.form.controls.booking_date.setValue(this.booking.booking_date);
        if (this.company.object === 'Vehicle') {
          setTimeout(() => {
            this.form.get('object').get('brand_id').setValue(this.booking.object.brand_id);
          }, 1000);
        }
        if (this.booking.hasConsumeAction) {
          setupInventoryActionForConsumeForm(this, this.booking);
          this.totalValue = calculateInventoryActionTotal(this.booking.inventory_action_for_consume);
        }
        setupOrderAdditionals(this, this.booking);
        this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    }

    this.subs.push(this.requirements.requirements$.subscribe((requirements) => {
      this.types = requirements;
    }));

    this.setupLocations();

    if (this.company.object !== 'Vehicle') {
      (<FormGroup> this.form.controls.object).removeControl('brand_id');
      (<FormGroup> this.form.controls.object).removeControl('brand_model_id');
    }

    if (this.booking.location_id) this.form.controls.booking_date.enable();

    this.loadLocationConfig(this.booking.location_id);

    this.setFormChangeHandlers();
    this.form.markAllAsTouched();
  }

  setupLocations() {
    this.company = this.adminService.local$.getValue();
    this.locations = this.adminService.getLocations();
    if (!this.booking.location_id) {
      if (this.locations && this.locations.length === 1) {
        this.booking.location_id = this.locations[0].id;
      } else if (this.adminService.locations$.getValue() && this.adminService.locations$.getValue().length === 1) {
        [this.booking.location_id] = this.adminService.locations$.getValue();
      }
    }
    this.locationId = this.booking.location_id;
  }

  setFormChangeHandlers() {
    merge(
      this.form.controls.booking_date.valueChanges,
      this.form.controls.location_id.valueChanges,
      this.form.controls.requirement_id.valueChanges,
      this.form.controls.user_id.valueChanges,
    ).pipe(
      debounceTime(500),
    ).subscribe((change) => {
      if (!change) return;

      // eslint-disable-next-line no-underscore-dangle
      if (change._isAMomentObject) {
        this.searchDate(this.form.controls.booking_date.value, null);
      } else {
        this.searchDate(this.form.controls.booking_date.value, this.booking.booking_time);
      }
      if (!this.booking.hasConsumeAction) {
        retrieveKits(this, this.booking);
      }
    });

    merge(
      this.form.controls.object.valueChanges,
      this.form.controls.custom_fields.valueChanges,
    ).pipe(
      debounceTime(500),
    ).subscribe(() => {
      if (!this.booking.hasConsumeAction) {
        retrieveKits(this, this.booking);
      }
    });

    this.form.controls.location_id.valueChanges.subscribe((id) => {
      this.loadLocationConfig(id);
    });
  }

  loadLocationConfig(id: number | string) {
    if (!id) {
      this.holydaysService.filterFunction().then((value) => {
        this.onlyWeek = value;
      });
      return;
    }

    const locationId = parseInt(id as string, 10);
    this.holydaysService.filterFunction(
      false,
      this.company.locations.find((l:Location) => l.id === locationId).preferences,
    ).then((value) => {
      this.onlyWeek = value;
    });

    this.requirements.requirements$.subscribe((response) => {
      this.types = response.filter((req) => req.location_ids.includes(locationId));
    });

    if (this.company.preferences.booking_type === 'professional') {
      this.userService.professionals(locationId).subscribe((response) => {
        this.users = response.body;
        if (this.users.length > 0) this.form.controls.user_id.enable();
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  saveForm() {
    this.working = true;
    if (!this.form.valid) {
      this.working = false;
      return;
    }

    if (!this.booking.id) {
      this.bookingService.create({ booking: this.form.getRawValue() })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.error(error);
            return of(null);
          }),
        )
        .subscribe((value) => {
          if (!value) return;

          this.success(value.body);
        });
      return;
    }

    this.bookingService.update(
      this.booking.id,
      { booking: this.form.getRawValue() },
      this.bookingEditToken,
    )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.error(error);
          return of(null);
        }),
      )
      .subscribe((value) => {
        if (!value) return;

        this.success(value.body);
      });
  }

  createOrder() {
    this.working = true;
    this.router.navigate(['main', 'checkin'], { queryParams: { booking_id: this.booking.id } });
    this.matDialogRef.close(true);
  }

  removeBooking() {
    this.matDialog.open(ConfirmDialogComponent).afterClosed().subscribe((result) => {
      if (result) {
        this.bookingService.delete(this.booking.id, this.bookingEditToken).toPromise().then(() => {
          this.deleteSuccess();
        }).catch(() => {
          this.snackBar.open('¡Ha ocurrido un error al borrar!', null, { duration: 1000 });
        });
      }
    });
  }

  confirm() {
    this.working = true;
    this.bookingService.update(
      this.booking.id,
      { booking: { confirmed: true } },
      this.bookingEditToken,
    ).toPromise().then((value) => {
      this.success(value.body, '¡Confirmado exitosamente!');
    }).catch((error:HttpErrorResponse) => {
      console.error(error);
      this.error(error);
      this.working = false;
    });
  }

  viewOrder() {
    this.router.navigate(['main', 'orders', this.booking.id]);
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    }
  }

  deleteSuccess() {
    this.snackBar.open('¡Eliminado exitosamente!', null, { duration: 1000 });
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    } else {
      this.deleted = true;
    }
  }

  success(value?, message?) {
    this.snackBar.open(message || '¡Guardado exitosamente!', null, { duration: 1000 });
    if (this.form) {
      this.form.reset();
      this.correct = true;
    }
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close(value);
    }
  }

  error(error) {
    if (error.error) {
      if (error.error.booking_date) {
        this.form.controls.booking_time.setErrors({ invalid: true });
        this.form.controls.identifier.updateValueAndValidity();
        this.snackBar.open('¡OOPS!, la fecha ya esta registrada.', null, { duration: 1000 });
      } else if (error.error.vehicle_id) {
        if (this.form.controls.identifier) {
          this.form.controls.identifier.setErrors({ invalid: true });
          this.form.controls.identifier.updateValueAndValidity();
        }
        this.snackBar.open('¡OOPS!, este vehículo ya esta ingresado.', null, { duration: 2000 });
      } else if (error.error['vehicle.identifier']) {
        if (this.form.controls.identifier) {
          this.form.controls.identifier.setErrors({ invalid: true });
          this.form.controls.identifier.updateValueAndValidity();
        }
        this.snackBar.open('¡OOPS!, este vehículo ya esta ingresado.', null, { duration: 2000 });
      } else if (error.error.object_id) {
        if (this.form.controls.identifier) {
          this.form.controls.identifier.setErrors({ invalid: true });
          this.form.controls.identifier.updateValueAndValidity();
        }
        this.snackBar.open(
          `¡OOPS!,${this.adminService.getText('object', 'capitalize')} ya está en la agenda`,
          null,
          { duration: 2000 },
        );
      } else if (error.error.booking_time) {
        this.form.controls.booking_time.setErrors({ invalid: true });
        if (this.form.controls.plater) this.form.controls.identifier.updateValueAndValidity();
        this.snackBar.open('¡OOPS!, Debes ingresar una hora.', null, { duration: 2000 });
      } else if (error.error.kms) {
        this.form.controls.kms.setErrors({ invalid: true });
        this.snackBar.open(
          `¡OOPS!, El kilometraje debe ser mayor o igual al del último ingreso de este vehículo (${error.error.kms}).'`,
          null,
          { duration: 2000 },
        );
      } else {
        const errors = [];
        Object.keys(error.error).forEach((key) => {
          if (error.error[key]) {
            errors.push(`${key}: ${error.error[key]}`);
            if (this.form[key]) {
              this.form[key].setErrors({ invalid: true });
              this.form[key].updateValueAndValidity();
            }
          }
        });
        if (errors.length === 0) {
          errors.push('Error desconocido');
        }
        this.snackBar.open(`¡OOPS! ${errors.join(',')}`, null, { duration: 2000 });
      }
    }
    this.working = false;
  }

  searchDate(date?: string, hour?, clientMode?): void {
    this.working = true;
    if (!this.form.controls.requirement_id.value) {
      this.freeBookingHours = [];
      return;
    }
    if (!hour) {
      this.form.controls.booking_time.setValue(null);
    }

    this.bookingService.searchFree(this.form.getRawValue()).toPromise().then(
      (value:HttpResponse<FreeBookingHoursData>) => {
        this.freeBookingHours = value.body.hours || [];
        if (value.body.errors) {
          this.bookingErrors = Object.keys(value.body.errors).length > 0 ? value.body.errors : null;
          if (clientMode) {
            this.freeBookingHours = [];
          }
        } else {
          this.bookingErrors = null;
        }
        if (moment(date).isSame(moment(), 'day')) {
          this.freeBookingHours = this.freeBookingHours.filter((freeHour) => moment().format('HH:mm') < freeHour);
        }
        this.permittedHours = cloneDeep(this.freeBookingHours);
        if (this.booking.id && hour) {
          this.freeBookingHours.unshift(this.booking.booking_time);
          this.freeBookingHours = uniq(this.freeBookingHours);
          this.permittedHours.unshift(this.booking.booking_time);
          this.permittedHours = uniq(this.permittedHours);
        }
        if (hour && !this.booking.id) {
          this.freeBookingHours.unshift(hour);
          this.freeBookingHours = uniq(this.freeBookingHours);
          this.form.controls.booking_time.setValue(hour);
        }

        if (clientMode) {
          if (this.form && this.form.controls.booking_time) {
            this.form.controls.booking_time.clearValidators();
            if (this.permittedHours.indexOf(this.booking.booking_time) === -1) {
              this.form.controls.booking_time.setValue(null);
              this.form.controls.booking_time.setErrors({ invalid: true });
              this.form.controls.booking_time.markAsTouched();
              this.form.controls.booking_time.markAsDirty();
            } else {
              this.form.controls.booking_time.setValue(this.booking.booking_time);
            }
          }
        }

        this.working = false;
      },
    ).catch((error:HttpErrorResponse) => {
      console.log(error);
      this.working = false;
    });
  }

  objectSelected(object) {
    const { identifier, brand_model_id: brandModelId, ...rest } = object;
    this.form.controls.object.patchValue(rest);
    (<FormGroup> this.form.controls.object).controls.identifier.patchValue(identifier, { emitEvent: false });

    if (this.company.object === 'Vehicle') {
      setTimeout(() => {
        (<FormGroup> this.form.controls.object).controls.brand_model_id.setValue(brandModelId);
      }, 1000);
    }
  }

  timeValidator(c: UntypedFormControl) {
    const error = { invalid: true };
    if (c && c.value) {
      if (!this.permittedHours || this.permittedHours.indexOf(c.value) >= 0) {
        return null;
      }
      return error;
    }
    return null;
  }

  closeDialog() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    } else {
      this.router.navigate(['main', 'orders']);
    }
  }

  get locationId() {
    return this.form.controls.location_id.value;
  }

  set locationId(value) {
    this.form.controls.location_id.setValue(value);
  }

  get requirementId() {
    return this.form.controls.requirement_id.value;
  }

  get userId() {
    return this.form.controls.user_id.value;
  }

  set userId(value) {
    this.form.controls.user_id.setValue(value);
  }

  onTotalValueChange(newTotal: number) {
    this.totalValue = newTotal;
  }
}
