import { DecimalPipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';

function formatRut(rut: string) {
  if (rut) {
    let value = rut.replace(/[^\dKk]/g, '');

    if (value.length < 2) return value;
    if (value.length >= 9) {
      value = value.slice(0, 9);
    }

    if (value.includes('K') || value.includes('k')) {
      const index = value.indexOf('K') || value.indexOf('k');
      const body = (new DecimalPipe('es_cl')).transform(value.slice(0, index), '1.0');
      return `${body}-K`;
    }

    const body = (new DecimalPipe('es_cl')).transform(value.slice(0, -1), '1.0');
    const dv = value.slice(-1).toUpperCase();
    return `${body}-${dv}`;
  }
  return '';
}

function getFormatter(countryCode: string) {
  switch (countryCode) {
    case 'cl':
      return formatRut;
    default:
      return (val: string) => val;
  }
}

export function formatIdentifier(identifier: string, countryCode: string) {
  return getFormatter(countryCode)(identifier);
}

export function checkRut(c: UntypedFormControl) {
  let correct = true;
  const rut = c.value;
  if (rut) {
    // Despejar Puntos
    const valor = rut.replace(/[^\dKk]/g, '');
    // Aislar Cuerpo y Dígito Verificador
    const cuerpo = valor.slice(0, -1);
    let dv = valor.slice(-1).toUpperCase();

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if (cuerpo.length < 7) { correct = false; }

    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;

    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i += 1) {
      // Obtener su Producto con el Múltiplo Correspondiente
      const index = multiplo * valor.charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma += index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) { multiplo += 1; } else { multiplo = 2; }
    }

    // Calcular Dígito Verificador en base al Módulo 11
    const dvEsperado = (11 - (suma % 11)).toString();
    // Casos Especiales (0 y K)
    dv = (dv === 'K') ? '10' : dv;
    dv = (dv === 'k') ? '10' : dv;
    dv = (dv === '0') ? '11' : dv;
    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado !== dv) { correct = false; }
    if (correct) {
      return null;
    }
    return { invalid: true };
  }
  return { invalid: true };
}
