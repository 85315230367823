import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, of } from 'rxjs';

import { Aditional } from '../models/types';
import { BaseService } from './_base.service';
import { UserService } from './user.service';

@Injectable()
export class AdditionalsService extends BaseService {
  additionals$ = new BehaviorSubject([]);

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
    this.all().pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return of(null);
      }),
    ).subscribe((value:HttpResponse<Aditional[]>) => {
      if (!value) return [];

      this.additionals$.next(value.body || []);
    });
  }

  all() {
    return this.http.get(`${this.apiURL}/additionals`, this.getParams());
  }
}
