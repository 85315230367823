<div mat-dialog-title class="name">
  Seleccione {{adminService.getText('object')}}
</div>
<div mat-dialog-content class="content">

  <mat-list>
    <mat-list-item *ngFor="let object of objects" (click)="selectObject(object)" class="option">
      <span matListItemTitle><i class="fa fa-cubes"></i> {{object.identifier}}</span>
    </mat-list-item>
  </mat-list>

</div>

<div mat-dialog-actions class="footer">
  <button mat-button cdkFocusInitial (click)="selectObject(null)">Crear nuevo {{adminService.getText('object')}}</button>
</div>
