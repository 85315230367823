import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {AdminService} from "../../admin/services/admin.service";
import {ClientService} from "../client.service";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs/operators";
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-client-budget',
  templateUrl: './client-budget.component.html',
  styleUrls: ['./client-budget.component.scss']
})
export class ClientBudgetComponent implements OnInit {
  budget:any;
  company_logo;
  company;
  loading = false;
  budgetToken:string;
  error = false;
  constructor(
    private sanitizer: DomSanitizer,
    private adminService: AdminService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.company_logo = this.adminService.local$.getValue().preferences.logo || 'assets/images/logo-anfruns.png';
    this.company = this.adminService.local$.getValue();
    this.route.queryParams.pipe(first()).forEach((value)=>{
      if(value.budget_token) {
        this.loading = true;
        this.budgetToken = value.budget_token;
        this.clientService.getBudget(this.budgetToken).toPromise().then((response) => {
          this.loading = false;
          if(response.body && response.status === 200) {
            this.budget = response.body;
          }
        }).catch((error) => {
          this.loading = false;
          this.error = true;
          console.log(error);
        });
      }
    });
    this.zendesk.hide();
  }
  sanitizeBackground(url){
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }

}
