import {NgModule} from '@angular/core';
import {WorkPointComponent} from './work-point.component';
import {WorkPointLoginComponent} from './work-point-login/work-point-login.component';
import {WorkPointMainComponent} from './work-point-main/work-point-main.component';
import {WorkPointWorksComponent} from './work-point-main/work-point-works/work-point-works.component';
import {CanActivateWorkPoint} from "./work-point.permission";
import {MainModule} from "../main/main.module";
import {PrioritiesModule} from "../priorities/priorities.module";
import {WorkModule} from "../work/work.module";
import {WorkPointUnauthorizedComponent} from './work-point-unauthorized/work-point-unauthorized.component';
import {BaseModule} from "../shared/base.module";

@NgModule({
  declarations: [
    WorkPointComponent,
    WorkPointLoginComponent,
    WorkPointMainComponent,
    WorkPointWorksComponent,
    WorkPointUnauthorizedComponent
  ],
  providers: [
    CanActivateWorkPoint
  ],
  imports: [
    BaseModule,
    MainModule,
    PrioritiesModule,
    WorkModule
  ]
})
export class WorkPointModule { }
