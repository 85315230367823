<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Categorías de productos</mat-card-title>
    <mat-card-subtitle>Administra las categorías de productos.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="categoryService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        <div class="click" (keyup)="$event.keyCode === 13 && showProducts(item)" (click)="showProducts(item)" tabindex="0"><i class="fa fa-plus"></i> {{item.name}}</div>
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Unidad</mat-label>
            <input matInput placeholder="Unidad" formControlName="unit" autocomplete="off" required>
          </mat-form-field>
          <mat-checkbox formControlName="use_as_name">
            Usar como descripción
          </mat-checkbox>
          <mat-form-field>
            <mat-label>Valor por Defecto</mat-label>
            <input matInput currencyMask placeholder="Valor por defecto" formControlName="value" autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Campos Customs</mat-label>
            <mat-select placeholder="Campos customs" formControlName="custom_field_ids" multiple="true">
              <mat-option *ngFor="let field of customFields | async" [value]="field.id">{{field.title}}</mat-option>
            </mat-select>
            <mat-hint>Campos customs a mostrar</mat-hint>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>

<app-admin-custom-fields [service]="fieldsService" model="FieldForCategory"></app-admin-custom-fields>
