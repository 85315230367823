import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { IdentifierDirective } from './identifier.directive';
import { PaginationDirective } from './pagination.directive';

@NgModule({
  declarations: [
    PaginationDirective,
    IdentifierDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PaginationDirective,
    IdentifierDirective,
  ],
  providers: [
    DecimalPipe,
  ],
})
export class CommonDirectivesModule { }
