import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss']
})
export class CompanyLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
