import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'brand'
})
export class BrandPipe implements PipeTransform {
  transform(items: any[], ids: number[]): any {
    if (!items || !ids) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => ids.indexOf(item.brand_id) >= 0);
  }
}
