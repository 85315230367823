<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-whatsapp fa-2x"></i></div>
    <mat-card-title>Configuración de Whatsapp</mat-card-title>
    <mat-card-subtitle>Inicie sesion con su cuenta de Meta</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">

        <button *ngIf="!hasToken" (click)="launchWhatsAppSignup()" mat-raised-button color="primary">
          Conectar con Meta 
        </button>
        
          <div *ngIf="hasToken" class="subtitle">Ya existe una cuenta de meta asociada <i class="fa fa-check-circle"></i> </div>
         
      </div>
    </div>
  </mat-card-content>
</mat-card>


