import { Pipe, PipeTransform } from '@angular/core';
import { StatusesService } from 'app/services/statuses.service';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  statuses = [];

  constructor(private statusesService: StatusesService) {
    this.statusesService.statuses().subscribe((statuses) => {
      this.statuses = statuses;
    });
  }

  transform(value: any, args?, array?:any[]): any {
    if (value instanceof Array) {
      return value.map((v) => this.transform(v, args, array)).join(', ');
    }
    const item = (array || this.statuses).find((a) => a.id === value);
    if (item) {
      return item[args || 'name'];
    }
    return value;
  }
}
