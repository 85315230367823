import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { debounce, interval } from 'rxjs';

@Directive({
  selector: '[appPagination]'
})
export class PaginationDirective {
  @Input() isLoading: boolean = false;
  @Output() loadMore = new EventEmitter<void>();
  @Input() page = 1;
  @Input() totalPages = 1;

  constructor(private el: ElementRef) { }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    if (this.hasMorePages && this.hasReachedBottom && !this.isLoading) {
      this.loadMore.emit();
    }
  }

  private get hasReachedBottom(): boolean {
    const threshold = 150;
    const position = this.el.nativeElement.scrollTop + this.el.nativeElement.offsetHeight;
    const height = this.el.nativeElement.scrollHeight;

    return position && position > height - threshold
  }

  private get hasMorePages(): boolean {
    return this.page < this.totalPages;
  }
}
