<mat-toolbar class="header">
  <div class="back" (click)="goBack()" matTooltip="Atrás" matTooltipPosition="right" (keyup)="$event.keyCode === 13 && goBack()" tabindex>
    <mat-icon >arrow_back_ios_new</mat-icon>
  </div>
  <div class="hide-print" style="font-weight: 500;">
   <span class="hide-mobile">{{adminService.getText('crm','capitalize')}} </span>#{{crm?.correlative_id}}
  </div>
  <div class="expander"></div>
<button mat-button [mat-menu-trigger-for]="menu"><mat-icon>more_horiz</mat-icon></button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openNotifications()"><mat-icon>notifications</mat-icon> Notificaciones al cliente</button>
</mat-menu>

</mat-toolbar>

@if (!crm){
  <div class="skeleton-container">
    <div class="skeleton" style="height: 98px;"></div>
    <div class="skeleton" style="height: 123px;"></div>
    <div class="skeleton" style="height: 170px;"></div>
    <div class="skeleton" style="height:  74px;"></div>
    <div class="skeleton" style="height:  90px;"></div>
  </div>
}

<div class="wrapper" #wrapper>

  <div class="crm" *ngIf="crm">

    <div class="content">
      <ng-container>
        <div class="subtitle">General</div>
        <div class="info">
          <div class="name">Gestión</div>
          <div class="value">{{crm.from_requirement?.name}} &nbsp; -> &nbsp; <b>{{crm.requirement?.name}}</b></div>
        </div>
        <div class="info">
          <div class="name">Creación</div>
          <div class="value">{{crm.created_at | date}}</div>
        </div>
        <div class="info last">
          <div class="name">{{adminService.getText('comment')}}</div>
          <div class="value">{{crm.booking?.comment}}</div>
        </div>
      </ng-container>

      <div class="subtitle">{{adminService.getText('client')}} #{{crm.client.id}}</div>
      <div class="info" [class.last]="(roleService.can('read','client') | async) === false">
        <div class="name">Nombre</div>
        <div class="value">{{crm.client?.name || 'No existe el' + adminService.getText('client','lowercase')}}</div>
      </div>
      <ng-container *ngIf="roleService.can('read','client') | async">
        <div class="info">
          <div class="name">{{company.country.preferences.client_id}}</div>
          <div class="value">{{crm.client?.rut}}</div>
        </div>
        <div class="info">
          <div class="name">Dirección</div>
          <div class="value">{{crm.client?.address}}, {{crm.client?.city}}</div>
        </div>
        <div class="info">
          <div class="name">E-mail</div>
          <div class="value">{{crm.client?.email}}</div>
        </div>
        <div class="info last">
          <div class="name">Teléfono</div>
          <div class="value"><a href="tel:{{crm.client?.phone}}" class="phone"><i class="fa fa-phone"></i>{{crm.client?.phone}}</a></div>
        </div>
      </ng-container>
      <ng-container *ngIf="company.object === 'Vehicle'">
        <div class="subtitle">{{adminService.getText('object')}} #{{crm.object?.id}}</div>
        <div class="info">
          <div class="name">{{adminService.getText('object_identifier')}}</div>
          <div class="value">{{crm.object?.identifier}}</div>
        </div>
        <div class="info">
          <div class="name">Marca</div>
          <div class="value">{{crm.object?.brand?.name}}</div>
        </div>
        <div class="info">
          <div class="name">Modelo</div>
          <div class="value">{{crm.object?.brand_model?.name}}</div>
        </div>
        <div class="info">
          <div class="name">Chasis</div>
          <div class="value">{{crm.object?.chasis || '-'}}</div>
        </div>
        <div class="info" *ngIf="crm.kms >= 0">
          <div class="name">Kilometraje</div>
          <div class="value">{{crm.kms || crm.object?.kms}}</div>
        </div>
        <div class="info last" style="border-bottom: none;">
          <div class="name">Color</div>
          <div class="value">{{crm.object?.color}}</div>
        </div>
      </ng-container>


      <ng-container *ngIf="company.object === 'Custom'">
        <div class="subtitle">{{adminService.getText('object')}} #{{order.object?.id}}</div>
        <div class="info">
          <div class="name">{{adminService.getText('object_identifier')}}</div>
          <div class="value">{{crm.object?.identifier}}</div>
        </div>
        <app-order-fields readonly="true" [model]="crm.object" mode='Custom' [wrap]=false></app-order-fields>
      </ng-container>

      <ng-container *ngIf="crm.booking">
        @if (crm.booking.checked_in) {
          <div class="subtitle">{{adminService.getText('checkin')}}
            <div class="expander"></div>
            <div class="order-button" (click)="goToOrder(crm.booking.id)" (keydown)="$event.keyCode === 13 && goToOrder(crm.booking.id)" tabindex>Ver {{adminService.getText('checkin')}}</div>
          </div>
        } @else {
          <div class="subtitle">{{adminService.getText('booking')}}
            <div class="expander"></div>
            <div class="order-button" (click)="createBooking()" (keydown)="$event.keyCode === 13 && createBooking()" tabindex>Ver {{adminService.getText('booking')}}</div>
          </div>
        }
        <div class="info" *ngIf="crm.location">
          <div class="name">{{adminService.getText('location')}}</div>
          <div class="value">{{crm.location.name}}</div>
        </div>
        <div class="info">
          <div class="name">Profesional</div>
          <div class="value">{{crm.user?.name}}</div>
        </div>
        <div class="info" *ngIf="crm.user && crm.user.phone">
          <div class="name">Teléfono</div>
          <div class="value">{{crm.user?.phone}}</div>
        </div>
        <div class="info">
          <div class="name">Creación Agendamiento</div>
          <div class="value">{{crm.booking.created_at | date: 'short'}}</div>
        </div>
        @if (crm.booking.checked_in) {
          <div class="info hide-print last">
            <div class="name">Finalización</div>
            <div class="value" *ngIf="!crm.booking.finish_date && !crm.booking.completed_at">Sin fecha agendada</div>
            <div class="value" *ngIf="crm.booking.finish_date && !crm.booking.completed_at">Agendada al {{crm.booking.finish_date | date:'short'}}</div>
            <div class="value" *ngIf="crm.booking.completed_at">Finalizada el {{crm.booking.completed_at | date:'short'}}</div>
          </div>
        } @else {
          <div class="info hide-print last">
            <div class="name">Agendado para</div>
            <div class="value" *ngIf="!crm.booking.booking_date">Sin fecha agendada</div>
            <div class="value" *ngIf="crm.booking.booking_date">{{crm.booking.booking_date | date}}, {{ crm.booking.booking_time }}</div>
          </div>
        }
      </ng-container>

      @if(crm.last_order) {
        <div class="subtitle">Última Orden
          <div class="expander"></div>
          <div class="order-button" (click)="goToOrder(crm.last_order.id)" (keydown)="$event.keyCode === 13 && goToOrder(crm.last_order.id)" tabindex>Ver Orden</div>
        </div>
        <div class="info">
          <div class="name">{{adminService.getText('requirement')}}</div>
          <div class="value">{{crm.last_order.requirement?.name}}</div>
        </div>
        <div class="info">
          <div class="name">{{adminService.getText('comment')}}</div>
          <div class="value">{{crm.last_order.comment}}</div>
        </div>
        <div class="info hide-print last">
          <div class="name">Finalización</div>
          <div class="value" *ngIf="!crm.last_order.finish_date && !crm.last_order.completed_at">Sin fecha agendada</div>
          <div class="value" *ngIf="crm.last_order.finish_date && !crm.last_order.completed_at">Agendada al {{crm.last_order.finish_date | date:'short'}}</div>
          <div class="value" *ngIf="crm.last_order.completed_at">Finalizada el {{crm.last_order.completed_at | date:'short'}}</div>
        </div>
      }

      <mat-accordion>
        <mat-expansion-panel class="hide-print order-expansion-panel-works expansion-panel">
          <mat-expansion-panel-header>
              <div class="panel-title">
                <mat-icon>build_circle</mat-icon>
                Trabajos&nbsp;<span>({{crm.works?.length || 0}})</span>
              </div>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <app-order-works [order]="crm" class="hide-print" (load)="load()"></app-order-works>
          </mat-expansion-panel-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="no-access" *ngIf="forbidden">
    <i class="fa fa-warning"></i>
    No tienes acceso a esta información
  </div>
</div>


<mat-toolbar *ngIf="crm" class="footer">
  <div class="buttons">
    <button mat-raised-button (click)="addWork()" class="primary">
      <span><mat-icon>add</mat-icon></span>
    </button>
    <div class="expander"></div>
    <button mat-raised-button class="primary" [matMenuTriggerFor]="menu"  *ngIf="(roleService.can('update','crm') | async)"><i class="fa fa-pencil-alt"></i><span class="hide-mobile">{{ crm?.status?.name }}</span></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let status of statuses" (click)="setStatus(status.id)" [style.color]="status.color">{{status.name}}</button>
    </mat-menu>
  </div>
</mat-toolbar>
