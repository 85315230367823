import {
  Component, Input, OnInit,
} from '@angular/core';
import {
  FormArray, FormBuilder, FormGroup, UntypedFormGroup,
} from '@angular/forms';
import { generateInventoryActionFormGroup } from 'app/models/inventory_action.model';
import { InventoryActionService } from 'app/services/inventory-action.service';

@Component({
  selector: 'wpl-inventory-actions',
  templateUrl: './inventory-actions.component.html',
  styleUrls: ['./inventory-actions.component.scss'],
})
export class InventoryActionsComponent implements OnInit {
  @Input() clientMode = false;

  @Input() disabled = false;

  @Input() parentForm: UntypedFormGroup;

  @Input() request;

  @Input() value: any = {};

  error = false;

  form: FormGroup;

  constructor(private fb: FormBuilder, private inventoryActionService: InventoryActionService) {}

  ngOnInit(): void {
    if (!this.request.order.inventory_action_for_consume) {
      this.createInventoryAction();
    } else {
      this.form = generateInventoryActionFormGroup(this.request.order.inventory_action_for_consume);
    }

    if (!this.request.order.inventory_action_for_consume?.inventory_action_products?.length) {
      this.parentForm.setErrors({ inventoryAction: true });
    }
  }

  createInventoryAction(): void {
    this.inventoryActionService.create({
      inventory_action: {
        document_id: this.request.order.id,
        document_type: 'Order',
        type: 'InventoryActionForConsume',
        warehouse_id: this.request.order.warehouse_id,
        estimated_completion_date: this.request.order.finish_date,
      },
    })
      .subscribe((inventoryAction) => {
        if (!inventoryAction) {
          this.error = true;
          return;
        }

        this.error = false;
        this.request.order.inventory_action_for_consume = inventoryAction;
        this.form = generateInventoryActionFormGroup(inventoryAction);
      });
  }

  updateInventoryAction(): void {
    const inventoryActionRawData = this.form.getRawValue();
    // if (inventoryActionRawData.status.locked) return;

    const inventoryActionData = {
      ...inventoryActionRawData,
      document_id: this.request.order.id,
      document_type: 'Order',
      inventory_action_products_attributes: inventoryActionRawData.inventory_action_products,
      inventory_action_products: undefined,
    };

    if (!inventoryActionData.id) return;

    if (!inventoryActionRawData?.inventory_action_products?.length) {
      this.parentForm.setErrors({ inventoryAction: true });
    }

    this.inventoryActionService.update(
      inventoryActionData.id,
      inventoryActionData,
    )
      .subscribe((inventoryAction) => {
        if (!inventoryAction) {
          this.error = true;
        }

        (<FormArray> this.form.get('inventory_action_products')).controls.forEach(
          (control, index1) => {
          // eslint-disable-next-line no-underscore-dangle
            if (control.value._destroy) {
              (<FormArray> this.form.get('inventory_action_products')).removeAt(index1);
            } else {
              const inventoryActionProduct = (inventoryAction.inventory_action_products as any[]).find(
                (product) => product.product.code === control.getRawValue().code,
              );
              if (inventoryActionProduct) {
                control.patchValue(inventoryActionProduct, { emitEvent: false });
              }
            }
          },
        );

        this.request.order.inventory_action_for_consume = inventoryAction;
        if (!this.request.order.inventory_action_for_consume?.inventory_action_products?.length) {
          this.parentForm.setErrors({ inventoryAction: true });
        } else {
          this.parentForm.setErrors(null);
        }
      });
  }
}
