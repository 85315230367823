<mat-form-field>
  <mat-label>Tipo</mat-label>
  <mat-select placeholder="Tipo" [(ngModel)]="selectedInventoryActionType" (ngModelChange)="onTypeSelected()" required>
    @for(type of inventoryActionTypes; track type) {
      <mat-option [value]="type">{{adminService.getText(underscore(type), 'capitalize')}}</mat-option>
    }
  </mat-select>
</mat-form-field>

@if(!loading){
  <app-status-form
  type="StatusFor{{selectedInventoryActionType}}"
  [showStatuses]="true"
  [showNotifyClient]="true"
  [showTransitions]="true"
  ></app-status-form>
  <app-work-form [model]="selectedInventoryActionType"></app-work-form>
  <app-admin-custom-fields [service]="fieldsService" model="FieldFor{{selectedInventoryActionType}}"></app-admin-custom-fields>
  <app-client-notification-form [model]="selectedInventoryActionType"></app-client-notification-form>
}
