import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {BrandModelsService} from "../../services/brand-models.service";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-admin-models',
  templateUrl: './admin-models.component.html',
  styleUrls: ['./admin-models.component.scss','../admin-main.common.scss']
})
export class AdminModelsComponent implements OnInit {
  @Input() parentItem:any;
  @Output() close = new EventEmitter();
  form = this.fb.group({name: ''});
  id
  constructor(
    public modelsService: BrandModelsService,
    public fb: UntypedFormBuilder,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if(this.parentItem){
      this.id = this.parentItem.id;
    }else{
      this.route.params.subscribe(value => {
        this.id = value.brand_id;
      });
    }
  }
  goUp(){
    if(this.parentItem){
      this.close.emit();
    }else{
      this.router.navigate(['admin','main','brands']);
    }
  }

}
