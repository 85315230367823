<div class="logo">
  <i class="fa fa-wrench"></i> Administración
</div>
<button mat-button [routerLink]="'booking'" routerLinkActive="active"><i class="fa fa-book"></i><span class="label">Agendamiento</span></button>
<button mat-button [routerLink]="'check-lists'" routerLinkActive="active" ><i class="fa fa-check-square"></i><span class="label">Listas de Trabajo</span></button>
<button mat-button [routerLink]="'clients'" routerLinkActive="active"><i class="fa fa-users"></i><span class="label">Clientes</span></button>
<button mat-button [routerLink]="'objects'" routerLinkActive="active" *ngIf="company.object==='Custom'"><i class="fa fa-cubes"></i><span class="label">{{company.object_name || 'Objeto'}}s</span></button>
<button mat-button [routerLink]="'vehicles'" routerLinkActive="active" *ngIf="company.object==='Vehicle'"><i class="fa fa-car"></i><span class="label">Vehículos</span></button>
<button mat-button [routerLink]="'brands'" routerLinkActive="active" *ngIf="company.object==='Vehicle'"><i class="fa fa-list"></i><span class="label">Marcas y modelos</span></button>
<button mat-button [routerLink]="'lists'" routerLinkActive="active" ><i class="fa fa-list"></i><span class="label">Listas</span></button>
<button mat-button [routerLink]="'roles'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-user-plus"></i><span class="label">Roles</span></button>
<button mat-button [routerLink]="'orders'" routerLinkActive="active"><i class="fa fa-book"></i><span class="label">Ordenes de Trabajo</span></button>
<button mat-button [routerLink]="'users'" routerLinkActive="active"><i class="fa fa-users"></i><span class="label">Usuarios</span></button>
<button mat-button [routerLink]="'works'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-wrench"></i><span class="label">Trabajos</span></button>
<button mat-button [routerLink]="'budgets'" routerLinkActive="active" ><i class="fa fa-file-invoice-dollar"></i><span class="label">Presupuestos</span></button>
<button mat-button [routerLink]="'shares'" routerLinkActive="active" ><i class="fa fa-dollar-sign"></i><span class="label">Comisiones</span></button>
<button mat-button [routerLink]="'crms/booking'" routerLinkActive="active"><i class="fa fa-user-shield"></i><span class="label" *ngIf="roleService.can('read','admin') | async">Gestión de Clientes</span></button>
<button mat-button [routerLink]="'mailing'" routerLinkActive="active"><i class="fa fa-envelope"></i><span class="label">Mailing</span></button>
<button mat-button [routerLink]="'whatsapp'" routerLinkActive="active"><i class="fa fa-phone"></i><span class="label" *ngIf="roleService.can('read','admin') | async">Whatsapp</span></button>
<button mat-button [routerLink]="'apikeys'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-key"></i><span class="label">API Keys</span></button>
<button mat-button [routerLink]="'payments'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-dollar-sign"></i><span class="label">Pagos</span></button>
<button mat-button [routerLink]="'workpoint'" routerLinkActive="active"><i class="fa fa-wrench"></i><span class="label">Punto de Trabajo</span></button>
<button mat-button [routerLink]="'patpass'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-wallet"></i><span class="label">Pago Automático</span></button>
<button mat-button [routerLink]="'products'" routerLinkActive="active"><i class="fa fa-box"></i><span class="label">{{adminService.getText('product_plural')}}</span></button>
<button mat-button [routerLink]="'kits'" routerLinkActive="active"><i class="fa fa-toolbox"></i><span class="label">Kits</span></button>
<button mat-button [routerLink]="'inventory'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-cart-plus"></i><span class="label">{{adminService.getText('inventory')}}</span></button>
<button mat-button [routerLink]="'warehouses'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-home"></i><span class="label">{{adminService.getText('warehouse_plural')}}</span></button>
<div class="expander"></div>
<button mat-button [routerLink]="'translate'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-language"></i><span class="label">Traducciones</span></button>
<button mat-button [routerLink]="'wizard'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-magic"></i><span class="label">Wizard</span></button>
<button mat-button [routerLink]="'locations'" routerLinkActive="active" *ngIf="roleService.can('read','admin') | async"><i class="fa fa-home"></i><span class="label">{{heading}}</span></button>
<button mat-button [routerLink]="'company'" routerLinkActive="active"><i class="fa fa-cog"></i><span class="label">Configuración</span></button>
<button mat-button [routerLink]="['/main']"><i class="fa fa-sign-out-alt"></i><span class="label">Salir a ControlCar</span></button>
