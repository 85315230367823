import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from 'app/models/category.model';
import {
  BehaviorSubject, catchError, map, of,
  Subject,
} from 'rxjs';

import { BaseService } from './_base.service';
import { UserService } from './user.service';

@Injectable(
  {
    providedIn: 'root',
  },
)
export class CategoriesService extends BaseService {
  public categories = new BehaviorSubject<Category[]>([]);

  public categories$ = this.categories.asObservable();

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
    this.index().subscribe((response) => {
      this.categories.next(response);
    });
  }

  index() {
    return this.http.get<Category[]>(
      `${this.apiURL}/categories`,
      this.getParams(),
    ).pipe(
      map((response) => response.body),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
    );
  }
}
