<div class="planner">
    <div class="day-line" #dayline>
      <div class="day"></div>
      <div class="day" *ngFor="let day of days" [style.flex]="calculateDaylineWidth(day)">{{day.date}}</div>
    </div>
    <div class="hour-line" #hourline>
      <div class="hour"></div>
      <div class="hour" *ngFor="let hour of hours">{{hour}}</div>
      <div class="hour fill"></div>
    </div>
    <div class="wrapper-y">
      <div class="users">

        <div class="user" *ngFor="let user of users">
          <div class="user-avatar-container" *ngIf="user.id > 0">
            <div class="avatar" [ngStyle]="user?.picture ? {'background-image': 'url(' + user.picture + ')'} : {}">
              <ng-container *ngIf="!user?.picture">
                <span class="user-initials">{{ getInitials(user?.name || user.name) }}</span>
              </ng-container>
            </div>
            <div class="user-status" [class.online]="user.online"></div>
          </div>
          <div class="user-name">{{user.name}}</div>
        </div>

      </div>
      <div class="timeline" [style.height]="calculateHeight()" #timeline>
        <div class="user-line" *ngFor="let user of users" [style.width]="calculateTimelineWidth()" [attr.data-user]="user.id">
          <div class="work" *ngFor="let work of user.works" [style.flex]="work.width" [draggable]="!work.started_at" [attr.data-id]="work.id" [attr.data-user]="user.id" [class.dragging]="dragged">
            <div class="data" [class.started]="work.started_at" [class.late]="work.late">
              <div class="buttons">
                <div class="button" (click)="setUser(work)">
                  <i class="fa fa-user"></i>
                </div>
                <div class="button" (click)="openTime(work)">
                  <i class="fa fa-clock"></i>
                </div>
              </div>
              <div class="info" (click)="openWork(work)" [attr.data-id]="work.id" [attr.data-user]="user.id">
                <div class="identifier">{{work.identifier}}</div>
                <div class="requirement">{{work.requirement_name}}</div>
                <div class="work">{{work.work_name}}</div>
                <div class="client-name">{{work.client_name}}</div>
                
                <div class="timer" [class.expired]="work.finish_date < current_date"><i class="fa fa-clock"></i>{{work.time_left}}</div>
               <!--
                <div class="status-list">
                  <div class="plan-status" [style.color]="status.id | status:'color'" *ngFor="let status of statuses" [matTooltip]="status.name">
                    <i class="fa fa-check-square" *ngIf="getStatusCompletion(status.id,work) && status.id != work.status_id"></i>
                    <i class="fa fa-square" *ngIf="!getStatusCompletion(status.id,work) && status.id != work.status_id"></i>
                    <i class="fa fa-minus-square" *ngIf="status.id == work.status_id"></i>
                  </div>
                </div>
              -->
              </div>
              <div class="fader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
