<mat-sidenav-container class="wrapper">
  <mat-sidenav #sidenav class="sidenav" mode="push">
    <app-admin-sidemenu></app-admin-sidemenu>
  </mat-sidenav>

  <div class="sidenav-content">
    <mat-toolbar color="primary">
      <i class="fa fa-bars" (click)="sidenav.toggle()"></i>
      <div class="expander"></div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </div>

</mat-sidenav-container>
