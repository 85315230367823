import {
  Component, EventEmitter, Inject, Output,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent {
  @Output() submitClicked = new EventEmitter();

  public selected: boolean;

  file: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  onFileSelected(event) {
    // eslint-disable-next-line prefer-destructuring
    this.file = event.target.files[0];
    this.selected = true;
  }

  uploadDocument() {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        this.submitClicked.emit(fileReader.result);
      } else if (fileReader.result instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        this.submitClicked.emit(decoder.decode(fileReader.result));
      }
    };
    fileReader.readAsText(this.file);
  }
}
