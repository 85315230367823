import {Injectable} from '@angular/core';
import {BaseService} from "./_base.service";
import {UserService} from "./user.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AnalyticsService extends BaseService {
  public snapshots = {};
  protected apiPath = '/analytics/';
  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  get(name, filters?) {
    return this.http.post(this.apiURL + this.apiPath + name, filters, this.getOptions());
  }
  generate(values,filters,snapshot?){
    if(snapshot){
      console.log('Usando Snapshot',snapshot)
      return new Promise(resolve => {
        const data = this.snapshots[snapshot].data[values.custom]
        console.log(data);
        resolve(data);
      });
    }else{
      const body = {...values,...filters};
      return new Promise((resolve, reject) => {
        this.http.post(this.apiURL + this.apiPath + 'generate',body, this.getOptions()).toPromise().then(value => {
          resolve(value.body);
        }).catch(reason => {
          reject(reason);
        });
      });
    }
  }
  generateDetails(values,filters,snapshot?){
    if(snapshot){
      console.log('Usando Snapshot',snapshot)
      return new Promise(resolve => {
        const data = this.snapshots[snapshot].data[values.custom]
        console.log(data);
        resolve(data);
      });
    }else{
      const body = {...values,...filters};
      return new Promise((resolve, reject) => {
        this.http.post(this.apiURL + this.apiPath + 'generate_details',body, this.getOptions()).toPromise().then(value => {
          resolve(value.body);
        }).catch(reason => {
          reject(reason);
        });
      });
    }
  }
  snapshot(filters){
    return this.http.post(this.apiURL + '/analytics/generate_snapshot', {...filters}, this.getOptions());
  }
  getSnapshot(key){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + this.apiPath +'get_snapshot', {key: key}, this.getOptions()).toPromise().then(value => {
        this.snapshots[key] = value.body;
        resolve(value);
      }).catch(reason => {
        reject(reason);
      });
    });
  }
}
