import { Route } from '@angular/router';

import { CanActivateAdmin, CanActivateManage } from './admin.permissions';
import { CompanyLoginComponent } from './admin-login/company-login.component';
import { AdminApisComponent } from './admin-main/admin-apis/admin-apis.component';
import { AdminAutomationWizardComponent } from './admin-main/admin-automation-wizard/admin-automation-wizard.component';
import { AdminBookingComponent } from './admin-main/admin-booking/admin-booking.component';
import { AdminBrandsComponent } from './admin-main/admin-brands/admin-brands.component';
import { AdminBudgetsComponent } from './admin-main/admin-budgets/admin-budgets.component';
import { AdminCheckListComponent } from './admin-main/admin-check-list/admin-check-list.component';
import { AdminCheckListItemsComponent } from './admin-main/admin-check-list-items/admin-check-list-items.component';
import { AdminClientsComponent } from './admin-main/admin-clients/admin-clients.component';
import { AdminCompanyComponent } from './admin-main/admin-company/admin-company.component';
import { AdminCrmComponent } from './admin-main/admin-crm/admin-crm.component';
import { AdminInventoryComponent } from './admin-main/admin-inventory/admin-inventory.component';
import { AdminKitProductsComponent } from './admin-main/admin-kit-products/admin-kit-products.component';
import { AdminKitsComponent } from './admin-main/admin-kits/admin-kits.component';
import { AdminListsComponent } from './admin-main/admin-lists/admin-lists.component';
import { AdminLocationComponent } from './admin-main/admin-location/admin-location.component';
import { AdminMailingComponent } from './admin-main/admin-mailing/admin-mailing.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { AdminModelsComponent } from './admin-main/admin-models/admin-models.component';
import { AdminObjectComponent } from './admin-main/admin-object/admin-object.component';
import { AdminOrderFieldsComponent } from './admin-main/admin-order-fields/admin-order-fields.component';
import { AdminPatpassComponent } from './admin-main/admin-patpass/admin-patpass.component';
import { AdminPaymentComponent } from './admin-main/admin-payment/admin-payment.component';
import { AdminProductsComponent } from './admin-main/admin-products/admin-products.component';
import { AdminProductListComponent } from './admin-main/admin-products/product-list/product-list.component';
import { AdminRolesComponent } from './admin-main/admin-roles/admin-roles.component';
import { AdminSharesComponent } from './admin-main/admin-shares/admin-shares.component';
import { AdminTranslateComponent } from './admin-main/admin-translate/admin-translate.component';
import { AdminUsersComponent } from './admin-main/admin-users/admin-users.component';
import { AdminVehiclesComponent } from './admin-main/admin-vehicles/admin-vehicles.component';
import { AdminWarehousesComponent } from './admin-main/admin-warehouses/admin-warehouses.component';
import { AdminWhatsappComponent } from './admin-main/admin-whatsapp/admin-whatsapp.component';
import { AdminWizardComponent } from './admin-main/admin-wizard/admin-wizard.component';
import { AdminWorkPointComponent } from './admin-main/admin-work-point/admin-work-point.component';
import { AdminWorkflowComponent } from './admin-main/admin-workflow/admin-workflow.component';
import { AdminWorksComponent } from './admin-main/admin-works/admin-works.component';

export const adminRoutes: Route[] = [
  { path: 'login', component: CompanyLoginComponent },
  {
    path: 'main',
    component: AdminMainComponent,
    canActivate: [CanActivateManage],
    children: [
      { path: 'wizard', component: AdminWizardComponent, canActivate: [CanActivateAdmin] },
      { path: 'automation-wizard', component: AdminAutomationWizardComponent, canActivate: [CanActivateAdmin] },
      { path: 'booking', component: AdminBookingComponent },
      { path: 'brands', component: AdminBrandsComponent },
      { path: 'brands/:brand_id/models', component: AdminModelsComponent },
      { path: 'check-lists', component: AdminCheckListComponent },
      { path: 'check-lists/:check_list_id/items', component: AdminCheckListItemsComponent },
      { path: 'lists/:list_id/items', component: AdminClientsComponent },
      { path: 'clients', component: AdminClientsComponent },
      { path: 'lists', component: AdminListsComponent },
      { path: 'company', component: AdminCompanyComponent },
      { path: 'mailing', component: AdminMailingComponent },
      { path: 'roles', component: AdminRolesComponent, canActivate: [CanActivateAdmin] },
      { path: 'orders', component: AdminOrderFieldsComponent },
      { path: 'users', component: AdminUsersComponent },
      { path: 'vehicles', component: AdminVehiclesComponent },
      { path: 'translate', component: AdminTranslateComponent },
      { path: 'budgets', component: AdminBudgetsComponent },
      { path: 'shares', component: AdminSharesComponent },
      { path: 'products', component: AdminProductsComponent },
      { path: 'budgets/:category_id/products', component: AdminProductListComponent },
      { path: 'workflow', component: AdminWorkflowComponent, canActivate: [CanActivateAdmin] },
      { path: 'works', component: AdminWorksComponent, canActivate: [CanActivateAdmin] },
      { path: 'apikeys', component: AdminApisComponent, canActivate: [CanActivateAdmin] },
      { path: 'locations', component: AdminLocationComponent, canActivate: [CanActivateAdmin] },
      { path: 'crms/:crm_type', component: AdminCrmComponent },
      { path: 'payments', component: AdminPaymentComponent, canActivate: [CanActivateAdmin] },
      { path: 'workpoint', component: AdminWorkPointComponent },
      { path: 'patpass', component: AdminPatpassComponent },
      { path: 'objects', component: AdminObjectComponent },
      { path: 'whatsapp', component: AdminWhatsappComponent },
      { path: 'kits', component: AdminKitsComponent },
      { path: 'kits/:kit_id/products', component: AdminKitProductsComponent },
      { path: 'inventory', component: AdminInventoryComponent, canActivate: [CanActivateAdmin] },
      { path: 'warehouses', component: AdminWarehousesComponent, canActivate: [CanActivateAdmin] },
      { path: '**', redirectTo: 'company' },
    ],
  },
  { path: '**', redirectTo: 'main/wizard' },
];
