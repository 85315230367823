import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {RoleService} from "../role/role.service";
import {UserService} from "../services/user.service";
import { HttpResponse } from "@angular/common/http";
import {PrioritiesService} from "./priorities.service";
import {Router} from "@angular/router";
import {WorkDialogComponent} from "../work/work-dialog/work-dialog.component";
import {WorkService} from "../work/work.service";
import {OrderNotificationsComponent} from "../main/order-notifications/order-notifications.component";
import {OrderComponent} from "../main/order/order.component";
import {BudgetViewComponent} from "../budget/budget-view/budget-view.component";
import {CrmClientComponent} from "../crm/crm-client/crm-client.component";
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ClientComponent } from 'app/client/client.component';

@Component({
  selector: 'app-priorities',
  templateUrl: './priorities.component.html',
  styleUrls: ['./priorities.component.scss']
})
export class PrioritiesComponent implements OnInit {
  priorities_count = -1;
  priorities: any[] = [];
  @Input() workpoint = false;
  @Output() workCount = new EventEmitter()
  @Output() notificationCount = new EventEmitter()
  works:any[] = [];
  constructor(
    private db: AngularFireDatabase,
    private matDialog: MatDialog,
    private roleService: RoleService,
    private userService: UserService,
    private prioritiesService: PrioritiesService,
    private workService: WorkService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.loadWorks();
    this.db.object(`company/${this.userService.user$.getValue().company_id}/priorities`).valueChanges().subscribe((value:any) => {
      if(value && value.priorities){
        const count = value.priorities.filter(
          (p)=>p.user_id === this.userService.user$.getValue().id
        ).reduce((previousValue, currentValue, currentIndex, array) => {
          return previousValue += currentValue.count;
        },0);
        if(count !== this.priorities_count){
          this.loadPriorities();
          this.loadWorks();
        }
        this.priorities_count = count;
      }
      else{
        this.priorities_count = 0;
      }
    });
  }
  loadPriorities(){
    this.prioritiesService.all().then((priorities:HttpResponse<any[]>)=>{
      this.priorities = priorities.body;
      this.notificationCount.emit(this.priorities.filter((p)=>!p.readed).length);
    }).catch((error)=>{
      console.log(error);
    });
  }
  loadWorks(){
    this.workService.own().toPromise().then((response:HttpResponse<any>)=>{
      this.works = response.body.items;
      this.workCount.emit(this.works.length);
    }).catch((error)=>{
      console.log(error);
    });
  }
  openPriority(priority){
    const index = this.priorities.findIndex((p)=>p === priority);
    if(index >= 0){
      if(!this.priorities[index].readed){
        this.priorities[index].readed = true;
        this.prioritiesService.complete(priority.id);
      }
      switch(priority.model_class){
        case 'WorkAssignation':
          this.matDialog.open(WorkDialogComponent,{data: {id: priority.model_id, type: 'WorkInstance'}, minWidth: '80%'})
            .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          break;
        case 'OrderNotification':
          if(!this.workpoint){
            this.router.navigate(['main','orders',priority.model_id]);
          }
          this.matDialog.open(OrderNotificationsComponent,{data: {order_id: priority.model_id}, minWidth: '80%'})
            .afterClosed().toPromise().then(()=>{
            this.loadPriorities();
          });
          break;
        case 'WorkRequest':
          this.matDialog.open(WorkDialogComponent,{data: {id: priority.model_id, type: 'WorkInstance'}, minWidth: '80%'})
            .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          break;
        case 'UserAssignation':
          this.matDialog.open(WorkDialogComponent,{data: {id: priority.model_id, type: 'WorkInstance'}, minWidth: '80%'})
            .afterClosed().toPromise().then(()=>{
            this.loadPriorities();
          });
          break;
        case 'OrderComment':
          if(this.workpoint){
            this.matDialog.open(OrderComponent, {data: priority.content});
          }else{
            this.router.navigate(['main','orders',priority.content],{queryParams: {goto: 'comments', id: priority.model_id}});
          }
          break;
        case 'Comment':
          if(priority.notification_type==='Order'){
            if(this.workpoint){
              this.matDialog.open(OrderComponent, {data: priority.content}).afterClosed().toPromise().then(()=>{
                this.loadPriorities();
              });
            }else{
              this.router.navigate(['main','orders',priority.content],{
                queryParams: {goto: 'comments', id: priority.model_id}
              });
            }
          }else if(priority.notification_type==='WorkInstance'){
            this.matDialog.open(WorkDialogComponent,{data: {id: priority.content, type: 'WorkInstance'}, minWidth: '80%'})
              .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          }else if(priority.notification_type==='Budget'){
            this.matDialog.open(BudgetViewComponent,{data: {id: priority.content, type: 'Budget'}, minWidth: '80%'})
              .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          }else if(priority.notification_type==='Client'){
            this.matDialog.open(CrmClientComponent,{data: {id: priority.content, type: 'Budget'}, minWidth: '80%'})
              .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          }
          break;
        case 'Client':
          this.matDialog.open(CrmClientComponent,{data: {id: priority.model_id, type: 'Client'}, minWidth: '80%'})
            .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          break;
        case 'Event':
          if(priority.notification_type==='Order' || priority.notification_type==='info') {
            if(this.workpoint){
              this.matDialog.open(OrderComponent, {data: priority.model_id});
            } else{
              this.router.navigate(['main', 'orders', priority.model_id]);
            }
          }else if(priority.notification_type==='WorkInstance'){
            this.matDialog.open(WorkDialogComponent,{data: {id: priority.model_id, type: 'WorkInstance'}, minWidth: '80%'})
              .afterClosed().toPromise().then(()=>{
              this.loadPriorities();
            });
          }
          break;
        case 'Recall':
          if(this.workpoint){
            this.matDialog.open(OrderComponent, {data: priority.model_id});
          } else{
            this.router.navigate(['main', 'orders', priority.model_id]);
          }
          break;
        default:
          break;
      }
    }
  }
  openWork(id,type){
    this.matDialog.open(WorkDialogComponent,{data: {id: id, type: type}, minWidth: '80%'}).afterClosed().toPromise().then(()=>{
      this.loadWorks();
    });
  }
}
