import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ChartFiltersService {
  private filtersSubject = new BehaviorSubject<any>(null);
  filters$ = this.filtersSubject.asObservable();

  updateFilters(newFilters) {
    this.filtersSubject.next(newFilters);
  }

  resetFilters() {
    this.filtersSubject.next(null);
  }
}
