import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class VehiclesService extends BaseService implements ICRUDService{
  params = ['client_id', 'brand_id', 'brand_model_id', 'color', 'identifier', 'chasis','custom_fields','kms']
  model = 'Vehicle';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null, page = 1){
    return this.http.get(this.adminURL+'/vehicles',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/vehicles/',{
      vehicle: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/vehicles/'+id,{
      vehicle: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/vehicles/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/vehicles/'+id+'/restore',this.getParams());
  }


}
