import {NgModule} from '@angular/core';
import {PaymentsComponent} from './payments.component';
import {PaymentComponent} from './payment/payment.component';
import {BaseModule} from "../shared/base.module";


@NgModule({
  declarations: [PaymentsComponent, PaymentComponent],
  imports: [
    BaseModule,
  ]
})
export class PaymentsModule { }
