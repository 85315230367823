<mat-toolbar>
  <div>
    <div *ngIf="display==='day'" class="back" (click)="openMonth()" matTooltip="Atrás" matTooltipPosition="right">
      <mat-icon >arrow_back_ios_new</mat-icon>
    </div>
  </div>

  <button mat-button class="primary" [matMenuTriggerFor]="calendarMenu" *ngIf="display!=='day'" >
  <mat-icon style="margin: 0;">calendar_month</mat-icon> <span class="display-desktop" style="margin-left: 3px;">{{ selectedCalendar }}</span> 
  </button>

  <mat-menu #calendarMenu="matMenu">
    <button mat-menu-item (click)="setCalendar('deliveries')">Calendario de Producción</button>
    <button mat-menu-item (click)="setCalendar('events')">Calendario de Eventos</button>
  </mat-menu>

  <!--
  <mat-form-field class="calendar-type display-desktop">
    <mat-select (valueChange)="setCalendar($event)" [(ngModel)]="type">
      <mat-option value="deliveries">
        Calendario de Producción
      </mat-option>
      <mat-option value="events">
        Calendario de Eventos
      </mat-option>
    </mat-select>
  </mat-form-field>
-->
  <div class="expander"></div>
  <button mat-button (click)="previous()" *ngIf="!working">
    <i class="fa fa-arrow-left" ></i>
  </button>
  <span class="title" *ngIf="display==='day'">{{now | date:'dd/LL'}}</span>
  <button mat-button (click)="next()" *ngIf="!working">
    <i class="fa fa-arrow-right"></i>
  </button>
  <button mat-button *ngIf="working">
    <i class="fa fa-circle-notch fa-spin"></i>
  </button>
</mat-toolbar>
<div class="date-names" > 

  <div class="hours" *ngFor="let date of dateNames; let last = last" [class.last]="last">
  
    <div class="title">
      {{date | date:'MMMM'}}
      <div class="expander"></div>

      @if (display === 'day') {
        <div class="day-current">
          <span class="label display-desktop">Facturado</span>
          <div>{{ dayCurrent | money }}</div>
        </div>
        <div class="day-total">
          <span class="label display-desktop">Producción</span>
          <div> /{{ dayTotal | money }}</div>
        </div>   
      }   

    </div>
  </div>
</div>
<div class="dates" *ngIf="display=='month'">
  <mwl-calendar-month-view (beforeViewRender)="beforeMonthViewRender($event)" [viewDate]="month" [excludeDays]="excludeDays"   [events]="events" (dayClicked)="openDay($event)" locale="es-ES" (eventClicked)="eventClick($event)" [refresh]="refresh"></mwl-calendar-month-view>
</div>
<div class="dates" *ngIf="display=='week'">
  <mwl-calendar-week-view (eventTimesChanged)="eventTimesChanged($event)" [viewDate]="month" [excludeDays]="excludeDays" [refresh]="refresh" [events]="events" locale="es-ES" (eventClicked)="eventClick($event)"></mwl-calendar-week-view>
</div>
<div class="dates" *ngIf="display==='day'">
  <div class="hours">
    <div class="hour" *ngFor="let event of dayEvents">
      <div class="time" *ngIf="event.meta.ended_at || event.meta.start_date">{{event.start | date:'shortTime'}}</div>
      <div class="time" *ngIf="!event.meta.ended_at && !event.meta.start_date">{{event.start | date:'HH:mm'}}</div>
      <div class="bookings">
        <div class="name" [class.checked-in]="event.meta.ended_at || event.meta.completed_at" (click)="eventClick({event: event})">
          <i class="fa fa-car"></i> <span class="event-title">{{event.meta.identifier || event.title}}</span>
        </div>
        <div class="requirement" *ngIf="event.meta.status_name">{{event.meta.status_name}}</div>
        <div class="value" *ngIf="event.meta.order_total >= 0">{{event.meta.order_total | money}}</div>
      </div>
    </div>
  </div>
</div>
<div class="float-button search">
  <div class="list">
    <div class="finding" *ngFor="let item of findings; let last = last" (click)="eventClick({event: {meta: item}})" [class.last]="last">
      <div class="time">
        <div class="hour">{{item.duration}} H</div>
        <div class="day">{{(item.start_date || item.ended_at || item.finish_date) | date:'dd/LL/HH'}}</div>
      </div>
      <div class="identifier">
        <div class="number">
          {{item.eventable?.vehicle?.identifier || item.identifier}}
        </div>
        <div class="req">{{item.name}}</div>
      </div>
    </div>
  </div>
  <div class="bar">
    <i class="fa fa-search"></i><input type="text" [formControl]="searchControl" name="search" autocomplete="off">
  </div>
</div>
<!--
  <div class="float-button" *ngIf="(roleService.can('create','event') | async) && dates[0] >= currentDate">
    <button mat-fab color="accent" (click)="createNewEvent()"><i class="fa fa-plus"></i></button>
  </div>
-->
