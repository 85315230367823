import {NgModule} from "@angular/core";
import {BaseModule} from "../base.module";
import {OrderWorksComponent} from "./order-works.component";

@NgModule({
  declarations: [
    OrderWorksComponent
  ],
  imports: [
    BaseModule
  ],
  exports: [
    OrderWorksComponent
  ]
})
export class WorksAddonsModule {}
