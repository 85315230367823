import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-order-date-range',
  templateUrl: './order-date-range.component.html',
  styleUrls: ['./order-date-range.component.scss']
})
export class OrderDateRangeComponent implements OnInit {
  filters:any = {
    start_check_in: null,
    end_check_in: null,
    start_ended_at: null,
    end_ended_at: null
  }
  constructor(
    private matDialogRef: MatDialogRef<OrderDateRangeComponent>
  ) { }

  ngOnInit(): void {
  }
  export(){
    this.matDialogRef.close(this.filters);
  }
  closeDialog() {
    this.matDialogRef.close();
  }

}
