import {NgModule} from "@angular/core";
import {BaseModule} from "../base.module";
import {RoleModule} from "../../role/role.module";
import {SortComponent} from "./sort.component";
import {SortableTableDirective} from "./sortable-table.directive";

@NgModule({
  declarations: [
    SortComponent,
    SortableTableDirective
  ],
  imports: [
    BaseModule
  ],
  exports: [
    SortComponent,
    SortableTableDirective
  ]
})
export class SortableModule{}
