<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-users fa-2x"></i></div>
    <mat-card-title>Usuarios</mat-card-title>
    <mat-card-subtitle>Administre los códigos de acceso</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="usersService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <div class="row" style="margin-bottom: 10px">
            <div class="col">
              <mat-form-field>
                <mat-label>Codigo de Ingreso</mat-label>
                <input matInput placeholder="Codigo de ingreso" formControlName="workpoint_code" name="pasword" autocomplete="off" required minlength="4">
                <mat-hint>Este código debe ser único entre los usuarios</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>

<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Administración de Punto de Trabajo</mat-card-title>
    <mat-card-subtitle>Configuración general</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="hours">
          <form [formGroup]="companyForm">
            <fieldset formGroupName="preferences">
              <mat-form-field>
                <mat-label>Tiempo de inactividad</mat-label>
                <input matInput type="number" placeholder="Tiempo de inactividad" formControlName="workpoint_logout_time" min="0" pattern="\d*"/>
                <mat-hint>Segundos de actividad para desconexión de usuario. 0 infinito</mat-hint>
              </mat-form-field>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <button mat-raised-button color="primary" style="width: 100%" (click)="startWorkPoint()" [disabled]="starting" *ngIf="!workpointURL">Iniciar punto de trabajo</button>
      <div class="workpoint-url" *ngIf="workpointURL">
        <span>Usar el siguiente enlace para configurar tu Punto de Trabajo.</span>
        <span class="link">{{workpointURL}}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>

