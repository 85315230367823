
<div class="empty" *ngIf="order && order.works && order.works.length == 0">
  Sin trabajos asignados
</div>
<div class="order-works-stepper">
  <mat-stepper orientation="vertical">
    @for (work of order.works; track work.id) {
      <mat-step [class.completed]="work.completed_at" completed="false">
        <ng-template matStepLabel>
          <div class="step-header">
            <span>{{work.name}} <span *ngIf="work.comment && work.comment.length > 0">: {{work.comment}}</span></span>
            <br>
            <div class="subtitle" [class.achieved]="work.completed_at">
              <span *ngIf="work.completed_at" [matTooltip]="'Completado'"><i class="fas fa-check-circle"></i> {{work.completed_at | date:'short'}}</span>
              <span *ngIf="!work.completed_at">Pendiente</span>
              @if (work.comment_count>0) {
                <div class="comment-container"> 
                  <span class="comments">
                    &nbsp;&nbsp;<i class="fas fa-comment"></i>
                  </span>
                  <span class="counter">{{work.comment_count}}</span>
                </div>
                
              }
            </div>
          </div>
        </ng-template>
        <ng-template matStepContent>

          <div class="row step-content" (click)="openWork(work)">
            <div class="col">
              <div class="content">
                <span><i class="fa fa-calendar"></i> Creado {{work.created_at | date:'short'}}</span>
                <br>
                <i *ngIf="work.type == 'WorkAssignation'" class="fa fa-user" ></i>
                <i *ngIf="work.type != 'WorkAssignation'" class="fa fa-wrench"></i>
                <span> {{work.user_name || 'Automático'}}</span>
                <br>
                <span><i class="fa fa-tag"></i> {{work.status_name}}</span>
              </div>
        
            </div>
            <div class="col-icon">
              <mat-icon>visibility</mat-icon><mat-icon>arrow_forward_ios</mat-icon> 
            </div>
          </div>

        </ng-template>
      </mat-step>
    }
  </mat-stepper>
</div>