import { Pipe, PipeTransform } from '@angular/core';
import { AdminService } from 'app/admin/services/admin.service';
import { ListsService } from 'app/admin/services/lists.service';

@Pipe({
  name: 'objectName'
})
export class ObjectNamePipe implements PipeTransform {
  constructor(
    private appService: AdminService,
    private listService: ListsService
  ) {
  }
  transform(value: any,optional?: any): any {
    const key = this.appService.local$.getValue().preferences.object_display;
    if (value && key && value[key]) {
      return value[key];
    }else if(value && value.custom_fields && value.custom_fields[key]){
      return this.listService.objectList(key,value.custom_fields);
    }else if(value && value.identifier){
      return value.identifier;
    }else if (optional){
      return optional
    }else{
      return value
    }
  }
}
