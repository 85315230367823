<div class="bookings" *ngFor="let booking of mails; let index = index">
  <div class="info">
    <div class="name">ID</div>
    <div class="value">{{booking.id}}</div>
  </div>
  <div class="info">
    <div class="name">Local</div>
    <div class="value">{{booking.data?.location?.name}}</div>
  </div>
  <div class="info">
    <div class="name">RUT</div>
    <div class="value">{{booking.data?.client?.rut || 'Sin rut'}}</div>
  </div>
  <div class="info">
    <div class="name">Nombre</div>
    <div class="value">{{booking.data?.client?.name || 'Sin cliente'}}</div>
  </div>
  <div class="info">
    <div class="name">Patente</div>
    <div class="value">{{booking.data?.vehicle?.plate || booking.data?.vehicle?.identifier || 'Sin patente'}}</div>
  </div>
  <div class="info">
    <div class="name">Requerimiento</div>
    <div class="value">{{booking.data?.requirement?.name}}</div>
  </div>
  <div class="info">
    <div class="name">Fecha</div>
    <div class="value">{{booking.data?.booking?.booking_date}}</div>
  </div>
  <div class="info">
    <div class="name">Hora</div>
    <div class="value">{{booking.data?.booking?.booking_time}}</div>
  </div>
  <div class="buttons">
    <button mat-raised-button class="primary" (click)="removeMail(booking)">Descartar</button>
    <button mat-raised-button class="secondary" (click)="addBooking(booking)" *ngIf="!booking.is_booked">Agendar</button>
    <button mat-raised-button *ngIf="booking.is_booked" (click)="openBooking(booking)"><i class="fa fa-check"></i>Agendado</button>
  </div>
</div>
