import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,
} from '@angular/forms';
import { AdminService } from 'app/admin/services/admin.service';
import { WarehousesService } from 'app/admin/services/warehouses.service';
import { Location } from 'app/models/location.model';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

import { LocationService } from '../../services/location.service';

@Component({
  selector: 'app-admin-location',
  templateUrl: './admin-location.component.html',
  styleUrls: ['./admin-location.component.scss', '../admin-main.common.scss'],
})
export class AdminLocationComponent implements OnInit, OnDestroy {
  formGroup = this.fb.group({
    name: this.fb.control('', Validators.required),
    address: this.fb.control('', Validators.required),
    warehouse_id: this.fb.control(null, Validators.required),
  });

  days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  workingDays = new UntypedFormGroup({
    work_days: new UntypedFormArray([]),
  });

  bookingDays = new UntypedFormGroup({
    days: new UntypedFormArray([]),
  });

  workHours = new UntypedFormGroup({
    time_interval: new UntypedFormControl(),
    max_bookings: new UntypedFormControl(),
    max_bookings_per_day: new UntypedFormControl(),
    mailing: this.fb.group({
      address: null,
      phone: null,
      webpage: null,
      facebook: null,
      instagram: null,
      email: null,
    }),
  });

  locations = [];

  locationId = null;

  subscriptions = [];

  email;

  warehouses$ = this.warehouseService.warehouses$;

  constructor(
    private fb: UntypedFormBuilder,
    public companyService: AdminService,
    public locationService: LocationService,
    public warehouseService: WarehousesService,
  ) {
    for (let i = 0; i < 7; i += 1) {
      const dayForm = new UntypedFormGroup({
        enabled: new UntypedFormControl(false, Validators.required),
        start_time: new UntypedFormControl('08:00', Validators.required),
        end_time: new UntypedFormControl('18:00', Validators.required),
        max_hours: new UntypedFormControl(''),
      });
      const workdayForm = new UntypedFormGroup({
        enabled: new UntypedFormControl(false, Validators.required),
        start_time: new UntypedFormControl('08:00', Validators.required),
        end_time: new UntypedFormControl('18:00', Validators.required),
        max_hours: new UntypedFormControl(''),
      });
      (<UntypedFormArray> this.workingDays.controls.work_days).push(workdayForm);
      (<UntypedFormArray> this.bookingDays.controls.days).push(dayForm);
    }
  }

  ngOnInit() {
    this.email = this.companyService.local$.getValue().email_hash;
    this.locationService.all().subscribe((response: HttpResponse<Location[]>) => {
      this.locations = response.body;
      if (this.locations.length > 0) {
        this.locationId = this.locations[0].id;
        this.setLocation(this.locationId);
      }
    });
  }

  formSubscribe() {
    this.subscriptions = [];
    this.subscriptions.push(this.workHours.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.locationService.update(this.locationId, { preferences: value }).toPromise().then((response) => {
        this.updateLocation(response.body);
      });
    }));
    this.subscriptions.push(this.workingDays.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.locationService.update(this.locationId, { preferences: value }).toPromise().then((response) => {
        this.updateLocation(response.body);
      });
    }));
    this.subscriptions.push(this.bookingDays.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.locationService.update(this.locationId, { preferences: value }).toPromise().then((response) => {
        this.updateLocation(response.body);
      });
    }));
  }

  setLocation(id) {
    this.locationId = id;
    this.patchForms(this.getLocation(id));
  }

  updateLocation(value) {
    const index = this.locations.findIndex((l) => l.id === this.locationId);
    this.locations[index] = value;
    this.patchForms(value);
  }

  patchForms(value) {
    this.ngOnDestroy();
    this.workingDays.patchValue(value.preferences);
    this.bookingDays.patchValue(value.preferences);
    this.workHours.patchValue(value.preferences);
    this.formSubscribe();
  }

  getLocation(id) {
    return this.locations.find((l) => l.id === id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
