<mat-card>
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-user-plus fa-2x"></i></div>
    <mat-card-title>Roles</mat-card-title>
    <mat-card-subtitle>Administre el acceso y permisos de los usuarios</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [formTemplate]="role" [service]="roleService" [formGroup]="formGroup" [minWidth]="'90%'"></ngx-crud-manager>
    <ng-template #role  let-form="form" let-item="item">
      <form [formGroup]="form">
        <mat-form-field>
          <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
        </mat-form-field>
        <app-role class="cc-dark" [parentForm]="form"></app-role>
      </form>

    </ng-template>

  </mat-card-content>
</mat-card>
