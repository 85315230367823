import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ListsService } from 'app/admin/services/lists.service';
import { Role } from 'app/models/types';
import { RoleService } from 'app/role/role.service';
import { BaseService } from 'app/services/_base.service';

@Component({
  selector: 'app-admin-custom-fields',
  templateUrl: './admin-custom-fields.component.html',
  styleUrls: ['../admin-main.common.scss', './admin-custom-fields.component.scss'],
})
export class AdminCustomFieldsComponent implements OnInit {
  formParams = this.fb.group({
    title: ['', Validators.required],
    required: false,
    show_client: false,
    show_booking: this.fb.control(true, { nonNullable: true }),
    require_booking: this.fb.control(true, { nonNullable: true }),
    field_type: ['text', Validators.required],
    field_filter: [],
    hide_optional: false,
  });

  types = [
    {
      id: 'text',
      name: 'Texto',
    },
    {
      id: 'number',
      name: 'Número',
    },
    {
      id: 'date',
      name: 'Fecha',
    },
    {
      id: 'boolean',
      name: 'Si/No',
    },
    {
      id: 'datetime',
      name: 'Fecha y Hora',
    },
    {
      id: 'users',
      name: 'Usuarios',
    },
    {
      id: 'image',
      name: 'Imagen',
    }];

  roles = [];

  @Input({ required: true }) model: string;

  @Input({ required: true }) service: BaseService;

  constructor(
    private fb: FormBuilder,
    private listsService: ListsService,
    private roleService: RoleService,
  ) {}

  ngOnInit(): void {
    this.roleService.simple_index().toPromise().then((value: HttpResponse<Role[]>) => {
      this.roles = value.body;
    });
    this.listsService.all().toPromise().then((value: HttpResponse<any[]>) => {
      this.types = this.types.concat(value.body.map((l:any) => ({
        id: `list${l.id}`,
        name: l.name,
      })));
    });
  }
}
