import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/_base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService{
  constructor(
    public http:HttpClient,
  ) {
    super(http,null);
  }
  get(){
    return this.http.get(this.managerURL+'/companies',this.getParams());
  }
  create(value){
    return this.http.post(this.managerURL+'/companies',{
      company: this.permitParams(value,'name','domain')
    },this.getParams());
  }
  delete(id){
    return this.http.delete(this.managerURL+'/companies/'+id,this.getParams());
  }
}