import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-work-point-works',
  templateUrl: './work-point-works.component.html',
  styleUrls: ['./work-point-works.component.scss']
})
export class WorkPointWorksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
