import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductStockLocationItem } from 'app/inventory/products/details/inventory-product-details.component';
import { PaginatedItems } from 'app/models/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Product } from '../models/product.model';
import { BaseService } from './_base.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseService {
  private products$: BehaviorSubject<Product[]> = new BehaviorSubject([]);

  select(
    searchValue?: string,
    categoryId?: number,
    brandId?: number,
    page?: number,
    page_size?: number,
    sort?: string,
  ): Observable<PaginatedItems<Product>> {
    return this.http.get<PaginatedItems<Product>>(
      `${this.apiURL}/products/select`,
      this.getParams({
        search: searchValue,
        category_id: categoryId,
        brand_id: brandId,
        page,
        page_size,
        sort,
      }),
    )
      .pipe(
        map((response: HttpResponse<PaginatedItems<Product>>) => response.body),
      );
  }

  all(): Observable<HttpResponse<Product[]>> {
    return this.http.get<Product[]>(`${this.apiURL}/products/all`, this.getParams());
  }

  create(data): Observable<Product> {
    return this.http
      .post<Product>(`${this.apiURL}/products`, data, this.getParams())
      .pipe(map((response: HttpResponse<Product>) => response.body));
  }

  get(id: number, categoryId?: number): Observable<Product> {
    return this.http
      .get<Product>(`${this.apiURL}/products/${id}`, this.getParams({ category_id: categoryId }))
      .pipe(map((response: HttpResponse<Product>) => response.body));
  }

  stockLocation(id: number) {
    return this.http
      .get<ProductStockLocationItem[]>(`${this.apiURL}/products/${id}/stock_location`, this.getParams())
      .pipe(map((response: HttpResponse<ProductStockLocationItem[]>) => response.body));
  }

  get allProducts(): Observable<Product[]> {
    return this.products$.asObservable();
  }
}
