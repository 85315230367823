import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.zendesk.hide();
  }

}
