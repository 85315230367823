import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'app/admin/services/admin.service';
import { LocationService } from 'app/admin/services/location.service';
import { WarehousesService } from 'app/admin/services/warehouses.service';
import { Location } from 'app/models/location.model';

@Component({
  selector: 'app-admin-warehouses',
  templateUrl: './admin-warehouses.component.html',
  styleUrls: ['./admin-warehouses.component.scss', '../admin-main.common.scss'],
})
export class AdminWarehousesComponent {
  form = this.fb.group({
    name: this.fb.control('', [Validators.required]),
  });

  locations: Location[];

  constructor(
    private fb: UntypedFormBuilder,
    public companyService: AdminService,
    public locationService: LocationService,
    public warehousesService: WarehousesService,
  ) {}
}
