<div class="snapshot-title" *ngIf="snapshot">
  Reporte Automático
  <div class="expander"></div>
  <div class="subtitle">
    {{filter.start_at | date:'shortDate'}} hasta {{filter.end_at | date:'shortDate'}}
  </div>
</div>
<app-chart-filters *ngIf="!snapshot"></app-chart-filters>
<div class="wrapper" *ngIf="!working">
  <ng-container>
    <app-chart-card
      chartKey="orders_by_week"
      [mainTitle]="adminService.getText('checkin_plural')"
      [mainSubtitle]="adminService.getText('object_plural')"
      [customCharts]="[{key: 'missing_by_week', name: 'Ausencias', type: 'column'},{key: 'orders_by_week',name: 'Vehiculos'},{key: 'acum_orders_by_week', name: 'AVG', type: 'spline'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      [stacked]="true"
      valueKey="performance"
      displayMode="avg_orders__1.1-1__%" />
    <app-chart-card
      chartKey="orders_by_status"
      mainTitle="Inventario"
      [mainSubtitle]="adminService.getText('object_plural')"
      [customCharts]="[{key: 'orders_by_status'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      displayMode="total__1.0-0" />
    <app-chart-card
      chartKey="delivered_orders"
      mainTitle="Unidades entregadas"
      mainSubtitle="Total"
      [customCharts]="[{key: 'delivered_orders', name: 'Unidades', type: 'spline'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      chartType="spline"
      displayMode="total__1.0-0" />
    <app-chart-card
      chartKey="finances_avg_ticket_by_requirement"
      mainTitle="Ingreso"
      mainSubtitle="Promedio"
      [customCharts]="[{key: 'finances_avg_ticket_by_requirement'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      dataLabelSuffix="K"
      displayMode="avg__1.2-2__k" />
    <app-chart-card
      chartKey="finances_total_ticket_by_requirement"
      mainTitle="Ingreso"
      mainSubtitle="Total"
      [customCharts]="[{key: 'finances_total_ticket_by_requirement'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      dataLabelSuffix="M"
      displayMode="total__1.2-2__M" />
    <app-chart-card
      chartKey="finances_total_by_period"
      mainTitle="Facturación"
      mainSubtitle="Total"
      [customCharts]="[{key: 'finances_total_by_period', name: 'Facturado', type: 'column'},{key: 'finances_total_by_estimated', name: 'Estimado', type: 'spline'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      chartType="spline"
      performanceKey="value"
      dataLabelSuffix="M"
      displayMode="diff__1.2-2__M" />
    <app-chart-card
      chartKey="total_works_by_user"
      mainTitle="Trabajos Pendientes"
      mainSubtitle="Total"
      [customCharts]="[{key: 'total_works_by_user', name: 'Trabajos', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      displayMode="total__1.0-0"
      detailType="work" />
    <app-chart-card
      chartKey="total_completed_works_by_user"
      mainTitle="Trabajos Completados"
      mainSubtitle="Total"
      [customCharts]="[{key: 'total_completed_works_by_user', name: 'Trabajos', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      displayMode="total__1.0-0"
      detailType="work" />
    <app-chart-card
      chartKey="rejections_by_user"
      mainTitle="Trabajos Rechazados"
      mainSubtitle="Total"
      [customCharts]="[{key: 'rejections_by_user', name: 'Trabajos', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      displayMode="total__1.0-0" />
    <app-chart-card
      chartKey="total_work_time_by_requirement"
      mainTitle="Tiempo Ejecución"
      mainSubtitle="Promedio"
      [customCharts]="[{key: 'total_work_time_by_requirement'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      [hasDetails]="false"
      displayMode="avg__1.2-2__HRS" />
    <app-chart-card
      chartKey="avg_time_by_user"
      mainTitle="Tiempo Ejecución"
      mainSubtitle="Promedio"
      [customCharts]="[{key: 'avg_time_by_user', name: 'Trabajos', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      [hasDetails]="false"
      displayMode="avg__1.2-2__HRS" />
    <app-chart-card
      chartKey="avg_rating_by_user"
      mainTitle="Valoración Ejecución"
      mainSubtitle="Promedio"
      [customCharts]="[{key: 'avg_rating_by_user', name: 'Trabajos', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      [hasDetails]="false"
      displayMode="avg__1.2-2__%" />
    <app-chart-card
      chartKey="approved_budgets"
      mainTitle="Presupuestos"
      mainSubtitle="Aprobados/Rechazados"
      [customCharts]="[{key: 'approved_budgets', name: 'Aprobados', type: 'column'},{key: 'declined_budgets', name: 'Denegados', type: 'column'}]"
      [filters]="filter"
      [stacked]="true"
      [snapshot]="snapshot"
      valueKey="performance_percent"
      displayMode="diff__1.0-0__%"
      detailType="budget" />
    <app-chart-card
      chartKey="offered_budgets"
      mainTitle="Presupuestos"
      mainSubtitle="Tasa de ofrecimiento"
      [customCharts]="[{key: 'offered_budgets', name: 'Tasa (%)', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      displayMode="avg__1.0-0__%"
      detailType="budget" />
    <app-chart-card
      chartKey="budget_avg_tickets"
      mainTitle="Presupuestos"
      mainSubtitle="Ingreso Promedio"
      [customCharts]="[{key: 'budget_avg_tickets', name: 'Valor', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      dataLabelSuffix="K"
      valueKey="performance_percent"
      displayMode="avg__1.2-2__K" />
    <app-chart-card
      chartKey="valued_orders"
      mainTitle="Ordenes"
      mainSubtitle="Con Valor/Sin Valor"
      [customCharts]="[{key: 'valued_orders', name: 'Pagadas', type: 'column'},{key: 'free_orders', name: 'No pagadas', type: 'column'}]"
      [filters]="filter"
      [stacked]="true"
      [snapshot]="snapshot"
      valueKey="performance_percent"
      [hasDetails]="false"
      displayMode="diff__1.0-0__%" />

    <app-chart-card
      chartKey="orders_with_additionals"
      mainTitle="Ordenes"
      mainSubtitle="Con Adicionales/Sin Adicionales"
      [customCharts]="[{key: 'orders_with_additionals', name: 'Con adicionales', type: 'column'},{key: 'orders_without_additionals', name: 'Sin adicionales', type: 'column'}]"
      [filters]="filter"
      [snapshot]="snapshot"
      [stacked]="true"
      valueKey="performance_percent"
      [hasDetails]="false"
      displayMode="diff__1.0-0__%" />
    <app-chart-card
      chartKey="mailing_openings"
      mainTitle="Correos"
      mainSubtitle="Tasa de apertura"
      [customCharts]="[{key: 'mailing_openings', name: 'Cantidad', type: 'pie'}]"
      chartType="pie"
      [filters]="filter"
      [stacked]="true"
      [snapshot]="snapshot"
      [performanceThreshold]="0.5"
      [hasDetails]="false"
      valueKey="performance_percent"
      displayMode="diff__1.0-0__%" />

  </ng-container>
</div>

<div class="loading" *ngIf="working">
  <mat-spinner></mat-spinner>
</div>
