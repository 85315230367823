import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";

export const flyInAnimation = trigger('flyInOut', [
  state('in', style({transform: 'translateX(0)'})),
  transition(':enter', [
    animate(300, keyframes([
      style({opacity: 0, transform: 'translateX(-100%)', offset: 0}),
      style({opacity: 1, transform: 'translateX(5px)',  offset: 0.3}),
      style({opacity: 1, transform: 'translateX(0)',     offset: 1.0})
    ]))
  ]),
  transition(':leave', [
    style({opacity: 0, transform: 'translateX(100%)'})
  ])
]);
