<mat-card>
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-users fa-2x"></i></div>
    <mat-card-title>Usuarios</mat-card-title>
    <mat-card-subtitle>Administre el acceso a la plataforma</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="usersService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        <i class="fa fa-check" style="color: green; margin-right: 5px" *ngIf="item.active"></i>{{item.name}}&nbsp;<b> ({{item.role?.name || 'Sin ROL'}})</b>
      </ng-template>
      <ng-template #formTemplate let-form="form" let-value="value">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>{{country.preferences.client_id}}</mat-label>
                <input matInput [placeholder]="country.preferences.client_id" formControlName="rut" name="rut" autocomplete="off">
                <mat-error>
                  El {{country.preferences.client_id}} es <strong>inválido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Nombre completo</mat-label>
                <input matInput placeholder="Nombre completo" required formControlName="name" name="name" autocomplete="off">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>{{heading}}</mat-label>
                <mat-select placeholder="{{heading}}" formControlName="location_ids" name="location_ids" required autocomplete="off" multiple="true">
                  <mat-option *ngFor="let location of locations" [value]="location.id">
                    {{location.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="E-mail" required formControlName="mail" name="mail" autocomplete="off">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Rol</mat-label>
                <mat-select placeholder="Rol" formControlName="role_id" name="role_id" required autocomplete="off">
                  <mat-option *ngFor="let role of roles" [value]="role.id">
                    {{role.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="Teléfono" formControlName="phone" name="phone" autocomplete="off">
              </mat-form-field>
            </div>
            <div class="col">
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px">
            <div class="col">
              <mat-checkbox formControlName="active">Activo</mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox formControlName="online">En Línea</mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Nueva Contraseña</mat-label>
                <input matInput placeholder="Nueva Contraseña" type="password" formControlName="password" name="password_confirmation" autocomplete="off">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Repetir Contraseña</mat-label>
                <input matInput placeholder="Repetir Contraseña" type="password" formControlName="password_confirmation" name="password_confirmation">
              </mat-form-field>
            </div>
          </div>
          <fieldset>
            <div class="row">
              <div class="subtitle">Bloqueos de horarios <i class="fa fa-plus" (click)="addBlockRow()"></i></div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-row" formArrayName="block_hours">
                  <div class="form-block" *ngFor="let c of form.controls['block_hours'].controls; let i = index" [formGroupName]="i">
                    <mat-checkbox formControlName="enabled"></mat-checkbox>
                    <mat-form-field>
                      <mat-label>Día</mat-label>
                      <mat-select placeholder="Día" formControlName="day" name="day" autocomplete="off">
                        <mat-option *ngFor="let day of days" [value]="day.id">
                          {{day.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Horario Inicio</mat-label>
                      <input matInput placeholder="Horario Inicio" formControlName="start_time">
                      <mat-hint>Ej: 10:20</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Horario Término</mat-label>
                      <input matInput placeholder="Horario Término" formControlName="end_time">
                      <mat-hint>Ej: 18:20</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="row" *ngIf="value && value.id">
            <div class="col">
              <app-work-user-speciality [user]="value"></app-work-user-speciality>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
