import {Component, OnInit} from '@angular/core';
import {UserService} from "../services/user.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CURRENT_VERSION} from "../app.config";
import { DomSanitizer } from '@angular/platform-browser';
import {AdminService} from 'app/admin/services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadPfpDialogComponent } from './upload-pfp-dialog/upload-pfp-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {interval} from "rxjs";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  working = false;
  user:any = {};
  loading = false;
  version = CURRENT_VERSION;
  qr;
  opt;
  private onlineDebouncer: any;
  userCheckbox = false;
  enableOTP = new UntypedFormGroup({
    password: new UntypedFormControl('',Validators.required),
    otp: new UntypedFormControl('',[Validators.minLength(6),Validators.required]),
    otp_secret: new UntypedFormControl('',Validators.required),
  })
  disableOTP = new UntypedFormGroup({
    password: new UntypedFormControl('',Validators.required),
    otp: new UntypedFormControl('',[Validators.minLength(6),Validators.required]),
  })
  changePassword = new UntypedFormGroup({
    old_password: new UntypedFormControl('',Validators.required),
    password: new UntypedFormControl('',[Validators.minLength(6),Validators.required]),
    password_confirmation: new UntypedFormControl('',[
      Validators.minLength(6),Validators.required
    ])
  }, this.samePassword.bind(this));
  themes = [
    { name: 'sky', label: 'Sky', backgroundColor: '#CEE5FF', color: '#0074EB' },
    { name: 'dark', label: 'Dark', backgroundColor: '#1a1e20', color: '#BCBDBF' },
    { name: 'mint', label: 'Mint', backgroundColor: '#97F4D9', color: '#006B58' },
    { name: 'dune', label: 'Dune', backgroundColor: '#f4b17d', color: '#865226' },
    { name: 'flamingo', label: 'Flamingo', backgroundColor: '#FFD9DF', color: '#B22156' },
    { name: 'monstera', label: 'Monstera', backgroundColor: '#d0f2a5', color: '#3F6900' }
  ];
  
  
  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    public appService: AdminService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.appService.pageTitle.set(this.appService.getText('Mi Perfil'));
    this.loading = true;
    this.userService.reauth().toPromise().then((response: HttpResponse<any>)=>{
      this.loading = false;
      this.user = response.body;
      this.userCheckbox = this.user.online;
    }).catch((error: HttpErrorResponse)=>{
      console.log(error);
    });
   
    }
  saveForm(f){
    if(f.valid){
      this.userService.update(f.value).toPromise().then((response: HttpResponse<any>)=>{
        this.snackBar.open("¡Cambios guardados!", null, { duration: 1000 });
      }).catch((error)=>{
        console.log(error);
      });
    }
  }
  samePassword(g: UntypedFormGroup){
    if(g.value['password']===g.value['password_confirmation']){
      return null;
    }else{
      g.controls['password_confirmation'].setErrors({notEqual: true})
      return {
        notEqual: true
      };
    }
  }
  enable2FA(){
    this.working = true;
    this.userService.enable2FA().toPromise().then((response: HttpResponse<any>)=>{
      this.qr = this.sanitizer.bypassSecurityTrustHtml(response.body.qr)
      this.enableOTP.patchValue({otp_secret: response.body.secret})
      this.working = false;
    }).catch((error: HttpErrorResponse)=>{
      console.log(error);
      this.working = false;
    });
  }
  validate2FA(){
    this.working = true;
    this.userService.validate2FA(this.enableOTP.value).toPromise().then((response: HttpResponse<any>)=>{
      this.user.has_2fa = true;
      this.userService.refreshToken(response.body.auth_token);
      this.working = false;
    }).catch((error: HttpErrorResponse)=>{
      console.log(error);
      if(error.status===401){
        this.enableOTP.controls['password'].setErrors({
          invalid: true
        });
      }
      if(error.status===403){
        this.enableOTP.controls['otp'].setErrors({
          invalid: true
        });
      }
      this.working = false;
    });
  }
  disable2FA(){
    this.working = true;
    this.userService.disable2FA(this.disableOTP.value).toPromise().then((response: HttpResponse<any>)=>{
      this.user.has_2fa = false;
      this.working = false;
    }).catch((error: HttpErrorResponse)=>{
      if(error.status===401){
        this.disableOTP.controls['password'].setErrors({
          invalid: true
        });
      }
      if(error.status===403){
        this.disableOTP.controls['otp'].setErrors({
          invalid: true
        });
      }
      this.working = false;
    });
  }
  savePassword(){
    this.working = true
    this.userService.changePassword(this.changePassword.value).toPromise().then((response: HttpResponse<any>)=>{
      this.snackBar.open("¡Contraseña cambiada!", null, { duration: 1000 });
      this.userService.refreshToken(response.body.auth_token)
      this.pristineForm(this.changePassword);
      this.working = false;
    }).catch((error: HttpErrorResponse)=>{
      this.working = false;
      if(error.status===401){
        this.changePassword.controls['old_password'].setErrors({
          invalid: true
        });
      }
    });
  }
  pristineForm(f: UntypedFormGroup){
    f.reset();
    f.markAsPristine();
    f.markAsPending();
    f.markAsUntouched()
    for(const control in f.controls){
      if(control){
        const c = f.controls[control];
        c.markAsUntouched();
        c.markAsPristine();
        c.markAsPending();
      }
    }
  }
  openUploadPfpDialog(): void {
    const dialogRef = this.dialog.open(UploadPfpDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //esto es para que se actualice la foto sin necesidad de actualizar la pagina
        this.user.picture = result; 
      }
    });
  }

  changeOnlineStatus(event) {
    this.updateOnlineStatus(event.checked);
    if (this.onlineDebouncer) {
      this.onlineDebouncer.unsubscribe();
    }
    this.onlineDebouncer = interval(500).subscribe(() => {
      this.userService.setOnlineStatus(event.checked).toPromise().then((user) => {
        this.user = user.body;
        this.userCheckbox = this.user.online; 
      }).catch((error) => {
        console.log(error);
      });
      this.onlineDebouncer.unsubscribe();
    });
  }

  onCheckboxChange(event: MatSlideToggleChange) {
    this.updateOnlineStatus(event.checked);
    this.changeOnlineStatus({ checked: event.checked });
  }

  updateOnlineStatus(status: boolean) {
    this.user.online = status;
    this.userCheckbox = status;
  }

  updateTheme(theme: string) {
    const user = { theme }; 
    return this.userService.update(user).toPromise().then((response: HttpResponse<any>) => {
      this.userService.user$.next(response.body);
      this.user = response.body;
      
    }).catch((error) => {
      console.log(error);
    });
  }
  
}
