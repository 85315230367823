import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CheckInService} from "../../../services/check-in.service";
import {RoleService} from "../../../role/role.service";
import moment from "moment";
import {Moment} from "moment";
import {AdminService} from "../../../admin/services/admin.service";
import {HolydaysService} from "../../../services/holydays.service";
import {BudgetService} from "../../../budget/budget.service";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'wpl-form-filler',
  templateUrl: './form-filler.component.html',
  styleUrls: ['./form-filler.component.scss']
})
export class FormFillerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() parentForm:UntypedFormGroup;
  @Input() value:any = {};
  @Input() disabled = false;
  @Input() request;
  @Input() clientMode = false;
  @Input() fields = [];
  currentDate = moment().toDate();
  key = 'custom_params';
  working = false;
  form:UntypedFormGroup = new UntypedFormGroup({
  });
  formSub;
  mode = 'Order';
  holydays = [];
  onlyWeek: (d: Moment) => boolean
  constructor(
    private orderService: CheckInService,
    private budgetService: BudgetService,
    public roleService: RoleService,
    private adminService: AdminService,
    private holydayService: HolydaysService
  ) {
  }

  ngOnInit() {
    if(this.value.budget_id){
      this.mode = 'Budget';
    }
    this.holydayService.filterFunction(true,this.value.order && this.value.order.location ? this.value.order.location.preferences : null).then(value1 => {
      this.onlyWeek = value1;
    })
    const group:UntypedFormGroup = new UntypedFormGroup({});
    this.form.addControl('custom_fields',group);
    this.fields.forEach((value1, index) => {
      if(value1 === 'finish_date' || value1 === 'total'){
        this.form.addControl(value1, new UntypedFormControl({disabled: this.disabled, value: ''},Validators.required));
      }
      else{
        group.addControl(value1, new UntypedFormControl({disabled: this.disabled, value: null},Validators.required));
      }
    });
    Promise.resolve(null).then(() => {
      this.parentForm.addControl(this.key,this.form);
      if(this.value.document_type === 'Budget') {
        this.budgetService.get(this.value.document_id).toPromise().then(response => {
          this.form.patchValue(response.body);
          this.formSub = this.form.valueChanges.pipe(debounceTime(1000)).subscribe(value1 => {
            this.budgetService.save(this.value.document_type, value1).subscribe();
          });
        });
      }else if (this.value.document_type === 'Order') {
        this.orderService.get(this.value.document_id).toPromise().then(response => {
          this.form.patchValue(response.body);
          this.formSub = this.form.valueChanges.pipe(debounceTime(1000)).subscribe(value1 => {
            this.orderService.update(this.value.document_id, value1).subscribe();
          });
        });
      }
    });


  }

  ngOnChanges(changes: SimpleChanges){
    this.form.patchValue(this.value[this.key]);
  }
  exist(key){
    return this.form.controls[key];
  }
  ngOnDestroy(){
    Promise.resolve(null).then(() => {
      this.parentForm.removeControl(this.key);
    });
    if(this.formSub){
      this.formSub.unsubscribe();
    }
  }

}
