import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StepperSelectionEvent} from "@angular/cdk/stepper";

@Component({
  selector: 'app-admin-wizard',
  templateUrl: './admin-wizard.component.html',
  styleUrls: ['./admin-wizard.component.scss']
})
export class AdminWizardComponent implements OnInit {
  step = 1;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  goWiz(){
    this.router.navigate(['admin','main','automation-wizard']);
  }
  setStep(event: StepperSelectionEvent){
    this.step = event.selectedIndex + 1;
  }

}
