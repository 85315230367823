import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService implements ICRUDService {
  params = ['name', 'address', 'warehouse_id', 'preferences'];

  model = 'Location';

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(value = null, page = 1) {
    return this.http.get(`${this.adminURL}/locations`, this.getParams({ search: value, page }));
  }

  create(value) {
    return this.http.post(`${this.adminURL}/locations/`, {
      location: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id, value) {
    return this.http.put(`${this.adminURL}/locations/${id}`, {
      location: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  destroy(id) {
    return this.http.delete(`${this.adminURL}/locations/${id}`, this.getParams());
  }

  restore(id) {
    return this.http.delete(`${this.adminURL}/locations/${id}/restore`, this.getParams());
  }

  all() {
    return this.http.get(`${this.adminURL}/locations/all`, this.getParams());
  }

  swap(ids: number[]) {
    return this.http.post(`${this.adminURL}/locations/swap`, { ids }, this.getParams());
  }
}
