<div class="wrapper client-style">
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <div class="logo">
      <img [src]="company.preferences.logo || 'assets/images/logo.svg'" alt="Company logo">
    </div>
    <div class="label">
      <span>Programa tu {{getSelectedRequirementName()}}</span>
    </div>
    <div class="content-container">
      <div class="content">
        <mat-card class="transparent">
          <mat-card-content>
            <ng-container [formGroup]="form">
              <div class="subtitle"><i class="fa fa-clock"></i> Información</div>
              <fieldset>
                <div class="row" *ngIf="company.preferences.booking_type === 'professional'">
                  <div class="col">
                    <mat-form-field appearance="outline" class="white-backgrounded-field">
                      <mat-label>Profesional</mat-label>
                      <mat-select placeholder="Profesional" formControlName="user_id" required autocomplete="off" >
                        <mat-option *ngFor="let user of professionals" [value]="user.id">
                          {{user.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="white-backgrounded-field" (click)="picker.open()">
                      <mat-label>Fecha</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [min]="nextDay"
                        [matDatepickerFilter]="onlyWeek"
                        placeholder="Fecha"
                        required
                        formControlName="booking_date"
                        autocomplete="off">
                      <mat-error>Fecha no disponible</mat-error>
                    </mat-form-field>
                    <mat-datepicker #picker [startAt]="form.controls.booking_date.value"></mat-datepicker>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline" class="white-backgrounded-field">
                      <mat-label>Hora</mat-label>
                      <mat-select placeholder="Hora" formControlName="booking_time" required autocomplete="off">
                        <mat-option *ngFor="let hour of freeBookingHours" [value]="hour">
                          {{hour}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="freebookingHours && freebookingHours.length === 0 || bookingErrors">No hay horas disponibles</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="white-backgrounded-field">
                      <mat-label>Comentario</mat-label>
                      <input matInput placeholder="Comentario" formControlName="comment" autocomplete="off">
                    </mat-form-field>
                  </div>
                </div>
                <div class="hidden-fields">
                  <input type="hidden" value="addon" formControlName="from">
                </div>
              </fieldset>
              <div class="subtitle"><i class="fa fa-plus-circle"></i> Adicionales</div>
              <app-additionals-form class="additionals-form"
              [initialValue]="booking?.order_aditionals || []"
              [parentFormArray]="form.controls.order_aditionals"
              (added)="addAdditional(this, $event)"
              (removed)="removeAdditional(this, $event)"
            ></app-additionals-form>

            <div class="buttons">
              <div class="expander"></div>
              <button
                mat-raised-button
                color="accent"
                [disabled]="!form.valid|| booking?.checked_in || loading" (click)="saveForm()"
                *ngIf="!booking?.id || (roleService.can('update','booking') | async) || (booking?.id && crmToken)">
                <span>{{booking ? 'Actualizar' : 'Agendar'}}</span>
              </button>
            </div>

            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="content summary">
        <mat-card class="details-card">
          <mat-card-content>

            <div class="field" *ngIf="crmData.requirement_id">
              <span class="requirement-title">{{getSelectedRequirementName()}}</span>
            </div>


            <div class="field">
              <span class="fieldname">Local: </span> <span *ngIf="crmData.location_id"> {{getSelectedLocationName()}}</span>
            </div>

            <!--
            <div class="field">
              <span class="fieldname">Solicitud: </span> <span *ngIf="crmData.requirement_id">{{getSelectedRequirementName()}}</span>
            </div>
            -->

            <div class="field">
              <span class="fieldname">Nombre Cliente: </span> <span *ngIf="crmData.client">{{crmData.client.name | titlecase }}</span>
            </div>

            <div class="field" *ngIf="company.preferences.booking_type === 'professional'">
              <span class="fieldname">Profesional: </span> <span>{{ form.get('user_id')?.value ? getSelectedProfessionalName() : 'Sin especificar' }}</span>
            </div>

            <div class="field">
              <span class="fieldname">Vehículo: </span> <span *ngIf="crmData.object">{{crmData.object.brand.name | titlecase }} {{crmData.object.brand_model.name | titlecase }}</span>
            </div>

            <div class="field">
              <span class="fieldname">Fecha y hora: </span>
              <span>{{ form.get('booking_date').value ? (form.get('booking_date').value | date: 'dd/MM/yyyy') : '-' }}, {{ form.get('booking_time').value ? form.get('booking_time').value : 'Pendiente' }}</span>
            </div>
            <!--
            <div class="field">
              <span class="fieldname">Hora: </span>
              <span>{{ form.get('booking_time').value ? form.get('booking_time').value : 'Sin especificar' }}</span>
            </div>
            -->

            <div class="field">
              <span class="fieldname">Comentario: </span>
              <span>{{ form.get('comment').value ? form.get('comment').value : 'Sin especificar' }}</span>
            </div>

            <div class="field">
              <span class="fieldname">Detalle del servicio: </span>
            </div>
            <app-inventory-action-form
              [parentFormGroup]="inventory_action_for_consume"
              [isLocked]="true"
              [readOnly]="true"
              [showCategories]="false"
              [showTotals]="false"
              [clientBookingMode]="true"
              #inventoryForm
              parentModel="booking"
            >
            </app-inventory-action-form>

          </mat-card-content>
        </mat-card>
    </div>
    </div>
  </ng-container>
</div>
