import {Component, OnInit} from '@angular/core';
import { HttpResponse } from "@angular/common/http";
import {CURRENT_VERSION} from "../app.config";
import {AdminService} from "../admin/services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SwUpdate} from "@angular/service-worker";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss']
})
export class UpdateAvailableComponent implements OnInit {
  actualVersion;
  usingVersion;
  constructor(
    private companyService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private swUpdate: SwUpdate,
    private matDialogRef: MatDialogRef<UpdateAvailableComponent>
  ) { }

  ngOnInit() {
    this.companyService.setupApp().toPromise().then((response: HttpResponse<any>)=>{
      this.actualVersion = response.body.current_version;
      this.usingVersion = CURRENT_VERSION;
    }).catch((error)=>{
    });
  }
  close(){
    this.matDialogRef.close();
  }
  deleteCaches(){
    caches.keys().then((names)  => {
      for (const name of names){
        caches.delete(name);
      }
      if(this.swUpdate){
        this.swUpdate.activateUpdate().then(value => {
          setTimeout(()=>{
            window.location.reload();
          },10);
        });
      }
    });
  }
}
