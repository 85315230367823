<div class="wrapper">
  <div class="search-container">
    <div class="search-bar" >
      @if (searchControl.value) {
        <button mat-icon-button (click)="clearSearch()"> <mat-icon>arrow_back</mat-icon> </button>
      }
      @else {
        <mat-icon class="icon-container">search</mat-icon>
      }
      <input [formControl]="searchControl" name="search_query" autocomplete="off" autocorrect="off" spellcheck="false">
      <button mat-icon-button (click)="openFilters()"> <mat-icon>filter_list</mat-icon> </button>

    </div>

    @if (!downloading && (roleService.can('update','check_in') | async)) {
      <button mat-icon-button (click)="openDateRange()"><mat-icon>download</mat-icon></button>
    }
    @if (downloading) {
      <button mat-icon-button disabled><mat-icon>downloading</mat-icon></button>
    }
  </div>


  <div class="statuses-container display-desktop">

    <div class="status-box" (click)="toggleFilter(-1)" onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);"> <!--[ngStyle]="statusOn(-1) ? {'border': '1px solid #795548'} : {}"-->
      <div class="status-content">
        <div class="checkbox-container" >
            @if (statusOn(-1)) {
              <mat-icon [ngStyle]="{'color': '#795548'}" >check_box</mat-icon>
            }@else{
              <mat-icon class="default">check_box_outline_blank</mat-icon>
              <mat-icon class="on-hover" [ngStyle]="{'color':'#795548'}">check_box_outline_blank</mat-icon>
            }
        </div>
        <div class="counter">
          <span class="default" [ngStyle]="statusOn(-1) ? {'color': '#795548'} : {}">{{totalByStatus[-1]?.count ?? 0}}</span>
          <span class="on-hover" [ngStyle]="{'color': '#795548'}">{{totalByStatus[-1]?.count ?? 0}}</span>
        </div>
        <div class="status-name">
          Sin {{ appService.getText('work_assignation')}}
        </div>
      </div>
    </div>

    <div class="status-box" *ngFor="let status of statuses" (click)="toggleFilter(status.id)" onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);"> <!--[ngStyle]="statusOn(status.id) ? {'border': '1px solid ' + status.color} : {}"-->
      <div class="status-content">
        <div class="checkbox-container" >
            @if (statusOn(status.id)) {
              <mat-icon [ngStyle]="{'color': status.color}" >check_box</mat-icon>
            }@else{
              <mat-icon class="default">check_box_outline_blank</mat-icon>
              <mat-icon class="on-hover" [ngStyle]="{'color': status.color}">check_box_outline_blank</mat-icon>
            }
        </div>
        <div class="counter">
          <span class="default" [ngStyle]="statusOn(status.id) ? {'color': status.color} : {}">{{totalByStatus[status.id]?.count ?? 0}}</span>
          <span class="on-hover" [ngStyle]="{'color': status.color}">{{totalByStatus[status.id]?.count ?? 0}}</span>
        </div>
        <div class="status-name">
          {{status.name}}
        </div>
      </div>
    </div>
  </div>



  <div class="table-container display-desktop" id="orders"  appPagination (loadMore)="getMore()" [isLoading]="loadingMore" [page]="page" [totalPages]="total_pages">
    <mat-card class="box-shadowless">
      <mat-card-content style="padding: 0;">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="correlative-id">
            <th mat-header-cell *matHeaderCellDef> N° {{appService.getText('check_in')}} </th>
            <td mat-cell *matCellDef="let order"> #{{order.correlative_id}} </td>
          </ng-container>

          <ng-container matColumnDef="requirement-name">
            <th mat-header-cell *matHeaderCellDef> {{appService.getText('requirement')}} </th>
            <td mat-cell *matCellDef="let order"> {{order.requirement?.name}}  <i matTooltip="Este {{appService.getText('object')}} tiene un recall" class="fa fa-bullhorn" *ngIf="order.recall_data && order.recall_data.length > 0"></i> </td>
          </ng-container>

          <ng-container matColumnDef="object-identifier">
            <th mat-header-cell *matHeaderCellDef> {{appService.getText('object_identifier') || appService.getText('client_identifier') }} </th> <!--REVISAR COMO CAMBIAR HEADER SI NO HAY OBJECT IDENTIFIER-->
            <td mat-cell *matCellDef="let order"> {{order.object?.identifier || order.client.rut}} </td>
          </ng-container>

          <ng-container matColumnDef="client-name">
            <th mat-header-cell *matHeaderCellDef> {{appService.getText('client')}} </th>
            <td mat-cell *matCellDef="let order"> {{order.client.name}} </td>
          </ng-container>

          <ng-container matColumnDef="status-list" >
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let order">
              <div class="status-list">
                <div class="status" [style.color]="status.id | status:'color'" *ngFor="let status of statuses" [matTooltip]="status.name">
                  @if (getStatusCompletion(status.id, order) && status.id != order.status_id) {
                    <i class="fa fa-square"></i>
                  }
                  @if (!getStatusCompletion(status.id, order) && status.id != order.status_id) {
                    <i class="far fa-square"></i>
                  }
                  @if (status.id == order.status_id) {
                    <i class="fa fa-check-square"></i>
                  }
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/main', 'orders', row.id]" [hidden]="working"></tr>
        </table>

        <div class="empty" [hidden]="!working">
          <mat-spinner></mat-spinner>
        </div>

        @if (orders && orders.length == 0 && !working) {
          <div class="empty white-rounded">
            <span>No se han encontrado {{appService.getText('order_plural', 'lowercase')}}</span>
          </div>
        }

        <div class="load-more white-rounded" [hidden]="page >= total_pages" id="load-more">
          <mat-spinner [diameter]="20"></mat-spinner> Cargando {{appService.getText('order_plural', 'lowercase')}}...
        </div>

      </mat-card-content>
    </mat-card>
  </div>



  <div class="table-container display-mobile scroll-shadows" id="orders" appPagination (loadMore)="getMore()" [isLoading]="loadingMore" [page]="page" [totalPages]="total_pages">

    <div class="card" *ngFor="let order of orders;" [routerLink]="['/main', 'orders', order.id]" [hidden]="working">
      <div class="card-content">
        <div class="card-image" *ngIf="order.order_attachments && order.order_attachments.length > 0" [ngStyle]="{'background-image': 'url(' + getThumbUrl(order.order_attachments[0])+ ')'}"></div>
        <div class="card-details">
          <div class="row no-margin">
            <span class="small light">#{{order.correlative_id}}</span>
          </div>
          <div class="row title">
            <span class="bold">{{order.object?.identifier || order.client.rut}}</span>
            <span class="light">{{order.requirement?.name}} <i matTooltip="Este {{appService.getText('object')}} tiene un recall" class="fa fa-bullhorn" *ngIf="order.recall_data && order.recall_data.length > 0"></i>
             </span>
          </div>
          <div class="row">
            <span>{{order.client.name}}</span>
          </div>
          <div class="row">

            <span> <i class="fas fa-check-circle" [style.color]="order.status?.id | status:'color'"></i>&nbsp;{{order.status?.name}}</span>
          </div>
        </div>
      </div>
    </div>


    @if (orders && orders.length == 0 && !working) {
      <div class="empty">
        <span>No se han encontrado {{appService.getText('order_plural', 'lowercase')}}</span>
      </div>
    }

    <div class="load-more" [hidden]="page >= total_pages" id="load-more">
      <mat-spinner [diameter]="20"></mat-spinner> Cargando {{appService.getText('order_plural', 'lowercase')}}...
    </div>

    <div class="loading" [hidden]="!working">
      <mat-spinner></mat-spinner>
    </div>


  </div>


</div>
