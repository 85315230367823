import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpResponse } from '@angular/common/http';
import {
  Component, HostBinding, Inject, OnInit, Optional, ViewChild,
} from '@angular/core';
import {
  FormBuilder, FormGroup, Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'app/models/user';
import { KitsService } from 'app/services/kits.service';
import { UserService } from 'app/services/user.service';
import { ZendeskService } from 'app/services/zendesk.service';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { AdminService } from '../../admin/services/admin.service';
import {
  addAdditional, BookingComponent, removeAdditional,
} from '../../main/booking/booking.component';
import { RoleService } from '../../role/role.service';
import { BookingService } from '../../services/booking.service';
import { HolydaysService } from '../../services/holydays.service';
import { RequirementsService } from '../../services/requirements.service';
import { ClientService } from '../client.service';
import { ClientMessageComponent } from '../client-message/client-message.component';

@Component({
  selector: 'app-client-booking',
  templateUrl: './client-booking.component.html',
  styleUrls: ['../../main/booking/booking.component.scss', './client-booking.component.scss'],
})
export class ClientBookingComponent extends BookingComponent implements OnInit {
  bookingNotFound = false;

  company;

  addAdditional = addAdditional;

  removeAdditional = removeAdditional;

  form: FormGroup = this.fb.group({
    id: null,
    booking_date: this.fb.control(moment().format('YYYY-MM-DD'), [Validators.required]),
    booking_time: [null, [Validators.required]],
    comment: '',
    from: 'addon',
    location_id: this.fb.control(null, [Validators.required]),
    requirement_id: this.fb.control(null, [Validators.required]),
    // [disabled]="!booking.requirement_id || !booking.location_id"
    user_id: this.fb.control({ value: null, disabled: true }, [Validators.required]),
    client: this.fb.group({
      id: null,
      rut: this.fb.control(null, [Validators.required]),
      name: '',
      address: '',
      city: '',
      email: '',
      phone: '',
      custom_fields: this.fb.group({}),
    }),
    custom_fields: this.fb.group({}),
    object: this.fb.group({
      id: null,
      client_id: '',
      brand_id: this.fb.control(null, [Validators.required]),
      brand_model_id: this.fb.control(null, [Validators.required]),
      color: '',
      identifier: '',
      chasis: '',
      custom_fields: this.fb.group({}),
    }),
    inventory_action_for_consume: this.fb.group({
      inventory_action_products: this.fb.array([]),
    }),
    order_aditionals: this.fb.array([]),
    kms: '',
  });

  @ViewChild(MatStepper) stepper!: MatStepper;

  currentStepIndex: number = 0;

  totalSteps: number = 4;

  hasLocations = false;

  nextDay = moment();

  professionals = [];

  step = 0;

  CLIENT_STEP = 'client';

  REQUIREMENT_STEP = 'requirement';

  VEHICLE_STEP = 'vehicle';

  TIME_STEP = 'time';

  steps = [this.REQUIREMENT_STEP, this.CLIENT_STEP, this.VEHICLE_STEP, this.TIME_STEP];

  constructor(
    bookingService: BookingService,
    snackBar: MatSnackBar,
    requirements: RequirementsService,
    router: Router,
    matDialog: MatDialog,
    roleService: RoleService,
    holydayService: HolydaysService,
    adminService: AdminService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() matDialogRef: MatDialogRef<ClientBookingComponent>,
    userService: UserService,
    private zendesk: ZendeskService,
    kitsService: KitsService,
    public fb: FormBuilder,
  ) {
    super(
      bookingService,
      snackBar,
      matDialogRef,
      router,
      matDialog,
      requirements,
      roleService,
      data,
      holydayService,
      adminService,
      userService,
      kitsService,
      fb,
    );
  }

  @HostBinding('style.backgroundColor') get backgroundColor() {
    return this.company.preferences && this.company.preferences.booking_addon
      ? this.company.preferences.booking_addon.background_color
      : 'white';
  }

  ngOnInit() {
    if (this.data && this.data.shortMode) {
      this.step = 3;
    }
    this.company = this.adminService.local$.getValue();
    this.booking.from = 'addon';
    this.booking.clientMode = true;

    this.route.queryParams.pipe(first()).forEach((value) => {
      if (value.booking_token) {
        this.loading = true;
        this.bookingEditToken = value.booking_token;
        this.clientService.getBooking(value.booking_token).pipe(
          catchError((error) => {
            this.loading = false;
            console.log(error);
            return of(null);
          }),
        ).subscribe((response) => {
          if (!response) return;

          this.loading = false;
          if (!response.body || response.status !== 200) {
            this.bookingNotFound = true;
            return;
          }

          this.booking = response.body;

          this.form.patchValue(this.booking, { emitEvent: false });

          this.searchDate(this.booking.booking_date, this.booking.booking_time, true);
          if (this.booking.checked_in) {
            this.bookingNotFound = true;
          }
          if (this.booking.requirement_id) {
            this.getProfessionals();
          }
        });
      } else if (value.location_id) {
        this.booking.location_id = value.location_id;
        this.hasLocations = false;
      } else if (this.company.locations && this.company.locations.length > 1) {
        this.hasLocations = true;
      } else if (this.company.locations && this.company.locations.length === 1) {
        this.booking.location_id = this.company.locations[0].id;
        this.hasLocations = false;
      }
      super.ngOnInit();
    });
    this.zendesk.hide();
  }

  setFormChangeHandlers() {
    super.setFormChangeHandlers();

    this.form.controls.requirement_id.valueChanges.subscribe((value) => {
      this.booking.requirement_id = value;
      this.getProfessionals();
    });
  }

  getProfessionals() {
    if (this.company.preferences.booking_type !== 'professional') {
      return;
    }

    this.userService.professionals(this.locationId).toPromise().then((value: HttpResponse<User[]>) => {
      this.professionals = value.body.filter((u) => !u.work_user_specialities.filter((s) => !s.enabled)
        .map((s) => s.speciality_id)
        .includes(this.requirementId));
      this.userId = this.professionals.findIndex((p) => p.id === this.userId) > -1
        ? this.userId
        : null;
    });
  }

  // OVERRIDE SUCCESS
  success(value) {
    let message = 'Cita agendada';
    if (this.booking && this.booking.id) {
      message = '¡Cita actualizada!';
    }
    if (this.data && this.data.shortMode) {
      this.matDialogRef.close(value);
    } else {
      this.matDialog.open(ClientMessageComponent, {
        data: {
          message,
        },
        width: '100%',
        height: '100%',
        disableClose: true,
        maxWidth: '100%',
        maxHeight: '100vh',
        minHeight: '100vh',
        panelClass: 'client-message-dialog',
      }).afterClosed();
    }
  }

  deleteSuccess() {
    const message = '¡Cita cancelada exitosamente!';
    this.matDialog.open(ClientMessageComponent, {
      data: { message },
      width: '100%',
      height: '100%',
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      minHeight: '100vh',
    }).afterClosed();
  }

  nextStep() {
    this.step += 1;
    const currentStep = this.getSteps()[this.step];

    if (currentStep === this.TIME_STEP) {
      this.searchDate(
        this.booking.booking_date,
        null,
        true,
      );
    }

    if (currentStep === this.VEHICLE_STEP) {
      setTimeout(() => {
        this.form.get('object.brand_id').setValue(this.form.get('object.brand_id').value);
      }, 1000);
    }
  }

  getSteps() {
    const { object } = this.company;
    let steps = [];
    if (object === 'Empty') {
      steps = this.steps.filter((s) => s !== this.VEHICLE_STEP);
    } else {
      steps = this.steps;
    }
    return steps;
  }

  isLastStep(): boolean {
    return this.currentStepIndex === this.totalSteps - 1;
  }

  onStepChange(event: StepperSelectionEvent): void {
    this.currentStepIndex = event.selectedIndex;
  }

  previousStep() {
    this.step -= 1;
  }

  checkValidStep(step:number) {
    const baseControls = {
      requirement: ['location_id', 'requirement_id'],
      time: ['booking_date', 'booking_time'],
      client: ['email', 'name', 'rut', 'phone', 'custom_fields'],
      vehicle: ['identifier', 'brand_id', 'brand_model_id'],
    };
    const controls = {};

    this.getSteps().forEach((st, index) => {
      controls[index] = baseControls[st];
      if (st === 'requirement' && this.company.preferences.booking_type === 'professional') {
        controls[index].push('user_id');
      }
    });

    let valid = true;
    controls[step].forEach((c) => {
      if (this.form.controls[c] && !this.form.controls[c].valid) {
        valid = false;
      }
    });
    return valid;
  }

  getSelectedLocationName(): string {
    const locationId = this.form.get('location_id')?.value;
    const selectedLocation = this.company?.locations?.find((location) => location.id === locationId);
    return selectedLocation ? selectedLocation.name : 'No seleccionada';
  }

  getSelectedRequirementName(): string {
    const requirementId = this.form.get('requirement_id')?.value;
    const selectedRequirement = this.types?.find((requirement) => requirement.id === requirementId);
    return selectedRequirement ? selectedRequirement.name : 'No seleccionada';
  }

  getSelectedProfessionalName(): string {
    const userId = this.form.get('user_id')?.value;
    const selectedUser = this.professionals?.find((user) => user.id === userId);
    return selectedUser ? selectedUser.name : 'No seleccionada';
  }

  onDateSelected(date: Date) {
    this.form.controls.booking_date.setValue(date);
  }
}
