import { NgModule } from '@angular/core';
import { BaseModule } from 'app/shared/base.module';
import { NgxCrudModule } from 'app/shared/crud-manager/ngx-crud.module';
import { MccColorPickerModule } from 'material-community-components/color-picker';

import { ClientNotificationFormComponent } from './client-notification-form/client-notification-form.component';
import { FieldsFormComponent } from './fields-form/fields-form.component';
import { StatusFormComponent } from './status-form/status-form.component';
import { WorkFormComponent } from './work-form/work-form.component';
import { WorkTimeFormComponent } from './work-time-form/work-time-form.component';

@NgModule({
  declarations: [
    ClientNotificationFormComponent,
    FieldsFormComponent,
    StatusFormComponent,
    WorkFormComponent,
    WorkTimeFormComponent,
  ],
  imports: [
    BaseModule,
    MccColorPickerModule.forRoot({}),
    NgxCrudModule,
  ],
  exports: [
    ClientNotificationFormComponent,
    FieldsFormComponent,
    StatusFormComponent,
    WorkFormComponent,
    WorkTimeFormComponent,
  ],
})
export class CommonFormsModule { }
