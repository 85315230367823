import {AfterViewInit, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {CameraService} from './camera.service';
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer} from "@angular/platform-browser";

declare var MediaRecorder: any;

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, AfterViewInit {
  progress: number;
  @ViewChild('videoplayer', { static: true }) videoPlayer: any;
  @ViewChild('canvas', { static: true }) canvas: any;
  @ViewChild('circle', {static: true}) circle: any;
  @ViewChild('photo', {static: true}) photo: any;
  public showVideo: any = false;
  public devices: any[] = [];
  public uploading = false;
  context: any;
  width: number;
  height: number;
  recorder: any;
  cameras: MediaDeviceInfo[]= [];
  currentDevice: string;
  stream: MediaStream;
  urls = [];
  blobs = [];
  uploads = []
  cancel = false;
  private interval: any;
  recording = false;
  landscape = false;
  recordedChunks = [];
  microphone = true;
  constructor(
    private cameraService: CameraService,
    private elementRef: ElementRef,
    private snackbar: MatSnackBar,
    private ngZone: NgZone,
    private matDialogRef: MatDialogRef<CameraComponent>,
    private sanitizer: DomSanitizer
  ) { }

  capture(id?) {
    this.showVideo = true;
    this.canvas.nativeElement.height = 1920;
    this.canvas.nativeElement.width = 1080;
    this.context = this.canvas.nativeElement.getContext('2d');

    const scale = Math.max(
      this.canvas.nativeElement.width / this.videoPlayer.nativeElement.videoWidth,
      this.canvas.nativeElement.height / this.videoPlayer.nativeElement.videoHeight
    );

    const x = (this.canvas.nativeElement.width / 2) - (this.videoPlayer.nativeElement.videoWidth / 2) * scale;
    const y = (this.canvas.nativeElement.height / 2) - (this.videoPlayer.nativeElement.videoHeight / 2) * scale;

    this.context.drawImage(
      this.videoPlayer.nativeElement,
      x, y,
      this.videoPlayer.nativeElement.videoWidth * scale,
      this.videoPlayer.nativeElement.videoHeight * scale
    );
    
    this.progress = 0;
    this.showVideo = false;
    this.canvas.nativeElement.toBlob((blob) => {
      if (id >= 0) {
        // Get thumbnail
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          this.uploads[id].blob = base64data;
          this.blobs[id].blob = base64data;
          this.uploading = false;
        }
      } else {
        if (!this.uploading) {
          this.uploading = true;
          this.saveBlob(blob);
        }
      }
    },'image/jpg', 1.0);
  }
  getThumb(image){
    if(image){
      const blob = image.blob;
      const thumb = image.downloadUrl;
      return this.sanitizer.bypassSecurityTrustStyle(`url('${blob || thumb}')`);
    }else{
      return null;
    }
  }
  saveBlob(blob,format='png'){
    return new Promise((resolve, reject) => {
      let id = this.uploads.length;

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        this.uploads.push({blob: base64data, id: id, url: null, progress: 0})
        const uploadTask = this.cameraService.saveBlob(blob,format);
        uploadTask.on('state_changed', (snapshot:any) => {
          // Observe state change events such as progress, pause, and resume
          // See below for more detail
          this.ngZone.run(() => {
            this.progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
            this.uploads[id].progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
          });
        }, (error) => {
          // Handle unsuccessful uploads
          this.ngZone.run(() => {
            this.uploading = false;
            this.showVideo = false;
            reject(error)
          });
        }, () => {
          this.ngZone.run(() => {
            this.uploading = false;
            this.showVideo = false;
            this.snackbar.dismiss();
          });
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
            const date = new Date().toISOString();
            this.urls.push({url: downloadURL, name: 'Camera-'+date, video: format!='png'});
            this.blobs.push({url: downloadURL, blob: base64data});
            this.uploads[id].url = downloadURL;
            resolve(id)
          });
        });
      };
    })
  }
  @HostListener('window:resize')
  ngOnInit() {
    this.width = this.elementRef.nativeElement.clientWidth;
    this.height = this.elementRef.nativeElement.clientHeight;
  }
  getDevices() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return null;
    }
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        this.devices = devices;
        this.cameras = [];
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            this.cameras.push(device);
          }
        });
        this.currentDevice = this.cameras[this.cameras.length - 1].deviceId;
        this.loadCamera();
      })
      .catch(function(err) {
        console.log(err.name + ': ' + err.message);
      });
  }
  loadCamera() {
    if (this.stream) {
      this.stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      navigator.mediaDevices.getUserMedia(this.getConstraints())
        .then(stream => {
          this.setupRecorder(stream);

          this.stream = stream;
          this.videoPlayer.nativeElement.volume = 0;
          this.videoPlayer.nativeElement.muted = 0;
          this.videoPlayer.nativeElement.srcObject = stream;
        }).catch(reason => {
          console.log(reason);
      });
    }
  }
  setupRecorder(stream){
    let mimeType;
    if(MediaRecorder.isTypeSupported('video/webm')){
      mimeType = 'video/webm';
    }else{
      mimeType = 'video/mp4';
    }
    this.recorder = new MediaRecorder(stream,{
      mimeType: mimeType
    });

    this.recorder.addEventListener('dataavailable', (e) => {
      if (e.data.size > 0) {
        this.recordedChunks.push(e.data);
      }
    });
    this.recorder.addEventListener('stop', () => {
      if(!this.cancel){
        const blob = new Blob(this.recordedChunks);
        this.saveVideo(blob,mimeType)
      }
      this.cancel = false;
    });
  }
  getCameraName() {
    const name = this.devices.find((d) => d.deviceId === this.currentDevice);
    if (name) {
      return name.label;
    }else {
      return 'Cargando';
    }
  }
  getConstraints(){
    let baseConstraints = {
      video: {
        width: { min: 1280, ideal: 1920 },
        height: { min: 720, ideal: 1080 },
        aspectRatio: { ideal: 1.7777777778 },
        facingMode: {
          ideal: 'environment'
        }
      },
      audio: true
    };
    return baseConstraints
  }
  toggleMic(){
    this.microphone = !this.microphone;
    this.stream.getAudioTracks().forEach(value => {
      value.enabled = this.microphone;
    })
  }
  changeCamera() {
    let index = this.cameras.findIndex((c) => {
      return c.deviceId === this.currentDevice;
    });
    index += 1;
    if (index >= this.cameras.length) {
      index = 0;
    }
    this.currentDevice = this.cameras[index].deviceId;
    this.loadCamera();
  }
  startRecording(){
    this.recordedChunks = [];
    this.recorder.start();

  }
  saveVideo(blob,mimeType){
    this.saveBlob(blob,mimeType.split('/').pop()).then(value => {
      this.capture(value)
    });

  }
  videoProgress(){
    let radius = this.circle.nativeElement.r.baseVal.value;
    let circumference = radius * 2 * Math.PI;
    this.circle.nativeElement.style.strokeDasharray = `${circumference} ${circumference}`;
    this.circle.nativeElement.style.strokeDashoffset = `${circumference}`;
  }
  startVideoProgress(){
    if(this.interval){
      this.cancelVideoProgress()
    }else{
      let seconds = -.5;
      const MAX_SECONDS = 15;
      this.interval = setInterval(()=>{
        seconds += 0.1;
        if(seconds >= MAX_SECONDS){
          seconds = 15;
          this.stopVideoProgress();
        }
        if(seconds >= 0){
          if(!this.recording){
            this.recording = true;
            this.startRecording();

          }
          this.setVideoProgress(seconds/MAX_SECONDS*100)
        }
      },100)
    }
  }
  cancelVideoProgress(){
    this.cancel = true;
    this.stopVideoProgress();
  }
  stopVideoProgress(){
    if(this.interval){
      if(this.recording){
        this.recording = false;
        this.recorder.stop();
      }else{
        this.capture()
      }
      clearInterval(this.interval);
      this.interval = null;
      this.setVideoProgress(0);
    }else{
      this.recording = false;
      this.cancel = false;
      this.setVideoProgress(0);
    }
  }
  setVideoProgress(percent){
    let radius = this.circle.nativeElement.r.baseVal.value;
    let circumference = radius * 2 * Math.PI;
    const offset = circumference - percent / 100 * circumference;
    this.circle.nativeElement.style.strokeDashoffset = offset;
  }
  ngAfterViewInit() {
    // Get current geolocation
    this.getDevices();
    this.width = this.elementRef.nativeElement.clientWidth;
    this.height = this.elementRef.nativeElement.clientHeight;
    this.videoProgress();
    this.photo.nativeElement.addEventListener('touchstart',(event)=>{
      this.startVideoProgress()
    })
    this.photo.nativeElement.addEventListener('touchend',(event)=>{
      this.stopVideoProgress();
    })
    window.addEventListener("orientationchange", (event) => {
      // Announce the new orientation number
      this.getOrientation()
    });
    this.getOrientation()
  }
  getOrientation(){
    setTimeout(()=>{
      if(screen.availHeight < screen.availWidth){
        this.landscape = true;
      }else{
        this.landscape = false;
      }
    },0)

  }
  close() {
    if(!this.uploading){
      if (this.stream) {
        this.stream.getTracks().forEach(function(track) {
          track.stop();
        });
      }
      this.matDialogRef.close({urls: this.urls,blobs: this.blobs});
    }
  }
  openLink(upload){
    window.open(upload.url,"_blank")
  }

}
