<div mat-dialog-title class="name">
  {{'Buscador de ' + categoryName || 'Productos'}}
</div>


<ng-container [formGroup]="form">
  <mat-form-field class="row">
    <mat-label>Producto</mat-label>
    <input
      matInput
      type="text"
      autocomplete="off"
      formControlName="search"
      placeholder="Busque por SKU o Descripción"
      required
    >
  </mat-form-field>
  <div mat-dialog-content class="content">
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef mat-sort-header> SKU </mat-header-cell>
          <mat-cell *matCellDef="let product"> {{ product.code }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
          <mat-cell *matCellDef="let product"> {{ product.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
          <mat-cell *matCellDef="let product"> {{ product.description }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Precio </mat-header-cell>
          <mat-cell *matCellDef="let product"> {{ product.price | money }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="brand_ids">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Marcas </mat-header-cell>
          <mat-cell *matCellDef="let product"> {{ getBrandNames(product.brand_ids) }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let product; columns: displayedColumns;" (click)="onOptionClick(product)"></mat-row>
      </mat-table>
      @if((dataSource?.data.length === 0)){
        <div class="no-results">
          {{ loading ? 'Cargando...' : 'No se encontraron resultados' }}
        </div>
      }
    </div>

    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <mat-paginator
      #paginator
      class="pagination"
      [length]="totalData"
      [pageSizeOptions]="pageSizes"
          showFirstLastButtons
    ></mat-paginator>
    <div class="no-results">
      @if (roleService.can('create', 'product') | async) {
        <p>¿No encuentras el producto que estás buscando? Haz click <a class="link" (click)="onCreateProductClick()"  (keyup)="$event.keyCode === 13 && onCreateProductClick()" tabindex>&nbsp;aquí&nbsp;</a> para agregarlo.</p>
      } @else {
        <mat-form-field>
          <input matInput [(ngModel)]="comment" [ngModelOptions]="{standalone: true}" placeholder="Comentario">
          <mat-hint>¿No encuentras el producto que estás buscando? Introduce aquí el nombre del producto.</mat-hint>
        </mat-form-field>
      }
    </div>
  </div>
</ng-container>

<div mat-dialog-actions class="footer">
  <button mat-button mat-dialog-close >Cancelar </button>
  <button mat-button (click)="onAcceptClick()" [disabled]="disabledButton()"> Seleccionar </button>
</div>

