<div class="zoom" *ngIf="image">
  <div class="exit" (click)="closeImage()">
    <i class="fa fa-times"></i>
  </div>
  <div class="prev" (click)="prevImage()">
    <i class="fa fa-arrow-left"></i>
  </div>
  <div class="next" (click)="nextImage()">
    <i class="fa fa-arrow-right"></i>
  </div>
  <div class="rotate" (click)="rotateImage()">
    <i class="fa fa-undo"></i>
  </div>
  <div class="download" (click)="downloadImage()">
    <i class="fa fa-external-link-alt"></i>
  </div>
  <div class="date">{{image.created_at | date:'short'}}</div>
  <div class="image" [style.background-image]="getImage(image.url)" [style.transform]="getRotation()"></div>
</div>
