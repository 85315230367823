import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'app/admin/services/admin.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-admin-translate',
  templateUrl: './admin-translate.component.html',
  styleUrls: ['./admin-translate.component.scss', '../admin-main.common.scss'],
})
export class AdminTranslateComponent implements OnInit {
  companyForm = this.fb.group({
    name: [{ value: '', disabled: true }],
    preferences: this.fb.group({
      translations: this.fb.group({
        aditional_plural: 'Adicionales',
        aditional: 'Adicional',
        booking_plural: 'Agendamientos',
        booking: 'Agendamiento',
        category: 'Grupo de Items',
        budget: 'Presupuesto',
        calendar: 'Calendario',
        check_in: 'Orden',
        checkin_plural: 'Recepciones',
        checkin: 'Recepción',
        client_identifier: 'RUT',
        client_plural: 'Clientes',
        client: 'Cliente',
        comment: 'Comentario',
        crm: 'Gestion de Clientes',
        crm_plural: 'Gestiones de Clientes',
        crm_for_booking: 'Gestion de Agendamiento de Clientes',
        crm_for_booking_plural: 'Gestiones de Agendamiento de Clientes',
        currency_prefix: '$',
        currency_suffix: '',
        customer_relationship: 'Gestión de Clientes',
        inventory: 'Inventario',
        inventory_plural: 'Inventarios',
        inventory_action_for_consume_plural: 'Consumos de Inventario',
        inventory_action_for_consume: 'Consumo de Inventario',
        inventory_action_for_loss_plural: 'Pérdidas de Inventario',
        inventory_action_for_loss: 'Pérdida de Inventario',
        inventory_action_for_movement_plural: 'Movimientos de Inventario',
        inventory_action_for_movement: 'Movimiento de Inventario',
        inventory_action_for_set_plural: 'Ajuste de Inventario',
        inventory_action_for_set: 'Ajustes de Inventario',
        inventory_action_for_stock_plural: 'Cotizaciones de Repuestos',
        inventory_action_for_stock: 'Cotización de Repuestos',
        inventory_action_for_edit_plural: 'Intercambios de Inventario',
        inventory_action_for_edit: 'Intercambio de Inventario',
        inventory_action_plural: 'Acciones de Inventario',
        inventory_action: 'Accion de Inventario',
        location_plural: 'Locales',
        location: 'Local',
        object_identifier: 'Patente',
        object_plural: 'Vehículos',
        object: 'Vehículo',
        order_plural: 'Ordenes de Trabajo',
        order: 'Orden de Trabajo',
        others: 'Otros',
        pending_works: 'Trabajos Pendiente',
        product: 'Producto',
        product_plural: 'Productos',
        professional_plural: 'Profesionales',
        professional: 'Profesional',
        requirement_plural: 'Solicitudes',
        requirement: 'Solicitud',
        tax: 'IVA',
        value: 'Valor',
        warehouse: 'Bodega',
        warehouse_plural: 'Bodegas',
        work_assignation: 'Trabajo',
      }),
    }),
    email: [{ value: '', disabled: true }],
    domain: [
      { value: '', disabled: true },
      Validators.pattern(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      ),
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: AdminService,
  ) { }

  ngOnInit(): void {
    this.companyService.setupApp().toPromise().then((response) => {
      this.companyForm.patchValue(response.body);
    }).catch((error) => {
      console.error(error);
    });
    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value) => {
      if (this.companyForm.valid) {
        this.companyService.updateCompany(value).subscribe();
      }
    });
  }

  getTrans() {
    return <FormGroup> this.companyForm.get('preferences.translations');
  }
}
