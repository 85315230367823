import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { InventoryActionProduct } from 'app/models/inventory-action-product';
import { Product } from 'app/models/product.model';
import { Warehouse } from 'app/models/warehouse.model';
import { InventoryActionProductsService } from 'app/services/inventory-action-products.service';
import { ProductsService } from 'app/services/products.service';
import { toUnderscore } from 'app/utils/string-utils';
import {
  catchError, map, merge, of, startWith, Subject, switchMap, takeUntil,
} from 'rxjs';

export interface ProductStockLocationItem {
  warehouse: Partial<Warehouse>;
  stock_on_hand: number;
  committed_stock: number;
  available_for_sale: number;
}

@Component({
  selector: 'app-inventory-product-details',
  templateUrl: './inventory-product-details.component.html',
  styleUrls: ['./inventory-product-details.component.scss'],
})
export class InventoryProductDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  public dataSourceForInventoryLog = new MatTableDataSource<InventoryActionProduct>();

  public dataSourceForStockLocation = new MatTableDataSource<ProductStockLocationItem>();

  public displayedColumnsForInventoryLog: string[] = ['action', 'quantity', 'date', 'warehouse', 'status'];

  public displayedColumnsForStockLocation = ['warehouse', 'stock_on_hand', 'committed_stock', 'available_for_sale'];

  isLoading: boolean = true;

  pageSizes = [5, 10, 25];

  @ViewChild('paginator') paginator: MatPaginator;

  public product: Product;

  private productId: number;

  totalData = 0;

  totalPages = 0;

  unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private adminService: AdminService,
    private inventoryActionProductService: InventoryActionProductsService,
    private location: Location,
    private productService: ProductsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.forEach((params) => {
      if (!params.id) {
        return;
      }
      this.productId = params.id;
      this.productService.get(params.id).subscribe((product: Product) => {
        this.product = product;
      });
      this.productService.stockLocation(params.id).subscribe((data) => {
        this.dataSourceForStockLocation = new MatTableDataSource(data);
      });
    });
  }

  ngAfterViewInit(): void {
    this.dataSourceForInventoryLog.paginator = this.paginator;
    merge(
      this.paginator.page,
    )
      .pipe(
        startWith({}),
        takeUntil(this.unsubscribe$),
        switchMap(() => {
          this.isLoading = true;
          return this.getTableData$(
            (this.paginator?.pageIndex ?? 0) + 1,
            (this.paginator?.pageSize ?? 10),
          ).pipe(catchError(() => of(null)));
        }),
        map((data) => {
          if (data == null) return [];
          this.totalData = data.total_count;
          this.totalPages = data.total_pages;
          this.isLoading = false;
          return data.items;
        }),
      )
      .subscribe((data) => {
        this.dataSourceForInventoryLog = new MatTableDataSource(data);
      });
  }

  getTableData$(pageNumber: number, pageSize: number) {
    return this.inventoryActionProductService.index(this.productId, pageNumber, pageSize);
  }

  goBack() {
    this.location.back();
  }

  titleForType(type: string) {
    return this.adminService.getText(toUnderscore(type));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
