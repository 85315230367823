<mat-card>
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-user-plus fa-2x"></i></div>
    <mat-card-title>{{companyService.getText('location') || 'Local'}}(s)</mat-card-title>
    <mat-card-subtitle>Administre cada {{companyService.getText('location') || 'Local'}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [formTemplate]="role" [service]="locationService" [formGroup]="formGroup" [itemTemplate]="itemTemplate"></ngx-crud-manager>
    <ng-template #itemTemplate let-item>
      {{item.name}}
    </ng-template>
    <ng-template #role  let-form="form" let-item="item">
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Direccion</mat-label>
          <input matInput placeholder="Dirección" formControlName="address" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Bodega</mat-label>
          <mat-select formControlName="warehouse_id" autocomplete="off">
            <mat-option *ngFor="let warehouse of warehouses$ | async" [value]="warehouse.id">
              {{warehouse.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </ng-template>
  </mat-card-content>
</mat-card>
<div class="holydays">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
      <mat-card-title>Preferencias</mat-card-title>
      <mat-card-subtitle>Administre sus preferencias de local</mat-card-subtitle>
      <div class="locale-selector">
        <mat-form-field>
          <mat-label>{{companyService.getText('location')}}</mat-label>
          <mat-select placeholder="{{companyService.getText('location')}}" [(ngModel)]="locationId" name="report_role_ids" autocomplete="off" (ngModelChange)="setLocation($event)">
            <mat-option *ngFor="let location of locations" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col-full">
          <div class="workdays">
            <h4>Días de Recepción</h4>
            <form [formGroup]="bookingDays">
              <ng-container>
                <div *ngFor="let day of days; let i = index" formArrayName="days">
                  <ng-container [formGroup]="bookingDays.controls['days'].controls[i]">
                    <div class="row-days">
                      <div class="col-days">
                        <mat-checkbox formControlName="enabled">{{day}}</mat-checkbox>
                      </div>
                      <div class="col-days">
                        <mat-form-field>
                          <mat-label>Hora Inicio</mat-label>
                          <input matInput placeholder="Horario Inicio" required formControlName="start_time">
                          <mat-hint>Ej: 10:20</mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-days">
                        <mat-form-field>
                          <mat-label>Horario Termino</mat-label>
                          <input matInput placeholder="Horario Término" required formControlName="end_time">
                          <mat-hint>Ej: 18:20</mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-days">
                        <mat-form-field>
                          <mat-label>Limite Minutos</mat-label>
                          <input matInput type="number" placeholder="Limite Minutos" formControlName="max_hours">
                          <mat-hint>Tiempo máximo de trabajo</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </div>
                </ng-container>
            </form>
          </div>
        </div>
        <div class="col-full">
          <div class="workdays">
            <h4>Días Laborables</h4>
            <form [formGroup]="workingDays">
              <ng-container>
                <div *ngFor="let day of days; let i = index" formArrayName="work_days">
                  <ng-container [formGroup]="workingDays.controls['work_days'].controls[i]">
                    <div class="row-days">
                      <div class="col-days">
                        <mat-checkbox formControlName="enabled">{{day}}</mat-checkbox>
                      </div>
                      <div class="col-days">
                        <mat-form-field>
                          <mat-label>Horario Inicio</mat-label>
                          <input matInput placeholder="Horario Inicio" required formControlName="start_time">
                          <mat-hint>Ej: 10:20</mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-days">
                        <mat-form-field>
                          <mat-label>Horario Termino</mat-label>
                          <input matInput placeholder="Horario Término" required formControlName="end_time">
                          <mat-hint>Ej: 18:20</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
        <div class="col-full">
          <div class="hours">
            <h4>Horario de Recepción</h4>
            <form [formGroup]="workHours">
              <mat-form-field>
                <mat-label>Intervalo entre agendamientos (minutos)</mat-label>
                <input matInput placeholder="Intervalo entre agendamientos (minutos)" type="number" required formControlName="time_interval" pattern="\d*">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Máx. Agendamientos Simultáneos</mat-label>
                <input matInput type="number" placeholder="Máx. Agendamientos Simultáneos" required formControlName="max_bookings" pattern="\d*">
                <mat-hint>En modo según profesional el valor se establece en 1.</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Máx. Agendamientos Diarios</mat-label>
                <input matInput type="number" placeholder="Máx. Agendamientos Diarios" required formControlName="max_bookings_per_day" pattern="\d*">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Email Agendamiento</mat-label>
                <input matInput type="text" placeholder="Email Agendamiento" [value]="email+'+'+locationId+'@controlcar.cl'" disabled>
              </mat-form-field>
              <h4>Mailing</h4>
              <fieldset formGroupName="mailing">
                <mat-form-field>
                  <mat-label>Dirección</mat-label>
                  <input matInput type="text" placeholder="Dirección" formControlName="address">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Teléfono</mat-label>
                  <input matInput type="text" placeholder="Teléfono" formControlName="phone">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Correo</mat-label>
                  <input matInput type="text" placeholder="Correo" formControlName="email">
                </mat-form-field>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
