import { Route } from '@angular/router';
import { ManagerLoginComponent } from './manager-login/manager-login.component';
import { ManagerMainComponent } from './manager-main/manager-main.component';
import { CanActivateManager } from './manager.permissions'; // Import the missing CanActivateManager class
import { ManagerDatabasesComponent } from './manager-databases/manager-databases.component';
export const managerRoutes: Route[] = [
  {
    path: 'login',
    component: ManagerLoginComponent
  },
  {
    path: 'main',
    component: ManagerMainComponent,
    canActivate: [CanActivateManager],
    children: [
      {
        path: 'database',
        component: ManagerDatabasesComponent
      },
      { path: '**', redirectTo: 'database'}
    ]
  },
  {path: '**', redirectTo: 'login'}
];