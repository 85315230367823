<div [hidden]="showVideo" style="z-index: 1;">
  <video #videoplayer [width]="width" autoplay muted="0" [volume]="0" playsinline="true"></video>
</div>
<div [hidden]="!showVideo" style="z-index: 1;">
  <canvas #canvas [width]="width" [height]="height"></canvas>
</div>
<div class="upload-list" [hidden]="uploads.length == 0">
  <div class="upload" *ngFor="let upload of uploads" [style.background-image]="getThumb(upload)" [class.uploading]="upload.progress < 100" (click)="openLink(upload)">
    <div class="progress">{{upload.progress}}%</div>
  </div>
</div>
<div class="change-camera" *ngIf="cameras && cameras.length > 1">
  <a mat-mini-fab (click)="changeCamera()" color="primary"><i class="fa fa-mobile"></i></a>
</div>
<div class="mute-mic" (click)="toggleMic()" [class.muted]="!microphone">
  <i class="fa fa-microphone" [hidden]="!microphone"></i>
  <i class="fa fa-microphone-slash" [hidden]="microphone"></i>
</div>
<div class="exit" (click)="close()">
  <i class="fa fa-times"></i>
</div>
<div class="take-photo" #photo (mousedown)="startVideoProgress()" (mouseup)="stopVideoProgress()" (mouseleave)="cancelVideoProgress()" [class.landscape]="landscape">
  <svg
    class="progress-ring"
    width="120"
    height="120">
    <circle #circle
      class="progress-ring__circle"
      stroke="red"
      stroke-width="8"
      fill="transparent"
      r="44"
      cx="60"
      cy="60"/>
  </svg>
  <div class="button-background"></div>
  <!--<button mat-fab color="accent" (click)="capture()" [disabled]="uploading"><i class="fa fa-camera"></i></button>
  <div class="progress" [hidden]="!uploading">{{progress}}%</div>-->
</div>
