<div class="header">
  <i class="fa fa-wrench"></i> Cobro Nº {{payment.correlative_id}}
</div>
<div class="finalize" #wrapper>
  <div class="payment-details">
    <div class="header-products">
      <i class="fa fa-plus-circle"></i> <div class="text">Productos (Total: {{payment.total | money}})</div>
    </div>
    <div class="wrapper">
      <div class="content">
        <div class="info" *ngFor="let item of payment.items">
          <div class="quantity right">{{item.quantity || 1}}</div>
          <div class="value">{{item.title}} <span *ngIf="item.item_description">|</span> {{item.item_description}}</div>
          <div class="name right">{{item.unit_price*item.quantity | money }}</div>
        </div>
        <div class="info">
          <div class="quantity"></div>
          <div class="value">Total</div>
          <div class="name right total" style="text-align: right">{{payment.total | money }}</div>
        </div>
        <div class="info last" *ngIf="payment.preference">
          <div class="value">Enlace Cobro:</div>
          <div class="name right total"><a [href]="payment.preference.init_point" target="_blank">Aquí</a></div>
        </div>
      </div>
    </div>
  </div>
  <div class="payment-details" *ngIf="payment.payment">
    <div class="payment-data">
      <div class="value">ID Pago:</div>
      <div class="name right total">{{payment.payment.id}}</div>
    </div>
    <div class="payment-data">
      <div class="value">Pagado el:</div>
      <div class="name right total">{{payment.payment.date_approved | date:'shortDate'}}</div>
    </div>
    <div class="payment-data" *ngIf="payment.payment.transaction_details">
      <div class="value">Pago Total:</div>
      <div class="name right total">{{payment.payment.transaction_details.total_paid_amount | money}}</div>
    </div>
    <div class="payment-data">
      <div class="value">Cuotas:</div>
      <div class="name right total">{{payment.payment.installments}}</div>
    </div>
    <div class="payment-data" *ngIf="payment.payment.transaction_details">
      <div class="value">Valor Cuota:</div>
      <div class="name right total">{{payment.payment.transaction_details.installment_amount | money}}</div>
    </div>
    <div class="payment-data" *ngIf="payment.payment.transaction_details && payment.payment.transaction_details.external_resource_url">
      <div class="value">Enlace Pago:</div>
      <div class="name right total"><a [href]="payment.preference.transaction_details.external_resource_url" target="_blank">Aquí</a></div>
    </div>
  </div>
  <div class="order" *ngIf="(roleService.can('read','check_in') | async)">
    <div class="order-button" (click)="openOrder(payment.order_id)"><i class="fa fa-cube"></i>Ver Orden de trabajo</div>
  </div>
  <button mat-raised-button color="accent" (click)="reprocess(payment.id)" *ngIf="payment.payment && !payment.payed">
    <span>Reprocesar pago</span>
  </button>
</div>

