<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Comisiones</mat-card-title>
    <mat-card-subtitle>Administra las comisiones de tus usuarios.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="sharesService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formTemplate let-fform="form" let-value="value">
        <form [formGroup]="fform">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Trabajo</mat-label>
            <mat-select placeholder="Trabajo" formControlName="work_id" required>
              <mat-optgroup label="Orden">
                <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
              </mat-optgroup>
              <mat-optgroup label="Presupuesto">
                <mat-option *ngFor="let work of budget_works" [value]="work.id">{{work.name}}</mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-hint>Calcular comisión si existe el trabajo</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Objeto a comisionar</mat-label>
            <mat-select placeholder="Objeto a comisionar" formControlName="item_key" required>
              @for(object of baseObjects; track object.name){
                <mat-option [value]="object.type + '-' + object.id">{{object.name}}</mat-option>
              }
              @if (categoriesObjects.length > 0) {
                <mat-optgroup label="Categorías">
                  @for(object of categoriesObjects; track object.name){
                    <mat-option [value]="object.type + '-' + object.id">{{object.name}}</mat-option>
                  }
                </mat-optgroup>
              }
              @if (additionalObjects.length > 0) {
                <mat-optgroup label="Adicionales">
                  @for(object of additionalObjects; track object.name){
                    <mat-option [value]="object.type + '-' + object.id">{{object.name}}</mat-option>
                  }
                </mat-optgroup>
              }
            </mat-select>
            <mat-hint>Hacer el cálculo de comisión según un objeto</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Método</mat-label>
            <mat-select placeholder="Método" formControlName="item_method" required>
              <mat-option *ngFor="let method of methods" [value]="method.value">{{method.name}}</mat-option>
            </mat-select>
            <mat-hint>¿Sobre qué variable del objeto se genera la comisión?</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Variable de Tramo</mat-label>
            <mat-select placeholder="Variable de Tramo" formControlName="step_variable" required>
                <mat-option value="AllShareTypesHistory-total">Monto total histórico de todas las comisiones</mat-option>
                <mat-option value="ThisShareTypesHistory-total">Monto total histórico de esta comisión</mat-option>
                <mat-option value="AllShareTypesHistory-count">Número histórico de todas las comisiones</mat-option>
                <mat-option value="ThisShareTypesHistory-count">Número histórico de esta comisión</mat-option>
                <mat-option value="ItemMethod">Total Ítem</mat-option>
            </mat-select>
            <mat-hint>¿Sobre qué variable se determinará el tramo correspondiente?</mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="form.controls['step_variable']?.value?.includes('History')">
            <mat-label>Periodo de cálculo histórico</mat-label>
            <mat-select placeholder="Periodo" formControlName="step_calculation_period" required>
              <mat-option value="day">Hoy</mat-option>
              <mat-option value="week">Esta semana</mat-option>
              <mat-option value="month">Este mes</mat-option>
              <mat-option value="year">Este año</mat-option>
              <mat-option value="lifetime">Desde el inicio de los tiempos</mat-option>
            </mat-select>
            <mat-hint>¿Sobre qué periodo de tiempo se hará el cálculo histórico del valor del tramo?</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Roles a comisionar</mat-label>
            <mat-select placeholder="Roles a comisionar" formControlName="role_ids" multiple required>
              <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
            </mat-select>
            <mat-hint>¿A quién se le va a otorgar la comisión?</mat-hint>
          </mat-form-field>
          <fieldset>
            <div class="subtitle">Tramos <i class="fa fa-plus" (click)="addShareFormulaControl(value, fform)" tabindex="0" (keyup)="addShareFormulaControl(value, fform)"></i></div>
            <div class="form-col" formArrayName="share_formulas">
              <div class="form-row form-control" *ngFor="let c of fform.controls['share_formulas'].controls; let i = index" [formGroupName]="i">
                <div class="form-col">
                  <mat-checkbox formControlName="enabled"></mat-checkbox>
                </div>
                <div class="form-col">
                  <div class="form-row">
                    <mat-form-field>
                      <mat-label>Formula</mat-label>
                      <input matInput formControlName="formula">
                      <mat-hint>Ej: *0+2000 || *0.02</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field>
                      <mat-label>Valor Mínimo</mat-label>
                      <input matInput formControlName="variable_start" type="number" step="0.01">
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-col">
                  <button mat-icon-button (click)="removeShareFormulaControl(i,fform)" color="warn">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
