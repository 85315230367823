import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  inject,
  NgZone,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';

import { AdminService } from '../../admin/services/admin.service';
import { CameraService } from '../image-uploader/camera/camera.service';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss'],
})
export class SignComponent implements OnInit, AfterViewInit {
  signaturePad:SignaturePad;

  uploading = false;

  progress = 0;

  company:any;

  termMode = true;

  @Output() saved = new EventEmitter();

  constructor(
    private cameraService: CameraService,
    private ngZone: NgZone,
    private adminService: AdminService,
    private storage: AngularFireStorage,
    private dialogRef: MatDialogRef<SignComponent>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.company = this.adminService.local$.getValue();
  }

  setupSign() {
    const canvas = document.querySelector('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight - 66;
    setTimeout(() => {
      this.signaturePad = new SignaturePad(canvas, {
        minWidth: 2,
      });
    }, 0);
  }

  close() {
    if (this.dialogRef instanceof MatDialogRef) {
      this.dialogRef.close();
    } else {
      this.saved.emit(false);
    }
  }

  clearSign() {
    this.signaturePad.clear();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setupSign();
  }

  saveSign() {
    const imageData = this.trimImage(document.querySelector('canvas')).toDataURL().substr(22);
    this.uploading = true;
    const storageRef = this.storage.storage.app
      .storage(this.company.bucket)
      .ref(`signs/${new Date().toISOString()}-${Math.random() * 100000}.png`);
    const uploadTask = storageRef.putString(imageData, 'base64', { contentType: 'image/png' });
    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // See below for more detail
      this.ngZone.run(() => {
        this.progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      });
    }, () => {
      // Handle unsuccessful uploads
      this.ngZone.run(() => {
        this.uploading = false;
      });
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.ngZone.run(() => {
          this.uploading = false;
        });
        if (this.dialogRef instanceof MatDialogRef) {
          this.dialogRef.close(downloadURL);
        } else {
          this.saved.emit(downloadURL);
        }
      });
    });
  }

  ngAfterViewInit() {
    this.setupSign();
  }

  trimImage(c: any) {
    const ctx = c.getContext('2d');
    const copy = document.createElement('canvas').getContext('2d');
    const pixels = ctx.getImageData(0, 0, c.width, c.height);
    const l = pixels.data.length;
    const bound = {
      top: null,
      left: null,
      right: null,
      bottom: null,
    };
    let i; let x; let
      y;

    for (i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % c.width;
        y = ~~((i / 4) / c.width);

        if (bound.top === null) {
          bound.top = y;
        }

        if (bound.left === null) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }

        if (bound.right === null) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }

        if (bound.bottom === null) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }

    const trimHeight = bound.bottom - bound.top;
    const trimWidth = bound.right - bound.left;
    const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // open new window with trimmed image:
    return copy.canvas;
  }
}
