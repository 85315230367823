import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class WorkRequirementAvgService extends BaseService implements ICRUDService{
  params = ['work_id','requirement_id','base_time','base_std_dev'];
  model = 'WorkRequirementAvg';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null,page = 1){
    return this.http.get(this.adminURL+'/work_requirement_avgs',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/work_requirement_avgs/',{
      work_requirement_avg: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/work_requirement_avgs/'+id,{
      work_requirement_avg: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/work_requirement_avgs/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/work_requirement_avgs/'+id+'/restore',this.getParams());
  }
  all(){
    return this.http.get(this.adminURL+'/work_requirement_avgs/all',this.getParams());
  }
}
