import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StatusesService } from 'app/services/statuses.service';

import { BudgetService } from '../../../budget/budget.service';
import { BudgetViewComponent } from '../../../budget/budget-view/budget-view.component';
import { WorkService } from '../../work.service';

@Component({
  selector: 'wpl-budget-work',
  templateUrl: './budget-work.component.html',
  styleUrls: ['./budget-work.component.scss'],
})
export class BudgetWorkComponent implements OnInit, OnDestroy {
  @Input() parentForm:UntypedFormGroup;

  @Input() value:any = {};

  key = 'custom_params';

  working = false;

  budget;

  statuses = [];

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      completed: new UntypedFormControl(null, Validators.required),
    },
    Validators.required,
  );

  constructor(
    private budgetService: BudgetService,
    private budgetStatusService: StatusesService,
    private workService: WorkService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.budgetStatusService.statuses('StatusForBudget').subscribe((value) => {
      this.statuses = value;
    });
    Promise.resolve(null).then(() => {
      if (this.value.document_type !== 'Budget') {
        this.form = new UntypedFormGroup({
          completed: new UntypedFormControl(null, Validators.required),
        });
        this.form.patchValue(this.value || {});
        this.parentForm.addControl(this.key, this.form);
        this.parentForm.updateValueAndValidity();
      }
      this.budget = this.value.budget;

      if (!this.budget) {
        this.budgetService.draft(this.value.document_id, this.value.id).subscribe((response) => {
          this.budget = response.body;
        });
      }
    });
  }

  getStatusCompletion(status_id, budget) {
    const current = this.statuses.findIndex((s) => s.id === budget.status_id);
    const ask = this.statuses.findIndex((s) => s.id === status_id);
    return current > ask;
  }

  openBudget() {
    this.matDialog.open(BudgetViewComponent, {
      data: { id: this.budget.id, work_id: this.value.id },
      minWidth: '90%',
    });
  }

  ngOnDestroy() {
    this.parentForm.removeControl(this.key);
  }
}
