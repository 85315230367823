import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Validators, UntypedFormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { FieldsService } from 'app/admin/services/fields.service';
import { ListsItemsService } from 'app/admin/services/lists-items.service';
import { ListsService } from 'app/admin/services/lists.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-admin-lists',
  templateUrl: './admin-lists.component.html',
  styleUrls: ['./admin-lists.component.scss','../admin-main.common.scss']
})
export class AdminListsComponent implements OnInit {
  formList = this.fb.group({
    name: ['',Validators.required],
    list_id: [''],
    list_item_filter_ids: [''],
    multiple: [false]
  })
  rows: any[] = [];
  importForm = this.fb.group({
    file: ['https://firebasestorage.googleapis.com/v0/b/cardcontrol-4793f.appspot.com/o/lists%2Far014znsg1d.xlsx?alt=media&token=b354a2d2-305b-4446-ae59-90476ea4c6ca'],
    type: [''],
    pairs: new FormArray([])
  });
  pairForm = this.fb.group({
    row_id: [''],
    attribute_id: ['']
  })
  list: any[] = [];
  global_lists: any[] = [];
  fields = [
    {
      name: 'Cliente',
      fields: [
        {
          name: 'Nombre',
          id: 'name'
        },
        {
           name: 'Email',
            id: 'email'
        },
        {
          name: 'Teléfono',
          id: 'phone'
        },
        {
          name: 'Dirección',
          id: 'address'
        }
      ]
    },
    {
      name: 'Objeto',
      fields: [
        {
          name: 'Identificador',
          id: 'identifier'
        }
      ]
    }
  ]
  selected;
  constructor(
    public listsService: ListsService,
    public listsItemsService: ListsItemsService,
    private fieldsService: FieldsService,
    public router: Router,
    private fb: UntypedFormBuilder,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.formList.controls['list_id'].valueChanges.subscribe((value)=>{
      if(value && this.global_lists){
        let list = this.global_lists.find(l => l.id === value)
        if(list){
          this.list = list.list_items;
        }

      }else{
        this.list = []
      }
    })
    this.fieldsService.fields('FieldForCustom').subscribe((value)=>{
      const fields = value.map((field)=>{
        return {
          name: field.title,
          id: field.key
        }
      });
      this.fields[1].fields = fields.concat(this.fields[1].fields);
    });

    this.fieldsService.fields('FieldForClient').subscribe((value)=>{
      const fields = value.map((field)=>{
        return {
          name: field.title,
          id: field.key
        }
      });
      this.fields[0].fields = fields.concat(this.fields[0].fields);
    });

    this.listsService.global().toPromise().then((value: HttpResponse<any[]>)=>{
      this.global_lists = value.body;
      this.global_lists.unshift({
        name: 'Sin Lista',
        id: null
      })
    })
  }

  select(item){
    this.selected = item;
  }
  goUp(){
    this.selected = null;
  }
  import(event){
    if(event.target.files.length){
      const randomName = Math.random().toString(36).substring(2);
      const file = event.target.files[0];
      const filePath = `lists/${randomName}.${file.name.split('.').pop()}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      this.importForm.controls['file'].setValue(filePath);
      task.then((value)=>{
        fileRef.getDownloadURL().subscribe((url)=>{
          this.importForm.controls['file'].setValue(url);
        })
      })
    }
  }
  getRows(){
    const url = this.importForm.controls['file'].value;
    this.listsService.import(url).toPromise().then((value:any)=>{
      if(value.body.rows){
        for(let i = 0; i < value.body.rows.length; i++){
          (<FormArray>this.importForm.controls['pairs']).push(cloneDeep(this.pairForm));
        }
        this.rows = value.body.rows;
      }
      else{
        this.rows = [];
      }
    })
  }
  getPairs(){
    return (<FormArray>this.importForm.controls['pairs']).controls;
  }

}
