import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FieldsService } from 'app/admin/services/fields.service';
import { RoleService } from 'app/role/role.service';
import { BrandService } from 'app/services/brand.service';
import { ObjectsService } from '../../services/objects.service';
import { AdminService } from 'app/admin/services/admin.service';

@Component({
  selector: 'app-admin-object',
  templateUrl: './admin-object.component.html',
  styleUrls: ['./admin-object.component.scss','../admin-main.common.scss']
})
export class AdminObjectComponent implements OnInit {
  form = this.fb.group({
    client_id: '',
    identifier: '',
    custom_fields: this.fb.group({})
  });
  objectConfigForm = this.fb.group({
    object_display: 'identifier'
  });
  identifiers = [
    {id: 'identifier', name: 'Identificador'},
  ];

  roles = [];
  formParams = this.fb.group({
    title: ['',Validators.required],
    required: [false],
    show_client: [false],
    field_type: ['text',Validators.required],
    field_filter: [[]]
  })
  custom = [];
  constructor(
    public fieldsService: FieldsService,
    public objectsService: ObjectsService,
    public brandService: BrandService,
    private fb: UntypedFormBuilder,
    private roleService: RoleService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.fieldsService.fields('FieldForCustom').subscribe(
      (fields) => {
        this.custom = fields;
        this.identifiers = this.identifiers.concat(fields.map((field) => {
          return {id: field.key, name: field.title}
        }));
      }
    );

    this.objectConfigForm.valueChanges.subscribe((value) => {
      this.adminService.updateCompany({
        preferences: value
      }).toPromise().then((value) => {
        this.adminService.setCompany(value.body);
      });
    });
    this.custom.forEach(field => {
      (<FormGroup>this.form.controls['custom_fields']).addControl(field.key,new FormControl('',{nonNullable: field.required}))
    })
    this.roleService.simple_index().toPromise().then((value: HttpResponse<any[]>)=>{
      this.roles = value.body;
    });
  }

}
