
<div mat-dialog-title class="name">
  Cambiar Imagen de Perfil
</div>

<div mat-dialog-content class="content">
  <div class="bar">
    <button mat-button class="primary" (click)="fileInput.click()" [disabled]="loading">
      <mat-icon>add_photo_alternate</mat-icon> Seleccionar foto
    </button>
    <input #fileInput type="file" (change)="fileChangeEvent($event)" style="display: none;" />
  </div>

<div *ngIf="errorMessage" class="bar error">
  <mat-icon style="margin-right: 3px;">warning</mat-icon>
  <span>{{ errorMessage }}</span>
</div>

  <image-cropper
    class="cropper"
    *ngIf="!errorMessage && imageChangedEvent"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="png"
    [cropperMinWidth]="100"
    [cropperMinHeight]="100"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

  
</div>

<div mat-dialog-actions class="footer">
  <button mat-button (click)="onCancel()">Cancelar</button>
  <button mat-button [disabled]="!croppedImage || !!errorMessage || loading" (click)="onUpload()">
    @if (loading) {
      <mat-icon>hourglass_empty</mat-icon>
    }
    Guardar
  </button>
</div>


