import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priority'
})
export class PriorityPipe implements PipeTransform {

  transform(value: any, type: any): any {
    if(type==='model'){
      switch(value.model_class){
        case 'WorkAssignation':
          return 'trabajo';
        case 'OrderComment':
          return 'comentario';
        case 'Comment':
          return 'comentario';
        case 'WorkRequest':
          return 'trabajo';
        case 'OrderNotification':
          return 'notificación';
        case 'Event':
          return value.content;
        case 'UserAssignation':
          return 'asignado';
        case 'Client':
          return 'citación';
        default:
          return value.model_class;
      }
    }else if(type==='action'){
      switch(value){
        case 'assigned':
          return 'Te han asignado un nuevo';
        case 'assignation_required':
          return 'Un trabajo necesita un'
        case 'mentioned':
          return 'Te mencionaron en un';
        case 'request':
          return 'Requerimiento de';
        case 'completed_request':
          return 'Actualizado estado de';
        case 'event':
          return 'Pronto: ';
        case 'answer':
          return 'Cliente respondió una'
        case 'recall':
          return 'Alerta de ';
        case 'revision_required':
          return 'Requerida revisión de un';
        default:
          return value;
      }
    }else if(type==='icon'){
      switch(value){
        case 'WorkAssignation':
          return 'fa-wrench';
        case 'OrderComment':
          return 'fa-comment';
        case 'WorkRequest':
          return 'fa-bullhorn';
        case 'Event':
          return 'fa-clock-o';
        default:
          return 'fa-bullhorn';
      }
    }
  }

}
