  <div mat-dialog-title class="name">
    Seleccione Usuarios
  </div>
  
  <div mat-dialog-content class="content">
    <button mat-button class="grid" *ngFor="let role of roles" (click)="setRole(role)" [hidden]="users.length > 0">
      <span>{{role.name}}</span>
    </button>

    <button mat-button class="grid" *ngFor="let user of users" (click)="setUser(user)" [hidden]="users.length == 0">
      <mat-icon [hidden]="!userSelected(user)">check_box</mat-icon>
      <mat-icon [hidden]="userSelected(user)">check_box_outline_blank</mat-icon>
      <span>{{user.name}}</span>

    </button>
  </div>


<div mat-dialog-actions class="footer">
  <!-- <button mat-button (click)="closeDialog()">Cancelar</button> -->
  <button mat-button [hidden]="users.length == 0" (click)="users = []">Regresar</button>
  
  <!-- <button mat-button cdkFocusInitial (click)="applyFilters()" [hidden]="users.length == 0">Aplicar</button> -->
  <button mat-button (click)="applyRoles()" cdkFocusInitial>Cerrar</button>
</div>