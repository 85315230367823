import {Component, OnInit} from '@angular/core';
import {RolesService} from "../../services/roles.service";
import {UntypedFormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.scss','../admin-main.common.scss']
})
export class AdminRolesComponent implements OnInit {
  formGroup  = this.fb.group({
    name: ['',Validators.required],
    powers: [{},Validators.required]
  })
  constructor(
    private fb: UntypedFormBuilder,
    public roleService: RolesService
  ) { }

  ngOnInit() {
  }

}
