<div class="waiting" *ngIf="works.length == 0">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <span>Esperando respuesta del servidor</span>
</div>
<div class="filters" *ngIf="works.length > 0 && !done && !working">
  <div class="title"><i class="fa fa-user"></i> <span>Seleccione Nuevo Trabajo</span></div>
  <div class="wrapper">
    <button mat-button class="option" *ngFor="let work of works" (click)="setWork(work)">
      <span class="name">{{work.name}}</span>
    </button>
  </div>
</div>

