import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import moment from "moment";
import {Moment} from "moment";
import {AdminService} from "../../admin/services/admin.service";
import { HttpResponse } from "@angular/common/http";
import {HolydaysService} from "../../services/holydays.service";
import {RoleService} from "../../role/role.service";
import {EventService} from "../event-service.service";
import {OrderComponent} from "../../main/order/order.component";
import {WorkDialogComponent} from "../../work/work-dialog/work-dialog.component";
import {cloneDeep} from "lodash-es";

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss']
})
export class EventDialogComponent implements OnInit {
  form = this.builder.group({
    'name': ['',Validators.required],
    'start_date': ['',Validators.required],
    'duration': [1],
    'pictures': [],
    'location_id': ''
  });
  loading = false;
  holydays = [];
  editMode = false;
  heading;
  locations:any[];
  company;
  currentDate = moment().toDate();
  onlyWeek: (d: Moment) => boolean;
  constructor(
    private matDialogRef: MatDialogRef<EventDialogComponent>,
    private builder: UntypedFormBuilder,
    private dialog: MatDialog,
    private adminService: AdminService,
    private holydayService: HolydaysService,
    public roleService: RoleService,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public event:any
  ) { }

  ngOnInit() {
    this.locations = this.adminService.getLocations();
    this.heading = this.adminService.getText('location');
    if(this.event && this.locations && this.locations.length == 1){
      this.event.location_id = this.locations[0].id
    }
    this.company = this.adminService.local$.getValue();
    if(this.event){
      this.form.patchValue(this.event);
    }
    this.form.controls['location_id'].valueChanges.subscribe(()=>{
      this.loadLocationConfig(this.event ? this.event.location_id : null);
    })
    this.form.controls['location_id'].setValue(this.locations[0].id)
  }
  loadLocationConfig(id,clientMode?){
    if(id){
      this.holydayService.filterFunction(false,this.locations.find(l => l.id === id).preferences).then(value => {
        this.onlyWeek = value;
      });
    }else{
      this.holydayService.filterFunction().then(value => {
        this.onlyWeek = value;
      });
    }
  }
  openOrder(id){
    this.dialog.open(OrderComponent,{data: id});
  }
  openWork(id){
    this.dialog.open(WorkDialogComponent,{data:
        {id: id}, minWidth: '80%'
    });
  }
  edit(){
    this.editMode = true;
  }
  saveImages(images:any[]){
    if(this.event){
      this.eventService.update(this.event.id,{event: {pictures: images}}).toPromise().then((response: HttpResponse<any>) => {
        this.event.pictures = response.body.pictures
        this.event = cloneDeep(this.event);
        this.form.patchValue(this.event);
      }).catch((error) => {
        console.log(error);
      });
    }else{
      this.form.patchValue({pictures: images});
    }
  }
  saveComment(value){
    this.eventService.update(this.event.id,{event: {comments: [value]}}).toPromise().then((response: HttpResponse<any>) => {
      this.event.comments = response.body.comments
      this.event = cloneDeep(this.event);
      this.form.patchValue(this.event);
    }).catch((error) => {
      console.log(error);
    });
  }
  deleteEvent(event){
    event.preventDefault();
    this.eventService.delete(this.event.id).toPromise().then((response: HttpResponse<any>) => {
      this.matDialogRef.close();
    }).catch((error) => {
      console.log(error);
    });
  }
  saveForm(){
    this.loading = true;
    if(this.event){
      this.eventService.update(this.event.id,{event: this.form.value}).toPromise().then((result)=>{
        this.matDialogRef.close(true);
      }).catch((error)=>{
        this.loading = false;
      });
    }else{
      this.eventService.create({event: this.form.value}).toPromise().then((result)=>{
        this.event = result.body;
        this.loading = false;
      }).catch((error)=>{
        this.loading = false;
      });
    }
  }
  closeDialog() {
    this.matDialogRef.close();
  }

}
