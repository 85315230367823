import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { ConfirmDialogComponent } from 'app/main/confirm-dialog/confirm-dialog.component';
import { InventoryAction } from 'app/models/inventory_action.model';
import { Status } from 'app/models/status.model';
import { InventoryActionService } from 'app/services/inventory-action.service';
import { StatusesService } from 'app/services/statuses.service';
import { toUnderscore } from 'app/utils/string-utils';
import { tap } from 'rxjs';

@Component({
  selector: 'app-inventory-action-details',
  templateUrl: './inventory-action-details.component.html',
  styleUrls: ['./inventory-action-details.component.scss'],
})
export class InventoryActionDetailsComponent implements OnInit {
  displayedColumns: string[] = ['product_id', 'quantity'];

  inventoryAction: InventoryAction;

  statuses: Status[];

  constructor(
    public adminService: AdminService,
    private dialog: MatDialog,
    private inventoryActionService: InventoryActionService,
    private statusService: StatusesService,
    @Inject(MAT_DIALOG_DATA) private input,
  ) {
  }

  ngOnInit(): void {
    this.inventoryAction = this.input.inventoryAction;
    this.statusService.statuses(`StatusFor${this.inventoryAction.type}`).subscribe((statuses) => {
      this.statuses = statuses;
    });
  }

  setStatus(status_id: number) {
    this.dialog
      .open(ConfirmDialogComponent, { data: { title: 'Actualizar Estado' } })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.inventoryAction.status_id = status_id;
          this.updateInventoryAction({ status_id });
        }
      });
  }

  openDetails(id: number) {
    this.inventoryActionService.get(id).subscribe((response) => {
      this.inventoryAction = response;
      this.statusService.statuses(`StatusFor${this.inventoryAction.type}`).subscribe((statuses) => {
        this.statuses = statuses;
      });
    });
  }

  private updateInventoryAction(changes: Partial<InventoryAction>) {
    this.inventoryActionService
      .update(this.inventoryAction.id, changes).pipe(
        tap((response) => Object.assign(this.inventoryAction, response)),
      )
      .subscribe(() => {
        setTimeout(() => { this.updateWorks(); }, 1000);
      });
  }

  private updateWorks() {
    this.inventoryActionService.getWorks(this.inventoryAction.id).subscribe((response) => {
      Object.assign(this.inventoryAction, { ...this.inventoryAction, works: response });
    });
  }

  reloadInventoryAction() {
    this.inventoryActionService.get(this.inventoryAction.id).subscribe((response) => {
      Object.assign(this.inventoryAction, response);
    });
  }

  get title() {
    const action = this.adminService.getText(toUnderscore(this.inventoryAction.type));
    return `${action} #${this.inventoryAction.correlative_id}`;
  }
}
