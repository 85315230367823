import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Status } from 'app/models/status.model';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';
import { BehaviorSubject, map, tap } from 'rxjs';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class StatusesService extends BaseService implements ICRUDService {
  params = [
    'budget_actions',
    'color',
    'name',
    'notify_client',
    'order',
    'status_type',
    'type',
  ];

  model = 'Status';

  private allStatuses$ = new BehaviorSubject<Status[]>([]);

  constructor(
    public http: HttpClient,
    userService: UserService,
  ) {
    super(http, userService);
    this.all().pipe(
      tap((response: HttpResponse<Status[]>) => {
        this.allStatuses$.next(response.body);
      }),
    ).subscribe();
  }

  index(value = null, page = 1, type = 'StatusForOrder') {
    return this.http.get(`${this.adminURL}/statuses`, this.getParams({ search: value, page, type }));
  }

  create(value, type = 'StatusForOrder') {
    Object.assign(value, { type });
    return this.http
      .post(
        `${this.adminURL}/statuses/`,
        { status: this.permitParams(value, ...this.params) },
        this.getParams(),
      )
      .pipe(
        tap((response: HttpResponse<Status>) => {
          this.allStatuses$.next([...this.allStatuses$.value, response.body]);
        }),
      );
  }

  update(id: number, value: Status) {
    return this.http
      .put(
        `${this.adminURL}/statuses/${id}`,
        { status: this.permitParams(value, ...this.params) },
        this.getParams(),
      )
      .pipe(
        tap((response: HttpResponse<Status>) => {
          const statuses = this.allStatuses$.value;
          const index = statuses.findIndex((status) => status.id === id);
          statuses[index] = response.body;
          this.allStatuses$.next(statuses);
        }),
      );
  }

  destroy(id: number) {
    return this.http
      .delete(
        `${this.adminURL}/statuses/${id}`,
        this.getParams(),
      )
      .pipe(
        tap(() => {
          const statuses = this.allStatuses$.value;
          const index = statuses.findIndex((status) => status.id === id);
          statuses.splice(index, 1);
          this.allStatuses$.next(statuses);
        }),
      );
  }

  restore(id: number) {
    return this.http
      .delete<Status>(`${this.adminURL}/statuses/${id}/restore`, this.getParams())
      .pipe(
        tap((response) => {
          this.allStatuses$.next([...this.allStatuses$.value, response.body]);
        }),
      );
  }

  all(type?: string) {
    return this.http.get<Status[]>(`${this.apiURL}/statuses`, this.getParams({ type }));
  }

  import(item: null) {
    return this.http.post(`${this.adminURL}/statuses/import`, { items: item }, this.getParams());
  }

  swap(ids) {
    return this.http.post(`${this.adminURL}/statuses/swap`, { ids }, this.getParams());
  }

  statuses(type?: string) {
    if (!type) {
      return this.allStatuses$.asObservable();
    }

    return this.allStatuses$.asObservable().pipe(
      map((statuses) => statuses.filter((status: Status) => status.type === type)),
    );
  }
}
