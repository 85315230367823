import { Component } from '@angular/core';

@Component({
  selector: 'app-iframe-rejected',
  templateUrl: './iframe-rejected.component.html',
  styleUrl: './iframe-rejected.component.scss'
})
export class IframeRejectedComponent {

}
