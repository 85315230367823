import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WorkService} from '../work.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-work-next',
  templateUrl: './work-next.component.html',
  styleUrls: ['./work-next.component.scss']
})
export class WorkNextComponent implements OnInit {
  works:any = [];
  done = false;
  working = false;
  constructor(
    private dialogRef: MatDialogRef<WorkNextComponent>,
    private workService: WorkService,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    this.workService.all().toPromise().then((value:HttpResponse<any[]>) => {
      this.works = value.body.filter((w) => this.data.work_ids.includes(w.id));
    });
  }
  setWork(work){
    this.dialogRef.close(work.id);
  }

}
