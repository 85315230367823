import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {UserService} from "../services/user.service";
import {BaseService} from "../services/_base.service";

@Injectable({
  providedIn: 'root'
})
export class VehiclesService extends BaseService{

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  all(filters?, search?) {
    const params = {};
    if (filters) {
      for (const filter in filters) {
        if (filter) {
          params[filter]=filters[filter].toString();
        }
      }
    }
    if (search) {
      params['query']=search;
    }
    return this.http.get(this.apiURL + '/vehicles', this.getParams(params));
  }
  processVehicle(id){
    return this.http.put(this.apiURL+'/vehicles/'+id,{},this.getParams())
  }
  totalsByStatus() {
    return this.http.get(this.apiURL + '/vehicles/totals_by_status', this.getParams());
  }
}
