import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from 'app/models/category.model';
import { ICRUDService, PagedResponse } from 'app/shared/crud-manager/crud.interface';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseService implements ICRUDService {
  params = ['custom_field_ids', 'name', 'unit', 'use_as_name', 'value'];

  model = 'Category';

  private allCategories$ = new BehaviorSubject<Category[]>([]);

  constructor(public http: HttpClient, userService: UserService) {
    super(http, userService);
    this.updateCategories();
  }

  index(search?: string, page?: number) {
    return this.http.get<PagedResponse<Category[]>>(
      `${this.adminURL}/categories`,
      this.getParams({ search, page }),
    );
  }

  all() {
    return this.http.get<Category[]>(`${this.adminURL}/categories/all`, this.getParams());
  }

  get(id: number): Observable<HttpResponse<Category>> {
    return this.http.get<Category>(`${this.adminURL}/categories/${id}`, this.getParams());
  }

  create(value: [Category]) {
    return this.http.post(
      `${this.adminURL}/categories/`,
      { category: this.permitParams(value, ...this.params) },
      this.getParams(),
    )
      .pipe(
        tap(() => {
          this.updateCategories();
        }),
      );
  }

  update(id: number, value: [Category]) {
    return this.http.put(
      `${this.adminURL}/categories/${id}`,
      {
        category: this.permitParams(value, ...this.params),
      },
      this.getParams(),
    )
      .pipe(tap(() => { this.updateCategories(); }));
  }

  destroy(id: number) {
    return this.http.delete(`${this.adminURL}/categories/${id}`, this.getParams())
      .pipe(tap(() => { this.updateCategories(); }));
  }

  restore(id: number) {
    return this.http.delete(`${this.adminURL}/categories/${id}/restore`, this.getParams())
      .pipe(tap(() => { this.updateCategories(); }));
  }

  swap(ids: number[]) {
    return this.http.post(`${this.adminURL}/categories/swap`, { ids }, this.getParams());
  }

  import(items = null) {
    return this.http.post(`${this.adminURL}/categories/import`, { items }, this.getParams())
      .pipe(tap(() => { this.updateCategories(); }));
  }

  allProducts() {
    return this.http.get(`${this.adminURL}/categories/products`, this.getParams());
  }

  categories(): Observable<Category[]> {
    return this.allCategories$.asObservable();
  }

  private updateCategories() {
    this.all().pipe(
      tap((allCategories: HttpResponse<Category[]>) => {
        this.allCategories$.next(allCategories.body);
      }),
    ).subscribe();
  }
}
