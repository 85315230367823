<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Modelos {{parentItem?.name}}</mat-card-title>
    <mat-card-subtitle>Administrar listado de Marcas y Modelos Permitidos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="modelsService" [formTemplate]="formTemplate" [formGroup]="form" [args]="[id]" (back)="goUp()" [showNavigation]="true" *ngIf="id">
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" autofocus required>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
