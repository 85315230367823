<mat-horizontal-stepper (selectionChange)="setStep($event)" class="stepper">
  <mat-step>
    <ng-template matStepLabel>General</ng-template>
    <div class="container">
      <div class="text">
        <h4>Bienvenido a la configuración de <b>ControlCar</b></h4>
        <p>Antes de poder utilizar completamente la aplicación debemos configurar algunos aspectos principales.</p>
        <p>En esta sección veremos el nombre y dominio de nuestro taller o empresa en Controlcar, por el cual los usuarios podrán acceder a la plataforma, ademas podremos editar nuestros logos y banners.</p>
        <p>El dominio solo puede ser cambiado por un Adminitrador de <b>ControlCar</b>.</p>
      </div>
      <div class="content">
        <app-admin-company *ngIf="step == 1"></app-admin-company>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Agendamiento</ng-template>
    <div class="container">
      <div class="text">
        <p>Para poder tener ordenes de trabajo, lo primero que hay que configurar es nuestro sistema de agendamiento.</p>
        <p>Podrás controlar con totalidad tu jornada y capacidad de trabajo. Si necesitas mas opciones puedes revisar la pestaña de agendamiento al terminar el wizard.</p>
      </div>
      <div class="content">
        <app-admin-location></app-admin-location>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Requerimientos</ng-template>
    <div class="container">
      <div class="text">
        <p>Para poder diferenciar según tipo de solicitud, es necesario agregar servicios(Requerimientos) que podrá contratar un cliente. Ej: Mantención, Recall, Reparación, etc.</p>
        <p>Mientrás mas requerimientos crees mas control tendrás sobre las ordenes y trabajos, además de una analítica mas detallada. Ej: Mantención 10.000, 20.000, etc</p>
      </div>
      <div class="content">
        <app-admin-booking hideAdditionals="true" hideHours="true" hideHolydays="true" *ngIf="step == 3"></app-admin-booking>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Marcas y Modelos</ng-template>
    <div class="container">
      <div class="text">
        <p>Una parte importante del proceso de recepción de Vehículos es la identificación de la marca y modelo, por lo que necesesitaras una buena base de datos de estos.</p>
        <p>Es recomentable que ingreses el modelo de la forma mas detallada que puedas.</p>
        <p>No te preocupes si crees que la lista es muy larga, puedes utilizar la importación automatica si trabajas con Mazda o Suzuki</p>
      </div>
      <div class="content">
        <app-admin-brands [inView]="true" *ngIf="step == 4"></app-admin-brands>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Estados</ng-template>
    <div class="container">
      <div class="text">
        <p>Es necesario detallar los estados en los cuales un Vehículo podría estar.</p>
        <p>Intenta utilizar distintos colores para cada estado, para su facil identificación en la plataforma.</p>
      </div>
      <div class="content">
        @if (step == 5) {
          <app-status-form
            type="StatusForOrder"
            title="Estados de una orden de trabajo"
            [showNotifyClient]="true"
            [showStatuses]="true">
          </app-status-form>
        }
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Roles</ng-template>
    <div class="container">
      <div class="text">
        <p>Ingresa los cargos que existen en tu Taller y regula el acceso a la plataforma</p>
        <p>Ej: Técnicos, Asistentes de Calidad, Asistentes de Agendamientos, Gerente, etc</p>
      </div>
      <div class="content">
        <app-admin-roles *ngIf="step == 6"></app-admin-roles>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Usuarios</ng-template>
    <div class="container">
      <div class="text">
        <p>Aquí puedes crear nuevos usuarios.</p>
        <p>Para que un usuario tenga acceso a la plataforma, debe estar ACTIVO y ademas con un Rol Asignado.</p>
        <p>Lo usuarios se pueden registrar libremente a la plataforma, pero solo un administrador podrá activar su ingreso</p>
      </div>
      <div class="content">
        <app-admin-users *ngIf="step == 7"></app-admin-users>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Características avanzadas</ng-template>
    <div class="container">
      <div class="text center">
        <p>¡Felicidades! Ya puedes comenzar a utilizar <b>ControlCar</b> de forma básica, pero aun te quedan muchas funciones por configurar. </p>
        <p>Si deseas automatizar los procesos y configurar los trabajos continua al <button mat-button color="accent" (click)="goWiz()">Wizard de Automatización</button></p>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext (click)="goWiz()">Siguiente</button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
