import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class CheckListsItemsService extends BaseService implements ICRUDService{
  params = ['name']
  model = 'CheckListItem';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null,page = 1,check_list_id){
    return this.http.get(this.adminURL+'/check_lists/'+check_list_id+'/check_list_items',this.getParams({search: value, page: page}));
  }
  create(value, check_list_id){
    return this.http.post(this.adminURL+'/check_lists/'+check_list_id+'/check_list_items',{
      check_list_item: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value, check_list_id){
    return this.http.put(this.adminURL+'/check_lists/'+check_list_id+'/check_list_items/'+id,{
      check_list_item: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id, check_list_id){
    return this.http.delete(this.adminURL+'/check_lists/'+check_list_id+'/check_list_items/'+id,this.getParams());
  }
  restore(id, check_list_id){
    return this.http.delete(this.adminURL+'/check_lists/'+check_list_id+'/check_list_items/'+id+'/restore',this.getParams());
  }
}
