import {Injectable} from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import {Observable} from 'rxjs'
import {catchError, map} from 'rxjs/operators'
import * as CryptoJS from 'crypto-js';

@Injectable()
export class DecryptInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse && event.headers.has('encrypted-body')) {
          const response = event;
          const decryptedResponse = this.getDecryptedResponse(response);
          return decryptedResponse;
        }

        return event;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.headers.has('encrypted-body')) {
          const response = error;
          const decryptedError = this.getDecryptedErrorResponse(response);
          throw decryptedError;
        }

        throw error;
      })
    )
  }

  private getDecryptedResponse(response: HttpResponse<any>){
    const decryptedBody = this.getDecryptedBodyFrom(response)
    return new HttpResponse({
      body: decryptedBody,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      url: response.url || undefined
    })
  }

  private getDecryptedErrorResponse(response: HttpErrorResponse){
    const decryptedBody = this.getDecryptedBodyFrom(response)
    return new HttpErrorResponse({
      error: decryptedBody,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      url: response.url || undefined
    })
  }


  private getDecryptedBodyFrom(response: HttpResponse<any> | HttpErrorResponse) {
    const body = response instanceof HttpResponse ? response.body : response.error
    const [ciphertext, iv] = body.data.split("|");
    const keyDecoded = CryptoJS.enc.Base64.parse(localStorage.getItem('encrypt_key'));
    const ivDecoded = CryptoJS.enc.Base64.parse(iv);
    const decryptedWA = CryptoJS.AES.decrypt(ciphertext, keyDecoded, {
      iv: ivDecoded
    });
    const decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedUtf8);
  }
}
