<div class="wrapper" #wrapper>
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <mat-card appearance="outlined" *ngIf="budget">
    <mat-card-content>
      <app-budget-view [budget]="budget" [clientMode]="true"></app-budget-view>
    </mat-card-content>
  </mat-card>
</div>

