declare global {
  interface Window {
    
    fbAsyncInit: () => void;
  }
}

declare var FB: any;

import { Component, OnInit, OnDestroy, afterNextRender } from '@angular/core';
import { WhatsappService } from 'app/admin/services/whatsapp.service';

@Component({
  selector: 'app-admin-whatsapp',
  templateUrl: './admin-whatsapp.component.html',
  styleUrl: './admin-whatsapp.component.scss'
})

export class AdminWhatsappComponent implements OnInit{

  hasToken: boolean = false;
  whatsapptoken: string = '';

  constructor(private whatsappService: WhatsappService) { 
    afterNextRender(() => { 
      this.loadFacebookSDK();
    }); 
  }

  ngOnInit() {
    this.whatsappService.index().subscribe(
      (response: any) => {
        // Check if the token exists in the response
        this.hasToken = response.has_token;
      },
      (error) => {
        //console.log('Error fetching WhatsApp config:', error);
      }
    );
  }
  

  

  ngOnDestroy() {
    // Cierra la sesion del usuario cuando sale de la pagina, validar si es necesario
    // delete window.fbAsyncInit;
  }

  loadFacebookSDK() {
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function() {
      FB.init({
        appId            : '606950480953275', //606950480953275 es el de controlcar y 728331664915094 es el de kogu
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v19.0'
      });
    };
  }


 // Facebook Login with JavaScript SDK
  launchWhatsAppSignup() {
  // Launch Facebook login
      FB.login((response) => {
       // console.log('Full response:', response);
    if (response.authResponse) {
      const accessToken = response.authResponse.code;
      this.whatsapptoken = accessToken;
      //Borrar despues: solo para pruebas
      // console.log('Access Token:', accessToken);

      this.whatsappService.create({ token: this.whatsapptoken }).subscribe(
        (response) => {
          console.log('Token sent to backend successfully');
        },
        (error) => {
          console.log('Error sending token to backend:', error);
          //console.log('Error details:', JSON.stringify(error, null, 2)); // This line will print the full error object
        }
      );
      
    } else {
              console.log('User cancelled login or did not fully authorize.');
    }
  }, {
    config_id: '807826647958825', // 807826647958825 es el de controlcar y 1506352696612903 es el de kogu
    response_type: 'code', // must be set to 'code' for System User access token
    override_default_response_type: true,

  });
}
}
