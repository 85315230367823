import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class WorkUserSpecialitiesService extends BaseService implements ICRUDService{
  params = ['user_id','enable','speciality_id','speciality_type']
  model = 'WorkUserSpeciality';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null,page = 1){
    return this.http.get(this.adminURL+'/work_user_specialities',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/work_user_specialities/',{
      work_user_speciality: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/work_user_specialities/'+id,{
      work_user_speciality: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/work_user_specialities/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/work_user_specialities/'+id+'/restore',this.getParams());
  }
}
