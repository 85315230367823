import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-vehicles-dialog',
  templateUrl: './vehicles-dialog.component.html',
  styleUrls: ['./vehicles-dialog.component.scss']
})
export class VehiclesDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public vehicles,
    private matDialogRef: MatDialogRef<VehiclesDialogComponent>
  ) {

  }
  ngOnInit() {

  }
  selectVehicle(vehicle: any) {
    this.matDialogRef.close(vehicle);
  }

}
