<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración de Sistemas de Pago</mat-card-title>
    <mat-card-subtitle>Administre sus pagos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="hours">
          <div class="mercado-data" *ngIf="!loading && mercadopago">
            <div class="subtitle">Expira en {{mercadopago.expires_in/(60*60*24)}} días</div>
            <div class="subtitle">Producción: {{mercadopago.live_mode ? 'Sí' : 'No'}}</div>
            <div class="subtitle">ID usuario: {{mercadopago.user_id}}</div>
            <div class="check"><i class="fa fa-check-circle"></i></div>
          </div>
          <button class="button" mat-raised-button color=primary (click)="openMpagoLink()" *ngIf="!loading">Enlazar Cuenta de Mercado Pago</button>
          <mat-progress-spinner mode="indeterminate" *ngIf="loading"></mat-progress-spinner>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>
