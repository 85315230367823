
<section class="mat-typography">
  <div class="title">{{title}}</div>
</section>
<form [formGroup]="form" class="form">
  <button mat-icon-button (click)="addProductControl()" color="accent" class="add-product-btn">
    <mat-icon>add</mat-icon>
  </button>

  <div class="form-row">
    <mat-form-field>
      <mat-label>Bodega</mat-label>
      <mat-select formControlName="warehouse_id" required>
        <mat-option *ngFor="let warehouse of warehouses$ | async" [value]="warehouse.id">
          {{ warehouse.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field>
      <mat-label>Fecha estimada de culminación</mat-label>
      <input matInput [matDatepicker]="estimated_completion_date" formControlName="estimated_completion_date">
      <mat-datepicker-toggle matSuffix [for]="estimated_completion_date"></mat-datepicker-toggle>
      <mat-datepicker #estimated_completion_date [startAt]=""></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-col" formArrayName="inventory_action_products_attributes">
    <div
      class="form-control"
      *ngFor="let productCtl of inventoryActionProducts.controls; let i = index" [formGroupName]="i">
      <div class="form-row">
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="product_id"
            name="product_id"
            placeholder="Producto"
            [matAutocomplete]="auto"
            required />
          <mat-autocomplete #auto [displayWith]="displayProduct">
            <mat-option *ngFor="let option of filteredProducts$[i] | async" [value]="option">
              {{ option | product }}
            </mat-option>
            @if (data.edit) {
              @for(product of data.inventoryAction.inventory_action_products; track product.id) {
                <mat-option [value]="product.product">
                  {{ product.product | product }}
                </mat-option>
              }
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Cantidad</mat-label>
          <input matInput formControlName="quantity" type="number" [min]="1">
        </mat-form-field>
        <button mat-icon-button (click)="removeProductControl(i)" color="warn" class="remove-product-btn">
          <mat-icon>delete</mat-icon>
        </button>
        @if (!isValidProduct(i)) {
          <i class="fa fa-exclamation-triangle" matTooltip="No hay suficiente stock para la cantidad seleccionada"></i>
        }
      </div>
    </div>
  </div>

  @if (data.edit) {
    <button
      mat-raised-button
      type="button"
      color="accent"
      [disabled]="!form.valid"
      (click)="editInventoryActionInstantly()">
        Editar instantáneamente
    </button>
    <button
      mat-raised-button
      type="button"
      color="accent"
      [disabled]="!form.valid"
      (click)="editInventoryAction()">
        Editar
      </button>
  } @else {
    <button mat-raised-button type="button" color="accent" [disabled]="!form.valid" (click)="saveForm()">Guardar</button>
  }
</form>
