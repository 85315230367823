<div class="wrapper">
  <mat-spinner *ngIf="!specialities"></mat-spinner>
  <div class="checklist" *ngIf="specialities">
    <div class="content">
      <div class="subtitle">Especialidades de Requerimiento</div>
      <div class="list">
        <div class="check" *ngFor="let requirement of requirements"><mat-checkbox [checked]="getValue('Requirement',requirement.id)" (change)="setValue('Requirement',requirement.id,$event)">{{requirement.name}}</mat-checkbox></div>
        <div class="check empty" *ngIf="!requirements || requirements.length == 0">No hay items para completar</div>
      </div>
    </div>
  </div>
</div>
