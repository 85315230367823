import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {AnalyticsService} from "../../services/analytics.service";
import {SharesService} from "../../shares/services/shares.service";
import Highcharts from "highcharts";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  standalone: true
})
export class ChartComponent implements OnInit, OnChanges {
  @Input() x:string;
  @Input() y:string;
  @Input() stacked:boolean;
  @Input() group:string;
  @Input() filters = {};
  @Input() type:string;
  @Input() title;
  @Input() where = {};
  @Input() code = '';
  @Input() y_filters = {};
  @Input() custom: {key: string, name?: string, type?: string}[];
  @Input() x_filters = {};
  @Input() mode = 'complete';
  @Input() dataLabelSuffix = '';
  @Input() snapshot_key;
  @Output() dataReady = new EventEmitter()
  @ViewChild('chart', { static: true }) chart:ElementRef;
  data = [];
  drill: any = {series: []};
  constructor(
    private analyticsService: AnalyticsService,
    private sharesService: SharesService
  ) {
  }

  ngOnInit() {
  }
  ngOnChanges() {
    Promise.all(this.custom.map(c => this.getData(c.key))).then(data => {
      const config = this.generateChartConfigFrom(data);
      this.renderChartWith(config);
    });
  }

  getData(custom_key:string){
    const params = {
      x: this.x,
      y: this.y,
      group: this.group,
      y_filters: this.y_filters,
      x_filters: this.x_filters,
      where: this.where,
      custom: custom_key
    }
    if(custom_key.startsWith('shares')) {
      this.sharesService.generate(params, this.filters, this.snapshot_key)
    }

    return this.analyticsService.generate(params, this.filters, this.snapshot_key);
  }

  generateChartConfigFrom(data:any[]){
    const self = this;
    const multiple = this.custom.length > 1;
    const series = data.flatMap((d,index) => {
      return this.transformData(
        d,
        this.custom[index].name,
        this.custom[index].type
      );
    });

    return {
      chart: {
        type: this.type,

        backgroundColor: 'transparent',
        style: {
          fontFamily: 'monospace',
          color: "#f00"
        }
      },
      colors: [
        '#058DC7', '#50B432', '#ED561B',
        '#DDDF00', '#24CBE5', '#64E572',
        '#FF9655', '#FFF263', '#6AF9C4'
      ],
      credits: {
        enabled: false
      },
      drilldown: multiple? null: this.drill,
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          showInLegend: false,
          groupPadding: 0,
        },
        bar: {
          dataLabels: {
            enabled: true,
            color: this.stacked ? '#FFFFFF': '#000000',
            formatter: function(){
              return self.dataLabelFormatter(this.y)
            },
            style: {
              textOutline: 'none',
              fontFamily: 'monospace',
            }
          },
          groupPadding: 0.1
        },
        column: {
          pointPadding: 0,
          dataLabels: {
            enabled: true,
            color: this.stacked ? '#FFFFFF': '#000000',
            borderColor: 'transparent',
            formatter: function () {
              return self.dataLabelFormatter(this.y)
            },
            style: {
              textOutline: 'none',
              fontFamily: 'monospace',
            }
          },
          stacking: this.stacked ? 'normal': null
        }
      },
      title: {
        text: null
      },
      tooltip: {
        xDateFormat: '%e %b, %Y',
        valueDecimals: 2,
        shared: multiple ? true : this.group !== undefined,
        useHTML: true
      },
      xAxis: {
        categories: this.transformGroups(data[0], this.custom[0].type),
        labels: {
          enabled: self.type === 'bar',
          step: 1,
          style: {
            fontSize: '8px',
            fontFamily: 'lato'
          },
        }
      },
      yAxis: {
        labels: {
          enabled: self.type === 'bar',
          overflow: 'justify',
        },
        gridLineWidth: '1px',
        minorGridLineWidth: '1px',
        min: 0,
        title: {
          text: null,
        }
      },
      series: series
    };
  }

  renderChartWith(config){
    this.dataReady.emit({ values: config.series, x: config.xAxis.categories });
    (Highcharts as any).chart(this.chart.nativeElement, config);
  }

  transformGroups(data, type){
    if (!data) return [];

    if(['spline', 'bar'].indexOf(type) >=0){
      const new_groups = []
      data.data.forEach((value) => {
        new_groups.push(value.name);
      });
      return new_groups;
    }else{
      return data.groups;
    }
  }

  transformData(data:{groups:any[],data:any[]},name?,type?){
    if (!data) return [];

    let values:any[] = data.data
    if(!type){
      type = this.type;
    }
    if (['bar'].indexOf(type) >= 0) {
      const dataValues = values.map((value, index, array) => {
        return value.data[0]
      });
      return {
        name: name || 'Data',
        data: dataValues,
      }
    }

    else if (['column'].indexOf(type) >= 0) {
      values = values.map((value, index, array) => {
        return {
          name: value.name || name,
          data: value.data,
          type: type,
          visible: value.data.reduce((a, b) => a + b, 0) > 0
        };
      });
    }

    else if (['pie'].indexOf(type) >= 0) {
      let new_data = [];
      if(data.groups && data.groups.length > 1){
        new_data = [{
          name: name || 'Datos',
          type: this.type,
          data: data.groups.map((value,index)=>{
            return {
              name: value,
              y: data.data.reduce((previousValue, currentValue) => {
                return previousValue + currentValue.data[index];
              },0),
              drilldown: value
            };
          })
        }];
        data.groups.forEach((value,index)=>{
          this.drill.series.push({
            name: value,
            id: value,
            type: type,
            data: data.data.filter((point)=>point.data[index]!==0).map((point)=>[point.name,point.data[index]])
          });
        });
        values = new_data;
      } else {
        new_data = [{
          name: name || 'Datos',
          data: []
        }];
        values.forEach((value, index, array) => {
          new_data[0].data.push({
            name: value.name || name,
            y: value.data[0],
            type: this.type,
            visible: value.data.reduce((a, b) => a + b, 0) > 0
          });
        });
        values = new_data;
      }
    }

    else if (['spline'].indexOf(type) >=0) {
      const new_data = [{
        name: name || 'Datos',
        type: type,
        data: []
      }];
      values.forEach((value, index, array) => {
        new_data[0].data.push(value.data[0]);
      });
      values = new_data;
    }

    return values;
  }

  dataLabelFormatter(value) {
    if(!value) {
      return '';
    }
    if(value % 1){
      return Highcharts.numberFormat(value, 1) + this.dataLabelSuffix;
    }

    return `${value}${this.dataLabelSuffix}`;
  }
}
