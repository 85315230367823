/* eslint-disable no-underscore-dangle */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormGroup, UntypedFormBuilder,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderComponent } from 'app/main/order/order.component';
import { Category } from 'app/models/category.model';
import { generateInventoryActionFormGroup } from 'app/models/inventory_action.model';
import { Status } from 'app/models/status.model';
import { Tag } from 'app/models/tag.model';
import { CategoriesService } from 'app/services/categories.service';
import { ZendeskService } from 'app/services/zendesk.service';
import { cloneDeep } from 'lodash-es';
import {
  catchError, combineLatest, of,
} from 'rxjs';
import {
  debounceTime, first,
} from 'rxjs/operators';

import { AdminService } from '../../admin/services/admin.service';
import { StatusesService } from '../../admin/services/statuses.service';
import { ConfirmDialogComponent } from '../../main/confirm-dialog/confirm-dialog.component';
import { RoleService } from '../../role/role.service';
import { InventoryActionService } from '../../services/inventory-action.service';
import { PDFService } from '../../services/pdf.service';
import { ImageUploaderComponent } from '../../shared/image-uploader/image-uploader.component';
import { WorkRequestComponent } from '../../work/work-request/work-request.component';
import { BudgetService } from '../budget.service';

function formatProduct(product) {
  return {
    item_description: product.code,
    item_quantity: product.quantity,
    item_name: product.description,
    item_price: product.price,
  };
}

@Component({
  selector: 'app-budget-view',
  templateUrl: './budget-view.component.html',
  styleUrls: ['./budget-view.component.scss', './budget-simple-view.component.scss'],
})
export class BudgetViewComponent extends ImageUploaderComponent implements OnInit, OnDestroy {
  @Input() budget;

  @Input() clientMode = false;

  @Input() id;

  @Output() close = new EventEmitter();

  bouncer;

  budget_view = 'simple';

  categories: Category[] = [];

  company_logo;

  country;

  forms = {};

  inventory_actions_form = this.fb.array([]);

  loading = false;

  order_id;

  statuses: Status[] = [];

  saveBouncer;

  saved = true;

  saving = false;

  selectedTag;

  tags: Tag[] = [];

  totals: Record<number, number> = {};

  working = false;

  budgetForm = this.fb.group({
    custom_fields: this.fb.group({}),
  });

  @ViewChildren('pictures') pictures:QueryList<ElementRef>;

  @ViewChild('wrapper', { static: true }) wrapper;

  constructor(
  @Optional() @Inject(MAT_DIALOG_DATA) budget,
    @Optional() private matDialogRef: MatDialogRef<BudgetViewComponent>,
    private budgetService: BudgetService,
    private categoriesService: CategoriesService,
    public adminService: AdminService,
    public roleService: RoleService,
    public snackBar: MatSnackBar,
    private statusService: StatusesService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private inventoryActionService: InventoryActionService,
    private pdfService: PDFService,
    private route: ActivatedRoute,
    private router: Router,
    private zendesk: ZendeskService,
    sanitizer: DomSanitizer,

  ) {
    super(null, null, null, null, sanitizer);
    if (budget) {
      this.id = budget.id;
    }
  }

  ngOnInit() {
    if (!(this.matDialogRef instanceof MatDialogRef)) {
      this.adminService.pageTitle.set('Presupuestos');
    }
    this.company_logo = this.adminService.local$.getValue().preferences.logo || 'assets/images/kongu.png';
    this.country = this.adminService.local$.getValue().country;
    this.budget_view = this.adminService.local$.getValue().preferences.budget_type || 'simple';
    this.route.params.pipe(first()).subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }

      combineLatest([
        this.statusService.all('StatusForBudget'),
        this.categoriesService.index(),
        this.budgetService.tags(),
      ])
        .subscribe((value) => {
          this.statuses = value[0].body;
          this.categories = [...value[1]];
          this.tags = value[2].body;
          if (this.budget) {
            this.setup();
          }
          if (!this.clientMode) {
            this.load();
          }
        });
    });
    this.zendesk.hide();
  }

  load() {
    this.loading = true;
    this.budgetService.get(this.id).toPromise().then((value) => {
      this.budget = value.body;
      this.setup();
    });
  }

  setup() {
    if (this.budget) {
      this.calculateValues();
      this.setupInventoryActions();
      this.budgetForm.patchValue(this.budget);
      this.handleBudgetFormChanges();
      this.loading = false;
    }
  }

  setupInventoryActions() {
    this.inventory_actions_form = this.fb.array(
      this.budget.inventory_actions.map((inventoryAction) => generateInventoryActionFormGroup(inventoryAction)),
    );
    this.calculateValues();
  }

  handleBudgetFormChanges() {
    this.bouncer = this.budgetForm.valueChanges.pipe(
      debounceTime(1000),
    ).subscribe(() => {
      this.saved = false;
      this.saveBudget();
    });
  }

  removeItem(index) {
    const inventoryActionId = this.inventory_actions_form.at(index).get('id').value;

    this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Eliminar producto', body: '' },
    }).afterClosed().subscribe((result) => {
      if (result) {
        if (!inventoryActionId) {
          this.inventory_actions_form.removeAt(index);
          return;
        }

        this.inventoryActionService.delete(inventoryActionId)
          .pipe(
            catchError(() => {
              this.snackBar.open('El item no se puede eliminar.')._dismissAfter(2000);
              return of(null);
            }),
          )
          .subscribe((response) => {
            if (!response) return;

            this.inventory_actions_form.removeAt(index);
            this.calculateValues();
          });
      }
    });
  }

  updateInventoryAction(index: number) {
    const formGroup = this.inventory_actions_form.at(index) as FormGroup;
    const inventoryActionRawData = formGroup.getRawValue();
    if (inventoryActionRawData.status.locked) return;

    console.log(formGroup.getRawValue().pictures);

    const inventoryActionData = {
      ...inventoryActionRawData,
      inventory_action_products_attributes: inventoryActionRawData.inventory_action_products,
      inventory_action_products: undefined,
      pictures_attributes: inventoryActionRawData.pictures,
      pictures: undefined,
    };
    this.saving = true;
    if (inventoryActionData.id) {
      this.inventoryActionService.update(
        inventoryActionData.id,
        inventoryActionData,
      ).subscribe((inventoryAction) => {
        this.saving = false;
        if (!inventoryAction) return;

        (<FormGroup> this.inventory_actions_form.at(index)).controls.pictures = this.fb.array(
          inventoryAction.pictures.map((picture) => this.fb.group(picture)),
        );

        (<FormArray> this.inventory_actions_form.at(index).get('inventory_action_products')).controls.forEach(
          (control, index1) => {
            if (control.value._destroy) {
              (<FormArray> this.inventory_actions_form.at(index).get('inventory_action_products')).removeAt(index1);
            } else {
              const inventoryActionProduct = (inventoryAction.inventory_action_products as any[]).find(
                (product) => product.product.code === control.getRawValue().code,
              );
              if (inventoryActionProduct) {
                control.patchValue(inventoryActionProduct, { emitEvent: false });
              }
            }
          },
        );

        this.calculateValues();
      });
    }
  }

  createInventoryAction(tag: Tag) {
    this.selectedTag = tag;

    this.saving = true;
    this.inventoryActionService.create(
      {
        inventory_action: {
          document_id: this.budget.id,
          document_type: 'Budget',
          tag_id: tag.id,
          type: 'InventoryActionForConsume',
          warehouse_id: this.budget.warehouse_id,
          estimated_completion_date: this.budget.order.finish_date,
        },
      },
    ).subscribe((inventoryAction) => {
      this.saving = false;
      if (!inventoryAction) return;

      this.inventory_actions_form.push(generateInventoryActionFormGroup(inventoryAction), { emitEvent: false });
      this.calculateValues();
    });
  }

  setImagesToInventoryAction(formGroup: FormGroup, index: number, pictures) {
    const oldValue = formGroup.get('pictures').getRawValue();

    formGroup.controls.pictures = this.fb.array(
      pictures.map((picture) => {
        const oldPicture = !picture.id && oldValue.find((p) => p.url === picture.url);
        if (oldPicture) {
          return this.fb.group(oldPicture);
        }

        return this.fb.group(picture);
      }),
    );

    this.updateInventoryAction(index);
  }

  setImages(pictures) {
    this.saving = true;
    this.budgetService.save(this.id || this.budget.id, {
      pictures,
    }).toPromise().then(() => {
      this.saving = false;
    });
  }

  getTag(tag_id: number) {
    return this.tags.find((t) => t.id === tag_id);
  }

  getTagCategories(tag_id: number) {
    return this.getTag(tag_id)?.category_ids;
  }

  getMarkerNumber(id) {
    return this.budget.markers.findIndex((m) => m.id === id) + 1;
  }

  saveBudget(updateModel = false) {
    this.saving = true;
    this.calculateValues();
    this.budgetService.save(this.id || this.budget.id, this.budgetForm.value).toPromise().then((value) => {
      this.saving = false;
      if (updateModel) {
        this.budget = cloneDeep(value.body);
      }
      this.saved = true;
      this.calculateValues();
    }).catch(() => {
    });
  }

  approveAll() {
    this.dialog.open(
      ConfirmDialogComponent,
      { data: { title: '¿Aprobar todos los Items?', body: `Esto completara el estado de ${this.budget.status.name}` } },
    )
      .afterClosed().subscribe((result) => {
        if (result) {
          this.working = true;
          this.budgetService.approve_all(this.budget.id).subscribe({
            complete: () => {
              this.working = false;
              this.saveBudget(true);
              this.load();
            },
          });
        }
      });
  }

  declineAll() {
    this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: '¿Rechazar todos los Items?',
          body: `Esto completara el estado de ${this.budget.status.name}`,
        },
      },
    )
      .afterClosed().subscribe((result) => {
        if (result) {
          this.working = true;
          this.budgetService.decline_all(this.budget.id).subscribe({
            complete: () => {
              this.working = false;
              this.saveBudget(true);
              this.load();
            },
          });
        }
      });
  }

  saveComment(value) {
    this.saving = true;
    this.budgetService.save(
      this.id || this.budget.id,
      { comments: [value] },
    ).toPromise().then((response: HttpResponse<{ comments: string[] }>) => {
      this.budget.comments = response.body.comments;
      this.budget = cloneDeep(this.budget);
      this.saving = false;
    }).catch((error) => {
      console.log(error);
    });
  }

  calculateValues() {
    if (this.budget) {
      const categoryIds = this.tags.map((t) => t.category_ids).flat();
      const categories = this.categories.filter((c) => categoryIds.includes(c.id));

      categories.forEach((category) => {
        this.totals[category.id] = 0;
      });
      this.inventory_actions_form.getRawValue().filter((ia) => !ia.status.failed).forEach((inventoryAction) => {
        inventoryAction.inventory_action_products
          .filter((product) => !product._destroy)
          .forEach((product) => {
            if (!this.totals[product.category_id]) {
              this.totals[product.category_id] = product.amount * product.quantity;
            } else {
              this.totals[product.category_id] += product.amount * product.quantity;
            }
          });
      });

      this.budget.total = Object.values(this.totals).reduce((a, b) => a + b, 0) - this.discountTotal;
    }
  }

  get discountTotal() {
    return this.inventory_actions_form.getRawValue()
      .map((inventoryAction) => inventoryAction.inventory_action_products.map(
        (product) => product.amount * ((product.discount_percent || 0) / 100) * product.quantity,
      ).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0);
  }

  goBack() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    } else {
      this.router.navigate(['main', 'budgets']);
    }
  }

  goOrder() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.dialog.open(OrderComponent, { data: this.budget.order_id, minWidth: '90%' });
    } else {
      this.router.navigate(['main', 'orders', this.budget.order_id], { queryParams: { budget: this.budget.id } });
    }
  }

  addWork(product) {
    this.dialog.open(WorkRequestComponent, {
      data:
      {
        document_id: this.id || this.budget.id,
        document_type: 'Budget',
        status_id: this.budget.status_id,
        custom_params: { order_items: [formatProduct(product)] },
      },
    }).afterClosed().toPromise().then(() => {
      this.saveBudget(true);
    });
  }

  setStatus(status: number) {
    this.dialog.open(
      ConfirmDialogComponent,
      { data: { title: 'Actualizar Estado' } },
    ).afterClosed().toPromise().then((result) => {
      if (result) {
        this.budget.status_id = status;
        this.working = true;
        this.budgetService.save(this.id || this.budget.id, { status_id: status }).toPromise().then((response) => {
          this.budget = response.body;
          this.working = false;
          this.setup();
        }).catch((error:HttpErrorResponse) => {
          console.log(error);
        });
      }
    });
  }

  print() {
    this.pdfService.getBudget(this.budget.id, `Presupuesto_${this.budget.correlative_id}`);
  }

  remigrate() {
    this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Restaurar Presupuesto',
          body: 'Esta acción reemplazará todos los items actuales y es irreversible. ¿Está seguro que desea continuar?',
        },
      },
    ).afterClosed().subscribe((result) => {
      if (result) {
        this.working = true;
        this.budgetService.remigrate(this.budget.id).subscribe(() => {
          this.working = false;
          this.load();
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.bouncer) {
      this.bouncer.unsubscribe();
    }
    if (this.saveBouncer) {
      this.saveBouncer.unsubscribe();
    }
    this.zendesk.show();
  }

  duplicateInventoryAction(index: number) {
    this.working = true;
    const inventoryActionId = this.inventory_actions_form.at(index).get('id').value;
    
    this.inventoryActionService.clone(inventoryActionId).subscribe(
      (duplicatedInventoryAction) => {
        if (duplicatedInventoryAction) {
          this.inventory_actions_form.push(generateInventoryActionFormGroup(duplicatedInventoryAction));
          this.calculateValues();
        }},null, () => {
        this.working = false;
      }
    );
  }
}
