@if (error) {
  <p>Error al cargar la información</p>
} @else if ( request.order.inventory_action_for_consume ) {

  <div class="subtitle" style="margin-bottom: 10px;">Introducir el detalle de {{request.order.requirement.name}}</div>
  <app-inventory-action-form
    [parentFormGroup]="form"
    (dataChanged)="updateInventoryAction()"
    [brandId]="this.request?.order?.object?.brand?.id"
  >
  </app-inventory-action-form>
}
