import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonFormsModule } from 'app/shared/common-forms/common-forms.module';
import { NgxCrudModule } from 'app/shared/crud-manager/ngx-crud.module';
import { MultiSelectDropdownComponent } from 'app/shared/multi-select-dropdown/multi-select-dropdown.component';
import { MccColorPickerModule } from 'material-community-components/color-picker';

import { ClientModule } from '../client/client.module';
import { LoginModule } from '../login/login.module';
import { RoleModule } from '../role/role.module';
import { BaseModule } from '../shared/base.module';
import { WorkUserSpecialityComponent } from '../work/work-user-speciality/work-user-speciality.component';
import { AdminComponent } from './admin.component';
import { CanActivateAdmin, CanActivateManage } from './admin.permissions';
import { CompanyLoginComponent } from './admin-login/company-login.component';
import { AdminAdditionalsComponent } from './admin-main/admin-additionals/admin-additionals.component';
import { AdminApisComponent } from './admin-main/admin-apis/admin-apis.component';
import { AdminAutomationWizardComponent } from './admin-main/admin-automation-wizard/admin-automation-wizard.component';
import { AdminBookingComponent } from './admin-main/admin-booking/admin-booking.component';
import { AdminBrandsComponent } from './admin-main/admin-brands/admin-brands.component';
import { AdminBudgetsComponent } from './admin-main/admin-budgets/admin-budgets.component';
import { AdminCheckListComponent } from './admin-main/admin-check-list/admin-check-list.component';
import { AdminCheckListItemsComponent } from './admin-main/admin-check-list-items/admin-check-list-items.component';
import { AdminClientsComponent } from './admin-main/admin-clients/admin-clients.component';
import { AdminCompanyComponent } from './admin-main/admin-company/admin-company.component';
import { AdminCrmComponent } from './admin-main/admin-crm/admin-crm.component';
import { AdminCustomFieldsComponent } from './admin-main/admin-custom-fields/admin-custom-fields.component';
import { AdminInventoryComponent } from './admin-main/admin-inventory/admin-inventory.component';
import { AdminKitProductsComponent } from './admin-main/admin-kit-products/admin-kit-products.component';
import { AdminKitsComponent } from './admin-main/admin-kits/admin-kits.component';
import { AdminListsComponent } from './admin-main/admin-lists/admin-lists.component';
import { AdminLocationComponent } from './admin-main/admin-location/admin-location.component';
import { AdminMailingComponent } from './admin-main/admin-mailing/admin-mailing.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { AdminModelsComponent } from './admin-main/admin-models/admin-models.component';
import { AdminObjectComponent } from './admin-main/admin-object/admin-object.component';
import { AdminOrderFieldsComponent } from './admin-main/admin-order-fields/admin-order-fields.component';
import { AdminPatpassComponent } from './admin-main/admin-patpass/admin-patpass.component';
import { AdminPaymentComponent } from './admin-main/admin-payment/admin-payment.component';
import { AdminProductsComponent } from './admin-main/admin-products/admin-products.component';
import { AdminCategoriesComponent } from './admin-main/admin-products/categories/admin-categories.component';
import { AdminProductListComponent } from './admin-main/admin-products/product-list/product-list.component';
import { AdminRolesComponent } from './admin-main/admin-roles/admin-roles.component';
import { AdminSharesComponent } from './admin-main/admin-shares/admin-shares.component';
import { AdminSidemenuComponent } from './admin-main/admin-sidemenu/admin-sidemenu.component';
import { AdminTranslateComponent } from './admin-main/admin-translate/admin-translate.component';
import { AdminUsersComponent } from './admin-main/admin-users/admin-users.component';
import { AdminVehiclesComponent } from './admin-main/admin-vehicles/admin-vehicles.component';
import { AdminWarehousesComponent } from './admin-main/admin-warehouses/admin-warehouses.component';
import { AdminWhatsappComponent } from './admin-main/admin-whatsapp/admin-whatsapp.component';
import { AdminWizardComponent } from './admin-main/admin-wizard/admin-wizard.component';
import { AdminWorkPointComponent } from './admin-main/admin-work-point/admin-work-point.component';
import { AdminWorkflowComponent } from './admin-main/admin-workflow/admin-workflow.component';
import { AdminWorksComponent } from './admin-main/admin-works/admin-works.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  imports: [
    BaseModule,
    ClientModule,
    CommonFormsModule,
    DragDropModule,
    LoginModule,
    MatStepperModule,
    MccColorPickerModule.forRoot({}),
    MultiSelectDropdownComponent,
    NgxCrudModule,
    RoleModule,
    SharedModule,
  ],
  declarations: [
    AdminAdditionalsComponent,
    AdminApisComponent,
    AdminAutomationWizardComponent,
    AdminBookingComponent,
    AdminBrandsComponent,
    AdminBudgetsComponent,
    AdminCategoriesComponent,
    AdminCheckListComponent,
    AdminCheckListItemsComponent,
    AdminClientsComponent,
    AdminCompanyComponent,
    AdminComponent,
    AdminCrmComponent,
    AdminCustomFieldsComponent,
    AdminInventoryComponent,
    AdminKitProductsComponent,
    AdminKitsComponent,
    AdminListsComponent,
    AdminLocationComponent,
    AdminMailingComponent,
    AdminMainComponent,
    AdminModelsComponent,
    AdminObjectComponent,
    AdminOrderFieldsComponent,
    AdminPatpassComponent,
    AdminPaymentComponent,
    AdminProductsComponent,
    AdminProductListComponent,
    AdminRolesComponent,
    AdminSharesComponent,
    AdminSidemenuComponent,
    AdminTranslateComponent,
    AdminUsersComponent,
    AdminVehiclesComponent,
    AdminWarehousesComponent,
    AdminWhatsappComponent,
    AdminWizardComponent,
    AdminWorkflowComponent,
    AdminWorkPointComponent,
    AdminWorksComponent,
    CompanyLoginComponent,
    WorkUserSpecialityComponent,
  ],
  providers: [
    CanActivateAdmin,
    CanActivateManage,
  ],
  exports: [
    AdminComponent,
    AdminWizardComponent,
    CompanyLoginComponent,
  ],
})
export class AdminModule { }
