import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Field, FieldsService } from 'app/admin/services/fields.service';
import { WorkRequirementAvgService } from 'app/admin/services/work-requirement-avg.service';
import { WorksService } from 'app/admin/services/works.service';
import { Status } from 'app/models/status.model';
import { Role } from 'app/models/types';
import { RoleService } from 'app/role/role.service';
import { StatusesService } from 'app/services/statuses.service';
import { WorkService } from 'app/work/work.service';
import { Observable } from 'rxjs';

import { getResponsesOptionsFor } from './work-form.config';

interface Response {
  id: string;
  name: string;
}

@Component({
  selector: 'app-work-form',
  templateUrl: './work-form.component.html',
  styleUrls: ['./work-form.component.scss', '../common-forms.common.scss'],
})
export class WorkFormComponent implements OnInit {
  @Input({ required: true }) model: string;

  revisionTypes = [
    { id: 'none', name: 'Ninguno' },
    { id: 'score', name: 'Puntaje' },
    { id: 'boolean', name: 'Si/No' },
  ];

  form = this.fb.group({
    name: new FormControl('', Validators.required),
    status_id: new FormControl('', Validators.required),
    role_id: '',
    roles_id: new FormControl('', Validators.required),
    automatic_assignation: false,
    assignation_roles_id: [],
    custom: false,
    can_change_status: false,
    complete_child_works: true,
    custom_response_type: 'none',
    next_custom_work_ids: [],
    non_productive: false,
    global: false,
    sign_needed: false,
    sign_text: '',
    min_photos: 0,
    sign_roles: [],
    form_fields: [],
    require_revision: false,
    revision_roles_id: [],
    revision_type: 'none',
    revision_max_score: 100,
    custom_field_ids: [],
  });

  statuses: Observable<Status[]>;

  roles: Role[] = [];

  works = [];

  fields: Partial<Field>[] = [];

  responses: Response[] = [];

  customFields: Field[] = [];

  constructor(
    public worksService: WorksService,
    private statusService: StatusesService,
    private roleService: RoleService,
    private workService: WorkService,
    private fb: UntypedFormBuilder,
    public fieldsService: FieldsService,
    public workRequirementAvgService: WorkRequirementAvgService,
  ) { }

  ngOnInit() {
    const statusModelName = `StatusFor${this.model}`;
    this.statuses = this.statusService.statuses(statusModelName);

    this.roleService.simple_index().subscribe((value: HttpResponse<Role[]>) => {
      this.roles = value.body;
    });

    this.workService.customWorks({}).subscribe((value: HttpResponse<any[]>) => {
      this.works = value.body;
    });

    this.fieldsService.fields('FieldForWork').subscribe((fields) => {
      this.customFields = fields;
    });

    const fieldsModelName = `FieldFor${this.model}`;
    this.fieldsService.fields(fieldsModelName).subscribe((fields) => {
      this.fields = fields;
      this.fields.unshift({ key: 'total', title: 'Total Orden' });
      this.fields.unshift({ key: 'finish_date', title: 'Fecha Finalización' });
    });

    this.responses = getResponsesOptionsFor(this.model);
  }
}
