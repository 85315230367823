import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-main',
  templateUrl: './manager-main.component.html',
  styleUrl: './manager-main.component.scss'
})
export class ManagerMainComponent {

}
