import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
declare var zE: any;
@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  hidden = false;
  constructor(private router: Router) {
    
  }
  toggle(){
    if(this.hidden){
      this.show();
    }else{
      this.hide();
    }
  }
  show(){
    this.hidden = false;
    try{
      zE('messenger', 'show');
    }catch(e){
      zE('webWidget', 'show');
    }
  }
  hide(){
    this.hidden = true;
    try{
      zE('messenger', 'hide');
    }catch(e){
      zE('webWidget', 'hide');
    }
  }
  login(token){
    
    try{
      zE('messenger',"loginUser", function(callback){
        console.log("[ZENDESK] Auth Completed.");
        callback(token);
      });
    }catch(e){
      console.log(e);
    }
  }
  logout(){
    try{
      zE('messenger',"logoutUser");
    }catch(e){
      console.log(e);
    }
  }
}
