import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {OrderNotificationsService} from "../../services/order-notifications.service";
import { HttpResponse } from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-order-notifications',
  templateUrl: './order-notifications.component.html',
  styleUrls: ['./order-notifications.component.scss']
})
export class OrderNotificationsComponent implements OnInit {
  notifications:any[];
  constructor(
    private notificationService: OrderNotificationsService,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(){
    this.notificationService.index(this.data).toPromise().then((response: HttpResponse<any[]>)=>{
      this.fetchResponse(response);
    });
  }
  fetchResponse(response){
    this.notifications = response.body;
    this.notifications.forEach((val,index)=>{
      this.notifications[index].safeBody = this.domSanitizer.bypassSecurityTrustHtml(val.body);
      this.notifications[index].safeTitle = this.domSanitizer.bypassSecurityTrustHtml(val.title);
    });
  }

}
