import { ChangeDetectorRef, Component } from '@angular/core';
import { AdminService } from 'app/admin/services/admin.service';
import { FieldsService } from 'app/admin/services/fields.service';
import { allInventoryActionTypes } from 'app/models/inventory_action.model';
import { toUnderscore } from 'app/utils/string-utils';

@Component({
  selector: 'app-admin-inventory',
  templateUrl: './admin-inventory.component.html',
  styleUrls: ['./admin-inventory.component.scss', '../admin-main.common.scss'],
})
export class AdminInventoryComponent {
  public selectedInventoryActionType: string = 'InventoryActionForStock';

  public loading = false;

  public inventoryActionTypes: string[] = allInventoryActionTypes;

  constructor(
    private cdr: ChangeDetectorRef,
    public adminService: AdminService,
    public fieldsService: FieldsService,
  ) { }

  underscore(value: string): string {
    return toUnderscore(value);
  }

  onTypeSelected(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 50);
  }
}
