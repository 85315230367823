import { Component, HostBinding } from '@angular/core';
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrl: './manager.component.scss'
})
export class ManagerComponent {

  constructor(
    private zendDeskService: ZendeskService
  ) {
    this.zendDeskService.hide();
  }
}
