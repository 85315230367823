import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Requirement } from '../models/requirement.model';
import { BaseService } from './_base.service';
import { UserService } from './user.service';

@Injectable()
export class RequirementsService extends BaseService {
  requirements$ = new BehaviorSubject([]);

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
    this.all().toPromise().then((value:HttpResponse<Requirement[]>) => {
      this.requirements$.next(value.body || []);
    }).catch((error) => {
      console.log(error);
    });
  }

  updateRequirements() {
    this.all().toPromise().then((value:HttpResponse<Requirement[]>) => {
      this.requirements$.next(value.body || []);
    }).catch((error) => {
      console.log(error);
    });
  }

  all(location_id?: number) {
    return this.http.get<Requirement[]>(`${this.apiURL}/requirements`, this.getParams({ location_id }));
  }
}
