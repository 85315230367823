

  <div mat-dialog-title class="name">
    Columnas
  </div>
  
  <div mat-dialog-content class="content">

    <mat-checkbox class="grid-full" [(ngModel)]="selectAll" (change)="toggleSelectAll()">
      Todos
    </mat-checkbox>
    <br>
    <mat-checkbox class="grid" *ngFor="let option of columns" [(ngModel)]="option[1]" (change)="checkIfAllSelected()">
      {{option[2] | formatColumn}}
    </mat-checkbox>
  </div>


<div mat-dialog-actions class="footer">
  <button mat-button (click)="closeDialog()">Cancelar</button>
  <button mat-button cdkFocusInitial (click)="applyFilters()">Aplicar</button>
</div>
