import { FormControl } from '@angular/forms';

import { Product } from './product.model';
import { Warehouse } from './warehouse.model';

export interface InventoryActionProduct {
  id?: number;
  category_id: number;
  product_id: number;
  amount: number;
  discount_percent: number;
  quantity: number;
  product: Partial<Product>;
  unit: string;
  warehouse: Partial<Warehouse>;
  _destroy: boolean;
}

export function toInventoryActionProductForm(inventoryActionProduct: InventoryActionProduct) {
  return {
    id: new FormControl(inventoryActionProduct.id),
    quantity: new FormControl(inventoryActionProduct.quantity),
    product_id: new FormControl(inventoryActionProduct.product_id),
    category_id: new FormControl(inventoryActionProduct.category_id),
    discount_percent: new FormControl(inventoryActionProduct.discount_percent),
    code: new FormControl(inventoryActionProduct.product.code),
    unit: new FormControl(inventoryActionProduct.unit),
    amount: new FormControl(inventoryActionProduct.amount),
    description: new FormControl(inventoryActionProduct.product.name),
    _destroy: new FormControl(false),
  };
}
