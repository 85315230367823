<div class="wrapper">
  <div class="logo">
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
     width="481px" height="281px" viewBox="0 0 481 281" enable-background="new 0 0 481 281" xml:space="preserve">
      <g>
        <path fill="currentColor" d="M263.203,124.061c0,4.398-1.541,8.153-4.611,11.265c-3.076,3.113-6.817,4.667-11.221,4.667
        c-4.403,0-8.205-1.553-11.404-4.667c-3.198-3.112-4.799-6.867-4.799-11.265V65.56c0-4.397,1.563-8.152,4.689-11.263
        c3.132-3.113,6.907-4.668,11.328-4.668c4.421,0,8.196,1.555,11.321,4.668c3.132,3.111,4.696,6.866,4.696,11.263V124.061z
          M252.482,124.298V65.324c0-1.41-0.519-2.635-1.558-3.669c-1.036-1.037-2.263-1.557-3.674-1.557c-1.497,0-2.765,0.52-3.805,1.557
        c-1.037,1.035-1.559,2.259-1.559,3.669v58.975c0,1.409,0.522,2.633,1.559,3.67c1.04,1.035,2.308,1.554,3.805,1.554
        c1.412,0,2.638-0.519,3.674-1.554C251.963,126.932,252.482,125.708,252.482,124.298z"/>
        <path fill="currentColor" d="M302.838,65.56v73.809h-10.721V65.324c0-1.408-0.518-2.635-1.558-3.669
        c-1.04-1.037-2.266-1.557-3.678-1.557c-1.494,0-2.762,0.52-3.802,1.557c-1.04,1.035-1.555,2.261-1.555,3.669v74.045h-10.596V65.56
        c0-4.397,1.531-8.152,4.595-11.263c3.062-3.113,6.785-4.668,11.172-4.668c4.47,0,8.275,1.555,11.422,4.668
        C301.265,57.408,302.838,61.163,302.838,65.56z"/>
        <path fill="currentColor" d="M340.352,60.098h-10.468v79.271h-10.72V60.098h-10.971v-9.971h32.159V60.098z"/>
        <path fill="currentColor" d="M377.368,84.404c0,2.161-0.652,4.279-1.96,6.355c-1.308,2.078-3.019,3.449-5.141,4.112
        c4.736,1.914,7.102,5.403,7.102,10.471v34.027H366.9V104.86c0-3.168-1.579-4.917-4.735-5.252h-5.86v39.761h-10.718V50.126h15.892
        c4.385,0,8.129,1.559,11.233,4.673c3.104,3.116,4.656,6.877,4.656,11.28V84.404z M366.9,84.152V65.832
        c0-1.412-0.521-2.638-1.558-3.678c-1.04-1.038-2.305-1.558-3.802-1.558h-5.235v28.791h5.735
        C365.282,89.057,366.9,87.312,366.9,84.152z"/>
        <path fill="currentColor" d="M416.632,124.061c0,4.398-1.541,8.153-4.617,11.265c-3.069,3.113-6.813,4.667-11.214,4.667
        c-4.409,0-8.208-1.553-11.404-4.667c-3.198-3.112-4.802-6.867-4.802-11.265V65.56c0-4.397,1.567-8.152,4.692-11.263
        c3.125-3.113,6.906-4.668,11.324-4.668c4.422,0,8.197,1.555,11.324,4.668c3.126,3.111,4.696,6.866,4.696,11.263V124.061z
          M405.908,124.298V65.324c0-1.41-0.516-2.635-1.559-3.669c-1.039-1.037-2.265-1.557-3.674-1.557c-1.497,0-2.763,0.52-3.802,1.557
        c-1.04,1.035-1.559,2.259-1.559,3.669v58.975c0,1.409,0.519,2.633,1.559,3.67c1.039,1.035,2.305,1.554,3.802,1.554
        c1.409,0,2.635-0.519,3.674-1.554C405.393,126.932,405.908,125.708,405.908,124.298z"/>
        <path fill="currentColor" d="M450.78,139.369h-28.917V50.126h10.718v79.272h18.199V139.369z"/>
      </g>
      <g>
        <path fill="currentColor" d="M264.953,163.647v74.538h-10.718v-31.41H243.89v31.41h-10.721v-74.538c0-4.401,1.534-8.162,4.598-11.279
        c3.058-3.116,6.745-4.674,11.047-4.674c4.47,0,8.278,1.558,11.422,4.674C263.379,155.485,264.953,159.246,264.953,163.647z
          M254.235,196.804V163.4c0-1.413-0.491-2.637-1.479-3.677c-0.984-1.039-2.217-1.56-3.692-1.56c-1.479,0-2.71,0.521-3.696,1.56
        c-0.987,1.04-1.478,2.264-1.478,3.677v33.403H254.235z"/>
        <path fill="currentColor" d="M303.966,183.218c0,2.162-0.655,4.28-1.96,6.356c-1.308,2.078-3.021,3.449-5.144,4.114
        c4.735,1.913,7.104,5.401,7.104,10.469v34.028h-10.468v-34.512c0-3.167-1.582-4.914-4.738-5.25h-5.857v39.762h-10.718v-89.243
        h15.889c4.387,0,8.128,1.558,11.236,4.674c3.104,3.117,4.656,6.877,4.656,11.279V183.218z M293.498,182.968v-18.322
        c0-1.412-0.524-2.637-1.558-3.677c-1.04-1.037-2.308-1.558-3.805-1.558h-5.232v28.791h5.732
        C291.876,187.873,293.498,186.127,293.498,182.968z"/>
      </g>
      <path fill="currentColor" d="M65.195,61.311C54.852,71.655,46.732,83.702,41.059,97.116c-5.88,13.894-8.858,28.647-8.858,43.85
        c0,15.204,2.979,29.957,8.858,43.853c5.674,13.415,13.793,25.462,24.137,35.805c3.134,3.135,5.222,5.096,8.698,7.841l11.858-14.53
        c-16.037-12.682-26.729-29.69-31.838-49.327c-0.108-0.557-0.929-5.212,1.277-8.088c0.906-1.184,2.192-1.897,3.821-2.125
        c-0.345-14.241,3.739-24.253,7.252-30.166c1.206-2.023,2.535-3.916,3.965-5.641c-0.38,0.022-0.759,0.033-1.126,0.033
        c-6.132,0-9.125-2.958-10.364-4.722c-1.59-2.267-2.125-5.103-1.361-7.226l0.208-0.511c1.395-3.188,4.647-5.782,7.257-7.865
        c0.835-0.669,1.624-1.296,2.222-1.848c2.383-2.192,6.533-7.706,7.385-10.833c0.904-3.314,9.405-15.795,9.765-16.325l0.104-0.115
        c0.622-0.525-0.014-0.016,0.622-0.525L73.757,53.572C70.389,56.251,68.264,58.241,65.195,61.311z"/>
      <path fill="currentColor" d="M201.883,215.782c-2.492,1.894-5.071,3.663-7.682,5.268c-2.816,1.697-12.547,7.229-19.537,7.229
        c-2.088,0-3.783-0.497-5.041-1.475c-0.832-0.647-1.449-1.499-1.834-2.534c-7.199,2.868-14.826,4.324-22.676,4.324
        c-3.221,0-6.461-0.246-9.627-0.736c-6.395-0.986-11.029-2.67-13.164-3.555c-0.388,1.021-0.998,1.859-1.821,2.501
        c-1.262,0.979-2.957,1.475-5.043,1.475c-6.99,0-16.719-5.53-19.534-7.226c-2.404-1.477-4.773-3.086-7.058-4.79L77.31,231.074
        c7.34,5.521,15.354,10.135,23.82,13.715c13.917,5.889,28.698,8.873,43.929,8.873c15.231,0,30.012-2.984,43.934-8.876
        c8.715-3.686,16.94-8.46,24.455-14.194L201.883,215.782z"/>
      <path fill="currentColor" d="M71.223,125.965c-3.234,5.464-6.989,14.745-6.612,28.02c29.735,6.981,52.234,46.652,52.46,47.055
        c0.266,0.417,6.811,10.755,6.532,17.777c1.473,0.636,6.129,2.486,12.661,3.487c2.905,0.444,5.881,0.674,8.845,0.674l0,0
        c7.436,0,14.639-1.408,21.417-4.18c-0.266-7.022,6.267-17.341,6.55-17.784c0.209-0.373,22.677-39.992,52.379-47.015
        c0.665-13.919-3.191-23.603-6.562-29.296c-1.248-2.112-2.661-4.075-4.197-5.839c-0.254-0.043-0.514-0.089-0.776-0.142
        c-9.114-1.845-19.81-4.487-25.211-5.854c0.948,0.804,1.853,1.638,2.706,2.492c7.582,7.582,7.077,17.936,5.4,27.999
        c-0.403,2.408-2.79,15.032-10.391,26.766c-4.31,6.654-9.562,11.934-15.61,15.691c-7.424,4.615-16.087,6.954-25.748,6.954
        c-9.664,0-18.328-2.339-25.749-6.954c-6.048-3.759-11.299-9.037-15.61-15.691c-7.593-11.721-9.988-24.355-10.391-26.766
        c-1.677-10.061-2.182-20.416,5.399-27.999c0.856-0.854,1.762-1.686,2.711-2.492c-5.282,1.335-15.653,3.896-24.588,5.726
        C75.376,120.076,73.253,122.532,71.223,125.965z"/>
      <path fill="currentColor" d="M192.01,143.306c1.493-8.953,1.982-18.131-4.474-24.589c-0.299-0.299-0.603-0.592-0.91-0.882
        c-0.307-0.289-0.587-0.544-0.871-0.797l-0.208-0.181c-0.224-0.199-0.453-0.393-0.683-0.588l-0.293-0.242
        c-0.201-0.168-0.404-0.334-0.612-0.494l-1.947-1.464c-0.273-0.19-0.55-0.382-0.827-0.567l-0.352-0.236
        c-9.06-5.958-20.762-8.854-35.773-8.854c-16.933,0-29.747,3.709-39.174,11.341l-0.149,0.125c-0.226,0.185-0.452,0.371-0.672,0.561
        l-0.227,0.195c-0.208,0.18-0.413,0.361-0.618,0.545l-0.156,0.144c-0.289,0.261-0.49,0.446-0.689,0.635l-0.475-0.266l0.361,0.377
        c-0.225,0.216-0.453,0.432-0.673,0.652c-6.456,6.456-5.964,15.634-4.474,24.589c0.37,2.213,2.562,13.809,9.552,24.599
        c8.806,13.593,21.388,20.486,37.399,20.486h0.004c16.01,0,28.592-6.893,37.397-20.486
        C189.442,157.127,191.64,145.523,192.01,143.306z M145.056,155.753c-7.947,0-14.412-6.464-14.412-14.411s6.465-14.411,14.412-14.411
        c7.948,0,14.413,6.464,14.413,14.411S153.004,155.753,145.056,155.753z"/>
      <path fill="currentColor" d="M145.055,131.139c-5.382,0-9.761,4.379-9.761,9.761c0,5.381,4.381,9.761,9.761,9.761
        c5.383,0,9.761-4.379,9.761-9.761C154.815,135.519,150.438,131.139,145.055,131.139z"/>
      <path fill="currentColor" d="M188.992,36.819c-13.922-5.891-28.702-8.875-43.934-8.875c-15.231,0-30.011,2.984-43.934,8.875
        c-8.568,3.623-16.667,8.305-24.08,13.914l11.327,14.99c16.455-12.462,36.042-19.044,56.687-19.044
        c20.645,0,40.036,6.431,56.109,18.597l11.266-15.024C205.203,44.857,197.319,40.338,188.992,36.819z"/>
    </svg>

  </div>



  <button mat-button [routerLink]="['/main','booking']" routerLinkActive="active" *ngIf="roleService.can('read','booking') | async"><i class="fa fa-address-book"></i><span class="label">{{appService.getText('booking_plural')}}</span></button>
  <button mat-button [routerLink]="['/main','checkin']" routerLinkActive="active" *ngIf="roleService.can('create','check_in') | async"><i *ngIf="this.object === 'Vehicle'" class="fa fa-car"></i><i *ngIf="this.object !== 'Vehicle'" class="fas fa-sign-in-alt"></i><span class="label">{{appService.getText('checkin')}}</span></button>
  <button mat-button [routerLink]="['/main','orders']" routerLinkActive="active" *ngIf="roleService.can('read','check_in') | async"><i class="fa fa-cubes"></i><span class="label">{{appService.getText('order_plural')}}</span></button>
  <button mat-button [routerLink]="['/main','work']" routerLinkActive="active" *ngIf="roleService.can('read','work') | async"><i class="fa fa-wrench"></i><span class="label">{{appService.getText('pending_works')}}</span></button>
  <button mat-button [routerLink]="['/main','calendar']" routerLinkActive="active" *ngIf="roleService.can('read','event') | async"><i class="fa fa-calendar"></i><span class="label">{{appService.getText('calendar')}}</span></button>
  <button mat-button [routerLink]="['/main','budgets']" routerLinkActive="active" *ngIf="roleService.can('read','budget') | async"><i class="fa fa-file-invoice-dollar"></i><span class="label">Presupuestos</span></button>
  <button mat-button [routerLink]="['/main','analytics']" routerLinkActive="active" *ngIf="roleService.can('read','analytics') | async"><i class="fa fa-chart-line"></i><span class="label">Analíticas</span></button>
  <button mat-button [routerLink]="['/main','vehicles']" routerLinkActive="active" *ngIf="roleService.can('read','vehicles') | async"><i class="fa fa-car"></i><span class="label">{{appService.getText('object_plural')}}</span></button>
  <button mat-button [routerLink]="['/main','crm']" routerLinkActive="active" *ngIf="roleService.can('read','crm') | async"><i class="fa fa-users"></i><span class="label">{{appService.getText('customer_relationship')}}</span></button>
  <button mat-button [routerLink]="['/main','inventory']" routerLinkActive="active" *ngIf="roleService.can('read','inventory_action') | async"><i class="fa fa-list"></i><span class="label">{{ appService.getText('inventory') }}</span></button>
  <div class="expander"></div>
  <button mat-button [routerLink]="['/main','shares']" routerLinkActive="active" *ngIf="roleService.can('read','shares') | async"><i class="fa fa-dollar-sign"></i><span class="label">Comisiones</span></button>
  <button mat-button [routerLink]="['/main','settings']" routerLinkActive="active"><i class="fas fa-user"></i><span class="label">Mi Perfil</span></button>
  <button mat-button [routerLink]="['/admin']" routerLinkActive="active" *ngIf="roleService.can('read','manager') | async"><i class="fa fa-cog"></i><span class="label">Administrar</span></button>
  <button mat-button (click)="logout()"><i class="fa fa-sign-out-alt"></i><span class="label">Cerrar sesión</span></button>
</div>
