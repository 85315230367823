<mat-sidenav-container class="wrapper">
  <mat-sidenav #sidebar mode="over">
    <app-priorities [workpoint]="true"></app-priorities>
  </mat-sidenav>

  <div class="sidenav-content">
    <mat-toolbar color="primary">
      <div class="toggle-sidebar" (click)="sidebar.toggle()">
        <i class="fa fa-car"></i>
        <div class="counter" *ngIf="priorities_count>0">
          {{priorities_count}}
        </div>
        <div class="work_counter" *ngIf="works_count>0">
          {{works_count}}
        </div>
      </div>
      <div class="expander"></div>
      <div class="check">
        <mat-slide-toggle [(ngModel)]="user.online" (change)="changeOnlineStatus($event)">En línea</mat-slide-toggle>
      </div>
      <div class="toggle-sidebar" (click)="logout()">
        <i class="fa fa-sign-out-alt"></i>
      </div>

    </mat-toolbar>
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
