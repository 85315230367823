import { HttpResponse } from '@angular/common/http';
import {
  afterNextRender, Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import {
  FormArray, FormControl, UntypedFormBuilder, Validators,
} from '@angular/forms';
import { CategoryService } from 'app/admin/services/category.service';
import { FieldsService } from 'app/admin/services/fields.service';
import { RolesService } from 'app/admin/services/roles.service';
import { WorksService } from 'app/admin/services/works.service';
import { Category } from 'app/models/category.model';
import { AdditionalsService } from 'app/services/additionals.service';
import { combineLatest } from 'rxjs';

import { SharesService } from '../../services/shares.service';

@Component({
  selector: 'app-admin-shares',
  templateUrl: './admin-shares.component.html',
  styleUrls: ['./admin-shares.component.scss', '../admin-main.common.scss'],
})
export class AdminSharesComponent implements OnInit {
  @ViewChild('formTemplate') formTemplate: TemplateRef<any>;

  form = this.fb.group({
    name: '',
    work_id: '',
    item_id: 0,
    item_type: new FormControl('', Validators.required),
    item_key: '',
    item_method: '',
    execute_at: '',
    step_variable: '',
    step_calculation_period: '',
    role_ids: [],
    share_formulas: this.fb.array(this.generateSharedFormulasControls()),
  });

  methods = [];

  objects = [];

  baseObjects = [
    {
      type: 'Order',
      id: 0,
      name: 'Orden',
      methods: [{ name: 'Total', value: 'total' }],
    },
    {
      type: 'Budget',
      id: 0,
      name: 'Presupuesto',
      methods: [{ name: 'Total', value: 'total' }],
    },
    {
      type: 'Work',
      id: 0,
      name: 'Trabajo',
      methods: [],
    },
  ];

  categoriesObjects = [];

  additionalObjects = [];

  works = [];

  budget_works = [];

  roles = [];

  constructor(
    public sharesService: SharesService,
    public categoryService: CategoryService,
    public additionalsService: AdditionalsService,
    private worksService: WorksService,
    private roleService: RolesService,
    private fb: UntypedFormBuilder,
    private fieldsService: FieldsService,
  ) {
    afterNextRender(() => {
      this.setForm();
    });
  }

  ngOnInit(): void {
    this.objects = this.baseObjects;
    this.categoryService.all().subscribe((res: HttpResponse<Category[]>) => {
      this.categoriesObjects = res.body.map((item) => (
        {
          type: 'Category',
          id: item.id,
          name: item.name,
          methods: [{ name: 'Total', value: 'total' }],
        }
      ));
      this.objects = this.objects.concat(this.categoriesObjects);
    });
    this.additionalsService.additionals$.subscribe((additionals) => {
      this.additionalObjects = additionals.map((item) => (
        {
          type: 'Aditional',
          id: item.id,
          name: item.name,
          methods: [{ name: 'Total', value: 'total' }],
        }
      ));
      this.objects = this.objects.concat(this.additionalObjects);
    });
    this.worksService.all('WorkForOrder').subscribe((res: HttpResponse<any>) => {
      this.works = res.body;
    });
    this.worksService.all('WorkForBudget').subscribe((res: HttpResponse<any>) => {
      this.budget_works = res.body;
    });
    this.roleService.index().subscribe((res: HttpResponse<any>) => {
      this.roles = res.body.items;
    });
  }

  setMethods(work_id: number, selectedItemType: string): void {
    switch (selectedItemType) {
      case 'Order':
        this.setOrderMethods();
        break;
      case 'Work':
        this.setWorkMethods(work_id);
        break;
      case 'Budget':
        this.setBudgetMethods();
        break;
      default:
        this.methods = this.objects.find((obj) => obj.type === selectedItemType)?.methods || [];
    }
    this.methods.sort((a, b) => a.name.localeCompare(b.name));
  }

  setOrderMethods(): void {
    this.fieldsService.fields('FieldForOrder').subscribe((custom_fields) => {
      this.methods = custom_fields
        .filter((wf) => wf.field_type === 'number')
        .map((wf) => ({ name: wf.title, value: `custom_fields.${wf.key}` }))
        .concat({ name: 'Total', value: 'total' });
    });
  }

  setWorkMethods(work_id): void {
    let work = this.works.find((w) => w.id === work_id);
    if (!work) {
      work = this.budget_works.find((work) => work.id === work_id);
    }
    if (!work) return;

    this.fieldsService.fields('FieldForWork').subscribe((custom_fields) => {
      this.methods = custom_fields
        .filter((wf) => wf.field_type === 'number'
            && work.custom_field_ids.includes(wf.id))
        .map((wf) => ({ name: wf.title, value: `custom_fields.${wf.key}` }));
    });
  }

  setBudgetMethods(): void {
    this.fieldsService.fields('FieldForBudget').subscribe((custom_fields) => {
      this.methods = custom_fields
        .filter((wf) => wf.field_type === 'number')
        .map((wf) => ({ name: wf.title, value: `custom_fields.${wf.key}` }))
        .concat({ name: 'Total', value: 'total' });
    });
  }

  setForm(): void {
    this.form.get('item_key').valueChanges.subscribe((value) => {
      if (!value) return;

      const type = value.split('-')[0];
      const id = value.split('-')[1];
      const object = this.objects.find((obj) => obj.type === type);
      if (object) {
        this.methods = object?.methods || [];
        this.form.patchValue({
          item_type: type,
          item_id: id,
        });
      }
    });

    combineLatest([this.form.get('work_id').valueChanges, this.form.get('item_type').valueChanges])
      .subscribe(([work_id, item_type]) => {
        const selectedItemType = item_type?.split('-')?.[0];
        this.setMethods(work_id, selectedItemType);
      });
  }

  addShareFormulaControl(formValue, form) {
    (<FormArray>form.controls.share_formulas).push(this.buildShareFormulaControl());
  }

  removeShareFormulaControl(index, form) {
    (<FormArray>form.controls.share_formulas).removeAt(index);
  }

  generateSharedFormulasControls() {
    const amount = 6;
    const controls = [];
    for (let i = 0; i < amount; i += 1) {
      controls.push(this.buildShareFormulaControl());
    }
    return controls;
  }

  setupControls(form, formValue) {
    formValue.share_formulas.forEach((share_formula) => {
      this.addShareFormulaControl(this.buildShareFormulaControl(share_formula), form);
    });
  }

  buildShareFormulaControl(formValue = {
    formula: '',
    variable_start: '',
    variable_end: '',
    enabled: false,
  }) {
    return this.fb.group({
      formula: new FormControl(formValue.formula, Validators.pattern(/^[0-9+\-*/().\s]*$/)),
      variable_start: formValue.variable_start,
      variable_end: formValue.variable_end,
      enabled: formValue.enabled,
    });
  }
}
