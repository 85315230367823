import {Component, OnInit} from '@angular/core';
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  constructor(
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.zendesk.hide();
  }
}
