<mat-toolbar class="toolbar">
  <div *ngIf="mode !== 'month'" class="back" (click)="openMonth()" matTooltip="Atrás" matTooltipPosition="right">
    <mat-icon >arrow_back_ios_new</mat-icon>
  </div>

 <div class="legend-container">
    <div class="icon-text-colors hide-mobile" *ngIf="((BOOKING_TYPE==='professional' && formattedBookings) || BOOKING_TYPE==='capacity') && mode==='day'">
      <i class="fas fa-circle checked-in" aria-hidden="true"></i><span>Ingresado</span>
      <i class="fas fa-circle confirmed" aria-hidden="true"></i><span>Confirmado</span>
      <i class="fas fa-circle non-checked" aria-hidden="true"></i><span>No Ingresado</span>
    </div>
    <div class="icon-text-colors hide-mobile" *ngIf="mode==='month'">
      <i class="fas fa-circle checked-in" aria-hidden="true"></i><span>Ingresado</span>
      <i class="fas fa-circle confirmed" aria-hidden="true"></i><span>Confirmado</span>
      <i class="fas fa-circle non-checked" aria-hidden="true"></i><span>No Ingresado</span>
      <i class="fas fa-circle booked" aria-hidden="true"></i><span>Reagendado</span>
    </div>
  </div>
<div class="action-container">
  <app-derco-mails></app-derco-mails>
  <div #searchButton class="search-button" (click)="openSearchModal()">
    <mat-icon>search</mat-icon>
  </div>
    <button mat-button (click)="previous()" class="no-padding-mobile">
      <i class="fa fa-arrow-left" ></i>
    </button>
    <span class="title"></span>
    <button mat-button (click)="next()" class="no-padding-mobile">
      <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</mat-toolbar>


<div class="search-modal" *ngIf="isSearchModalOpen">
  <div class="modal-content" #modalContent>
    <div class="bar">
      <mat-icon class="search-icon">search</mat-icon>
      <input #searchInput type="text" [formControl]="searchControl" name="search_query" autocomplete="off" data-lpignore="true" placeholder="Buscar">
    </div>
    <div class="list" (click)="handleListClick($event)">
      <div class="finding" *ngFor="let item of findings; let last = last; let first = first" (click)="openBooking(null,null,item)" [class.last]="last" [class.first]="first" >
        <div class="time">
          <div class="hour">{{item.booking_time || '-'}}</div>
          <div class="day">{{item.check_in_time || item.booking_date | date:'dd/LL'}}</div>
        </div>
        <div class="identifier">
          <div class="number">{{item.object | objectName:item.client.rut}} <i class="fa fa-check" *ngIf="item.checked_in"></i></div>
          <div class="req">{{item.requirement?.name}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode==='month' then monthview else dayview"></div>
<ng-template #monthview>
  <div class="date-names" >
    <div class="hours" *ngFor="let date of dateNames; let last = last" [class.last]="last">
      <span class="title">{{date | date:'MMMM'}}</span>
    </div>
  </div>
  <div class="dates">
    <mwl-calendar-month-view (beforeViewRender)="beforeMonthViewRender($event)" [viewDate]="month" [excludeDays]="excludeDays"   [events]="events" (dayClicked)="openDay($event)" locale="es-ES" (eventClicked)="eventClick($event)"></mwl-calendar-month-view>
  </div>
</ng-template>
<ng-template #dayview>
  <div class="date-names" >
    <div class="hours" *ngFor="let date of dates; let last = last" [class.last]="last">
      <span class="title">{{date | date:'dd MMMM yyyy':'':'es-ES'}} <button mat-button matTooltip="Bloquear día" (click)="toggleBlock(dates[0])" class="block" *ngIf="(roleService.can('create','booking_block') | async) && dates[0] >= currentDate"><i class="fa fa-lock-open" [hidden]="blocked(dates[0])"></i> <i class="fa fa-lock" [hidden]="!blocked(dates[0])"></i></button></span>
    </div>
  </div>
  <div class="agenda-wrapper">
    <div class="professional-names" *ngIf="BOOKING_TYPE === 'professional'">
      <div class="span"></div>
      <div class="names" *ngFor="let user of professionalList; let last = last; let first = first" [class.last]="last" (click)="showOnly(user)">

        <div class="user">
          <div class="avatar" [ngStyle]="user?.picture ? {'background-image': 'url(' + user?.picture + ')'} : {}">
            @if (!user?.picture){
              {{ getInitials(user?.name) }}
            }
          </div>
        </div>

        <span class="name">{{user.name}}</span>
        <i class="fa fa-arrow-left" *ngIf="first && professionals.length > professionalStep" (click)="previousProfessionals($event)"></i>
        <i class="fa fa-arrow-right" *ngIf="last && professionals.length > professionalStep" (click)="nextProfessionals($event)"></i>
      </div>
    </div>
    <div class="dates">
      <div class="hours" *ngFor="let date of dates; let last = last" [class.last]="last">
        <div class="hour" *ngFor="let hour of booking.days[getDayNumber()]; let index = index; let first = first; let last = last">
          <div class="time">
            <span class="block" matTooltip="Bloquear Hora" (click)="toggleBlock(dates[0],hour)" class="block" *ngIf="(roleService.can('create','booking_block') | async) && dates[0] >= currentDate"><i class="fa fa-lock-open" [hidden]="blocked(dates[0],hour)"></i> <i class="fa fa-lock" [hidden]="!blocked(dates[0],hour)"></i></span>
            <span>{{hour}}</span>
          </div>
          <ng-container *ngIf="BOOKING_TYPE==='capacity'">
            <div class="bookings" *ngFor="let booking of getBookings(date,hour,index)">
              <div class="booking-content" (click)="openBooking(date,hour,booking)">
                <div class="name" [class.checked-in]="booking.checked_in" [class.confirmed]="booking.confirmed" [class.holiday]="isHoliday(date,hour)">
                  {{booking | objectName:booking.client_name | truncate:[25]}}
                </div>
                <div class="requirement" [title]="booking?.client_name || 'Sin'+appService.getText('client','lowercase')" [class.checked-in]="booking.checked_in" [class.confirmed]="booking.confirmed" [class.holiday]="isHoliday(date,hour)">
                  <span>{{booking?.requirement_name || 'Sin'+appService.getText('requirement','lowercase')}} </span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="getBookings(date, hour, index).length < MAX_BOOKINGS">
              <ng-container *ngFor="let i of getMissingBookingsArray(getBookings(date, hour, index).length)">
                <div class="bookings new">
                  <div class="requirement" title="Crear nuevo agendamiento" (click)="openBooking(date, hour)" [ngClass]="{'blocked': date < currentDate || isHoliday(date, hour) || blocked(date, hour)}" >
                    <i class="fa fa-plus" *ngIf="date >= currentDate && !isHoliday(date, hour) && !blocked(date, hour)"></i>
                    <i class="fa fa-lock" *ngIf="date < currentDate || isHoliday(date, hour) || blocked(date, hour)"></i>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="BOOKING_TYPE==='professional' && formattedBookings">
            <ng-container *ngFor="let user of professionalList">
              <ng-container *ngIf="formattedBookings[user.id]">
                <ng-container *ngIf="!formattedBookings[user.id][date] || !formattedBookings[user.id][date][hour]">
                  <div class="bookings new professional">
                    <div class="requirement" [class.last]="last" [class.first]="first" title="Crear nuevo agendamiento" (click)="openBooking(date,hour,null,user.id)" [class.holiday]="isHoliday(date,hour,user.id)"  [ngClass]="{'blocked': date < currentDate || isHoliday(date,hour,user.id) || blocked(date,hour,user.id)}" >
                      <i class="fa fa-plus" *ngIf="date >= currentDate && !isHoliday(date,hour,user.id) && !blocked(date,hour,user.id)"></i>
                      <i class="fa fa-lock" *ngIf="date < currentDate || isHoliday(date,hour,user.id) || blocked(date,hour,user.id)"></i>
                    </div>
                    <span class="block" matTooltip="Bloquear Hora" (click)="toggleBlock(dates[0],hour,user.id)" class="block" *ngIf="(roleService.can('create','booking_block') | async) && dates[0] >= currentDate"><i class="fa fa-lock-open" [hidden]="blocked(dates[0],hour,user.id)"></i> <i class="fa fa-lock" [hidden]="!blocked(dates[0],hour,user.id)"></i></span>
                  </div>
                </ng-container>
                <ng-container *ngIf="formattedBookings[user.id][date] && formattedBookings[user.id][date][hour] && formattedBookings[user.id][date][hour].id">
                  <div class="bookings">
                    <div class="booking-content" (click)="openBooking(date,hour,formattedBookings[user.id][date][hour])">
                      <div class="name" [class.checked-in]="formattedBookings[user.id][date][hour].checked_in" [class.confirmed]="formattedBookings[user.id][date][hour].confirmed" [class.holiday]="isHoliday(date,hour,user.id)">
                        {{formattedBookings[user.id][date][hour] | objectName:booking.client_name | truncate:[25]}}
                      </div>
                      <div class="requirement" [class.last]="last" [class.first]="first" [title]="formattedBookings[user.id][date][hour].client_name || 'Sin'+appService.getText('client','lowercase')" [class.checked-in]="formattedBookings[user.id][date][hour].checked_in" [class.confirmed]="formattedBookings[user.id][date][hour].confirmed" [class.holiday]="isHoliday(date,hour,user.id)">
                        <span>{{formattedBookings[user.id][date][hour].requirement_name || 'Sin'+appService.getText('requirement','lowercase')}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="formattedBookings[user.id][date] && formattedBookings[user.id][date][hour] && !formattedBookings[user.id][date][hour].id">
                  <div class="bookings">
                    <div class="requirement no-content" [class.last]="last" [class.first]="first" [title]="formattedBookings[user.id][date][hour].client_name || 'Sin'+appService.getText('client','lowercase')" (click)="openBooking(date,hour,getBooking(date,formattedBookings[user.id][date][hour]))" [class.checked-in]="getBooking(date,formattedBookings[user.id][date][hour]).checked_in" [class.confirmed]="getBooking(date,formattedBookings[user.id][date][hour]).confirmed">
                      &nbsp;
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</ng-template>

  <button mat-fab class="add-button" (click)="openBooking(dates[0],null)"  *ngIf="(roleService.can('create','booking') | async) && dates[0] >= currentDate && !isHoliday(dates[0]) && mode==='day'" ><i class="fa fa-plus"></i></button>
