import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UntypedFormBuilder} from "@angular/forms";
import {CheckListsService} from "../../services/check-lists.service";
import { HttpResponse } from "@angular/common/http";
import {WorkCheckListsService} from "../../services/work-check-lists.service";
import {RequirementsService} from "../../../services/requirements.service";
import {Brand} from "../../../models/types";
import {VehiclesService} from "../../services/vehicles.service";
import {BrandService} from "../../../services/brand.service";
import {WorksService} from "../../services/works.service";
import {RoleService} from "../../../role/role.service";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-admin-check-list',
  templateUrl: './admin-check-list.component.html',
  styleUrls: ['./admin-check-list.component.scss','../admin-main.common.scss']
})
export class AdminCheckListComponent implements OnInit {
  form = this.fb.group({name: '', check_list_type: 'check', warn_requires_photo: false, danger_requires_photo: false});
  workCheckListForm = this.fb.group({work_id: [],check_list_id: [],requirements_id: [], brands_id:[],brand_models_id:[]});
  lists = [];
  requirements = [];
  brands = [];
  models = [];
  works = [];
  types = [
    {name: 'Checklist', id: 'check'},
    {name: 'Radio', id: 'radio'},
    {name: 'Si/No', id: 'boolean'}
  ]
  @Input() inView = false;
  @Input() hideLinks = false;
  @Input() hideLists = false;
  public showId:number;
  selected: any;
  constructor(
    public checkListsService: CheckListsService,
    public workCheckListsService: WorkCheckListsService,
    private requirementService: RequirementsService,
    public vehiclesService: VehiclesService,
    public brandService: BrandService,
    private worksService: WorksService,
    public fb: UntypedFormBuilder,
    public router: Router,
    public roleService: RoleService
  ) { }

  ngOnInit() {
    this.checkListsService.all().toPromise().then((response: HttpResponse<any[]>)=>{
      this.lists = response.body;
    });
    this.requirementService.all().toPromise().then((response: HttpResponse<any[]>)=>{
      this.requirements = response.body;
    });
    this.brandService.all().toPromise().then((values:HttpResponse<Brand[]>) => {
      this.brands = values.body;
    }).catch((error) => {
      console.log(error);
    });
    this.brandService.getModels(0).toPromise().then((values:HttpResponse<Brand[]>) => {
      this.models = values.body;
    }).catch((error) => {
      console.log(error);
    });
    this.roleService.can('read','admin').pipe(first()).subscribe(value => {
      if(value){
        this.worksService.all().toPromise().catch().then((value:any) => {
          this.works = value.body;
        });
      }
    })
  }
  select(item){
    this.selected = item;
    if(!this.inView){
      this.router.navigate(['admin','main','check-lists',this.selected.id,'items']);
    }
  }

}
