<mat-card  style="margin-bottom: 1em">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración de Presupuesto</mat-card-title>
    <mat-card-subtitle>Configuración general</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="hours">
          <form [formGroup]="companyForm">
            <fieldset formGroupName="preferences">
              <mat-form-field style="margin-bottom: 10px">
                <mat-label>Tipo de presupuesto</mat-label>
                <mat-select placeholder="Tipo de presupuesto" formControlName="budget_type" required>
                  <mat-option *ngFor="let type of budget_types" [value]="type.id">{{type.name}}</mat-option>
                </mat-select>
                <mat-hint>Tipo de presupuesto a mostrar</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Términos y Condiciones"</mat-label>
                <textarea matInput placeholder="Terminos y Condiciones" formControlName="budget_legend" rows="10"></textarea>
                <mat-hint>Terminos y condiciones del presupuesto</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Campos PDF (Orden)</mat-label>
                <mat-select placeholder="Campos Customs PDF (Orden)" formControlName="pdf_custom_field_ids" multiple="true">
                  <mat-option *ngFor="let field of orderFields" [value]="field.id">{{field.title}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Items por página PDF</mat-label>
                <input matInput placeholder="Items por página PDF" formControlName="budget_items_per_page" autocomplete="off" required type="number">
              </mat-form-field>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
    <mat-card-title>Etiquetas</mat-card-title>
    <mat-card-subtitle>Nombres y colores de etiquetas</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="tagService" [itemTemplate]="tagNameTemplate" [formTemplate]="tagFormTemplate" [formGroup]="tagForm">
      <ng-template #tagNameTemplate let-item>
        <div class="color" [style.background-color]="sanitizeColor(item.color)"></div> {{item.name}}
      </ng-template>
      <ng-template #tagFormTemplate let-form="form" let-value="value">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
          </mat-form-field>
          <div class="picker"style="margin-bottom: 10px">
            Color:
            <mcc-color-picker hideUsedColors hideEmpty hideButtons (selected)="form.controls['color'].patchValue($event)" [selectedColor]="form.controls['color'].value"></mcc-color-picker>
          </div>
          <mat-form-field hidden>
            <input matInput placeholder="Color" formControlName="color" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field style="margin-bottom: 10px">
            <mat-label>Categorías</mat-label>
            <mat-select placeholder="Categorías" formControlName="category_ids" required multiple="true">
              <mat-option *ngFor="let tag of tags" [value]="tag.id">{{tag.name}}</mat-option>
            </mat-select>
            <mat-hint>Categorías asociadas a marcador</mat-hint>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>

@if (roleService.can('read','admin') | async) {
  <app-status-form
    type="StatusForBudget"
    title="Estados de un presupuesto"
    [showStatuses]="true"
    [showActions]="true"
    [showTransitions]="true">
  </app-status-form>
}

<app-work-form model="Budget"></app-work-form>
<app-admin-custom-fields [service]="fieldsService" model="FieldForBudget"></app-admin-custom-fields>
<app-client-notification-form model="Budget"></app-client-notification-form>
