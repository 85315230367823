import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { RequirementsService } from 'app/services/requirements.service';
import { WorkService } from 'app/work/work.service';
import { fork } from 'child_process';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';
import { ListsService } from './lists.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  public pageTitle: WritableSignal<string> = signal('');

  local$:BehaviorSubject<any> = new BehaviorSubject(null);

  started = false;

  locations$:BehaviorSubject<any[]> = new BehaviorSubject(localStorage.getItem('locations') ? localStorage.getItem('locations').split(',').map((i) => parseInt(i)) : []);

  constructor(
    public httpClient: HttpClient,
    public service: UserService,
    public listService: ListsService,
    public requirementService: RequirementsService,
    private workService: WorkService,
  ) {
    super(httpClient, service);
    this.locations$.subscribe((value) => {
      if (this.started) {
        this.setupApp().toPromise().then((value) => {
          this.setCompany(value.body);
          forkJoin([
            this.requirementService.all(),
            this.service.index(),
            this.workService.all(),
          ]).toPromise().then((values:any) => {
            this.requirementService.requirements$.next(values[0].body);
            this.service.users$.next(values[1].body);
            this.workService.works$.next(values[2].body);
          }).catch((error) => {
            console.log(error);
          });
        });
      } else {
        this.started = true;
      }
    });
  }

  getLocations() {
    if (this.service.user$.getValue()) {
      const l_ids = this.service.user$.getValue().location_ids;
      if (this.local$.getValue() && this.local$.getValue().locations) {
        return this.local$.getValue().locations.filter((l) => l_ids.length === 0 || l_ids.includes(l.id));
      }

      return [];
    }
    return [];
  }

  getLists() {
    return this.listService.lists$.getValue();
  }

  getText(key, style?, default_value?) {
    const formattedKey = key?.split(/\.?(?=[A-Z])/)?.join('_')?.toLowerCase();
    const value = this.local$.getValue()?.preferences?.translations?.[formattedKey];

    if (!value) return default_value || key;

    switch (style) {
      case 'capitalize':
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      case 'uppercase':
        return value.toUpperCase();
      case 'lowercase':
        return value.toLowerCase();
      default:
        return value;
    }
  }

  setupApp(token?) {
    return this.httpClient.get(`${this.apiURL}/companies`, this.getParams({ company_token: token || localStorage.getItem('workpoint_token') }));
  }

  updateCompany(body) {
    return this.httpClient.put(`${this.apiURL}/companies/current`, { company: body }, this.getParams());
  }

  startWorkPoint() {
    return this.httpClient.post(`${this.apiURL}/companies/workpoint`, {}, this.getParams());
  }

  validateWorkPoint(token) {
    return this.httpClient.post(`${this.apiURL}/companies/validate_workpoint`, { token }, this.getParams());
  }

  setCompany(value) {
    this.local$.next(value);
  }

  setupMercagoPago(code: any) {
    return this.httpClient.post(`${this.apiURL}/companies/setup_mercado_pago`, { code }, this.getParams());
  }

  getTaxValue() {
    return this.local$?.getValue()?.preferences?.tax_value || 1.19;
  }
}
