
<mat-toolbar class="header">
  <div class="back" mat-dialog-close matTooltip="Atrás" matTooltipPosition="right">
    <mat-icon >arrow_back_ios_new</mat-icon>
  </div>
  <div class="hide-print" style="font-weight: 500;">
  Trabajo: {{work?.work?.name || 'Otros'}}
  </div>

</mat-toolbar>

@if (!work) {
  <div class="skeleton-container">
    <div class="skeleton" style="height: 98px;"></div>
    <div class="skeleton" style="height: 123px;"></div>
    <div class="skeleton" style="height: 170px;"></div>
    <div class="skeleton" style="height:  74px;"></div>
    <div class="skeleton" style="height:  90px;"></div>
  </div>
}

<div class="dialog-wrapper">
  <div *ngIf="work" #wrapper>

      <div class="work" *ngIf="work">
        <div class="info first" *ngIf="work.order && work.order.object">
          <div class="name">{{appService.getText('object_identifier')}}</div>
          <div class="value">{{work.order?.object?.identifier || 'Sin '+appService.getText('object_identifier')}}</div>
        </div>
        <div class="info" *ngIf="work.order && work.order.vehicle">
          <div class="name">{{appService.getText('object_identifier')}}</div>
          <div class="value">{{work.order?.vehicle?.identifier || 'Sin '+appService.getText('object_identifier')}}</div>
        </div>
        <div class="info">
          <div class="name">Creacion</div>
          <div class="value">{{work.created_at | date:'shortDate'}}</div>
        </div>
        <div class="info">
          <div class="name">{{appService.getText('requirement')}}</div>
          <div class="value">{{work.order?.requirement?.name || 'Sin '+appService.getText('requirement')}}</div>
        </div>

        <div class="info">
          <div class="name">Asignado</div>
          <div class="value">{{work.user?.name || 'Sin asignado'}}</div>
        </div>

        <div class="info" *ngIf="work.sign_url">
          <div class="name">Firma</div>
          <div class="value"><img src="{{work.sign_url}}" style="max-height: 100px"/></div>
        </div>

        <div class="info" *ngIf="work.name">
          <div class="name"><b>{{work.requester?.name}}</b></div>
          <div class="value">{{work.name}}</div>
        </div>

      </div>



    <div class="form-container" *ngIf="work && work.check_list">
      <div class="checklist">

          <div class="subtitle">Listado de Trabajo - {{work.check_list.name}}</div>
          <div class="list">
            <div class="row">
              <div class="col">
                <div class="check" *ngFor="let item of work.work_statuses; let i = index">
                  <ng-container *ngIf="work.check_list.check_list_type === 'check'">
                    <mat-checkbox [disabled]="work.completed_at" [(ngModel)]="item.completed" (ngModelChange)="toggle(item)" [disabled]="waitingRevision()">{{getCheckListName(item.check_list_item_id)}}</mat-checkbox>
                  </ng-container>
                  <ng-container *ngIf="work.check_list.check_list_type === 'radio'">
                    <mat-radio-group [(ngModel)]="item.value" (ngModelChange)="toggle(item)" [disabled]="work.completed_at || waitingRevision()" class="radio-group">
                      <mat-radio-button [value]=2 class="radio-green" ></mat-radio-button>
                      <mat-radio-button [value]=1 class="radio-yellow" ></mat-radio-button>
                      <mat-radio-button [value]=0 class="radio-red" ></mat-radio-button>
                    </mat-radio-group>
                    <div class="text">{{getCheckListName(item.check_list_item_id)}}</div>
                  </ng-container>
                  <ng-container *ngIf="work.check_list.check_list_type === 'boolean'">
                    <mat-radio-group [(ngModel)]="item.value" (ngModelChange)="toggle(item)" [disabled]="work.completed_at || waitingRevision()" class="radio-group">
                      <mat-radio-button [value]=2 class="radio-green" ></mat-radio-button>
                      <mat-radio-button [value]=0 class="radio-red" ></mat-radio-button>
                    </mat-radio-group>
                    <div class="text">{{getCheckListName(item.check_list_item_id)}}</div>
                  </ng-container>
                </div>
              </div>
              <div class="col revision" *ngIf="work && work.work && work.work.require_revision && (isRevisor() || work.last_revisor_id)">
                <div class="checklist">
                  <div class="check" *ngFor="let item of work.work_statuses; let i = index">
                    <ng-container *ngIf="work.check_list.check_list_type === 'check'">
                      <mat-checkbox [disabled]="work.completed_at" [(ngModel)]="item.revision_completed" (ngModelChange)="toggle(item)" [disabled]="!isRevisor()">{{getCheckListName(item.check_list_item_id)}}</mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="work.check_list.check_list_type === 'radio'">
                      <mat-radio-group [(ngModel)]="item.revision_value" (ngModelChange)="toggle(item)" [disabled]="work.completed_at || !isRevisor()" class="radio-group">
                        <mat-radio-button [value]=2 class="radio-green" ></mat-radio-button>
                        <mat-radio-button [value]=1 class="radio-yellow" ></mat-radio-button>
                        <mat-radio-button [value]=0 class="radio-red" ></mat-radio-button>
                      </mat-radio-group>
                      <div class="text">{{getCheckListName(item.check_list_item_id)}}</div>
                    </ng-container>
                    <ng-container *ngIf="work.check_list.check_list_type === 'boolean'">
                      <mat-radio-group [(ngModel)]="item.revision_value" (ngModelChange)="toggle(item)" [disabled]="work.completed_at || !isRevisor()" class="radio-group">
                        <mat-radio-button [value]=2 class="radio-green" ></mat-radio-button>
                        <mat-radio-button [value]=0 class="radio-red" ></mat-radio-button>
                      </mat-radio-group>
                      <div class="text">{{getCheckListName(item.check_list_item_id)}}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="score">
                  <div class="title">Puntaje</div>
                  <div class="number">{{calcPoints(work.work_statuses,work.check_list.check_list_type)}}%</div>
                  <div class="title">Revisión</div>
                </div>
              </div>
            </div>
            <div class="check empty" *ngIf="!work.user_id">Debes asignar un usuario para poder completar</div>
            <div class="check empty" *ngIf="(!work.work_statuses || work.work_statuses.length == 0) && work.user_id">No hay puntos a completar</div>
          </div>

      </div>
    </div>

    <div class="form-container">
      <div class="subtitle" style="margin-bottom: 10px;">Adjuntar fotos del {{appService.getText('work_assignation')}}</div>
      <app-image-uploader
        [gallery]="getGallery()"
        [photos]="work.pictures"
        [small]="true"
        [scrollTarget]="wrapper"
        (uploaded)="saveImages($event)"
        [readonly]="clientMode"
        [placeholders]="placeholders">
      </app-image-uploader>
      <div class="subtitle error" *ngIf="work.work && this.work.work.min_photos > work.pictures.length || hasPendingPlaceholder()"><i class="fa fa-exclamation-triangle"></i>Faltan imágenes ({{getPendingImages()}})</div>
    </div>
    @if (work.work && work.work.custom_response_type!=="none") {
      <div class="form-container">
        <wpl-budget-work [parentForm]="form" [value]="work" *ngIf="work.budget_id || (work.work && work.work.custom_response_type==='budget')"></wpl-budget-work>
        <app-order-fields mode="Work" [model]="work" [parentFormGroup]="form" [clientMode]="clientMode" [disabled]="work.completed_at" [filterFields]="filterCustom"></app-order-fields>
        <ng-template [ngIf]="work && work.work && work.work_request && work.work_request.work && work.work_request.custom_params && !clientMode && work.user_id">
          <div class="subtitle">&nbsp;&nbsp;<i class="fa fa-clock"></i>&nbsp;&nbsp; Trabajo Previo: {{work.work_request?.work?.name}}<div class="expander"></div>
            <button mat-button (click)="cloneData()"><i class="fa fa-copy" *ngIf="work.work.custom_response_type === work.work_request.work.custom_response_type && !work.completed_at"></i></button>
          </div>
          <wpl-custom-params class="custom_params" [workRequest]="work.work_request" [clientMode]="clientMode ">
          </wpl-custom-params>
        </ng-template>
        <wpl-custom-params class="custom_params" *ngIf="work && work.work && work.user_id" [workRequest]="work" [parentForm]="form"  [clientMode]="clientMode">
        </wpl-custom-params>
      </div>
    }
    <ng-container *ngIf="!clientMode">
      <div class="comments-container">
        <div class="subtitle hide-print">Bitácora</div>
        <app-order-comments
        [work]="work"
        [order]="work.order"
        [comments]="work.comments"
        [disableAddWork]="true"
        class="hide-print"
        style="margin-bottom: 15px; margin-top: 15px"
        ></app-order-comments>
      </div>
    </ng-container>

  </div>
</div>


<mat-toolbar class="footer" *ngIf="work">

  <button mat-raised-button class="primary" *ngIf="work && work.order && (roleService.can('read','check_in') | async)" [hidden]="clientMode" (click)="openOrder(work.order.id)">
    <span class="display-desktop">Ver {{appService.getText('order')}} Nº {{work.order.correlative_id}}</span>
    <span class="display-mobile"><i class="fa fa-eye"></i></span>
  </button>
  <button mat-raised-button class="primary" *ngIf="work && work.budget" [hidden]="clientMode" (click)="openBudget(work.budget.id)">
    <span class="display-desktop">Ver {{appService.getText('budget')}} Nº {{work.budget.correlative_id}}</span>
    <span class="display-mobile"><i class="fa fa-eye"></i></span>
  </button>

  <button mat-raised-button class="primary" *ngIf="work && work.client && (roleService.can('read','crm') | async)" [hidden]="clientMode" (click)="openClient(work.client.id)">

    <span class="display-desktop">Ver {{appService.getText('client')}} >Nº {{work.order.correlative_id}}</span>
    <span class="display-mobile"><i class="fas fa-user"></i></span>
  </button>
  <div class="expander"></div>
  <button mat-raised-button class="primary magin" (click)="setUser()" [hidden]="work.user_id">
    <span>ASIGNAR USUARIO</span>
  </button>

  <button mat-raised-button class="primary margin" [class.approve]="isRevisor()" (click)="complete()" [disabled]="work?.completed_at || remainingWork()>0 || !work.user_id || !form.valid || (work.work && this.work.work.min_photos > work.pictures.length) || hasPendingPlaceholder() || (work.revisor_id && !isRevisor())" [hidden]="clientMode || !work.user_id">
    <span *ngIf="work.completed_at">Trabajo finalizado</span>
    <div *ngIf="!work.completed_at && (!work.work || !work.work.require_revision || isRevisor())" style="display: flex;">
      <span *ngIf="work.work && work.work.sign_needed">Firmar y </span>
      {{isRevisor() ? 'Aprobar' : 'Finalizar'}}
      <span class="display-desktop">&nbsp;{{work?.work?.name || 'Trabajo'}}</span>
    </div>
    <span *ngIf="work && work.work && !work.completed_at && work.work.require_revision && !work.revisor_id">Enviar para revisión</span>
    <span *ngIf="waitingRevision() && !isRevisor()">Esperando revisión</span>
  </button>
  <button mat-raised-button class="warn margin" (click)="reject()" [hidden]="work?.completed_at || !work.user_id || clientMode || (!isRevisor() && work.revisor_id)">
    <div style="display: flex;">
      <span>Rechazar</span><span class="display-desktop">&nbsp;trabajo</span>
    </div>
  </button>
</mat-toolbar>



