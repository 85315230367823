import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NgModel,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { FieldsService } from 'app/admin/services/fields.service';
import { ListsService } from 'app/admin/services/lists.service';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';

import { AssignUserComponent } from '../../../work/assign-user/assign-user.component';
import { HiddenFieldsComponent } from '../hidden-fields/hidden-fields.component';

@Component({
  selector: 'app-order-fields',
  templateUrl: './order-fields.component.html',
  styleUrls: ['./order-fields.component.scss'],
})
export class OrderFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: any = { client: {}, custom_fields: {} };

  @Input() outlinemode: boolean = false;

  currentDate = moment().toDate();

  fields = [];

  filteredList = {};

  filteredSublist = {};

  form = new UntypedFormGroup({});

  hiddenFields = [];

  subs = [];

  sublist = {};

  users = {};

  @ViewChildren(forwardRef(() => NgModel)) controls: QueryList<NgModel>;

  @Input() @HostBinding('class.readonly') readonly = false;

  @Input() bookingMode = false;

  @Input() clientMode = false;

  @Input() disabled = false;

  @Input() filterFields;

  @Input() mode = 'Order';

  @Input() parentFormGroup: UntypedFormGroup;

  @Input() reactiveFormMode: boolean = false;

  @Input() showHidden = false;

  @Input() wrap = true;

  @Output() updated = new EventEmitter();

  constructor(
    private fieldsService: FieldsService,
    private listsService: ListsService,
    private matDialog: MatDialog,
    public appService: AdminService,
  ) {

  }

  ngOnInit() {
    const fieldModelName = `FieldFor${this.mode}`;
    this.fieldsService.fields(fieldModelName).subscribe((fields) => {
      if (this.filterFields) {
        this.fields = fields.filter((f) => this.filterFields.findIndex((f2) => f2 === f.key || f2 === f.id) >= 0);
      } else {
        this.fields = fields;
      }
      if (this.bookingMode) {
        this.fields = this.fields.filter((f) => f.show_booking);
      }
      this.fields.map((field) => {
        if (field.field_type.indexOf('list') >= 0) {
          field.list_id = parseInt(field.field_type.split('list')[1]);
          field.field_type = 'list';
          if (!field.list) {
            field.list = this.listsService.lists$.getValue().find((l) => l.id === field.list_id);
          }
          this.setList(field, this.model.custom_fields[field.key]);
        }
      });
      if (!this.showHidden) {
        this.hiddenFields = this.fields.filter((field) => {
          const hide = (field.hide_optional && this.bookingMode && !field.require_booking && field.show_booking) || (field.hide_optional && !this.bookingMode && !field.required);
          const hasValue = this.model.custom_fields[field.key] || this.model.custom_fields[field.key] === 0;
          const isInFilter = this.filterFields && this.filterFields.findIndex((f) => f === field.key || f === field.id) >= 0;
          return hide && !hasValue && !isInFilter;
        }).sort((a, b) => a.order - b.order);
      }
      this.setControls();
    });
    //this.parentFormGroup.get('custom_fields').markAllAsTouched();
  }

  hidden(field) {
    if (this.showHidden) {
      return false;
    }
    return this.hiddenFields.findIndex((f) => f.key === field.key) >= 0;
  }

  setList(field, id) {
    if (field.list && field.list.list_items) {
      const index = field.list.list_items.findIndex((l) => l.id == id);
      if (index >= 0) {
        if (field.list.list_items[index].list_items) {
          this.sublist[field.key] = field.list.list_items[index].list_items;
          this.filteredSublist[field.key] = [];
        }
      }
    }
    this.updated.emit();
  }

  saveDrop(field, event) {
    if (this.parentFormGroup) {
      this.parentFormGroup.controls.custom_fields.patchValue({
        [field.key]: event.value,
      });
    }
    this.updated.emit();
  }

  showHiddenFields() {
    this.matDialog.open(HiddenFieldsComponent, {
      data: {
        fields: this.hiddenFields,
        model: this.model,
      },
    }).afterClosed().toPromise().then((result) => {
      if (result) {
        result.hide_optional = false;
        this.hiddenFields = this.hiddenFields.filter((f) => f.key !== result.key);
        this.setControls();
      }
    });
  }

  getListItem(field) {
    if (this.model.custom_fields[field.key]) {
      if (field.list && field.list.list_items) {
        return this.getItemNames(field.list.list_items, [], field).join(' - ');
      }
      return this.model.custom_fields[field.key];
    }
    return '-';
  }

  getItemNames(items, names = [], field) {
    const key = names.length == 0 ? field.key : `${field.key}_${(names.length + 1).toString()}`;
    const value = this.model.custom_fields[key];

    if (value) {
      const item = this.findItem(items, value);
      if (item) {
        names.push(item.name);
      }
      if (item.list_items) {
        return this.getItemNames(item.list_items, names, field);
      }
      return names;
    }
    return names;
  }

  findItem(items, id) {
    const index = items.findIndex((l) => l.id == id);
    if (index >= 0) {
      return items[index];
    }
    return {
      name: id,
    };
  }

  setControls() {
    if (this.parentFormGroup) {
      this.fields.forEach((field) => {
        (<FormGroup> this.parentFormGroup.controls.custom_fields).addControl(
          field.key,
          new FormControl({
            value: this.model.custom_fields[field.key],
            disabled: this.disabled,
          }),
        );
      });
    }
  }

  ngOnChanges() {
    if (!this.model) {
      this.model = {};
    }
    if (!this.model.custom_fields) {
      this.model.custom_fields = {};
    }
  }

  addImages(field, images = []) {
    if (this.parentFormGroup) {
      const control = (<UntypedFormArray> this.parentFormGroup.controls.custom_fields).controls[field.key];
      control.setValue(images);
    } else if (this.model) {
      this.model.custom_fields[field.key] = images;
    }
    this.updated.emit();
  }

  setUser(field, disabled = false) {
    if (!disabled) {
      this.matDialog.open(AssignUserComponent, { data: field.field_filter }).afterClosed().toPromise().then((user) => {
        if (user) {
          const value = {
            id: user.id,
            name: user.name,
          };
          this.model.custom_fields[field.key] = value;
          if (this.parentFormGroup) {
            this.parentFormGroup.controls.custom_fields.patchValue({
              [field.key]: value,
            });
          }
          this.updated.emit();
        }
      })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  searchList(field, value: any) {
    if (value && value.toUpperCase) {
      this.filteredList[field.key] = field.list.list_items.filter((value1) => value1.name.toUpperCase().indexOf(value.toUpperCase()) >= 0);
    } else {
      this.filteredList[field.key] = cloneDeep(field.list.list_items);
    }
  }

  searchItem(field, value: any) {
    if (value && value.toUpperCase) {
      this.filteredSublist[field.key] = this.sublist[field.key].filter((value1) => value1.name.toUpperCase().indexOf(value.toUpperCase()) >= 0);
    } else {
      this.filteredSublist[field.key] = cloneDeep(this.sublist[field.key]);
    }
  }

  displayItem = (list) => (id) => {
    if (list && list.length !== 0 && id) {
      const i = list.findIndex((value) => value.id === id);
      if (i >= 0) {
        return list[i].name;
      }
      return id;
    }
    return id;
  };

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
