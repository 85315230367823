/* eslint-disable no-underscore-dangle */
import {
  Component, EventEmitter, Input,
  input, OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray, FormBuilder, FormGroup, Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'app/admin/services/admin.service';
import { ConfirmDialogComponent } from 'app/main/confirm-dialog/confirm-dialog.component';
import { Category } from 'app/models/category.model';
import { InventoryActionProduct } from 'app/models/inventory-action-product';
import { Product } from 'app/models/product.model';
import { RoleService } from 'app/role/role.service';
import { CategoriesService } from 'app/services/categories.service';
import { InventoryActionService } from 'app/services/inventory-action.service';
import { ProductsService } from 'app/services/products.service';
import { debounceTime } from 'rxjs';

import { KitsDialogComponent } from '../kits-dialog/kits-dialog.component';
import { ProductsDialogComponent } from '../products-dialog/products-dialog.component';

@Component(
  {
    selector: 'app-inventory-action-form',
    templateUrl: './inventory-action-form.component.html',
    styleUrls: ['./inventory-action-form.component.scss'],
  },
)
export class InventoryActionFormComponent implements OnInit, OnChanges {
  @Input() brandId: number;

  @Input() filterCategories: number[];

  @Input() parentFormGroup: FormGroup;

  @Input() searchBy = 'product';

  @Input() showTotals = true;

  @Input() showCategories = true;

  @Input() clientBookingMode = false;

  @Input() simpleView = false;

  @Input() parentModel: string = 'check_in';

  @Input() gallery: any[] = [];

  @Input() photos: any[] = [];

  @Input() isReadOnly = false;

  readonly panelOpenState = signal(false);

  public canCreate: boolean;

  public canDestroy: boolean;

  public canUpdate: boolean;

  public categories: Category[];

  public mainCategory: Category;

  hasMainCategory: boolean;

  form: FormGroup;

  @Output() approved: EventEmitter<void> = new EventEmitter();

  @Output() dataChanged: EventEmitter<unknown> = new EventEmitter();

  @Output() declined: EventEmitter<void> = new EventEmitter();

  @ViewChild('expansionPanel') expansionPanel!: MatExpansionPanel;

  constructor(
    public categoriesService: CategoriesService,
    private fb: FormBuilder,
    private inventoryActionsService: InventoryActionService,
    private matDialog: MatDialog,
    private rolesService: RoleService,
    private snackBar: MatSnackBar,
    private productsService: ProductsService,
    public adminService: AdminService,
  ) {
    this.rolesService.can('update', this.parentModel).subscribe((canUpdate) => {
      this.canUpdate = canUpdate;
    });
    this.rolesService.can('create', this.parentModel).subscribe((canCreate) => {
      this.canCreate = canCreate;
    });
    this.rolesService.can('destroy', this.parentModel).subscribe((canDestroy) => {
      this.canDestroy = canDestroy;
    });
  }

  get parentFormArray() {
    return this.parentFormGroup.get('inventory_action_products') as FormArray;
  }

  get parentDocumentOnApproval() {
    return this.parentDocument?.status?.approval;
  }

  get parentDocument() {
    return this.parentFormGroup.getRawValue()?.document;
  }

  get isApproved() {
    return this.parentFormGroup.getRawValue()?.status?.completed;
  }

  get isDeclined() {
    return this.parentFormGroup.getRawValue()?.status?.failed;
  }

  get isParentLocked() {
    return this.parentDocument?.status?.locked;
  }

  get isLocked() {
    return this.parentFormGroup.getRawValue()?.status?.locked;
  }

  get completed() {
    return this.isApproved || this.isDeclined;
  }

  ngOnInit() {
    this.categoriesService.categories.subscribe((categories) => {
      this.categories = categories;

      this.mainCategory = categories.find((category) => category.use_as_name);
      if (this.filterCategories?.length) {
        this.categories = this.categories.filter((category) => this.filterCategories.includes(category.id));
      }
      this.hasMainCategory = this.categories.some((category) => category.id === this.mainCategory?.id);
      this.initializeForm();
      // this.emitTotal();

      if (this.isLocked) {
        this.parentFormGroup.get('quantity')?.disable();
        this.parentFormGroup.get('discount_percent')?.disable();
        this.parentFormGroup.get('amount')?.disable();
      }
    });
  }

  onImagesUploaded(pictures) {
    const picturesArray = this.parentFormGroup.get('pictures') as FormArray;
    picturesArray.clear();
    pictures.forEach((picture) => {
      picturesArray.push(this.fb.group(picture));
    });
    this.emitTotal();
  }

  togglePanel() {
    if (this.expansionPanel.expanded) {
      this.expansionPanel.close();
    } else {
      this.expansionPanel.open();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentFormGroup && !changes.parentFormGroup.firstChange && this.categories) {
      this.initializeForm();
    }
  }

  private initializeForm() {
    this.form = this.fb.group({});
    this.categories.forEach((category) => {
      this.form.addControl(category.id.toString(), this.fb.array([]));
    });

    if (!this.canUpdate || this.isLocked || this.isParentLocked) {
      this.parentFormGroup.get('comment')?.disable();
    } else {
      this.parentFormGroup.get('comment')?.valueChanges?.pipe(debounceTime(500))?.subscribe(() => {
        this.emitTotal();
      });
    }

    this.prefillForm();
  }

  private prefillForm() {
    this.parentFormArray?.controls?.forEach((control) => {
      const product = control.getRawValue();
      let categoryFormArray = this.getCategoryFormArray(product.category_id);

      if (!categoryFormArray) {
        categoryFormArray = this.fb.array([]);
        this.form.setControl(product.category_id.toString(), categoryFormArray);
      }

      categoryFormArray.push(control);
      this.addFormHandlersAndValidators(control as FormGroup);
    });
  }

  onAddItem(category_id: number) {
    if (this.searchBy === 'product') {
      this.selectProduct(category_id);
    } else if (this.searchBy === 'kit') {
      this.selectKit();
    }
  }

  onReplaceItem(category_id: number, index: number) {
    this.replaceProduct(category_id, index);
  }

  onRemoveItem(category_id: number, index: number) {
    this.matDialog.open(
      ConfirmDialogComponent,
      { data: { title: 'Eliminar producto', body: '' } },
    )
      .afterClosed().subscribe((result) => {
        if (result) {
          this.removeRow(category_id, index);
        }
      });
  }

  removeRow(category_id: number, index: number) {
    const categoryArrayControl = this.form.get(category_id.toString()) as FormArray;
    categoryArrayControl.at(index)?.get('_destroy')?.setValue(true);

    if (!categoryArrayControl.at(index).get('id').value) {
      const ctlIdx = this.parentFormArray.controls.findIndex((control) => control === categoryArrayControl.at(index));
      this.parentFormArray.removeAt(ctlIdx);
    }
    categoryArrayControl.removeAt(index);
  }

  selectKit() {
    this.matDialog.open(KitsDialogComponent).afterClosed().subscribe((selectedKit) => {
      if (!selectedKit) return;

      const products = selectedKit.kit_products.filter(
        (product) => this.categories.map((cat) => cat.id).includes(product.category_id),
      );
      products.forEach((product: InventoryActionProduct) => {
        this.addProductRow(product.product_id, product.category_id);
      });
    });
  }

  selectProduct(categoryId: number) {
    const categoryName = this.categories.find((category) => category.id === categoryId)?.name;
    this.matDialog.open(ProductsDialogComponent, {
      data: { categoryId, categoryName, brandId: this.brandId }, minWidth: '50vw',
    }).afterClosed().subscribe((product: Partial<Product>) => {
      if (!product) return;

      if (typeof product === 'object') {
        const categoryFormArray = this.form.get(categoryId.toString()) as FormArray;
        const alreadyAdded = categoryFormArray.controls.find(
          (ctl) => ctl.get('product_id')?.value === product.id,
        );

        if (alreadyAdded) {
          if (alreadyAdded.get('_destroy')?.value) {
            alreadyAdded.get('_destroy')?.setValue(false);
          } else {
            this.snackBar.open('Este producto ya está agregado', 'Cerrar', {
              duration: 3000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
        } else {
          const control = this.generateFormGroup();
          categoryFormArray.push(control);
          this.addProductRow(product.id, categoryId, categoryFormArray.length - 1);
        }
      } else if (typeof product === 'string') {
        this.parentFormGroup.get('comment').setValue(product);
      }
    });
  }

  replaceProduct(categoryId: number, index: number) {
    const categoryFormArray = this.getCategoryFormArray(categoryId);
    const currentProduct = categoryFormArray.at(index).get('product_id').value;
    const categoryName = this.categories.find((category) => category.id === categoryId)?.name;
    this.matDialog.open(ProductsDialogComponent, {
      data: {
        categoryId, categoryName, brandId: this.brandId, currentProduct,
      },
      minWidth: '50vw',
    }).afterClosed().subscribe((product: Partial<Product> | string) => {
      if (!product) return;

      if (typeof product === 'object') {
        this.updateRow(categoryFormArray.at(index) as FormGroup, product.id, categoryId);
      } else if (typeof product === 'string') {
        this.parentFormGroup.get('comment').setValue(product);
      }
    });
  }

  addProductRow(product_id: number, categoryId: number, index?: number) {
    const categoryFormArray = this.getCategoryFormArray(categoryId);

    const alreadyAdded = categoryFormArray.controls.find(
      (ctl) => ctl.get('product_id')?.value === product_id,
    );

    if (alreadyAdded) {
      alreadyAdded.get('quantity').markAsTouched();
      alreadyAdded.get('_destroy')?.setValue(false);
      return;
    }
    const control = categoryFormArray.at(index || categoryFormArray.length - 1) as FormGroup;
    this.updateRow(control, product_id, categoryId);
    this.parentFormArray.push(control);
  }

  private updateRow(rowGroup: FormGroup, productId: number, categoryId: number) {
    this.productsService.get(productId, categoryId).subscribe((product) => {
      rowGroup.get('product_id')?.setValue(product.id);
      rowGroup.get('category_id')?.setValue(product.category.id);
      rowGroup.get('code')?.setValue(product.code);
      rowGroup.get('unit')?.setValue(product.unit);
      rowGroup.get('discount_percent')?.enable();
      rowGroup.get('discount_percent')?.setValue(product.discount_percent);
      rowGroup.get('amount')?.setValue(product.price);
      rowGroup.get('description')?.setValue(product.description);
      rowGroup.get('quantity')?.enable();
      rowGroup.get('quantity')?.setValue(1);
      rowGroup.get('_destroy')?.setValue(false);
      rowGroup.get('_edited')?.setValue(true);
    });
  }

  getCategoryFormArray(categoryId: number): FormArray {
    return (this.form?.get(categoryId.toString()) as FormArray);
  }

  getCategoryTotal(categoryId?: number) {
    if (!this.form) {
      return 0;
    }
    const formValue = this.form.getRawValue();

    const total = Object.keys(formValue)
      .filter((key) => key.toString() === categoryId.toString())
      .map((key) => formValue[key])
      .reduce((acc, val) => acc.concat(val), [])
      .filter((control) => !control._destroy)
      .map((control) => (control.amount || 0) * (control.quantity || 0))
      .reduce((acc, val) => acc + val, 0);

    return total;
  }

  getTotal() {
    if (!this.form) {
      return 0;
    }
    const formValue = this.form.getRawValue();

    const total = Object.keys(formValue)
      .map((key) => formValue[key])
      .reduce((acc, val) => acc.concat(val), [])
      .filter((control) => !control._destroy)
      .map((control) => (control.amount || 0) * (control.quantity || 0) * (1 - (control.discount_percent || 0) / 100))
      .reduce((acc, val) => acc + val, 0);

    return total;
  }

  private generateFormGroup({
    id = null,
    category_id = null,
    code = '',
    description = '',
    discount_percent = null,
    kit_name = '',
    kit_product_quantity = null,
    amount = null,
    product_id = null,
    quantity = null,
    unit = '',
    _destroy = false,
    _edited = false,
  } = {}) : FormGroup {
    const controlGroup = this.fb.group({
      id,
      product_id,
      code,
      discount_percent: [{ value: discount_percent, disabled: !discount_percent }],
      quantity: [{ value: quantity, disabled: !quantity }],
      unit,
      amount,
      category_id,
      description,
      kit_name,
      kit_product_quantity,
      _destroy,
      _edited,
    });

    this.addFormHandlersAndValidators(controlGroup);

    return controlGroup;
  }

  addFormHandlersAndValidators(controlGroup: FormGroup) {
    controlGroup.get('product_id').setValidators(Validators.required);
    controlGroup.get('category_id').setValidators(Validators.required);
    controlGroup.get('code').setValidators(Validators.required);
    controlGroup.get('quantity').setValidators([Validators.required, Validators.min(1)]);
    controlGroup.get('unit').disable();
    controlGroup.get('unit').setValidators(Validators.required);
    controlGroup.get('amount').setValidators([Validators.required, Validators.min(0)]);
    controlGroup.get('description').disable();
    controlGroup.get('kit_name')?.disable();
    controlGroup.get('kit_product_quantity')?.disable();
    controlGroup.get('discount_percent').setValidators([Validators.min(0), Validators.max(100)]);

    const category = this.categories.find((cat) => cat.id === controlGroup.get('category_id').value);
    if (category?.name === 'Adicionales') {
      controlGroup.get('amount').disable();
      controlGroup.get('_edited')?.setValue(true);
    }

    controlGroup.get('discount_percent').valueChanges.subscribe((value) => {
      if (value > 100) {
        controlGroup.get('discount_percent').setValue(100, { emitEvent: false });
      }
    });

    if (!this.canUpdate) {
      controlGroup.disable();
    }

    if (this.isLocked || this.isParentLocked || !this.canUpdate) {
      controlGroup.get('quantity').disable();
      controlGroup.get('code').disable();
      controlGroup.get('amount').disable();
    }

    controlGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      if (!data._destroy && (!data.quantity)) return;
      this.emitTotal();
    });
  }

  approveInventoryAction() {
    this.matDialog.open(
      ConfirmDialogComponent,
      { data: { title: '¿Aprobar item?', body: 'Esta acción es irreversible.' } },
    )
      .afterClosed().subscribe((result) => {
        if (result) {
          this.inventoryActionsService.approve(this.parentFormGroup.getRawValue().id).subscribe(() => {
            const statusData = this.parentFormGroup.get('status').value;
            this.parentFormGroup.get('status').setValue(
              {
                ...statusData, completed: true, initial: false, locked: true,
              },
            );
            this.approved.emit();
          });
        }
      });
  }

  declineInventoryAction() {
    this.matDialog.open(
      ConfirmDialogComponent,
      { data: { title: '¿Rechazar item?', body: 'Esta acción es irreversible.' } },
    )
      .afterClosed().subscribe((result) => {
        if (result) {
          this.inventoryActionsService.decline(this.parentFormGroup.getRawValue().id).subscribe(() => {
            const statusData = this.parentFormGroup.get('status').value;
            this.parentFormGroup.get('status').setValue(
              {
                ...statusData, failed: true, initial: false, locked: true,
              },
            );
            this.declined.emit();
          });
        }
      });
  }

  updateQuantity(categoryId: number, index: number, step: number) {
    const formArray = this.getCategoryFormArray(categoryId);
    const productControl = formArray.at(index);
    const currentQuantity = productControl.get('quantity')?.value || 0;

    const newQuantity = currentQuantity + step;
    if (newQuantity >= 0) {
      productControl.get('quantity')?.setValue(newQuantity);
    }
  }

  onQuantityInputBlur(categoryId: number, index: number): void {
    const formArray = this.getCategoryFormArray(categoryId);
    const productControl = formArray.at(index);
    let currentQuantity = productControl.get('quantity')?.value;

    currentQuantity = Number(currentQuantity);

    if (Number.isNaN(currentQuantity) || currentQuantity <= 0) {
      this.matDialog.open(
        ConfirmDialogComponent,
        { data: { title: 'Eliminar producto', body: '' } },
      ).afterClosed().subscribe((result) => {
        if (result) {
          this.removeRow(categoryId, index);
        } else {
          productControl.get('quantity')?.setValue(1);
        }
      });
    } else {
      productControl.get('quantity')?.setValue(currentQuantity);
    }
  }

  blockNegativeInput(event: KeyboardEvent) {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  }

  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  emitTotal() {
    this.dataChanged.emit(this.getTotal());
  }

  get discountTotal() {
    return this.parentFormGroup.getRawValue().inventory_action_products?.map(
      (product) => product.amount * ((product.discount_percent || 0) / 100) * product.quantity,
    ).reduce((a, b) => a + b, 0) || 0;
  }

  hasProducts(): boolean {
    if (!this.categories || this.categories.length === 0) {
      return false;
    }
    return this.categories.some((category) => this.getCategoryFormArray(category.id).controls.length > 0);
  }
}
