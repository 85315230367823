@if (additionals.length > 0) {
  <div class="row">
    <div class="col" *ngFor="let additional of visibleAdditionals">
      <mat-checkbox
          name="aditional"
          [checked]="isSelected(additional)"
          (change)="onChecked($event, additional)"
          [disabled]="readonly">
        {{additional.name}}<b *ngIf="additional.price">&nbsp;({{additional.price * adminService.getTaxValue() | money}})</b>
      </mat-checkbox>
    </div>
  </div>
}

@if (!readonly && hiddenAdditionals.length > 0) {
  <div
      class="extra"
      (click)="onShowHidden()"
      (keyup)="$event.keyCode === 13 && onShowHidden()"
      tabindex
  >
    <i class="fa fa-plus"></i>Agregar Adicionales
  </div>
}

<div class="row" *ngIf="!additionals || additionals.length === 0">
  <div class="col">
    Sin servicios adicionales
  </div>
</div>
