import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/_base.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends BaseService {
  params = ['name', 'keys', 'db', 'db_type', 'sheet'];

  updateParams = ['name', 'db'];

  constructor(
    public http:HttpClient,
  ) {
    super(http, null);
  }

  get() {
    return this.http.get(`${this.managerURL}/databases`, this.getParams());
  }

  create(value) {
    return this.http.post(`${this.managerURL}/databases`, {
      database: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id, value) {
    return this.http.put(`${this.managerURL}/databases/${id}`, {
      database: this.permitParams(value, ...this.updateParams),
    }, this.getParams());
  }

  delete(id) {
    return this.http.delete(`${this.managerURL}/databases/${id}`, this.getParams());
  }
}
