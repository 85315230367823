import {Pipe, PipeTransform} from '@angular/core';
import {AdminService} from "../../admin/services/admin.service";
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  constructor(
    private adminService: AdminService
  ) {
  }
  transform(value) {
    const prefix = this.adminService.getText('currency_prefix',null,' ');
    const suffix = this.adminService.getText('currency_suffix',null,' ');
    return new CurrencyPipe('es-CL').transform(value, suffix, prefix, '0.0-0');
  }
}
