import {debounceTime, first} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CheckInService} from "../../services/check-in.service";
import {MatDialog} from "@angular/material/dialog";
import {FilterComponent} from "../filter/filter.component";
import {UntypedFormControl} from "@angular/forms";
import {interval, Subscription} from "rxjs";
import {StatusesService} from "../../services/statuses.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {PaginatedItems, PaginatedOrders} from "../../models/types";
import {uniq} from "lodash-es";
import * as XLSX from "xlsx";
import {OrderDateRangeComponent} from "../order-date-range/order-date-range.component";
import {DatePipe} from "@angular/common";
import {RoleService} from "../../role/role.service";
import { AdminService } from 'app/admin/services/admin.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['correlative-id', 'requirement-name', 'object-identifier','client-name','status-list'];
  dataSource: MatTableDataSource<any>;

  statuses: any[] = [];
  orders: any[] = [];
  searchControl: UntypedFormControl = new UntypedFormControl();
  filter: any  = this.getFilter();
  page = 1;
  total_pages = 1;
  downloading = false;
  filters: any = [{
      name: 'Estado',
      key: 'status_id',
      options: [],
      multi: true
    }
  ];
  subs: Subscription[] = [];
  public working = false;
  private search: any;
  count = 0;
  loadingMore = false;
  totalByStatus: any = {};
  constructor(
    private orderService: CheckInService,
    private matDialog: MatDialog,
    private statusesService: StatusesService,
    public roleService: RoleService,
    public appService: AdminService
  ) { }

  ngOnInit(search?) {
    this.appService.pageTitle.set(this.appService.getText('order_plural'));
    this.ngOnDestroy();
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if(this.statuses.length > 0){
        this.search = value;
        this.getOrders();
      }
    }));
    this.subs.push(this.appService.locations$.subscribe((value)=>{
      if(this.statuses.length > 0){
        this.getOrders();
      }
    }))
    this.subs.push(this.statusesService.statuses('StatusForOrder').subscribe((value) => {
      this.statuses = value;
      if(!this.filter && value.length > 0){
        this.filter = this.statuses.filter((status) => !status.finish).map((s) => s.id);
        this.saveFilter();
      }
      if(this.statuses.length > 0) {
        this.getOrders();
      }
    }));
    this.getMore();
    this.getTotalsByStatus();
    this.dataSource = new MatTableDataSource(this.orders);

  }
  getTotalsByStatus() {
    this.orderService.totalsByStatus().subscribe({
      next: (response) => {
        const totals = response.body;
        if (totals && Array.isArray(totals)) {
          this.totalByStatus = totals.reduce((acc, current) => {
            acc[current.status_id] = current;
            return acc;
          }, {});
        } else {
          //console.error('Totales por estado vacio');
        }
       // console.log(this.totalByStatus);
      }
    });
  }
  statusOn(id: number) {
    if(this.filter){
      const index = this.filter.findIndex((s) => parseInt(s,0) === id);
      return index >= 0;
    }else{
      return false;
    }
  }

  getStatusCompletion(status_id: number, order) {
    const index =  order.status_logs.findIndex(statusLog =>  statusLog.status_id === status_id);
    if (index >= 0) {
      return order.status_logs[index].completed;
    } else {
      return false;
    }
  }

  getOrders(loadingMore?) {
    // SCROLL TOP
    if(!loadingMore){
      this.resetOrders();
      this.working = true;
    }
    this.orderService.all({status_id: this.filter, page: this.page}, this.search)
      .toPromise().then((response:HttpResponse<PaginatedOrders>) => {
      if(loadingMore){
        this.orders = this.orders.concat(response.body.orders);
      }else{
        this.orders = response.body.orders;
      }
      this.count = response.body.total;
      this.page = response.body.page;
      this.total_pages = response.body.total_pages;
      this.working = false;
      this.loadingMore = false;
      this.dataSource.data = this.orders;
      this.appService.pageTitle.set(this.appService.getText('order_plural'));//+ " (" + this.count + ")"
    }).catch((error:HttpErrorResponse) => {
      console.log(error);
      this.working = false;
    });
  }

  resetOrders(){
    const element = document.getElementById('orders');
    if(element){
      element.scrollTop = 0;
    }
    this.page = 1;
    this.total_pages = 1;
    this.count = 0;
  }
  getMore(){
    const element = document.getElementById('orders');
    if(element){
        this.loadingMore = true;
        this.page++;
        this.getOrders(true);
    }
  }
  getFilter(){
    const value = localStorage.getItem('orders_filter');
    let filter = null;
    if(value){
      filter = uniq(value.split(',')).map((v)=>parseInt(v,0));
    }
    return filter;
  }
  saveFilter(){
    this.filter.push(0);
    this.filter = uniq(this.filter);
    localStorage.setItem('orders_filter',this.filter);
  }
  toggleFilter(id: number) {
    const index = this.filter.findIndex((s) => s === id);
    if (index >= 0) {
      this.filter.splice(index, 1);
    }else {
      this.filter.push(id);
    }
    this.saveFilter();
    this.getOrders();
  }
  openFilters() {
    // Setup Options
    this.filters[0].options = this.statuses.map((s) => [s.id, s.name, this.statusOn(s.id)]);
    this.matDialog.open(FilterComponent, {data: this.filters}).afterClosed().pipe(first()).forEach((result) => {
      if(result){
        this.filter = result.status_id;
        this.saveFilter();
        this.getOrders();
      }
    });
  }
  openDateRange(){
    this.matDialog.open(OrderDateRangeComponent).afterClosed().pipe(first()).forEach((result) => {
      if(result){
        this.exportAsExcelFile(result);
      }
    });
  }
  exportAsExcelFile(dateRange?): void {
    this.downloading = true;
    this.orderService.allPost({status_id: this.filter, page: -1, dates: dateRange}, this.search)
      .toPromise().then((value:HttpResponse<PaginatedItems>) => {
      const excelFileName = 'Ordenes de Trabajo';
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.formatJson(value.body.items));
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
      const today = new Date();
      const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '';
      const time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
      const name = excelFileName + date + time;
      XLSX.writeFile(workbook, name + ".xlsx");
      this.downloading = false;
    });

  }
  formatJson(data){
    if(data){
      return data.map(row => {
        let formattedData = {
          'ID': row.correlative_id,
          'Cliente': row.client_name,
          'Rut': row.client_rut,
          'E-Mail': row.client_email,
          'Recepcionista': row.user_name,
          'Marca': row.brand_name,
          'Modelo': row.brand_model_name,
          'Patente': row.identifier,
          'Chasis': row.chasis,
          'KMS': row.kms,
          'Requerimiento': row.requirement_name,
          'Estado': row.status_name,
          'Fecha Recepción': new DatePipe('es-CL').transform(row.check_in_time,'short'),
          'Fecha Entrega': new DatePipe('es-CL').transform(row.ended_at,'short'),
          'Comentario': row.comment,
        }
        Object.keys(row).filter((value:string) => value.includes('$$') && !value.includes('image')).forEach(value => {
          let key = value.split('$$')[0].replace(/\'/g,'')
          formattedData[key] = row[value];
        });
        return formattedData;
      })
    }else{
      return []
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  clearSearch() {
    this.searchControl.setValue('');
  }
  getThumbUrl(image:any){
    const search = 'images%2F';
    let url
    if(image.url || image.new_url){
      if(image.video){
        url = (image.new_url || image.url).replace(search,search+"thumb@256_");
        url = url.replace(".mp4",".mp4.gif");
        url = url.replace(".webm",".webm.gif");
      }else{
        url = (image.new_url || image.url).replace(search,search+"thumb@256_");
      }
    }
    return url;
  }
  
}
