
<div #commentsContainer class="comments-container">
  @for (comment of comments ; track comment.id) {
    

    <div class="comment">
      @if (comment?.user_id == this.userID) {
        <div class="expander"></div>
      }
      @else{
        <div class="user">
          <div class="avatar" [ngStyle]="comment?.user?.picture ? {'background-image': 'url(' + comment?.user?.picture + ')'} : {}">
            @if (!comment?.user?.picture){
              {{ getInitials(comment?.user?.name || comment.name) }}
            }
          </div>
        </div>
      }
   

      <div class="col" [ngClass]="{ 'own': comment?.user_id == userID, 'not-own': comment?.user_id != userID }" *ngIf="comment.user">
        <div class="info">
          @if (comment?.user_id != this.userID) {
            <span class="name">{{comment?.user?.name || comment.name}}</span>
          }
          <span class="date">{{comment.created_at | date:'short'}}</span>
          @if (comment?.user_id != this.userID) {
            <i class="fa fa-reply" (click)="reply(comment.user)" *ngIf="comment.user"></i>
          }
        </div>
        <div class="content" [ngClass]="comment.custom_class">

          <div class="text">
            <span>{{comment.comment}}</span>

            <span class="tag" *ngFor="let user of comment.users | slice:0:1"  (click)="reply(user)">&#64;{{user.name}}</span>
            <span class="tag" *ngIf="comment.users.length > 1" [matTooltip]="getTooltip(comment.users)">CC ({{comment.users.length -1}})</span>

          </div>
          <div class="row" [ngClass]="{'right': comment?.user_id == userID}">
        
              <span class="event" *ngIf="comment.event || comment.calendar_event" [class.achieved]="comment.achieved" (click)="openEvent(comment.calendar_event)">
                <i class="fa fa-check" *ngIf="comment.achieved" style="margin-right: 3px;"></i>
                <i class="fa fa-clock" *ngIf="!comment.achieved" style="margin-right: 3px;"></i>
                {{(comment.calendar_event?.start_date || comment.event)  | date:'short'}}
              </span>
          
          </div>
        </div>
      </div>
    </div>
  }
</div>

@if (comments.length == 0) {
  <div class="empty" *ngIf="comments.length == 0">
    No hay comentarios
  </div>
}




<form (submit)="createComment(f)" *ngIf="(roleService.can('create','order_comment') | async)" #f="ngForm">
  
  <div class="receipts" *ngIf="selected_users.length > 0">
    <span class="centered">Mencionando a:</span>
    <div class="receipt" *ngFor="let user of selected_users" (click)="removeReply(user)">&#64;{{user.name}}</div>
  </div>

  <div class="input">
    <input class="input-element" [(ngModel)]="order_comment.comment" name="comment" placeholder="Ingrese comentario" autocomplete="off" required id="comment-input">
    <div class="notify-user">
      <mat-form-field class="hidden-datepicker">
        <input matInput [matDatetimepicker]="datetimePicker" [matDatepickerFilter]="onlyWeek" placeholder="Choose a date" [min]="now" [(ngModel)]="order_comment.event" name="event">
        <mat-datepicker #picker></mat-datepicker>
        <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
      </mat-form-field>
      <input type="submit" class="hidden-datepicker">
    
        <i class="fa fa-wrench" (click)="addWork()" matTooltip="Requerir Trabajo" *ngIf="order && order.id && !disableAddWork"></i>
        <i class="fas fa-at" (click)="selectRoles()" matTooltip="Notificar"></i>
        <i class="far fa-clock" (click)="datetimePicker.open()" [class.has-event]="order_comment.event" matTooltip="Agregar Plazo"></i>
        <i class="fa fa-paper-plane last" (click)="createComment(f)" matTooltip="Enviar" [ngStyle]="order_comment.comment ? {'color': 'var(--sys-primary)'} : null"></i>
     
    </div>
  </div>

</form>
