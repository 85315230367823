import { NgModule } from '@angular/core';

import { BaseModule } from '../base.module';
import { ChoiceDialogComponent } from './choice-dialog/choice-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';

@NgModule({
  declarations: [
    ChoiceDialogComponent,
    ErrorDialogComponent,
    FileUploadDialogComponent,
  ],
  imports: [BaseModule],
  exports: [
    ChoiceDialogComponent,
    ErrorDialogComponent,
    FileUploadDialogComponent,
  ],
})
export class DialogsModule { }
