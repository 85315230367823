import {
  AfterViewInit, Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'app/admin/services/admin.service';
import { BudgetViewComponent } from 'app/budget/budget-view/budget-view.component';
import { OrderComponent } from 'app/main/order/order.component';
import { allInventoryActionTypes, InventoryAction } from 'app/models/inventory_action.model';
import { User } from 'app/models/user';
import { InventoryActionService } from 'app/services/inventory-action.service';
import { StatusesService } from 'app/services/statuses.service';
import { UserService } from 'app/services/user.service';
import { WarehousesService } from 'app/services/warehouses.service';
import { ChoiceDialogComponent } from 'app/shared/dialogs/choice-dialog/choice-dialog.component';
import { ErrorDialogComponent } from 'app/shared/dialogs/error-dialog/error-dialog.component';
import { FileUploadDialogComponent } from 'app/shared/dialogs/file-upload-dialog/file-upload-dialog.component';
import { toUnderscore } from 'app/utils/string-utils';
import {
  catchError, map, merge, Observable, of, startWith, Subject, switchMap, takeUntil,
} from 'rxjs';

import { InventoryActionDetailsComponent } from './details/inventory-action-details.component';
import { InventoryActionFormComponent } from './form/inventory-action-form.component';

@Component({
  selector: 'app-inventory-actions',
  templateUrl: './inventory-actions.component.html',
  styleUrls: ['./inventory-actions.component.scss', '../inventory.component.scss'],
})
export class InventoryActionsComponent implements OnInit, AfterViewInit, OnDestroy {
  allTypes = allInventoryActionTypes;

  creableTypes = [
    'InventoryActionForStock',
    'InventoryActionForConsume',
    'InventoryActionForMovement',
    'InventoryActionForLoss',
  ];

  private dataChanged$ = new Subject<boolean>();

  dataSource = new MatTableDataSource<InventoryAction>();

  displayedColumns: string[] = [
    'type',
    'correlative_id',
    'created_at',
    'estimated_completion_date',
    'user',
    'document_type',
    'document_id',
    'status',
    'actions',
  ];

  filters = new FormGroup({
    created_at_start: new FormControl(''),
    created_at_end: new FormControl(''),
    'status_id[]': new FormControl([]),
    type: new FormControl(''),
    'user_id[]': new FormControl([]),
  });

  inventoryActions = [];

  isLoading = true;

  isLoading1 = false;

  unsubscribe$: Subject<boolean> = new Subject();

  pageSizes = [10, 25, 50, 100];

  sort = 'updated_at desc';

  statuses = [];

  totalData = 0;

  totalPages = 0;

  users: Observable<User[]>;

  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(MatSort) sorter: MatSort;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private matDialog: MatDialog,
    private statusService: StatusesService,
    private userService: UserService,
    private warehousesService: WarehousesService,
    public adminService: AdminService,
    public inventoryActionService: InventoryActionService,
  ) {}

  ngOnInit() {
    this.statusService.statuses().subscribe((statuses) => {
      this.statuses = statuses.filter((status) => status.type.startsWith('StatusForInventoryAction'));
    });

    this.users = this.userService.users$.asObservable();
  }

  statusesFor(type: string) {
    return this.statuses.filter((status) => status.type === `StatusFor${type}`);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sorter;
    merge(
      this.paginator.page,
      this.sorter.sortChange.asObservable(),
      this.filters.valueChanges,
      this.warehousesService.selectedWarehouses.asObservable(),
      this.dataChanged$, // This is used to refresh the data table when an action is created
    )
      .pipe(
        startWith({}),
        takeUntil(this.unsubscribe$),
        switchMap(() => {
          this.isLoading = true;
          return this.getTableData$(
            (this.paginator?.pageIndex ?? 0) + 1,
            (this.paginator?.pageSize ?? 10),
            this.sort,
            this.warehousesService.selectedWarehouses.value.map((w) => w.id),
            this.filters.getRawValue(),
          ).pipe(catchError(() => of(null)));
        }),
        map((data) => {
          if (data == null) return [];
          this.totalData = data.total_count;
          this.totalPages = data.total_pages;
          this.isLoading = false;
          return data.items;
        }),
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
      });

    this.filters.controls.type.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.filters.get('status_id[]').setValue([]);
    });
  }

  getTableData$(pageNumber: number, pageSize: number, sort: string, warehouseIds: number[], filters) {
    return this.inventoryActionService.index(
      pageNumber,
      pageSize,
      sort,
      warehouseIds,
      filters,
    );
  }

  onSortChange({ active, direction }: { active: string; direction: string }) {
    if (!direction) {
      this.sort = 'updated_at desc';
      return;
    }

    this.sort = `${active} ${direction}`;
  }

  createAction(type: string) {
    this.matDialog
      .open(
        ChoiceDialogComponent,
        { data: { title: 'Seleccione una opción', choices: ['Crear Manualmente', 'Importar desde un archivo'] } },
      )
      .afterClosed().subscribe((result: number) => {
        if (result === 0) {
          this.openCreateForm(type);
        }
        if (result === 1) {
          this.openUploadForm(type);
        }
      });
  }

  openCreateForm(type: string) {
    this.matDialog
      .open(InventoryActionFormComponent, { data: { inventoryActionType: type }, minWidth: '25%' })
      .afterClosed().subscribe(() => {
        this.dataChanged$.next(true);
      });
  }

  openEditForm(inventoryAction: InventoryAction) {
    this.matDialog
      .open(
        InventoryActionFormComponent,
        { data: { edit: true, inventoryAction }, minWidth: '25%' },
      )
      .afterClosed().subscribe(() => {
        this.dataChanged$.next(true);
      });
  }

  openUploadForm(type: string) {
    this.dialogRef = this.matDialog
      .open(FileUploadDialogComponent, { data: { title: 'Importar desde archivo' } });

    this.dialogRef.componentInstance.submitClicked.subscribe((data: string) => {
      const parsedData = data
        .split('\n')
        .map((line) => line.split(','))
        .filter((line) => line.length > 0);

      this.isLoading1 = true;
      this.dialogRef.close();

      this.inventoryActionService.import(type, parsedData)
        .subscribe((result: InventoryAction | null) => {
          if (!result) {
            this.matDialog.open(ErrorDialogComponent, { data: { message: 'Error al importar los datos' } });
          }

          this.isLoading1 = false;
          this.dataChanged$.next(true);
        });
    });
  }

  openDetails(inventoryAction: InventoryAction) {
    this.matDialog.open(InventoryActionDetailsComponent, { data: { inventoryAction }, minWidth: '90%' });
  }

  selectAllUsers(event: MatCheckboxChange) {
    if (event.checked) {
      this.users.subscribe((users) => {
        this.filters.controls['user_id[]'].setValue(users.map((v) => v.id));
      });
    } else {
      this.filters.controls['user_id[]'].setValue([]);
    }
  }

  selectAllStatuses(event: MatCheckboxChange) {
    if (event.checked) {
      this.filters.controls['status_id[]'].setValue(
        this.statusesFor(this.filters.get('type').value).map((v) => v.id),
      );
    } else {
      this.filters.controls['status_id[]'].setValue([]);
    }
  }

  titleForType(type: string) {
    return this.adminService.getText(toUnderscore(type));
  }

  openDocument(element) {
    const { document } = element;
    if (element.document_type === 'Order') {
      this.matDialog.open(OrderComponent, { data: document.id, minWidth: '80%' });
    } else if (element.document_type === 'Budget') {
      this.matDialog.open(BudgetViewComponent, { data: { id: document.id }, minWidth: '80%' });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
