<div class="wrapper">
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="!loading">
    <div mat-dialog-title class="dialog-title">
      <i class="fa fa-calendar-check"></i> Evento: {{event.name || 'Otros'}}
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && !editMode && event">
      <div mat-dialog-content class="content">
        

      <div class="finalize" *ngIf="event" #wrapper>
        <div class="work">
          <div class="info" title="Taller" *ngIf="event.location">
            <div class="name"><i class="fa fa-home"></i> {{adminService.getText('location')}}</div>
            <div class="value">{{event.location.name}}</div>
          </div>
          <div class="info" title="Fecha de Evento">
            <div class="name"><i class="fa fa-clock"></i> Fecha</div>
            <div class="value">{{event.start_date | date:'short'}}</div>
          </div>
          <div class="info" title="Duración">
            <div class="name"><i class="fa fa-clock"></i> Duración (Horas)</div>
            <div class="value">{{event.duration}}</div>
          </div>
  
          <div class="info" title="Vehiculo" *ngIf="event.eventable_type=='Order' && event.eventable && company.object=='Vehicle'">
            <div class="name"><i class="fa fa-car"></i></div>
            <div class="value">{{event.eventable?.vehicle?.identifier || 'Sin Patente'}}</div>
          </div>
          <div class="info" title="Creado por">
            <div class="name"><i class="fa fa-user"></i> Creador</div>
            <div class="value">{{event.user?.name || 'Automático'}}</div>
          </div>
        </div>
      </div>

      <app-image-uploader style="margin-bottom: 10px;" [photos]="event.pictures" [small]="true" [scrollTarget]="wrapper" (uploaded)="saveImages($event)" [readonly]="!(roleService.can('update','event') | async)" *ngIf="event && event.pictures"></app-image-uploader>

      <div class="subtitle" *ngIf="event.comments">Comentarios</div>
      <app-order-comments [comments]="event.comments" (saveComment)="saveComment($event)" *ngIf="event.comments"></app-order-comments>

      
    </div>
      <div mat-dialog-actions class="footer">

        @if (event.eventable_type=='Order' && (roleService.can('read','check_in') | async) && event.eventable){
          <button mat-button class="primary" (click)="openOrder(event.eventable_id)">
            <i class="fa fa-cube"></i>
            <span class="display-desktop">Ver Orden de trabajo Nº {{event.eventable.correlative_id}}</span>
          </button>
        }
        @if (event.eventable_type=='WorkInstance' && (roleService.can('read','work') | async)){
          <button mat-button class="primary" (click)="openWork(event.eventable_id)">
            <i class="fa fa-cube"></i>
            <span class="display-desktop">Ver Trabajo</span>
          </button>
        }

        <div class="expander"></div>

        <button mat-button (click)="closeDialog()">Cerrar</button>
        <button mat-button cdkFocusInitial (click)="edit()" *ngIf="(roleService.can('update','event') | async)">
          <span>Editar</span>
        </button>
      </div>
   
  </ng-container>



  <form [formGroup]="form" autocomplete="off" (submit)="saveForm()" *ngIf="!loading && (editMode || !event)">
    <fieldset>
      <ng-container *ngIf="locations && locations.length > 1">
        <mat-form-field>
          <mat-select placeholder="{{heading}}" formControlName="location_id" name="location_id" required autocomplete="off">
            <mat-option *ngFor="let location of locations" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Nombre del evento</mat-label>
            <input matInput formControlName="name" name="name" autocomplete="off" required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="hidden-datepicker">
            <mat-label>Fecha y hora de inicio"</mat-label>
            <input matInput [matDatetimepicker]="datetimePicker" [matDatepickerFilter]="onlyWeek" [min]="currentDate" formControlName="start_date" name="date_time" required>
            <mat-datepicker #picker></mat-datepicker>
            <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Duración (Horas)</mat-label>
            <input matInput type="number" formControlName="duration" name="duration" autocomplete="off" step="0.1" required>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
    <app-image-uploader style="margin-bottom: 10px;" [photos]="[]" [small]="true" [scrollTarget]="wrapper" (uploaded)="saveImages($event)" [readonly]="!(roleService.can('create','event') | async)" *ngIf="!event"></app-image-uploader>

    <div mat-dialog-actions class="footer">
      <button mat-button (click)="closeDialog()">Cancelar</button>
      <button mat-button class="warn" [disabled]="!form.valid || loading" *ngIf="!event || (roleService.can('update','event') | async)" (click)="deleteEvent($event)">Eliminar</button>
      <button mat-button class="primary" [disabled]="!form.valid || loading" *ngIf="!event || (roleService.can('update','event') | async)">Guardar</button>
    </div>
  </form>

</div>
