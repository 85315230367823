<ng-container *ngIf="notifications">
  <div class="notification" *ngFor="let notification of notifications; let last = last" [class.last]="last">
    <div class="user">
      <i class="fa fa-bell"></i> {{notification.title}}
      <div class="expander"></div>
      <div class="receipts">
        <div class="receipt last" *ngIf="notification.readed">Leído <i class="fa fa-eye"></i></div>
        <div class="receipt last" *ngIf="!notification.readed">No leído <i class="fa fa-eye-slash"></i></div>
      </div>
    </div>
    <div class="message">
      <div class="text" [innerHTML]="notification.safeBody">

      </div>
      <div class="event achieved" *ngIf="notification.answer">
        <ng-container *ngIf="notification.client_notification.answer_type=='date'">
          Cliente: {{notification.answer.date | date:'short'}}
        </ng-container>
        <ng-container *ngIf="notification.client_notification.answer_type=='datetime'">
          Cliente: {{notification.answer.date_time | date:'short'}}
        </ng-container>
        <ng-container *ngIf="notification.client_notification.answer_type=='time'">
          Cliente: {{notification.answer.time}}
        </ng-container>
        <ng-container *ngIf="notification.client_notification.answer_type=='text'">
          Cliente: {{notification.answer.text}}
        </ng-container>
        <ng-container *ngIf="notification.client_notification.answer_type=='boolean'">
          Cliente: {{notification.answer.boolean}}. {{notification.answer.text}}
        </ng-container>
        <div class="expander"></div>
        <i class="fa fa-comment"></i>
      </div>
    </div>
    <div class="date">
      <i class="fa fa-calendar"></i> {{notification.created_at | date:'short'}}
      <div class="expander"></div>
      <div class="receipts">
        <div class="receipt last">
          <span *ngIf="notification.answer">{{notification.updated_at | date:'short'}} </span>
          <span *ngIf="!notification.answer">Sin respuesta </span>
          <i class="fa fa-check" *ngIf="notification.answer"></i>
          <i class="fa fa-times" *ngIf="!notification.answer"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="notification" *ngIf="notifications && notifications.length == 0">
    <div class="message">
      <div class="text">
        No hay notificationes
      </div>
    </div>
  </div>
</ng-container>
<mat-spinner *ngIf="!notifications"></mat-spinner>
