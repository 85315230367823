import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component, forwardRef, Input, OnDestroy, OnInit, ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { interval } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { OrderComponent } from '../main/order/order.component';
import { PaginatedItems } from '../models/types';
import { RoleService } from '../role/role.service';
import { RequirementsService } from '../services/requirements.service';
import { StatusesService } from '../services/statuses.service';
import { UserService } from '../services/user.service';
import { SortService } from '../shared/sort/sort.service';
import { SortableTableDirective } from '../shared/sort/sortable-table.directive';
import { AssignUserComponent } from './assign-user/assign-user.component';
import { WorkService } from './work.service';
import { WorkColumnsComponent } from './work-columns/work-columns.component';
import { WorkColumnsService } from './work-columns/work-columns.service';
import { WorkDialogComponent } from './work-dialog/work-dialog.component';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
})
export class WorkComponent implements OnInit, OnDestroy {
  workList:any[];

  @Input() workpoint = false;

  zoom = 1;

  subs = [];

  users = [];

  roles = [];

  works = [];

  types = [{
    id: 'WorkAssignation',
    name: 'Asignados',
  }, {
    id: 'WorkRequest',
    name: 'Requeridos',
  }];

  zooms = [
    { id: 1, name: 'x1' },
    { id: 2, name: 'x2' },
    { id: 4, name: 'x4' },
    { id: 8, name: 'x8' },
  ];

  statuses = [];

  requirements = [];

  page = 1;

  total_pages = 1;

  downloading = false;

  highlight = null;

  loadingMore = false;

  columnsSet = false;

  count = 0;

  working = false;

  planMode = localStorage.getItem('planMode') === 'true';

  searchControl: UntypedFormControl = new UntypedFormControl();

  columns:any = { pending: [], requests: [] };

  filter = {
    can_change_status: false,
    user_id: [],
    work_id: [],
    status_id: [],
    role_id: [],
    requirement_id: [],
    query: null,
    type: ['WorkRequest', 'WorkAssignation'],
  };

  @ViewChildren(forwardRef(() => SortableTableDirective)) orderTables:SortableTableDirective[];

  constructor(
    private workService: WorkService,
    private matDialog: MatDialog,
    private router: Router,
    private columnsService: WorkColumnsService,
    public roleService: RoleService,
    private sortService: SortService,
    private userService: UserService,
    private statusService: StatusesService,
    private requirementService: RequirementsService,
    public appService: AdminService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.appService.pageTitle.set(`${this.appService.getText('work_assignation')}s`);
    Promise.all([
      this.userService.index().toPromise(),
      this.workService.all().toPromise(),
      this.statusService.all('StatusForOrder').toPromise(),
      this.requirementService.requirements$.pipe(first()).toPromise(),
      this.roleService.simple_index().toPromise(),
      this.route.queryParams.pipe(first()).toPromise(),
    ]).then((values:any) => {
      this.users = values[0].body;
      this.users.unshift({ id: 'NULL', name: 'Sin Asignado' });
      this.filter.user_id = this.users.map((r) => r.id);
      this.works = values[1].body;
      this.works.push({ id: 'NULL', name: 'Otros' });
      this.filter.work_id = this.works.map((r) => r.id);
      this.statuses = values[2].body;
      this.filter.status_id = this.statuses.map((r) => r.id);
      this.requirements = values[3].filter(
        (req) => req.location_ids.filter((li) => this.appService.getLocations().map((l) => l.id).includes(li)),
      );
      this.filter.requirement_id = this.requirements.map((r) => r.id);
      this.roles = values[4].body;
      this.roles.unshift({ id: 'NULL', name: 'Sin Asignado' });
      this.filter.role_id = this.roles.map((r) => r.id);
      if (values[5].not_assigned) {
        this.filter.user_id = ['NULL'];
        this.highlight = parseInt(values[5].not_assigned);
      }
      this.getWorks();
    }).catch((reason) => console.log(reason));
    this.subs.push(this.appService.locations$.subscribe((value) => {
      if (this.works.length > 0) {
        this.getWorks();
      }
    }));
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this.works.length > 0) {
        this.filter.query = value;
        this.getWorks();
      }
    }));
    this.getMore();
  }

  getMore() {
    interval(500).subscribe(() => {
      if (this.page < this.total_pages && !this.loadingMore) {
        const element = document.getElementById('works');
        if (element) {
          const loaderElement = document.getElementById('load-more');
          if (loaderElement && element.scrollTop + element.clientHeight > element.scrollHeight - loaderElement.clientHeight) {
            this.loadingMore = true;
            this.page++;
            this.getWorks(true);
          } else if (!loaderElement) {
            this.page = 1;
            this.getWorks();
          }
        }
      }
    });
  }

  getWorks(loadingMore?, sort?) {
    if (this.planMode) {
      const { filter } = this;
      filter.can_change_status = true;
      this.workService.onlyPriorityJobs(this.filter, 0, {
        sortColumn: 'correlative_id',
        sortDirection: 'asc',
      }).toPromise().then((response:any) => {
        this.workList = response.body;
      });
    } else {
      if (!loadingMore) {
        this.resetWorks();
        this.working = true;
      }
      this.sortService.getDefault('pending').then((sortValue) => {
        this.workService.index(this.filter, this.page, sort || sortValue).toPromise().then((response: HttpResponse<PaginatedItems>) => {
          this.total_pages = response.body.total_pages;
          this.count = response.body.total;
          this.page = response.body.page;
          this.working = false;
          this.appService.pageTitle.set(`${this.appService.getText('work_assignation')}s` + ` (${this.count})`);
          if (response.body.items.length > 0 && !this.columnsSet) {
            this.columnsSet = true;
            this.columnsService.setColumns('pending', response.body.items).then((value:any[]) => {
              if (value) {
                this.columns.pending = value;
                this.columns.pending_show = value.filter((v) => v[1]);
              }
            }).catch();
          }
          if (loadingMore) {
            this.workList = this.workList.concat(response.body.items);
          } else {
            this.workList = response.body.items;
          }
          this.addTimes();
          this.loadingMore = false;
        }).catch((error: HttpErrorResponse) => {
          console.log(error);
        });
      });
    }
  }

  setUser(values) {
    event.preventDefault();
    event.stopPropagation();
    this.roleService.can('update', 'work').pipe(first()).subscribe((value) => {
      if (value === true && values.roles_id) {
        this.matDialog.open(AssignUserComponent, { data: values.roles_id }).afterClosed().toPromise().then((user) => {
          if (user) {
            this.workService.update(values.id, { user_id: user.id }).toPromise().then((s) => {
              this.getWorks();
            }).catch((error) => {
              console.log(error);
            });
          }
        })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  openWork(id) {
    this.matDialog.open(WorkDialogComponent, { data: { id, type: 'WorkAssignation' }, minWidth: '80%' }).afterClosed().toPromise().then(() => {
      this.getWorks();
    });
  }

  openOrder(work) {
    event.preventDefault();
    event.stopPropagation();
    this.matDialog.open(OrderComponent, { data: work.order_id, minWidth: '80%' }).afterClosed().toPromise().then(() => {
      this.getWorks();
    });
  }

  addTimes() {
    for (const index in this.workList) {
      if (this.workList[index]) {
        const val = this.workList[index];
        this.workList[index].status = -this.workList[index].avg_time
          + this.workList[index].current_time
          - this.workList[index].order_avg_sum
          + this.workList[index].order_current_sum;
      }
    }
  }

  setupColumns(id) {
    this.matDialog.open(WorkColumnsComponent, { data: { columns: this.columns[id], id } }).afterClosed().toPromise().then((value) => {
      if (value) {
        this.columns[id] = value;
        this.columns[`${id}_show`] = value.filter((v) => v[1]);
      }
    });
  }

  exportAsExcelFile(): void {
    this.downloading = true;
    this.sortService.getDefault('pending').then((sortValue) => {
      this.workService.index(this.filter, -1, sortValue).toPromise().then((value:any) => {
        const excelFileName = 'Trabajos';
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.formatJson(value.body.items, this.columns.pending));
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const today = new Date();
        const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`;
        const time = `${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
        const name = excelFileName + date + time;
        XLSX.writeFile(workbook, `${name}.xlsx`);
        this.downloading = false;
      });
    });
  }

  formatJson(data, columns) {
    return this.columnsService.toExcel(data, columns);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  resetWorks() {
    const element = document.getElementById('works');
    if (element) {
      element.scrollTop = 0;
    }
    this.page = 1;
    this.total_pages = 1;
  }

  selectAllUsers(event:MatCheckboxChange) {
    if (event.checked) {
      this.filter.user_id = this.users.map((v) => v.id);
    } else {
      this.filter.user_id = [];
    }
    this.getWorks();
  }

  selectAllRoles(event:MatCheckboxChange) {
    if (event.checked) {
      this.filter.role_id = this.roles.map((v) => v.id);
    } else {
      this.filter.role_id = [];
    }
    this.getWorks();
  }

  selectAllWorks(event:MatCheckboxChange) {
    if (event.checked) {
      this.filter.work_id = this.works.map((v) => v.id);
    } else {
      this.filter.work_id = [];
    }
    this.getWorks();
  }

  selectAllStatuses(event:MatCheckboxChange) {
    if (event.checked) {
      this.filter.status_id = this.statuses.map((v) => v.id);
    } else {
      this.filter.status_id = [];
    }
    this.getWorks();
  }

  selectAllTypes(event:MatCheckboxChange) {
    if (event.checked) {
      this.filter.type = this.types.map((v) => v.id);
    } else {
      this.filter.type = [];
    }
    this.getWorks();
  }

  selectAllRequirements(event:MatCheckboxChange) {
    if (event.checked) {
      this.filter.requirement_id = this.requirements.map((v) => v.id);
    } else {
      this.filter.requirement_id = [];
    }
    this.getWorks();
  }

  togglePlanMode() {
    this.planMode = !this.planMode;
    localStorage.setItem('planMode', this.planMode.toString());
    this.getWorks();
  }

  clearSearch() {
    this.searchControl.setValue('');
  }
}
