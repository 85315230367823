import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ClientService} from "../client.service";
import {AdminService} from "../../admin/services/admin.service";
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-client-payment',
  templateUrl: './client-payment.component.html',
  styleUrls: ['./client-payment.component.scss']
})
export class ClientPaymentComponent implements OnInit {
  status = 'pending'
  company;
  payment:any;
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private companyService: AdminService,
    private zendesk: ZendeskService
  ) { }

  ngOnInit(): void {
    this.company = this.companyService.local$.getValue();
    this.route.queryParams.pipe().subscribe(value => {
      this.status = (value.collection_status || value.payment_status);
      this.clientService.getPreference(value.preference_id || value.external_reference,value.payment_id).toPromise().then(value1 => {
        this.payment = value1.body;
      }).catch(reason => {
        console.log(reason);
      })
    })
    this.zendesk.hide();
  }

}
