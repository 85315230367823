import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'photos',
  pure: false
})
export class PhotosPipe implements PipeTransform {
  transform(items: any[],key:string,inverse:boolean = false,placeholders): any {
    const types = ['jpg','svg','jpeg','png','gif','mp4','webm'];
    let typeItems = items.filter(item => {
      const pieces = item[key].split('.');
      const file_ext:string = pieces[pieces.length-1].split('?')[0];
      return types.indexOf(file_ext.toLowerCase())===-1 === inverse;
    });
    if(placeholders){
      typeItems = typeItems.filter(item => {
        const placeholder = placeholders.find(p=>p.id===item.placeholder_id);
        return placeholder?false:true;
      });
    }
    return typeItems;
  }
}
