import { Component } from '@angular/core';
import { FieldsService } from 'app/admin/services/fields.service';

@Component({
  selector: 'app-admin-works',
  templateUrl: './admin-works.component.html',
  styleUrls: ['./admin-works.component.scss','../admin-main.common.scss']
})
export class AdminWorksComponent {

  constructor(
    public fieldsService: FieldsService,
  ) { }
}
