<mat-toolbar>
  <i class="fa fa-list"></i>
  {{ companyService.getText('inventory', 'capitalize') }}
  <div class="expander"></div>
  <form [formGroup]="warehouseFilter">
    <mat-form-field>
      <mat-label>Bodegas</mat-label>
      <mat-select formControlName="warehouseIds[]" [multiple]="true">
        @for(warehouse of warehouses; track warehouse.id){
          <mat-option [value]="warehouse.id">{{warehouse.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</mat-toolbar>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a
    mat-tab-link
    routerLink="actions"
    routerLinkActive
    #rla2="routerLinkActive"
    [active]="rla2.isActive">Tareas de bodega</a>
  <a
    mat-tab-link
    routerLink="products"
    routerLinkActive
    #rla1="routerLinkActive"
    [active]="rla1.isActive">Consulta de Stock</a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
