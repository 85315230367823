import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ClientNotificationsService } from 'app/admin/services/client-notifications.service';
import { RequirementTransitionsService } from 'app/admin/services/requirement-transitions.service';
import { StatusesService } from 'app/admin/services/statuses.service';
import { WorksService } from 'app/admin/services/works.service';
import { Status } from 'app/models/status.model';
import { RoleService } from 'app/role/role.service';
import { CameraService } from 'app/shared/image-uploader/camera/camera.service';

@Component({
  selector: 'app-client-notification-form',
  templateUrl: './client-notification-form.component.html',
  styleUrls: [
    './client-notification-form.component.scss',
  ],
})
export class ClientNotificationFormComponent implements OnInit {
  @Input({ required: true }) model: string;

  answers: { id: string, name: string }[] = [
    { id: 'empty', name: 'Sin respuesta' },
    { id: 'text', name: 'Texto' },
    { id: 'date', name: 'Fecha' },
    { id: 'datetime', name: 'Fecha y Hora' },
    { id: 'boolean', name: 'Si/No' },
    { id: 'management', name: 'Gestión' },
  ];

  form = this.fb.group({
    active: true,
    answer_type: new FormControl('', Validators.required),
    banner_style: 'auto',
    banner_url: '',
    complete_work: false,
    name: new FormControl('', Validators.required),
    requirement_transitions_ids: [],
    roles_ids: [0],
    send_at: 'end',
    show_budget: false,
    show_order: false,
    show_work_data: false,
    statuses_ids: [],
    text: new FormControl('', Validators.required),
    whatsapp_active: false,
    works_ids: [],
  });

  statuses: Status[] = [];

  transitions: any[] = [];

  users: { id: number, name: string }[] = [{
    id: 0,
    name: 'Cliente',
  }];

  works = [];

  constructor(
    private fb: FormBuilder,
    public clientNotificationsService: ClientNotificationsService,
    private statusesService: StatusesService,
    private worksService: WorksService,
    private roleService: RoleService,
    private cameraService: CameraService,
    private requirementTransitionsService: RequirementTransitionsService,

  ) { }

  ngOnInit() {
    this.statusesService.all(`StatusFor${this.model}`).subscribe(
      (response: HttpResponse<Status[]>) => {
        this.statuses = response.body;
      },
    );

    this.worksService.all(`WorkFor${this.model}`).subscribe((response: HttpResponse<any>) => {
      this.works = response.body;
    });

    this.roleService.simple_index().subscribe((response: HttpResponse<any[]>) => {
      this.users = [...this.users, ...response.body];
    });

    if (this.model.startsWith('Crm')) {
      this.requirementTransitionsService.index().subscribe((response: HttpResponse<any>) => {
        this.transitions = response.body.items;
      });
    }
  }

  onFileChange(event, form) {
    const uploadTask = this.cameraService.saveImage(event.target.files[0]);
    uploadTask.then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        form.patchValue({ banner_url: url });
      });
    });
  }

  getStatusTooltipText(whatsapp_template_status: string): string {
    switch (whatsapp_template_status) {
      case 'APPROVED':
        return 'La plantilla de notificación está activa';
      case 'PENDING':
        return 'La plantilla de notificación está en revisión, no se enviará hasta que se active';
      case 'REJECTED':
        return 'La plantilla de notificación está rechazada, no se enviará';
      default:
        return '';
    }
  }

  getStatusClass(whatsapp_template_status: string): string {
    switch (whatsapp_template_status) {
      case 'APPROVED':
        return 'approved';
      case 'PENDING':
        return 'revision';
      case 'REJECTED':
        return 'rejected';
      default:
        return '';
    }
  }
}
