
<div *ngFor="let filter of data">
  <div mat-dialog-title class="name">
    {{ filter.name }}
  </div>
  
  <div mat-dialog-content class="content">
    <mat-checkbox class="grid-full" [checked]="filter.selectAll" (change)="toggleOptions(filter)">
      Todos
    </mat-checkbox>
    <br>
    <mat-checkbox class="grid" *ngFor="let option of filter.options" [(ngModel)]="option[2]">
      {{ option[1] }}
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-actions class="footer">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="applyFilters()">Aplicar</button>
</div>