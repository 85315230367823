import { Injectable } from "@angular/core";
import { ManagerService } from "./manager.service"; // Import the ManagerService class from the appropriate file
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router"; // Import the Router class from the appropriate file
import { Observable } from "rxjs";

@Injectable()
export class CanActivateManager  {
  constructor(
    private managerService: ManagerService,
    private router: Router
  ) {
    
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (this.managerService.user() != null) {
      return true
    }else if (this.managerService.authToken()) {
      return this.managerService.managerAuthCallback();
    }else {
      this.router.navigate(['manager','login']);
      return false;
    }
  }
}