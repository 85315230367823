<mat-toolbar class="header">
  <div class="back" (click)="goBack()" matTooltip="Atrás" matTooltipPosition="right">
    <mat-icon >arrow_back_ios_new</mat-icon>
  </div>
  <div class="hide-print" style="font-weight: 500;">
   <span class="hide-mobile">{{adminService.getText('order','capitalize')}} </span>#{{order?.correlative_id}}
  </div>
  <div class="expander"></div>
<button mat-button [mat-menu-trigger-for]="menu"><mat-icon>more_horiz</mat-icon></button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openNotifications()"><mat-icon>notifications</mat-icon> Notificaciones al cliente</button>
  <button mat-menu-item (click)="openChanges()"><mat-icon>fingerprint</mat-icon> Historial de cambios</button>
  <button mat-menu-item (click)="print()"><mat-icon>download</mat-icon> Descargar</button>
  <button mat-menu-item (click)="editMode=true"*ngIf="(roleService.can('update','check_in') | async)"><mat-icon>edit</mat-icon> Editar</button>
</mat-menu>

</mat-toolbar>

@if (!order){
  <div class="skeleton-container">
    <div class="skeleton" style="height: 98px;"></div>
    <div class="skeleton" style="height: 123px;"></div>
    <div class="skeleton" style="height: 170px;"></div>
    <div class="skeleton" style="height:  74px;"></div>
    <div class="skeleton" style="height:  90px;"></div>
  </div>
}

<div class="wrapper" #wrapper>
  <div class="check-in-container" *ngIf="editMode && order">
    <app-check-in [data]="order" (saved)="reload()" ></app-check-in>
  </div>

  <div class="order" *ngIf="order && !editMode">

    <div class="content">
      <div class="subtitle">{{adminService.getText('checkin')}}</div>
      <div class="info" *ngIf="order.location">
        <div class="name">{{adminService.getText('location')}}</div>
        <div class="value">{{order.location.name}}</div>
      </div>
      <div class="info">
        <div class="name">Recepcionista</div>
        <div class="value">{{order.user?.name}}</div>
      </div>
      <div class="info" *ngIf="order.user && order.user.phone">
        <div class="name">Teléfono</div>
        <div class="value">{{order.user?.phone}}</div>
      </div>
      <div class="info last">
        <div class="name">Creación</div>
        <div class="value">{{order.created_at | date:'shortDate'}}</div>
      </div>
      <!--
      <div class="info last" >
        <div class="name">Actualización</div>
        <div class="value">{{order.updated_at | date:'shortDate'}}</div>
      </div>
      -->
      <div class="subtitle">{{adminService.getText('client')}} #{{order.client_id}}</div>
      <div class="info" [class.last]="!roleService.can('read','client') | async">
        <div class="name">Nombre</div>
        <div class="value">{{order.client?.name || 'No existe el'+adminService.getText('client','lowercase')}}</div>
      </div>
      <ng-container *ngIf="roleService.can('read','client') | async">
      <div class="info">
        <div class="name">{{company.country.preferences.client_id}}</div>
        <div class="value">{{order.client?.rut}}</div>
      </div>
      <div class="info">
        <div class="name">Dirección</div>
        <div class="value">{{order.client?.address}}, {{order.client?.city}}</div>
      </div>

        <div class="info">
          <div class="name">E-mail</div>
          <div class="value">{{order.client?.email}}</div>
        </div>
        <div class="info last">
          <div class="name">Teléfono</div>
          <div class="value"><a href="tel:{{order.client?.phone}}" class="phone"><i class="fa fa-phone"></i>{{order.client?.phone}}</a></div>
        </div>
      </ng-container>
      <app-order-fields readonly="true" [model]="order.client" mode='Client' [wrap]=false></app-order-fields>
      <ng-container *ngIf="company.object === 'Vehicle'" style="margin-bottom: 15px;">
        <div class="subtitle">{{adminService.getText('object')}} #{{order.object_id}}</div>
        <div class="info">
          <div class="name">{{adminService.getText('object_identifier')}}</div>
          <div class="value">{{order.object?.identifier}}</div>
        </div>
        <div class="info">
          <div class="name">Marca</div>
          <div class="value">{{order.object?.brand?.name}}</div>
        </div>
        <div class="info">
          <div class="name">Modelo</div>
          <div class="value">{{order.object?.brand_model?.name}}</div>
        </div>
        <div class="info">
          <div class="name">Chasis</div>
          <div class="value">{{order.object?.chasis || '-'}}</div>
        </div>
        <div class="info" *ngIf="order.kms >= 0">
          <div class="name">Kilometraje</div>
          <div class="value">{{order.kms || order.object?.kms}}</div>
        </div>
        <div class="info" style="border-bottom: none;">
          <div class="name">Color</div>
          <div class="value">{{order.object?.color}}</div>
        </div>
        <app-order-fields readonly="true" [model]="order.object" mode='Vehicle' [wrap]=false></app-order-fields>
      </ng-container>


      <ng-container *ngIf="company.object === 'Custom'">
        <div class="subtitle">{{adminService.getText('object')}} #{{order.object_id}}</div>
        <div class="info">
          <div class="name">{{adminService.getText('object_identifier')}}</div>
          <div class="value">{{order.object?.identifier}}</div>
        </div>
        <app-order-fields readonly="true" [model]="order.object" mode='Custom' [wrap]=false></app-order-fields>
      </ng-container>


      <div class="subtitle">General</div>
      <div class="info">
        <div class="name">{{adminService.getText('requirement')}}</div>
        <div class="value">{{order.requirement?.name}}</div>
      </div>
      <div class="info">
        <div class="name">{{adminService.getText('comment')}}</div>
        <div class="value">{{order.comment}}</div>
      </div>
      <div class="info hide-print">
        <div class="name">Finalización</div>
        <div class="value" *ngIf="!order.finish_date && !order.completed_at">Sin fecha agendada</div>
        <div class="value" *ngIf="order.finish_date && !order.completed_at">Agendada al {{order.finish_date | date:'short'}}</div>
        <div class="value" *ngIf="order.completed_at">Finalizada el {{order.completed_at | date:'short'}}</div>
      </div>
      <div class="info last hide-print" *ngIf="order.sign_url">
        <div class="name">Firma</div>
        <div class="value"><img src="{{order.sign_url}}" style="max-height: 100px"/></div>
      </div>
      <app-order-fields readonly="true" [model]="order"></app-order-fields>
      @if (order.order_aditionals.length > 0) {
        <div class="subtitle">{{adminService.getText('aditional_plural')}}</div>
        <div class="aditionals-form">
          <app-additionals-form
            class="aditionals"
            [initialValue]="order.order_aditionals"
            [readonly]="true"
          >
          </app-additionals-form>
        </div>
      }

      @if(order.requirement) {
        <div class="subtitle">Detalle</div>
        <mat-accordion multi>
          <mat-expansion-panel class="expansion-panel">
            <mat-expansion-panel-header>
                <div class="panel-title">
                  <mat-icon>attach_money</mat-icon>
                  {{order.requirement.name}} &nbsp; <span>({{(order.inventory_action_for_consume?.total * 1.19 | money)}})</span>
                </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel-content>
              <div class="info" *ngFor="let item of order.inventory_action_for_consume?.inventory_action_products">
                <div class="quantity right">{{item.quantity || 1}}</div>
                <div class="value">{{item.code}} - {{item.description}} </div>
                <div class="total">{{item.amount*item.quantity | money }}</div>
              </div>
              <div class="info">
                <div class="quantity"></div>
                <div class="value highlight">Neto</div>
                <div class="total highlight" style="text-align: right">{{order.inventory_action_for_consume?.total | money }}</div>
              </div>
              <div class="info">
                <div class="quantity"></div>
                <div class="value highlight">{{adminService.getText('tax','uppercase','IVA')}}</div>
                <div class="total highlight" style="text-align: right">{{order.inventory_action_for_consume?.total * 0.19 | money }}</div>
              </div>
              <div class="info last">
                <div class="quantity"></div>
                <div class="value highlight">Total</div>
                <div class="total highlight" style="text-align: right">{{order.inventory_action_for_consume?.total * 1.19 | money }}</div>
              </div>
            </mat-expansion-panel-content>
          </mat-expansion-panel>

          @for(budget of order.budgets; track budget.correlative_id){
            <mat-expansion-panel class="expansion-panel">
              <mat-expansion-panel-header>
                  <div class="panel-title">
                    <mat-icon>attach_money</mat-icon>
                    {{adminService.getText('budget','capitalize')}} Nº {{budget.correlative_id}} &nbsp; <span>({{(budget.total * 1.19 | money)}})</span>
                  </div>
              </mat-expansion-panel-header>
              <mat-expansion-panel-content>



                @for(item of budgetItems(budget); track item.id) {
                  <div class="info">
                    <div class="quantity right">{{item.quantity || 1}}</div>
                    <div class="value">{{item.code}} - {{item.description}}</div>
                    <div class="total">{{item.amount * item.quantity | money }}</div>
                  </div>
                }
                <div class="info">
                  <div class="quantity"></div>
                  <div class="value highlight">Neto</div>
                  <div class="total highlight" style="text-align: right">{{budget.total | money }}</div>
                </div>
                <div class="info">
                  <div class="quantity"></div>
                  <div class="value highlight">{{adminService.getText('tax','uppercase','IVA')}}</div>
                  <div class="total highlight" style="text-align: right">{{budget.total * 0.19 | money }}</div>
                </div>
                <div class="info last">
                  <div class="quantity"></div>
                  <div class="value highlight">Total</div>
                  <div class="total highlight" style="text-align: right">{{budget.total * 1.19 | money }}</div>
                </div>
                <div class="budget-buttons">
                  <button mat-button class="primary" (click)="openBudget(budget.id)">Ver  {{adminService.getText('budget','capitalize')}}</button>
                </div>

              </mat-expansion-panel-content>
            </mat-expansion-panel>
          }

          <mat-expansion-panel disabled>
            <mat-expansion-panel-header>
                <div class="panel-title">
                  <mat-icon>attach_money</mat-icon>
                 Total: {{getOrderTotal() | money }}
                </div>
            </mat-expansion-panel-header>

          </mat-expansion-panel>
        </mat-accordion>
      }
      <br>

      <mat-accordion multi >
        @if ((order.order_attachments && order.order_attachments.length > 0) || (order.pictures && order.pictures.length > 0)) {
          <mat-expansion-panel class="expansion-panel">
            <mat-expansion-panel-header>
                <div class="panel-title">
                  <mat-icon>photo_camera</mat-icon>
                  Imagenes&nbsp;<span>({{(order.order_attachments?.length || 0) + (order.pictures?.length || 0)}})</span>
                </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel-content>
              <div class="subtitle subtitle-images" *ngIf="order.order_attachments && order.order_attachments.length > 0">Imagenes Pre-trabajo</div>
              <app-image-uploader class="hide-print" [photos]="order.order_attachments" [readonly]="true" [scrollTarget]="wrapper" *ngIf="order.order_attachments && order.order_attachments.length > 0"></app-image-uploader>
              <div class="subtitle subtitle-images" *ngIf="order.pictures && order.pictures.length > 0">Imagenes de Trabajos</div>
              <app-image-uploader [photos]="order.pictures" [readonly]="true" [scrollTarget]="wrapper" *ngIf="order.pictures && order.pictures.length > 0"></app-image-uploader>
            </mat-expansion-panel-content>
          </mat-expansion-panel>
        }

        <mat-expansion-panel class="hide-print order-expansion-panel-works expansion-panel">
          <mat-expansion-panel-header>
              <div class="panel-title">
                <mat-icon>build_circle</mat-icon>
                Trabajos&nbsp;<span>({{order.works?.length || 0}})</span>
              </div>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <app-order-works [order]="order" class="hide-print" (load)="load()"></app-order-works>
          </mat-expansion-panel-content>
        </mat-expansion-panel>

        <mat-expansion-panel class="hide-print expansion-panel">
          <mat-expansion-panel-header>
              <div class="panel-title">
                <mat-icon>forum</mat-icon>
                Bitácora&nbsp;<span>({{order.order_comments?.length}})</span>
              </div>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <app-order-comments [order]="order" [comments]="order.order_comments" class="hide-print"  *ngIf="(roleService.can('read','order_comment') | async)" (load)="openLastWork()"></app-order-comments>
          </mat-expansion-panel-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="legend only-print">
      {{company.preferences.order_legend}}
      <ng-container *ngIf="!company.preferences.order_legend">
        Autorizo todas las reparaciones aquí detalladas, el uso, traslado e instalación de piezas que se requiera para ello.

        {{company.name}} queda autorizado para efectuar las pruebas que sean necesarias fuera del servicio, tanto en calles como carreteras.

        {{company.name}} realizará reparaciones de acuerdo a lo autorizado por la compañía de seguros. Toda reparación no efectuada deberá atenderse de forma externa entre el asegurado y respectivo liquidador.{{company.name}} no se responsabiliza por objetos no declarados al momento de la recepción del vehículo.

        {{company.name}} por todo vehículo que no sea retirado dentro de 48 horas de finalizada la reparación, cobrará el respectivo almacenaje.
      </ng-container>
    </div>
    <div class="signature">
      <div class="placeholder" [style.background-image]="sanitizeBackground(order.sign_url)">
        <div class="sign"></div>
        <span>Firma Cliente</span>
      </div>
    </div>
  </div>
  <div class="no-access" *ngIf="forbidden">
    <i class="fa fa-warning"></i>
    No tienes acceso a esta información
  </div>
</div>


<mat-toolbar *ngIf="order && !editMode" class="footer">
  <div class="buttons">
    <button mat-raised-button (click)="addWork()" class="primary">
      <span><mat-icon>add</mat-icon></span>
    </button>
    <!--
    <button mat-raised-button class="secondary hide-mobile" (click)="print()" style="margin-left: 15px" ><i class="fa fa-eye"></i></button>
    -->
    <div class="expander"></div>
    <button mat-raised-button class="primary" [matMenuTriggerFor]="menu"  *ngIf="(roleService.can('update','check_in') | async)"><i class="fa fa-pencil-alt"></i><span class="hide-mobile">{{order.status_id | status}}</span></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let status of statuses" (click)="setStatus(status.id)" [style.color]="status.color">{{status.name}}</button>

    </mat-menu>
  </div>
</mat-toolbar>
