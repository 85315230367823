<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Traducciones</mat-card-title>
    <mat-card-subtitle>Edite cada texto de la aplicación</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="companyForm">
      <fieldset formGroupName="preferences" #group>
        <fieldset formGroupName="translations">
          <ng-container *ngFor="let control of getTrans().controls | keyvalue">
            <div class="row">
              <mat-form-field appearance="outline" *ngIf="control.key.includes('_plural') == false">
                <mat-label>{{control.key}}</mat-label>
                <input matInput placeholder="{{control.key}}" formControlName="{{control.key}}">
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="getTrans().controls[control.key+'_plural']">
                <mat-label>{{control.key}}_plural</mat-label>
                <input matInput placeholder="{{control.key}}_plural" formControlName="{{control.key}}_plural">
              </mat-form-field>
            </div>
          </ng-container>
        </fieldset>
      </fieldset>
    </form>
  </mat-card-content>
</mat-card>
