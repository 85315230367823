import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from "../../services/analytics.service";
import {ActivatedRoute} from "@angular/router";
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-client-analytic',
  templateUrl: './client-analytic.component.html',
  styleUrls: ['./client-analytic.component.scss']
})
export class ClientAnalyticComponent implements OnInit {
  loading = false;
  snapshot;
  constructor(
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.subscribe(value => {
      if(value.snapshot){
        this.snapshot = value.snapshot;
        this.loading = false;
      }else{
        console.log("Route");
      }
    });
    this.zendesk.hide();
  }

}
