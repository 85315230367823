import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';
import { CameraService } from "../../shared/image-uploader/camera/camera.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-upload-pfp-dialog',
  templateUrl: './upload-pfp-dialog.component.html',
  styleUrls: ['./upload-pfp-dialog.component.scss']
})
export class UploadPfpDialogComponent {
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl | null = null;
  blobImage: Blob | null = null;
  errorMessage: string = '';
  loading = false;
  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<UploadPfpDialogComponent>,
    private sanitizer: DomSanitizer,
    private cameraService: CameraService
  ) {}

  ngOnInit(): void {
    this.triggerFileInput(); 
  }

  triggerFileInput(): void {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = null; 
    this.croppedImage = null;       
    this.blobImage = null;          
    this.errorMessage = '';        
  
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
  
    const validFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/ico'];
    if (file && !validFormats.includes(file.type)) {
      this.errorMessage = 'Formato no aceptado';
      return;
    }
  
    this.imageChangedEvent = event;  // Solo asignar el evento si no hay errores de formato
  }
  
  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    this.blobImage = event.blob; // Guardar el blob para subirlo
  }

  imageLoaded(image: LoadedImage): void {
    // Validar tamaño mínimo de la imagen
    if (image.original.size.width < 100 || image.original.size.height < 100) {
      this.errorMessage = 'La foto debe de tener al menos 100px * 100px';
      this.imageChangedEvent = null; 
      this.croppedImage = null;  
      this.blobImage = null;     
    } else {
      this.errorMessage = '';
    }
  }

  loadImageFailed(): void {
    this.errorMessage = 'Error al cargar la imagen';
  }


  onCancel(): void {
    this.dialogRef.close();
  }


  onUpload(): void {
    if (this.blobImage) {
      if (this.blobImage.size > 2e+7) {
        this.errorMessage = 'El tamaño del recorte es muy pesado';  
        return; 
      }
      const uploadTask = this.cameraService.saveBlob(this.blobImage, 'png');
      
      this.loading = true;
      uploadTask.on('state_changed', 
        (snapshot: any) => {
        }, 
        (error) => {
          this.errorMessage = 'Error al subir la imagen'; 
        }, 
        () => {
         
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
            this.errorMessage = '';  
            
            this.userService.update({picture: downloadURL}).subscribe(
              () => {
                this.dialogRef.close(downloadURL); 
              },
              (error) => {
                this.errorMessage = 'Error al actualizar la imagen de perfil'; 
              }
            );
          }).catch((error: any) => {
            this.errorMessage = 'Error al obtener la URL de la imagen';
          });
        }
      );
    }
  }  
  
}
