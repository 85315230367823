import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal, ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckIn } from 'app/models/checkin.model';
import { calculateInventoryActionTotal } from 'app/models/inventory_action.model';
import { Location } from 'app/models/location.model';
import { User } from 'app/models/user';
import { AdditionalsService } from 'app/services/additionals.service';
import { KitsService } from 'app/services/kits.service';
import { InventoryActionFormComponent }
  from 'app/shared/common-forms/inventory-action-form/inventory-action-form.component';
import { formatIdentifier } from 'app/utils/client-utils';
import moment, { Moment } from 'moment';
import { merge, of, Subscription } from 'rxjs';
import { catchError, debounceTime, first } from 'rxjs/operators';

import { AdminService } from '../../admin/services/admin.service';
import { RoleService } from '../../role/role.service';
import { BookingService } from '../../services/booking.service';
import { CheckInService } from '../../services/check-in.service';
import { HolydaysService } from '../../services/holydays.service';
import { RequirementsService } from '../../services/requirements.service';
import { UserService } from '../../services/user.service';
import {
  addAdditional, removeAdditional, retrieveKits, setupInventoryActionForConsumeForm, setupOrderAdditionals,
} from '../booking/booking.component';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent implements OnInit, OnDestroy {
  @Input() data;

  @Input() wrapper;

  @Output() saved = new EventEmitter();

  additionals = [];

  addAdditional = addAdditional;

  checkin: Partial<CheckIn> = { additionals: {}, order_attachments: [] };

  company = this.adminService.local$.getValue();

  currentDate = moment().toDate();

  errortext = '';

  readonly panelOpenState = signal(false);

  totalValue: number = 0;

  form: FormGroup = this.fb.group({
    id: null,
    client: this.fb.group({
      id: null,
      address: '',
      city: '',
      correlative_id: null,
      custom_fields: this.fb.group({}),
      email: '',
      name: '',
      phone: '',
      rut: this.fb.control(null, [Validators.required]),
    }),
    checked_in: true,
    comment: '',
    custom_fields: this.fb.group({}),
    finish_date: this.fb.control(null),
    inventory_action_for_consume: this.fb.group({
      id: null,
      estimated_completion_date: this.fb.control(new Date()),
      inventory_action_products: this.fb.array([]),
    }),
    location_id: [null],
    object: this.fb.group({
      id: null,
      brand_id: this.fb.control(null, [Validators.required]),
      brand_model_id: this.fb.control(null, [Validators.required]),
      chasis: '',
      client_id: '',
      color: '',
      custom_fields: this.fb.group({}),
      identifier: '',
    }),
    order_attachments: this.fb.control([]),
    order_aditionals: this.fb.array([]),
    requirement_id: new FormControl(null, [Validators.required]),
    user_id: this.fb.control({ value: [], disabled: true }),
    sign_url: '',
    kms: '',
  });

  locations: Location[];

  onlyWeek: (d: Moment) => boolean;

  progress: number;

  removeAdditional = removeAdditional;

  showSign = false;

  private subs: Subscription[] = [];

  suggested = 0;

  types = [];

  uploading = false;

  uploadingFiles = [];

  users: User[] = [];

  working = false;

  @ViewChild('inventoryForm') inventoryForm : InventoryActionFormComponent;

  constructor(
    private snackBar: MatSnackBar,
    private bookingService: BookingService,
    private checkInService: CheckInService,
    private router: Router,
    private route: ActivatedRoute,
    private requirements: RequirementsService,
    private userService: UserService,
    public roleService: RoleService,
    public adminService: AdminService,
    private holydayService: HolydaysService,
    private additionalService: AdditionalsService,
    private kitsService: KitsService,
    private fb: FormBuilder,
  ) { }

  get inventory_action_for_consume() {
    return this.form.controls.inventory_action_for_consume as FormGroup;
  }

  set inventory_action_for_consume(value) {
    this.form.controls.inventory_action_for_consume = value;
  }

  get inventory_action_products() {
    return this.inventory_action_for_consume.controls.inventory_action_products as FormArray;
  }

  set inventory_action_products(value) {
    this.inventory_action_for_consume.controls.inventory_action_products = value;
  }

  get order_aditionals() {
    return this.form.controls.order_aditionals as FormArray;
  }

  set order_aditionals(value) {
    this.form.controls.order_aditionals = value;
  }

  get order_attachments() {
    return this.checkin.order_attachments;
  }

  set order_attachments(value) {
    this.form.controls.order_attachments.setValue(value);
  }

  get requirement_id() {
    return this.form.get('requirement_id');
  }

  get sign_url() {
    return this.form.get('sign_url').value;
  }

  set sign_url(value) {
    this.form.get('sign_url').setValue(value);
  }

  get estimated_completion_date() {
    return this.inventory_action_for_consume.controls.estimated_completion_date;
  }

  set estimated_completion_date(value) {
    this.estimated_completion_date.setValue(value);
  }

  ngOnInit() {
    this.adminService.pageTitle.set(this.adminService.getText('checkin', 'capitalize'));
    this.holydayService.filterFunction(true).then((value) => {
      this.onlyWeek = value;
    });
    this.subs.push(this.requirements.requirements$.subscribe((requirements) => {
      this.types = requirements;
    }));
    this.subs.push(this.additionalService.additionals$.subscribe((additionals) => {
      this.additionals = additionals;
    }));
    this.route.queryParams.pipe(first()).forEach((params) => {
      if (params.booking_id) {
        this.bookingService.get(params.booking_id)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              return of(null);
            }),
          )
          .subscribe((value) => {
            if (!value) return;

            this.checkin = value.body;
            this.form.patchValue(this.checkin, { emitEvent: false });
            if (this.company.object === 'Vehicle') {
              this.form.get('object').get('brand_id').setValue(this.checkin.object.brand_id);
            }

            if (this.checkin.inventory_action_for_consume) {
              setupInventoryActionForConsumeForm(this, this.checkin);
              this.totalValue = calculateInventoryActionTotal(this.checkin.inventory_action_for_consume);
            }
            setupOrderAdditionals(this, this.checkin);
            this.loadLocationConfig(this.checkin.location_id);
          });
      }
    });

    if (this.data) {
      this.checkin = this.data;
      this.form.patchValue(this.checkin, { emitEvent: false });
      if (this.company.object === 'Vehicle') {
        setTimeout(() => {
          this.form.get('object').get('brand_id').setValue(this.checkin.object.brand_id);
        }, 1000);
      }
      if (this.checkin.inventory_action_for_consume) {
        setupInventoryActionForConsumeForm(this, this.checkin);
        this.totalValue = calculateInventoryActionTotal(this.checkin.inventory_action_for_consume);
      }
      setupOrderAdditionals(this, this.checkin);
    }

    this.setupLocation();

    if (this.company.object !== 'Vehicle') {
      (<FormGroup> this.form.controls.object).removeControl('brand_id');
      (<FormGroup> this.form.controls.object).removeControl('brand_model_id');
    }

    this.setFormChangeHandlers();
    this.form.markAllAsTouched();
  }

  setFormChangeHandlers() {
    this.form.controls.location_id.valueChanges.subscribe((id) => {
      this.loadLocationConfig(id);
    });

    this.form.controls.requirement_id.valueChanges.subscribe(() => {
      if (!this.checkin.inventory_action_for_consume) {
        retrieveKits(this, this.checkin);
      }
    });

    merge(
      this.form.controls.object.valueChanges,
      this.form.controls.custom_fields.valueChanges,
    ).pipe(
      debounceTime(500),
    ).subscribe(() => {
      if (!this.checkin.inventory_action_for_consume) {
        retrieveKits(this, this.checkin);
      }
    });

    this.form.controls.finish_date.valueChanges.subscribe((date) => {
      this.estimated_completion_date = date;
    });
  }

  setupLocation() {
    this.locations = this.adminService.getLocations();
    if (this.locations && this.locations.length === 1) {
      this.checkin.location_id = this.locations[0].id;
      this.form.get('location_id').setValue(this.locations[0].id);
    }
    if (this.checkin.location_id) {
      this.loadLocationConfig(this.checkin.location_id);
    }
  }

  loadLocationConfig(id: number) {
    if (!id) {
      this.holydayService.filterFunction(true).then((value) => {
        this.onlyWeek = value;
      });
      return;
    }

    this.holydayService.filterFunction(true, this.locations.find((l) => l.id === id).preferences).then((value) => {
      this.onlyWeek = value;
    });

    if (this.company.preferences.booking_type === 'professional') {
      this.userService.professionals(id).subscribe((response) => {
        this.users = response.body;
      });
    }

    this.requirements.requirements$.subscribe((response) => {
      this.types = response.filter((req) => req.location_ids.includes(id));
    });
  }

  objectSelected(object) {
    const { identifier, brand_model_id: brandModelId, ...rest } = object;
    this.form.controls.object.patchValue(rest);
    (<FormGroup> this.form.controls.object).controls.identifier.patchValue(identifier, { emitEvent: false });

    if (this.company.object === 'Vehicle') {
      setTimeout(() => {
        (<FormGroup> this.form.controls.object).controls.brand_model_id.setValue(brandModelId);
      }, 1000);
    }
  }

  saveForm() {
    this.working = true;
    this.order_attachments = this.checkin.order_attachments;
    if (this.checkin.id) {
      this.form.controls.checked_in.setValue(true);
      this.checkInService
        .update(this.checkin.id, { check_in: this.form.getRawValue() })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.error(error);
            return of(null);
          }),
        )
        .subscribe((success) => {
          if (!success) return;

          this.success();
          if (this.checkin.checked_in) {
            this.saved.emit();
          } else {
            this.router.navigate(['/main', 'orders', success.body.id]);
          }
        });
    } else {
      this.checkInService
        .create({ check_in: this.form.getRawValue() })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.error(error);
            if (error.status === 422) {
              this.errortext = `El/la ${this.adminService.getText('object')} ya esta ingresado.`;
            }
            return of(null);
          }),
        )
        .subscribe((response: HttpResponse<CheckIn>) => {
          if (!response) return;

          this.success();
          this.router.navigate(['/main', 'orders', response.body.id]);
        });
    }
  }

  success() {
    this.working = false;
    this.snackBar.open('¡Guardado exitosamente!', null, { duration: 1000 });
  }

  sign() {
    this.showSign = true;
  }

  setSign(event) {
    this.showSign = false;
    if (event) {
      this.checkin.sign_url = event;
      this.sign_url = event;
    }
  }

  error(error: HttpErrorResponse) {
    this.working = false;
    if (error.error) {
      if (error.error.date) {
        this.form.controls.hour.setErrors({ invalid: true });
        this.snackBar.open('¡OOPS!, la fecha ya esta registrada.', null, { duration: 2000 });
      } else if (error.error.vehicle_id) {
        this.form.get('object').get('identifier').setErrors({ invalid: true });
        this.snackBar.open('¡OOPS!, este vehículo ya esta ingresado.', null, { duration: 2000 });
      } else if (error.error.kms) {
        this.form.controls.kms.setErrors({ invalid: true });
        this.snackBar.open(
          `¡OOPS!, El kilometraje debe ser mayor o igual al del último ingreso de este vehículo (${error.error.kms}).'`,
          null,
          { duration: 2000 },
        );
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  onTotalValueChange(newTotal: number) {
    this.totalValue = newTotal;
  }
}
