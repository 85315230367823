import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {interval, Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {StatusesService} from "../services/statuses.service";
import {debounceTime, first} from "rxjs/operators";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {uniq} from "lodash-es";
import {FilterComponent} from "../main/filter/filter.component";
import {PaymentsService} from "./payments.service";
import {PaymentComponent} from "./payment/payment.component";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  statuses: any[] = [{
    name: 'Pagado',
    id: 2,
    color: 'green'
  },{
    name: 'Pendiente',
    id: 1,
    color: '#d1c028'
  }];
  payments: any;
  searchControl: UntypedFormControl = new UntypedFormControl();
  filter: any  = this.getFilter();
  page = 1;
  total_pages = 1;
  filters: any = [{
    name: 'Estado',
    key: 'status_id',
    options: [],
    multi: true
  }
  ];
  subs: Subscription[] = [];
  public working = false;
  private search: any;
  count = 0;
  private loadingMore = false;
  constructor(
    private paymentService: PaymentsService,
    private matDialog: MatDialog,
    private statusesService: StatusesService
  ) { }

  ngOnInit(search?) {
    this.ngOnDestroy();
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if(this.statuses.length > 0){
        this.search = value;
        this.getPayments();
      }
    }));
    this.getPayments()
    this.getMore();

  }
  statusOn(id) {
    if(this.filter){
      const index = this.filter.findIndex((s) => parseInt(s,0) === id);
      return index >= 0;
    }else{
      return false;
    }
  }
  getPayments(loadingMore?) {
    // SCROLL TOP
    if(!loadingMore){
      this.resetPayment();
      this.working = true;
    }
    this.paymentService.all({status_id: this.filter, page: this.page}, this.search)
      .toPromise().then((response:HttpResponse<any>) => {
      if(loadingMore){
        this.payments = this.payments.concat(response.body.payments);
      }else{
        this.payments = response.body.payments;
      }
      this.count = response.body.total;
      this.page = response.body.page;
      this.total_pages = response.body.total_pages;
      this.working = false;
      this.loadingMore = false;
    }).catch((error:HttpErrorResponse) => {
      console.log(error);
      this.working = false;
    });
  }
  resetPayment(){
    const element = document.getElementById('payments');
    if(element){
      element.scrollTop = 0;
    }
    this.page = 1;
    this.total_pages = 1;
    this.count = 0;
  }
  getMore(){
    interval(500).subscribe(()=>{
      if(this.page < this.total_pages && !this.loadingMore){
        const element = document.getElementById('payments');
        if(element){
          const loaderElement = document.getElementById('load-more');
          if(element.scrollTop+element.clientHeight > element.scrollHeight - loaderElement.clientHeight){
            this.loadingMore = true;
            this.page++;
            this.getPayments(true);
          }
        }
      }
    });
  }
  getFilter(){
    const value = localStorage.getItem('payments_filter');
    let filter = null;
    if(value){
      filter = uniq(value.split(',')).map((v)=>parseInt(v,0));
    }else{
      filter = [1,2]
    }
    return filter;
  }
  saveFilter(){
    this.filter.push(0);
    this.filter = uniq(this.filter);
    localStorage.setItem('payments_filter',this.filter);
  }
  toggleFilter(id: number) {
    const index = this.filter.findIndex((s) => s === id);
    if (index >= 0) {
      this.filter.splice(index, 1);
    }else {
      this.filter.push(id);
    }
    this.saveFilter();
    this.getPayments();
  }
  openPayment(payment){
    this.matDialog.open(PaymentComponent, {data: payment, minWidth: '80%'}).afterClosed().pipe(first()).forEach(value => {
      this.getPayments();
    })
  }
  openFilters() {
    // Setup Options
    this.filters[0].options = this.statuses.map((s) => [s.id, s.name, this.statusOn(s.id)]);
    this.matDialog.open(FilterComponent, {data: this.filters}).afterClosed().pipe(first()).forEach((result) => {
      if(result){
        this.filter = result.status_id;
        this.saveFilter();
        this.getPayments();
      }
    });
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
