<ng-container [formGroup]="parentFormGroup">
  <div class="row">
    <div class="col" *ngIf="!hideOptionals || !identifierOptional">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>{{companyService.getText('object_identifier')}}</mat-label>
        <input
          matInput
          #identifierInput
          [required]="!identifierOptional"
          [minlength]="country.preferences.vehicle_id_min"
          [maxlength]="country.preferences.vehicle_id_max"
          formControlName="identifier"
          autocomplete="off"
          [matAutocomplete]="autoPlate">
      </mat-form-field>
      <mat-autocomplete #autoPlate="matAutocomplete">
        <mat-option *ngFor="let option of plates" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="col" *ngIf="!hideOptionals">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <input matInput formControlName="chasis" autocomplete="off" minlength="17" maxlength="17">
        <mat-label>Nº Chasis</mat-label>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>Marca</mat-label>
        <input matInput formControlName="brand_id" [matAutocomplete]="autoBrand" required>
        <mat-autocomplete #autoBrand="matAutocomplete" [displayWith]="displayBrand">
          <mat-option *ngFor="let brand of filteredBrands" [value]="brand.id">
            {{brand.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <mat-label>Modelo</mat-label>
        <input matInput formControlName="brand_model_id" [matAutocomplete]="autoModel" required>
      </mat-form-field>
      <mat-autocomplete #autoModel="matAutocomplete" [displayWith]="displayModel">
        <mat-option *ngFor="let brand_model of filteredModels" [value]="brand_model.id">
          {{brand_model.name}}
        </mat-option>
      </mat-autocomplete>
    </div>

  </div>
  <div class="row">
    <div class="col" *ngIf="!hideOptionals">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <input matInput [required]="!optionalFields && formRequired.color" formControlName="color" autocomplete="off">
        <mat-label>Color</mat-label>
      </mat-form-field>
    </div>
    <div class="col" *ngIf="!hideOptionals && !hideKms">
      <mat-form-field [appearance]="outlinemode ? 'outline' : ''" [ngClass]="{'white-backgrounded-field': outlinemode}">
        <input matInput type="text" [required]="!optionalFields" [formControl]="parentControl.get('kms')" autocomplete="off" (keypress)="validateNumberInput($event)" (keydown)="handleArrowKeys($event)">
        <mat-label>Kilometraje</mat-label>
      </mat-form-field>
    </div>
  </div>
  <app-order-fields mode='Vehicle' [model]="model" [wrap]="false" [parentFormGroup]="parentFormGroup" [reactiveFormMode]="true"  [outlinemode]="outlinemode"></app-order-fields>
</ng-container>
