import {debounceTime, first} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild, ElementRef, HostBinding, HostListener} from '@angular/core';
import moment from 'moment';
import {BookingService} from "../../services/booking.service";
import {MatDialog} from "@angular/material/dialog";
import {BookingComponent} from "../booking/booking.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {RoleService} from "../../role/role.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {CalendarEvent} from "../../models/types";
import {interval, Subscription} from "rxjs";
import {CalendarMonthViewDay} from 'angular-calendar';
import {AdminService} from "../../admin/services/admin.service";
import {CheckInService} from "../../services/check-in.service";
import {UntypedFormControl} from "@angular/forms";
import {range} from "lodash-es";
import { UserService } from 'app/services/user.service';
import { ZendeskService } from 'app/services/zendesk.service';
import { ObjectNamePipe } from 'app/shared/pipes/object.pipe';
import { ListsService } from 'app/admin/services/lists.service';
import { DercoMailsDialogComponent } from '../derco-mails/derco-mails-dialog/derco-mails-dialog.component';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit, OnDestroy {
  month = moment().toDate();
  events: any[] = [];
  monthDays: number[];
  booking: any = {
    days: []
  };
  mode = 'month';
  BOOKING_TYPE = 'capacity';
  NUMBER_OF_DAYS = 1;
  MAX_BOOKINGS = 3;
  BOOKING_INTERVAL = 10;
  professionals = [];
  findings = [];
  searchControl: UntypedFormControl = new UntypedFormControl();
  isSearchModalOpen = false;
  bookingDialogOpen = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchButton') searchButton!: ElementRef;
  @ViewChild('modalContent') modalContent!: ElementRef;
  now = moment();
  currentDate = moment().format('YYYY-MM-DD');
  dates = [];
  users = [];
  dateNames = [];
  excludeDays = [];
  companySub = null;
  company = null;
  userSub = null;
  subs = [];
  formattedBookings = {};
  formattedBlocks = {};
  private dataInterval: Subscription;
  professionalList: any[];
  professionalIndex: number = 0;
  professionalStep: number = 4;
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if(this.booking.holydays){
        const dateStr = day.date.toISOString().split('T')[0];
        if(this.isHoliday(day.date)){
          day.cssClass = 'holiday';
        }
        else if (this.blocked(dateStr, 'all', 'all') && moment(dateStr).isSameOrAfter(this.currentDate)) {
          day.cssClass = 'blocked-day';
        }
        else{
          day.cssClass = '';
        }
      }
    });
  }
  constructor(
    private bookingService: BookingService,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer,
    private listService: ListsService,
    private router: Router,
    private route: ActivatedRoute,
    public appService: AdminService,
    private userService: UserService,
    private orderService: CheckInService,
    public roleService: RoleService,
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.appService.pageTitle.set(this.appService.getText('booking_plural'));

    this.zendesk.hide();
    this.route.queryParams.forEach((params) => {
      if(this.companySub) this.companySub.unsubscribe();
      if(this.userSub) this.userSub.unsubscribe();
      this.companySub = this.appService.local$.subscribe((value)=>{
        if(this.userSub) this.userSub.unsubscribe();
        if (params.date) {
          this.now = moment(params.date);
          this.month = this.now.toDate();
          this.mode = 'day';
          this.zendesk.hide()
        }else {
          this.mode = 'month';
        }
        this.events = []
        this.company = value;
        this.BOOKING_TYPE = this.appService.local$.getValue().preferences.booking_type || this.BOOKING_TYPE;
        let role_id = this.appService.local$.getValue().preferences.booking_role_ids || this.appService.local$.getValue().preferences.booking_role_id
        this.MAX_BOOKINGS = this.appService.local$.getValue().days_preferences.max_bookings || this.MAX_BOOKINGS;
        this.BOOKING_INTERVAL = this.appService.local$.getValue().days_preferences.time_interval || this.BOOKING_INTERVAL;
        this.userSub = this.userService.users$.subscribe((users)=>{
          this.users = users;
        });
        if(this.BOOKING_TYPE == 'professional' && role_id){
          this.userService.professionals().toPromise().then((response:any) => {
            this.professionals = response.body;
            // check role
            this.roleService.onlyOwn('read','booking').pipe(first()).forEach((value)=>{
              if(value){
                this.professionals = this.professionals.filter(p => p.id == this.userService.user$.getValue().id);
              }
              this.stepProfessionals();
            }
            );
          }) 
        }
        this.setWeekends();
        this.getData();
        this.setDates();
      })
    });
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((identifier) => {
      this.highlightBookings(identifier);
    }));
  }
  ngOnDestroy() {
    this.companySub.unsubscribe();
    this.subs.forEach(s => s.unsubscribe());
    
  }
  showOnly(user){
    if(this.professionalList.length != 1){
      this.professionalList = this.professionals.filter(p => p.id == user.id);
    }else{
      this.stepProfessionals();
    }
  }
  externalBooking(){
    this.matDialog.open(DercoMailsDialogComponent, {
      width: '80%',
    })
  }
  nextProfessionals(event: Event) {
    event.preventDefault();
    if (this.professionalIndex + this.professionalStep < this.professionals.length) {
      this.professionalIndex += this.professionalStep;
      this.professionalList = this.professionals.slice(this.professionalIndex, this.professionalIndex + this.professionalStep);
    } else {
      this.professionalIndex = 0;
      this.professionalList = this.professionals.slice(0, this.professionalStep);
    }
  }
  previousProfessionals(event: Event){
    event.preventDefault();
    if(this.professionalIndex - this.professionalStep >= 0){
      this.professionalIndex -= this.professionalStep;
      this.professionalList = this.professionals.slice(this.professionalIndex,this.professionalIndex+this.professionalStep);
    }
    else{
      this.professionalIndex = Math.floor(this.professionals.length/this.professionalStep)*this.professionalStep;
      this.professionalList = this.professionals.slice(this.professionalIndex,this.professionalIndex+this.professionalStep);
    }
  }
  stepProfessionals(){
    if(this.professionals.length > this.professionalStep){
      this.professionalList = this.professionals.slice(this.professionalIndex,this.professionalIndex+this.professionalStep);
    }else{
      this.professionalList = this.professionals;
    }
  }
  setDates() {
    if (this.mode === 'month') {
      this.dateNames = [this.now.format('YYYY-MM-DD')];
      this.dates = [this.now.format('YYYY-MM-DD')];
      this.monthDays = range(1, this.now.daysInMonth() + 1);
      this.month = this.now.toDate();
    }else {
      this.dateNames = [this.now.format('DD/MM/YYYY')];
      this.dates = [this.now.format('YYYY-MM-DD')];
      for (let i = 1; i < this.NUMBER_OF_DAYS; i++) {
        const date = moment(this.dateNames[0]).add(i, 'day');
        this.dates.push(date.format('YYYY-MM-DD'));
        this.dateNames.push(date);
      }
    }

  }
  getData() {
    if(this.dataInterval){
      this.dataInterval.unsubscribe();
    }
    this.dataInterval = interval(200).subscribe(()=>{
      this.dataInterval.unsubscribe();
      let start_at, end_at;
      start_at = moment(this.now).startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
      end_at = moment(this.now).endOf('month').add(7, 'days').format('YYYY-MM-DD');
      this.bookingService.all(start_at, end_at).toPromise().then((values) => {
        this.booking = values.body;
        this.events = this.toEvents();
        this.formatBookings(values.body);
      }).catch((error:HttpErrorResponse) => {
        console.log(error);
      });
    });

  }
  getBookings(date, hour, index?) {
    if (this.booking.bookings[date]) {
      return this.booking.bookings[date].filter((b) => 
      {
        const hours = this.booking.days[this.getDayNumber()]
        if(index == hours.length - 1){
          return b.booking_time >= hours[index]
        }else if(index === 0){
          return b.booking_time < hours[index+1]
        }
        else{
          return b.booking_time >= hours[index] && b.booking_time < hours[index+1]
        }
      }
    );
    }else {
      return [];
    }
  }
  formatBookings(bookings){
    this.formattedBookings = {};
    this.formattedBlocks = {};
    let b = bookings.bookings
    let hours = this.booking.days[this.getDayNumber()];
    this.professionals.map(p => p.id).forEach(p => {
      if(!this.formattedBookings[p]){
        this.formattedBookings[p] = {}
      }
      Object.keys(b).forEach(k=>{
        if(!this.formattedBookings[p][k]){
          this.formattedBookings[p][k] = {}
        }
        b[k].filter(bk => bk.user_id === p).forEach(bk => {
          const i = hours.findIndex(h => bk.booking_time < h) - 1;
          const periods = Math.ceil(bk.duration/this.BOOKING_INTERVAL)
          if(!this.formattedBookings[p][k][hours[i]]){
            this.formattedBookings[p][k][hours[i]] = bk;
          }
          for(let j=1; j<periods;j++){
            this.formattedBookings[p][k][hours[i+j]] = bk.id;
          };
        });
      })
    })
    let bls = bookings.blocks;
    bls.forEach(bl => {
      if(!this.formattedBlocks[bl.date]) this.formattedBlocks[bl.date] = {};
      bls.filter(bl2 => bl2.date == bl.date).forEach(bl2 => {
        if(!this.formattedBlocks[bl.date][bl2.hour]) this.formattedBlocks[bl.date][bl2.hour] = {}
        bls.filter(bl3 => bl3.date == bl.date && bl3.hour == bl2.hour).forEach(bl3 => {
          this.formattedBlocks[bl.date][bl2.hour][bl3.user_id || 'all'] = bl3.active;
        })
      })
    })
  }
  getBooking(date,id){
    const index = this.booking.bookings[date].findIndex(b => b.id == id);
    if(index >= 0){
      return this.booking.bookings[date][index]
    }else{
      return {}
    }
  }
  getBookingIcon(booking) {
    if (booking && this.company) {
      if (booking.identifier) {
        if(this.company.object == 'Vehicle'){
          return 'fa fa-car icon';
        }else{
          return 'fa fa-cubes icon';
        }
      } else {
        return 'fa fa-user icon';
      }
    } else {
      return null;
    }
  }
  openBooking(date, hour, booking?,user_id?) {
    if ((date >= this.currentDate && !this.isHoliday(date,hour,user_id) && !this.blocked(date,hour,user_id)) || booking) {
      this.bookingDialogOpen = true;
      this.matDialog.open(BookingComponent, {
        data: booking || {booking_time: hour, booking_date: date, user_id: user_id},
        width: '80%'
      }).afterClosed().pipe(first()).forEach((value) => {
        this.bookingDialogOpen = false;
        this.getData();
      });
    }
  }
  isHoliday(date,hour='all',user_id='all'){
    if(this.booking.holydays){
      const fdate = moment(date).format('YYYY-MM-DD');
      const index = this.booking.holydays.findIndex((v)=>v.date === fdate);
      return index >= 0 || this.isUserBlocked(date,hour,user_id);
    }else{
      return false;
    }
  }
  isUserBlocked(date,hour,user_id){
    if(user_id=='all'){
      return false
    }else{
      const day = moment(date).isoWeekday();
      
      const index = this.professionals.findIndex(u => u.id === user_id)
      
      if(index >= 0){
        const d = this.professionals[index].block_hours.find(bh => bh.day === day)
        if(d && d.enabled){
          const h = parseInt(hour.replace(":",""));
          const s_t = parseInt(d.start_time.replace(":",""));
          const e_t = parseInt(d.end_time.replace(":",""));
          return h >= s_t && h<e_t 
        }else{
          return false;
        }
      }else{
        return false;
      }
    }
  }
  openDay(date) {
    this.mode = 'day';
    this.now = moment(date.day.date.toISOString());
    this.setDates();
    this.getData();
    this.zendesk.hide();
    this.router.navigate(['./'], {relativeTo: this.route, queryParams: {date: this.now.format('YYYY-MM-DD')}});
    
  }
  getDayNumber(){
    return this.now.isoWeekday()-1;
  }
  openMonth() {
    this.router.navigate(['./'], {relativeTo: this.route, queryParams: {date: null}});
    this.zendesk.show();
  }
  previous() {
    if (this.mode === 'month') {
      this.now = moment(this.now).subtract(1, 'month');
      this.setDates();
      this.getData();
    }else {
      this.now = moment(this.now).subtract(this.NUMBER_OF_DAYS, 'day');
      this.router.navigate(['./'], {relativeTo: this.route, queryParams: {date: this.now.format('YYYY-MM-DD')}});
    }
  }
  next() {
    if (this.mode === 'month') {
      this.now = moment(this.now).add(1, 'month');
      this.setDates();
      this.getData();
    }else {
      this.now = moment(this.now).add(this.NUMBER_OF_DAYS, 'day');
      this.router.navigate(['./'], {relativeTo: this.route, queryParams: {date: this.now.format('YYYY-MM-DD')}});
    }
  }
  eventClick(event){
    this.openBooking(null,null,event.event.meta);
  }
  highlightBookings(event){
    if(event && event.length >=2){
      this.orderService.search(event).toPromise().then((response: HttpResponse<any>)=>{
        this.findings = response.body.orders;
      });
    }else{
      this.findings = [];
    }
  }
  transformDay(index){
    // index = 0 Monday index 6 = Sunday
    // to = 0 Sunday 1 Monday
    if(index + 1>6){
      return 0;
    }else{
      return index+1;
    }
  }
  toggleBlock(date:any,hour="all",user_id="all"){
    if(!this.formattedBlocks[date]) this.formattedBlocks[date] = {};
    if(!this.formattedBlocks[date][hour]) this.formattedBlocks[date][hour] = {};
    this.formattedBlocks[date][hour][user_id] = !this.formattedBlocks[date][hour][user_id];
    this.bookingService.toggleBlock(date,hour,user_id).toPromise().then(value => {
    }).catch((error)=>{
      this.formattedBlocks[date][hour][user_id]= !this.formattedBlocks[date][hour][user_id];
    });
  }
  blocked(date:any,hour="all",user_id="all"){
    if(this.formattedBlocks[date]){
      if(this.formattedBlocks[date][hour]){
        if(this.formattedBlocks[date][hour]['all']){
          return true
        }else{
          return this.formattedBlocks[date][hour][user_id]
        }
      }
      else if(this.formattedBlocks[date]['all']){
        return this.formattedBlocks[date]['all']['all'];
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  setWeekends(){
    const days:any[] = this.appService.local$.getValue().days_preferences.days;
    const notDays = [];
    days.forEach((value,index) =>{
      if(value === false){
        notDays.push(index);
      }else if(value !== true && !value.enabled){
        notDays.push(index);
      }
    });
    this.excludeDays = notDays.map((value)=>this.transformDay(value));
  }
  toEvents(identifier?: string) {
    const tempEvents: { event: CalendarEvent, priority: number }[] = [];
  
    for (const date in this.booking.bookings) {
      if (date) {
        for (const booking of this.booking.bookings[date]) {
          let title = new ObjectNamePipe(this.appService, this.listService).transform(booking, booking.client_name);
          if (booking.reschedule_count > 0) {
            title += ` (${booking.reschedule_count})`;
          }
          const toDate = moment(booking.booking_date).toDate();
          let color = '#D50F25'; 
          let priority = 2; 
  
          if (booking.checked_in) {
            color = '#009925';
            priority = 0; 
          } else if (booking.confirmed) {
            color = '#3369E8';
            priority = 1; 
          } else if (booking.reschedule_count && booking.reschedule_count >= 1) {
            color = '#EEB211';
            priority = 3; 
          }
  
          tempEvents.push({
            event: {
              start: toDate,
              title: title,
              color: { primary: color },
              meta: booking
            },
            priority: priority
          });
        }
      }
    }
    tempEvents.sort((a, b) => a.priority - b.priority);
    const events = tempEvents.map(temp => temp.event);
    return events;
  }
  
  getMissingBookingsArray(currentLength: number): number[] {
    return Array(this.MAX_BOOKINGS - currentLength).fill(0);
  }
  openSearchModal() {
    this.isSearchModalOpen = true;
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  }
  
  closeSearchModal() {
    this.isSearchModalOpen = false;
    this.searchControl.reset();
  }
  @HostListener('document:click',['$event'])
  handleClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!this.bookingDialogOpen && this.isSearchModalOpen &&  !this.modalContent.nativeElement.contains(target) && !this.searchButton.nativeElement.contains(target)) {
      this.closeSearchModal();
    }
  }
  handleListClick(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.finding')) {
      this.closeSearchModal();
    }
  }

  getInitials(name: string): string {
    if (!name) return '';
    const words = name.split(' ');
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words.length > 1 ? words[words.length > 2 ? 2 : 1].charAt(0).toUpperCase() : '';
    
    return firstInitial + secondInitial;
  }


}
