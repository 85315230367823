import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CheckInService} from "../../services/check-in.service";
import { HttpResponse } from "@angular/common/http";
import {StatusesService} from "../../services/statuses.service";
import {RequirementsService} from "../../services/requirements.service";
import {UserService} from "../../services/user.service";
import {first} from "rxjs/operators";
import { FieldsService } from "../../admin/services/fields.service";
import moment from 'moment';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  changes:any[];

  statuses = [];

  requirements = [];

  users = [];

  fields = [];

  IGNORE_ATTRS = ['check_in_time','ended_at','sign_url'];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private orderService: CheckInService,
    private statusService: StatusesService,
    private requirementsService: RequirementsService,
    private userService: UserService,
    private fieldsService: FieldsService,
    private matDialogRef: MatDialogRef<OrderHistoryComponent>,
  ) { }

  ngOnInit() {
    this.orderService.getChanges(this.data.order_id).toPromise().then((value:HttpResponse<any[]>) => {
      this.changes = this.formatCustomParams(value.body);
    }).catch(reason => {
      console.log(reason);
    });

    this.statusService.statuses('StatusForOrder').pipe(first()).subscribe(value => {
      this.statuses = value;
    });

    this.requirementsService.requirements$.pipe(first()).subscribe(value => {
      this.requirements = value;
    });

    this.userService.users$.pipe(first()).subscribe(value => {
      this.users = value;
    });

    this.fieldsService.all('FieldForOrder').subscribe((fields) => {
      this.fields = fields;
    });
  }

  filterChangeSet(change){
    const keys = Object.keys(change.changeset).filter((k)=> this.IGNORE_ATTRS.findIndex(a => a ===k) === -1);
    return keys.map(value => {
      return {id: value, name: this.getName(value)};
    });
  }
  formatCustomParams(values:any[]){
    values.forEach((value,index) => {
      const custom = value.changeset['custom_fields'];
      delete value.changeset['custom_fields'];
      if(custom){
        for(const key in custom[0]){
          if(custom[0][key] && custom[1][key]!==custom[0][key]){
            if(!values[index].changeset[key]){
              values[index].changeset[key] = [null,null];
            }
            values[index].changeset[key][0] = custom[0][key];
          }
        }
        for(const key in custom[1]){
          if(custom[1][key] && custom[1][key]!==custom[0][key]){
            if(!values[index].changeset[key]){
              values[index].changeset[key] = [null,null];
            }
            values[index].changeset[key][1] = custom[1][key];
          }
        }
      }
    });
    return values;
  }
  getName(key){
    switch (key) {
      case 'vehicle_id':
        return 'Id vehiculo';
      case 'client_id':
        return 'Id cliente';
      case 'user_id':
        return 'Recepcionista';
      case 'status_id':
        return 'Status';
      case 'finish_date':
        return 'Fecha finalización';
      case 'checked_in':
        return 'Recepcionado';
      case 'comment':
        return 'Comentario';
      case 'requirement_id':
        return 'Requerimiento';
      default:
        return this.getField(key);
    }
  }
  getValue(key:string,id){
    let index;
    switch (key) {
      case 'status_id':
        index = this.statuses.findIndex(s => s.id === id);
        if(index >= 0){
          return this.statuses[index].name;
        }else{
          return id;
        }
      case 'requirement_id':
        index = this.requirements.findIndex(s => s.id === id);
        if(index >= 0){
          return this.requirements[index].name;
        }else{
          return id;
        }
      case 'checked_in':
        if(id){
          return 'Si';
        }else{
          return 'No';
        }
      case 'user_id':
        index = this.users.findIndex(s => s.id === id);
        if(index >= 0){
          return this.users[index].name;
        }else{
          return id;
        }
      default:
        if(id && id.name){
          return id.name;
        }
        else if(id && id.map){
          return id.map((v) => v.name || v).join(", ");
        }
        else{
          if(key.includes('date')){
            const date = moment(id)
            if(date.isValid()){
              return date.format('YYYY-MM-DD');
            }else{
              return id;
            }
          }else{
            return id;
          }
        }
    }
  }
  getField(key){
    const index = this.fields.findIndex(s => s.key === key);
    if(index >= 0){
      return this.fields[index].title;
    }else{
      return key;
    }
  }

}
