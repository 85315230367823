<mat-card>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Tipo de Gestión de Clientes</mat-label>
      <mat-select [(ngModel)]="crm_type">
        <mat-option value="CrmForBooking">Agendamiento</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración General</mat-card-title>
    <mat-card-subtitle>Administre la configuración de Gestión de Clientes</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <form [formGroup]="companyForm">
          <fieldset formGroupName="preferences">
            <fieldset formGroupName="crm_for_bookings">
              <mat-form-field>
                <mat-label>Texto de la notificación</mat-label>
                <textarea matInput placeholder="Texto de la notificación" formControlName="notification_text"></textarea>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Texto del recordatorio</mat-label>
                <textarea matInput placeholder="Texto del recordatorio" formControlName="reminder_text"></textarea>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Estado en que se enviará la notificación</mat-label>
                <mat-select placeholder="Estado de envío de la notificación" formControlName="notification_status_id">
                  @for(status of statuses; track status.id) {
                    <mat-option value="{{status.id}}">{{status.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Estado de primer contacto fallido</mat-label>
                <mat-select placeholder="Estado de primer contacto fallido" formControlName="failed_contact_status_id">
                  @for(status of statuses; track status.id) {
                    <mat-option value="{{status.id}}">{{status.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Nº máximo de clientes gestionados por día</mat-label>
                <input matInput placeholder="Nº máximo de clientes gestionados por día" formControlName="max_recalls">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Días máximos de anticipación</mat-label>
                <input matInput placeholder="Días máximos de anticipación" formControlName="anticipation_days">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Número máximo de reintentos</mat-label>
                <input matInput placeholder="Número máximo de reintentos" formControlName="max_number_of_retries">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Días entre reintentos</mat-label>
                <input matInput placeholder="Días entre reintentos" formControlName="days_between_retries">
              </mat-form-field>
              <mat-checkbox formControlName="auto_recall">
                Activar autogestión
                <i class="fa fa-question-circle" matTooltip="Se agregarán clientes para gestionar todos los días laborales"></i>
              </mat-checkbox>
            </fieldset>
          </fieldset>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
    <mat-card-title>Transiciones entre Requerimientos</mat-card-title>
    <mat-card-subtitle>Tiempo para llamar clientes segun requerimiento</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="requirementTransitionsService" [itemTemplate]="itemRequirementTemplate" [formTemplate]="formRequirementTemplate" [formGroup]="formRequirementTransition">
      <ng-template #itemRequirementTemplate let-item>
        <ng-container>
          <!--<div class="color" [style.background-color]="sanitizeColor(item.from_requirement?.color || 'white')"></div>--> {{item.from_requirement?.name || 'Cargando Requerimiento'}}
          <i class="fa fa-arrow-right"></i>
          <span>&nbsp;({{item.wait_months}} meses)</span>
          <i class="fa fa-arrow-right"></i>
          <!--<div class="color" [style.background-color]="sanitizeColor(item.to_requirement?.color || 'white')"></div>--> {{item.to_requirement?.name || 'Cargando Requerimiento'}}

        </ng-container>

      </ng-template>
      <ng-template #formRequirementTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Desde</mat-label>
            <mat-select placeholder="Desde" formControlName="from_requirement_id" required>
              <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
            </mat-select>
            <mat-hint>Requirimiento anterior</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Hasta</mat-label>
            <mat-select placeholder="Hasta" formControlName="to_requirement_id" required>
              <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
            </mat-select>
            <mat-hint>Siguiente Requerimiento</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Nº de meses</mat-label>
            <input matInput type="number" placeholder="Nº de meses de espera" formControlName="wait_months" autocomplete="off" required pattern="\d*">
            <mat-hint>Número mínimo de meses para contactar cliente</mat-hint>
          </mat-form-field>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>

@if (roleService.can('read','admin') | async) {
  <app-status-form
    type="StatusFor{{this.crm_type}}"
    title="Estados de un cliente"
    [showStatuses]="true"
    [showTransitions]="true">
  </app-status-form>
}

<app-work-form [model]="this.crm_type"></app-work-form>
<app-client-notification-form [model]="this.crm_type"></app-client-notification-form>
