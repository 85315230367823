<mat-horizontal-stepper (selectionChange)="setStep($event)">
  <mat-step>
    <ng-template matStepLabel>General</ng-template>
    <div class="container">
      <div class="text center">
        <h4>Bienvenido a la configuración avanzada de <b>ControlCar</b></h4>
        <p>Una vez completes esta configuración, <b>ControlCar</b> podrá realizar las siguientes funciones.</p>
        <ul>
          <li>Asignar trabajos a distintos usuarios al cambiar Estado</li>
          <li>Asignar trabajos a usuarios con disponibilidad</li>
          <li>Notificar técnicos sobre sus trabajos pendientes</li>
          <li>Permitir interacción y solicitud de trabajos entre usuarios (cotizaciónes, aprobaciones de presupuestos)</li>
          <li>Asociar checklists a trabajos </li>
          <li>Cambiar automáticamente el estado de un {{company.getText('object','lowercase')}}</li>
        </ul>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Transiciones</ng-template>
    <div class="container">
      <div class="text">
        <p>Una transicion es un cambio de estado permitido de un {{company.getText('object','lowercase')}}.</p>
        <p>Si configuras las transiciones, el {{company.getText('object','lowercase')}} cambiará de estado automaticamente al finalizar los trabajos que correspondan al estado actual.</p>
        <p>Si necesitas que algunas transiciones solo puedan ocurrir cuando ciertos estados estan completos, visita en el menu Estados/Requisitos de transición</p>
      </div>
      <div class="content">
        @if (step == 2) {
          <app-status-form
            type="StatusForOrder"
            [showTransitions]="true">
          </app-status-form>
        }
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Trabajos</ng-template>
    <div class="container">
      <div class="text">
        <p>Los trabajos son una de las características mas importantes de <b>ControlCar</b>, estos informan a los distintos usuario de tu taller, Que, Cuando y Como se deben realizar ciertas actividades.</p>
        <p>Existen dos tipos de trabajos:</p>
        <ul>
          <li>Trabajo Automatico: Este se realizará SIEMPRE que un vehículo cambie a un estado en particular. (Ej: Si el vehículo cambia a estado Control de Calidad un Asistente de Calidad debe ejecutar el Trabajo Control de Calidad)</li>
          <li>Trabajo Requerido <i class="fa fa-wrench"></i> : Estos trabajos son requeridos por usuarios hacia otros usuarios, las opciones de trabajos requeridos estan restringidas según el estado actual de un vehículo (No se puede hacer una cotizacion cuando un {{company.getText('object','lowercase')}} esta en estado entregado).</li>
        </ul>
        <p>Recomendamos crear un trabajo automatico por Estado</p>
      </div>
      <div class="content">
        <app-admin-works *ngIf="step == 3"></app-admin-works>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>CheckLists</ng-template>
    <div class="container">
      <div class="text">
        <p>Cada Trabajo necesita tener una lista con items que describan que se debe hacer, el usuario al completar dicha lista será capaz de dar por finalizado este.</p>
        <p>Puedes crear tantas listas quieras, para distintos requerimientos, vehiculos y marcas, Ej: Listas para Mantención, Mantención Mazda, Mantención Impar Mazda, etc</p>
        <p>Recomendamos iniciar con 1 Lista por Trabajo excepto en Trabajo Técnico donde recomendamos 1 Lista por Requerimiento. </p>
        <p>¡Puedes Utilizar el botón copiar, para clonar una lista y editarla como quieras! OJO: Puedes seleccionar la misma lista para distintos trabajos.</p>
      </div>
      <div class="content">
        <app-admin-check-list hideLinks="true" [inView]="true" *ngIf="step == 4"></app-admin-check-list>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Enlazar Listas</ng-template>
    <div class="container">
      <div class="text">
        <p>Ya tienes las Listas y los Trabajos, pero <b>ControlCar</b> aun no sabe que listas pertenecen a que trabajo, ni a que requerimiento, marca o modelo.</p>
        <p>En el proceso de selección de listas para un trabajo <b>ControlCar</b> busca desde listas detalladas para ciertos modelos hasta Trabajos generales, si no encuentra una lista busca en la siguiente definición</p>
        <ul>
          <li>Listas especifica para Trabajo + Requerimiento + Marca + Modelo</li>
          <li>Listas especifica para Trabajo + Requerimiento + Marca</li>
          <li>Listas especifica para Trabajo + Requerimiento</li>
          <li>Listas especifica para Trabajo</li>
        </ul>
        <p>Con esto puedes especificar hasta el mínimo detalle de un Trabajo, como tambien especificar trabajos generales</p>
      </div>
      <div class="content">
        <app-admin-check-list hideLists="true" *ngIf="step == 5"></app-admin-check-list>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Notificaciones</ng-template>
    <div class="container">
      <div class="text">
        <p><b>ControlCar</b> te ofrece la posibilidad de notificar a tus clientes cuando se realizan ciertas acciones dentro de la plataforma.</p>
        <p>Podrás elegir el tipo de notificación, y además que campos tendrá el cliente la posibilidad de contestar para así tener un comunicación mas fluida.</p>
      </div>
      <div class="content">
          <app-admin-client-notificacions *ngIf="step == 6"></app-admin-client-notificacions>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Add-ons</ng-template>
    <div class="container">
      <div class="text">
        <p>Parte de la automatización que proponemos en <b>ControlCar</b> también esta enfocada a tus Clientes, es por eso que hemos creado un add-on de Agendamiento Online que puedes incluir en tu página institucional o donde tu quieras. (Ejemplo a la derecha)</p>
        <p>Solo debes insertar el siguiente codigo en tu página web.</p>
        <div class="code">
          {{code}}
        </div>
      </div>
      <div class="content">
        <div class="wrapper">
          <app-client-booking *ngIf="step == 7"></app-client-booking>
        </div>

      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-button matStepperNext>Siguiente</button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Fin</ng-template>
    <div class="container">
      <div class="text center">
        <i class="fa fa-check-circle fa-4x"></i>
        <p>Has terminado la configuración avanzada de <b>ControlCar</b></p>
        <p>Recuerda que si tienes consultas puedes escribirnos a soporte&#64;controlcar.cl</p>
      </div>
    </div>
    <div class="buttons">
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
