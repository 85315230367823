@if (showStatuses) {
  <mat-card >
    <mat-card-header>
      <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
      <mat-card-title>Estados</mat-card-title>
      <mat-card-subtitle>{{ title }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ngx-crud-manager [service]="statusesService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="statusForm" [args]="[type]">
        <ng-template #itemTemplate let-item>
          <div class="color" [style.background-color]="sanitizeColor(item.color)"></div> {{item.name}}
        </ng-template>
        <ng-template #formTemplate let-form="form" let-item="item">
          <form [formGroup]="form">
            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
            </mat-form-field>
            <mat-form-field hidden>
              <mat-label>Color</mat-label>
              <input matInput placeholder="Color" formControlName="color" autocomplete="off" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tipo de Estado</mat-label>
              <mat-select formControlName="status_type" required multiple>
                @for (statusType of statusTypes; track statusType) {
                  <mat-option value="{{ statusType.value }}">
                    {{ statusType.viewValue }}
                    @if (statusType.tooltipMessage) {
                      <i class="fa fa-question-circle" matTooltip="{{ statusType.tooltipMessage }}"></i>
                    }
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div class="picker">
              Color:
              <mcc-color-picker hideUsedColors hideEmpty hideButtons (selected)="form.controls['color'].patchValue($event)" [selectedColor]="form.controls['color'].value"></mcc-color-picker>
            </div>
            @if (showNotifyClient) {
              <mat-checkbox formControlName="notify_client">
                Notificar Cliente
                <i class="fa fa-question-circle" matTooltip="Enviar una notificación al cliente cuando el auto ingrese a este estado"></i>
              </mat-checkbox>
            }
            @if (showActions) {
              <mat-form-field>
                <mat-label>Permisos</mat-label>
                <mat-select placeholder="Permisos" formControlName="budget_actions" multiple="true">
                  <mat-option *ngFor="let budget_action of budgetActions" [value]="budget_action.id">{{budget_action.name}}</mat-option>
                </mat-select>
                <mat-hint>Opciones que podrá ejecutar el usuario en este estado</mat-hint>
              </mat-form-field>
            }
          </form>
        </ng-template>
      </ngx-crud-manager>
    </mat-card-content>
  </mat-card>
}

@if (showTransitions && type) {
  <mat-card >
    <mat-card-header>
      <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
      <mat-card-title>Transiciones</mat-card-title>
      <mat-card-subtitle>Controle el flujo entre estados</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ngx-crud-manager
          [service]="statusTransitionsService"
          [itemTemplate]="itemStatusTemplate"
          [formTemplate]="formStatusTemplate"
          [formGroup]="statusTransitionForm"
          [args]="[statusTransitionModel]">
        <ng-template #itemStatusTemplate let-item>
          <ng-container>
            <div class="color" [style.background-color]="sanitizeColor(item.from_status?.color || 'white')"></div> {{item.from_status?.name || 'Sin estado'}}
            <i class="fa fa-arrow-right"></i>
            <div class="color" [style.background-color]="sanitizeColor(item.to_status?.color || 'white')"></div> {{item.to_status?.name || 'Sin estado'}}
            ({{item.requirement_ids.length > 0 ? getRequirementsNames(item.requirement_ids) : 'Principal'}})
          </ng-container>

        </ng-template>
        <ng-template #formStatusTemplate let-form="form" let-item="item">
          <form [formGroup]="form">
            <mat-form-field>
              <mat-label>Desde</mat-label>
              <mat-select placeholder="Desde" formControlName="from_status_id" required>
                <mat-option *ngFor="let status of statuses | async" [value]="status.id">{{status.name}}</mat-option>
              </mat-select>
              <mat-hint>Estado inicial de transición</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Hasta</mat-label>
              <mat-select placeholder="Hasta" formControlName="to_status_id" required>
                <mat-option *ngFor="let status of statuses | async" [value]="status.id">{{status.name}}</mat-option>
              </mat-select>
              <mat-hint>Estado final de transición</mat-hint>
            </mat-form-field>
            @if (showTransitionRequirement) {
              <mat-form-field>
                <mat-label>Requerimiento (Opcional)</mat-label>
                <mat-select placeholder="Requerimiento (Opcional)" formControlName="requirement_ids" multiple>
                  <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
                </mat-select>
                <mat-hint>Transición especifica de un Requerimiento</mat-hint>
              </mat-form-field>
            }
          </form>
        </ng-template>
      </ngx-crud-manager>
    </mat-card-content>
  </mat-card>
}

@if (showStatusTransitionRequirement) {
  <mat-card >
    <mat-card-header>
      <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
      <mat-card-title>Requisitos</mat-card-title>
      <mat-card-subtitle>Requisitos de transición</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ngx-crud-manager [service]="statusRequirementsService" [itemTemplate]="itemRequirementTemplate" [formTemplate]="formRequirementTemplate" [formGroup]="requirementTransitionForm">
        <ng-template #itemRequirementTemplate let-item>
          <ng-container *ngIf="item.status">
            <div class="color" [style.background-color]="sanitizeColor(item.requirement_status.color)"></div> {{item.requirement_status.name}}
            <i class="fa fa-arrow-right"></i>
            <div class="color" [style.background-color]="sanitizeColor(item.status.color)"></div> {{item.status.name}}
          </ng-container>
          <ng-container *ngIf="!item.status">
            <span>Guardando</span>
          </ng-container>
        </ng-template>
        <ng-template #formRequirementTemplate let-form="form" let-item="item">
          <form [formGroup]="form">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select placeholder="Estado" formControlName="status_id" required>
                <mat-option *ngFor="let status of statuses | async" [value]="status.id">{{status.name}}</mat-option>
              </mat-select>
              <mat-hint>Estado al que se va a hacer la transición</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Requisito</mat-label>
              <mat-select placeholder="Requisito" formControlName="requirement_status_id" required>
                <mat-option *ngFor="let status of statuses | async" [value]="status.id">{{status.name}}</mat-option>
              </mat-select>
              <mat-hint>Estado requisito para poder pasar al siguiente estado</mat-hint>
            </mat-form-field>
          </form>
        </ng-template>
      </ngx-crud-manager>
    </mat-card-content>
  </mat-card>
}
