import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AdditionalsService } from 'app/admin/services/additionals.service';
import { AdminService } from 'app/admin/services/admin.service';
import { BrandService } from 'app/services/brand.service';
import { RequirementsService } from 'app/services/requirements.service';

import { AdminKitsComponent } from '../admin-kits/admin-kits.component';

@Component({
  selector: 'app-admin-additionals',
  templateUrl: '../admin-kits/admin-kits.component.html',
  styleUrls: ['../admin-kits/admin-kits.component.scss', './admin-additionals.component.scss'],
})
export class AdminAdditionalsComponent extends AdminKitsComponent {
  hiddenInputTitle = 'Esconder';

  hiddenInputTooltipMessage = 'Al habilitar esta opción, el adicional no aparecerá visible de forma automática.';

  showSelectors: boolean = false;

  subtitle = 'Administrar adicionales de venta';

  title = 'Adicionales';

  constructor(
    public adminService: AdminService,
    public brandService: BrandService,
    public fb: FormBuilder,
    public requirementService: RequirementsService,
    public router: Router,
    public additionalsService: AdditionalsService,
  ) {
    super(adminService, brandService, fb, requirementService, router);
    this.service = this.additionalsService;
  }
}
