import { NgModule } from '@angular/core';
import { BaseModule } from '../shared/base.module';
import { ZipComponent } from './zip.component';

@NgModule({
  declarations: [
    ZipComponent
  ],
  imports: [
    BaseModule
  ]
})
export class ZipModule {

}
