<mat-card appearance="outlined">
  <mat-card-content>
    <div class="success" *ngIf="status=='approved'">
      <i class="fa fa-check-circle fa-2x" style="margin-right: 10px; color: green"></i>
      <span>Pago Aprobado</span>
    </div>
    <div class="success" *ngIf="status!='approved'">
      <i class="fa fa-times-circle fa-2x" style="margin-right: 10px; color: red"></i>
      <span>Pago Rechazado. Intenta nuevamente</span>
    </div>
    <div class="payment-details">
      <div class="header">
        <i class="fa fa-plus-circle"></i> <div class="text">Productos (Total: {{payment.total | money}})</div>
      </div>
      <div class="wrapper">
        <div class="content">
          <div class="info" *ngFor="let item of payment.items">
            <div class="quantity right">{{item.quantity || 1}}</div>
            <div class="value">{{item.title}} <span *ngIf="item.item_description">|</span> {{item.item_description}}</div>
            <div class="name right">{{item.unit_price*item.quantity | money }}</div>
          </div>
          <div class="info last">
            <div class="quantity"></div>
            <div class="value">Total</div>
            <div class="name right total" style="text-align: right">{{payment.total | money }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-details" *ngIf="payment.payment">
      <div class="payment-data">
        <div class="value">ID Pago:</div>
        <div class="name right total">{{payment.payment.id}}</div>
      </div>
      <div class="payment-data">
        <div class="value">Pagado el:</div>
        <div class="name right total">{{payment.payment.date_approved | date:'shortDate'}}</div>
      </div>
      <div class="payment-data">
        <div class="value">Pago Total:</div>
        <div class="name right total">{{payment.payment.transaction_details.total_paid_amount | money}}</div>
      </div>
      <div class="payment-data">
        <div class="value">Cuotas:</div>
        <div class="name right total">{{payment.payment.installments}}</div>
      </div>
      <div class="payment-data">
        <div class="value">Valor Cuota:</div>
        <div class="name right total">{{payment.payment.transaction_details.installment_amount | money}}</div>
      </div>
    </div>
    <div class="footer">
      {{company.name}}. <ng-container *ngIf="company.preferences.address"><i class="fa fa-address-book"></i> {{company.preferences.address}}</ng-container>
      <ng-container *ngIf="company.preferences.phone"><i class="fa fa-phone"></i> {{company.preferences.phone}}</ng-container>
    </div>
  </mat-card-content>
</mat-card>

