import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BudgetService } from 'app/budget/budget.service';
import { first } from 'rxjs/operators';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { AdminService } from 'app/admin/services/admin.service';
import { CheckInService } from 'app/services/check-in.service';
import { BookingService } from 'app/services/booking.service';
@Component({
  selector: 'app-zip',
  templateUrl: './zip.component.html',
  styleUrls: ['./zip.component.scss']
})
export class ZipComponent implements OnInit {
  pictures:any[] = null;
  budget:any = null;
  company:any = null;
  downloaded = false;
  constructor(
    private budgetService: BudgetService,
    private orderService: CheckInService,
    private bookingService: BookingService,
    private activeRoute: ActivatedRoute,
    private companyService: AdminService
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.pipe(first()).subscribe((params) => {
      this.activeRoute.queryParams.pipe(first()).subscribe((queryParams) => {
        if(params.model && params.model.toLowerCase() == 'budget') {
          this.budgetService.get(params.id,queryParams.token).pipe(first()).subscribe((res:any) => {
            this.budget = res.body;
            this.pictures = res.body.pictures;
            this.company = this.companyService.local$.getValue();
            this.createZip(params.model);
          });
        }
        if(params.model && params.model.toLowerCase() == 'order') {
          this.bookingService.get(params.id,queryParams.token).pipe(first()).subscribe((res:any) => {
            this.budget = res.body;
            this.pictures = res.body.order_attachments;
            this.company = this.companyService.local$.getValue();
            this.createZip(params.model);
          });
        }
      });
    });
  }
  createZip(model){
    // get image data from urls
    const promises = [];
    this.pictures.filter(p => !p.video).forEach((picture) => {
      promises.push(fetch(picture.url).then((res) => res.blob()));
    });
    Promise.all(promises).then((blobs) => {
      // create zip
      const zip = new JSZip();
      blobs.forEach((blob,i) => {
        let fileName = (this.pictures[i].name || this.pictures[i].id)+".jpg";
        zip.file(fileName, blob);
      });
      zip.generateAsync({type:"blob"}).then((content) => {
        const title = model == 'budget' ? 'Presupuesto' : 'Orden de Trabajo';
        saveAs(content, `${this.company.name} - ${title} ${this.budget.correlative_id}.zip`);
        this.downloaded = true;
      });
    });
  }
}
