import { Component } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ParsingOptions, read, readFile, utils,
} from 'xlsx';

import { CompaniesService } from '../services/companies.service';
import { DatabaseService } from '../services/database.service';
import { DatabaseConnectionsService } from '../services/database-connections.service';

@Component({
  selector: 'app-manager-databases',
  templateUrl: './manager-databases.component.html',
  styleUrl: './manager-databases.component.scss',
})
export class ManagerDatabasesComponent {
  databases = [
    { name: 'Crear nueva base de datos', id: 0 },
  ];

  dbTypes = [
    { name: 'Tablas', id: 'tables' },
  ];

  database = null;

  newDatabase:FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    db: new FormControl('', [Validators.required]),
    db_type: new FormControl('clients', [Validators.required]),
    keys: new FormControl([]),
    sheet: new FormControl(''),
  });

  readingFile = false;

  uploadingDB = false;

  selectedDatabase = 0;

  selectedSheet = 0;

  selectedKeys = {};

  selectedCompany = null;

  companies = [];

  keys = [
    {
      name: 'Cliente',
      id: 'Client',
      fields: [
        { name: 'Rut', id: 'rut' },
        { name: 'Nombre', id: 'name' },
        { name: 'Dirección', id: 'address' },
        { name: 'Email', id: 'email' },
        { name: 'Comuna', id: 'city' },
        { name: 'Teléfono', id: 'phone' },
        { name: 'Custom', id: 'custom_fields' },
      ],
    },
    {
      name: 'Vehículo',
      id: 'Vehicle',
      fields: [
        { name: 'Patente', id: 'identifier' },
        { name: 'Chasis', id: 'chasis' },
        { name: 'Año', id: 'year' },
        { name: 'Color', id: 'color' },
        { name: 'Custom', id: 'custom_fields' },
      ],
    },
    {
      name: 'Marca',
      id: 'Brand',
      fields: [
        { name: 'Nombre', id: 'name' },
      ],
    },
    {
      name: 'Modelo',
      id: 'BrandModel',
      fields: [
        { name: 'Nombre', id: 'name' },
      ],
    },
    {
      name: 'Objeto',
      id: 'Custom',
      fields: [
        { name: 'Identificador', id: 'identifier' },
        { name: 'Custom', id: 'custom_fields' },
      ],
    },
    {
      name: 'Producto',
      id: 'Product',
      fields: [
        { name: 'Nombre', id: 'name' },
        { name: 'SKU', id: 'code' },
        { name: 'Precio', id: 'price' },
        { name: 'Description', id: 'description' },
      ],
    },
    {
      name: 'Categoría',
      id: 'Category',
      fields: [
        { name: 'Nombre', id: 'name' },
      ],
    },

  ];

  workbook = null;

  progress = 0;

  sheet = null;

  header = null;

  constructor(
    private angularFireStorage: AngularFireStorage,
    private databaseService: DatabaseService,
    private databaseConnectionsService: DatabaseConnectionsService,
    private companiesService: CompaniesService,
  ) {
    this.databaseService.get().subscribe({
      next: (response:any) => {
        this.databases = this.databases.concat(response.body);
      },
      error: (error) => {
        console.log(error);
      },
    });
    this.companiesService.get().subscribe({
      next: (response:any) => {
        this.companies = response.body;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onDatabaseChange(event) {
    this.selectedDatabase = event.value;
    if (this.selectedDatabase == 0) {
      this.database = null;
    } else {
      this.database = this.databases.find((db) => db.id == this.selectedDatabase);
    }
  }

  updateDatabase() {

  }

  createDatabase() {
    const formatKeys = [];
    for (let i = 0; i < this.header.length; i++) {
      if (this.selectedKeys[i]) {
        if (this.selectedKeys[i].indexOf('custom_fields') >= 0) {
          formatKeys.push(this.formatHeader(this.selectedKeys[i], this.header[i]));
        } else {
          formatKeys.push(this.selectedKeys[i]);
        }
      } else {
        formatKeys.push('');
      }
    }
    this.newDatabase.patchValue({ keys: formatKeys });
    this.uploadingDB = true;
    this.uploadJSON().then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        this.newDatabase.patchValue({ db: url });
        this.databaseService.create(this.newDatabase.value).subscribe({
          next: (response:any) => {
            this.uploadingDB = false;
            this.databases.push(response.body);
            this.selectedDatabase = response.body.id;
            this.database = response.body;
            this.newDatabase.reset();
          },
          error: (error) => {
            this.uploadingDB = false;
            console.log(error);
          },
        });
      });
    });
  }

  deleteDatabase(id) {
    this.databaseService.delete(id).subscribe({
      next: (response:any) => {
        console.log(response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onFileChange(event, id) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      this.readingFile = true;
      reader.onload = (e:any) => {
        this.readingFile = false;
        const data = new Uint8Array(e.target.result);
        this.workbook = read(data, { type: 'array' });
        this.selectedSheet = this.database ? this.database.sheet : this.workbook.SheetNames[0];
        this.newDatabase.patchValue({ db: file.name, sheet: this.selectedSheet });
        this.processExcel();
        if (id === 'upgrade') {
          this.uploadJSON().then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              this.database.db = url;
              this.databaseService.update(this.database.id, this.database).subscribe({
                next: (response:any) => {
                  console.log(response);
                },
                error: (error) => {
                  console.log(error);
                },
              });
            });
          });
        }
      };
      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          this.progress = Math.round((e.loaded / e.total) * 100);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  processExcel() {
    const sheet = this.workbook.Sheets[this.selectedSheet];
    this.sheet = utils.sheet_to_json(sheet, { header: 1 });
    this.header = this.sheet[0];
  }

  onSheetChange(event) {
    this.selectedSheet = event.value;
    this.newDatabase.patchValue({ sheet: this.selectedSheet });
    this.processExcel();
  }

  createConnection() {
    this.databaseConnectionsService.create({
      manager_database_id: this.database.id,
      company_id: this.selectedCompany,
    }).subscribe({
      next: (response:any) => {
        const company = this.companies.find((company) => company.id == this.selectedCompany);
        response.body.company = company;
        this.database.connections.push(response.body);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  uploadJSON() {
    // create file
    const blob = new Blob([JSON.stringify(this.sheet)], { type: 'application/json' });
    const randomUUID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const uploadTask = this.angularFireStorage.upload(`databases/${randomUUID}.json`, blob);
    uploadTask.percentageChanges().subscribe((percentage) => {
      this.progress = percentage;
    });
    return uploadTask;
  }

  formatHeader(key, header) {
    return `${key}.${header.replace(/\W/g, '_').toLowerCase()}`;
  }

  uploadFile(input) {
    input.click();
  }
}
