<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Campos Personalizados</mat-card-title>
    <mat-card-subtitle>Agregue mas información a sus formularios</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <ngx-crud-manager
            [service]="service"
            [itemTemplate]="itemFieldTemplate"
            [formTemplate]="formFieldTemplate"
            [formGroup]="formParams"
            [args]="[model]"
        >
          <ng-template #itemFieldTemplate let-item>
            {{item.title}}
          </ng-template>
          <ng-template #formFieldTemplate let-form="form" let-item="item">
            <form [formGroup]="form">
              <div class="row">
                <div class="col">
                  <mat-form-field>
                    <mat-label>Título(s)</mat-label>
                    <input matInput placeholder="Título" formControlName="title" autocomplete="off" required>
                    <mat-hint>Título del campo. Usar comas para múltiples titulos</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field>
                <mat-label>Tipo</mat-label>
                <mat-select placeholder="Tipo" formControlName="field_type" required>
                  <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}</mat-option>
                </mat-select>
                <mat-hint>Seleccione el tipo de campo</mat-hint>
              </mat-form-field>
              <mat-form-field [hidden]="form.controls['field_type'].value!=='users'">
                <mat-label>Filtro de Roles</mat-label>
                <mat-select placeholder="Filtro de Roles" formControlName="field_filter" [required]="form.controls['field_type'].value==='users'" multiple="true">
                  <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                </mat-select>
                <mat-hint>Roles a mostrar</mat-hint>
              </mat-form-field>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="required">
                    Requerir en Orden
                    <i class="fa fa-question-circle" matTooltip="El usuario debe obligatoriamente llenar este campo"></i>
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="show_booking">
                    Mostrar en Agendamiento
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="require_booking">
                    Requerir en agendamiento
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="show_client">
                    Mostrar a cliente
                    <i class="fa fa-question-circle" matTooltip="La información de este campo se mostrara en la orden que verá el cliente"></i>
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="hide_optional">
                   Ocultar opcional
                    <i class="fa fa-question-circle" matTooltip="La información de este campo se mostrara en la orden que verá el cliente"></i>
                  </mat-checkbox>
                </div>
              </div>
            </form>
          </ng-template>
        </ngx-crud-manager>
      </div>
    </div>
  </mat-card-content>
</mat-card>
