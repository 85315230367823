<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Productos{{ parentCategory ? ' - ' + parentCategory.name : ''}}</mat-card-title>
    <mat-card-subtitle>Administrar listado de productos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager
        [service]="productsService"
        [itemTemplate]="itemTemplate"
        [formTemplate]="formTemplate"
        [formGroup]="form"
        [args]="[categoryId]"
        [showNavigation]="!!categoryId"
        (back)="goUp()"
    >
      <ng-template #itemTemplate let-item>
        [{{item.code}}] {{item.name || item.description}}
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="value">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>SKU</mat-label>
            <input matInput placeholder="SKU" formControlName="code" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Categoría</mat-label>
            <mat-select placeholder="Categoría" formControlName="category_ids" multiple="true" required>
              <mat-option *ngFor="let category of categories$ | async" [value]="category.id">{{category.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>
              Unidad&nbsp;
              <i
                class="fa fa-question-circle"
                matTooltip="De no especificarse la unidad aquí, se tomará la de la categoría.">
              </i>
            </mat-label>
            <input matInput placeholder="Unidad" formControlName="unit" autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Precio</mat-label>
            <input currencyMask matInput placeholder="Precio" formControlName="price" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field style="margin-bottom: 10px">
            <mat-label>Marcas</mat-label>
            <mat-select placeholder="Marcas" formControlName="brand_ids" multiple="true">
              <mat-option *ngFor="let brand of brands" [value]="brand.id">{{brand.name}}</mat-option>
            </mat-select>
            <mat-hint>Marcas a las que corresponde el producto</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="Descripción" formControlName="description" autocomplete="off" required></textarea>
          </mat-form-field>
          @if(filterCustom && filterCustom.length > 0) {
            <app-order-fields
                mode="Category"
                [model]="item"
                [parentFormGroup]="form"
                [filterFields]="filterCustom">
            </app-order-fields>
          }
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
