import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ClientService} from "../client.service";
import {first} from "rxjs/operators";
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-client-confirm',
  templateUrl: './client-confirm.component.html',
  styleUrls: ['./client-confirm.component.scss']
})
export class ClientConfirmComponent implements OnInit {
  loading = false;
  bookingEditToken:string;
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(first()).forEach((value)=>{
      if(value.booking_token) {
        this.loading = true;
        this.bookingEditToken = value.booking_token;
        this.clientService.confirmBooking(this.bookingEditToken).toPromise().then((response) => {
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          console.log(error);
        });
      }
    })
    this.zendesk.hide();
  }

}
