<mat-toolbar class="header">
  <div
      class="back"
      (click)="closeDialog()"
      (keyup)="$event.keyCode === 13 && closeDialog()"
      matTooltip="Atrás"
      matTooltipPosition="right"
      tabindex
  >
    <mat-icon >arrow_back_ios_new</mat-icon>
  </div>
  <div style="font-weight: 500; align-content: center;">
    {{adminService.getText('booking','capitalize')}}
  </div>
</mat-toolbar>

<div class="divider">
  <div class="column" style="margin-right: 0;">
    <div class="wrapper"> 
      <div class="skeleton-container" *ngIf="loading">
        <div class="skeleton" style="height: 56px;"></div>
        <br>
        <div class="skeleton" style="height: 56px;"></div>
        <div class="skeleton" style="height: 56px;"></div>
        <br>
        <div class="skeleton" style="height: 56px;"></div>
        <div class="skeleton" style="height: 56px;"></div>
        <br>
        <div class="skeleton" style="height: 56px;"></div>
        <div class="skeleton" style="height: 56px;"></div>
        <div class="skeleton" style="height: 56px;"></div>
      </div>
        
    
      <div *ngIf="!loading">
        <form [formGroup]="form" autocomplete="off">
          <div class="form-container" mat-dialog-content>
            <ng-container *ngIf="locations && locations.length > 1">
              <div class="subtitle"><i class="fa fa-warehouse"></i> {{adminService.getText('location')}}</div>
              <fieldset>
                <mat-form-field>
                  <mat-label>{{adminService.getText('location')}}</mat-label>
                  <mat-select formControlName="location_id" required autocomplete="off">
                    <mat-option *ngFor="let location of locations" [value]="location.id">
                      {{location.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </fieldset>
            </ng-container>
            <ng-container *ngIf="company.preferences.booking_type === 'professional'">
              <div class="subtitle"><i class="fa fa-user"></i> {{adminService.getText('professional','capitalize')}}</div>
              <fieldset>
                <mat-form-field>
                  <mat-label>Nombre</mat-label>
                  <mat-select formControlName="user_id" required autocomplete="off">
                    <mat-option *ngFor="let user of users" [value]="user.id">
                      {{user.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </fieldset>
            </ng-container>
            <ng-container *ngIf="roleService.can('read','client') | async">
              <div class="subtitle"><i class="fa fa-user"></i> {{adminService.getText('client','capitalize')}}</div>
              <app-client-form [client]="booking.client" [parentFormGroup]="form.controls.client" [bookingMode]="true" (vehicleSelected)="objectSelected($event)"></app-client-form>
            </ng-container>
            <ng-container *ngIf="(roleService.can('read','client') | async) === false">
              <div class="subtitle">{{adminService.getText('client')}} #{{ booking.client.correlative_id }}</div>
              <div class="info" [class.last]="(roleService.can('read','client') | async) === false">
                <div class="name">Nombre</div>
                <div class="value">{{booking.client?.name || 'No existe el'+adminService.getText('client','lowercase')}}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="company.object === 'Vehicle'">
              <div class="subtitle" id="vehicle-form"><i class="fa fa-car"></i> {{adminService.getText('object','capitalize')}}</div>
              <app-vehicle-form [model]="booking.object" [parentFormGroup]="form.controls.object" [parentControl]="form" (plateSelected)="form.controls.client.patchValue($event, { emitEvent: false })" [optionalFields]="true"></app-vehicle-form>
            </ng-container>
            <ng-container *ngIf="company.object === 'Custom'">
              <div class="subtitle" id="custom-form"><i class="fa fa-cubes"></i> {{adminService.getText('object','capitalize')}}</div>
              <app-custom-object-form [model]="booking.object" [parentFormGroup]="form.controls.object" (plateSelected)="form.controls.client.patchValue($event, { emitEvent: false })" [optionalFields]="true"></app-custom-object-form>
            </ng-container>
            <div class="subtitle"><i class="fa fa-clock"></i> Información</div>
            <fieldset>
              <div class="row">
                <div class="col">
                  <mat-form-field>
                    <mat-label>{{adminService.getText('requirement','capitalize')}}</mat-label>
                    <mat-select formControlName="requirement_id" required autocomplete="off">
                      <mat-option *ngFor="let requirement of types" [value]="requirement.id">
                        {{requirement.name}} <span *ngIf="requirement.price">&nbsp;({{requirement.price | money}})</span>
                      </mat-option>
                    </mat-select>
                    <mat-error>Debe seleccionar un {{adminService.getText('requirement','lowercase')}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col" style="margin-right: 10px;">
                  <mat-form-field (click)="picker.open()">
                    <mat-label>Fecha</mat-label>
                    <input
                        matInput
                        [matDatepicker]="picker"
                        [min]="currentDate"
                        [matDatepickerFilter]="onlyWeek"
                        required
                        formControlName="booking_date"
                        autocomplete="off">
                  </mat-form-field>
                  <mat-datepicker #picker [startAt]="form.controls.booking_date.value"></mat-datepicker>
                </div>
                <div class="col">
                  <mat-form-field>
                    <mat-label>Hora</mat-label>
                    <mat-select formControlName="booking_time" required autocomplete="off">
                      @for(hour of freeBookingHours; track hour) {
                        <mat-option [value]="hour">
                          {{hour}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field>
                    <mat-label>{{adminService.getText('comment','capitalize')}}</mat-label>
                    <input matInput formControlName="comment" autocomplete="off">
                  </mat-form-field>
                </div>
              </div>
              <div class="hidden-fields">
                <input type="hidden" formControlName="from" value="app">
              </div>
            </fieldset>


            <div class="subtitle"><i class="fa fa-plus-circle"></i> {{adminService.getText('aditional_plural','capitalize')}}</div>
            <app-additionals-form class="additionals-form"
              [initialValue]="booking.order_aditionals"
              [parentFormArray]="form.controls.order_aditionals"
              (added)="addAdditional(this, $event)"
              (removed)="removeAdditional(this, $event)"
            ></app-additionals-form>

            <div class="booking-inventory-action-form">
              <mat-expansion-panel hideToggle (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                  <div class="header-container">
                    <div class="subtitle" ><i class="fa fa-wrench"></i>Detalle del servicio   <mat-icon>{{panelOpenState() ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon> </div>
                    <div class="total">
                      {{ totalValue * company.country.preferences.tax_rate | money }}
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <app-inventory-action-form
                  [parentFormGroup]="form.controls.inventory_action_for_consume"
                  (dataChanged)="onTotalValueChange($event)"
                  [disabled]="(roleService.can('update', 'booking') | async) === false"
                  [brandId]="form.get('object.brand_id')?.value"
                  parentModel="booking"
                  #inventoryForm>
                </app-inventory-action-form>

              </mat-expansion-panel>
            </div>
            <app-order-fields
            [model]="booking"
            [parentFormGroup]="form"
            [bookingMode]="true"
            [reactiveFormMode]="true"
            ></app-order-fields>
            <div class="remove">
              <button mat-raised-button class="warn display-mobile" (click)="removeBooking()" *ngIf="!booking.checked_in && booking.id && ((roleService.can('destroy','booking') | async) || bookingEditToken)"><i class="fa fa-trash"></i> Eliminar {{adminService.getText('booking','capitalize')}}</button>
            </div>
            <div class="booking-error" *ngIf="bookingErrors && !booking.id">
              <i class="fa fa-exclamation-triangle"></i>
              <div class="errors">
                <span *ngIf="bookingErrors['invalid_date']">Hora inválida.</span>
                <span *ngIf="bookingErrors['max_requirements_reached']">Se alcanzo el límite de este {{adminService.getText('requirement','lowercase')}} para este día.</span>
                <span *ngIf="bookingErrors['disabled_day']">Día deshabilitado en el calendario.</span>
                <span *ngIf="bookingErrors['max_bookings_reached']">Numero de máximo de {{adminService.getText('booking_plural','lowercase')}} alcanzados para este día.</span>
                <span *ngIf="bookingErrors['max_hours_reached']">Numero máximo de horas de trabajo alcanzados para este día.</span>
                <!-- <span *ngIf="bookingErrors['not_available_stock']">Stock insuficiente para el kit asignado.</span> -->
              </div>
            </div>
          </div>
          <div mat-dialog-actions class="footer">

            <div class="buttons">
              <button mat-raised-button class="primary"  [disabled]="!form.valid || booking.checked_in || working" (click)="saveForm()" *ngIf="!booking.id || (roleService.can('update','booking') | async) || (booking.id && bookingEditToken)" style="margin-left: 0;">
                <span class="display-desktop">Guardar</span>
                <span class="display-mobile">
                  <mat-icon>save</mat-icon>
                </span>
              </button>
              <button mat-raised-button class="primary"  [disabled]="!form.valid || booking.checked_in || working" (click)="confirm()" *ngIf="((booking.id && (roleService.can('update','booking') | async)) || (booking.id && bookingEditToken)) && !booking.confirmed">
                <span class="display-desktop">Confirmar</span>
                <span class="display-mobile">
                  <mat-icon>check_circle</mat-icon>
                </span>
              </button>
              <div class="expander"></div>
              <button mat-raised-button class="primary" [disabled]="working" (click)="createOrder()" *ngIf="booking.id && !booking.checked_in && (roleService.can('create','check_in') | async)">{{adminService.getText('checkin','capitalize')}}</button>
              <button mat-raised-button class="primary" [disabled]="working" (click)="viewOrder()" *ngIf="booking.id && booking.checked_in && (roleService.can('read','check_in') | async)">Ver {{adminService.getText('order','capitalize')}}</button>
              <button mat-raised-button class="warn display-desktop" (click)="removeBooking()" *ngIf="!booking.checked_in && booking.id && ((roleService.can('destroy','booking') | async) || bookingEditToken)"><i class="fa fa-trash"></i></button>

            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>


