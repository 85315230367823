import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AnalyticsService} from "../services/analytics.service";
import { HttpResponse } from "@angular/common/http";
import { AdminService } from 'app/admin/services/admin.service';
import { ChartFiltersService } from './chart-filters/chart-filters.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  working = true;
  filter = {};
  sub: Subscription;
  @Input() snapshot = '';

  constructor(
    private analyticsService: AnalyticsService,
    public adminService: AdminService,
    public chartFiltersService: ChartFiltersService,
  ) {}

  ngOnInit() {
    this.adminService.pageTitle.set('Analíticas');
    this.sub = this.chartFiltersService.filters$
    .subscribe((filters) => {
      if (filters){
        this.working = false;
        this.filter = filters;
      }
    });

    if(this.snapshot){
      this.analyticsService.getSnapshot(this.snapshot).then((response: HttpResponse<any>) => {
        this.chartFiltersService.updateFilters(response.body.filters);
        this.working = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.chartFiltersService.resetFilters();
  }
}
