import {CanActivateWorkPoint} from "./work-point.permission";
import {Route} from "@angular/router";
import {WorkPointLoginComponent} from "./work-point-login/work-point-login.component";
import {WorkPointMainComponent} from "./work-point-main/work-point-main.component";
import {WorkPointWorksComponent} from "./work-point-main/work-point-works/work-point-works.component";
import {WorkPointUnauthorizedComponent} from "./work-point-unauthorized/work-point-unauthorized.component";

export const workPointRoutes: Route[] = [
  {path: 'login', component: WorkPointLoginComponent},
  {path: 'unauthorized', component: WorkPointUnauthorizedComponent},
  {path: 'main', component: WorkPointMainComponent, canActivate: [CanActivateWorkPoint], children: [
      {path: 'works', component: WorkPointWorksComponent},
      {path: '**', redirectTo: 'works'}
    ]
  },
  {path: '**', redirectTo: 'login'}
];
