import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {cloneDeep} from "lodash-es";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  constructor(
    private matDialogRef: MatDialogRef<FilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  ngOnInit() {
    for(const i in this.data){
      if(this.data[i]){
        if(this.data[i].options.filter((o)=>o[2]===true).length === this.data[i].options.length){
          this.data[i].selectAll = true;
        }else{
          this.data[i].selectAll = false;
        }
      }
    }
  }
  applyFilters() {
    this.matDialogRef.close(this.formatResult());
  }
  formatResult() {
    const formattedFilters = {};
    const filters: any[] = cloneDeep(this.data);
    for (const f of filters) {
      formattedFilters[f.key] = f.options.filter((o) => o[2]).map((o) => o[0]);
    }
    return formattedFilters;
  }
  toggleOptions(filter){
    filter.selectAll = !filter.selectAll;
    for(const i in filter.options){
      if(filter.options[i]){
        filter.options[i][2] = filter.selectAll;
      }
    }
  }
}
