export interface Kit {
  id: number;
  name: string;
  company_id: number;
  hidden: boolean;
  price: number;
  kit_products: KitProduct[];
}

export interface KitProduct {
  category_id: number;
  code: string;
  company_id: number;
  description: string;
  id: number;
  kit_id: number;
  name: string;
  price: number;
  product_category_id: number;
  product_id: number;
  quantity: number;
  unit: string;
}

function toInventoryActionProduct(kitProduct: KitProduct) {
  return {
    ...kitProduct,
    kit_product_quantity: kitProduct.quantity,
    amount: kitProduct.price,
    discount_percent: 0,
    id: null,
    _destroy: false,
    _edited: false,
  };
}

function toInventoryActionProducts(kit: Kit) {
  return kit.kit_products.map(toInventoryActionProduct).map((product) => ({ ...product, kit_name: kit.name }));
}

export function fromKitsToInventoryActionProducts(kits: Kit[]) {
  return Object.values(
    kits
      .flatMap((kit) => toInventoryActionProducts(kit))
      .reduce((acc, product) => {
        if (acc[product.product_id]) {
          acc[product.product_id].quantity += product.quantity;
          acc[product.product_id].kit_product_quantity += product.quantity;
          acc[product.product_id].kit_name += `, ${product.kit_name}`;
        } else {
          acc[product.product_id] = product;
        }
        return acc;
      }, {}),
  );
}
