import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseModule } from "../../shared/base.module";
import { AdminService } from 'app/admin/services/admin.service';
import { BudgetViewComponent } from 'app/budget/budget-view/budget-view.component';
import { BookingComponent } from 'app/main/booking/booking.component';
import { OrderComponent } from 'app/main/order/order.component';
import { AnalyticsService } from 'app/services/analytics.service';
import { FormatColumnPipe } from 'app/work/format-column.pipe';
import { WorkColumnsService } from 'app/work/work-columns/work-columns.service';
import { WorkDialogComponent } from 'app/work/work-dialog/work-dialog.component';
import { WorkService } from 'app/work/work.service';
import { cloneDeep } from 'lodash';
import * as XLSX from 'xlsx';
import { WorkModule } from 'app/work/work.module';
import { SharesService } from 'app/shares/services/shares.service';
import { BookingService } from 'app/services/booking.service';

@Component({
  selector: 'app-chart-details',
  templateUrl: './chart-details.component.html',
  styleUrls: ['./chart-details.component.scss'],
  imports: [BaseModule, WorkModule],
  standalone: true,
})
export class ChartDetailsComponent implements OnInit {
  data;
  columns = [];
  loadingMore = false;
  page = 1;
  totalPages = 1;
  pageSize = 100;
  totalCount = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) private input,
    private analyticsService: AnalyticsService,
    private sharesService: SharesService,
    private columnService: WorkColumnsService,
    private adminService: AdminService,
    private bookingService: BookingService,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(loadingMore = false) {
    const params = {
      custom: this.input.custom,
      page: this.page,
      page_size: this.pageSize,
    }
    const promise = this.input.custom.startsWith('shares')
      ? this.sharesService.generateDetails(params, this.input.filter, null)
      : this.analyticsService.generateDetails(params, this.input.filter, null)

    promise.then((data:any) => {
      if (loadingMore) {
        this.data = this.data.concat(data)
      } else {
        this.columns = this.getColumns(data)

        this.data = data
      }
      this.totalCount = data[1]?.total_count || 0
      this.totalPages = this.totalCount % this.pageSize
        ? Math.floor(this.totalCount / this.pageSize) + 1
        : Math.floor(this.totalCount / this.pageSize)
      this.loadingMore = false;
    });
  }

  getColumns(data){
    if(data.length > 0) {
      const keys = Object.keys(data[0]).filter(k => !this.input.excludedKeys.includes(k))
      return this.columnService
        .transformKeys('pending', keys ,false)
        .filter(k => !['order_id', 'total_count'].includes(k[0]))
    } else {
      return []
    }
  }
  exportAsExcelFile(): void {
    const excelFileName = 'Analítica';
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.formatJson(this.data));
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const today = new Date();
    const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '';
    const time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
    const name = excelFileName + date + time;
    XLSX.writeFile(workbook, name + ".xlsx");
  }

  openDetails(row){
    if(row.item_type == 'WorkAssignation' || this.input.type == 'work'){
      this.matDialog.open(WorkDialogComponent, {
        data: {
          id: row.parent_item_id || row.work_instance_id || row.item_id || row.id
        },
        minWidth: "80%"
      })
    }else if(row.parent_item_type?.includes('Budget') || row.item_type?.includes('Budget') || this.input.type == 'budget'){
      this.matDialog.open(BudgetViewComponent, {
        data: {
          id: row.parent_item_id || row.item_id || row.budget_id
        },
        minWidth: "80%"
      })
    }
    else{
      if(row.hasOwnProperty('checked_in') && !row.checked_in){
        this.matDialog.open(BookingComponent, {
          data: {
            id: row.parent_item_id || row.item_id || row.order_id
          },
          minWidth: "80%"
        })
      }else{
        this.matDialog.open(OrderComponent, {
          data: row.parent_item_id || row.item_id || row.order_id,
          minWidth: "80%"
        })
      }
    }
  }
  formatJson(data){
    let array = cloneDeep(data);
    array = array.map(row => {
      const json = {};
      this.columns.forEach(c => {
        json[c[2]] = new FormatColumnPipe(
          this.adminService,
          this.bookingService,
          this.sanitizer
        ).transform(row[c[0]], c[0], true);
      });
      return json;
    });
    return array;
  }

  getMoreData() {
    this.loadingMore = true;
    this.page++;
    this.getData(true)
  }
}
