import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { RoleService } from './role/role.service';
import { UserService } from './services/user.service';

@Injectable()
export class CanActivateMain {
  private user = null;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.user != null) {
      return true;
    } if (this.authService.token) {
      return this.authService.authCallback();
    }
    this.router.navigate(['/']);
    return false;
  }

  constructor(private authService: UserService, private router: Router) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }
}

@Injectable()
export class CanActivateMenu {
  private user = null;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const path = route.url.toString();
    return this.roleService.canRead(this.roleService.pathToModel(path));
  }

  constructor(
    private authService: UserService,
    private router: Router,
    private roleService: RoleService,
  ) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }
}
