<div class="wrapper settings">
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="container">

    <mat-card  *ngIf="!loading">
      <mat-card-content>
        
        <div class="check">
          <mat-slide-toggle 
            [(ngModel)]="userCheckbox" 
            (change)="onCheckboxChange($event)">
          </mat-slide-toggle>
          En línea
        </div>
  

      </mat-card-content>
    </mat-card>

    <mat-card  *ngIf="!loading">
      <mat-card-content>
        <form #f="ngForm" autocomplete="off">
          <div class="subtitle">Información General</div>
          <div class="profile-container">
            <div class="profile-picture" (click)="openUploadPfpDialog()" 
            [ngStyle]="{'background-image': 'url(' + (user.picture ? user.picture : 'https://firebasestorage.googleapis.com/v0/b/controlcar-6ff6ceedb1c/o/images%2F2024-09-12T20%3A57%3A32.022Z-66270.83877942957.png?alt=media&token=80cfd3ea-0f4e-4c44-9c9b-45caff2f0f7e') + ')'}"
            >
              <div class="overlay">
                <mat-icon>edit</mat-icon>
              </div>
            </div>
          </div>
          <fieldset>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Nombre</mat-label>
                  <input matInput placeholder="Nombre" name="name" autocomplete="off" [(ngModel)]="user.name">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Correo</mat-label>
                  <input matInput placeholder="Correo" type="email" name="mail" autocomplete="off" [(ngModel)]="user.mail">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Teléfono</mat-label>
                  <input matInput placeholder="Teléfono" name="phone" autocomplete="off" [(ngModel)]="user.phone">
                  <mat-hint>Teléfono al cual podrán llamarte los clientes</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Rut</mat-label>
                  <input matInput placeholder="Rut" name="rut" autocomplete="off" [(ngModel)]="user.rut">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Código punto de trabajo</mat-label>
                  <input matInput placeholder="Codigo Punto de Trabajo" name="workpoint_code" autocomplete="off" [(ngModel)]="user.workpoint_code" minlength="4" type="password">
                  <mat-hint>Codigo de ingreso al punto de trabajo, intenta no utilizar tu clave para ingresar directamente</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <div class="buttons middle-buttons">
            <button mat-raised-button class="primary" [disabled]="working" (click)="saveForm(f)">Guardar</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="!loading">
      <mat-card-content>
        <form [formGroup]="changePassword" autocomplete="off">
          <div class="subtitle">Cambiar contraseña (Cerrará todas las sesiones activas)</div>
          <fieldset>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Contraseña Actual</mat-label>
                  <input matInput type="password" placeholder="Contraseña Actual" name="old_password" autocomplete="off" formControlName="old_password">
                  <mat-error>
                    Contraseña inválida
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Nueva Contraseña</mat-label>
                  <input matInput type="password" placeholder="Nueva Contraseña" name="password" autocomplete="off" formControlName="password">
                  <mat-error>
                    Contraseña inválida, debe tener mínimo 6 dígitos
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Repita Contraseña</mat-label>
                  <input matInput type="password" placeholder="Repita Contraseña" name="password_confirmation" autocomplete="off" formControlName="password_confirmation">
                  <mat-error *ngIf="changePassword.errors?.notEqual">
                    Contraseñas no coinciden
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <div class="buttons middle-buttons">
            <button mat-raised-button class="primary" [disabled]="working || !changePassword.valid" (click)="savePassword()">Guardar</button>
          </div>
        </form>

      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="!loading">
      <mat-card-content>
          <div class="subtitle">Seguridad Two-Factor</div>
          <div class="buttons middle-buttons">
            <form [formGroup]="disableOTP" autocomplete="off" *ngIf="user.has_2fa">
              <div class="activated-2fa" >
                <div class="qr-tutorial">
                  
                  <span style="margin-bottom: 30px;"><i class="fa fa-check"></i>Autenticación en dos pasos activada. Rellene los campos para desactivar</span>
                  <mat-form-field appearance="outline">
                    <mat-label>Codigo Authenticator</mat-label>
                    <input matInput placeholder="Codigo Authenticator" autocomplete="off" formControlName="otp" name="otp" minlength="6" maxlength="6" type="tel">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Contraseña Actual</mat-label>
                    <input matInput type="password" placeholder="Contraseña Actual" name="password" autocomplete="off" formControlName="password">
                    <mat-error>
                      Contraseña inválida
                    </mat-error>
                  </mat-form-field>
                  <button mat-raised-button class="primary" (click)="disable2FA()" [disabled]="working || !disableOTP.valid">Desactivar 2FA</button>
                </div>
              </div>
            </form>
            <button mat-raised-button class="primary" (click)="enable2FA()" *ngIf="!qr && !user.has_2fa" [disabled]="working">Habilitar 2FA</button>
            <form [formGroup]="enableOTP" autocomplete="off">
            <div class="qr" *ngIf="qr && !user.has_2fa">
              <div class="qr-tutorial">
                <span style="margin-bottom: 15px;">Por favor escanee este QR con Google Authenticator u otra app. Luego escriba el código a continuación junto a su contraseña.</span>
                <mat-form-field appearance="outline">
                  <mat-label>Codigo Authenticator</mat-label>
                  <input matInput placeholder="Codigo Authenticator" autocomplete="off" formControlName="otp" name="otp" minlength="6" maxlength="6" type="number">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Contraseña Actual</mat-label>
                  <input matInput type="password" placeholder="Contraseña Actual" name="password" autocomplete="off" formControlName="password">
                  <mat-error>
                    Contraseña inválida
                  </mat-error>
                </mat-form-field>
                <button mat-raised-button class="primary" (click)="validate2FA()" [disabled]="working || !enableOTP.valid">Activar 2FA</button>
              </div>
              <div class="code"[innerHTML]="qr"></div>
            </div>
          </form>
          </div>
        
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!loading">
      <mat-card-content>
        <div class="subtitle">Temas</div>
        <div class="themes-container">

          <div *ngFor="let theme of themes" class="theme-button"
          [ngClass]="theme.name"
          [ngStyle]="{
            'background-color': theme.backgroundColor,
            'color': theme.color,
            'border': user?.theme === theme.name ? '3px solid ' + theme.color : '3px solid ' + theme.backgroundColor
          }"
          (click)="updateTheme(theme.name)">
          <mat-icon *ngIf="user?.theme === theme.name">check_circle</mat-icon>
          <span>{{ theme.label }}</span>
        </div>
     
          
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <span class="version">ControlCar v{{version}}</span>
</div>
