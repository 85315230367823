import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-admin-patpass',
  templateUrl: './admin-patpass.component.html',
  styleUrls: ['./admin-patpass.component.scss','../admin-main.common.scss']
})
export class AdminPatpassComponent implements OnInit {
  formSignUP = new UntypedFormGroup({
    name: new UntypedFormControl('',Validators.required),
    first_last_name: new UntypedFormControl('',Validators.required),
    second_last_name: new UntypedFormControl('',Validators.required),
    rut: new UntypedFormControl('',Validators.required),
    max_amount: new UntypedFormControl('',Validators.required),
    phone_number: new UntypedFormControl('',Validators.required),
    mobile_number: new UntypedFormControl('',Validators.required),
    patpass_name: new UntypedFormControl('',Validators.required),
    email: new UntypedFormControl('',Validators.required),

  })
  constructor() { }

  ngOnInit(): void {
  }

}
