<div class="wrapper">
  <div class="header">
    <h3>Seleccionar Locales</h3>
  </div>
  <div class="container">
    <!-- "Select All" Checkbox -->
    <mat-checkbox class="option" 
      (change)="toggleSelectAll($event)" 
      [checked]="areAllSelected()">
      Seleccionar Todos
    </mat-checkbox>

    <!-- List of Location Checkboxes -->
    <ng-container *ngFor="let location of locations">
      <mat-checkbox class="option"
        (change)="toggleLocationSelection(location.id)"
        [checked]="selectedLocations.includes(location.id)">
        {{ location.name }}
      </mat-checkbox>
    </ng-container>
  </div>
</div>
