import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AdditionalsService extends BaseService implements ICRUDService {
  params = ['name', 'price', 'hidden', 'selectors'];

  constructor(public http:HttpClient, userService: UserService) {
    super(http, userService);
  }

  index(value = null, page = 1) {
    return this.http.get(`${this.adminURL}/additionals`, this.getParams({ search: value, page }));
  }

  create(value) {
    return this.http.post(`${this.adminURL}/additionals/`, {
      additional: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  update(id, value) {
    return this.http.put(`${this.adminURL}/additionals/${id}`, {
      additional: this.permitParams(value, ...this.params),
    }, this.getParams());
  }

  destroy(id) {
    return this.http.delete(`${this.adminURL}/additionals/${id}`, this.getParams());
  }

  restore(id) {
    return this.http.delete(`${this.adminURL}/additionals/${id}/restore`, this.getParams());
  }

  import(items = null) {
    return this.http.post(`${this.adminURL}/additionals/import`, { items }, this.getParams());
  }

  swap(ids) {
    return this.http.post(`${this.adminURL}/additionals/swap`, { ids }, this.getParams());
  }
}
