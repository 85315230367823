import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'app/admin/services/admin.service';
import { StatusesService } from 'app/admin/services/statuses.service';
import { Client } from 'app/models/client.model';
import { Status } from 'app/models/status.model';
import { RoleService } from 'app/role/role.service';
import { CrmsDialogComponent } from 'app/shared/common-forms/crms-dialog/crms-dialog.component';
import { uniq } from 'lodash-es';
import { interval, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { OrderDateRangeComponent } from '../../main/order-date-range/order-date-range.component';
import { PaginatedItems } from '../../models/types';
import { CRMService } from '../crm.service';
import { CrmDetailsComponent } from '../crm-details/crm-details.component';

@Component({
  selector: 'app-crmview',
  templateUrl: './crmview.component.html',
  styleUrls: ['./crmview.component.scss'],
})
export class CRMViewComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'client-identifier',
    'client-name',
    'client-email',
    'object-identifier',
    'status-list',
    'events',
  ];

  dataSource: MatTableDataSource<any>;

  statuses: Status[] = [];

  clients: Client[];

  company = this.appService.local$.getValue();

  searchControl: UntypedFormControl = new UntypedFormControl();

  filter: any = this.getFilter();

  page = 1;

  total_pages = 1;

  filters: Record<string, unknown>[] = [{
    name: 'Estado',
    key: 'status_id',
    options: [],
    multi: true,
  }];

  subs: Subscription[] = [];

  public working = false;

  private search: string;

  count = 0;

  private loadingMore = false;

  totalByStatus: Record<number, Record<string, number>> = {};

  constructor(
    private crmService: CRMService,
    private matDialog: MatDialog,
    private statusesService: StatusesService,
    public appService: AdminService,
    public roleService: RoleService,
  ) { }

  ngOnInit() {
    this.appService.pageTitle.set(`${this.appService.getText('crm_for_booking_plural')}`);
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this.statuses.length > 0) {
        this.search = value;
        this.getCrms();
      }
    }));
    this.statusesService.statuses('StatusForCrmForBooking').subscribe((statuses: Status[]) => {
      this.statuses = statuses;
      if (!this.filter && statuses.length > 0) {
        this.filter = this.statuses.filter((status) => status.finish).map((s) => s.id);
        this.saveFilter();
      }
      if (this.statuses.length > 0) {
        this.getCrms();
      }
    });

    this.crmService.getTotalsByStatus().subscribe((value) => {
      const totals = value.body as any[];
      this.totalByStatus = totals.reduce((acc, current) => {
        acc[current.status_id] = current;
        return acc;
      }, {});
    });

    this.getMore();
    this.dataSource = new MatTableDataSource(this.clients);
  }

  statusOn(id) {
    if (this.filter) {
      const index = this.filter.findIndex((s) => parseInt(s, 10) === id);
      return index >= 0;
    }
    return false;
  }

  getStatusCompletion(status_id, client) {
    const current = this.statuses.findIndex((s) => s.id === client.status_id);
    const ask = this.statuses.findIndex((s) => s.id === status_id);
    return current > ask;
  }

  getCrms(loadingMore?) {
    // SCROLL TOP
    if (!loadingMore) {
      this.resetClients();
      this.working = true;
    }
    this.crmService.index({ status_id: this.filter, page: this.page }, this.search)
      .toPromise().then((response: HttpResponse<PaginatedItems>) => {
        if (loadingMore) {
          this.clients = this.clients.concat(response.body.items);
        } else {
          this.clients = response.body.items;
        }
        this.count = response.body.total;
        this.page = response.body.page;
        this.total_pages = response.body.total_pages;
        this.working = false;
        this.loadingMore = false;
        this.dataSource.data = this.clients;
        this.appService.pageTitle.set(
          `${this.appService.getText('crm_for_booking_plural')} (${response.body.total_count})`,
        );
      }).catch((error:HttpErrorResponse) => {
        console.log(error);
        this.working = false;
      });
  }

  resetClients() {
    const element = document.getElementById('clients');
    if (element) {
      element.scrollTop = 0;
    }
    this.page = 1;
    this.total_pages = 1;
    this.count = 0;
  }

  getMore() {
    interval(500).subscribe(() => {
      if (this.page < this.total_pages && !this.loadingMore) {
        const element = document.getElementById('clients');
        if (element) {
          const loaderElement = document.getElementById('load-more');
          if (element.scrollTop + element.clientHeight > element.scrollHeight - loaderElement.clientHeight) {
            this.loadingMore = true;
            this.page += 1;
            this.getCrms(true);
          }
        }
      }
    });
  }

  getFilter() {
    const value = localStorage.getItem('clients_filter');
    let filter = null;
    if (value) {
      filter = uniq(value.split(',')).map((v) => parseInt(v, 0));
    }
    return filter;
  }

  saveFilter() {
    this.filter.push(0);
    this.filter = uniq(this.filter);
    localStorage.setItem('clients_filter', this.filter);
  }

  toggleFilter(id: number) {
    const index = this.filter.findIndex((s) => s === id);
    if (index >= 0) {
      this.filter.splice(index, 1);
    } else {
      this.filter.push(id);
    }
    this.saveFilter();
    this.getCrms();
  }

  openDateRange() {
    this.matDialog.open(OrderDateRangeComponent, {
      data: {
        filters: {

        },
      },
    });
  }

  onAddNewCrm() {
    this.matDialog.open(CrmsDialogComponent, { minWidth: '80%' }).afterClosed().pipe(first()).forEach((selectedCrm) => {
      if (!selectedCrm) return;

      this.crmService.create(
        {
          client_id: selectedCrm.client.id,
          object_id: selectedCrm.object.id,
          object_type: this.company.object,
          recall_data: selectedCrm.recall_data,
        },
      ).subscribe(() => {
        this.getCrms();
      });
    });
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  openClient(crm_id: number) {
    this.matDialog.open(CrmDetailsComponent, { data: { id: crm_id }, minWidth: '80%' });
  }

  clearSearch() {
    this.searchControl.setValue('');
  }
}
