<mat-card  style="margin-bottom: 15px;">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-car fa-2x"></i></div>
    <mat-card-title>Configuración vehículos</mat-card-title>
    <mat-card-subtitle>

    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="vehicleConfigForm">
      <mat-form-field>
        <mat-label>Identificador de vehiculo</mat-label>
        <mat-select placeholder="Identificador de vehiculo" formControlName="object_display" name="object_display" autocomplete="off" multiple="false" required>
          <mat-option *ngFor="let identifier of identifiers" [value]="identifier.id">
            {{identifier.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
<app-admin-custom-fields [service]="fieldsService" model="FieldForVehicle"></app-admin-custom-fields>
<mat-card  style="margin-top: 1em">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-car fa-2x"></i></div>
    <mat-card-title>Vehiculos</mat-card-title>
    <mat-card-subtitle>Administrar vehiculos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="vehiclesService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.identifier}}
      </ng-template>
      <ng-template #formTemplate let-form="form" let-value="value">
        <form [formGroup]="form">
          <app-vehicle-form [model]="value" [parentFormGroup]="form" [parentControl]="form" [hideKms]="true"></app-vehicle-form>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
