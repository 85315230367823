import { NgModule } from '@angular/core';

import { BaseModule } from '../shared/base.module';
import { CommentsAddonsModule } from '../shared/comments/comments.addons.module';
import { CommonFormsModule } from '../shared/common-forms/common-forms.module';
import { ImageUploaderModule } from '../shared/image-uploader/image-uploader.module';
import { WorksAddonsModule } from '../shared/works/works.addons.module';
import { BudgetItemFinderComponent } from './budget-item-finder/budget-item-finder.component';
import { BudgetLinkerComponent } from './budget-linker/budget-linker.component';
import { BudgetProductComponent } from './budget-product/budget-product.component';
import { BudgetViewComponent } from './budget-view/budget-view.component';
import { BudgetsComponent } from './budgets/budgets.component';
import { MarkerPipePipe } from './marker-pipe.pipe';

@NgModule({
  declarations: [
    BudgetItemFinderComponent,
    BudgetLinkerComponent,
    BudgetProductComponent,
    BudgetsComponent,
    BudgetViewComponent,
    MarkerPipePipe,
  ],
  imports: [
    BaseModule,
    CommentsAddonsModule,
    CommonFormsModule,
    ImageUploaderModule,
    WorksAddonsModule,
  ],
  exports: [
    BudgetItemFinderComponent,
    BudgetLinkerComponent,
    BudgetProductComponent,
    BudgetsComponent,
    BudgetViewComponent,
    MarkerPipePipe,
  ],
})
export class BudgetModule { }
