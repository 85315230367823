import {Injectable} from '@angular/core';
import {UserService} from "./user.service";
import {BaseService} from "./_base.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CommentsService extends BaseService{

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  create(value: any) {
    return this.http.post(this.apiURL + '/order_comments', value, this.getOptions());
  }
  update(id: number, value: any) {
    return this.http.put(this.apiURL + '/order_comments/' + id, value, this.getOptions());
  }
}
