import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldsService } from 'app/admin/services/fields.service';
import { debounceTime } from 'rxjs/operators';

import { RoleService } from '../../../role/role.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-admin-order-fields',
  templateUrl: './admin-order-fields.component.html',
  styleUrls: ['./admin-order-fields.component.scss', '../admin-main.common.scss'],
})
export class AdminOrderFieldsComponent implements OnInit {
  roles = [];

  companyForm = this.fb.group({
    name: [{ value: '', disabled: true }],
    preferences: this.fb.group({
      order_legend: '',
      order_min_pictures: 4,
      require_signature: true,
      require_finish_date: false,
      autocomplete_order_values: false,
    }),
  });

  formRequired = new UntypedFormGroup({
    client: new UntypedFormGroup({
      name: new UntypedFormControl(true),
      address: new UntypedFormControl(true),
      city: new UntypedFormControl(true),
      phone: new UntypedFormControl(true),
    }),
    vehicle: new UntypedFormGroup({
      chasis: new UntypedFormControl(true),
      color: new UntypedFormControl(true),
    }),
  });

  constructor(
    private fb: UntypedFormBuilder,
    public fieldsService: FieldsService,
    private companyService: AdminService,
    private roleService: RoleService,
  ) { }

  ngOnInit() {
    this.companyService.setupApp().toPromise().then((response:any) => {
      this.companyForm.patchValue(response.body);
      this.formRequired.patchValue(response.body.preferences.forms_required);
    }).catch((error) => {
      console.log(error);
    });
    this.roleService.simple_index().toPromise().then((value: HttpResponse<any[]>) => {
      this.roles = value.body;
    });
    this.formRequired.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.companyService.updateCompany({ preferences: { forms_required: value } }).toPromise().then((response) => {
      });
    });
    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value) => {
      if (this.companyForm.valid) {
        this.companyService.updateCompany(value).toPromise().then((response) => {
        });
      }
    });
  }
}
