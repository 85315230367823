import { HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'app/models/client.model';
import { ObjectModel } from 'app/models/object.model';
import { checkRut, formatIdentifier } from 'app/utils/client-utils';
import { cloneDeep } from 'lodash-es';
import { interval, of, Subscription } from 'rxjs';
import { catchError, debounceTime, first } from 'rxjs/operators';

import { AdminService } from '../../../admin/services/admin.service';
import { BookingService } from '../../../services/booking.service';
import { ObjectsDialogComponent } from '../objects-dialog/objects-dialog.component';
import { VehiclesDialogComponent } from '../vehicles-dialog/vehicles-dialog.component';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnInit, OnDestroy {
  @Input() bookingMode = false;

  @Input() clientMode = false;

  @Input() client: Client;

  @Input() parentFormGroup: FormGroup;

  @Output() vehicleSelected = new EventEmitter();

  @Input() outlinemode: boolean = false;

  comunas: string[];

  country;

  formBooking = {
    name: true,
    phone: true,
    city: true,
    address: true,
    custom_fields: {},
  };

  formRequired;

  object;

  rutFindObs: Subscription;

  ruts: string[] = [];

  subs = [];

  @ViewChildren(MatAutocompleteTrigger) autoTriggers: QueryList<MatAutocompleteTrigger>;

  constructor(
    private bookingService: BookingService,
    private matDialog: MatDialog,
    public companyService: AdminService,
  ) {

  }

  ngOnInit() {
    this.country = this.companyService.local$.getValue().country;
    this.object = this.companyService.local$.getValue().object;
    this.formRequired = this.companyService.local$.getValue().preferences.forms_required.client;
    if (this.companyService.local$.getValue().preferences.forms_booking) {
      this.formBooking = this.companyService.local$.getValue().preferences.forms_booking.client;
    }

    if (this.country.code === 'cl') {
      this.parentFormGroup.controls.rut.setValidators(checkRut);
    }

    this.parentFormGroup.controls.rut.setValue(
      formatIdentifier(this.parentFormGroup.get('rut').value, this.country.code),
      { emitEvent: false },
    );

    this.parentFormGroup.controls.rut.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => { this.searchRut(value); });

    this.subs.push(
      this.parentFormGroup.controls.city.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => {
          this.filterLocations(value);
        }),
    );
  }

  searchRut(value: string) {
    if (!value || this.clientMode) return;

    this.bookingService.searchRut({ rut: value }).pipe(
      catchError((error) => {
        console.error(error);
        this.ruts = error.error;
        this.parentFormGroup.controls.id.setValue(null, { emitEvent: false });
        return of(null);
      }),
    ).subscribe((response: HttpResponse<Client>) => {
      if (!response) return;

      const formattedIdentifier = formatIdentifier(value, this.country.code);
      this.parentFormGroup.patchValue({ ...response.body, rut: formattedIdentifier }, { emitEvent: false });

      this.closeAutoCompletes();
      if (this.object === 'Vehicle') {
        this.showVehicles(response.body.vehicles);
      } else if (this.object === 'Custom') {
        this.showObjects(response.body.objects);
      }
    });
  }

  closeAutoCompletes() {
    this.autoTriggers.forEach((i) => i.closePanel());
  }

  filterLocations(value: string) {
    if (!value) {
      this.comunas = [];
      return;
    }

    if (this.rutFindObs) {
      this.rutFindObs.unsubscribe();
      this.rutFindObs = null;
    }

    if (value.length >= 3) {
      this.rutFindObs = interval(200).subscribe(() => {
        this.comunas = cloneDeep(this.country.places.map((s) => s.name))
          .filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        this.rutFindObs.unsubscribe();
        this.rutFindObs = null;
      });
    }
  }

  showVehicles(vehicles: ObjectModel[]) {
    if (vehicles && vehicles.length > 0 && !this.clientMode) {
      this.matDialog.open(VehiclesDialogComponent, { data: vehicles })
        .afterClosed().pipe(
          first(),
        )
        .forEach((vehicle) => {
          if (!vehicle) return;

          this.vehicleSelected.emit(vehicle || {});
          this.closeAutoCompletes();
          const ele = document.getElementById('vehicle-form');
          if (ele) {
            ele.scrollIntoView();
          }
        });
    }
  }

  showObjects(objects: ObjectModel[]) {
    if (objects && objects.length > 0 && !this.clientMode) {
      this.matDialog.open(ObjectsDialogComponent, { data: objects })
        .afterClosed().pipe(
          first(),
        )
        .forEach((object) => {
          if (!object) return;

          Object.assign(object, { custom_fields: JSON.parse(object.custom_fields) });
          this.vehicleSelected.emit(object || {});
          this.closeAutoCompletes();
          const ele = document.getElementById('object-form');
          if (ele) {
            ele.scrollIntoView();
          }
        });
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
