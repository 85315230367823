import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {orderBy} from "lodash-es";

@Injectable({
  providedIn: "root"
})
export class SortService {
  public innited = false;
  constructor() {
    this.setupDB();
  }

  public columnSortedSource = new Subject<ColumnSortedEvent>();
  private db: IDBDatabase;
  columnSorted$ = this.columnSortedSource.asObservable();

  columnSorted(event: ColumnSortedEvent) {
    if(this.db){
      this.db.transaction('sort','readwrite').objectStore('sort').put({id: event.key, ...event})
    }
    this.columnSortedSource.next(event);
  }

  setupDB(){
    const request = indexedDB.open("controlcar");
    request.onsuccess = () => {
      this.db = request.result;
    };
    request.onerror = ev => {
      console.log(ev);
    };
  }
  getDefault(id){
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("controlcar")

      request.onsuccess = () => {
        this.db = request.result;
        const query = this.db.transaction('sort','readonly').objectStore('sort').get(id);
        query.onsuccess = ev => {
          resolve(query.result);
        };
        query.onerror = () => {
          reject(query.error);
        };
      };
      request.onerror = ev => {
        console.log(ev);
      };
    });
  }
  order(table,event){
    if(event){
      return orderBy(table,(e)=> this.byString(e,event.sortColumn),[event.sortDirection]);
    }else{
      return table;
    }
  }
  byString(o:any|string, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
        if(isNaN(o) && o.charCodeAt(0)===36){
          o = parseFloat(o.match(/\d+/g).join(''));
        }else if(!isNaN(o)){
          o = parseFloat(o);
        }
      } else {
        return;
      }
    }
    return o;
  }

}
export interface ColumnSortedEvent {
  sortColumn: string;
  sortDirection: string;
  key: string;
}
