import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'wpl-text',
  templateUrl: './text-form.component.html',
  styleUrls: ['./text-form.component.scss']
})
export class TextFormComponent implements OnInit, OnDestroy {
  @Input() parentForm:UntypedFormGroup;
  @Input() value:any = {};
  @Input() disabled = false;
  @Input() clientMode = false;
  key = 'custom_params';
  working = false;
  form:UntypedFormGroup = new UntypedFormGroup({
    comment: new UntypedFormControl({disabled: this.disabled, value: null}, Validators.required)
  });
  constructor(
  ) {
  }

  ngOnInit() {
    Promise.resolve(null).then(() => {
      this.form = new UntypedFormGroup({
        comment: new UntypedFormControl({disabled: this.disabled, value: null}, Validators.required)
      });
      this.form.patchValue(this.value || {});
      this.parentForm.addControl(this.key, this.form);
    });
  }
  ngOnDestroy(){
    this.parentForm.removeControl(this.key);
  }

}
