import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-choice-dialog',
  templateUrl: './choice-dialog.component.html',
  styleUrls: ['./choice-dialog.component.scss'],
})
export class ChoiceDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }
}
