import {NgModule} from '@angular/core';
import {ChartComponent} from './chart/chart.component';
import {ChartDetailsComponent} from './chart-details/chart-details.component';
import { ChartCardComponent } from './chart-card/chart-card.component';
import { ChartFiltersComponent } from './chart-filters/chart-filters.component';
import {AnalyticsComponent} from "./analytics.component";
import {BaseModule} from "../shared/base.module";
import { WorkModule } from 'app/work/work.module';

@NgModule({
  imports: [
    BaseModule,
    WorkModule,
    ChartComponent,
    ChartCardComponent,
    ChartFiltersComponent,
    ChartDetailsComponent
  ],
  declarations: [
    AnalyticsComponent,
  ],
  exports: [
    AnalyticsComponent,
  ]
})
export class AnalyticsModule { }
