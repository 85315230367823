import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import {UserService} from "./user.service";
import {Aditional} from "../models/types";
import {BaseService} from "./_base.service";

@Injectable({
  providedIn: 'root'
})
export class PDFService extends BaseService{

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  getOrder(id,name){
    const url = this.apiURL + '/pdf/'+id+'/order?auth_token='+this.userService.token;
    var link = document.createElement('a');
    link.href = url;
    link.download = name+".pdf";
    link.target = "_blank"
    link.click();
  }
  getBudget(id,name){
    const url = this.apiURL + '/pdf/'+id+'/budget?auth_token='+this.userService.token;
    var link = document.createElement('a');
    link.href = url;
    link.download = name+".pdf";
    link.target = "_blank"
    link.click();
  }
}
