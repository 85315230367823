import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckIn } from 'app/models/checkin.model';
import { Observable } from 'rxjs';

import { BaseService } from './_base.service';
import { UserService } from './user.service';

@Injectable()
export class CheckInService extends BaseService {
  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }

  create(value: any) {
    return this.http.post(`${this.apiURL}/check_ins`, value, this.getOptions());
  }

  update(id: number, value: { check_in: Partial<CheckIn> }): Observable<HttpResponse<CheckIn>> {
    return this.http.put<CheckIn>(`${this.apiURL}/check_ins/${id}`, value, this.getOptions());
  }

  searchPlate(identifier) {
    return this.http.post(`${this.apiURL}/check_ins/identifier`, identifier, this.getOptions());
  }

  search(identifier) {
    return this.http.post(`${this.apiURL}/check_ins/search`, {query: identifier}, this.getOptions());
  }
  all(filters?, search?) {
    const params: Record<string, any> = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter && filters[filter]) {
          params[filter] = filters[filter].toString();
        }
      });
    }
    if (search) {
      params.query = search;
    }
    return this.http.get(`${this.apiURL}/check_ins`, this.getParams(params));
  }

  allPost(filters?, search?) {
    const params: Record<string, any> = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter) {
          params[filter] = filters[filter];
        }
      });
    }
    if (search) {
      params.query = search;
    }
    return this.http.post(`${this.apiURL}/check_ins/orders`, params, this.getParams());
  }

  get(id: number) {
    return this.http.get(`${this.apiURL}/check_ins/${id}`, this.getParams());
  }

  getChanges(id: number) {
    return this.http.get(`${this.apiURL}/check_ins/${id}/changes`, this.getParams());
  }

  generatePayment(id: number) {
    return this.http.post(`${this.apiURL}/check_ins/${id}/payment`, {}, this.getParams());
  }

  getPayment(order_id, id) {
    return this.http.post(`${this.apiURL}/check_ins/${id}/payment`, { payment_id: id }, this.getParams());
  }

  analytics() {
    return this.http.get(`${this.apiURL}/check_ins/analytics`, this.getParams());
  }

  totalsByStatus() {
    return this.http.get(`${this.apiURL}/check_ins/totals_by_status`, this.getParams());
  }
}
