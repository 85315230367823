import {NgModule} from '@angular/core';
import {DercoMailsComponent} from './derco-mails.component';
import {DercoMailsDialogComponent} from './derco-mails-dialog/derco-mails-dialog.component';
import {BaseModule} from "../../shared/base.module";
import { DercoMailComponent } from './derco-mail/derco-mail.component';

@NgModule({
    imports: [
        BaseModule
    ],
    declarations: [DercoMailsComponent, DercoMailsDialogComponent, DercoMailComponent],
    exports: [DercoMailsComponent, DercoMailsDialogComponent, DercoMailComponent]
})
export class DercoMailsModule { }
