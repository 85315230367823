import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrmForBooking } from 'app/models/crm.model';
import { PaginatedItems } from 'app/models/types';
import { map, Observable } from 'rxjs';

import { BaseService } from '../services/_base.service';

@Injectable({
  providedIn: 'root',
})
export class CRMService extends BaseService {
  index(filters?, search?: string) {
    const params: Record<string, unknown> = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter) {
          params[filter] = filters[filter].toString();
        }
      });
    }
    if (search) {
      params.search = search;
    }
    return this.http.get(`${this.apiURL}/crms`, this.getParams(params));
  }

  suggestedToCreate(
    searchValue?: string,
    page?: number,
    page_size?: number,
    sort?: string,
  ) : Observable<PaginatedItems<CrmForBooking>> {
    return this.http.get<PaginatedItems<CrmForBooking>>(
      `${this.apiURL}/crms/suggested_to_create`,
      this.getParams({
        search: searchValue,
        page,
        page_size,
        sort,
      }),
    )
      .pipe(
        map((response: HttpResponse<PaginatedItems<CrmForBooking>>) => response.body),
      );
  }

  create(body: { client_id: number, object_id: number, object_type: string, recall_data: Record<string, unknown> }) {
    return this.http.post(`${this.apiURL}/crms`, { crm: body }, this.getParams({}));
  }

  get(id) {
    return this.http.get<CrmForBooking>(`${this.apiURL}/crms/${id}`, this.getParams({}));
  }

  getData(params: { id?: number, crm_token?: string }) {
    return this.http.get<CrmForBooking>(`${this.apiURL}/crms/get_data`, this.getParams(params));
  }

  update(id, body) {
    return this.http.put(`${this.apiURL}/clients/${id}`, { client: body }, this.getParams({}));
  }

  getTotalsByStatus() {
    return this.http.get(`${this.apiURL}/crms/totals_by_status`, this.getParams({}));
  }

  allPost(filters?, search?) {
    const params: Record<string, unknown> = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter) {
          params[filter] = filters[filter].toString();
        }
      });
    }
    if (search) {
      params.query = search;
    }
    return this.http.post(`${this.apiURL}/clients/all`, params, this.getParams());
  }
}
