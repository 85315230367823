import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FieldsService } from 'app/admin/services/fields.service';
import { RoleService } from 'app/role/role.service';
import { debounceTime } from 'rxjs/operators';

import { BookingService } from '../../../services/booking.service';
import { ClientFormComponent } from '../../../shared/common-forms/client-form/client-form.component';
import { AdminService } from '../../services/admin.service';
import { ClientsService } from '../../services/clients.service';
import { ListsService } from '../../services/lists.service';
import { ListsItemsService } from '../../services/lists-items.service';

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.scss', '../admin-main.common.scss'],
})
export class AdminClientsComponent extends ClientFormComponent implements OnInit {
  form = this.fb.group({
    rut: '',
    name: '',
    address: '',
    city: '',
    phone: '',
    email: '',
    custom_fields: this.fb.group({}),
  });

  custom = [];

  roles = [];

  list = [];

  formRequired = new UntypedFormGroup({
    client: new UntypedFormGroup({
      name: new UntypedFormControl(true),
      address: new UntypedFormControl(true),
      city: new UntypedFormControl(true),
      phone: new UntypedFormControl(true),
    }),
    vehicle: new UntypedFormGroup({
      chasis: new UntypedFormControl(true),
      color: new UntypedFormControl(true),
    }),
  });

  formShow = new UntypedFormGroup({
    client: new UntypedFormGroup({
      name: new UntypedFormControl(true),
      address: new UntypedFormControl(true),
      city: new UntypedFormControl(true),
      phone: new UntypedFormControl(true),
    }),
    vehicle: new UntypedFormGroup({
      chasis: new UntypedFormControl(true),
      color: new UntypedFormControl(true),
    }),
  });

  constructor(
    public clientsService: ClientsService,
    bookingService: BookingService,
    matDialog: MatDialog,
    companyService: AdminService,
    public roleService: RoleService,
    public fieldsService: FieldsService,
    public listsService: ListsService,
    public listsItemsService: ListsItemsService,
    public router: Router,
    private fb: UntypedFormBuilder,
  ) {
    super(bookingService, matDialog, companyService);
  }

  ngOnInit() {
    this.companyService.setupApp().toPromise().then((response:any) => {
      this.formShow.patchValue(response.body.preferences.forms_booking);
      this.formRequired.patchValue(response.body.preferences.forms_required);
    }).catch((error) => {
      console.log(error);
    });
    this.formRequired.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.companyService.updateCompany({ preferences: { forms_required: value } }).toPromise().then((response) => {
      });
    });
    this.formShow.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.companyService.updateCompany({ preferences: { forms_booking: value } }).toPromise().then((response) => {
      });
    });
    this.roleService.simple_index().toPromise().then((value: HttpResponse<any[]>) => {
      this.roles = value.body;
    });
    this.fieldsService.fields('FieldForClient').subscribe(
      (fields) => {
        this.custom = fields;
        this.custom.forEach((field) => {
          (
          <FormGroup> this.form.controls.custom_fields)
            .addControl(field.key, new FormControl('', { nonNullable: field.required }));
        });
      },
    );
    this.country = this.companyService.local$.getValue().country;
  }
}
