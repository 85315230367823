import {NgModule} from '@angular/core';
import {WorkModule} from "../work/work.module";
import {BudgetModule} from "../budget/budget.module";
import {AnalyticsModule} from "../analytics/analytics.module";
import {BaseModule} from "./base.module";
import {MainModule} from "../main/main.module";

@NgModule({
  imports: [
    BaseModule,
    WorkModule,
    BudgetModule,
    MainModule,
    AnalyticsModule,
  ],
  exports: [
    // MODULES
    WorkModule,
    BudgetModule,
    MainModule,
    AnalyticsModule
  ]
})
export class SharedModule { }
