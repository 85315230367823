export interface StatusType {
  value: string;
  viewValue: string;
  tooltipMessage: string | null;
  order: number;
}

const defaultStatusTypes: StatusType[] = [
  {
    value: 'initial',
    viewValue: 'Inicial',
    tooltipMessage: null,
    order: 0,
  },
  {
    value: 'normal',
    viewValue: 'Normal',
    tooltipMessage: null,
    order: 1,
  },
  {
    value: 'failed',
    viewValue: 'Fallido',
    tooltipMessage: null,
    order: 2,
  },
  {
    value: 'locked',
    viewValue: 'Bloqueado',
    tooltipMessage: null,
    order: 3,
  },
  {
    value: 'approval',
    viewValue: 'Pendiente de Aprobación',
    tooltipMessage: null,
    order: 3.5,
  },
  {
    value: 'completed',
    viewValue: 'Completado',
    tooltipMessage: null,
    order: 4,
  },
  {
    value: 'finish',
    viewValue: 'Final',
    tooltipMessage: null,
    order: 5,
  },
];

const translations = {
  StatusForOrder: {
    initial: 'Todas las ordenes de trabajos iniciaran en este estado',
    failed: 'Se considerará la orden como fallida en este estado',
    approval: 'Se considerará la orden como pendiente para aprobación',
    completed: 'Se considerará que los trabajos han sido finalizados en este estado',
    finish: 'Se considerará la orden finalizada en este estado',
  },
  StatusForBudget: {
    initial: 'Todas los presupuestos iniciaran en este estado',
    completed: 'Se considerará el presupuesto completado en este estado',
    approval: 'Se considerará el presupuesto como pendiente para aprobación',
    finish: 'Se considerará el presupuesto finalizado en este estado',
  },
  StatusForCrmForBooking: {
    ignore: 'Las gestiones de clientes en este estado no serán regestionados',
  },
  default: {
    initial: 'Todas las gestiones iniciarán en este estado',
    failed: 'Se considerará que la gestión ha fallado en este estado',
    locked: 'La gestión no es editable en este estado',
    approval: 'Se considerará la gestión como pendiente para aprobación',
    completed: 'Se considerará la gestión completada en este estado',
    finish: 'Se considerará la gestión finalizada en este estado',
  },
};

function translate(type: string, statusTypes: StatusType[]): StatusType[] {
  return statusTypes.map((statusType) => {
    const translation = translations[type]?.[statusType.value] || translations.default[statusType.value];
    return {
      ...statusType,
      tooltipMessage: translation || statusType.tooltipMessage,
    };
  });
}

export const statusTypesMap: Record<string, StatusType[]> = {
  StatusForCrmForBooking: [
    ...defaultStatusTypes,
    {
      value: 'ignore',
      viewValue: 'No Reprocesar',
      tooltipMessage: null,
      order: 1.5,
    },
  ],
};

export function getStatusesTypesFor(type: string): StatusType[] {
  const statusTypes = statusTypesMap[type]?.sort((a, b) => a.order - b.order) || defaultStatusTypes;
  return translate(type, statusTypes);
}

export const getTransitionModelFor = (type: string): string => `StatusTransitionFor${type.split('StatusFor')[1]}`;

export const budgetActions = [
  {
    id: 'edit_photos',
    name: 'Editar Fotos',
  },
  {
    id: 'edit_markers',
    name: 'Editar Marcadores',
  },
  {
    id: 'edit_products',
    name: 'Editar Productos',
  },
  {
    id: 'edit_fields',
    name: 'Editar Campos Custom',
  },
  {
    id: 'approve_products',
    name: 'Aprobar Productos',
  },
];
