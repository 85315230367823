import {
  FormArray, FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';

import { toInventoryActionProductForm } from './inventory-action-product';

export interface InventoryAction {
  id: number;
  change_log: Partial<InventoryAction>[];
  company_id: number;
  completed: boolean;
  correlative_id: number;
  created_at: Date;
  custom_fields: Record<string, unknown>;
  document_id: number | null;
  document_type: string | null;
  edited: boolean;
  estimated_completion_date: Date;
  failed: boolean;
  finish: boolean;
  initial: boolean;
  inventory_action_products: InventoryActionProduct[];
  is_valid: boolean;
  last_edit_action: InventoryAction;
  locked: boolean;
  pictures: any[];
  normal: boolean;
  status_id: number;
  tag_id?: number;
  target_document_id: number | null;
  target_document_type: string | null;
  target_warehouse_id: number | null;
  type: string;
  updated_at: Date;
  user: InventoryActionUser;
  user_id: number;
  warehouse_id: number;
  works: Record<string, unknown>[];
}

interface InventoryActionProduct {
  id: number;
  amount: number;
  created_at: Date;
  inventory_action_id: number;
  product_id: number;
  quantity: number;
  updated_at: Date;
  warehouse_id: null;
}

interface InventoryActionUser {
  id: number;
  name: string;
}

export const allInventoryActionTypes = [
  'InventoryActionForStock',
  'InventoryActionForLoss',
  'InventoryActionForMovement',
  'InventoryActionForEdit',
  'InventoryActionForSet',
  'InventoryActionForConsume',
];

export function generateInventoryActionFormGroup({
  id = null,
  comment = null,
  document_type = null,
  document_id = null,
  type = 'InventoryActionForConsume',
  tag_id = null,
  warehouse_id = null,
  estimated_completion_date = null,
  inventory_action_products = [],
  pictures = [],
  markers = [],
  document = {},
  status = {},
} = {}) {
  const fb = new FormBuilder();
  return fb.group(
    {
      id: fb.control(id),
      comment: fb.control(comment),
      document_type: fb.control(document_type, [Validators.required]),
      document_id: fb.control(document_id, [Validators.required]),
      estimated_completion_date: fb.control(estimated_completion_date, [Validators.required]),
      tag_id: fb.control(tag_id, [Validators.required]),
      type: fb.control(type, [Validators.required]),
      warehouse_id: fb.control(warehouse_id, [Validators.required]),
      inventory_action_products: fb.array(
        inventory_action_products.map((product) => fb.group(toInventoryActionProductForm(product))),
      ),
      pictures: fb.array(
        pictures.map((picture) => fb.group(picture)),
      ),
      markers: fb.array(
        markers.map((marker) => fb.group(marker)),
      ),
      document: fb.control(document),
      status: fb.control(status),
    },
  );
}

export function calculateInventoryActionTotal(inventoryAction: InventoryAction) {
  return inventoryAction?.inventory_action_products?.reduce(
    (acc, product) => acc + product.amount * product.quantity,
    0,
  ) || 0;
}
