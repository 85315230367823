import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ColumnSortedEvent, SortService} from "./sort.service";
import {Subscription} from "rxjs";

@Directive({
  selector: '[sortable-table]'
})
export class SortableTableDirective implements OnInit, OnDestroy{

  constructor(private sortService: SortService) {}
  @Input('sortable-table')
  table = [];
  @Input('sortable-table-key')
  key = 'key';
  @Output()
  sorted = new EventEmitter();

  private columnSortedSubscription: Subscription;

  ngOnInit() {
    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
      if(this.sortService.innited){
        this.sort(event);
      }
    });
    this.getDefault();
  }
  getDefault(){
    this.sortService.getDefault(this.key).then((value:any) => {
      if(value){
        this.sortService.columnSortedSource.next(value);
      }
      this.sortService.innited = true;
    }).catch(reason => {
      console.log(reason);
    });
  }
  sort(event:ColumnSortedEvent){
    if(event.key === this.key){
      this.sorted.emit(event);
    }
  }
  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }

}
