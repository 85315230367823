import {Injectable} from '@angular/core';
import {FormatColumnPipe} from "../format-column.pipe";
import {cloneDeep} from "lodash-es";
import {AdminService} from "../../admin/services/admin.service";
import { WorkService } from '../work.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BookingService } from 'app/services/booking.service';

@Injectable({
  providedIn: 'root'
})
export class WorkColumnsService {
  notPermittedValues = ['roles_id','work_id','requirement_id',
    'requester_id','order_id', "type", "avg_time", "order_avg_sum",
    "order_current_sum",'correlative_id','user_name','created_at','status', 'work_order',
    'user_id', 'status_ids','started_at','status_id', 'work_base_time','work_base_std_dev',
    'work_user_time','user_timer', 'std_dev', 'base_time', 'base_std_dev',
  ];
  public columns:any[];
  DEFAULT = [
    {
      id: 'pending',
      values: ['identifier','requirement_name','work_name']
    },
    {
      id: 'requests',
      values: ['identifier','requirement_name','requester_name','work_name']
    }
  ];
  private db: IDBDatabase;
  constructor(private company:AdminService, private bookingService:BookingService, private sanitizer:DomSanitizer) {
    this.setupDB();
  }
  setupDB(){
    return new Promise((resolve, reject) => {
      if(this.columns){
        resolve(true);
      }else{
        const request = indexedDB.open("controlcar",5);

        request.onupgradeneeded = () => {
          // The database did not previously exist, so create object stores and indexes.
          const db = request.result;
          if(!db.objectStoreNames.contains('sort')){
            db.createObjectStore("sort", {keyPath: "id"});
          }
          if(!db.objectStoreNames.contains('columns')){
            db.createObjectStore("columns", {keyPath: "id"});
          }
        };

        request.onsuccess = () => {
          this.db = request.result;
          const getAll = this.db.transaction('columns','readonly').objectStore('columns').getAll();
          getAll.onsuccess = () => {
            if(getAll.result.length === 0){
              this.DEFAULT.forEach(value => {
                this.db.transaction('columns','readwrite').objectStore('columns').add(value);
              });
              this.columns = cloneDeep(this.DEFAULT);
            }else{
              this.columns = getAll.result;
            }
            resolve(true);
          };
        };
        request.onerror = () => {
          this.columns = cloneDeep(this.DEFAULT);
          reject();
        };
      }
    });
  }
  setColumns(id,body){
    return new Promise((resolve, reject) => {
      this.setupDB().then(value => {
        resolve(this.transformKeys(id,Object.keys(body[0])));
      }).catch(()=>{
        reject();
      });
    });
  }
  transformKeys(id,keys:any[],filter=true){
    return keys.filter(value => this.notPermittedValues.indexOf(value)<0 || !filter)
      .map(value => [value,this.getValue(id,value),this.transformName(value)]);
  }
  getValue(id,key){
    if(this.columns){
      return this.columns.find(value1 => value1.id===id).values.findIndex(v=>v===key)>=0;
    }else{
      return false;
    }
  }
  saveColumns(id,columns){
    const object = {
      id: id, values: columns.filter(value => value[1]).map(value => value[0])
    };
    const query = this.db.transaction('columns','readwrite').objectStore('columns').put(object);
    query.onsuccess = () => {
      const index = this.columns.findIndex(value1 => value1.id===id);
      this.columns[index] = object;
    };
  }
  toExcel(data:any[],columns){
    let array = cloneDeep(data);
    const columnList = (['correlative_id','user_name'].concat(columns.filter(c => c[1]).map(c => c[0]))).concat(['created_at']);
    array = array.map(row => {
      const json = {};
      columnList.forEach(key => {
        json[this.transformName(key)] = new FormatColumnPipe(this.company,this.bookingService,this.sanitizer).transform(row[key],key,true);
      });
      return json;
    });
    return array;
  }
  transformName(id:string){
    switch (id) {
      case 'id':
        return 'Id';
      case 'budget_id':
        return "ID Presupuesto";
      case 'budget_status':
        return "Estado Presupuesto";
      case 'user_name':
        return 'Asignado';
      case 'name':
        return `${this.company.getText('comment')}`;
      case 'requester_name':
        return 'Requerido por';
      case 'status_name':
        return 'Estado';
      case 'correlative_id':
        return 'Id Orden';
      case 'created_at':
        return 'Fecha Creación';
      case 'deleted_at':
        return 'Fecha Rechazo';
      case 'requirement_name':
        return `${this.company.getText('requirement')}`;
      case 'work_name':
        return 'Trabajo';
      case 'identifier':
        return `${this.company.getText('object_identifier')}`;
      case 'check_in_time':
        return `Fecha de ${this.company.getText('checkin')}`
      case 'total_time':
        return 'Tiempo Total';
      case 'brand_name':
        return 'Marca';
      case 'brand_model_name':
        return 'Modelo';
      case 'checked_in':
        return '¿Recepcionado?'
      case 'color':
        return 'Color';
      case 'client_name':
        return `${this.company.getText('client')}`;
      case 'chasis':
        return 'VIN';
      case 'base_neto':
        return 'Base Neto';
      case 'neto':
        return 'Neto';
      case 'total':
        return 'Valor(+TAX)';
      case 'finish_date':
        return 'Finalización Agendada';
      case 'recepcionist_name':
        return 'Recepcionista';
      case 'revisor_name':
        return 'Revisor';
      case 'current_time':
        return 'Tiempo Trabajo (horas)';
      case 'current_days':
        return 'Días Trabajo';
      case 'rejector_name':
        return 'Rechazado por';
      case 'reason':
        return 'Razón Rechazo';
      case 'item_type':
        return 'Objeto';
      case 'item_id':
          return 'ID de Objeto';
      case 'parent_item_type':
        return 'Objeto Padre';
      case 'parent_item_id':
          return 'ID de Objeto Padre';
      default:
        const new_value = id.replace(/\'/g,'');
        return new_value;
    }
  }
}
