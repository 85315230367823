import {
  Component, Inject, Input, OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoleSelectorComponent } from 'app/role/role-selector/role-selector.component';

@Component({
  selector: 'app-hidden-fields',
  templateUrl: './hidden-fields.component.html',
  styleUrls: ['./hidden-fields.component.scss'],
})
export class HiddenFieldsComponent implements OnInit {
  @Input() isChecked: (additional: any) => boolean;

  @Input() onChecked: (event: any, additional: any) => void;

  @Input() multiple = false;

  fields: any[] = [];

  search = '';

  constructor(
    private matDialogRef: MatDialogRef<RoleSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    if (data.multiple) {
      this.multiple = data.multiple;
      this.isChecked = data.isChecked;
      this.onChecked = data.onChecked;
    }
  }

  ngOnInit() {
    this.fields = this.data.fields;
  }

  setField(field) {
    if (this.multiple) {
      this.checkField(field);
    } else {
      this.matDialogRef.close(field);
    }
  }

  checkField(field) {
    if (this.multiple) {
      const checked = !this.isChecked(field);
      this.onChecked({ checked }, field);
    }
  }

  close() {
    this.matDialogRef.close();
  }

  searchFields() {
    if (this.multiple) {
      this.fields = this.data.fields.filter((f) => f.name.toLowerCase().includes(this.search.toLowerCase()));
      return;
    }
    this.fields = this.data.fields.filter((f) => f.title.toLowerCase().includes(this.search.toLowerCase()));
  }
}
