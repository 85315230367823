import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from 'app/admin/services/admin.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-local-selector',
  templateUrl: './local-selector.component.html',
  styleUrls: ['./local-selector.component.scss']
})
export class LocalSelectorComponent implements OnInit, OnDestroy {
  locations: any[] = [];
  selectedLocations: number[] = [];

  constructor(
    private adminService: AdminService, 
    private bottomSheetRef: MatBottomSheetRef<LocalSelectorComponent> 
  ) {}

  ngOnInit() {
    this.locations = this.adminService.getLocations();
    
    const storedLocations = localStorage.getItem('locations');
    this.selectedLocations = storedLocations ? storedLocations.split(',').map(id => parseInt(id, 10)) : [];
  }

  toggleLocationSelection(locationId: number): void {
    const index = this.selectedLocations.indexOf(locationId);
    if (index === -1) {
      this.selectedLocations.push(locationId); 
    } else {
      this.selectedLocations.splice(index, 1);
    }
  }

  toggleSelectAll(event: any): void {
    if (event.checked) {
      // Select all locations
      this.selectedLocations = this.locations.map(location => location.id);
    } else {
      // Deselect all locations
      this.selectedLocations = [];
    }
  }

  areAllSelected(): boolean {
    return this.selectedLocations.length === this.locations.length && this.locations.length > 0;
  }

  ngOnDestroy() {
    localStorage.setItem('locations', this.selectedLocations.join(','));
    this.bottomSheetRef.dismiss();
  }
}
