import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminService } from 'app/admin/services/admin.service';
import { Warehouse } from 'app/models/warehouse.model';
import { WarehousesService } from 'app/services/warehouses.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  loading: boolean;

  warehouses: Warehouse[];

  warehouseFilter = new FormGroup({
    'warehouseIds[]': new FormControl([]),
  });

  constructor(
    public companyService: AdminService,
    public warehousesService: WarehousesService,
  ) {}

  ngOnInit(): void {
    this.companyService.pageTitle.set(this.companyService.getText('inventory_action_plural'));
    this.loading = true;
    this.warehousesService.index().subscribe((warehouses) => {
      this.warehouses = warehouses;
      this.warehouseFilter.get('warehouseIds[]').setValue(warehouses.slice(0, 1).map((warehouse) => warehouse.id));
      this.loading = false;
    });
    this.warehouseFilter.get('warehouseIds[]').valueChanges.subscribe((warehouseIds) => {
      this.warehousesService
        .selectedWarehouses
        .next(this.warehouses.filter((warehouse) => warehouseIds.includes(warehouse.id)));
    });
  }
}
