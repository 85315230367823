import {
  Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../../main/confirm-dialog/confirm-dialog.component';
import { CameraComponent } from './camera/camera.component';
import { CameraService } from './camera/camera.service';
import { GalleryComponent } from './gallery/gallery.component';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
  @Input() photos = [];

  @Input() gallery = [];

  @Input() readonly = false;

  @Input() scrollTarget;

  @Input() small = false;

  @Input() placeholders = [];

  @Input() allowUploadPhoto = true;

  @Input() allowUploadFile = true;

  @Input() allowAdvanceUpload = true;

  @Output() uploaded = new EventEmitter();

  uploadedImages = [];

  showAdvanced = false;

  selectedImage = null;

  blobs = [];

  dragged = null;

  uploadingFiles:FileList | any = [];

  currentImage:{ url:string, markers: any[] } = null;

  MAX_IMAGES = 60;

  constructor(
    public matDialog:MatDialog,
    public snackBar: MatSnackBar,
    public ngZone: NgZone,
    public cameraService: CameraService,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if (!this.photos) {
      this.photos = [];
    }
  }

  addPhotos(placeholder?) {
    this.matDialog.open(CameraComponent, { width: '80%', height: '80%' }).afterClosed().pipe(first()).forEach((values) => {
      if (values) {
        this.ngZone.run(() => {
          if (this.photos) {
            values.urls.forEach((photo) => {
              this.photos.push(photo);
            });
          } else {
            this.photos = cloneDeep(values.urls);
          }
          values.blobs.forEach((blob) => {
            this.blobs.push(blob);
          });
          this.uploaded.emit(values.urls);
        });
      }
    });
  }

  fromGallery() {
    this.matDialog.open(GalleryComponent, { data: this.gallery, disableClose: true }).afterClosed().pipe(first()).forEach((values) => {
      if (values) {
        this.ngZone.run(() => {
          if (this.photos) {
            values.forEach((photo) => {
              this.photos.push(photo);
            });
          } else {
            this.photos = cloneDeep(values);
          }
          this.uploaded.emit(values);
        });
      }
    });
  }

  openFile(url) {
    window.open(url, '_blank');
  }

  handlePlaceholderClick(placeholder, input) {
    if (placeholder.url) {
      const image = this.photos.find((p) => p.placeholder_id === placeholder.id);
      if (image) {
        this.openAdvanced(image);
      }
    } else {
      this.uploadPhotos(input);
    }
  }

  @HostListener('dragstart', ['$event'])
  handleDragStart(event) {
    this.dragged = event.target;
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
  }

  @HostListener('drop', ['$event'])
  handleDragEnd(event) {
    event.preventDefault();
    if (event.target.className.includes('empty-placeholder')) {
      const photoId = this.dragged.dataset.id;
      const placeholderIndex = event.target.dataset.index;
      const placeholder = this.placeholders[placeholderIndex];
      const photo = this.photos.find((p) => p.url === photoId);
      placeholder.url = photo.url;
      placeholder.blob = photo.blob;
      photo.placeholder_id = placeholder.id;
      this.uploaded.emit(this.photos);
    }
  }

  uploadPhotos(input) {
    input.click();
  }

  compress(file: File): Observable<any> {
    const width = 400; // For scaling relative to width
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create((observer) => {
      if (file.type.indexOf('image/') === -1) {
        observer.next(null);
      } else {
        reader.onload = (ev) => {
          const img = new Image();
          img.src = (ev.target as any).result;
          (img.onload = () => {
            const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
            const scaleFactor = width / img.width;
            elem.width = width;
            elem.height = img.height * scaleFactor;
            const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
            ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
            observer.next(ctx.canvas.toDataURL(
              'image/jpeg',
              1,
            ));
          }),
          (reader.onerror = (error) => observer.error(error));
        };
      }
    });
  }

  getBlob(image) {
    const index = this.blobs.findIndex((b) => b.url === (image.new_url || image.url));
    if (index >= 0) {
      return this.blobs[index].blob;
    }
    return null;
  }

  uploadFile(event, placeholder?) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      this.uploadingFiles = Array.prototype.slice.call(event.target.files, 0, 40 - this.photos.length);
      for (const fileIndex in this.uploadingFiles) {
        if (this.uploadingFiles[fileIndex] && typeof fileIndex !== 'function') {
          const file = this.uploadingFiles[fileIndex];
          if (file.size > 2e+7) {
            this.snackBar.open(`¡Oops! El Archivo ${file.name} es muy pesado.`)._dismissAfter(2000);
          } else if (!file.type || (file.type.indexOf('image/') === -1 && file.type.indexOf('pdf') === -1 && file.type.indexOf('doc') === -1 && file.type.indexOf('heic') === -1)) {
            this.snackBar.open(`¡Oops! El Formato${file.type} no es compatible.`)._dismissAfter(2000);
          } else {
            this.ngZone.run(() => {
              const uploadTask = this.cameraService.saveImage(file);
              uploadTask.on('state_changed', (snapshot:any) => {
                this.ngZone.run(() => {
                  this.uploadingFiles[fileIndex].progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
                  if (placeholder) placeholder.progress = this.uploadingFiles[fileIndex].progress;
                });
              }, (error) => {
                this.ngZone.run(() => {
                  this.uploadingFiles[fileIndex].error = true;
                });
              }, () => {
                this.ngZone.run(() => {
                  this.uploadingFiles[fileIndex].uploaded = true;
                  if (placeholder) placeholder.uploaded = true;
                  uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    this.compress(file).pipe(first()).subscribe((blob) => {
                      const imageObject:any = { url: downloadURL, name: file.name };
                      if (placeholder) {
                        placeholder.url = downloadURL;
                        placeholder.blob = blob;
                        imageObject.placeholder_id = placeholder.id;
                      }
                      if (this.photos) {
                        this.photos.push(imageObject);
                      } else {
                        this.photos = [imageObject];
                      }
                      this.blobs.push({ url: downloadURL, blob });

                      this.uploadedImages.push(imageObject);
                      if (this.uploadingFiles.length === this.uploadedImages.length) {
                        this.uploaded.emit(this.photos);
                        this.uploadedImages = [];
                      }
                    });
                  }).catch((error) => {
                    console.log(error);
                  });
                });
              });
            });
          }
        }
      }
    }
  }

  removePlaceholderImage(placeholder) {
    const image = this.photos.find((p) => p.placeholder_id === placeholder.id);
    if (image) {
      this.removeImage(image, placeholder);
    } else {
      this.resetPlaceholder(placeholder);
    }
  }

  resetPlaceholder(placeholder) {
    placeholder.url = null;
    placeholder.blob = null;
    placeholder.progress = null;
    placeholder.uploaded = null;
  }

  removeImage(image, placeholder?) {
    event.preventDefault();
    event.stopPropagation();
    this.matDialog.open(ConfirmDialogComponent).afterClosed().toPromise().then((result) => {
      if (result) {
        const index = this.photos.findIndex((i) => i === image);
        if (this.photos[index].id) {
          this.photos[index]._destroy = true;
          this.uploaded.emit(this.photos);
        } else {
          this.photos.splice(index, 1);
          this.uploaded.emit(this.photos);
        }
        if (placeholder) {
          this.resetPlaceholder(placeholder);
        }
      }
    });
  }

  getThumbUrl(image:any) {
    const search = 'images%2F';
    let url;
    if (image.url || image.new_url) {
      if (image.video) {
        url = (image.new_url || image.url).replace(search, `${search}thumb@256_`);
        url = url.replace('.mp4', '.mp4.gif');
        url = url.replace('.webm', '.webm.gif');
      } else {
        url = (image.new_url || image.url).replace(search, `${search}thumb@256_`);
      }
    }
    return url;
  }

  getThumb(image) {
    if (image) {
      const blob = this.getBlob(image);
      const thumb = this.getThumbUrl(image);
      return this.sanitizer.bypassSecurityTrustStyle(`url('${blob || thumb}')`);
    }
    return null;
  }

  getBackground(image) {
    if (image) {
      return this.sanitizer.bypassSecurityTrustStyle(`url('${image.new_url || image.url}')`);
    }
    return null;
  }

  openAdvanced(image) {
    const index = this.photos.findIndex((value) => value === image);
    this.selectedImage = index;
    this.showAdvanced = true;
  }

  closeAdvanced(blobs) {
    this.showAdvanced = false;
    this.selectedImage = null;
    this.blobs = blobs;
    this.uploaded.emit(this.photos);
  }
}
