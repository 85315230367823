import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequirementsService } from 'app/services/requirements.service';
import { WorkService } from 'app/work/work.service';

@Component({
  selector: 'app-work-time',
  templateUrl: './work-time.component.html',
  styleUrls: ['./work-time.component.scss']
})
export class WorkTimeComponent implements OnInit {
  form =new UntypedFormGroup({
    requirement_id: new UntypedFormControl({disabled: false, value: this.data.requirement_id}),
    work_id: new UntypedFormControl({disabled: true, value: this.data.work_id}),
    avg_time: new UntypedFormControl({disabled: true, value: 0}),
    std_dev: new UntypedFormControl({disabled: true, value: 0}),
    base_time : new UntypedFormControl(0),
    base_std_dev : new UntypedFormControl(0),
  });
  requirements = [];
  works = [];
  constructor(
    private matDialogRef: MatDialogRef<WorkTimeComponent>,
    private workService: WorkService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.form.patchValue(this.data);
    if(!this.data.requirement_id){
      this.requirements.push({
        id: 0,
        name: 'Todos'
      })
    }
    this.requirements.push({
      id: this.data.alternative_requirement_id,
      name: this.data.requirement_name
    })
    this.works.push({
      id: this.data.work_id,
      name: this.data.work_name
    })
    
  }
  save(){
    console.log(this.form.value)
    const value = this.form.value;
    this.workService.saveTimers(this.data.work_id,this.form.value).toPromise().then(res => {
      this.matDialogRef.close(res);
    })
  }

}
