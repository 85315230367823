
<div class="row" *ngIf="!parentForm">
  <div class="col">
    <mat-form-field class="expand">
      <mat-select placeholder="Rol" [(ngModel)]="current_role" (ngModelChange)="selectRole()">
        <mat-option *ngFor="let role of roles; let index = index" [value]="index">
          {{role.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col shrink">
    <button mat-raised-button color="accent" (click)="createNewRole()" *ngIf="(roleService.can('create','role') | async)"><i class="fa fa-plus"></i></button>
  </div>
</div>
<div class="form">
  <div class="import" *ngIf="!role && !parentForm">
    <ng-container *ngIf="!importing">
      No hay items en esta lista. <div class="defaults" (click)="importDefaults()">Importar valores por defecto</div>
    </ng-container>
    <ng-container *ngIf="importing">
      <mat-spinner></mat-spinner>
    </ng-container>
  </div>
  <form *ngIf="role && !role.powers.admin">
    <div class="row" *ngIf="!parentForm">
      <div class="col">
        <mat-form-field>
          <input matInput placeholder="Nombre" required [(ngModel)]="role.name" name="chasis" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="row title">
      <div class="col col-4">
        Modelo
      </div>
      <div class="col col-4">
        Leer
      </div>
      <div class="col col-4">
        Crear
      </div>
      <div class="col col-4">
        Actualizar
      </div>
      <div class="col col-4">
        Destruir
      </div>
    </div>
    <div class="row" *ngFor="let model of models">
      <div class="col col-4">
        {{model.name | role}}
      </div>
      <div class="col col-4" *ngFor="let action of actions">
        <div *ngIf="role.powers[model.name]['own'] && action!='create' && model.actions.indexOf(action)!=-1 then sel else checkb"></div>
        <ng-template #sel>
          <mat-form-field style="width: 80px">
            <mat-select [name]="model.name+'_'+action"[(ngModel)]="role.powers[model.name][action]" [disabled]="model.actions.indexOf(action)==-1" >
              <mat-option [value]="true">Todos</mat-option>
              <mat-option value="own">Propios</mat-option>
              <mat-option [value]="false">Ninguno</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template #checkb>
          <mat-checkbox [name]="model.name+'_'+action"[(ngModel)]="role.powers[model.name][action]" [disabled]="model.actions.indexOf(action)==-1" ></mat-checkbox>
        </ng-template>

      </div>
    </div>
    <div class="buttons" *ngIf="!parentForm">
      <button mat-raised-button color="primary" (click)="saveRole()"  *ngIf="(roleService.can('update','role') | async)">Guardar</button>
    </div>

  </form>
</div>


