import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import { HttpErrorResponse } from "@angular/common/http";
import {WorkService} from "../work.service";

@Component({
  selector: 'app-set-status',
  templateUrl: './set-status.component.html',
  styleUrls: ['./set-status.component.scss']
})
export class SetStatusComponent implements OnInit {
  statuses:any = [];
  done = false;
  working = false;
  constructor(
    private dialogRef: MatDialogRef<SetStatusComponent>,
    private db: AngularFireDatabase,
    private workService: WorkService,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    this.db.object('status_change_listener/' + this.data.document_id + "_" + this.data.status_id).valueChanges().subscribe((value)=>{
      if(value){
        this.statuses = value;
      }
    });
  }
  setStatus(status){
    if(status){
      this.working = true
      this.workService.updateStatus(this.data.work_instance_id, status.id).toPromise().then((response) => {
        this.done = true;
        this.working = false;
        setTimeout(()=>{
          this.dialogRef.close();
        },2000);
      }).catch((error:HttpErrorResponse) => {
        console.log(error);
        this.working = false;
      });
    }else{
      this.dialogRef.close();
    }
  }
}
