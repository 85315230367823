import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from 'app/admin/services/admin.service';
import { FieldsService } from 'app/admin/services/fields.service';
import { StatusesService } from 'app/admin/services/statuses.service';
import { CameraService } from 'app/shared/image-uploader/camera/camera.service';
import { debounceTime } from 'rxjs/operators';

import { BudgetService } from '../../../budget/budget.service';
import { RoleService } from '../../../role/role.service';
import { TagService } from '../../services/budget-tag.service';
import { ClientNotificationsService } from '../../services/client-notifications.service';
import { StatusTransitionsService } from '../../services/status-transitions.service';
import { WorksService } from '../../services/works.service';

@Component({
  selector: 'app-admin-budgets',
  templateUrl: './admin-budgets.component.html',
  styleUrls: ['./admin-budgets.component.scss', '../admin-main.common.scss'],
})
export class AdminBudgetsComponent implements OnInit {
  tagForm = this.fb.group({
    name: '',
    color: '',
    category_ids: [],
  });

  formStatus = this.fb.group({
    budget_actions: [],
    color: '',
    name: '',
    notify_client: false,
    order: '',
    status_type: new FormControl<string[]>([], Validators.required),
  });

  formStatusTransition = this.fb.group({
    from_status_id: new FormControl(null, Validators.required),
    to_status_id: new FormControl(null, Validators.required),
    requirement_id: null,
  });

  showId;

  inView = false;

  selected;

  statuses = [];

  requirements = [];

  works = [];

  budget_types = [
    {
      id: 'simple',
      name: 'Simple',
    },
    {
      id: 'expanded',
      name: 'Expandido',
    },
  ];

  formWork = this.fb.group({
    name: '',
    status_id: '',
    role_id: '',
    roles_id: [],
    automatic_assignation: false,
    custom: false,
    can_change_status: false,
    complete_child_works: true,
    custom_response_type: 'none',
    next_custom_work_id: '',
    non_productive: false,
    global: false,
    sign_needed: false,
    sign_text: '',
    sign_roles: [],
    form_fields: [],
  });

  companyForm = this.fb.group({
    preferences: this.fb.group({
      budget_legend: '',
      budget_type: 'expanded',
      budget_items_per_page: 5,
      pdf_custom_field_ids: [],
    }),
  });

  roles = [];

  users = [{
    id: 0,
    name: 'Cliente',
  }];

  answers = [
    { id: 'empty', name: 'Sin respuesta' },
    { id: 'text', name: 'Texto' },
    { id: 'date', name: 'Fecha' },
    { id: 'datetime', name: 'Fecha y Hora' },
    { id: 'boolean', name: 'Si/No' },
  ];

  formField = this.fb.group({
    title: ['', Validators.required],
    required: [false],
    show_client: [false],
    field_type: ['text', Validators.required],
    field_filter: [[]],
  });

  budgetFields = [];

  orderFields: any[];

  tags = [];

  constructor(
    public tagService: TagService,
    public budgetService: BudgetService,
    public fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    public roleService: RoleService,
    public worksService: WorksService,
    public fieldsService: FieldsService,
    public statusesService: StatusesService,
    public statusTransitionsService: StatusTransitionsService,
    public clientNotificationsService: ClientNotificationsService,
    private companyService: AdminService,
    private cameraService: CameraService,
  ) { }

  ngOnInit() {
    this.budgetService.groups().subscribe((value) => {
      this.tags = value.body;
    });
    this.statusesService.all('StatusForBudget').toPromise().then((response: HttpResponse<any[]>) => {
      this.statuses = response.body;
    });
    this.roleService.simple_index().toPromise().then((value: HttpResponse<any[]>) => {
      this.roles = value.body;
      this.users = [...this.users, ...value.body];
    });

    this.fieldsService.fields('FieldForBudget').subscribe((fields) => {
      this.budgetFields = fields;
    });

    this.fieldsService.fields('FieldForOrder').subscribe((fields) => {
      this.orderFields = fields;
    });

    this.companyService.setupApp().toPromise().then((response:any) => {
      this.companyForm.patchValue(response.body);
    }).catch((error) => {
      console.warn(error);
    });
    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value) => {
      if (this.companyForm.valid) {
        this.companyService.updateCompany(value).toPromise().then((response) => {
        });
      }
    });
  }

  sanitizeColor(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  onFileChange(event, form) {
    const uploadTask = this.cameraService.saveImage(event.target.files[0]);
    uploadTask.then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        form.patchValue({ banner_url: url });
      });
    });
  }

  getStatusTooltipText(whatsapp_template_status: string): string {
    switch (whatsapp_template_status) {
      case 'APPROVED':
        return 'La plantilla de notificación está activa';
      case 'PENDING':
        return 'La plantilla de notificación está en revisión, no se enviará hasta que se active';
      case 'REJECTED':
        return 'La plantilla de notificación está rechazada, no se enviará';
      default:
        return '';
    }
  }

  getStatusClass(whatsapp_template_status: string): string {
    switch (whatsapp_template_status) {
      case 'APPROVED':
        return 'approved';
      case 'PENDING':
        return 'revision';
      case 'REJECTED':
        return 'rejected';
      default:
        return '';
    }
  }
}
