import {Component, NgZone, OnInit} from '@angular/core';
import {FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../services/admin.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CameraService} from "../../../shared/image-uploader/camera/camera.service";
import {RoleService} from "../../../role/role.service";
import { HttpResponse } from "@angular/common/http";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-admin-mailing',
  templateUrl: './admin-mailing.component.html',
  styleUrls: ['./admin-mailing.component.scss','../admin-main.common.scss']
})
export class AdminMailingComponent implements OnInit {

  companyForm = this.fb.group({
    name: [{value: '', disabled: true}],
    preferences: this.fb.group({
      mailing: this.fb.group({
        logo: null,
        color_text: '#FFFFFF',
        color_primary: '#e40a20',
        color_accent: '#e40a20',
        address: null,
        phone: null,
        webpage: null,
        facebook: null,
        instagram: null,
        email: null
      })
    })
  });
  days = ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo'];
  uploadingFiles = false;
  roles = [];
  constructor(
    private fb: UntypedFormBuilder,
    private companyService: AdminService,
    private snackBar: MatSnackBar,
    private cameraService: CameraService,
    private ngZone: NgZone,
    private roleService: RoleService,
  ) {

  }

  ngOnInit() {
    this.companyService.setupApp().toPromise().then((response)=>{
      this.companyForm.patchValue(response.body);
    }).catch((error)=>{
      console.log(error);
    });
    this.roleService.simple_index().toPromise().then((response: HttpResponse<any[]>)=>{
      this.roles = response.body;
    }).catch((error)=>{
      this.roles = [];
    });
    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value)=>{
      if(this.companyForm.valid){
        this.companyService.updateCompany(value).toPromise().then((response)=>{
        });
      }
    });
  }
  uploadPhotos(input) {
    input.click();
  }
  patchValueColor(color,control){
    (<UntypedFormGroup>(<UntypedFormGroup>this.companyForm.controls['preferences']).controls['mailing']).controls[control].patchValue(color);
  }
  getValueColor(control){
    return (<UntypedFormGroup>(<UntypedFormGroup>this.companyForm.controls['preferences']).controls['mailing']).controls[control].value;
  }
  uploadFile(event,control) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      this.uploadingFiles = true;
      for (const fileIndex in event.target.files) {
        if (event.target.files[fileIndex]){
          const file = event.target.files[fileIndex];
          if (file.size > 2e+7) {
            this.snackBar.open("¡Oops! La imagen " + file.name + " es muy pesada")._dismissAfter(2000);
          } else if (!file.type || file.type.indexOf('image/') === -1) {
          } else {
            this.ngZone.run(() => {
              const uploadTask = this.cameraService.saveImage(file);
              uploadTask.on('state_changed', (snapshot:any) => {
                this.ngZone.run(() => {
                  event.target.files[fileIndex].progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
                });
              }, (error) => {
                this.ngZone.run(() => {
                  event.target.files[fileIndex].uploaded = true;
                });
              }, () => {
                this.ngZone.run(() => {
                  event.target.files[fileIndex].uploaded = true;
                });
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
                  this.uploadingFiles = false;
                  (<UntypedFormGroup>(<UntypedFormGroup>this.companyForm.controls['preferences'])
                    .controls['mailing'])
                    .controls[control].patchValue(downloadURL);
                }).catch((error)=>{
                  console.log(error);
                });
              });
            });
          }
        }
      }
    }
  }

}
