import {Pipe, PipeTransform} from '@angular/core';
import {TruncatePipe} from "../shared/pipes/truncate.pipe";
import {DatePipe} from "@angular/common";
import {AdminService} from "../admin/services/admin.service";
import { DomSanitizer } from '@angular/platform-browser';
import { MoneyPipe } from 'app/shared/pipes/money.pipe';
import { BookingService } from 'app/services/booking.service';

@Pipe({
  name: 'formatColumn'
})
export class FormatColumnPipe implements PipeTransform {
  constructor(
    private company: AdminService,
    private bookingService: BookingService,
    private sanitizer: DomSanitizer,
  ){}
  transform(value: any, key: any, exportable=false): any {
    let type, formattedValue;
    if(key){
      type = key.toString().split("$$")[1];
    }
    if(value){
      formattedValue = value.toString().split("$$")[0];
    }
    if((key == 'budget_status' || key =='checked_in') && !exportable){
      if(value==='approved' || value === true)
        return this.sanitizer.bypassSecurityTrustHtml(
        '<i class="fa fa-check"></i>'
        );
      else
      return this.sanitizer.bypassSecurityTrustHtml(
        '<i class="fa fa-times"></i>'
        );
    }
    else if(key && key.includes('list')){
      let id = parseInt(key.split("list")[1].split("_")[0]);
      let lists = this.company.getLists();
      let list = lists.find(l => l.id === id);
      let allItems = this.getAllListItems(list)
      let item = allItems.find(i => i.id === parseInt(formattedValue));
      if(item){
        return item.name;
      }else{
        return formattedValue;
      }

    }
    else if((key === 'total' || key === 'neto' || key === 'base_neto') && !exportable){
      return new MoneyPipe(this.company).transform(formattedValue);
    }else if(['check_in_time','finish_date','created_at','deleted_at'].includes(key)){
      return new DatePipe('es-CL').transform(formattedValue,'shortDate');
    }else if(type==='datetime'){
      return new DatePipe('es-CL').transform(formattedValue,'short');
    }
    else if(type==='date'){
      return new DatePipe('es-CL').transform(formattedValue,'shortDate');
    }
    else if(key==='current_days'){
      return this.bookingService.businessDays(formattedValue).days;
    }
    else if(key==='total_time'){
      return this.bookingService.businessDays(formattedValue).days;
    }
    else if(key==='current_time'){
      return (this.bookingService.businessDays(formattedValue).seconds/3600).toFixed(2);
    }
    else if(key === 'item_type' || key === 'parent_item_type'){
      return this.company.getText(formattedValue);
    }
    else if(exportable){
      return formattedValue;
    }
    else{
      return new TruncatePipe().transform(formattedValue,['15']);
    }
  }
  //recursive
  getAllListItems(list){
    let items = [];
    if(list.list_items){
      items = items.concat(list.list_items);
      list.list_items.forEach(child => {
        items = items.concat(this.getAllListItems(child));
      })
    }
    return items;
  }
}
