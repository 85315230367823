import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RoleService } from '../../role/role.service';
import { WorkService } from '../work.service';

@Component({
  selector: 'app-work-request',
  templateUrl: './work-request.component.html',
  styleUrls: ['./work-request.component.scss'],
})
export class WorkRequestComponent implements OnInit {
  works = [];

  roles = [];

  working = false;

  current_work = null;

  form = new UntypedFormGroup({
    custom_params: new UntypedFormControl({}),
    document_id: new UntypedFormControl(null, Validators.required),
    document_type: new UntypedFormControl(null, Validators.required),
    name: new UntypedFormControl(null),
    roles_id: new UntypedFormControl([]),
    work_id: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private workService: WorkService,
    private matDialogRef: MatDialogRef<WorkRequestComponent>,
    private roleService: RoleService,
  ) {
    this.form.controls.document_id.setValue(this.data.document_id);
    this.form.controls.document_type.setValue(this.data.document_type);
    this.form.controls.custom_params.setValue(this.data.custom_params || {});
  }

  ngOnInit() {
    this.workService.customWorks({
      status_id: this.data.status_id,
      budget_id: this.data.budget_id,
      type: `WorkFor${this.data.document_type}`,
    }).toPromise().then(
      (response:HttpResponse<any[]>) => {
        this.works = response.body;
      },
    ).catch((error) => {
      console.log(error);
    });
    this.roleService.simple_index().toPromise().then((response:HttpResponse<any[]>) => {
      this.roles = response.body;
    }).catch((error) => {
      console.log(error);
    });
  }

  sendRequest() {
    if (!this.working) {
      this.working = true;
      this.workService.requestWork(this.form.value).toPromise().then((response: HttpResponse<any>) => {
        this.working = false;
        this.matDialogRef.close({
          work: this.works.find((w) => w.id === this.form.value.work_id),
          request: this.form.value,
        });
      }).catch((error) => {
        this.working = false;
        console.log(error);
      });
    }
  }

  setWork(value) {
    const index = this.works.findIndex((w) => w.id === value);
    if (index >= 0) {
      this.current_work = this.works[index];
      this.form.controls.roles_id.disable();
      this.form.controls.name.clearValidators();
      this.form.controls.name.updateValueAndValidity();
    } else {
      this.current_work = null;
      this.form.controls.roles_id.enable();
      this.form.controls.name.setValidators([Validators.required]);
      this.form.controls.name.updateValueAndValidity();
    }
  }
}
