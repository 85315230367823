import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoryService } from 'app/admin/services/category.service';
import { Field, FieldsService } from 'app/admin/services/fields.service';
import { BudgetService } from 'app/budget/budget.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.scss', '../admin-products.component.scss'],
})
export class AdminCategoriesComponent implements OnInit {
  customFields: Observable<Field[]>;

  form = this.fb.group({
    custom_field_ids: [],
    name: '',
    unit: '',
    use_as_name: false,
    value: '',
  });

  selected;

  tags = [];

  constructor(
    private budgetService: BudgetService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public categoryService: CategoryService,
    public fieldsService: FieldsService,
  ) { }

  ngOnInit() {
    this.budgetService.groups().subscribe((value) => {
      this.tags = value.body;
    });

    this.customFields = this.fieldsService.fields('FieldForCategory');
  }

  findTags(form) {
    const { name } = form.value;
    if (name) {
      // remove from array
      return this.tags.filter((tag) => tag.name !== name);
    }
    return this.tags;
  }

  showProducts(item) {
    this.selected = item;
    this.router.navigate(['admin', 'main', 'budgets', item.id, 'products']);
  }
}
