import {
  AfterViewInit,
  Component, Inject, Input, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Product } from 'app/models/product.model';
import { RoleService } from 'app/role/role.service';
import { BrandService } from 'app/services/brand.service';
import { ProductsService } from 'app/services/products.service';
import {
  debounceTime, map, merge, Observable, Subject, switchMap,
  takeUntil,
} from 'rxjs';

import { ProductFormComponent } from '../product-form/product-form.component';

@Component({
  selector: 'app-products-dialog',
  templateUrl: './products-dialog.component.html',
  styleUrls: ['./products-dialog.component.scss'],
})

export class ProductsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public parentFormGroup: FormGroup;

  private brands = [];

  public categoryName = this.data.categoryName;

  public comment: string;

  public dataSource = new MatTableDataSource<Product>();

  public displayedColumns: string[] = ['code', 'name', 'description', 'price', 'brand_ids'];

  public filteredProducts$: Observable<Product[]>;

  public form: FormGroup = this.fb.group({
    search: [''],
  });

  public isLoading: boolean = true;

  public pageSizes = [10, 25, 50, 100];

  public selectedProduct: Product;

  public sort = 'brand_ids desc';

  public totalData = 0;

  public totalPages = 0;

  unsubscribe$: Subject<boolean> = new Subject();

  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(MatSort) sorter: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private brandService: BrandService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<ProductsDialogComponent>,
    private productsService: ProductsService,
    public roleService: RoleService,
  ) {}

  ngOnInit() {
    this.brandService.brands$.subscribe((brands) => {
      this.brands = brands;
    });
  }

  ngAfterViewInit(): void {
    const { brandId, categoryId } = this.data;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sorter;

    merge(
      this.paginator.page,
      this.sorter.sortChange.asObservable(),
      this.form.get('search').valueChanges,
    ).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      switchMap(() => {
        this.isLoading = true;
        return this.productsService.select(
          this.form.get('search').value,
          categoryId,
          brandId,
          (this.paginator?.pageIndex ?? 0) + 1,
          (this.paginator?.pageSize ?? 10),
          this.sort,
        );
      }),
      map((data) => {
        this.selectedProduct = null;
        this.totalData = data.total_count;
        this.totalPages = data.total_pages;
        this.isLoading = false;
        return data.items;
      }),
    ).subscribe((products) => {
      this.dataSource = new MatTableDataSource(products);
      this.checkForExactMatch();
    });

    setTimeout(() => {
      this.form.get('search').setValue('');
    }, 0);
  }

  checkForExactMatch(): void {
    const searchValue = this.form.get('search')?.value.toLowerCase();
    const products = this.dataSource.data;

    if (products.length === 1) {
      const matchingProduct = products[0].code.toLowerCase() === searchValue ? products[0] : null;

      if (matchingProduct) {
        this.onOptionClick(matchingProduct);
      }
    }
  }

  onAcceptClick() {
    if (this.selectedProduct) {
      this.selectProduct();
    } else if (this.comment) {
      this.matDialogRef.close(this.comment);
    }
  }

  selectProduct() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close(this.selectedProduct);
    } else {
      this.parentFormGroup.addControl('product_id', this.fb.control(this.selectedProduct.id));
    }
  }

  disabledButton() {
    return !this.selectedProduct && !this.comment;
  }

  onOptionClick(product: Product) {
    this.form.get('search').setValue(product.name, { emitEvent: false });
    this.selectedProduct = product;

    this.selectProduct();
  }

  onCreateProductClick() {
    const searchValue = this.form.get('search').value;

    this.matDialog.open(
      ProductFormComponent,
      { data: { categoryId: this.data.categoryId, categoryName: this.categoryName, searchValue } },
    )
      .afterClosed().subscribe((product) => {
        if (!product) {
          return;
        }

        this.onOptionClick(product);
      });
  }

  getBrandNames(brand_ids: number[]) {
    if (!brand_ids || brand_ids.length === 0) {
      return 'Genérico';
    }

    return this.brands.filter((brand) => brand_ids.includes(brand.id)).map((brand) => brand.name).join(', ');
  }

  onSortChange({ active, direction }: { active: string; direction: string }) {
    if (!direction) {
      this.sort = 'code asc';
      return;
    }

    this.sort = `${active} ${direction}`;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
