<div>
  <mat-progress-spinner *ngIf="!budget"></mat-progress-spinner>
 
  <div *ngIf="budget">
    <div class="title">
      <span>Presupuesto Adjunto</span>
    </div>
    <div>
     <span class="budget-link" (click)="openBudget()"><i class="fa fa-file-invoice-dollar"></i> Presupuesto Nº {{budget.correlative_id}}</span>
     &nbsp;&nbsp;<span class="status-name"><i class="fas fa-check-circle" [style.color]="budget.status.color"></i>&nbsp;{{budget.status.name}}</span>  
    </div>
    <div class="subtitle">
      <span *ngIf="value.work.type==='WorkForOrder'">* El trabajo se podrá completar cuando el presupuesto alcance su estado final.</span>
      <span *ngIf="value.work.type==='WorkForBudget'">Total: {{budget.total | money}}.</span>
    </div>
  </div>
</div>
