import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseModule } from "../../shared/base.module";
import { UserService } from 'app/services/user.service';
import { RequirementsService } from 'app/services/requirements.service';
import { WorkService } from 'app/work/work.service';
import { AdminService } from 'app/admin/services/admin.service';
import moment from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChartFiltersService } from './chart-filters.service';
import { cloneDeep } from 'lodash';
import { zip } from 'rxjs';

@Component({
  selector: 'app-chart-filters',
  templateUrl: './chart-filters.component.html',
  styleUrls: ['./chart-filters.component.scss'],
  imports: [BaseModule],
  standalone: true
})
export class ChartFiltersComponent implements OnInit, OnDestroy {
  filters: any = {
    start_at: moment().locale('es').startOf('month'),
    end_at: moment().locale('es').endOf('month'),
    period_group: "week",
    user_id: [],
    work_id: [],
    requirement_id: [],
    from: ['app','derco','addon']
  };
  users: any[] = [];
  works: any[] = [];
  requirements: any[] = [];
  subs;

  @Input() includedFilters = {
    start_at: true,
    end_at: true,
    period_group: true,
    requirement: true,
    work: true,
    user: true,
    from: true,
  }

  constructor(
    private userService: UserService,
    private requirementService: RequirementsService,
    private workService: WorkService,
    public adminService: AdminService,
    private chartFiltersService: ChartFiltersService
  ) { }

  ngOnInit() {
    this.populateFiltersOptions();
  }

  updateFilters() {
    this.chartFiltersService.updateFilters(cloneDeep(this.filters));
  }

  selectAllUsers(event:MatCheckboxChange){
    if(event.checked){
      this.filters.user_id = this.users.map((v)=>v.id);
    }else{
      this.filters.user_id = [];
    }
    this.updateFilters();
  }

  selectAllRequirements(event:MatCheckboxChange){
    if(event.checked){
      this.filters.requirement_id = this.requirements.map((v)=>v.id);
    }else{
      this.filters.requirement_id = [];
    }
    this.updateFilters();
  }

  selectAllWorks(event:MatCheckboxChange){
    if(event.checked){
      this.filters.work_id = this.works.map((v)=>v.id);
    }else{
      this.filters.work_id = [];
    }
    this.updateFilters();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private populateFiltersOptions(){
    const tasks = [this.requirementService.requirements$, this.workService.works$, this.userService.users$];
    this.subs = zip(tasks).subscribe((values)=>{
      const [requirements, works, users] = values;
      if (requirements.length === 0 || works.length === 0 || users.length === 0) return;
      this.requirements = [...requirements];
      this.filters.requirement_id = this.requirements.map((r)=>r.id);
      this.works = [...works];
      this.filters.work_id = this.works.map((r)=>r.id);
      this.users = [...users];
      this.users.unshift({id: 'NULL', name: 'Sin Asignado'});
      this.filters.user_id = this.users.map((r)=>r.id);
      this.updateFilters();
    });
  }
}
