import {Component, OnInit, ViewChild, HostBinding} from '@angular/core';
import {UserService} from "../services/user.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {RoleService} from "../role/role.service";
import {interval} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";
import {Router} from "@angular/router";
import {first} from "rxjs/operators";
import {MessagingService} from "../services/messaging.service";
import {RequirementsService} from "../services/requirements.service";
import {ZendeskService} from "../services/zendesk.service";
import { AdminService } from 'app/admin/services/admin.service';
import { ListsService } from 'app/admin/services/lists.service';
import { BrandService } from 'app/services/brand.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FieldsService } from 'app/admin/services/fields.service';
import { WorkService } from 'app/work/work.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { LocalSelectorComponent } from './local-selector/local-selector.component';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  priorities_count = 0;
  user:any = {online: false};
  //userCheckbox = false;
  //private onlineDebouncer: any;
  works_count = 0;
  zEshow = true;
  locations:any[];
  location;
  heading;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @HostBinding('class') componentCssClass: string;
  constructor(
    private db: AngularFireDatabase,
    private userService: UserService,
    public roleService: RoleService,
    private _router: Router,
    private messagingService: MessagingService,
    private requirementService: RequirementsService,
    private listsService: ListsService,
    private brandsService: BrandService,
    private fieldsService: FieldsService,
    private requirementsService: RequirementsService,
    private workService: WorkService,
    public adminService: AdminService,
    private zendeskService: ZendeskService,
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit() {
    this.messagingService.init();
    this.zendeskService.hide();
    this._router.events.subscribe(() => {
      this.sidenav.close();
      this.zendeskService.hide();
    });

    this.locations = this.adminService.getLocations();
    this.heading = this.adminService.getText('location');
    if(this.locations && this.locations.length === 1){
      this.setLocations(this.locations.map((l)=> l.id))
    } else {
      this.location = localStorage.getItem('locations') ? localStorage.getItem('locations').split(",").map(i => parseInt(i)) : null;
    }

    this.db.object(`company/${this.userService.user$.getValue().company_id}/priorities`).valueChanges().subscribe((value:any) => {
      if(value && value.priorities){
        this.priorities_count = value.priorities.filter(
          (p)=>p.user_id === this.userService.user$.getValue().id
        ).reduce((previousValue, currentValue, currentIndex, array) => {
          return previousValue += currentValue.count;
        },0);
      }
      else{
        this.priorities_count = 0;
      }
    });
    this.userService.user$.forEach((value:any)=>{
      this.user = value;
      if (this.user?.theme) {
           this.applyTheme(this.user.theme);
         } else {
           this.applyTheme('sky'); 
         }
    });
  }

  applyTheme(theme: string) {
    this.componentCssClass = theme;  
    document.body.className = theme;  
  }
 
  setLocations(event){
    localStorage.setItem('locations',event.join(","))
    this.adminService.locations$.next(event);
  }

  openZe(nav){
    this.zendeskService.show();
  }

  closeZe(nav){
    if(this._router.url !== '/main/booking'){
      this.zendeskService.hide();
    }
  }

  logout() {
    this.userService.logout();
  }

  openLocalSelector(): void {
    const ref = this.bottomSheet.open(LocalSelectorComponent);

    ref.afterDismissed().subscribe(() => {
      this.location = localStorage.getItem('locations') ? localStorage.getItem('locations').split(",").map(i => parseInt(i)) : null;
      this.adminService.locations$.next(this.location);
     
    });
  }

  toggleSelectAllLocations(event: any): void {
    if (event.checked) {
      this.location = this.locations.map(location => location.id);
    } else {
      this.location = [];
    }
    this.setLocations(this.location);
  }

  areAllSelected(): boolean {
    return this.locations.length > 0 && this.locations.every(loc => this.location?.includes(loc.id));
  }
  
}
