<mat-sidenav-container class="wrapper">
  <mat-sidenav #sidenav class="sidenav" mode="over" (closed)="closeZe(sidenav)" (openedStart)="openZe(sidenav)">
    <app-sidemenu></app-sidemenu>
  </mat-sidenav>
  <mat-sidenav #sidebar mode="over" position="end" class="sidenav">
    <app-priorities (workCount)="works_count = $event" (notificationCount)="priorities_count = $event"></app-priorities>
  </mat-sidenav>

  <div class="sidenav-content">
    <mat-toolbar>
      <mat-icon (click)="sidenav.open()">menu</mat-icon>
      
      <div class="page-title">
        {{adminService.pageTitle()}}
      </div>

      <div class="expander">
        <ng-container *ngIf="locations && locations.length > 1" >
         
          <mat-select placeholder="{{heading?.locale_name || 'Local'}}" [multiple]="true" (ngModelChange)="setLocations($event)" [(ngModel)]="location" required class="local-selector display-desktop">
            <mat-checkbox [checked]="areAllSelected()" (change)="toggleSelectAllLocations($event)">Todos</mat-checkbox>
            <mat-option *ngFor="let location of locations" [value]="location.id">{{location.name}}</mat-option>
          </mat-select>
        </ng-container>
      </div>
      
      <div class="toggle-sidebar display-mobile" style="margin-right: 5px;" (click)="openLocalSelector()" *ngIf="locations && locations.length > 1">
        <mat-icon>store</mat-icon>
      </div>

      <div class="toggle-sidebar" (click)="sidebar.open()">
        <mat-icon>notifications</mat-icon>
        <div class="counter" *ngIf="priorities_count>0">
          {{priorities_count}}
        </div>
        <div class="work_counter" *ngIf="works_count>0">
          {{works_count}}
        </div>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </div>

</mat-sidenav-container>
