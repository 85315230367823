import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "../services/user.service";

@Injectable()
export class CanActivateAdmin  {

  private user= null;
  constructor(
    private authService: UserService,
    private router: Router
    ) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (this.user != null) {
      if(this.user.role.powers.admin){
        return true
      }else{
        this.router.navigate(['/admin/main/company']);
      };
    }else if (this.authService.token) {
      return this.authService.adminAuthCallback();
    }else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}

@Injectable()
export class CanActivateManage  {

  private user= null;
  constructor(
    private authService: UserService,
    private router: Router
  ) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (this.user != null) {
      return this.user.role.powers.manager.read || this.user.role.powers.admin;
    }else if (this.authService.token) {
      return this.authService.managerAuthCallback();
    }else {

      this.router.navigate(['/login']);
      return false;
    }
  }
}
