import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../services/users.service";
import {RoleService} from "../../../role/role.service";
import { HttpResponse } from "@angular/common/http";
import {AdminService} from "../../services/admin.service";
import {debounceTime} from "rxjs/operators";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {WORKPOINT_URL} from "../../../app.config";

@Component({
  selector: 'app-admin-work-point',
  templateUrl: './admin-work-point.component.html',
  styleUrls: ['./admin-work-point.component.scss','../admin-main.common.scss']
})
export class AdminWorkPointComponent implements OnInit {
  starting = false;
  form = new UntypedFormGroup({
    workpoint_code: new UntypedFormControl('',Validators.required),
  });
  companyForm = this.fb.group({
    preferences: this.fb.group({
      workpoint_logout_time: 30,
    })
  });
  workpointURL:any;
  roles = [];
  constructor(
    private fb: UntypedFormBuilder,
    private companyService: AdminService,
    public usersService: UsersService,
    private roleService: RoleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.roleService.simple_index().toPromise().then((response: HttpResponse<any[]>)=>{
      this.roles = response.body;
    }).catch((error)=>{
      this.roles = [];
    });
    this.companyService.setupApp().toPromise().then((response)=>{
      this.companyForm.patchValue(response.body);
    }).catch((error)=>{
      console.log(error);
    });
    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value)=>{
      if(this.companyForm.valid){
        this.companyService.updateCompany(value).toPromise().then((response)=>{
        });
      }
    });
  }
  startWorkPoint(){
    this.starting = true;
    this.companyService.startWorkPoint().toPromise().then((value:any) => {
      localStorage.setItem('workpoint_token', value.body.token);
      if(environment.production){
        this.workpointURL = WORKPOINT_URL+'/workpoint/login?token='+value.body.token;
      }else{
        this.workpointURL = window.location.host+'/workpoint/login?token='+value.body.token;
      }
      this.starting = false;
    }).catch(reason => {
      this.starting = false;
    });
  }
}
