import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {RoleService} from "../../role/role.service";
import {Router} from "@angular/router";
import { AdminService } from 'app/admin/services/admin.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  object = 'Empty'
  constructor(
    private userService: UserService,
    public roleService: RoleService,
    private router: Router,
    public appService: AdminService
  ) { }

  ngOnInit() {
    this.object = this.appService.local$.getValue().object;
  }
  logout() {
    this.userService.logout();
    this.router.navigate(['login']);
  }

}
