<div class="header">
  <i class="fa fa-pencil-alt"></i> <div class="text">Complete los siguientes datos</div>
</div>
<div class="wrapper">
  <div class="content">
    <form [formGroup]="form">
      <div class="subtitle" *ngIf="exist('finish_date') || exist('total')">Información</div>
      <fieldset *ngIf="exist('finish_date')">
        <mat-form-field (click)="datetimePicker.open()">
          <input matInput [matDatetimepicker]="datetimePicker" [matDatepickerFilter]="onlyWeek" [min]="currentDate"  placeholder="Fecha Finalización" formControlName="finish_date" name="finish_date" autocomplete="off" required>
        </mat-form-field>
        <mat-datetimepicker #datetimePicker type="datetime" timeInterval="5"></mat-datetimepicker>
      </fieldset>
      <fieldset *ngIf="exist('total')">
        <mat-form-field>
          <input matInput type="number" placeholder="Total" formControlName="total" name="total" autocomplete="off" required>
        </mat-form-field>
      </fieldset>
      <app-order-fields
        [filterFields]="fields"
        [parentFormGroup]="form"
        [mode]="mode"
        [showHidden]="true"
        [reactiveFormMode]="true"
      >
      </app-order-fields>
    </form>
  </div>
</div>
