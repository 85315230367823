<div mat-dialog-title class="name">
  Seleccionar nuevo estado
</div>

<div mat-dialog-content class="content">

  <div class="waiting" *ngIf="statuses.length == 0 && !done">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <span>Esperando respuesta del servidor</span>
  </div>

  <div class="waiting" *ngIf="working">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <span>Actualizando Estado</span>
  </div>

  @if (statuses.length > 0 && !done && !working) {
    <button mat-button class="grid" *ngFor="let status of statuses" (click)="setStatus(status)">
      <span >{{status.name}}</span>
    </button>
  }

  <div class="done" *ngIf="done">
    <i class="fa fa-check-circle"></i>
    <span>Estado actualizado</span>
  </div>
  
</div>


<div mat-dialog-actions class="footer">
  <button mat-button mat-dialog-close cdkFocusInitial [disabled]="working || (statuses.length == 0 && !done)" >Regresar</button>
</div>