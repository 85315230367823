import {Injectable} from '@angular/core';
import {API_URL} from "../app.config";
import {UserService} from "./user.service";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import {BaseOptions} from "../models/types";
import {PUB_KEY} from "../utils/public.key";
import { Observable, from, map } from 'rxjs';
import * as CryptoJS from 'crypto-js';

declare var JSEncrypt: any;
@Injectable()
export class BaseService {
  public apiURL = API_URL;
  public adminURL = API_URL+"/admin";
  public managerURL = API_URL+"/manager";
  constructor(public http: HttpClient, public userService: UserService) {

  }
  getParams(params?,headers?,responseType?) {
    return this.getOptions(params,headers,responseType);
  }

  get defaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    });
  }

  getOptions(addParams?:any, headers:HttpHeaders = this.defaultHeaders, responseType?): BaseOptions{
    const paramsObject = {
      'ngsw-bypass': 'true'
    };
    for(const key in addParams){
      if(key && addParams[key]){
        paramsObject[key] = addParams[key];
      }
    }
    if(this.userService){
      paramsObject['auth_token'] = this.userService.token;
    }
    if(localStorage.getItem('workpoint_token')){
      paramsObject['company_token'] = localStorage.getItem('workpoint_token');
    }
    if(localStorage.getItem('locations')){
      paramsObject['locations']= localStorage.getItem('locations');
    }
    if(localStorage.getItem('manager_token')){
      paramsObject['manager_token'] = localStorage.getItem('manager_token');
    }
    const params = new HttpParams({fromObject: paramsObject});
    const options: BaseOptions = {
      headers: headers,
      observe: "response",
      responseType: responseType || 'json',
      params: params
    };
    return options;
  }
  permitParams(value:any, ...params){
    const filteredValue = {};
    for(const param of params){
      if(param && value.hasOwnProperty(param) && value[param]!==null){
        filteredValue[param] = this.encryptPassword(param,value[param]);
      }
    }
    return filteredValue;
  }
  permitNullParams(value:any, ...params){
    const filteredValue = {};
    for(const param of params){
      if(param && value.hasOwnProperty(param)){
        filteredValue[param] = this.encryptPassword(param,value[param]);
      }
    }
    return filteredValue;
  }
  encryptPassword(key,value){
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUB_KEY);
    if(key.match('password')){
      return encrypt.encrypt(value);
    }else{
      return value;
    }
  }
}

