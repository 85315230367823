<mat-card  style="margin-bottom: 15px;">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-car fa-2x"></i></div>
    <mat-card-title>Configuración Objetos</mat-card-title>
    <mat-card-subtitle>

    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="objectConfigForm">
      <mat-form-field>
        <mat-label>Identificador de objeto agendamiento"</mat-label>
        <mat-select placeholder="Identificador de objeto agendamiento" formControlName="object_display" name="object_display" autocomplete="off" multiple="false" required>
          <mat-option *ngFor="let identifier of identifiers" [value]="identifier.id">
            {{identifier.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
<app-admin-custom-fields [service]="fieldsService" model="FieldForCustom"></app-admin-custom-fields>
<mat-card  style="margin-top: 1em">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cubes fa-2x"></i></div>
    <mat-card-title>Objetos</mat-card-title>
    <mat-card-subtitle>Administrar objetos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="objectsService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.identifier}}
      </ng-template>
      <ng-template #formTemplate let-form="form">
        <form [formGroup]="form">
          <app-custom-object-form [parentFormGroup]="form"></app-custom-object-form>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>

