import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { ZendeskService } from 'app/services/zendesk.service';

@Component({
  selector: 'app-client-message',
  templateUrl: './client-message.component.html',
  styleUrls: ['./client-message.component.scss']
})
export class ClientMessageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private zendesk: ZendeskService
  ) { }

  ngOnInit() {
    this.zendesk.hide();
  }

}
