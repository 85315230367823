import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '../../services/_base.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoriesService extends BaseService {
  constructor(public http: HttpClient, userService: UserService) {
    super(http, userService);
  }

  all() {
    return this.http.get(`${this.adminURL}/product_categories/all`, this.getParams());
  }

  select(search: string) {
    return this.http.get(`${this.adminURL}/product_categories/select`, this.getParams({ search }));
  }
}
