<div class="wrapper">
  <h1>Seleccione una tarea a realizar</h1>
  <div class="buttons">
    <button mat-raised-button class="actionButtons" (click)="createAction('InventoryActionForStock')">Comprar</button>
    <button mat-raised-button class="actionButtons" (click)="createAction('InventoryActionForConsume')">Registrar Salida de Bodega</button>
    <button mat-raised-button class="actionButtons" (click)="createAction('InventoryActionForMovement')">Transferir a otra Bodega</button>
    <button mat-raised-button class="actionButtons" (click)="createAction('InventoryActionForLoss')">Merma de Producto</button>
    <button mat-raised-button class="actionButtons">Crear Nuevo Proveedor</button>
  </div>

  <h1>Historial de Bodega</h1>
  <form [formGroup]="filters" class="filters">
    <mat-form-field>
      <mat-label>Por Tipo</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="">Ninguno</mat-option>
        <mat-option *ngFor="let type of allTypes" [value]="type">{{ titleForType(type) }}</mat-option>
      </mat-select>
    </mat-form-field>
    @if (filters.get('type').getRawValue()) {
      <mat-form-field>
        <mat-label>Por Estado</mat-label>
        <mat-select formControlName="status_id[]" [multiple]="true">
          <mat-checkbox class="mat-option"
                      (click)="$event.stopPropagation()"
                      (change)="selectAllStatuses($event)">
            Seleccionar Todo
          </mat-checkbox>
          <mat-option *ngFor="let status of statusesFor(filters.get('type').getRawValue())" [value]="status.id">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    }
    <mat-form-field>
      <mat-label>Por Fecha Creacion Desde</mat-label>
      <input matInput [matDatepicker]="start" formControlName="created_at_start">
      <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
      <mat-datepicker #start></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Por Fecha Creacion Hasta</mat-label>
      <input matInput [matDatepicker]="end" formControlName="created_at_end">
      <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
      <mat-datepicker #end [startAt]="filters.get('created_at_start').getRawValue()"></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Por Usuario</mat-label>
      <mat-select formControlName="user_id[]" [multiple]="true">
        <mat-checkbox class="mat-option"
                      (click)="$event.stopPropagation()"
                      (change)="selectAllUsers($event)">
          Seleccionar Todo
        </mat-checkbox>
        <mat-option *ngFor="let user of users | async" [value]="user.id">
          {{ user.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>


  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef> Tipo </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openDetails(element)">
        {{ titleForType(element.type) }}
        @if (!element.is_valid) {
          &nbsp;<i class="fa fa-exclamation-triangle"></i>
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="correlative_id">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nº </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openDetails(element)"> {{ element.correlative_id }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openDetails(element)"> {{ element.created_at | date:'dd/MM/yyyy HH:mm' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="estimated_completion_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Estimada </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openDetails(element)"> {{ element.estimated_completion_date | date:'dd/MM/yyyy HH:mm' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef> Responsable </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openDetails(element)"> {{ element.user?.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="document_type">
      <mat-header-cell *matHeaderCellDef> Tipo Documento </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{adminService.getText(element.document_type)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="document_id">
      <mat-header-cell *matHeaderCellDef> Nº Documento </mat-header-cell>
      <mat-cell *matCellDef="let element" class="link" (click)="openDocument(element)"> #{{ element.document.correlative_id }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="openDetails(element)">
        <span class="status-text" [style.color]="element.status.color">{{ element.status.name }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if(!element.locked && !element.completed_at && !element.failed) {
          <mat-icon color="accent" (click)="openEditForm(element)" matTooltip="Editar Acción">edit</mat-icon>
        }
        @if(!element.completed_at && !element.failed) {
          <mat-icon color="warn" matTooltip="Cancelar Acción">cancel</mat-icon>
        }
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <mat-paginator
    #paginator
    [length]="totalData"
    [pageSizeOptions]="pageSizes"
    showFirstLastButtons
  ></mat-paginator>
</div>


<div class="loading" *ngIf="isLoading1">
  <mat-spinner></mat-spinner>
</div>
