<mat-card style="margin-bottom: 1em">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
    <mat-card-title>Agendamiento</mat-card-title>
    <mat-card-subtitle>Configura aspectos generales del agendamiento</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="formBooking">
      <ng-container formGroupName="preferences">
        <ng-container formGroupName="booking_mail">
          <mat-form-field>
            <mat-label>Email Agendado</mat-label>
            <textarea matInput placeholder="<h5>Hola {nombre}</h5><p>¡Tu cita ya fue agendada!</p>" formControlName="text"></textarea>
            <mat-hint>Contenido del email de agendamiento <i class="fa fa-question-circle" matTooltip="Puedes utilizar los siguientes placeholders: {nombre},{patente},{marca},{modelo},{requerimiento},{fecha},{hora}. Tambien puedes utilizar tus campos personalizados de orden (ej: {numero_de_siniestro}) y HTML Básico."></i></mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email Confirmación</mat-label>
            <textarea matInput placeholder="<h2>Hola {nombre}</h2><p>Te recordamos que mañana a las {hora} tienes una cita</p>" formControlName="confirm_text"></textarea>
            <mat-hint>Contenido del email de confirmación <i class="fa fa-question-circle" matTooltip="Puedes utilizar los siguientes placeholders: {nombre},{patente},{marca},{modelo},{requerimiento},{fecha},{hora}. Tambien puedes utilizar tus campos personalizados de orden (ej: {numero_de_siniestro}) y HTML Básico."></i></mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>URL Banner</mat-label>
            <input matInput placeholder="Banner" formControlName="banner_url" autocomplete="off">
            <mat-hint>URL de la imagen a mostrar en el correo</mat-hint>
            <button mat-icon-button matSuffix (click)="banner.click()">
              <mat-icon>link</mat-icon>
            </button>
            <input type="file" style="display: none" name="image" #banner (change)="onFileChange($event,formBooking)">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Estilo Banner</mat-label>
            <mat-select placeholder="Estilo Banner" formControlName="banner_style">
              <mat-option value="">Seleccione</mat-option>
              <mat-option value="contain">Contener</mat-option>
              <mat-option value="cover">Cubrir</mat-option>
            </mat-select>
            <mat-hint>Estilo CSS para el banner</mat-hint>
          </mat-form-field>
        </ng-container>
        <ng-container formGroupName="booking_addon">
          <mat-form-field>
            <mat-label>Dominios</mat-label>
            <textarea matInput placeholder="https://www.example.com http://*.otherexample.com" formControlName="allowed_domains"></textarea>
            <mat-hint>Dominios permitidos para integrar el addon de agendamiento<i class="fa fa-question-circle" matTooltip="En caso de proporcionar más de uno, deben estar separados por espacios."></i></mat-hint>
          </mat-form-field>

          <div class="picker">
            Color de fondo:
            <mcc-color-picker hideUsedColors hideEmpty hideButtons (selected)="formBooking.get('preferences').get('booking_addon').get('background_color').patchValue($event)" [selectedColor]="formBooking.get('preferences').get('booking_addon').get('background_color').value"></mcc-color-picker>
          </div>
          <mat-form-field hidden >
            <mat-label>Color de fondo</mat-label>
            <input matInput placeholder="#FFFFFF" formControlName="background_color" autocomplete="off" >
          </mat-form-field>



        </ng-container>
      </ng-container>
    </form>
  </mat-card-content>
</mat-card>
<ng-template #itemTemplate let-item>
  {{item.name}}
</ng-template>


<app-admin-additionals></app-admin-additionals>


<div class="requirements" *ngIf="!hideRequirements">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar><i class="fa fa-book fa-2x"></i></div>
      <mat-card-title>Requerimientos</mat-card-title>
      <mat-card-subtitle>Administra tus solicitudes</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ngx-crud-manager [service]="requirementsService" [itemTemplate]="itemTemplate" [formTemplate]="requirementTemplate" [formGroup]="requirementForm">
      </ngx-crud-manager>
      <ng-template #requirementTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>{{companyService.getText('location_plural')}}</mat-label>
            <mat-select [placeholder]="companyService.getText('location_plural')" formControlName="location_ids" multiple>
              <mat-option *ngFor="let location of locations" [value]="location.id">{{location.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" autocomplete="off" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Valor (Base)</mat-label>
            <input matInput type="number" placeholder="Valor (Base)" formControlName="price">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Máx. Agendamientos Diarios</mat-label>
            <input matInput type="number" placeholder="Máx. Agendamientos Diarios" formControlName="max_daily_bookings">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Duración (Minutos)</mat-label>
            <input matInput type="number" placeholder="Duración (Minutos)" formControlName="duration" step="1">
          </mat-form-field>
        </form>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
