<h2 mat-dialog-title>{{data?.title}}</h2>
<div class="content">
  <div class="fileInput">
    <button mat-raised-button color="accent" (click)="fileInput.click()">
      {{selected ? 'Elegir otro archivo' : 'Elegir archivo'}}
    </button>
    <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file">
    @if (selected && file)
    {
      <p>{{file.name}}</p>
    }
  </div>

  @if (selected) {
    <button mat-raised-button color="accent" (click)="uploadDocument()">Importar</button>
  }
</div>
