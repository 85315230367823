<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-users fa-2x"></i></div>
    <mat-card-title>API Keys</mat-card-title>
    <mat-card-subtitle>Administre acceso a información de su plataforma</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="apiService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.name}}&nbsp;<i class="fa fa-key" [matTooltip]="'Copiar llave al portapapeles'" (click)="copyKey(item.key)"></i>
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
              <mat-form-field>
                <input matInput placeholder="Nombre" required formControlName="name" name="name" autocomplete="off">
                <mat-hint>Nombre descriptivo</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="active">
                Activo
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fieldset formGroupName="scope">
                <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <mat-select placeholder="Estado (Opcional)" formControlName="statuses_ids" multiple="true">
                        <mat-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</mat-option>
                      </mat-select>
                      <mat-hint>Solo podrá ver ordenes en algunos estado</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <mat-select placeholder="Roles (Opcional)" formControlName="roles_ids" multiple="true">
                        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                      </mat-select>
                      <mat-hint>Solo podrá ver ordenes de algunos roles</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <mat-select placeholder="Requerimientos (Opcional)" formControlName="requirements_ids" multiple="true">
                        <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
                      </mat-select>
                      <mat-hint>Solo podrá ver ordenes en algunos Requerimientos</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
