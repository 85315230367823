import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-work-rejection',
  templateUrl: './work-rejection.component.html',
  styleUrls: ['./work-rejection.component.scss']
})
export class WorkRejectionComponent implements OnInit {
  rejectForm = {
    reason: null,
    comment: null
  }
  constructor(
    private matDialogRef: MatDialogRef<WorkRejectionComponent>
  ) { }

  ngOnInit() {
  }
  reject(){
    this.matDialogRef.close(this.rejectForm);
  }

}
