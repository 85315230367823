import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventoryItem } from 'app/models/inventory.model';
import { PaginatedItems } from 'app/models/types';
import { map } from 'rxjs';

import { BaseService } from '../services/_base.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService extends BaseService {
  index(
    warehouseIds: number[] = [],
    pageNumber: number = 1,
    pageSize: number = 10,
    sort: string = 'updated_at desc',
    filters = {},
  ) {
    return this.http.get<PaginatedItems<InventoryItem>>(
      `${this.apiURL}/inventories`,
      this.getOptions({
        'warehouse_ids[]': warehouseIds,
        page: pageNumber,
        page_size: pageSize,
        sort,
        ...filters,
      }),
    )
      .pipe(
        map((response: HttpResponse<PaginatedItems<InventoryItem>>) => response.body),
      );
  }

  all() {
    return this.http.get<InventoryItem[]>(`${this.apiURL}/inventories/all`, this.getOptions())
      .pipe(
        map((response: HttpResponse<InventoryItem[]>) => response.body),
      );
  }
}
