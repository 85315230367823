<mat-toolbar>
  <i class="fa fa-file-invoice-dollar"></i>
  <span class="hide-mobile">Cobros&nbsp;</span>({{count}})
  <div class="expander"></div>
  <button mat-button (click)="openFilters()"><i class="fa fa-filter"></i></button>
</mat-toolbar>
<div class="wrapper">

  <div class="statuses hide-mobile">
    <div class="status-legend" *ngFor="let status of statuses" [class.inactive]="!statusOn(status.id)" (click)="toggleFilter(status.id)">
      <i class="fa fa-square" [style.color]="status.color" ></i>
      <i class="fa fa-check-square" [style.color]="status.color" ></i>
      <span>{{status.name}}</span>
    </div>
  </div>
  <div class="filters">
    <input [formControl]="searchControl" placeholder="Buscar.." name="search">
  </div>
  <div class="loading" [hidden]="!working">
    <mat-spinner></mat-spinner>
  </div>
  <div class="payments" [hidden]="working" id="payments">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="payment" *ngFor="let payment of payments; let even = even" [class.even]="even" (click)="openPayment(payment)">
          <div class="header">
            <i class="fa fa-car"></i> {{payment.vehicle_identifier}}
            <div class="expander"></div>
            <span>#{{payment.correlative_id}}</span>
          </div>
          <div class="info">
            {{payment.requirement_name}}
            <div class="expander"></div>
            <div class="payment-status" [class.payed]="payment.payed" [matTooltip]="payment.payed ? 'Pagado' : 'Cobro enviado'">
              {{payment.total || 0 | money}} <i class="fa fa-check-circle"></i>
              <i class="fa fa-exclamation-triangle" *ngIf="payment.payment && !payment.payed" [matTooltip]="'El pago se esta procesando'"></i>
            </div>
          </div>
          <div class="footer">
            <i class="fa fa-user" [matTooltip]="payment.client_name"></i>
            <div class="client" class="hide-mobile">{{payment.client_name}}</div>
            <div class="expander"></div>
            <div class="date">({{payment.payment?.id || '-'}}) - {{payment.created_at | date:'shortDate'}}</div>
          </div>
        </div>
        <div class="empty" *ngIf="payments && payments.length == 0">
          <span>No se han encontrado cobros</span>
        </div>
        <div class="load-more" [hidden]="page >= total_pages" id="load-more">
          <mat-spinner [diameter]="20"></mat-spinner> Cargando cobros
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
