import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StatusesService } from 'app/services/statuses.service';
import { uniq } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { AdminService } from '../../admin/services/admin.service';
import { FilterComponent } from '../../main/filter/filter.component';
import { StatusForBudget } from '../../models/status.model';
import { PaginatedItems } from '../../models/types';
import { BudgetService } from '../budget.service';

// @ts-ignore
@Component({
  selector: 'app-budgets',
  templateUrl: './budgets.component.html',
  styleUrls: ['./budgets.component.scss'],
})
export class BudgetsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['correlative-id', 'requirement', 'object-identifier', 'status-list', 'budget-total'];

  dataSource: MatTableDataSource<any>;

  statuses : StatusForBudget[] = [];

  budgets: any;

  totalByStatus: any = {};

  searchControl: UntypedFormControl = new UntypedFormControl();

  filter: any = this.getFilter();

  page = 1;

  total_pages = 1;

  downloading = false;

  filters: any = [{
    name: 'Estado',
    key: 'status_id',
    options: [],
    multi: true,
  }];

  subs: Subscription[] = [];

  working = false;

  search: any;

  count = 0;

  country;

  loadingMore = false;

  constructor(
    private matDialog: MatDialog,
    private statusesService: StatusesService,
    private budgetService: BudgetService,
    public adminService: AdminService,
  ) { }

  ngOnInit(search?) {
    this.adminService.pageTitle.set('Presupuestos');
    this.country = this.adminService.local$.getValue().country;
    this.ngOnDestroy();
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this.statuses.length > 0) {
        this.search = value;
        this.getBudgets();
      }
    }));
    this.subs.push(this.adminService.locations$.subscribe((value) => {
      if (this.statuses.length > 0) {
        this.getBudgets();
      }
    }));
    this.statusesService.statuses('StatusForBudget').subscribe((statuses: StatusForBudget[]) => {
      this.statuses = statuses;
      if (!this.filter && statuses.length > 0) {
        this.filter = this.statuses.filter((status) => !status.finish).map((s) => s.id);
        this.saveFilter();
      }
      if (this.statuses.length > 0) {
        this.getBudgets();
      }
    });
    this.getMore();
    this.getTotalsByStatus();
    this.dataSource = new MatTableDataSource(this.budgets);
  }

  getBudgets(loadingMore?) {
    // SCROLL TOP
    if (!loadingMore) {
      this.resetBudgets();
      this.working = true;
    }
    this.budgetService.index({ status_id: this.filter, page: this.page }, this.search)
      .toPromise().then((response:HttpResponse<PaginatedItems>) => {
        if (!this.budgets) {
          this.budgets = [];
        }
        if (loadingMore) {
          this.budgets = this.budgets.concat(response.body.items);
        } else {
          this.budgets = response.body.items;
        }
        this.count = response.body.total;
        this.page = response.body.page;
        this.total_pages = response.body.total_pages;
        this.working = false;
        this.loadingMore = false;
        this.dataSource.data = this.budgets;
        this.adminService.pageTitle.set(`Presupuestos`); //(${this.count})
      }).catch((error:HttpErrorResponse) => {
        console.log(error);
        this.working = false;
      });
  }

  getTotalsByStatus() {
    this.budgetService.totalsByStatus().subscribe({
      next: (response) => {
        const totals = response.body;
        if (totals && Array.isArray(totals)) {
          this.totalByStatus = totals.reduce((acc, current) => {
            acc[current.status_id] = current;
            return acc;
          }, {});
        } else {
          // console.error('Totales por estado vacio');
        }
        // console.log(this.totalByStatus);
      },
    });
  }

  resetBudgets() {
    const element = document.getElementById('budgets');
    if (element) {
      element.scrollTop = 0;
    }
    this.page = 1;
    this.total_pages = 1;
    this.count = 0;
  }

  // FILTERS
  openFilters() {
    // Setup Options
    this.filters[0].options = this.statuses.map((s) => [s.id, s.name, this.statusOn(s.id)]);
    this.matDialog.open(FilterComponent, { data: this.filters }).afterClosed().pipe(first()).forEach((result) => {
      if (result) {
        this.filter = result.status_id;
        this.saveFilter();
        this.getBudgets();
      }
    });
  }

  getFilter() {
    const value = localStorage.getItem('budgets_filter');
    let filter = null;
    if (value) {
      filter = uniq(value.split(',')).map((v) => parseInt(v, 0));
    }
    return filter;
  }

  statusOn(id) {
    if (this.filter) {
      const index = this.filter.findIndex((s) => parseInt(s, 0) === id);
      return index >= 0;
    }
    return false;
  }

  saveFilter() {
    this.filter.push(0);
    this.filter = uniq(this.filter);
    localStorage.setItem('budgets_filter', this.filter);
    return this.filter;
  }

  toggleFilter(id: number) {
    const index = this.filter.findIndex((s) => s === id);
    if (index >= 0) {
      this.filter.splice(index, 1);
    } else {
      this.filter.push(id);
    }
    this.saveFilter();
    this.getBudgets();
  }

  getMore() {
    const element = document.getElementById('budgets');
    if (element) {
      this.loadingMore = true;
      this.page++;
      this.getBudgets(true);
    }
  }

  getStatusCompletion(status_id, budget) {
    const current = this.statuses.findIndex((s) => s.id === budget.status_id);
    const ask = this.statuses.findIndex((s) => s.id === status_id);
    return current > ask;
  }

  exportAsExcelFile(dateRange?): void {
    this.downloading = true;
    this.budgetService.allPost({ status_id: this.filter, page: -1, dates: dateRange }, this.search)
      .toPromise().then((value:HttpResponse<any>) => {
        const excelFileName = 'Presupuestos';
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.formatJson(value.body.items));
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const today = new Date();
        const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`;
        const time = `${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
        const name = excelFileName + date + time;
        XLSX.writeFile(workbook, `${name}.xlsx`);
        this.downloading = false;
      }).catch((reason) => {
        console.log(reason);
        this.downloading = false;
      });
  }

  formatJson(data) {
    return data.map((row) => ({
      ID: row.correlative_id,
      Orden: row.order.correlative_id,
      Recepcionista: row.order.user.name,
      Solicitante: row.user.name,
      Cliente: row.order.client.name,
      RUT: row.order.client.rut,
      Objeto: row.order.object.identifier,
      'Valor Neto': row.total,
      Estado: row.status.name,
    }));
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  clearSearch() {
    this.searchControl.setValue('');
  }
}
