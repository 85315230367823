import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartDetailsComponent } from '../chart-details/chart-details.component';
import { BaseModule } from "../../shared/base.module";
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss'],
  imports: [BaseModule, ChartComponent],
  standalone: true
})
export class ChartCardComponent {
  value: number;
  performance: number;
  chart: any;
  suffix: string;
  numberFormat: string = '1.0-0'
  @Input() stacked:boolean = false;
  @Input() mainTitle:string;
  @Input() mainSubtitle:string;
  @Input() chartKey:string;
  @Input() customCharts:[];
  @Input() filters: any;
  @Input() chartType: string = 'column';
  @Input() displayMode: string;
  @Input() snapshot = '';
  @Input() detailType = 'order';
  @Input() performanceThreshold = 0;
  @Input() valueKey = 'value';
  @Input() performanceKey = 'performance';
  @Input() hasDetails = true;
  @Input() excludedKeysInDetails = []
  @Input() dataLabelSuffix = '';

  constructor(private matDialog: MatDialog) { }

  viewDetails(custom, filter=null){
    this.matDialog.open(ChartDetailsComponent, {
      data: {
        custom: custom,
        type: this.detailType,
        filter: filter,
        excludedKeys: this.excludedKeysInDetails,
      },
      minWidth: '80%'
    })
  }

  updateChart(data){
    if (!this.displayMode) { return }
    const [type, numberFormat, suffix] = this.displayMode.split('__');
    this.suffix = suffix;
    switch(type){
      case 'total':
        this.getTotal(data);
        this.numberFormat = numberFormat
        break;
      case 'avg':
        this.getAvg(data);
        this.numberFormat = numberFormat
        break;
      case 'avg_orders':
        this.getAvgOrders(data);
        this.numberFormat = numberFormat
        break;
      case 'diff':
        this.getDiff(data);
        this.numberFormat = numberFormat
        break;
      case 'diff_invert':
        this.getDiff(data,true);
        this.numberFormat = numberFormat
        break;
    }
    if (this.valueKey === 'performance') {
      this.value = this.chart.performance;
    } else if (this.valueKey === 'performance_percent') {
      this.value = this.chart.performance * 100;
    } else {
      this.value = this.chart.value;
    }
    this.performance = this.chart[this.performanceKey];
  }

  getTotal(data){
    this.chart = {
      value: data.values.map((v) => v.data.reduce((total, score) => total + score,0)).reduce((total, score) => total + score,0)
    };
  }
  getAvg(data){
    this.chart = {
      value: data.values
        .map((v) => v.data.filter(d => d != 0).reduce((total, score) => total + score,0)/(v.data.filter(d => d != 0).length || 1)).filter(d => d != 0)
        .reduce((total, score) => total + score,0)/data.values.length
    };
  }
  getAvgOrders(data:any){
    if(data.values[1] && data.values[2]){
      const current = data.values[1].data;
      const current_avg = current.reduce((total, score) => total + score,0) / current.length;
      const avg = data.values[2].data[0];
      this.chart = {value: current_avg, performance: (current_avg/avg*100-100)};
    }else{
      this.chart = {value: 0, performance: 0};
    }

  }
  getDiff(data,invert=false){
    if(data.values[0] && data.values[1]) {
      const first = data.values[0].data.reduce((total, score) => total + score, 0);
      const second = data.values[1].data.reduce((total, score) => total + score, 0);
      if(invert){
        this.chart = {value: second - first, performance: second /(first+second)};
      }
      else{
        this.chart = {value: first - second, performance: first /(first+second)};
      }
    }
    else if(data.values[0]){
      if(data.values[0].data.length > 1){
        const first = data.values[0].data[0].y;
        const second = data.values[0].data[1].y;
        if(invert){
          this.chart = {value: second - first, performance: second /(first+second)};
        }
        else{
          this.chart = {value: first - second, performance: first /(first+second)};
        }
      }
      else{
        this.chart = {value: 0, performance: 0};
      }
    }
    else{
      this.chart = {value: 0, performance: 0};
    }
  }
}
