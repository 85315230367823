import {
  AfterViewInit,
  Component, Inject, OnDestroy, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'app/admin/services/admin.service';
import { CRMService } from 'app/crm/crm.service';
import { CrmForBooking } from 'app/models/crm.model';
import { RoleService } from 'app/role/role.service';
import {
  debounceTime, map, merge, Observable, Subject, switchMap,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-crm-dialog',
  templateUrl: './crms-dialog.component.html',
  styleUrls: ['./crms-dialog.component.scss'],
})

export class CrmsDialogComponent implements AfterViewInit, OnDestroy {
  public comment: string;

  public dataSource = new MatTableDataSource<CrmForBooking>();

  public displayedColumns: string[] = ['rut', 'name', 'email', 'object-identifier', 'from-requirement', 'requirement'];

  public filteredCrm$: Observable<CrmForBooking[]>;

  public form: FormGroup = this.fb.group({
    search: [''],
  });

  public isLoading: boolean = true;

  public pageSizes = [10, 25, 50, 100];

  public selectedCrm: CrmForBooking;

  public sort = 'brand_ids desc';

  public totalData = 0;

  public totalPages = 0;

  unsubscribe$: Subject<boolean> = new Subject();

  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(MatSort) sorter: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private adminService: AdminService,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<CrmsDialogComponent>,
    private crmService: CRMService,
    public roleService: RoleService,
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sorter;

    merge(
      this.paginator.page,
      this.sorter.sortChange.asObservable(),
      this.form.get('search').valueChanges,
    ).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      switchMap(() => {
        this.isLoading = true;
        return this.crmService.suggestedToCreate(
          this.form.get('search').value,
          (this.paginator?.pageIndex ?? 0) + 1,
          (this.paginator?.pageSize ?? 10),
          this.sort,
        );
      }),
      map((data) => {
        this.selectedCrm = null;
        this.totalData = data.total_count;
        this.totalPages = data.total_pages;
        this.isLoading = false;
        return data.items;
      }),
    ).subscribe((clients) => {
      this.dataSource = new MatTableDataSource(clients);
      this.checkForExactMatch();
    });

    setTimeout(() => {
      this.form.get('search').setValue('');
    }, 0);
  }

  checkForExactMatch(): void {
    const searchValue = this.form.get('search')?.value.toLowerCase();
    const clients = this.dataSource.data;

    if (clients.length === 1) {
      const matchingClient = clients[0].client.rut.toLowerCase() === searchValue ? clients[0] : null;

      if (matchingClient) {
        this.onOptionClick(matchingClient);
      }
    }
  }

  onAcceptClick() {
    if (this.selectedCrm) {
      this.selectCrm();
    }
  }

  selectCrm() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close(this.selectedCrm);
    }
  }

  disabledButton() {
    return !this.selectedCrm && !this.comment;
  }

  onOptionClick(crm: CrmForBooking) {
    this.form.get('search').setValue(crm.client.name, { emitEvent: false });
    this.selectedCrm = crm;

    this.selectCrm();
  }

  onSortChange({ active, direction }: { active: string; direction: string }) {
    if (!direction) {
      this.sort = 'name asc';
      return;
    }

    this.sort = `${active} ${direction}`;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
