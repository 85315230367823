<div mat-dialog-title class="name">
    Filtros Adicionales
</div>
  
<div mat-dialog-content class="content">
  <span class="subtitle">*Máximo 1000 registros</span>
  
  <mat-form-field appearance="fill">
    <mat-label>Fecha Recepción</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Inicio" [(ngModel)]="filters.start_check_in">
      <input matEndDate placeholder="Fin" [(ngModel)]="filters.end_check_in">>
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Fecha Entrega</mat-label>
    <mat-date-range-input [rangePicker]="picker2">
      <input matStartDate placeholder="Inicio" [(ngModel)]="filters.start_ended_at">>
      <input matEndDate placeholder="Fin" [(ngModel)]="filters.end_ended_at">>
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-date-range-picker #picker2></mat-date-range-picker>
  </mat-form-field>
</div>


<div mat-dialog-actions class="footer">
  <button mat-button (click)="closeDialog()">Cancelar</button>
  <button mat-button cdkFocusInitial (click)="export()">Exportar</button>
</div>