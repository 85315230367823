import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormControl, UntypedFormBuilder, UntypedFormControl, Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusesService } from 'app/admin/services/statuses.service';
import { Requirement } from 'app/models/requirement.model';
import { RequirementTransition } from 'app/models/requirement-transition.model';
import { Role } from 'app/models/types';
import { CameraService } from 'app/shared/image-uploader/camera/camera.service';
import { debounceTime } from 'rxjs/operators';

import { RoleService } from '../../../role/role.service';
import { RequirementsService } from '../../../services/requirements.service';
import { AdminService } from '../../services/admin.service';
import { ClientNotificationsService } from '../../services/client-notifications.service';
import { RequirementTransitionsService } from '../../services/requirement-transitions.service';
import { StatusTransitionsService } from '../../services/status-transitions.service';
import { WorksService } from '../../services/works.service';
import {
  CRM_TYPES, defaulCrmType,
} from './admin-crm.config';

@Component({
  selector: 'app-admin-crm',
  templateUrl: './admin-crm.component.html',
  styleUrls: ['./admin-crm.component.scss', '../admin-main.common.scss'],
})
export class AdminCrmComponent implements OnInit {
  formStatus = this.fb.group({
    budget_actions: [],
    color: '',
    name: '',
    notify_client: false,
    order: '',
    status_type: new FormControl([], Validators.required),
  });

  companyForm = this.fb.group({
    preferences: this.fb.group({
      crm_for_bookings: this.fb.group({
        notification_text: this.fb.control(''),
        reminder_text: this.fb.control(''),
        notification_status_id: this.fb.control(null, [Validators.required]),
        failed_contact_status_id: this.fb.control(null, [Validators.required]),
        min_recall_number: this.fb.control(0),
        min_recall_period: this.fb.control('months'),
        max_recalls: this.fb.control(0, [Validators.required, Validators.min(0)]),
        anticipation_days: this.fb.control(0, [Validators.required, Validators.min(0)]),
        auto_recall: this.fb.control(false),
        max_number_of_retries: this.fb.control(0, [Validators.required, Validators.min(0)]),
        days_between_retries: this.fb.control(1, Validators.min(1)),
      }),

    }),
  });

  crm_type: string;

  formStatusTransition = this.fb.group({
    from_status_id: new FormControl(null, Validators.required),
    to_status_id: new FormControl(null, Validators.required),
    requirement_id: null,
  });

  formRequirementTransition = this.fb.group({
    from_requirement_id: null,
    to_requirement_id: null,
    wait_months: null,
  });

  selected;

  statuses = [];

  requirements = [];

  works = [];

  transitions: RequirementTransition[] = [];

  budget_actions = [
    {
      id: 'edit_photos',
      name: 'Editar Fotos',
    },
    {
      id: 'edit_markers',
      name: 'Editar Marcadores',
    },
    {
      id: 'edit_products',
      name: 'Editar Productos',
    },
    {
      id: 'edit_fields',
      name: 'Editar Campos Custom',
    },
    {
      id: 'approve_products',
      name: 'Aprobar Productos',
    },
  ];

  formWork = this.fb.group({
    name: '',
    status_id: '',
    role_id: '',
    roles_id: [],
    automatic_assignation: false,
    custom: false,
    can_change_status: false,
    complete_child_works: true,
    custom_response_type: 'none',
    next_custom_work_id: '',
    non_productive: false,
    global: false,
    sign_needed: false,
    sign_text: '',
    sign_roles: [],
    form_fields: [],
  });

  formNotification = this.fb.group({
    name: '',
    text: '',
    answer_type: '',
    statuses_ids: [],
    roles_ids: [0],
    works_ids: [],
    requirement_transitions_ids: [],
    active: true,
    send_at: 'end',
    show_order: false,
    show_budget: false,
    show_work_data: false,
    complete_work: false,
    banner_url: '',
    banner_style: 'auto',
    whatsapp_active: false,
  });

  roles = [];

  users = [{
    id: 0,
    name: 'Cliente',
  }];

  answers = [
    { id: 'empty', name: 'Sin respuesta' },
    { id: 'text', name: 'Texto' },
    { id: 'date', name: 'Fecha' },
    { id: 'datetime', name: 'Fecha y Hora' },
    { id: 'boolean', name: 'Si/No' },
    { id: 'booking', name: 'Agendamiento' },
  ];

  fields = [];

  constructor(
    public fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    public roleService: RoleService,
    public worksService: WorksService,
    private router: Router,
    public statusesService: StatusesService,
    public requirementService: RequirementsService,
    public statusTransitionsService: StatusTransitionsService,
    public requirementTransitionsService: RequirementTransitionsService,
    public clientNotificationsService: ClientNotificationsService,
    private companyService: AdminService,
    private cameraService: CameraService,
    private route: ActivatedRoute,
  ) {
    const param = this.route.snapshot.paramMap.get('crm_type').toString();
    const type = `CrmFor${param.charAt(0).toUpperCase()}${param.slice(1)}`;
    this.crm_type = CRM_TYPES.includes(type) ? type : defaulCrmType;
  }

  ngOnInit() {
    this.statusesService.statuses(`StatusFor${this.crm_type}`).subscribe((statuses) => {
      this.statuses = statuses;
    });

    this.requirementService.requirements$.subscribe((requirements: Requirement[]) => {
      this.requirements = requirements;
    });

    this.requirementTransitionsService.index().subscribe((response) => {
      this.transitions = response.body.items;
    });

    this.companyService.setupApp().toPromise().then((response) => {
      this.companyForm.patchValue(response.body);
    }).catch((error) => {
      console.log(error);
    });

    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value) => {
      if (this.companyForm.valid) {
        this.companyService.updateCompany(value).subscribe();
      }
    });

    this.roleService.simple_index().subscribe((value: HttpResponse<Role[]>) => {
      this.roles = value.body;
      this.users = [...this.users, ...value.body];
    });
  }

  sanitizeColor(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  showProducts(item) {
    this.selected = item;
    this.router.navigate(['admin', 'main', 'budgets', item.id, 'products']);
  }

  onFileChange(event, form) {
    const uploadTask = this.cameraService.saveImage(event.target.files[0]);
    uploadTask.then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        form.patchValue({ banner_url: url });
      });
    });
  }
}
