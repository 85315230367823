<mat-toolbar>
  @if(inventoryAction.document_id) {
    <i class="fa fa-arrow-left" (click)="openDetails(inventoryAction.document_id)" (keyup)="openDetails(inventoryAction.document.id)" tabindex="0"></i>
  }
  {{ title }}
  <div class="expander"></div>
  @if(inventoryAction.document_id) {
    <span
        (click)="openDetails(inventoryAction.document_id)"
        (keyup)="openDetails(inventoryAction.document.id)"
        tabindex="1"
        [style]="{cursor: 'pointer'}">
      Edita la acción #{{ inventoryAction.document.correlative_id }}
    </span>
  } @else {
    @if (inventoryAction.edited) {
      <span>Editado</span>
    }
  }
</mat-toolbar>

<mat-table [dataSource]="inventoryAction.inventory_action_products" matSort>
  <ng-container matColumnDef="product_id">
    <mat-header-cell *matHeaderCellDef> Producto </mat-header-cell>
    <mat-cell *matCellDef="let element"> <b>[{{ element.product.code }}]</b>&nbsp;{{ element.product.name }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef> Cantidad </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.quantity }}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

@if (inventoryAction.change_log.length > 0) {
  Historial de cambios
  @for(childEditAction of inventoryAction.change_log; track childEditAction.id) {
    <span (click)="openDetails(childEditAction.id)"
          (keyup)="$event.keyCode === 13 && openDetails(childEditAction.id)"
          [style]="{cursor: 'pointer'}"
          tabindex="1">
      ID: #{{childEditAction.correlative_id}} &nbsp; Estado: {{childEditAction.status.name}}
    </span>
  }
}

<app-order-works [order]="inventoryAction" (load)="reloadInventoryAction()"></app-order-works>

<div class="buttons">
  <div class="expander"></div>
  @if(inventoryAction.status_id) {
    <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
      <i class="fa fa-pencil-alt"></i>
      <span class="hide-mobile">{{inventoryAction.status_id | status:'name': statuses}}</span>
    </button>
  }
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let status of statuses" (click)="setStatus(status.id)" [style.color]="status.color">{{status.name}}</button>
  </mat-menu>
</div>
