import {Injectable} from '@angular/core';
import {BaseService} from "../services/_base.service";
import {UserService} from "../services/user.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PrioritiesService extends BaseService{

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  all() {
    return this.http.get(this.apiURL + '/priorities', this.getParams()).toPromise();
  }
  complete(id){
    return this.http.post(this.apiURL + '/priorities/'+id+'/complete',{},this.getOptions()).toPromise();
  }
}
