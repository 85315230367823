import {Component, OnInit} from '@angular/core';
import {AdminService} from "./admin/services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import { HttpResponse } from "@angular/common/http";
import {CURRENT_VERSION} from "./app.config";
import {SwUpdate} from "@angular/service-worker";
import {MatDialog} from "@angular/material/dialog";
import {UpdateAvailableComponent} from "./update-available/update-available.component";
import { ListsService } from './admin/services/lists.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  loadedApp = false;
  error = false;
  constructor(
    private companyService: AdminService,
    private router: Router,
    private title: Title,
    private route: ActivatedRoute,
    private swUpdate: SwUpdate,
    private matDialog: MatDialog,
    private listsService: ListsService
  ){

  }
  ngOnInit(){
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if(token){
      localStorage.setItem('workpoint_token',token);
    }
    this.swUpdate.versionUpdates.subscribe(evt => {
      if(evt.type === 'VERSION_READY'){
        this.matDialog.open(UpdateAvailableComponent,{disableClose: true});
      }
    });
    this.router.events.subscribe((val) => {
      if(this.router.url.indexOf('/manager') === 0){
        this.loadedApp = true;
        this.title.setTitle(`Manager - ControlCar`);
      }
    });
    this.companyService.setupApp(token).toPromise().then((response: HttpResponse<any>)=>{
      if(isRunningOnIframe()){
        checkIfIFrameIsAllowed(response.body.preferences.booking_addon.allowed_domains);
      }

      this.companyService.setCompany(response.body);
      this.title.setTitle(`${response.body.name} - ControlCar`);
      this.listsService.load().then((lists)=>{
        this.loadedApp = true;
      });
      console.log("Client: "+CURRENT_VERSION+ " Server: "+response.body.current_version);
      if(response.body.workpoint_mode){
        this.router.navigate(['workpoint','login']);
      }
    }).catch((error)=>{
      this.error = true;
    });
  }
}

function isRunningOnIframe() {
  return top !== self;
}

function checkIfIFrameIsAllowed(allowedDomains: string) {
  if (isIframeInSameDomain()) return;

  if(document.referrer && allowedDomains && isParentDomainIn(allowedDomains)){
    return;
  }

  // redirect to main page where iframe will be rejected
  this.router.navigate(['iframe-rejected']);
}

function isIframeInSameDomain() {
  return document.referrer.includes(window.location.hostname);
}

function isParentDomainIn(allowedDomains: string) {

  const allowedDomainsRegexArray = toRegexArray(allowedDomains)
  const parentDomain = (new URL(document.referrer)).origin;

  return allowedDomainsRegexArray.some((allowedDomainRegex) => {
    try {
      return allowedDomainRegex.test(parentDomain);
    } catch (e) {
      return false;
    }
  });
}

function toRegexArray(domains: string) {
  return toCleanArray(domains).map(toRegex);
}

function toCleanArray(domain: string) {
  return domain
    .trim()
    .replace(/\s+/, ' ')
    .split(' ')
    .filter(Boolean)
    .map(d => d.trim())
}

function toRegex(domain: string) {
  const base = domain
    .replace(/\./g, "\\.") // escape dots in domain names
    .replace(/\*/g, ".*") // replace wildcard with regex

  return new RegExp(`^${base}$`);
}
