import {Pipe} from "@angular/core";

@Pipe({
  name: 'truncate'
})
export class TruncatePipe {
  transform(value: string, args: string[]) : string {
    if (value) {
      const limit = args.length > 0 ? parseInt(args[0], 10) : 10;
      const trail = args.length > 1 ? args[1] : '...';

      return value.length > limit ? value.substring(0, limit) + trail : value;
    }else {
      return "";
    }

  }
}
