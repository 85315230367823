import { Route } from '@angular/router';

import { CrmClientComponent } from './crm-client/crm-client.component';
import { CrmDetailsComponent } from './crm-details/crm-details.component';
import { CRMViewComponent } from './crmview/crmview.component';

export const crmRoutes: Route[] = [
  { path: 'clients', component: CRMViewComponent },
  { path: 'clients/:id', component: CrmClientComponent },
  { path: ':id', component: CrmDetailsComponent },
  { path: '**', redirectTo: 'clients' },
];
