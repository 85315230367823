import { HttpClient } from '@angular/common/http';
import { Injectable, computed, effect, signal } from '@angular/core';
import { BaseService } from 'app/services/_base.service';
import { UserService } from 'app/services/user.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerService extends BaseService{
  user = signal(null)
  authToken = computed(() => {
    if(this.user()){
      return this.user().auth_token;
    }else if(localStorage.getItem('manager_token')){
      return localStorage.getItem('manager_token');
    }
    return null;
  })
  constructor(
    public http:HttpClient,
    public router:Router,
  ) {
    super(http,null);
    effect(() => {
      if(this.user()){
        localStorage.setItem('manager_token',this.user().auth_token);
      }
    })
    
  }

  login(params){
    return new Promise((resolve, reject) => {
      this.http.post(this.managerURL+'/auth',{
        manager: this.permitParams(params,'email','password','otp')
      },this.getParams()).subscribe((response:any)=>{
        if(response && response.body){
          resolve(response.body);
          this.user.set(response.body);
        }else{
          reject(response.error);
        }
      });
    });
  }
  relogin(){
    this.reauth(this.authToken()).subscribe({
      next: (response:any) => {
        if(response && response.body){
          this.user.set(response.body);
        }
      },
      error: (error) => {
        this.logout();
      }
    });
  }
  logout(){
    this.user.set(null);
    localStorage.removeItem('manager_token');
    this.router.navigate(['manager','login']);
  }
  reauth(token){
    return this.http.post(this.managerURL+'/reauth',{auth_token: token},this.getParams())
  }
  managerAuthCallback(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.reauth(this.authToken()).subscribe({
        next: (response:any) => {
          if(response && response.body){
            this.user.set(response.body);
            resolve(true);
          }
          resolve(false);
        },
        error: (error) => {
          resolve(false);
        }
      })
    });
  }
}
