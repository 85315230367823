<div class="wrapper">
  <div class="working" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <app-analytics [snapshot]="snapshot"></app-analytics>
  </ng-container>

</div>

