<app-sign *ngIf="showSign" (saved)="setSign($event)"></app-sign>
<div class="wrapper" [class.with-data]="data">
  <form [formGroup]="form" autocomplete="off">

    <ng-container *ngIf="locations && locations.length > 1">
      <div class="subtitle"><i class="fa fa-warehouse"></i> {{adminService.getText('location','capitalize')}}</div>
      <fieldset>
        <mat-form-field>
          <mat-label>{{adminService.getText('location','capitalize')}}</mat-label>
          <mat-select
            formControlName = "location_id"
            autocomplete="off"
            required
          >
            <mat-option *ngFor="let location of locations" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </fieldset>
    </ng-container>
    <ng-container *ngIf="company.preferences.booking_type === 'professional'">
      <div class="subtitle"><i class="fa fa-user"></i> {{adminService.getText('professional','capitalize')}}</div>
      <fieldset>
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <mat-select
            formControlName="user_id"
            autocomplete="off"
            [disabled]="users.length === 0"
            required
          >
            <mat-option *ngFor="let user of users" [value]="user.id">
              {{user.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </fieldset>
    </ng-container>
    <ng-container *ngIf="roleService.can('read','client') | async">
      <div class="subtitle">{{adminService.getText('client','capitalize')}}</div>
      <app-client-form [client]="checkin.client" [parentFormGroup]="form.controls.client" (vehicleSelected)="objectSelected($event)"></app-client-form>
    </ng-container>
    <ng-container *ngIf="(roleService.can('read','client') | async) === false">
      <div class="subtitle">{{adminService.getText('client')}} #{{checkin.client_id}}</div>
      <div class="info" [class.last]="(roleService.can('read','client') | async) === false">
        <div class="name">Nombre</div>
        <div class="value">{{checkin.client?.name || 'No existe el' + adminService.getText('client','lowercase')}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="company.object === 'Vehicle'">
      <div class="subtitle" id="vehicle-form">{{adminService.getText('object','capitalize')}}</div>
      <app-vehicle-form [model]="checkin.object" [parentFormGroup]="form.controls.object" [parentControl]="form" (plateSelected)="form.controls.client.patchValue($event)"></app-vehicle-form>
    </ng-container>
    <ng-container *ngIf="company.object === 'Custom'">
      <div class="subtitle" id="custom-form"><i class="fa fa-cubes"></i> {{adminService.getText('object','capitalize')}}</div>
      <app-custom-object-form [model]="checkin.object" [parentFormGroup]="form.controls.object" (plateSelected)="form.controls.client.patchValue($event)"></app-custom-object-form>
    </ng-container>
    <div class="subtitle">Información</div>
    <fieldset>
      <mat-form-field>
        <mat-label>{{adminService.getText('requirement','capitalize')}}</mat-label>
        <mat-select
          formControlName="requirement_id"
          autocomplete="off"
          required
        >
          <mat-option *ngFor="let requirement of types" [value]="requirement.id">
            {{requirement.name}} <span *ngIf="requirement.price">&nbsp;({{requirement.price | money}})</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{adminService.getText('comment','capitalize')}}</mat-label>
        <input matInput
          formControlName="comment"
          autocomplete="off"
        >
      </mat-form-field>
      <mat-form-field (click)="datetimePicker.open()">
        <mat-label>Fecha Finalización</mat-label>
        <input
          matInput
          [matDatetimepicker]="datetimePicker"
          [matDatepickerFilter]="onlyWeek"
          [min]="currentDate"
          placeholder="Fecha Finalización"
          formControlName="finish_date"
          name="finish_date"
          autocomplete="off"
          [required]="company.preferences.require_finish_date"
        >
      </mat-form-field>
      <mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
      <input formControlName="order_attachments" style="display: none" name="images"/>
      <input formControlName="checked_in" type="hidden"/>
    </fieldset>

    <div class="subtitle">{{adminService.getText('aditional_plural','capitalize')}}</div>
    <app-additionals-form
      [initialValue]="checkin.order_aditionals"
      [parentFormArray]="order_aditionals"
      (added)="addAdditional(this, $event)"
      (removed)="removeAdditional(this, $event)"
    ></app-additionals-form>

    <div class="check-in-inventory-action-form">
      <mat-expansion-panel hideToggle (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <div class="header-container">
            <div class="subtitle" ><i class="fa fa-wrench"></i>Detalle del servicio   <mat-icon>{{panelOpenState() ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon> </div>
            <div class="total">
              {{ totalValue * 1.19| money }}
            </div>
          </div>
        </mat-expansion-panel-header>
         <!--no tenia form.controls -->
        <app-inventory-action-form
          [parentFormGroup]="form.controls.inventory_action_for_consume"
          (dataChanged)="onTotalValueChange($event)"
          [brandId]="form.get('object.brand_id')?.value"
          parentModel="check_in"
          #inventoryForm>
        </app-inventory-action-form>

      </mat-expansion-panel>
    </div>

    <app-order-fields [model]="checkin" [parentFormGroup]="form" [reactiveFormMode]="true"></app-order-fields>
    <div class="subtitle">Imagenes</div>
    <app-image-uploader [photos]="checkin.order_attachments" [scrollTarget]="wrapper"></app-image-uploader>

  </form>
  <div>
    <span class="error" *ngIf="!checkin.order_attachments || checkin.order_attachments.length < (company.preferences.order_min_pictures >= 0 ? company.preferences.order_min_pictures : 4)"><i class="fa fa-exclamation-triangle"></i> Es necesario adjuntar por lo menos {{company.preferences.order_min_pictures >= 0 ? company.preferences.order_min_pictures : 4}} fotos</span>
    <span class="error" *ngIf="errortext"><i class="fa fa-exclamation-triangle"></i> {{errortext}}</span>
  </div>
</div>
<mat-toolbar class="footer">
  <div class="buttons">
    <button style="margin-right: 5px"mat-raised-button class="primary"
    [disabled]="working || !form.valid || (!order_attachments || order_attachments.length < (company.preferences.order_min_pictures >= 0 ? company.preferences.order_min_pictures : 4))"
    (click)="saveForm()"
    *ngIf="((roleService.can('update','check_in') | async) || !this.checkin.id || (!this.checkin.checked_in && ((roleService.can('create','check_in') | async)))) && (sign_url || !company.preferences.require_signature)"
    >Guardar</button>
    <button mat-raised-button class="primary"
    [disabled]="working" (click)="sign()" *ngIf="company.preferences.require_signature"
    >Firmar</button>
  </div>

  <div class="expander"></div>
  <span class="footer-total">{{ totalValue * 1.19| money }}</span>
</mat-toolbar>
