<!--
  <div class="icon-text-container">
    <i class="fa fa-cubes"></i>
    <span class="hide-mobile">{{appService.getText('client_plural')}}&nbsp;</span>
    <span>({{count}})</span>
  </div>
-->


<div class="wrapper">
  <div class="search-container">
    <div class="search-bar" >
      @if (searchControl.value) {
        <button mat-icon-button (click)="clearSearch()"> <mat-icon>arrow_back</mat-icon> </button>
      }
      @else {
        <mat-icon class="icon-container">search</mat-icon>
      }
      <input [formControl]="searchControl" name="search_query" autocomplete="off" autocorrect="off" spellcheck="false">
    </div>
  </div>

  <div class="statuses-container display-desktop">
    <div
        class="status-box"
        *ngFor="let status of statuses"
        (click)="toggleFilter(status.id)"
        onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);"
        (keyup)="$event.keyCode === 13 && toggleFilter(-1)"
        tabindex
    >
      <div class="status-content">
        <div class="checkbox-container" >
            @if (statusOn(status.id)) {
              <mat-icon [ngStyle]="{'color': status.color}" >check_box</mat-icon>
            }@else{
              <mat-icon class="default">check_box_outline_blank</mat-icon>
              <mat-icon class="on-hover" [ngStyle]="{'color': status.color}">check_box_outline_blank</mat-icon>
            }
        </div>
        <div class="counter">
          <span class="default" [ngStyle]="statusOn(status.id) ? {'color': status.color} : {}">{{totalByStatus[status.id]?.count ?? 0}}</span>
          <span class="on-hover" [ngStyle]="{'color': status.color}">{{totalByStatus[status.id]?.percent ?? 0 | number : '1.0-0'}}%</span>
        </div>
        <div class="status-name">
          {{status.name}}
        </div>
      </div>
    </div>

    <div
        class="status-box"
        (click)="toggleFilter(-1)"
        (keyup)="$event.keyCode === 13 && toggleFilter(-1)"
        tabindex
        onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);">
      <div class="status-content">
        <div class="checkbox-container" >

          @if (statusOn(-1)) {
              <mat-icon [ngStyle]="{'color': '#795548'}" >check_box</mat-icon>
          } @else {
            <mat-icon class="default">check_box_outline_blank</mat-icon>
            <mat-icon class="on-hover" [ngStyle]="{'color':'#795548'}">check_box_outline_blank</mat-icon>
          }
        </div>
        <div class="counter">
          <span class="default" [ngStyle]="statusOn(-1) ? {'color': '#795548'} : {}">{{totalByStatus[-1]?.count || 0}}</span>
          <span class="on-hover" [ngStyle]="{'color': '#795548'}">{{totalByStatus[-1]?.percent | number : '1.0-0'}}%</span>
        </div>
        <div class="status-name">
          Leídos
        </div>
      </div>
    </div>
    <div
        class="status-box"
        (click)="toggleFilter(-2)"
        (keyup)="$event.keyCode === 13 && toggleFilter(-2)"
        tabindex
        onclick="this.classList.add('clicked'); setTimeout(() => this.classList.remove('clicked'), 300);">
      <div class="status-content">
        <div class="checkbox-container" >
          @if (statusOn(-2)) {
              <mat-icon [ngStyle]="{'color': '#795548'}" >check_box</mat-icon>
          } @else {
            <mat-icon class="default">check_box_outline_blank</mat-icon>
            <mat-icon class="on-hover" [ngStyle]="{'color':'#795548'}">check_box_outline_blank</mat-icon>
          }
        </div>
        <div class="counter">
          <span class="default" [ngStyle]="statusOn(-1) ? {'color': '#795548'} : {}">{{totalByStatus[-2]?.count || 0}}</span>
          <span class="on-hover" [ngStyle]="{'color': '#795548'}">{{totalByStatus[-2]?.percent | number : '1.0-0'}}%</span>
        </div>
        <div class="status-name">
          Clicks
        </div>
      </div>
    </div>
  </div>



  <div class="table-container display-desktop" id="clients" appPagination (loadMore)="getMore()" >
    <mat-card class="box-shadowless">
      <mat-card-content style="padding: 0;">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="client-identifier">
            <th mat-header-cell *matHeaderCellDef> {{ appService.getText('client_identifier') }} </th>
            <td mat-cell *matCellDef="let client"> {{ client.rut | identifier }} </td>
          </ng-container>

          <ng-container matColumnDef="client-name">
            <th mat-header-cell *matHeaderCellDef> {{appService.getText('client')}} </th>
            <td mat-cell *matCellDef="let client"> {{ client.name | titlecase }} </td>
          </ng-container>

          <ng-container matColumnDef="client-email">
            <th mat-header-cell *matHeaderCellDef> <i class="fa fa-envelope"></i> &nbsp; Email </th>
            <td mat-cell *matCellDef="let client"> {{ client.email | lowercase }} </td>
          </ng-container>

          <ng-container matColumnDef="object-identifier">
            <th mat-header-cell *matHeaderCellDef> <i class="fa fa-{{company.object === 'Vehicle' ? 'car': 'cubes'}}"></i> &nbsp; Patente </th>
            <td mat-cell *matCellDef="let crm"> {{ crm.object_identifier | uppercase }} </td>
          </ng-container>

          <ng-container matColumnDef="status-list">
            <th mat-header-cell *matHeaderCellDef class="status-header"> Estado </th>
            <td mat-cell *matCellDef="let client">
              <div class="status-list">
                <div class="status" [style.color]="status.color" *ngFor="let status of statuses" [matTooltip]="status.name">
                  @if (getStatusCompletion(status.id, client) && status.id !== client.status_id) {
                    <i class="fa fa-check-square"></i>
                  }
                  @if (!getStatusCompletion(status.id, client) && status.id !== client.status_id) {
                    <i class="fa fa-square"></i>
                  }
                  @if (status.id === client.status_id) {
                    <i class="fa fa-minus-square"></i>
                  }
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="events">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let crm">
              @if (crm.readed_at){
                <i class="fa fa-eye event done" matTooltip="El cliente visualizó el correo el {{crm.readed_at | date : 'short'}}"></i>&nbsp;
              } @else {
                <i class="fa fa-eye event"></i>&nbsp;
              }
              @if (crm.clicked_at){
                <i class="fa fa-hand-pointer event done" matTooltip="El cliente hizo click al enlace del correo el {{crm.clicked_at | date : 'short'}}"></i>
              } @else {
                <i class="fa fa-hand-pointer event"></i>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/main', 'crm', row.id]" [hidden]="working"></tr>
        </table>

        <div class="empty" [hidden]="!working">
          <mat-spinner></mat-spinner>
        </div>

        @if (clients && clients.length === 0) {
          <div class="empty white-rounded">
            <span>No se han encontrado {{appService.getText('crm_plural')}}</span>
          </div>
        }

        <div class="load-more white-rounded" [hidden]="page >= total_pages" id="load-more">
          <mat-spinner [diameter]="20"></mat-spinner> Cargando {{appService.getText('crm_plural')}}...
        </div>

      </mat-card-content>
    </mat-card>
  </div>


  <div class="table-container display-mobile" id="clients" appPagination (loadMore)="getMore()">
    <div class="card" *ngFor="let client of clients" [routerLink]="['/main', 'crm', 'clients', client.id]" [hidden]="working">
      <div class="card-details">
        <div class="row">
          <span class="light"> {{appService.getText('client_identifier')}}:{{client.rut}}</span>
          <div class="expander"></div>
          <div class="status-list">
            <div class="status" [style.color]="status.color" *ngFor="let status of statuses" [matTooltip]="status.name">
              @if (getStatusCompletion(status.id, client) && status.id !== client.status_id) {
                <i class="fa fa-check-square"></i>
              }
              @if (!getStatusCompletion(status.id, client) && status.id !== client.status_id) {
                <i class="fa fa-square"></i>
              }
              @if (status.id === client.status_id) {
                <i class="fa fa-minus-square"></i>
              }
            </div>
          </div>
        </div>
        <div class="row title">
          <span class="bold">{{client.name}}</span>

        </div>
        <div class="row">
          <span>{{client.email}} </span>
        </div>
      </div>
    </div>

    @if (clients && clients.length === 0) {
      <div class="empty">
        <span>No se han encontrado {{appService.getText('crm_plural')}}</span>
      </div>
    }

    <div class="load-more" [hidden]="page >= total_pages" id="load-more">
      <mat-spinner [diameter]="20"></mat-spinner> Cargando {{appService.getText('crm_plural')}}...
    </div>

    <div class="loading" [hidden]="!working">
      <mat-spinner></mat-spinner>
    </div>

  </div>



</div>

@if (roleService.can('create', 'client') | async) {
  <mat-toolbar class="footer">
    <div class="buttons">
      <div class="expander"></div>
      <button mat-raised-button (click)="onAddNewCrm()" class="primary">
        <span><mat-icon>add</mat-icon></span>
      </button>
    </div>
  </mat-toolbar>
}
