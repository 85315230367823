import {NgModule} from "@angular/core";
import {BaseModule} from "../base.module";
import {SignComponent} from "./sign.component";

@NgModule({
  imports: [
    BaseModule
  ],
  declarations: [
    SignComponent
  ],
  exports: [
    SignComponent
  ]
})
export class SignModule { }
