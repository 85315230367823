import { Component, OnInit, Optional } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'app/admin/services/admin.service';
import { BookingComponent } from 'app/main/booking/booking.component';
import { OrderNotificationsComponent } from 'app/main/order-notifications/order-notifications.component';
import { Client } from 'app/models/client.model';
import { CrmForBooking } from 'app/models/crm.model';
import { ObjectModel } from 'app/models/object.model';
import { Status } from 'app/models/status.model';
import { RoleService } from 'app/role/role.service';
import { StatusesService } from 'app/services/statuses.service';
import { WorkDialogComponent } from 'app/work/work-dialog/work-dialog.component';
import { WorkRequestBottomSheetComponent }
  from 'app/work/work-request-bottom-sheet/work-request-bottom-sheet.component';
import { catchError, of } from 'rxjs';

import { CRMService } from '../crm.service';

@Component({
  selector: 'app-crm-details',
  templateUrl: './crm-details.component.html',
  styleUrls: ['./crm-details.component.scss', '../crm-client/crm-client.component.scss'],
})
export class CrmDetailsComponent implements OnInit {
  client: Client;

  company = this.adminService.local$.getValue();

  company_logo: string;

  crm: CrmForBooking;

  crmNotFound = false;

  forbidden = false;

  id: number;

  loading = false;

  statuses: Status[] = [];

  vehicle: ObjectModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    public adminService: AdminService,
    private crmService: CRMService,
    private dialog: MatDialog,
    @Optional() private matDialogRef: MatDialogRef<CrmDetailsComponent>,
    public roleService: RoleService,
    private router: Router,
    private statusesService: StatusesService,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.forEach((params) => {
      if (params.id) {
        this.id = params.id;
        this.load();
      }
    });

    this.company = this.adminService.local$.getValue();
    this.statusesService.statuses('StatusForCrmForBooking').subscribe((statuses) => {
      this.statuses = statuses;
    });
  }

  load() {
    return new Promise((resolve, reject) => {
      this.crmService.get(this.id).pipe(
        catchError((error) => {
          console.log(error);
          if (error.status === 403) {
            this.forbidden = true;
          }
          return of(null);
        }),
      ).subscribe((response) => {
        this.loading = false;
        if (!response.body || response.status !== 200) {
          this.crmNotFound = true;
          reject();
          return;
        }

        this.crm = response.body;
        resolve(this.crm);
      });
    });
  }

  goBack() {
    if (this.matDialogRef instanceof MatDialogRef) {
      this.matDialogRef.close();
    } else {
      this.router.navigate(['main', 'crm', 'clients']);
    }
  }

  goToOrder(order_id: number) {
    this.router.navigate(['main', 'orders', order_id]);
  }

  openNotifications() {
    this.dialog.open(OrderNotificationsComponent, { data: { client_id: this.crm.client.id } });
  }

  addWork() {
    let customActions = [];
    if (this.crm?.booking?.checked_in) {
      customActions = [];
    } else if (this.crm?.booking) {
      customActions = [{ name: 'Reagendar Vehículo', action: 'booking' }];
    } else {
      customActions = [{ name: 'Agendar Vehículo', action: 'booking' }];
    }

    const bottomSheetRef = this.bottomSheet.open(WorkRequestBottomSheetComponent, {
      data: {
        document_id: this.crm.id,
        document_type: 'Crm',
        status_id: this.crm.status.id,
        work_type: 'WorkForCrmForBooking',
        customActions,
      },
      panelClass: 'work-request-bottom-sheet',
    });

    bottomSheetRef.afterDismissed().subscribe((value) => {
      if (!value) return;

      if (value === 'booking') {
        this.createBooking();
      } else {
        this.openLastWork();
      }
    });
  }

  openLastWork() {
    this.load().then(() => {
      const lastWork = this.crm.works[this.crm.works.length - 1];
      this.dialog.open(WorkDialogComponent, {
        data:
        { id: lastWork.id, type: lastWork.type },
        minWidth: '80%',
      }).afterClosed().toPromise().then(() => this.load());
    });
  }

  createBooking() {
    let data = {};
    if (this.crm.booking) {
      data = { id: this.crm.booking.id };
    } else {
      data = {
        client: this.crm.client,
        object: this.crm.object,
        requirement_id: this.crm.requirement.id,
        from: 'crm',
        document_id: this.crm.id,
        document_type: 'Crm',
      };
    }

    this.dialog.open(BookingComponent, {
      minWidth: '80%',
      data,
    });
  }
}
