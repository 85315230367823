import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'wpl-custom-params',
  templateUrl: './custom-params.component.html',
  styleUrls: ['./custom-params.component.scss']
})
export class CustomPlaceholderComponent implements OnInit {
  @Input() workRequest:any = {work: {},order:{}};
  @Input() parentForm = new UntypedFormGroup({});
  @Input() clientMode = false;
  constructor() { }

  ngOnInit() {
    if(this.workRequest && !this.workRequest.work){
      this.workRequest.work = {custom_response_type: 'text'};
    }
  }

}
