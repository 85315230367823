import {
  Component, OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'app/admin/services/category.service';
import { ProductsService } from 'app/admin/services/products.service';
import { Category } from 'app/models/category.model';
import { Brand } from 'app/models/types';
import { BrandService } from 'app/services/brand.service';
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';
import { map, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-admin-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss', '../admin-products.component.scss'],
})
export class AdminProductListComponent implements OnInit {
  public brands: Brand[] = [];

  public categories$: Observable<Category[]>;

  public categoryId: number;

  public crudService: ICRUDService;

  public filterCustom: number[];

  public form = this.fb.group({
    brand_ids: this.fb.control([]),
    category_ids: this.fb.control([], Validators.required),
    code: this.fb.control('', Validators.required),
    custom_fields: new UntypedFormGroup({}),
    description: this.fb.control('', Validators.required),
    name: this.fb.control('', Validators.required),
    price: this.fb.control(0.00, Validators.required),
    unit: this.fb.control(''),
  });

  public parentCategory: Category;

  constructor(
    private activeRoute: ActivatedRoute,
    private brandService: BrandService,
    private categoryService: CategoryService,
    public fb: UntypedFormBuilder,
    public productsService: ProductsService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.activeRoute.params.pipe(first()).forEach((params) => {
      if (params.category_id) {
        this.categoryId = params.category_id;
        this.categoryService.get(params.category_id).subscribe((value) => {
          this.parentCategory = value.body;
        });
      }
      this.categories$ = this.categoryService.categories();
      this.brandService.all().subscribe((value) => {
        this.brands = value.body;
      });

      this.form.get('category_ids').valueChanges
        .pipe(
          switchMap((category_ids: number[]) => this.categoryService.categories().pipe(
            map((categories: Category[]) => (
              category_ids
                ? categories.filter((category) => category_ids.includes(category.id))
                : []
            )),
          )),
        )
        .subscribe((categories: Category[]) => {
          this.form.removeControl('custom_fields');
          this.form.addControl('custom_fields', new UntypedFormGroup({}));
          const customFieldIds = categories.reduce((acc, category) => acc.concat(category.custom_field_ids), []);
          this.filterCustom = customFieldIds;
        });
    });
  }

  goUp() {
    this.router.navigate(['admin', 'main', 'products']);
  }
}
