<app-chart-filters [includedFilters]="includedFilters" *ngIf="!working && !snapshot"></app-chart-filters>
<div class="wrapper" *ngIf="!working && filters">
  <mat-card class="kpis">
    <mat-card-header>
      <mat-card-subtitle class="kpis__title">
        Has obtenido en Comisiones
      </mat-card-subtitle>
      <mat-card-title class="kpis__value_wrapper">
        <span class="kpis__value">{{ totalShares | money }}</span>
        <div class="kpis__performance" *ngIf="totalSharesPerformance">
          <div class="values" [class.good]="totalSharesPerformance > 0" [class.bad]="totalSharesPerformance < 0">
            <i class="fa fa-caret-up"></i>
            <i class="fa fa-caret-down"></i>
            {{ totalSharesPerformance | number : '1.0-0'}}%
          </div>
          <i class="fa fa-question-circle" matTooltip="Rendimiento con respecto al mismo periodo el año anterior."></i>
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        {{ start_at | period: end_at : periodGroup }}
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card class="kpis">
    <mat-card-header>
      <mat-card-subtitle class="kpis__title">
        Cantidad de Ordenes Comisionadas
      </mat-card-subtitle>
      <mat-card-title class="kpis__value_wrapper">
        <span class="kpis__value">{{ totalOrders | number }}</span>
        <div class="kpis__performance" *ngIf="totalOrdersPerformance">
          <div class="values" [class.good]="totalOrdersPerformance > 0" [class.bad]="totalOrdersPerformance < 0">
            <i class="fa fa-caret-up"></i>
            <i class="fa fa-caret-down"></i>
            {{ totalOrdersPerformance | number : '1.0-0'}}%
          </div>
          <i class="fa fa-question-circle" matTooltip="Rendimiento con respecto al mismo periodo el año anterior."></i>
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        {{ start_at | period: end_at : periodGroup }}
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card class="kpis">
    <mat-card-header>
      <mat-card-subtitle class="kpis__title">
        Promedio Comisión / Orden
      </mat-card-subtitle>
      <mat-card-title class="kpis__value_wrapper">
        <span class="kpis__value">{{ avgShares | money: '1.0-0' }}</span>
        <div class="kpis__performance" *ngIf="avgSharesPerformance">
          <div class="values" [class.good]="avgSharesPerformance > 0" [class.bad]="avgSharesPerformance < 0">
            <i class="fa fa-caret-up"></i>
            <i class="fa fa-caret-down"></i>
            {{ avgSharesPerformance | number : '1.0-0'}}%
          </div>
          <i class="fa fa-question-circle" matTooltip="Rendimiento con respecto al mismo periodo el año anterior."></i>
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        {{ start_at | period: end_at : periodGroup }}
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>
<div class="wrapper">
  <ng-container>
    <app-chart-card
      chartKey="shares_by_period"
      mainTitle="Comisiones Netas"
      mainSubtitle="Por Periodo"
      [customCharts]="[{key: 'shares_by_period', name: 'Comisiones', type: 'column'}, {key: 'shares_average_by_period', name: 'AVG', type: 'spline'}]"
      [filters]="filters"
      [snapshot]="snapshot"
      [hasDetails]="false"
      dataLabelSuffix="K" />
    <app-chart-card
      chartKey="shares_by_user"
      mainTitle="Comisiones Netas"
      mainSubtitle="Por usuario"
      [customCharts]="[{key: 'shares_by_user', name: 'Comisiones', type: 'column'}]"
      [filters]="filters"
      [snapshot]="snapshot"
      [excludedKeysInDetails]="['parent_item_type', 'parent_item_id']"
      dataLabelSuffix="K"
      displayMode="total__1.2-2__K" />
    <app-chart-card
      chartKey="shares_by_name"
      mainTitle="Comisiones Netas"
      mainSubtitle="Por Nombre"
      [customCharts]="[{key: 'shares_by_name', name: 'Comisiones', type: 'column'}]"
      [filters]="filters"
      [snapshot]="snapshot"
      [hasDetails]="false"
      dataLabelSuffix="K" />

    @if (!onlyOwn) {
      <app-chart-card
        chartKey="shares_ranking_by_user"
        mainTitle="Comisiones Netas"
        mainSubtitle="Top 10 Usuarios"
        chartType="bar"
        [customCharts]="[{key: 'shares_ranking_by_user', name: 'Ranking', type: 'bar'}]"
        [filters]="filters"
        [snapshot]="snapshot"
        [hasDetails]="false"
        dataLabelSuffix="K"/>
    }
  </ng-container>
</div>
