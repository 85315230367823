<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración de Recepción</mat-card-title>
    <mat-card-subtitle>Configuración general</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="hours">
          <form [formGroup]="companyForm">
            <fieldset formGroupName="preferences">
              <mat-form-field>
                <mat-label>Terminos y Condiciones</mat-label>
                <textarea matInput placeholder="Terminos y Condiciones" formControlName="order_legend" rows="10"></textarea>
                <mat-hint>Terminos y condiciones de órdenes de trabajo</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Imagenes mínimas recepción</mat-label>
                <input matInput type="number" placeholder="Imagenes mínimas recepción" formControlName="order_min_pictures" rows="10" pattern="\d*"/>
                <mat-hint>Numero minimo de imagenes para poder recepciónar</mat-hint>
              </mat-form-field>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="require_signature">
                    Requerir Firma
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="require_finish_date">
                    Requerir Fecha de Finalización
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col">
                  <mat-checkbox formControlName="autocomplete_order_values">
                    Autocompletar valores de la orden
                  </mat-checkbox>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<app-status-form
  modelName
  title="Estados de una orden de trabajo"
  type="StatusForOrder"
  [showStatuses]="true"
  [showNotifyClient]="true"
  [showTransitions]="true"
  [showTransitionRequirement]="true"
  [showStatusTransitionRequirement]="true"
  >
</app-status-form>
<app-admin-custom-fields [service]="fieldsService" model="FieldForOrder"></app-admin-custom-fields>
<app-client-notification-form model="Order"></app-client-notification-form>
