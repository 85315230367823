<mat-toolbar>
  <i class="fa fa-arrow-left" (click)="goBack()" (keyup)="goBack()" tabindex="0"></i>
  {{product?.name}}
  <div class="expander"></div>
</mat-toolbar>

<div class="wrapper">
  <div class="row">
    <div class="info">
      <div class="info-row">
        <div class="info-label">Tipo de producto</div>
        <div class="info-value">Item</div>
      </div>
      <div class="info-row">
        <div class="info-label">Categoría</div>
        <div class="info-value">{{ product?.category?.name }}</div>
      </div>
      <div class="info-row">
        <div class="info-label">Unidad</div>
        <div class="info-value">{{ product?.category?.unit }}</div>
      </div>
      <div class="info-row">
        <div class="info-label">SKU</div>
        <div class="info-value">{{product?.code}}</div>
      </div>
      <div class="info-row">
        <div class="info-label">Precio de venta</div>
        <div class="info-value">{{product?.price | money}}</div>
      </div>
    </div>

    <div>
      <mat-table [dataSource]="dataSourceForStockLocation">
        <ng-container matColumnDef="warehouse">
          <mat-header-cell *matHeaderCellDef> Bodega </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.warehouse.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="stock_on_hand">
          <mat-header-cell *matHeaderCellDef> Stock En Mano </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{ element.stock_on_hand }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="committed_stock">
          <mat-header-cell *matHeaderCellDef> Stock Reservado </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{ element.committed_stock }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="available_for_sale">
          <mat-header-cell *matHeaderCellDef> Disponible para venta </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{ element.available_for_sale }}
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsForStockLocation"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsForStockLocation;"></mat-row>
      </mat-table>
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    </div>
  </div>
  <div>
    <h2>Historial de Inventario</h2>
    <mat-table [dataSource]="dataSourceForInventoryLog">
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ titleForType(element.action.type) }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef> Cantidad </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="status-text" [style.color]="element.action.status.color"> {{element.action.status.name}} </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ (element.action.completed_at || element.action.estimated_completion_date) | date:'dd/MM/yyyy HH:mm' }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="warehouse">
        <mat-header-cell *matHeaderCellDef> Bodega </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.warehouse.name }} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumnsForInventoryLog"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsForInventoryLog;"></mat-row>
    </mat-table>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <mat-paginator
      #paginator
      [length]="totalData"
      [pageSizeOptions]="pageSizes"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
