import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class RequirementsService extends BaseService implements ICRUDService{
  params = ['name','order','max_daily_bookings','duration','price', 'location_ids'];
  model = 'Requirement';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null, page = 1){
    return this.http.get(this.adminURL+'/requirements',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/requirements/',{
      requirement: this.permitNullParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/requirements/'+id,{
      requirement: this.permitNullParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/requirements/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/requirements/'+id+'/restore',this.getParams());
  }
  import(items=null){
    return this.http.post(this.adminURL+'/requirements/import',{items: items},this.getParams());
  }
  swap(ids){
    return this.http.post(this.adminURL+'/requirements/swap',{ids},this.getParams());
  }

}
