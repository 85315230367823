import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminService } from 'app/admin/services/admin.service';
import { Booking } from 'app/models/booking.model';
import moment from 'moment';
import { Observable } from 'rxjs';

import { BaseService } from './_base.service';
import { HolydaysService } from './holydays.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService extends BaseService {
  constructor(http: HttpClient, userService: UserService, private holidayService: HolydaysService, private adminService: AdminService) {
    super(http, userService);
  }

  create(value: any) {
    return this.http.post<Booking>(`${this.apiURL}/bookings`, value, this.getOptions());
  }

  get(id: number, token?: string): Observable<HttpResponse<Booking>> {
    return this.http.get<Booking>(`${this.apiURL}/bookings/${id}`, this.getParams({ token }));
  }

  update(id: number, value: any, token?: string) {
    return this.http.put(`${this.apiURL}/bookings/${id}`, value, this.getOptions({ token }));
  }

  delete(id: number, token?: string) {
    return this.http.delete(`${this.apiURL}/bookings/${id}`, this.getOptions({ token }));
  }

  searchRut(rut) {
    return this.http.post(`${this.apiURL}/bookings/rut`, rut, this.getOptions());
  }

  searchFree(booking) {
    const url = '/bookings/check';
    return this.http.post(
      this.apiURL + url,
      { booking },
      this.getOptions(),
    );
  }

  toggleBlock(date: string, hour?: string, user_id?: number | string) {
    return this.http.post(
      `${this.apiURL}/bookings/toggle_block`,
      { date, hour, user_id },
      this.getOptions(),
    );
  }

  all(start_at: string, end_at: string) {
    return this.http.get(`${this.apiURL}/bookings`, this.getParams({ start_at, end_at }));
  }

  clientNotification(id, order_id, token) {
    return this.http.get(`${this.apiURL}/bookings/${id}/notification`, this.getParams({ order_id, token }));
  }

  saveNotification(order_id, body, token) {
    return this.http.post(
      `${this.apiURL}/order_notifications/`,
      { order_notification: body },
      this.getParams({ order_id, token }),
    );
  }

  externalMails() {
    return this.http.get(`${this.apiURL}/bookings/external_mails/`, this.getOptions());
  }

  removeExternalMail(id) {
    return this.http.delete(`${this.apiURL}/bookings/${id}/external_mails/`, this.getOptions());
  }

  // calculate business days
  businessDays(start) {
    const excluded = this.holidayService.blockedDays.holydays.map((v) => v.date);
    const workingDays = this.adminService.local$.getValue().days_preferences.work_days;
    const date = moment(start).utc(true);
    start = moment(start).utc(true);
    const end = moment();
    let seconds = 0;
    const days = [];
    while (date.format('YYYY-MM-DD') <= end.format('YYYY-MM-DD')) {
      const day = date.isoWeekday();
      if (workingDays[day - 1].enabled && !excluded.includes(date.toISOString().split('T')[0])) {
        // if first day equals last day
        if (start.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')) {
          const time = start.format('HH:mm:ss');
          const time2 = end.format('HH:mm:ss');
          const startTime = moment(time, 'HH:mm:ss').utc(true);
          const endTime = moment(time2, 'HH:mm:ss');
          const maxEnd = moment(workingDays[day - 1].end_time, 'HH:mm:ss');
          const minStart = moment(workingDays[day - 1].start_time, 'HH:mm:ss');
          if (startTime.isBefore(minStart) && endTime.isAfter(maxEnd)) {
            seconds += (maxEnd.diff(minStart, 'seconds'));
          } else if (startTime.isBefore(minStart)) {
            seconds += (endTime.diff(minStart, 'seconds'));
          } else if (endTime.isAfter(maxEnd)) {
            seconds += (maxEnd.diff(startTime, 'seconds'));
          } else {
            seconds += (endTime.diff(startTime, 'seconds'));
          }
        }
        // if first day
        else if (date.format('YYYY-MM-DD') === start.format('YYYY-MM-DD')) {
          // get date time as moment
          const time = moment(date).format('HH:mm:ss');
          const startTime = moment(time, 'HH:mm:ss').utc(true);
          // get end hour
          const endHour = moment(workingDays[day - 1].end_time, 'HH:mm:ss');
          // difference
          const diff = moment.duration(endHour.diff(startTime));
          // add to seconds if diff is greater than 0
          if (diff.asSeconds() > 0) {
            seconds += diff.asSeconds();
          }
        }
        // if last day
        else if (date.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')) {
          // get date time as moment
          const time = moment(end).format('HH:mm:ss');
          const startTime = moment(workingDays[day - 1].start_time, 'HH:mm:ss');
          // get end hour
          const endTime = moment(time, 'HH:mm:ss');
          // difference
          const diff = moment.duration(endTime.diff(startTime));
          if (diff.asSeconds() > 0) {
            seconds += diff.asSeconds();
          }
        }
        // if middle day
        else {
          // get date time as moment
          const startTime = moment(workingDays[day - 1].start_time, 'HH:mm:ss');
          // get end hour
          const endTime = moment(workingDays[day - 1].end_time, 'HH:mm:ss');
          // difference
          const diff = moment.duration(endTime.diff(startTime));
          if (diff.asSeconds() > 0) {
            seconds += diff.asSeconds();
          }
        }
        days.push(date.format('YYYY-MM-DD'));
      }
      date.add(1, 'days');
    }
    return { days: days.length, seconds };
  }
}
