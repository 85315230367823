<mat-card  style="margin-bottom: 1em">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración de Recepción</mat-card-title>
    <mat-card-subtitle>Configuración general</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="workdays" style="padding-left: 15px">
          <form [formGroup]="formShow">
            <fieldset [formGroupName]="'client'">
              <h4>Campos Cliente Agendamiento</h4>
              <div class="col-full">
                <mat-checkbox formControlName="name">Nombre</mat-checkbox>
                <mat-checkbox formControlName="address">Dirección</mat-checkbox>
                <mat-checkbox formControlName="city">Comuna</mat-checkbox>
                <mat-checkbox formControlName="phone">Teléfono</mat-checkbox>
              </div>
            </fieldset>
          </form>
          <form [formGroup]="formRequired">
            <fieldset [formGroupName]="'client'">
              <h4>Campos Cliente Orden</h4>
              <div class="col-full">
                <mat-checkbox formControlName="name">Nombre</mat-checkbox>
                <mat-checkbox formControlName="address">Dirección</mat-checkbox>
                <mat-checkbox formControlName="city">Comuna</mat-checkbox>
                <mat-checkbox formControlName="phone">Teléfono</mat-checkbox>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<app-admin-custom-fields [service]="fieldsService" model="FieldForClient"></app-admin-custom-fields>
<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-users fa-2x"></i></div>
    <mat-card-title>Clientes</mat-card-title>
    <mat-card-subtitle>Administrar clientes</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="clientsService" [itemTemplate]="itemTemplate" [formTemplate]="formTemplate" [formGroup]="form">
      <ng-template #itemTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formTemplate let-form="form">
        <form [formGroup]="form">
          <app-client-form [parentFormGroup]="form" [hideKms]="true"></app-client-form>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>


