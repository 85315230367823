<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>Notificaciones</mat-card-title>
    <mat-card-subtitle>Administrar las notificaciones a tus clientes o usuarios.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager
        [service]="clientNotificationsService"
        [itemTemplate]="itemTemplate"
        [formTemplate]="formTemplate"
        [formGroup]="form"
        [args]="['ClientNotificationFor' + model]">
      <ng-template #itemTemplate let-item>
        {{item.name}}
      </ng-template>
      <ng-template #formTemplate let-form="form" let-item="value">
        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Asunto</mat-label>
            <input matInput placeholder="Título" formControlName="name" autocomplete="off" required>
            <mat-hint>Será el asunto de el correo</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Notificar a</mat-label>
            <mat-select placeholder="Notificar a" formControlName="roles_ids" [multiple]="true">
              <mat-option *ngFor="let user of users" [value]="user.id">{{user.name}}</mat-option>
            </mat-select>
            <mat-hint>Listado de usuarios a notificar</mat-hint>
          </mat-form-field>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Estados (opcional)</mat-label>
                <mat-select placeholder="Estados (opcional)" formControlName="statuses_ids" [multiple]="true">
                  <mat-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</mat-option>
                </mat-select>
                <mat-hint>Enviar en ciertos estados</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Trabajos (opcional)</mat-label>
                <mat-select placeholder="Trabajos (opcional)" formControlName="works_ids" [multiple]="true">
                  <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
                </mat-select>
                <mat-hint>Enviar en ciertos trabajos</mat-hint>
              </mat-form-field>

              @if(model.startsWith('Crm')) {
                <mat-form-field>
                  <mat-label>Transiciones (opcional)</mat-label>
                  <mat-select placeholder="Transiciones (opcional)" formControlName="requirement_transitions_ids" [multiple]="true">
                    <mat-option *ngFor="let transition of transitions" [value]="transition.id">
                      {{transition.from_requirement?.name || 'Desde'}} -> {{transition.to_requirement?.name || 'Hasta'}}
                    </mat-option>
                  </mat-select>
                  <mat-hint>Enviar en ciertas transiciones</mat-hint>
                </mat-form-field>
              }

            </div>
            <div class="col send_at">
              <mat-radio-group formControlName="send_at">
                <h4>Enviar al: </h4>
                <mat-radio-button value="start" >
                  Iniciar Estado/Trabajo{{model === 'Client' ? '/Transición' : ''}}
                </mat-radio-button>
                <mat-radio-button value="end" >
                  Finalizar Estado/Trabajo{{model === 'Client' ? '/Transición' : ''}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <mat-form-field>
            <mat-label>Texto de la notificacion</mat-label>
            <textarea matInput placeholder="Texto de la notificación" formControlName="text" required></textarea>
            <mat-hint>Contenido del Mail de notificación <i class="fa fa-question-circle" matTooltip="Puedes utilizar los siguientes placeholders: {nombre},{patente},{marca},{modelo},{requerimiento},{estado},{recepcionista:nombre},{recepcionista:telefono},{taller:telefono}. Tambien puedes utilizar tus campos personalizados (ej: {numero_de_siniestro}) y HTML Básico."></i></mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Banner</mat-label>
            <input matInput placeholder="Banner" formControlName="banner_url" autocomplete="off">
            <mat-hint>URL de la imagen a mostrar en el correo</mat-hint>
            <button mat-icon-button matSuffix (click)="banner.click()">
              <mat-icon>link</mat-icon>
            </button>
            <input type="file" style="display: none" name="image" #banner (change)="onFileChange($event,form)">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Estilo Banner</mat-label>
            <mat-select placeholder="Estilo Banner" formControlName="banner_style">
              <mat-option value="">Seleccione</mat-option>
              <mat-option value="contain">Contener</mat-option>
              <mat-option value="cover">Cubrir</mat-option>
            </mat-select>
            <mat-hint>Estilo CSS para el banner</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Formato de respuesta</mat-label>
            <mat-select placeholder="Formato de respuesta" formControlName="answer_type" required>
              <mat-option value="">Seleccione</mat-option>
              <mat-option *ngFor="let answer of answers" [value]="answer.id">{{answer.name}}</mat-option>
            </mat-select>
            <mat-hint>Luego de hacer click en el enlace el cliente podrá responder</mat-hint>
          </mat-form-field>
          <mat-checkbox formControlName="show_work_data" *ngIf="form.controls['works_ids'].value && form.controls['works_ids'].value.length > 0">
            Mostrar información del trabajo
            <i class="fa fa-question-circle" matTooltip="Mostrara checklist, textos y listado de productos"></i>
          </mat-checkbox>
          <mat-checkbox formControlName="show_order">
            Adjuntar orden de trabajo
            <i class="fa fa-question-circle" matTooltip="Mostrara un enlace a la orden de trabajo"></i>
          </mat-checkbox>
          @if (model === 'Budget'){
            <mat-checkbox formControlName="show_budget">
              Adjuntar presupuesto
              <i class="fa fa-question-circle" matTooltip="Mostrará un enlace al presupuesto"></i>
            </mat-checkbox>
          }
          <mat-checkbox formControlName="complete_work" *ngIf="form.controls['works_ids'].value && form.controls['works_ids'].value.length > 0">
            Completar Trabajo asociado (Se elegirá la 1º transición según orden)
          </mat-checkbox>
          <mat-checkbox formControlName="whatsapp_active">
            Incluir notificación a Whatsapp
            <span [ngClass]="getStatusClass(item?.whatsapp_template_status)" class="status-circle-container" [matTooltip]="getStatusTooltipText(item?.whatsapp_template_status)">
              <div class="circle"></div>
              <span class="circle-text"></span>
            </span>
          </mat-checkbox>
          <mat-checkbox formControlName="active">
            Activo
          </mat-checkbox>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
