import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup, Validators,
} from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { ConfirmDialogComponent } from 'app/main/confirm-dialog/confirm-dialog.component';
import { ObjectModel } from 'app/models/object.model';
import { Brand, BrandModel } from 'app/models/types';
import { CheckInService } from 'app/services/check-in.service';
import { formatIdentifier } from 'app/utils/client-utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-object-form',
  templateUrl: './custom-object-form.component.html',
  styleUrls: ['./custom-object-form.component.scss'],
})
export class CustomObjectFormComponent implements OnInit, OnDestroy {
  @Input() hideKms = false;

  @Input() hideOptionals = false;

  @Input() model;

  @Input() optionalFields = false;

  @Input() parentFormGroup: FormGroup;

  @Output() plateSelected = new EventEmitter();

  @Input() outlinemode: boolean = false;

  brands: Brand[] = [];

  country;

  filteredModels: Brand[] = [];

  formRequired: Record<string, boolean>;

  identifierOptional = false;

  models: BrandModel[] = [];

  plates = [];

  searchingModels = false;

  subs: Subscription[] = [];

  @ViewChild('autoModel') autoModel: MatAutocomplete;

  constructor(
    public companyService: AdminService,
    private matDialog: MatDialog,
    private checkinService: CheckInService,
  ) { }

  ngOnInit(): void {
    this.country = this.companyService.local$.getValue().country;
    this.formRequired = this.companyService.local$.getValue().preferences.forms_required.vehicle;
    this.identifierOptional = this.companyService.local$.getValue().preferences.temp_identifier;

    this.parentFormGroup.controls.identifier.setValidators(!this.identifierOptional ? [Validators.required] : []);

    this.subs.push(this.parentFormGroup.controls.identifier.valueChanges.subscribe((value) => {
      this.searchPlate(value);
    }));
  }

  searchPlate(value: string) {
    if (!value) return;

    this.checkinService.searchPlate({ identifier: value }).toPromise().then((response: HttpResponse<ObjectModel>) => {
      const { identifier, ...rest } = response.body;

      this.parentFormGroup.patchValue(rest);
      this.parentFormGroup.controls.identifier.setValue(identifier, { emitEvent: false });

      if (response.body.client) {
        this.matDialog.open(ConfirmDialogComponent, {
          data: {
            title: '¡Cliente encontrado!', body: '¿Desea importar la información?',
          },
          minWidth: '300px',
        }).afterClosed().subscribe((selected: boolean) => {
          if (!selected) return;

          response.body.client.rut = formatIdentifier(
            response.body.client.rut,
            this.country.code,
          );

          this.plateSelected.emit(response.body.client);
        });
      }
    }).catch((error:HttpErrorResponse) => {
      this.plates = error.error;
      this.parentFormGroup.controls.id.setValue(null);
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
