<mat-form-field>
  <mat-label>Database</mat-label>
  <mat-select [(value)]="selectedDatabase" (selectionChange)="onDatabaseChange($event)">
    <mat-option *ngFor="let database of databases" [value]="database.id">
      {{ database.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<fieldset>
  <form [formGroup]="newDatabase" *ngIf="selectedDatabase===0">
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre" formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="db_type">
        <mat-option *ngFor="let type of dbTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input type="file" style="display: none" name="image" #db (change)="onFileChange($event,'db')">
      <input matInput placeholder="Excel" formControlName="db">
      <button mat-icon-button matSuffix (click)="uploadFile(db)" *ngIf="!readingFile && !uploadingDB">
        <mat-icon>link</mat-icon>
      </button>
      <mat-progress-spinner matSuffix *ngIf="readingFile || uploadingDB" mode="determinate" [value]="progress" diameter="25" style="margin-right: 15px;"></mat-progress-spinner>
    </mat-form-field>
    @if(workbook){
      <mat-form-field>
        <mat-label>Hoja a importar</mat-label>
        <mat-select [(value)]="selectedSheet" (selectionChange)="onSheetChange($event)" required>
          <mat-option *ngFor="let sheet of workbook.SheetNames" [value]="sheet">
            {{ sheet }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    }
    @if(workbook && header){
      @for (column of header; track $index) {
        <mat-form-field>
          <mat-label>{{ column }}</mat-label>
          <mat-select [(value)]="selectedKeys[$index]" required>
            <mat-optgroup *ngFor="let key of keys" [label]="key.name">
              <mat-option *ngFor="let field of key.fields" [value]="key.id+'.'+field.id" >
                {{ field.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      }

    }


    <button mat-raised-button color="primary" (click)="createDatabase()" [disabled]="uploadingDB || !newDatabase.valid">Crear</button>
  </form>
  <div class="db" *ngIf="database">
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre" [(ngModel)]="database.name" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <input matInput placeholder="Tipo" [(ngModel)]="database.db_type" required readonly>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Url DB</mat-label>
      <input matInput placeholder="Url" [(ngModel)]="database.db" required readonly>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hoja</mat-label>
      <input matInput placeholder="Hoja" [(ngModel)]="database.sheet" required>
    </mat-form-field>
    <input type="file" style="display: none" name="image" #upgrade (change)="onFileChange($event,'upgrade')">
    <button mat-raised-button color="accent" (click)="uploadFile(upgrade)">ACTUALIZAR</button>
    <h2 style="margin-top: 1em;">Conexiones</h2>
    <div class="connections">
      <div class="connection" *ngFor="let connection of database.connections" [class.connected]="connection.status==='connected'" [class.connecting]="connection.status==='connecting'" [class.pending]="connection.status==='pending'">
        <div class="connection-info">
          <p>{{ connection.company.name }}</p>
        </div>
        <div class="connection-status">
          <p *ngIf="connection.status==='connected'">Conectado</p>
          <p *ngIf="connection.status==='connecting'">Conectando</p>
          <p *ngIf="connection.status==='pending'">Pendiente</p>
        </div>
      </div>
    </div>
    <div class="connect">
      <mat-form-field>
        <mat-label>Compañia</mat-label>
        <mat-select [(value)]="selectedCompany">
          <mat-option *ngFor="let company of companies" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="createConnection()" [disabled]="!selectedCompany">Conectar</button>
    </div>
  </div>
</fieldset>
