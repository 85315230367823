import {
  Directive, ElementRef, HostListener, OnInit, Renderer2,
} from '@angular/core';
import { AdminService } from 'app/admin/services/admin.service';
import { formatIdentifier } from 'app/utils/client-utils';

@Directive({
  selector: '[appIdentifier]',
})
export class IdentifierDirective implements OnInit {
  rutChars = /[-.]/g;

  constructor(private el: ElementRef, private renderer: Renderer2, private adminService: AdminService) { }

  ngOnInit() {
    this.format(this.el.nativeElement.value); // format any initial values
  }

  @HostListener('input', ['$event.target.value']) onInput(e: string) {
    this.format(e);
  }

  @HostListener('change', ['$event.target.value']) onChange(e: string) {
    this.format(e);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.format(event.clipboardData.getData('text/plain'));
  }

  format(val: string) {
    const countryCode = this.adminService.local$.getValue().country.code;
    const formattedValue = formatIdentifier(String(val), countryCode);

    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }
}
