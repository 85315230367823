<mat-card *ngIf="service">
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-list fa-2x"></i></div>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="!loading">
    <ngx-crud-manager
        clone="true"
        [service]="service"
        [itemTemplate]="itemTemplate"
        [formTemplate]="formTemplate"
        [formGroup]="form"
    >
      <ng-template #itemTemplate let-item>
        <div tabindex="0" class="click" (keyup)="select(item)" (click)="select(item)"><i class="fa fa-plus"></i> {{item.name}} &nbsp; <b>({{ item.price | money }})</b></div>
      </ng-template>
      <ng-template #formTemplate let-fform="form" let-value="value">
        <form [formGroup]="fform">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
          </mat-form-field>
          <div>
            <mat-label>{{hiddenInputTitle}} <i class="fa fa-question-circle" [matTooltip]="hiddenInputTooltipMessage"></i></mat-label>
            <mat-checkbox formControlName="hidden" id="hidden" />
          </div>
          <fieldset *ngIf="showSelectors">
            <div class="subtitle">
              <span>Selector&nbsp;</span>
              <i class="fa fa-question-circle" matTooltip="Las condiciones que se deben cumplir para que el kit aplique"></i>
            </div>
              <div class="form-row form-control">
                <div class="form-col" formGroupName="selectors">
                  <div class="form-row">
                    <mat-form-field>
                      <mat-label>Requerimiento</mat-label>
                      <mat-select formControlName="requirement_ids" name="requirement_ids" autocomplete="off" [multiple]="true" required>
                        <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">
                          {{requirement?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field>
                      <mat-label>Nº Chasis</mat-label>
                      <input matInput [formControl]="fform.get('chasis')" placeholder="Nº Chasis" autocomplete="off">
                    </mat-form-field>
                  </div>
                  <ng-container formGroupName="object">
                    <div class="form-row">
                        <app-multi-select-dropdown label="Marca"
                                               [formControl]="fform.get('selectors').get('object').get('brand_ids')"
                                               name="brand_ids"
                                               ngDefaultControl
                                               [items]="brands"> </app-multi-select-dropdown>
                    </div>
                    <div class="form-row">
                        <app-multi-select-dropdown label="Modelo"
                                               [formControl]="fform.get('selectors').get('object').get('brand_model_ids')"
                                               name="brand_model_ids"
                                               ngDefaultControl
                                               [items]="models"> </app-multi-select-dropdown>
                    </div>
                    <app-admin-fields-form
                      [modelName]="company.object"
                      [parentFormGroup]="fform.get('selectors').get('object').get('custom_fields')"
                    >
                    </app-admin-fields-form>
                  </ng-container>

                  <app-admin-fields-form
                      modelName="Order"
                      [parentFormGroup]="fform.get('selectors').get('custom_fields')"
                  >
                  </app-admin-fields-form>
                </div>
              </div>
          </fieldset>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
