import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class ListsService extends BaseService implements ICRUDService {

  params = ['name','multiple','list_id','list_item_filter_ids']

  model = 'List';

  public lists$ = new BehaviorSubject([]);

  constructor(
    public http:HttpClient,
    userService: UserService,
    ) {
    super(http,userService);
  }
  load(){
    return new Promise((resolve,reject)=>{
      this.all().toPromise().then((res:any)=>{
        this.lists$.next(res.body);
        resolve(res.body);
      }).catch((error)=>{
        reject(error);
      });
    });
  }
  objectList(key,values){
    const list = this.lists$.getValue().find((list)=>{
      return list.keys.includes(key)
    });
    if(list){
      return this.getItemNames(values,list.list_items,[],key).join(" - ")
    }else{
      return values[key]
    }
  }
  getItemNames(values,items,names = [],key:string){
    const new_key = names.length == 0 ? key : key+"_"+(names.length+1).toString();
    const value = values[new_key];

    if(value){
      let item = this.findItem(items,value)
      if(item){
        names.push(item.name);
      }
      if(item.list_items){
        return this.getItemNames(values,item.list_items,names,key)
      }else{
        return names;
      }
    }else{
      return names;
    }
  }
  findItem(items,id){
    const index = items.findIndex((l)=>l.id == id);
    if(index >= 0){
      return items[index];
    }else{
      return {
        name: id
      }
    }
  }
  index(value = null,page = 1){
    return this.http.get(this.adminURL+'/lists',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/lists/',{
      list: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/lists/'+id,{
      list: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/lists/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/lists/'+id+'/restore',this.getParams());
  }
  import(value){
    return this.http.post(this.adminURL+'/lists/import',{
      file: value,
    },this.getParams());
  }
  all(){
    return this.http.get(this.adminURL+'/lists/all',this.getParams(
    ));
  }
  global(){
    return this.http.get(this.adminURL+'/lists/global',this.getParams(
    ));
  }
  clone(id){
    return this.http.post(this.adminURL+'/lists/'+id+'/clone',{},this.getParams());
  }
}
