<div class="wrapper no-padding-mobile" [class.admin]="adminMode">
  <mat-card-content class="control-card">
      <div class="logo">
        <img [src]="company.preferences.logo || 'assets/images/logo.svg'">
      </div>
      <div class="user" *ngIf="user">
        No tienes acceso a esta característica
        <button mat-raised-button class="secondary" (click)="logout()">Cerrar sesión</button>
      </div>
      <div class="login-controls" *ngIf="mode=='login' && !user">
        <form #loginForm="ngForm">
          <mat-form-field [hidden]="otp">
            <input type="email" placeholder="Correo" matInput name="mail" required ngModel autocomplete="email">
          </mat-form-field>
          <mat-form-field [hidden]="otp">
            <input type="password" placeholder="Contraseña" matInput name="password" required ngModel autocomplete="current-password">
          </mat-form-field>
          <mat-form-field *ngIf="otp">
            <input type="tel" placeholder="Codigo Autenticador" matInput name="otp" required ngModel minlength="6" maxlength="6">
          </mat-form-field>
          
          <ng-container *ngIf="errortext">
            <div class="error-text"> <i class="fa fa-exclamation-circle"></i> {{errortext}} </div>
          </ng-container>
      
          <button mat-raised-button class="primary" (click)="login(loginForm.value)" [disabled]="loading || !loginForm.valid">
            <span [hidden]="loading">Entrar</span>
            <i class="fa fa-spin fa-circle-notch" [hidden]="!loading"></i>
          </button>
          
        </form>
        <button mat-raised-button class="secondary" (click)="otp=false" style="margin-bottom: 10px" *ngIf="otp">Volver</button>
        <button mat-raised-button class="secondary" (click)="mode='register'" style="margin-bottom: 10px" [disabled]="loading" [hidden]="otp">Crear cuenta</button>
        <button mat-raised-button class="warn" (click)="mode='forgot'" [disabled]="loading" [hidden]="otp">Olvidó contraseña</button>
      </div>
      <div class="register-controls" *ngIf="mode=='forgot' && !user" @flyInOut>
        <form #loginForm="ngForm" *ngIf="!sentRecoverMail">
          <mat-form-field>
            <input type="email" placeholder="Correo" matInput name="mail" required ngModel>
          </mat-form-field>
          <button mat-raised-button class="primary" (click)="recover(loginForm.value)" [disabled]="loading || !loginForm.valid">
            <span [hidden]="loading">Recuperar</span>
            <i class="fa fa-spin fa-circle-notch" [hidden]="!loading"></i>
          </button>
        </form>
        <div class="account-created" *ngIf="sentRecoverMail">Se ha enviado un correo con las instrucciones para recuperar tu contraseña.</div>
        <button mat-raised-button class="secondary" (click)="mode='login'" [disabled]="loading">Volver</button>
      </div>
      <div class="register-controls" *ngIf="mode=='change-password' && !user" @flyInOut>
        <form [formGroup]="recoverForm" *ngIf="!changedPassword">
          <mat-form-field>
            <input type="password" placeholder="Contraseña" matInput name="password" required formControlName="password">
            <mat-error>
              Contraseña inválida, debe tener mínimo 6 dígitos
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input type="password" placeholder="Confirmar Contraseña" matInput name="password_confirmation" required formControlName="password_confirmation">
            <mat-error *ngIf="recoverForm.errors?.notEqual">
              Contraseñas no coinciden
            </mat-error>
          </mat-form-field>
          <button mat-raised-button class="primary" (click)="savePassword()" [disabled]="loading || !recoverForm.valid">
            <span [hidden]="loading">Cambiar contraseña</span>
            <i class="fa fa-spin fa-circle-notch" [hidden]="!loading"></i>
          </button>
        </form>
        <div class="account-created" *ngIf="changedPassword">Contraseña cambiada exitosamente. Intente iniciar sesión</div>
        <button mat-raied-button class="primary" (click)="mode='login'" [disabled]="loading" *ngIf="changedPassword">Iniciar sesión</button>
      </div>
      <div class="register-controls" *ngIf="mode=='register' && !user" @flyInOut>
        <form [formGroup]="registerForm" *ngIf="!savedAccount">
          <mat-form-field>
            <input type="text" placeholder="Nombre completo" matInput name="name" required formControlName="name"  autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <input type="text" placeholder="Correo" matInput name="mail" required formControlName="mail">
          </mat-form-field>
          <mat-form-field>
            <input type="text" [placeholder]="country.preferences.client_id" matInput name="rut" required formControlName="rut" [pattern]="country.preferences.client_id_pattern" autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <input type="password" placeholder="Contraseña" matInput name="password" required formControlName="password">
          </mat-form-field>
          <mat-form-field>
            <input type="password" placeholder="Confirmar Contraseña" matInput name="password_confirmation" required formControlName="password_confirmation">
          </mat-form-field>
          <button mat-raised-button class="primary" (click)="register(registerForm,registerForm.value)" [disabled]="loading || !registerForm.valid">
            <span [hidden]="loading">Registrase</span>
            <i class="fa fa-spin fa-circle-notch" [hidden]="!loading"></i>
          </button>
        </form>
        <div class="account-created" *ngIf="savedAccount">Usuario creado satisfactoriamente. Debes esperar que tu cuenta sea activada por un administrador antes de por iniciar sesión</div>
        <button mat-raised-button class="secondary" (click)="mode='login'" [disabled]="loading">Ya tengo cuenta</button>
      </div>
  </mat-card-content>
</div>

