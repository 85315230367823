import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Booking } from 'app/models/booking.model';
import { Kit } from 'app/models/kits.model';
import { map, Observable } from 'rxjs';

import { BaseService } from './_base.service';

@Injectable()
export class KitsService extends BaseService {
  searchByOrder(booking: Booking): Observable<Kit[]> {
    return this.http.post(
      `${this.apiURL}/kits/search_by_order`,
      booking,
      this.getOptions(),
    )
      .pipe(
        map((response: HttpResponse<Kit[]>) => response.body),
      );
  }

  searchByCode(code: string): Observable<Kit[]> {
    return this.http.get(
      `${this.apiURL}/kits/search_by_code`,
      this.getParams({
        code,
      }),
    )
      .pipe(
        map((response: HttpResponse<Kit[]>) => response.body),
      );
  }
}
