import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { StatusesService } from 'app/services/statuses.service';
import moment from 'moment';
import { WorkService } from '../work.service';
import { WorkTimeComponent } from './work-time/work-time.component';
import { BookingService } from 'app/services/booking.service';
@Component({
  selector: 'app-work-plan',
  templateUrl: './work-plan.component.html',
  styleUrls: ['./work-plan.component.scss']
})
export class WorkPlanComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() works:any;
  @Input() zoom:number = 1;
  @Output() onOpenWork = new EventEmitter();
  @Output() onSetUser = new EventEmitter();
  @Output() update = new EventEmitter();
  @ViewChild('timeline') timeline;
  @ViewChild('hourline') hourline;
  @ViewChild('dayline') dayline;
  users = []
  hours = [];
  days = [];
  current_date = new Date().toISOString();
  statuses = [];
  maxWorks = 0;
  maxWorkStartDate:any;
  lastTarget = null;
  ignoreScrollEvents = false
  DAY_WIDTH = 50;
  dragged: any;
  constructor(
    private adminService: AdminService,
    private statusesService: StatusesService,
    private workService: WorkService,
    private matDialog: MatDialog,
    private bookingService: BookingService
  ) { }

  ngOnInit(): void {
    let users_hash = {};
    this.maxWorks = 0;
    this.maxWorkStartDate = null;
    this.hours = [];
    this.days = [];
    this.statusesService.all('StatusForOrder').toPromise().then((statuses:any) => {
      this.statuses = statuses.body;
    });
    if(this.works && this.works.items){
      this.works.items.forEach(work => {
        if(!users_hash[work.user_id || 0]){
          users_hash[work.user_id || 0] = {
            id: work.user_id || 0,
            name: work.user_name || 'Sin Asignar',
            online: work.online || false,
            picture: work.user_picture || false,
            total_avg_time: 0,
            works: []
          }
        }
        if(work.started_at){
          work.time = (work.base_time || work.work_base_time || work.avg_time || 1) - this.bookingService.businessDays(work.started_at).seconds/3600;
          if(work.time < 0){
            work.time = 1;
          }
          work.maxTime = moment(work.started_at).add(work.time, 'hours').toString();
          work.started_at = new Date(work.started_at).toString();
          
          // get max work start date
          if(!this.maxWorkStartDate || this.maxWorkStartDate < work.started_at){
            this.maxWorkStartDate = work.started_at;
          }
        }else{
          work.time = (work.base_time || work.work_base_time || work.avg_time || 1)
        }
        users_hash[work.user_id || 0].total_avg_time += work.time;
        work.time_left = this.timeLeftTil(work.finish_date);
        work.width = this.calculateWorkWidth(work)
        users_hash[work.user_id || 0].works.push(work);
        
      });
      // get max total avg time
      Object.keys(users_hash).forEach(key => {
        if(users_hash[key].total_avg_time > this.maxWorks){
          this.maxWorks = Math.ceil(users_hash[key].total_avg_time);
        }
      });
      this.users = Object.keys(users_hash).map(key => users_hash[key]);
      // hours
      const work_days = this.adminService.local$.getValue().days_preferences.work_days
      // get the current hour
      const current_time = moment().hour(moment().hour()).minute(0).second(0).millisecond(0);
      const total_hours = this.maxWorks*2/this.zoom;
      for(let i = 0; this.hours.length < total_hours; i++){
        // add 30 minutes to the current hour
        const hour = current_time.clone().add(i*30*this.zoom, 'minutes');
        const hour_string = hour.format('HH:mm');
        // day month
        const day_string = hour.format('DD/MM');
        const isoWeekday = hour.isoWeekday(); 
        const workDay = work_days[isoWeekday-1];
        if(workDay && workDay.enabled){
          const start_hour = moment(workDay.start_time, 'HH:mm');
          const end_hour = moment(workDay.end_time, 'HH:mm');
          if(start_hour.isBefore(end_hour)){
            if(moment(hour_string, 'HH:mm').isBetween(start_hour, end_hour, 'minute', '[)')){
              this.hours.push(hour_string);
              const day = this.days.find(day => day.date == day_string);
              if(day){
                this.days.find(day => day.date == day_string).hours += 1;
              }else{
                this.days.push({
                  date: day_string,
                  hours: 1
                });
              }
            }
          }
        }
      }
    }
  }
  @HostListener('dragstart', ['$event'])
  handleDragStart(event) {
    if (event.target.className.includes("work")) {
      this.dragged = event.target;
    }
  }
  @HostListener("dragover", ["$event"]) onDragOver(event) {
    event.preventDefault()
    if (event.target.className.includes("info")) {
      this.moveWork(event);
    }
  }
  @HostListener('drop', ['$event'])
  handleDragEnd(event) {
    event.preventDefault();
    if (this.dragged) {
      const userId = this.dragged.dataset.user;
      const targetUserId = event.target.dataset.user;  
      const workId = this.dragged.dataset.id; 

      const currentUser = this.users.find(user => user.id == userId);
      const targetUser = this.users.find(user => user.id == targetUserId);

       
      if (!currentUser || !targetUser) {
        console.error('Usuario no encontrado');
        return;
      }

     
      if (userId !== targetUserId) {
        const workIndex = currentUser.works.findIndex(work => work.id == workId);
        if (workIndex === -1) {
          console.error('Trabajo no encontrado');
          return;
        }
        const work = currentUser.works.splice(workIndex, 1)[0];

        
        work.user_id = targetUserId;
        targetUser.works.push(work);

        this.workService.update(work.id, { user_id: targetUserId }).toPromise().then(() => {
          this.update.emit();
        }).catch(err => {
          console.error('Error updating work user:', err);
        });

      } else {
      // Reordenar trabajos dentro del mismo usuario
      const works = currentUser.works;
      const workIds = works.map(work => work.id);
      this.workService.order(workIds).toPromise().then(() => {
        this.update.emit();
      }).catch(err => {
        console.error('Error updating work order:', err);
      });
    }

    this.dragged = null;
    this.lastTarget = null;
  }
}

  ngOnChanges(changes: any): void {
    this.ngOnInit();
  }
  ngAfterViewInit(){
    this.syncScroll(this.timeline.nativeElement, [this.hourline.nativeElement, this.dayline.nativeElement]);
  }
  moveWork(event){
    const workId = this.dragged.dataset.id;
    const targetWorkId = event.target.dataset.id;
    const userId = this.dragged.dataset.user;
    const targetUserId = event.target.dataset.user;

    if (userId == targetUserId) {
      let works = this.users.find(user => user.id == userId).works;
      const targetIndex = works.findIndex(work => work.id == targetWorkId);
      const index = works.findIndex(work => work.id == workId);
      const targetWork = works[targetIndex];
      if(index != targetIndex && !targetWork.started_at && targetWorkId != this.lastTarget){
        const work = works.splice(index, 1)[0];
        works.splice(targetIndex, 0, work);
        this.lastTarget = targetWorkId;
      } else if(workId == targetWorkId){
        this.lastTarget = null;
      }
    }
  }
  syncScroll(element1, elements) {
    //on element1 scroll sync element2
    element1.addEventListener('scroll', () => {
      var ignore = this.ignoreScrollEvents
      this.ignoreScrollEvents = false
      if (ignore) return
      this.ignoreScrollEvents = true
      elements.forEach(element => {
        element.scrollLeft = element1.scrollLeft
      }
      )
    }
    );
  }
    
  calculateHeight(){
    return this.users.length*(100+1)  + 'px';
  }
  calculateTimelineWidth(){
    return this.maxWorks*(this.DAY_WIDTH*2)/this.zoom  + 'px';
  }
  calculateDaylineWidth(day){
    return '0 0 '+(day.hours*this.DAY_WIDTH)+'px';
  }
  calculateWorkWidth(work){
    // if current time is after the max time
    if(moment().isAfter(work.maxTime)){
      work.late = true;
    }
    return '0 0 '+(work.time/this.zoom*this.DAY_WIDTH*2-2) + 'px';
  }
  getStatusCompletion(status_id,work){
    if(work.status_ids && work.status_ids.indexOf(status_id) >= 0){
      return true;
    }
    return false;
  }
  openWork(work){
    this.onOpenWork.emit(work.id);
  }
  setUser(work){
    this.onSetUser.emit({id:work.id,roles_id: work.roles_id})
  }
  openTime(work){
    let clone_work = JSON.parse(JSON.stringify(work));
    clone_work.alternative_requirement_id = work.requirement_id;
    if(!work.base_time && work.work_base_time){
      clone_work.requirement_id = 0;
      clone_work.base_time = work.work_base_time;
      clone_work.base_std_dev = work.work_base_std_dev;
    }else if(!work.base_time){
      clone_work.requirement_id = null;
    }
    this.matDialog.open(WorkTimeComponent, {data: clone_work}).afterClosed().toPromise().then(time => {
      this.update.emit(true);
    });
  }
  timeLeftTil(date){
    // return countdown as string
    const now = moment();
    const end = moment(date);
    const duration = moment.duration(end.diff(now));
    const days = Math.abs(duration.days());
    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());
    const seconds = Math.abs(duration.seconds());
    let time = '';
    if(duration.asMilliseconds() < 0){
      time = '-';
    }
    if(days > 0){
      //add a 0 if the number is less than 10
      time += days;
      time += 'd:';
    }
    if(hours > 0 || days > 0){
      //add a 0 if the number is less than 10
      time += hours < 10 ? '0'+hours : hours;
      time += 'h';
      //time += ':';
    }
    /** 
    if(minutes > 0 || hours > 0 || days > 0){
      //add a 0 if the number is less than 10
      time += minutes < 10 ? '0'+minutes : minutes;
      time += ':';
    }
    if(seconds > 0 || minutes > 0 || hours > 0 || days > 0){
      //add a 0 if the number is less than 10
      time += seconds < 10 ? '0'+seconds : seconds;
    }
      */
    return time;

      
  }
  getInitials(name: string): string {
    if (!name) return '';
    const words = name.split(' ');
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words.length > 1 ? words[words.length > 2 ? 2 : 1].charAt(0).toUpperCase() : '';
    
    return firstInitial + secondInitial;
  }

}
