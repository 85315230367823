<mat-card>
  <mat-card-header>
    <mat-card-title>Inicio de Sesión</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form (submit)="login(f)" #f="ngForm">
      <mat-form-field>
        <input matInput placeholder="Usuario" name="email" [(ngModel)]="user.email" required autocomplete="username">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Contraseña" name="password" [(ngModel)]="user.password" required type="password" autocomplete="current-password">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="One time password" name="otp" [(ngModel)]="user.otp" required autocomplete="off">
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit">Ingresar</button>
    </form>
  </mat-card-content>
</mat-card>
