<div class="hide-mobile filters">
  @if (includedFilters['start_at']) {
    <mat-form-field>
      <input matInput [matDatepicker]="start" placeholder="Fecha de inicio" [(ngModel)]="filters.start_at" (ngModelChange)="updateFilters()">
      <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
      <mat-datepicker #start></mat-datepicker>
    </mat-form-field>
  }
  @if (includedFilters['end_at']) {
    <mat-form-field>
      <input matInput [matDatepicker]="end" placeholder="Fecha de termino" [(ngModel)]="filters.end_at" (ngModelChange)="updateFilters()">
      <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
      <mat-datepicker #end></mat-datepicker>
    </mat-form-field>
  }
  @if (includedFilters['period_group']) {
    <mat-form-field>
      <mat-select placeholder="Escala de tiempo" [(ngModel)]="filters.period_group" (ngModelChange)="updateFilters()">
        <mat-option value="day">
          Diario
        </mat-option>
        <mat-option value="week">
          Semanal
        </mat-option>
        <mat-option value="month">
          Mensual
        </mat-option>
        <mat-option value="year">
          Anual
        </mat-option>
      </mat-select>
    </mat-form-field>
  }
  @if (includedFilters['user']) {
    <mat-form-field>
      <mat-select placeholder="Usuario" [multiple]="true" [(ngModel)]="filters.user_id" (ngModelChange)="updateFilters()">
        <mat-checkbox class="mat-option"
                      (click)="$event.stopPropagation()"
                      (change)="selectAllUsers($event)"
                      [indeterminate]="filters.user_id.length != users.length && filters.user_id.length > 0"
                      [checked]="filters.user_id.length == users.length">
          Seleccionar Todo
        </mat-checkbox>
        <mat-option *ngFor="let user of users" [value]="user.id">{{user.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  }
  @if (includedFilters['requirement']) {
    <mat-form-field>
      <mat-select placeholder="{{adminService.getText('requirement')}}" [multiple]="true" [(ngModel)]="filters.requirement_id" (ngModelChange)="updateFilters()">
        <mat-checkbox class="mat-option"
                      (click)="$event.stopPropagation()"
                      (change)="selectAllRequirements($event)"
                      [indeterminate]="filters.requirement_id.length != requirements.length && filters.requirement_id.length > 0"
                      [checked]="filters.requirement_id.length == requirements.length">
          Seleccionar Todo
        </mat-checkbox>
        <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  }
  @if (includedFilters['work']) {
    <mat-form-field>
      <mat-select placeholder="Trabajo" [multiple]="true" [(ngModel)]="filters.work_id" (ngModelChange)="updateFilters()">
        <mat-checkbox class="mat-option"
                      (click)="$event.stopPropagation()"
                      (change)="selectAllWorks($event)"
                      [indeterminate]="filters.work_id.length != works.length && filters.work_id.length > 0"
                      [checked]="filters.work_id.length == works.length">
          Seleccionar Todo
        </mat-checkbox>
        <mat-option *ngFor="let work of works" [value]="work.id">{{work.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  }
  @if (includedFilters['from']) {
    <mat-form-field>
      <mat-select placeholder="Canal" [multiple]="true" [(ngModel)]="filters.from" (ngModelChange)="updateFilters()">
        <mat-option value="app">Aplicación</mat-option>
        <mat-option value="addon">Add-on</mat-option>
        <mat-option value="derco">Externos</mat-option>
      </mat-select>
    </mat-form-field>
  }
</div>
