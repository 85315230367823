<div class="wrapper">
  <form [formGroup]="filters" class="filters">
    <mat-form-field>
      <mat-label>Por SKU</mat-label>
      <input matInput formControlName="code" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Por Nombre</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fecha Hasta</mat-label>
      <input matInput [matDatepicker]="to_date" formControlName="to_date">
      <mat-datepicker-toggle matSuffix [for]="to_date"></mat-datepicker-toggle>
      <mat-datepicker #to_date></mat-datepicker>
    </mat-form-field>
  </form>
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef mat-sort-header> SKU </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.product.code }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.product.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="stock_on_hand">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Stock En Mano </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>
          {{ element.stock_on_hand }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="committed_stock">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Stock Reservado </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>
          {{ element.committed_stock }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="available_for_sale">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Disponible para venta </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>
          {{ element.available_for_sale }}
        </span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToProductDetails(row)" [style]="{ cursor: 'pointer' }"></mat-row>
  </mat-table>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <mat-paginator
    #paginator
    [length]="totalData"
    [pageSizeOptions]="pageSizes"
    showFirstLastButtons
  ></mat-paginator>
</div>
