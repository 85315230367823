import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../../models/user";
import {Subscription} from "rxjs";
import {UserService} from "../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RoleService} from "../../role/role.service";
import {AdminService} from "../../admin/services/admin.service";
import {UntypedFormGroup} from "@angular/forms";
import { HttpResponse } from "@angular/common/http";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-work-point-login',
  templateUrl: './work-point-login.component.html',
  styleUrls: ['./work-point-login.component.scss']
})
export class WorkPointLoginComponent implements OnInit, OnDestroy {
  loading = false;
  public user: User;
  subs: Subscription[] = [];
  company:any = {};
  constructor(
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private companyService: AdminService
  ) { }

  ngOnInit() {
    this.company = this.companyService.local$.getValue();
    this.route.queryParams.pipe(first()).forEach((value)=>{
      this.companyService.validateWorkPoint(localStorage.getItem('workpoint_token')).toPromise().then((response:any) => {
        if(!response.body.ok){
          this.router.navigate(['workpoint','unauthorized']);
        }
      }).catch(reason => {

      });
    });
    this.userService.logout();
  }
  login(form: UntypedFormGroup) {
    this.loading = true;
    this.userService.authCode(form.value,localStorage.getItem('workpoint_token')).toPromise().then((response:HttpResponse<User>) => {
      if (response.status === 200) {
        this.userService.login(response.body);
        this.router.navigate(['workpoint','main']);
      }
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      if(error && error.status === 401){
        form.controls['password'].setErrors({'invalid': true});
        form.controls['password'].markAsTouched();
      }
    });
  }
  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
