import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {UserService} from "../../services/user.service";
import {RoleService} from "../../role/role.service";
import {Router} from "@angular/router";
import {interval} from "rxjs";
import {AdminService} from "../../admin/services/admin.service";

@Component({
  selector: 'app-work-point-main',
  templateUrl: './work-point-main.component.html',
  styleUrls: ['./work-point-main.component.scss']
})
export class WorkPointMainComponent implements OnInit {

  priorities_count = 0;
  user:any = {online: false};
  private onlineDebouncer: any;
  private activeDebouncer: any;
  DEFAULT_INACTIVE_TIME = 30;
  inactive_time = this.DEFAULT_INACTIVE_TIME;
  works_count = 0;

  constructor(
    private db: AngularFireDatabase,
    private userService: UserService,
    public roleService: RoleService,
    private companyService: AdminService,
    private _router: Router,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.userService.setOnlineStatus(true).toPromise().then((user)=>{
      this.user = user.body;
    }).catch((error)=>{
      console.log(error);
    });
    this.inactive_time = (this.companyService.local$.getValue().preferences.workpoint_logout_time || this.DEFAULT_INACTIVE_TIME)*1000;
    this.db.object(`company/${this.userService.user$.getValue().company_id}/priorities`).valueChanges().subscribe((value:any) => {
      if(value && value.priorities){
        this.priorities_count = value.priorities.filter(
          (p)=>p.user_id === this.userService.user$.getValue().id
        ).reduce((previousValue, currentValue, currentIndex, array) => {
          return previousValue += currentValue.count;
        },0);
      }
      else{
        this.priorities_count = 0;
      }
      if(value && value.works){
        this.works_count = value.works.filter(
          (p)=>p.user_id === this.userService.user$.getValue().id
        ).reduce((previousValue, currentValue, currentIndex, array) => {
          return previousValue += currentValue.count;
        },0);
      }
      else{
        this.works_count = 0;
      }
    });
    this.userService.user$.forEach((value:any)=>{
      if(!value){
        this._router.navigate(['workpoint','login']);
      }else{
        this.user = value;
      }
    });
    this.automaticLogout();
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:onwheel', ['$event'])
  automaticLogout(event?){
    if(this.activeDebouncer){
      this.activeDebouncer.unsubscribe();
    }
    if(this.inactive_time !== 0){
      this.activeDebouncer = interval(this.inactive_time).subscribe(()=>{
        this.matDialog.closeAll();
        this.logout();
        this.activeDebouncer.unsubscribe();
      });
    }
  }
  changeOnlineStatus(event){
    if(this.onlineDebouncer){
      this.onlineDebouncer.unsubscribe();
    }
    this.onlineDebouncer = interval(500).subscribe(()=>{
      this.userService.setOnlineStatus(event.checked).toPromise().then((user)=>{
        this.user = user.body;
      }).catch((error)=>{
        console.log(error);
      });
      this.onlineDebouncer.unsubscribe();
    });
  }
  logout() {
    this.userService.logout();
  }

}
