const responsesMap = {
  Budget: [
    { id: 'none', name: 'Solo Checklist' },
    { id: 'text', name: 'Texto' },
    { id: 'form_filler', name: 'Completar Formulario' },
  ],
  Order: [
    { id: 'none', name: 'Solo Checklist' },
    { id: 'text', name: 'Texto' },
    { id: 'form_filler', name: 'Completar Formulario' },
    { id: 'budget', name: 'Presupuestación' },
    { id: 'payment', name: 'Generar Cobro' },
    { id: 'inventory_actions', name: 'Acciones de Inventario' },
  ],
};

const defaultResponses = [
  { id: 'none', name: 'Solo Checklist' },
  { id: 'text', name: 'Texto' },
];

export const getResponsesOptionsFor = (model: string) => responsesMap[model] || defaultResponses;
