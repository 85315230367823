<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-cog fa-2x"></i></div>
    <mat-card-title>Configuración de Mailing</mat-card-title>
    <mat-card-subtitle>Administre la configuración y diseño de sus correos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-full">
        <div class="hours">
          <form [formGroup]="companyForm">
            <fieldset formGroupName="preferences">
              <fieldset formGroupName="mailing">
                <div class="logo">
                  <mat-form-field>
                    <mat-label>URL Logo</mat-label>  
                    <input matInput placeholder="URL Logo" formControlName="logo">
                    <mat-hint>Preferentemente que tenga buen contraste con el color primario</mat-hint>
                  </mat-form-field>
                  <div class="image" (click)="uploadPhotos(logo)" [class.disabled]="uploadingFiles">
                    <input type="file" style="display: none" name="image" #logo (change)="uploadFile($event,'logo')">
                    <div class="add-image"><i class="fa fa-upload" ></i></div>
                  </div>
                </div>
                <mat-form-field>
                  <mat-label>Web</mat-label> 
                  <input matInput placeholder="Web" formControlName="webpage">
                  <mat-hint>Página web institucional</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Dirección</mat-label>
                  <input matInput placeholder="Dirección" formControlName="address">
                  <mat-hint>Dirección de atención de publico</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Teléfono</mat-label>
                  <input matInput placeholder="Teléfono" formControlName="phone">
                  <mat-hint>Teléfono atención publico</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Correo</mat-label>
                  <input matInput placeholder="Correo" formControlName="email">
                  <mat-hint>Correo atención publico</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Facebook</mat-label>
                  <input matInput placeholder="Facebook" formControlName="facebook">
                  <mat-hint>URL Facebook</mat-hint>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Instagram</mat-label>
                  <input matInput placeholder="Instagram" formControlName="instagram">
                  <mat-hint>URL Instagram test</mat-hint>
                </mat-form-field>
                <div class="row">
                  <div class="col-3">
                    <div class="picker">
                      Color Texto *:
                      <mcc-color-picker hideUsedColors hideEmpty hideButtons (selected)="patchValueColor($event,'color_text')" [selectedColor]="getValueColor('color_text')"></mcc-color-picker>
                    </div>
                    <mat-form-field hidden>
                      <mat-label>Color</mat-label>
                      <input matInput="cback" placeholder="Color" formControlName="color_text" autocomplete="off" autofocus required>
                    </mat-form-field>
                  </div>
                  <div class="col-3">
                    <div class="picker">
                      Color Primario *:
                      <mcc-color-picker hideUsedColors hideEmpty hideButtons (selected)="patchValueColor($event,'color_primary')" [selectedColor]="getValueColor('color_primary')"></mcc-color-picker>
                    </div>
                    <mat-form-field hidden>
                      <mat-label>Color</mat-label>
                      <input matInput="cback" placeholder="Color" formControlName="color_primary" autocomplete="off" autofocus required>
                    </mat-form-field>
                  </div>
                  <div class="col-3">
                    <div class="picker">
                        Color Acción *:
                      <mcc-color-picker hideUsedColors hideEmpty hideButtons (selected)="patchValueColor($event,'color_accent')" [selectedColor]="getValueColor('color_accent')"></mcc-color-picker>
                    </div>
                    <mat-form-field hidden>
                      <mat-label>Color</mat-label>
                      <input matInput="cback" placeholder="Color" formControlName="color_accent" autocomplete="off" autofocus required>
                    </mat-form-field>
                  </div>
                </div>
              </fieldset>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>