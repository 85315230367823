import {NgModule} from '@angular/core';
import {RoleComponent} from './role.component';
import {RoleService} from './role.service';
import {RolePipe} from './role.pipe';
import {RoleSelectorComponent} from './role-selector/role-selector.component';
import {BaseModule} from "../shared/base.module";

@NgModule({
    imports: [
        BaseModule
    ],
    declarations: [RoleComponent, RolePipe, RoleSelectorComponent],
    exports: [RoleComponent, RoleSelectorComponent],
    providers: [RoleService]
})
export class RoleModule { }
