<div class="header">
  <i class="fa fa-times-circle"></i> Rechazar Trabajo
</div>
<div class="body">
  <form #form="ngForm" (ngSubmit)="reject()">
    <mat-form-field>
      <mat-select [(ngModel)]="rejectForm.reason" placeholder="Razón de Rechazo" required name="reason">
        <mat-option value="last_job_unfinished">Trabajo anterior no finalizado</mat-option>
        <mat-option value="not_available">No estoy disponible</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput [(ngModel)]="rejectForm.comment" placeholder="Comentario" required name="comment">
    </mat-form-field>
    <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid">Rechazar</button>
  </form>

</div>
