import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CheckInService} from "../../../services/check-in.service";
import {RoleService} from "../../../role/role.service";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: 'wpl-payment',
  templateUrl: './wpl-payment.component.html',
  styleUrls: ['./wpl-payment.component.scss']
})
export class WplPaymentComponent implements OnInit {
  @Input() parentForm:UntypedFormGroup;
  @Input() value:any = {};
  @Input() disabled = false;
  @Input() request;
  @Input() clientMode = false;
  form:UntypedFormGroup = new UntypedFormGroup({payment_id: new UntypedFormControl('',Validators.required)});
  key = 'custom_params';
  working = false;
  order:any = {};
  payment;
  constructor(
    private orderService: CheckInService,
    public roleService: RoleService
  ) { }

  ngOnInit() {
    Promise.resolve(null).then(() => {
      this.form.patchValue(this.value || {});
      this.parentForm.addControl(this.key, this.form);
      this.order = this.request.order;
      if(this.value && this.value.payment_id){
       this.getPayment();
      }
    });
  }
  ngOnDestroy(){
    Promise.resolve(null).then(() => {
      this.parentForm.removeControl(this.key);
    });
  }
  getPayment(){
    this.working = true;
    this.orderService.getPayment(this.order.id,this.value.payment_id).toPromise().then((value1:HttpResponse<any>) => {
      this.working = false;
      this.payment = value1.body;
    }).catch(reason => {
      this.working = false;
    })
  }
  generatePayment(){
    this.working = true;
    this.orderService.generatePayment(this.order.id).toPromise().then((value1:HttpResponse<any>) => {
      this.form.patchValue({payment_id: value1.body.id})
      this.working = false;
      this.payment = value1.body;
    }).catch(reason => {
      this.working = false;
    })
  }
  openPayment(){
    window.open(this.payment.preference.init_point,"_blank")
  }
  getTotal(){
    if(this.order.order_items){
      return this.order.order_items.reduce((prev,current:any)=>{
        prev += current.item_price*current.item_quantity;
        return prev;
      },0) + this.order.total;
    }else{
      return 0;
    }

  }

}
