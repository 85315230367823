import {NgModule} from '@angular/core';
import { BaseModule} from "../shared/base.module";
import { WorkModule } from '../work/work.module';
import { SharesComponent } from './shares.component';
import { ChartFiltersComponent } from 'app/analytics/chart-filters/chart-filters.component';
import { ChartCardComponent } from 'app/analytics/chart-card/chart-card.component';

@NgModule({
  imports: [
    BaseModule,
    WorkModule,
    ChartFiltersComponent,
    ChartCardComponent,
  ],
  declarations: [
    SharesComponent
  ],
  exports: [
    SharesComponent
  ]
})
export class SharesModule { }
