import {Injectable} from '@angular/core';
import {UserService} from "./user.service";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {mergeMap, take} from "rxjs/operators";
import {WorkDialogComponent} from "../work/work-dialog/work-dialog.component";
import {OrderNotificationsComponent} from "../main/order-notifications/order-notifications.component";
import {OrderComponent} from "../main/order/order.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PrioritiesService} from "../priorities/priorities.service";
import {BudgetViewComponent} from "../budget/budget-view/budget-view.component";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  listenSub: any;
  user:any;
  constructor(
    private userService: UserService,
    private afMessaging: AngularFireMessaging,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private prioritiesService: PrioritiesService
  ) { }
  init(){
    this.requestPermission();
    this.userService.user$.subscribe(value => {
      if(value){
        this.user = value;
      }else if(this.listenSub){
        this.deleteToken();
      }
    });
  }
  requestPermission() {
    this.afMessaging.requestToken
      .subscribe({
        next: (token) => {
          this.updateToken(token);
          this.listen();
        },
        error: (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      }
      );
  }
  updateToken(token) {
    this.userService.update({
      notification_token: token
    }).pipe(take(1)).subscribe();
  }
  deleteToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => {},
      );
  }
  listen() {
    if(this.listenSub){
      this.listenSub.unsubscribe();
    }
    this.listenSub = this.afMessaging.messages
      .subscribe((message:any) => {
        if(parseInt(message.data.user_id,0) === this.user.id){
          this.openSnackBar(message);
        }
      });
  }
  openDialog(notification){
    switch(notification.model_class){
      case 'WorkAssignation':
        this.matDialog.open(WorkDialogComponent,{data: {id: notification.model_id, type: 'WorkInstance'}, minWidth: '80%'});
        break;
      case 'OrderNotification':
        this.matDialog.open(OrderNotificationsComponent,{data: {order_id: notification.model_id}, minWidth: '80%'});
        break;
      case 'WorkRequest':
        this.matDialog.open(WorkDialogComponent,{data: {id: notification.model_id, type: 'WorkInstance'}, minWidth: '80%'});
        break;
      case 'UserAssignation':
        this.matDialog.open(WorkDialogComponent,{data: {id: notification.model_id, type: 'WorkInstance'}, minWidth: '80%'});
        break;
      case 'OrderComment':
        this.matDialog.open(OrderComponent, {data: notification.content});
        break;
      case 'Comment':
        if(notification.notification_type==='Order'){
          this.matDialog.open(OrderComponent, {data: notification.model_id});
        }else if(notification.notification_type==='WorkInstance'){
          this.matDialog.open(WorkDialogComponent,{data: {id: notification.content, type: 'WorkInstance'}, minWidth: '80%'});
        }else if(notification.notification_type==='Budget'){
          this.matDialog.open(BudgetViewComponent,{data: {id: notification.content, type: 'Budget'}, minWidth: '80%'});
        }
        break;
      case 'Event':
        if(notification.notification_type==='Order' || notification.notification_type==='info') {
          this.matDialog.open(OrderComponent, {data: notification.model_id});
        }else if(notification.notification_type==='WorkInstance'){
          this.matDialog.open(WorkDialogComponent,{data: {id: notification.model_id, type: 'WorkInstance'}, minWidth: '80%'});
        }
        break;
      case 'Recall':
        this.matDialog.open(OrderComponent, {data: notification.model_id});
        break;
      default:
        break;
    }
  }
  openSnackBar(message) {
    this.snackBar.open(message.notification.body, 'Ver', {
      duration: 10000,
    }).onAction().pipe(take(1)).subscribe(()=>{
      this.prioritiesService.complete(message.data.id);
      this.openDialog(message.data);
    });
  }
}
