import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {interval, Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {StatusesService} from "../services/statuses.service";
import {debounceTime, first} from "rxjs/operators";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {uniq} from "lodash-es";
import {FilterComponent} from "../main/filter/filter.component";
import {VehiclesService} from "./vehicles.service";
import {OrderComponent} from "../main/order/order.component";
import {BookingComponent} from "../main/booking/booking.component";
import {AdminService} from 'app/admin/services/admin.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  displayedColumns: string[] = ['brand', 'object_identifier', 'client', 'requirement', 'workInfo', 'note'];
  dataSource: MatTableDataSource<any>;

  statuses: any[] = [{
    name: 'Sin orden',
    id: 1,
    color: 'gray'
  },{
    name: 'Agendado',
    id: 2,
    color: '#45ABFF'
  },{
    name: 'Con orden',
    id: 3,
    color: 'green'
  }];
  vehicles: any;
  MobileScreen : boolean;
  searchControl: UntypedFormControl = new UntypedFormControl();
  filter: any  = this.getFilter();
  page = 1;
  total_pages = 1;
  totalByStatus: any = {};
  filters: any = [{
    name: 'Estado',
    key: 'status_id',
    options: [],
    multi: true
  }
  ];
  subs: Subscription[] = [];
  public working = false;
  private search: any;
  count = 0;
  private loadingMore = false;
  constructor(
    private vehicleService: VehiclesService,
    private matDialog: MatDialog,
    private statusesService: StatusesService,
    public appService: AdminService,
  ) { }

  ngOnInit(search?) {
    this.appService.pageTitle.set(this.appService.getText('object_plural'));
    this.ngOnDestroy();
    this.subs.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if(this.statuses.length > 0){
        this.search = value;
        this.getVehicles();
      }
    }));
    this.getVehicles();
    this.getTotalsByStatus();
    this.getMore();
    this.dataSource = new MatTableDataSource(this.vehicles);

  }
  statusOn(id) {
    if(this.filter){
      const index = this.filter.findIndex((s) => parseInt(s,0) === id);
      return index >= 0;
    }else{
      return false;
    }
  }
  getVehicles(loadingMore?) {
    // SCROLL TOP
    if(!loadingMore){
      this.resetVehicle();
      this.working = true;
    }
    this.vehicleService.all({status_id: this.filter, page: this.page}, this.search)
      .toPromise().then((response:HttpResponse<any>) => {
      if(loadingMore){
        this.vehicles = this.vehicles.concat(response.body.vehicles);
      }else{
        this.vehicles = response.body.vehicles;
      }
      this.count = response.body.total;
      this.page = response.body.page;
      this.total_pages = response.body.total_pages;
      this.working = false;
      this.loadingMore = false;

      this.dataSource.data = this.vehicles;
      this.appService.pageTitle.set(this.appService.getText('object_plural'));//+ " (" + this.count + ")"
    }).catch((error:HttpErrorResponse) => {
      console.log(error);
      this.working = false;
    });
  }
  getTotalsByStatus() {
    this.vehicleService.totalsByStatus().subscribe({
      next: (response) => {
        const totals = response.body;
        if (totals && Array.isArray(totals)) {
          this.totalByStatus = totals.reduce((acc, current) => {
            acc[current.vehicle_status] = current;
            return acc;
          }, {});
        } else {
          //console.error('Totales por estado vacio');
        }
       // console.log(this.totalByStatus);
      }
    });
  }
  resetVehicle(){
    const element = document.getElementById('Vehicles');
    if(element){
      element.scrollTop = 0;
    }
    this.page = 1;
    this.total_pages = 1;
    this.count = 0;
  }
  getMore(){
    interval(500).subscribe(()=>{
      if(this.page < this.total_pages && !this.loadingMore){
        const element = document.getElementById('Vehicles');
        if(element){
          const loaderElement = document.getElementById('load-more');
          if(element.scrollTop+element.clientHeight > element.scrollHeight - loaderElement.clientHeight){
            this.loadingMore = true;
            this.page++;
            this.getVehicles(true);
          }
        }
      }
    });
  }
  getFilter(){
    const value = localStorage.getItem('vehicles_filter');
    let filter = null;
    if(value){
      filter = uniq(value.split(',')).map((v)=>parseInt(v,0));
    }else{
      filter = [1,2]
    }
    return filter;
  }
  saveFilter(){
    this.filter.push(0);
    this.filter = uniq(this.filter);
    localStorage.setItem('vehicles_filter',this.filter);
  }
  toggleFilter(id: number) {
    const index = this.filter.findIndex((s) => s === id);
    if (index >= 0) {
      this.filter.splice(index, 1);
    }else {
      this.filter.push(id);
    }
    this.saveFilter();
    this.getVehicles();
  }
  openVehicle(vehicle){
    if(vehicle.correlative_id){
      this.matDialog.open(OrderComponent, {data: vehicle.order_id, minWidth: '80%'}).afterClosed().pipe(first()).forEach(value => {
        this.getVehicles();
      })
    }
    else if(vehicle.order_id){
      this.matDialog.open(BookingComponent, {data: {id: vehicle.order_id}, minWidth: '80%'}).afterClosed().pipe(first()).forEach(value => {
        this.getVehicles();
      })
    }else{
      this.matDialog.open(BookingComponent, {data: {vehicle: vehicle}, minWidth: '80%'}).afterClosed().pipe(first()).forEach(value => {
        this.getVehicles();
      })
    }

  }
  openFilters() {
    // Setup Options
    this.filters[0].options = this.statuses.map((s) => [s.id, s.name, this.statusOn(s.id)]);
    this.matDialog.open(FilterComponent, {data: this.filters}).afterClosed().pipe(first()).forEach((result) => {
      if(result){
        this.filter = result.status_id;
        this.saveFilter();
        this.getVehicles();
      }
    });
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  clearSearch() {
    this.searchControl.setValue('');
  }

}
