import {debounceTime} from 'rxjs/operators';
import {Component, NgZone, OnInit} from '@angular/core';
import {FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../services/admin.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CameraService} from "../../../shared/image-uploader/camera/camera.service";
import { HttpResponse } from "@angular/common/http";
import {RoleService} from "../../../role/role.service";

@Component({
  selector: 'app-admin-company',
  templateUrl: './admin-company.component.html',
  styleUrls: ['./admin-company.component.scss','../admin-main.common.scss']
})
export class AdminCompanyComponent implements OnInit{
  dayForm = new UntypedFormGroup({
    enabled: new UntypedFormControl(false, Validators.required),
    start_time: new UntypedFormControl('08:00', Validators.required),
    end_time: new UntypedFormControl('18:00', Validators.required),
  });
  workDays = new UntypedFormArray([])
  companyForm = this.fb.group({
    name: [{value: '', disabled: true}],
    preferences: this.fb.group({
      logo: '',
      address: '',
      phone: '',
      email_banner: '',
      work_days: this.workDays,
      report_send_frecuency: 'weekly',
      report_role_ids: [],
      booking_role_ids: [],
      booking_type: 'capacity',
      temp_identifier: false,
    }),
    email: [{value: '', disabled: true}],
    domain: [{value: '', disabled: true},
      Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)]
  });
  days = ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo'];
  booking_types = [{
    id: 'capacity',
    name: 'Según capacidad'
  },{
    id: 'professional',
    name: 'Según Profesionales'
  },{
    id: 'stock',
    name: 'Según Stock'
  }]
  uploadingFiles = false;
  roles = [];
  constructor(
    private fb: UntypedFormBuilder,
    private companyService: AdminService,
    private snackBar: MatSnackBar,
    private cameraService: CameraService,
    private ngZone: NgZone,
    private roleService: RoleService,
  ) {
    for(let i=0;i<7;i++){
      const dayForm = new UntypedFormGroup({
        enabled: new UntypedFormControl(false, Validators.required),
        start_time: new UntypedFormControl('08:00', Validators.required),
        end_time: new UntypedFormControl('18:00', Validators.required),
      });
      (<UntypedFormArray>this.workDays).push(dayForm)
    }
  }

  ngOnInit() {
    this.companyService.setupApp().toPromise().then((response)=>{
      this.companyForm.patchValue(response.body);
    }).catch((error)=>{
      console.log(error);
    });
    this.roleService.simple_index().toPromise().then((response: HttpResponse<any[]>)=>{
      this.roles = response.body;
    }).catch((error)=>{
      this.roles = [];
    });
    this.companyForm.valueChanges.pipe(debounceTime(2000)).subscribe((value)=>{
      if(this.companyForm.valid){
        this.companyService.updateCompany(value).toPromise().then((response)=>{
        });
      }
    });
  }
  uploadPhotos(input) {
    input.click();
  }
  getPref(){
    let form = (<FormGroup>this.companyForm.controls['preferences'])
    return form;
  }
  uploadFile(event,control) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      this.uploadingFiles = true;
      for (const fileIndex in event.target.files) {
        if (event.target.files[fileIndex]){
          const file = event.target.files[fileIndex];
          if (file.size > 2e+7) {
            this.snackBar.open("¡Oops! La imagen " + file.name + " es muy pesada")._dismissAfter(2000);
          } else if (!file.type || file.type.indexOf('image/') === -1) {
          } else {
            this.ngZone.run(() => {
              const uploadTask = this.cameraService.saveImage(file);
              uploadTask.on('state_changed', (snapshot:any) => {
                this.ngZone.run(() => {
                  event.target.files[fileIndex].progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
                });
              }, (error) => {
                this.ngZone.run(() => {
                  event.target.files[fileIndex].uploaded = true;
                });
              }, () => {
                this.ngZone.run(() => {
                  event.target.files[fileIndex].uploaded = true;
                });
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
                  this.uploadingFiles = false;
                  (<UntypedFormGroup>this.companyForm.controls['preferences']).controls[control].patchValue(downloadURL);
                }).catch((error)=>{
                  console.log(error);
                });
              });
            });
          }
        }
      }
    }
  }

}
