
  <div mat-dialog-title class="name">
    Seleccione Campo
  </div>

  <div mat-dialog-content class="content">
    <div class="grid-full">
      <mat-form-field appearance="fill">
        <mat-label>Buscar</mat-label>
        <input matInput [(ngModel)]="search" (ngModelChange)="searchFields()" autocomplete="off">
      </mat-form-field>
    </div>

    @if (multiple) {
      <button mat-button class="grid" *ngFor="let field of fields" (click)="setField(field)">
        <mat-icon *ngIf="isChecked(field) && multiple">check_box</mat-icon>
        <mat-icon *ngIf="!isChecked(field) && multiple">check_box_outline_blank</mat-icon>
        <span >{{field.title || field.name}} <b *ngIf="field.price">&nbsp;({{field.price | money}})</b></span>
      </button>

    } @else {
      <button mat-button class="grid" *ngFor="let field of fields" (click)="setField(field)">
        <span >{{field.title || field.name}} <b *ngIf="field.price">&nbsp;({{field.price | money}})</b></span>
      </button>
    }

      <ng-template [ngIf]="fields.length === 0">
        <span class="empty">No se han encontrado resultados</span>
      </ng-template>

  </div>


<div mat-dialog-actions class="footer">
  <button mat-button (click)="close()">Cancelar</button>
</div>
