/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'app/models/product.model';

@Pipe({
  name: 'product',
})
export class ProductPipe implements PipeTransform {
  transform(product: Product): string {
    return `[${product.code}] - ${product.name}`;
  }
}
