import {Component, Inject, OnInit} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BookingComponent} from '../../booking/booking.component';
import {UserService} from "../../../services/user.service";
import {first} from "rxjs/operators";
import { AdminService } from 'app/admin/services/admin.service';
import { BookingService } from 'app/services/booking.service';

@Component({
  selector: 'app-derco-mails-dialog',
  templateUrl: './derco-mails-dialog.component.html',
  styleUrls: ['./derco-mails-dialog.component.scss']
})
export class DercoMailsDialogComponent implements OnInit {
  

  constructor(
    private db: AngularFireDatabase,
    private matDialogRef: MatDialogRef<DercoMailsDialogComponent>,
    private matDialog: MatDialog,
    private userService: UserService,
    private adminService: AdminService,
    private bookingService: BookingService,
    @Inject(MAT_DIALOG_DATA) public mails: any[]
  ) {

  }
ºº
  ngOnInit() {
    
  }
  removeMail(booking) {
    const index = this.mails.findIndex((m) => m === booking);
    this.mails.splice(index, 1);
    this.bookingService.removeExternalMail(booking.id).pipe(first()).subscribe((data:any)=>{
      this.mails = data.body;
      if(this.mails.length === 0){
        this.matDialogRef.close();
      }
    });
  }
  addBooking(mail) {
    this.matDialog.open(BookingComponent, {
      data: mail.booking,
      width: '80%'
    })
  }
  openBooking(mail) {
    console.log(mail);
    this.matDialog.open(BookingComponent, {
      data: {
        id: mail.is_booked || mail.booking.id
      },
      width: '80%'
    })
  }

}
