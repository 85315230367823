import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BaseService} from '../services/_base.service';
import {UserService} from '../services/user.service';
import {Observable} from "rxjs";

import {User} from "../models/user";

@Injectable()
export class RoleService extends BaseService {

  constructor(http: HttpClient, userService: UserService) {
    super(http, userService);
  }
  index() {
    return this.http.get(this.apiURL + '/roles/', this.getParams());
  }
  simple_index() {
    return this.http.get(this.apiURL + '/roles/simple_index', this.getParams());
  }
  create(value: any) {
    return this.http.post(this.apiURL + '/roles', value, this.getOptions());
  }
  get(id: number) {
    return this.http.get(this.apiURL + '/roles/' + id, this.getParams());
  }
  update(id: number, value: any) {
    return this.http.put(this.apiURL + '/roles/' + id, value, this.getOptions());
  }
  delete(id: number) {
    return this.http.delete(this.apiURL + '/roles/' + id, this.getOptions());
  }
  import() {
    return this.http.post(this.apiURL + '/roles/import',{}, this.getOptions());
  }
  onlyOwn(action, model): Observable<any> {
    return this.userService.user$.pipe(map((u: User) => {
      if (u?.role?.powers?.[model]?.[action]) {
        return u.role.powers[model][action]==='own';
      } else {
        return false;
      }
    }));
  }
  can(action: string, model: string): Observable<any> {
    return this.userService.user$.pipe(map((u: User) => {
      if (u && u.role && (u.role.powers && u.role.powers[model] && u.role.powers[model][action] || u.role.powers['admin'])) {
        return u.role.powers['admin'] || u.role.powers[model][action];
      }else {
        return false;
      }
    }));
  }
  canRead(model: string): Observable<any> {
    const action = 'read';
    return this.userService.user$.pipe(map((u: User) => {
      if (u && u.role && (u.role.powers && u.role.powers[model] && u.role.powers[model][action] || u.role.powers['admin'])) {
        return u.role.powers['admin'] || u.role.powers[model][action]!=null;
      }else {
        return false;
      }
    }));
  }

  getRootUrl(user: User) {
    if(user && user.role && user.role.powers){
      for(const power in user.role.powers){
        if(power && user.role.powers[power] && user.role.powers[power].read && this.modelToPath(power)){
          return this.modelToPath(power);
        }
      }
    }
    return 'login';
  }
  pathToModel(path){
    path = path.split(",")[0]
    switch (path){
      case 'booking':
        return 'booking';
      case 'orders':
        return 'check_in';
      case 'calendar':
        return 'event';
      case 'checkin':
        return 'check_in';
      case 'works':
        return 'work';
      case 'analytics':
        return 'analytics';
      case 'admin':
        return 'manager';
      default:
        return path;
    }
  }
  modelToPath(path){
    path = path.split("/")[0]
    switch (path){
      case 'booking':
        return 'booking';
      case 'check_in':
        return 'orders';
      case 'event':
        return 'calendar';
      case 'work':
        return 'works';
      case 'analytics':
        return 'analytics';
      case 'manager':
        return 'admin';
      default:
        return false;
    }
  }

  getUsers(id: any) {
    return this.http.get(this.apiURL + '/roles/' + id + '/users', this.getParams());
  }
}
