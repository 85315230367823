import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PeriodPipe } from '../../shares/pipes/period.pipe';
import { BrandPipe } from './brand.pipe';
import { IdentifierPipe } from './identifier.pipe';
import { MoneyPipe } from './money.pipe';
import { ObjectNamePipe } from './object.pipe';
import { PhotosPipe } from './photos.pipe';
import { ProductPipe } from './product.pipe';
import { SingularizePipe } from './singularize.pipe';
import { StatusPipe } from './status.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    BrandPipe,
    IdentifierPipe,
    MoneyPipe,
    ObjectNamePipe,
    PeriodPipe,
    PhotosPipe,
    ProductPipe,
    SingularizePipe,
    StatusPipe,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    BrandPipe,
    IdentifierPipe,
    MoneyPipe,
    ObjectNamePipe,
    PeriodPipe,
    PhotosPipe,
    ProductPipe,
    SingularizePipe,
    StatusPipe,
    TruncatePipe,
  ],
})
export class CommonPipesModule { }
