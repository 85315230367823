<mat-card >
  <mat-card-header>
    <div mat-card-avatar><i class="fa fa-wrench fa-2x"></i></div>
    <mat-card-title>Tiempos Trabajos</mat-card-title>
    <mat-card-subtitle>Calculo de tiempo promedio de trabajos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ngx-crud-manager [service]="workRequirementAvgService" [itemTemplate]="wraTemplate" [formTemplate]="wraFormTemplate" [formGroup]="wraForm">
      <ng-template #wraTemplate let-item>
        {{item.work?.name}} + {{item.requirement?.name || 'Todos'}}
      </ng-template>
      <ng-template #wraFormTemplate let-form="form" let-item="item">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Trabajo</mat-label>
                <mat-select placeholder="Trabajo" formControlName="work_id" required>
                  <mat-option *ngFor="let work of allWorks" [value]="work.id">{{work.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Requerimiento</mat-label>
                <mat-select placeholder="Requerimiento" formControlName="requirement_id" required>
                  <mat-option *ngFor="let requirement of requirements" [value]="requirement.id">{{requirement.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Tiempo Base (hrs)</mat-label>
                <input matInput placeholder="Tiempo Base (hrs)" formControlName="base_time" autocomplete="off">
                <mat-hint>Tiempo de duración base</mat-hint>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Desviación Base (hrs)</mat-label>
                <input matInput placeholder="Desviación Base (hrs)" formControlName="base_std_dev" autocomplete="off">
                <mat-hint>Desviación base</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Tiempo Promedio (hrs)</mat-label>
                <input matInput placeholder="Tiempo Promedio (hrs)" formControlName="time" autocomplete="off">
                <mat-hint>Tiempo promedio calculado</mat-hint>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Desviación Estandar (hrs)</mat-label>
                <input matInput placeholder="Desviación Estandar (hrs)" formControlName="std_dev" autocomplete="off">
                <mat-hint>Desviación estandar calculada</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-template>
    </ngx-crud-manager>
  </mat-card-content>
</mat-card>
