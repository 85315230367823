import {Injectable} from '@angular/core';
import {BaseService} from "../../services/_base.service";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../services/user.service";
import { ICRUDService } from 'app/shared/crud-manager/crud.interface';

@Injectable({
  providedIn: "root"
})
export class ClientsService extends BaseService implements ICRUDService{
  params = ['name','rut','phone','address','city','email','custom_fields']
  model = 'Client';
  constructor(public http:HttpClient,userService: UserService) {
    super(http,userService);
  }
  index(value = null, page = 1){
    return this.http.get(this.adminURL+'/clients',this.getParams({search: value, page: page}));
  }
  create(value){
    return this.http.post(this.adminURL+'/clients/',{
      client: this.permitParams(value,...this.params)
    },this.getParams());
  }
  update(id,value){
    return this.http.put(this.adminURL+'/clients/'+id,{
      client: this.permitParams(value,...this.params)
    },this.getParams());
  }
  destroy(id){
    return this.http.delete(this.adminURL+'/clients/'+id,this.getParams());
  }
  restore(id){
    return this.http.delete(this.adminURL+'/clients/'+id+'/restore',this.getParams());
  }

}
